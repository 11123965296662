import React from "react";

const AboutSection = () => {
  return (
    <section className="about-area pt-70 pb-90">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-6 col-lg-6">
            <div className="about-img mb-50">
              <img src="assets/img/about/1/1.png" alt="Section À Propos" />
              <div className="about-img-meta">
                <span>
                  <i className="fa-solid fa-star" /> 4.5 (3,4k Avis)
                </span>
                <h5>Félicitations</h5>
              </div>
            </div>
          </div>
        {/*  <div className="col-xl-6 col-lg-6 col-md-10">
            <div className="about-content mb-50">
              <div className="section-area mb-20">
                <span className="section-subtitle">Commencez à apprendre gratuitement</span>
                <h2 className="section-title mb-15">
                  Les cours en ligne peuvent être adaptés aux besoins des apprenants
                </h2>
                <p className="section-text">
                  Grâce à une combinaison de conférences, de lectures et de discussions,
                  les étudiants acquerront une base solide en psychologie de l'éducation.
                </p>
              </div>
              <div className="about-content-list">
                <ul>
                  <li>Collection de cours internationaux en 14 langues</li>
                  <li>Les meilleures certifications en technologie et en affaires</li>
                  <li>Accès à plus de 35 000 cours Eduan de qualité, à tout moment et partout</li>
                </ul>
              </div>
              <div className="about-content-button">
                <a
                  href="about.html"
                  className="theme-btn about-btn theme-btn-medium"
                >
                  Plus de Détails
                </a>
              </div>
            </div>
          </div>*/}
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
