import React, {useState, useEffect, useRef} from 'react';
import {InputText} from 'primereact/inputtext';
import {InputTextarea} from 'primereact/inputtextarea';
import {InputMask} from 'primereact/inputmask';
import {InputNumber} from 'primereact/inputnumber';
import {AutoComplete} from 'primereact/autocomplete';
import {Calendar} from 'primereact/calendar';
import {Chips} from 'primereact/chips';
import {Dropdown} from 'primereact/dropdown';
import {MultiSelect} from 'primereact/multiselect';

import {Checkbox} from "primereact/checkbox";
import {RadioButton} from "primereact/radiobutton";
import {InscriptionService} from '../../service/operations/InscriptionsService';
import {ParentService} from '../../service/parametre/ParentService';
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import classNames from "classnames";
import {MatiereService} from "../../service/parametre/MatiereService";

const IdentificationsEleve = () => {
    let emptyeleve = {
        eleve_mail :'' ,
        eleve_numero_extrait_naiss :0,
        eleveadresse:'',
        elevecellulaire :'',
        elevecode :'',
        elevedate_etabli_extrait_naiss :null,
        elevedate_naissance :null,
        idTenant : 0,
        identifiantEleve : 0,
        elevelieu_etabliss_etrait_naissance :'',
        elevelieu_naissance:'',
        elevenom:'',
        eleveprenom:'' ,
        eleve_matricule_national:''

    };
    let emptyparent = {
        parentid :null ,
        parentcode :'',
        parentnom:'',
        parentprenom :'',
        parent_tel :'',
        parent_tel2 :'',
        parent_profession :'',
        parentemail : '',
        identifiantTypeParent : 0,
        elevelieu_etabliss_etrait_naissance :'',
        identifiantTenant:''

    };

    let emptyIdentification = {
        inscriptionsid :null ,
        inscriptionscode :'',
      inscriptions_ecole_origine:'',
       inscriptions_classe_precedente :'',
       inscriptions_derniereclasse_religieuse :'',
        inscriptions_classe_actuelle :'',
         inscriptions_status :'',
      inscriptionsdate_creation : Date.now(),
      identifiantEleve : 0,
      identifiantEcole :0,
        identifiantAnnee_scolaire:0,
       libellehandicap:[]

    };


    const [countries, setCountries] = useState([]);

    const [handicaps, setHandicaps] = useState([]);

    const [inscriptions, setinscriptions] = useState(null);
    const [productDialog, setProductDialog] = useState(false);

    const [handicapDialog, sethandicapDialog] = useState(false);

    const [inscription, setinscription]  = useState(emptyIdentification);
    const [eleve, seteleve]  = useState(emptyeleve);
    const [parent, setparent]  = useState(emptyparent);
    const [selectedTypeParent, setSelectedTypeParent] = useState(null);

    const [submitted, setSubmitted] = useState(false);
    const [submittedHandicap, setSubmittedHandicap] = useState(false);

    const [filteredinscriptions, setFilteredinscriptions] = useState(null);

    const [checkboxValue, setCheckboxValue] = useState([]);
    const [radioValue, setRadioValue] = useState('non');

    const toast = useRef(null);

    const TypeParents = [
        {type_parentlibelle: 'PERE', type_parentcode: 'PERE'},
        {type_parentlibelle: 'MERE', type_parentcode: 'MERE'},
        {type_parentlibelle: 'TUTEUR', type_parentcode: 'TUTEUR'},
        {type_parentlibelle: 'PERSONNE EN CHARGE', type_parentcode: 'PERSONNE EN CHARGE'}

    ];

    const onTypeParentChange = (e) => {
        setSelectedTypeParent(e.value);
    }




    useEffect(() => {
        const inscriptionsService = new InscriptionService();
        const parentService = new ParentService();
       /* matiereService.getAllMatiere().then(data => {
            console.log(data)
            setMatieres(data)
        });*/
    }, []);

 const  onRadioChange = (e) => {
        setRadioValue(e.value);
     setHandicaps([]) ;
     setSubmittedHandicap(false) ;
     // setSubmitted(false);
     sethandicapDialog(true) ;
    }



    const onCheckboxChange = (e) => {
        let selectedValue = [...checkboxValue];
        if (e.checked)
            selectedValue.push(e.value);
        else
            selectedValue.splice(selectedValue.indexOf(e.value), 1);

        setCheckboxValue(selectedValue);
    };
    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';

    }
    const onInputNumberChange = (e, name) => {
        const val = e.value || 0;

    }
    const openNew = () => {
        setparent(emptyparent);
        setSubmitted(false);
        setProductDialog(true);
    }

    const openNewHandicap = () => {
        //setparent(emptyparent);
      setRadioValue('oui')
       setHandicaps([]) ;
        setSubmittedHandicap(false) ;
       // setSubmitted(false);
        sethandicapDialog(true) ;
       // setProductDialog(true);
    }

    const saveParent = () => {
        setSubmitted(true);
        if (parent.parentnom.trim()&& parent.parentprenom.trim()&&parent.parent_tel.trim()) {
            const parentService = new ParentService();
            parentService.creerParent(parent) ;
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Created', life: 3000 });
            setProductDialog(false);

        }
    }

    const saveHandicap = () => {
        setSubmittedHandicap(true);
        setHandicaps(checkboxValue);

    }




    const searchCountry = (event) => {
        // in a real application, make a request to a remote url with the query and
        // return filtered results, for demo we filter at client side
        const filtered = [];
        const query = event.query;
        for (let i = 0; i < countries.length; i++) {
            const country = countries[i];
            if (country.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
                filtered.push(country);
            }
        }
        setFilteredinscriptions(filtered);
    };



    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
    }

    const hideHandiDialog = () => {
        setSubmittedHandicap(false);

        sethandicapDialog(false)
    }
    const productDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveParent} />
        </>
    );

    const handicapDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideHandiDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveHandicap} />
        </>
    );
    return (
        <div className="card">
            <h5>IDENTIFICATION ELEVE </h5>
            <div className="grid p-fluid mt-3">
                <div className="field col-12 md:col-4">
                    <span className="p-float-label">
                        <InputText type="text" id="nom" value={eleve.elevenom} onChange={(e) => onInputChange(e, 'nom')}/>
                        <label htmlFor="nom">Nom</label>
                    </span>
                </div>

                <div className="field col-12 md:col-4">
                    <span className="p-float-label">
                        <InputText type="text" id="prenom" value={eleve.eleveprenom} onChange={(e) => onInputChange(e, 'prenom')}/>
                        <label htmlFor="prenom">prenom</label>
                    </span>
                </div>

                <div className="field col-12 md:col-4">
                    <span className="p-float-label">
                        <Calendar inputId="dateNaissance" value={eleve.elevedate_naissance} onChange={(e) => onInputChange(e, 'dateNaissance')}></Calendar>
                        <label htmlFor="dateNaissance">Date de Naissance</label>
                    </span>
                </div>

                <div className="field col-12 md:col-4">
                    <span className="p-float-label">
                        <InputText type="text" id="lieuNaissance" value={eleve.elevelieu_naissance} onChange={(e) => onInputChange(e, 'lieuNaissance')}/>
                        <label htmlFor="lieuNaissance">Lieu de naissance</label>
                    </span>
                </div>

                <div className="field col-12 md:col-4">
                    <span className="p-float-label">
                        <InputText type="text" id="NumeroExtrait" value={eleve.eleve_numero_extrait_naiss} onChange={(e) => onInputChange(e, 'NumeroExtrait')}/>
                        <label htmlFor="NumeroExtrait">Numero extrait de naissance </label>
                    </span>
                </div>

                <div className="field col-12 md:col-4">
                    <span className="p-float-label">
                        <Calendar inputId="dateEtablissement" value={eleve.elevedate_etabli_extrait_naiss} onChange={(e) => onInputChange(e, 'dateEtablissement')}></Calendar>
                        <label htmlFor="dateEtablissement">Date etablissement extrait de naissance</label>
                    </span>
                </div>


                <div className="field col-12 md:col-4">
                    <span className="p-float-label">
                        <InputText type="text" id="lieuEtablissement" value={eleve.elevedate_etabli_extrait_naiss} onChange={(e) => onInputChange(e, 'lieuEtablissement')}/>
                        <label htmlFor="lieuEtablissement">Lieu etablissement extrait de naissance  </label>
                    </span>
                </div>

                <div className="field col-12 md:col-4">
                    <span className="p-float-label">
                        <InputText type="text" id="adresse" value={eleve.eleveadresse} onChange={(e) => onInputChange(e, 'adresse')}/>
                        <label htmlFor="adresse">Adresse  </label>
                    </span>
                </div>

                <div className="field col-12 md:col-4">
                    <span className="p-float-label">
                        <InputMask id="cellulaire" mask="99/99/99/99/99" value={eleve.elevecellulaire} onChange={(e) => onInputChange(e, 'cellulaire')}></InputMask>
                        <label htmlFor="cellulaire">Cellulaire</label>
                    </span>
                </div>


                <div className="field col-12 md:col-4">
                    <span className="p-float-label">
                        <InputText type="text" id="email" value={eleve.eleve_mail} onChange={(e) => onInputChange(e, 'email')}/>
                        <label htmlFor="email">Email  </label>
                    </span>
                </div>

                <div className="field col-12 md:col-4">
                    <span className="p-float-label">
                        <InputText type="text" id="elevMatricule" value={eleve.eleve_matricule_national} onChange={(e) => onInputChange(e, 'email')}/>
                        <label htmlFor="elevMatricule">Matricule elève  </label>
                    </span>
                </div>

                <div className="col-12">
                    <div className="card">
                        <h5>Handicap</h5>

                        <div className="grid">
                            <div className="col-12 md:col-6">
                                <div className="field-radiobutton">
                                    <RadioButton inputId="option1" name="option" value="oui" checked={radioValue === 'oui'} onChange={openNewHandicap}  />
                                    <label htmlFor="option1">Oui</label>
                                </div>
                            </div>
                            <div className="col-12 md:col-6">
                                <div className="field-radiobutton">
                                    <RadioButton inputId="option2" name="option" value="non" checked={radioValue === 'non'} onChange={(e) => setRadioValue(e.value)}  />
                                    <label htmlFor="option2">Non</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12">

                        <Dialog visible={handicapDialog} style={{ width: '450px' }} header="Product Details" modal className="p-fluid" footer={handicapDialogFooter} onHide={hideHandiDialog}>
                            <div className="field-checkbox">
                                <Checkbox inputId="checkOption1" name="option" value="moteur" checked={checkboxValue.indexOf('moteur') !== -1} onChange={onCheckboxChange} />
                                <label htmlFor="checkOption1">moteur</label>
                            </div>

                            <div className="field-checkbox">
                                <Checkbox inputId="checkOption2" name="option" value="Visuel" checked={checkboxValue.indexOf('Visuel') !== -1} onChange={onCheckboxChange} />
                                <label htmlFor="checkOption2">Visuel</label>
                            </div>

                            <div className="field-checkbox">
                                <Checkbox inputId="checkOption3" name="option" value="auditif" checked={checkboxValue.indexOf('auditif') !== -1} onChange={onCheckboxChange} />
                                <label htmlFor="checkOption3">auditif</label>
                            </div>
                        </Dialog>
                </div>
                <div className="col-12">
                    <div className="card">
                        <h5>SCOLARITE ANTERIEURE</h5>
                        <div className="grid p-fluid">
                            <div className="col-12 md:col-6">
                            <span className="p-float-label">
                              <InputText id="ecoleDorigine" type="text" value={inscription.inscriptions_ecole_origine} onChange={(e) => onInputChange(e, 'ecoleDorigine')}/>
                              <label htmlFor="ecoleDorigine">Ecole d'origine</label>
                          </span>
                            </div>

                            <div className="col-12 md:col-6">
                            <span className="p-float-label">
                              <InputText id="classePrecc" type="text" value={inscription.inscriptions_classe_precedente} onChange={(e) => onInputChange(e, 'classePrecc')}/>
                              <label htmlFor="classePrecc">Classe précedente</label>
                            </span>
                            </div>
                            <div className="col-12 md:col-6">
                            <span className="p-float-label">
                              <InputText id="derniClassRelig" type="text" value={inscription.inscriptions_derniereclasse_religieuse} onChange={(e) => onInputChange(e, 'derniClassRelig')}/>
                              <label htmlFor="derniClassRelig">Dernière classe religieuse</label>
                          </span>
                            </div>


                        </div>
                    </div>

                    <div className="card">
                        <h5>SCOLARITE ACTUELLE</h5>
                        <div className="grid p-fluid">
                            <div className="col-12 ">
                            <span className="p-float-label">
                              <InputText id="classeAsuiv" type="text" value={inscription.inscriptions_classe_actuelle} onChange={(e) => onInputChange(e, 'classeAsuiv')}/>
                              <label htmlFor="classeAsuiv">Classe à suivre</label>
                          </span>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="my-2">
                    <Button label="Ajouter un parent" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                </div>


                <div className="col-12">

                        <Dialog visible={productDialog} style={{ width: '450px' }} header="Details parent" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>

                            <div className="field">
                                <Dropdown value={selectedTypeParent} options={TypeParents} onChange={onTypeParentChange} optionLabel="type_parentlibelle" placeholder="Selectionnez le type" />
                            </div>

                            <div className="field" >
                                <label htmlFor="parentnom"> Parent nom </label>
                                <InputText id="parentnom" value={parent.parentnom} onChange={(e) => onInputChange(e, 'parentnom')} required autoFocus className={classNames({ 'p-invalid': submitted && !parent.parentnom })} />
                                {submitted && !parent.parentnom && <small className="p-invalid">Le nom est obligatoire.</small>}
                            </div>


                            <div className="field">
                                <label htmlFor="parentcode">Parent code</label>
                                <InputText id="parentcode" value={parent.parentcode} onChange={(e) => onInputChange(e, 'parentcode')} required autoFocus className={classNames({ 'p-invalid': submitted && !parent.parentcode })} />
                                {submitted && !parent.parentnom && <small className="p-invalid">Le nom est obligatoire.</small>}
                            </div>

                            <div className="field">
                                <label htmlFor="parentprenom">Parent prénom</label>
                                <InputText id="parentprenom" value={parent.parentprenom} onChange={(e) => onInputChange(e, 'parentprenom')} required autoFocus className={classNames({ 'p-invalid': submitted && !parent.parentprenom })} />
                                {submitted && !parent.parentprenom && <small className="p-invalid">Le prénom est obligatoire.</small>}
                            </div>

                            <div className="field">
                                <label htmlFor="parentTel">parent mobile  1</label>
                                <InputText id="parentTel" value={parent.parent_tel} onChange={(e) => onInputChange(e, 'parentTel')} required autoFocus className={classNames({ 'p-invalid': submitted && !parent.parent_tel })} />
                                {submitted && !parent.parent_tel && <small className="p-invalid">Le téléphone est obligatoire.</small>}
                            </div>

                            <div className="field">
                                <label htmlFor="parentTel2">parent mobile  2</label>
                                <InputText id="parentTel2" value={parent.parent_tel2} onChange={(e) => onInputChange(e, 'parentTel2')} />

                            </div>

                            <div className="field">
                                <label htmlFor="parentEmail">Parent email</label>
                                <InputText id="parentEmail" value={parent.parentemail} onChange={(e) => onInputChange(e, 'parentEmail')}  />
                            </div>
                        </Dialog>

                </div>

            </div>
            <div className="col-12">
                <div className="card">
                    <Button label="Enregistrer" className="mr-2 mb-2"></Button>
                    <Button label="Annuler" className="mr-2 mb-2"></Button>

                </div>
            </div>
        </div>
    )
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(IdentificationsEleve, comparisonFn);
