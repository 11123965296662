import React, {useEffect, useRef, useState} from 'react';
import { useForm, Controller } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Password } from 'primereact/password';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { classNames } from 'primereact/utils';
import '../../assets/login/FormDemo.css';
import '../../App.scss';
import App from "../../App";
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'prismjs/themes/prism-coy.css';
import '../../assets/demo/flags/flags.css';
import '../../assets/demo/Demos.scss';
import '../../assets/layout/layout.scss';
import {AppConfig} from "../../AppConfig";
import {CSSTransition} from "react-transition-group";
import {Route, useLocation} from "react-router-dom";
import { useHistory } from "react-router-dom";
import ScrollToTop from "../../ScrollToTop";
import CompteProfesseur from "./CompteProfesseur";
import CreerEcole from "./CreerEcole";
import CreerEcole2 from "./CreerEcole2";
import SouscripEcole from "./SouscripEcole";
import PageAccueil from "./PageAccueil";
import EspacePersonnel from "./EspacePersonnel";
import App3 from "../../App3.js";
import Accueil from "./Accueil";
import {Tooltip} from "primereact/tooltip";
import {AppTopbar} from "../../AppTopbar";
import {AppMenu} from "../../AppMenu";
import ModifierMotDepassPersonnel from "./ModifierMotDepassPersonnel";
import DocumentsPersonnel from "./DocumentsPersonnel";
import BoiteEnvoieFondateur from "./BoiteEnvoieFondateur";
import BoiteReceptionCandidat from "./BoiteReceptionCandidat";

import PrimeReact from "primereact/api";
//import classNames from 'classnames';



export const ConnexionCandidat = () => {
    const [callCompte, setcallCompte] = useState('');
    const [layoutMode, setLayoutMode] = useState('static');
    const [layoutColorMode, setLayoutColorMode] = useState('light')
    const [inputStyle, setInputStyle] = useState('outlined');
    const [ripple, setRipple] = useState(true);
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
    const copyTooltipRef = useRef();
    // const [titleName, setTitleName] = useState('');
    const location = useLocation();
    // const [countries, setCountries] = useState([]);
    const [showMessage, setShowMessage] = useState(false);
    const [formData, setFormData] = useState({});
    const [passWord, setPassWord] = useState('soum@1111');
    const [email, setEmail] = useState('soumsmith1@gmail.com');
    // const [codeEcole, setCodeEcole] = useState('01');

    let history = useHistory();
  //  const countryservice = new CountryService();
    const defaultValues = {
        name: '',
        email: '',
        password: '',
        date: null,
        country: null,
        accept: false,
        text:''
    }

    // const Profils = [
    //     {codeProfil: 'ADMIN', libelleProfil: 'ADMINISTRATEUR'},
    //     {codeProfil: 'FOND', libelleProfil: 'FONDATEUR'}
    // ];


    let menuClick = false;
    let mobileTopbarMenuClick = false;

    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
    }, [mobileMenuActive]);

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    // const onInputStyleChange = (inputStyle) => {
    //     setInputStyle(inputStyle);
    // }

    // const onRipple = (e) => {
    //     PrimeReact.ripple = e.value;
    //     setRipple(e.value)
    // }

    // const onLayoutModeChange = (mode) => {
    //     setLayoutMode(mode)
    // }

    // const onColorModeChange = (mode) => {
    //     setLayoutColorMode(mode)
    // }

    // const onWrapperClick = (event) => {
    //     if (!menuClick) {
    //         setOverlayMenuActive(false);
    //         setMobileMenuActive(false);
    //     }

    //     if (!mobileTopbarMenuClick) {
    //         setMobileTopbarMenuActive(false);
    //     }

    //     mobileTopbarMenuClick = false;
    //     menuClick = false;
    // }

    // const onToggleMenuClick = (event) => {
    //     menuClick = true;

    //     if (isDesktop()) {
    //         if (layoutMode === 'overlay') {
    //             if (mobileMenuActive === true) {
    //                 setOverlayMenuActive(true);
    //             }

    //             setOverlayMenuActive((prevState) => !prevState);
    //             setMobileMenuActive(false);
    //         }
    //         else if (layoutMode === 'static') {
    //             setStaticMenuInactive((prevState) => !prevState);
    //         }
    //     }
    //     else {
    //         setMobileMenuActive((prevState) => !prevState);
    //     }

    //     event.preventDefault();
    // }

    // const onSidebarClick = () => {
    //     menuClick = true;
    // }

    // const onMobileTopbarMenuClick = (event) => {
    //     mobileTopbarMenuClick = true;

    //     setMobileTopbarMenuActive((prevState) => !prevState);
    //     event.preventDefault();
    // }

    // const onMobileSubTopbarMenuClick = (event) => {
    //     mobileTopbarMenuClick = true;

    //     event.preventDefault();
    // }
    useEffect(() => {

        setcallCompte(0)
    }, []);
    // const onMenuItemClick = (event) => {
    //     if (!event.item.items) {
    //         setOverlayMenuActive(false);
    //         setMobileMenuActive(false);
    //     }
    // }
    // const isDesktop = () => {
    //     return window.innerWidth >= 992;
    // }


    const addClass = (element, className) => {
        if (element.classList)
            element.classList.add(className);
        else
            element.className += ' ' + className;
    }

    const removeClass = (element, className) => {
        if (element.classList)
            element.classList.remove(className);
        else
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }
    const wrapperClass = classNames('layout-wrapper', {
        'layout-overlay': layoutMode === 'overlay',
        'layout-static': layoutMode === 'static',
        'layout-static-sidebar-inactive': staticMenuInactive && layoutMode === 'static',
        'layout-overlay-sidebar-active': overlayMenuActive && layoutMode === 'overlay',
        'layout-mobile-sidebar-active': mobileMenuActive,
        'p-input-filled': inputStyle === 'filled',
        'p-ripple-disabled': ripple === false,
        'layout-theme-light': layoutColorMode === 'light'
    });
    /*useEffect(() => {
        console.log('callCompte '+callCompte)
        history.push('/home/candidat');
    }, [callCompte]); // eslint-disable-line react-hooks/exhaustive-deps
*/
    const { control, formState: { errors }, handleSubmit, reset } = useForm({ defaultValues });

    const onSubmit = (data) => {
      //  onClick() ;
        sessionStorage.setItem('connect', '1');
      //  history.push('/home/candidat/index')


    };

    // const getFormErrorMessage = (name) => {
    //     return errors[name] && <small className="p-error">{errors[name].message}</small>
    // };
    // const menu = [

    //     {
    //         label: 'ESPACE CANDIDAT', icon: 'pi pi-fw pi-sitemap',
    //         items: [
    //             { label: "Mon profil", icon: "pi pi-fw pi-check-square", to: "/home/candidat/monProfil" },
    //             { label: "Login et mot de passe", icon: "pi pi-fw pi-check-square", to: "/home/candidat/ModifierMotdePasse" },
    //             { label: "Mes documents", icon: "pi pi-fw pi-check-square", to: "/home/candidat/MesDocuments" },
    //             {
    //                 label: 'Ma Messagerie', icon: 'pi pi-fw pi-bookmark',
    //                 items: [
    //                     {label: 'Messages envoyés', icon: 'pi pi-fw pi-send', to: '/home/candidat/messageEnvoyes'},
    //                     { label: 'Messages réçus', icon: 'pi pi-fw pi-inbox', to: '/home/candidat/messagerecus'},

    //                 ]
    //             }

    //         ]
    //     }
    // ];
    const dialogFooter = <div className="flex justify-content-center"><Button label="OK" className="p-button-text" autoFocus onClick={() => setShowMessage(false)} /></div>;
    // const passwordHeader = <h6>Choisir un mot de passe</h6>;
    // const passwordFooter = (
    //     <React.Fragment>
    //         <Divider />
    //         <p className="mt-2">Suggestions</p>
    //         <ul className="pl-2 ml-2 mt-0" style={{ lineHeight: '1.5' }}>
    //             <li>Au moins une minuscule</li>
    //             <li>Au moins une majuscule</li>
    //             <li>Au moins un chiffre</li>
    //             <li>8 caractères minimum</li>
    //         </ul>
    //     </React.Fragment>
    // );
    const creerCompte = () => {
        setcallCompte('1');
    }


    const retourAccueil = () => {
        setcallCompte('2');
    }


        return (
            <div className="form-demo">
                <Dialog visible={showMessage} onHide={() => setShowMessage(false)} position="top" footer={dialogFooter} showHeader={false} breakpoints={{ '960px': '80vw' }} style={{ width: '30vw' }}>
                    <div className="flex justify-content-center flex-column pt-6 px-3">
                        <i className="pi pi-check-circle" style={{ fontSize: '5rem', color: 'var(--green-500)' }}></i>
                        <h5>Registration Successful!</h5>
                        <p style={{ lineHeight: 1.5, textIndent: '1rem' }}>
                            Your account is registered under name <b>{formData.name}</b> ; it'll be valid next 30 days without activation. Please check <b>{formData.email}</b> for activation instructions.
                        </p>
                    </div>
                </Dialog>

                <div className="flex flex-column align-items-center justify-content-center">


                    <form onSubmit={handleSubmit(onSubmit)} >
                        <div className="flex flex-column align-items-center justify-content-center">
                            <div style={{ borderRadius: '56px', padding: '0.3rem', background: 'linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%)' }}>
                                <div className="w-full surface-card py-8 px-5 sm:px-8" style={{ borderRadius: '53px' }}>
                                    <div className="text-center mb-5">

                                        <div className="text-900 text-3xl font-medium mb-3">Mon espace Candidat! </div>
                                        <span className="text-600 font-medium">Connectez vous pour continuer</span>
                                    </div>
                                    <div>
                                        <label htmlFor="email1" className="block text-900 text-xl font-medium mb-2">
                                            Email
                                        </label>
                                        <InputText inputid="email1" type="text" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Adresse email" className="w-full md:w-30rem mb-5" style={{ padding: '1rem' }} />
                                        <label htmlFor="passWord" className="block text-900 text-xl font-medium mb-2">
                                            Mot de passe
                                        </label>
                                        <Password inputid="passWord" value={passWord} onChange={(e) => setPassWord(e.target.value)} placeholder="Password" toggleMask className="w-full mb-5" inputClassName='w-full p-3 md:w-30rem'></Password>

                                    </div>
                                    <Button type="submit" label="Submit" className="w-full p-3 text-xl" ></Button>
                                    <div>
                                        <Button label="Créer mon compte" className="p-button-link mb-2 mr-2" icon="pi-user" onClick={creerCompte}/>
                                        <Button label="Retour" className="p-button-link mb-2 mr-2" icon="pi-user" onClick={retourAccueil}/>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>


                </div>


            </div>
        )









}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

//export default React.memo(ConnexionCandidat, comparisonFn);
export default ConnexionCandidat;
