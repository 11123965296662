import axios from 'axios';
import { BaseService } from './BaseService';
const constants = {
    host: 'http://localhost:8889/gestion-ecole-api',    
  };

export class TypeActiviteService extends BaseService {
    getList(){
        return axios.get(this.getHost()+'type-activite/list').then(res => res.data);
    }

    getListByEcole(ecoleId){
        return axios.get(this.getHost()+'type-activite/get-by-ecole/'+ecoleId).then(res => res.data);
    }

    getListByEcoleAndType(ecoleId, type){
        return axios.get(this.getHost()+'type-activite/get-by-ecole-and-type/'+ecoleId+'/?type='+type).then(res => res.data);
    }
}