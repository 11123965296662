import axios from 'axios';
import { BaseService } from './BaseService';
export class AppelNumeriqueService extends BaseService {

    async getListAppel(seanceId, position) {
        console.log(position);
        return axios.get(this.getHost() + 'appel-numerique/get-list-eleve/'+seanceId+'/'+position).then(res => res.data);
    }

    async save(dto) {
        console.log(dto)
        return axios.post(this.getHost() + 'appel-numerique/save-handle-dto', dto);
    }

}