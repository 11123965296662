import axios from 'axios';
import { BaseService } from './BaseService';

const ecole = sessionStorage.getItem('EcoleID');
export class PersonnelService extends BaseService{

    getList(){
        return axios.get(this.getHost()+'personnels/list').then(resp => resp.data);
    }

    async getListProf(){
        return axios.get(this.getHost()+'personnels/get-by-fonction?fonction=1&ecole='+ecole).then(resp => resp.data);
    }

    async getListByFonction(fonction){
        return axios.get(this.getHost()+'personnels/get-by-fonction?fonction='+fonction+'&ecole='+ecole).then(resp => resp.data);
    }

    async getListByProfil(ecoleId, profilId){
        return axios.get(this.getHost()+'personnels/get-by-ecole-and-profil?ecole='+ecoleId+'&profil='+profilId).then(resp => resp.data);
    }

    async getListByFonction_v2(fonction, ecole){
        return axios.get(this.getHost()+'personnels/get-by-fonction?fonction='+fonction+'&ecole='+ecole).then(resp => resp.data);
    }

    async getById(id){
        return axios.get(this.getHost()+'personnels/get-by-id/'+id).then(resp => resp.data);
    }

    async getByUserId(id){
        return axios.get(this.getHost()+'personnels/get-by-user-id/'+id).then(resp => resp.data);
    }

    async getByUserAndEcole(id, user){
        return axios.get(this.getHost()+'personnels/get-by-user-ecole/'+id+'/'+user).then(resp => resp.data);
    }

    getPersonnelEnseignantByEcole(ecoleId, anneeId){
        return null;
    }

    getPersonnelEducateurByEcole(ecoleId, anneeId){
        return null;
    }

    getPersonnelByEcole(ecoleId, anneeId){
        return null;
    }

    getEnseignantByClasseAndAnnee(classeId, anneeId){
        return null ;
    }

}
