import axios from 'axios';
import { BaseService } from './BaseService';

const ecoleId = sessionStorage.getItem('EcoleID');
const anneeId = sessionStorage.getItem('AnneEncours');
export class PersonnelMatiereClasseService extends BaseService {

    async getList() {
        return axios.get(this.getHost() + 'personnel-matiere-classe/list').then(res => res.data);
    }
    async getListAllFields() {
        return axios.get(this.getHost() + 'personnel-matiere-classe/list-populate').then(res => res.data);
    }
    async getById(id) {
        return axios.get(this.getHost() + 'personnel-matiere-classe/' + id).then(res => res.data);
    }
    async getByBranche(branche) {
        return axios.get(this.getHost() + 'personnel-matiere-classe/get-by-branche?branche=' + branche).then(res => res.data);
    }
    async getByMatiere(matiere) {
        return axios.get(this.getHost() + 'personnel-matiere-classe/get-by-matiere?matiere=' + matiere + '&ecole=' + ecoleId + '&annee=' + anneeId).then(res => res.data);
    }
    async getByClasse(classe, annee) {
        return axios.get(this.getHost() + 'personnel-matiere-classe/get-professeur-by-classe?classe=' + classe + '&annee=' + annee).then(res => res.data);
    }
    async getByProfesseur(annee, prof, ecole) {
        return axios.get(this.getHost() + 'personnel-matiere-classe/get-by-prof?annee=' + annee + '&prof=' + prof + '&ecole=' + ecole).then(res => res.data);
    }

    // ressource a fusionner avec getByProfesseur(prof)
    async getByProfesseurAndAnnee(prof, annee) {
        return axios.get(this.getHost() + 'personnel-matiere-classe/get-by-prof?prof=' + prof + '&annee=' + annee + '&ecole=' + ecoleId).then(res => res.data);
    }

    async getByProfesseurAndClasse(prof, classe, annee) {
        console.log(prof + ' ' + classe);
        return axios.get(this.getHost() + 'personnel-matiere-classe/get-by-prof-classe?prof=' + prof + '&classe=' + classe + '&annee=' + annee).then(res => res.data);
    }

    async getByProfesseurAndClasseWhereCoefIsDefine(prof, classe, annee) {
        console.log(prof + ' ' + classe);
        return axios.get(this.getHost() + 'personnel-matiere-classe/get-by-prof-classe-where-coef-is?prof=' + prof + '&classe=' + classe + '&annee=' + annee).then(res => res.data);
    }
    // Prevoir ecole comme paramètre
    async getByFonction(annee, ecole, fonction) {
        return axios.get(this.getHost() + 'personnel-matiere-classe/get-by-fonction?annee=' + annee + '&ecole=' + ecole + '&fonctionId=' + fonction).then(res => res.data);
    }
    // Obténir les prof princ ou éducateur en fonction d'une classe
    async getPersonnelByClasse(annee, ecole, classe) {
        console.log("annee : " + annee + " ecole : " + ecole + " classe : " + classe);
        return axios.get(this.getHost() + 'personnel-matiere-classe/get-personnel-by-classe?annee=' + annee + '&ecole=' + ecole + '&classe=' + classe).then(res => res.data);
    }

    async getEducateurAndProfPrinc(annee, ecole, classe) {
        console.log("annee : " + annee + " ecole : " + ecole + " classe : " + classe);
        return axios.get(this.getHost() + 'personnel-matiere-classe/get-pp-and-educ-dto-by-classe?annee=' + annee + '&ecole=' + ecole + '&classe=' + classe).then(res => res.data);
    }

    async getProfesseurByClasseAndMatiere(annee, classe, matiere) {

        return axios.get(this.getHost() + 'personnel-matiere-classe/get-professeur-by-matiere?annee=' + annee + '&matiere=' + matiere + '&classe=' + classe).then(res => res.data);
    }

    save(classe) {
        axios.post(this.getHost() + 'personnel-matiere-classe/save', classe);
    }

    async update(classe) {
        return axios.post(this.getHost() + 'personnel-matiere-classe/update', classe).then(response => response.data);
    }

    async updateAndDisplay(classe) {
        return axios.post(this.getHost() + 'personnel-matiere-classe/update-display', classe).then(res => res.data);
    }

    async saveAndDisplay(classe) {
        return axios.post(this.getHost() + 'personnel-matiere-classe/saveAndDisplay', classe).then(res => res.data);
    }

    async saveAndDisplayList(classe, persClaMatList) {
        return axios.post(this.getHost() + 'personnel-matiere-classe/saveAndDisplayList', classe, persClaMatList).then(res => res.data);
    }


    delete(id) {
        return axios.delete(this.getHost() + 'personnel-matiere-classe/delete/' + id);
    }

    deleteByStatus(pmc) {
        return axios.put(this.getHost() + 'personnel-matiere-classe/delete-by-status/', pmc);
    }
}