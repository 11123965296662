import axios from 'axios';
import { BaseService } from './BaseService';
const constants = {
    host: 'http://localhost:8889/gestion-ecole-api',
};
export class NiveauEnseignementService extends BaseService {

    getList() {
        return axios.get(this.getHost() + 'niveau-enseignement/list').then(res => res.data);
    }

    getListProjection(){
        return axios.get(this.getHost()+'niveau-enseignement/list-projection').then(res => res.data);
    }

    delete(id){
        return axios.delete(this.getHost()+'niveau-enseignement/delete/'+id);
    }
}
