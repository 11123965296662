import axios from 'axios';
import { BaseService } from './BaseService';


export class FileService extends BaseService {

    async send() {

        return axios.post(this.getHost() + 'file/send').then(res => res.data);
    }

    get getUrlFileSend() {
        return this.getHost() + 'file/send';
    }

    get getUrlFileToUpload() {
        return this.getHost() + 'file/send-files';
    }

    async sendFile(formData) {
        for (let pair of formData.entries()) {
            console.log(pair[0] + ', ' + pair[1]);
        }
        return axios.post(this.getHost() + 'file/send-files/file', formData).then(res => res);
    }

    async downloadFile(fileUri) {
        console.log('file uri ', fileUri)
        return axios.get(this.getHost() + 'file/download/'+ encodeURIComponent(fileUri), {
            responseType: 'blob', // Assurez-vous que 'blob' est utilisé
          });
    }
}