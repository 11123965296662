import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Image } from 'primereact/image';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Toolbar } from 'primereact/toolbar';
import { TabView, TabPanel } from 'primereact/tabview';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import {EleveService} from '../../service/operations/EleveService';
import {BulletinsService} from "../../service/operations/BulletinsService";
import {RapportService} from "../../service/rapports/RapportService";

import { InputSwitch } from 'primereact/inputswitch';


import {Dropdown} from "primereact/dropdown";
import {Message} from "primereact/message";


const MiseAjourHeureAbsences = () => {
    let emptyBulletin = {
        ecoleId:null,
        libellePeriode:'',
        matricule:'',
        nom:'',
        prenoms:'',
        libelleClasse:'',
        anneeLibelle:'',
        heuresAbsJustifiees:'',
        heuresAbsNonJustifiees:''

    }
    let emptyInscrAvalider = {
        idEleveInscrit: null,
        inscriptionsidEleve:null,
        nomEleve :'' ,
        prenomEleve :'',
        matriculeEleve:'',
        Date_naissanceEleve :null,
        sexeEleve :'',
        contactEleve :null,
        inscriptions_statut_eleve :null,
        inscriptions_status:'EN_ATTENTE' ,
        inscriptions_processus:'EN_COURS',
        inscriptions_type : null,
        inscriptions_ecole_origine:'',
        inscriptions_classe_precedente:'',
        inscriptions_derniereclasse_religieuse:'',
        inscriptions_classe_actuelle:'',
        inscriptions_redoublant:'',
        inscriptions_langue_vivante:'',
        inscriptions_contact2:'',
        inscriptions_boursier:'',
        inscriptionsid:null,
        transfert:'',
        num_decision_affecte:''
    };
    let emptyeleve = {
        eleve_mail :'' ,
        eleve_numero_extrait_naiss :0,
        eleveadresse:'',
        elevecellulaire :'',
        elevecode :'',
        elevedate_etabli_extrait_naiss :null,
        elevedate_naissance :null,
        eleveid:null ,
        idTenant : '',
        eleveSexe : '',
        elevelieu_etabliss_etrait_naissance :'',
        elevelieu_naissance:'',
        elevenom:'',
        eleveprenom:'',
        elevematricule_national:'',
        parentList:[]
    };

    let emptyInscriptions = {
        inscriptionsid :null ,
        inscriptionscode :'',
        inscriptions_ecole_origine:'',
        inscriptions_classe_precedente :'',
        inscriptions_derniereclasse_religieuse :'',
        inscriptions_classe_actuelle :'',
        inscriptions_delete :false,
        inscriptions_status : '',
        inscriptions_redoublant:'',
        inscriptions_boursier:'',
        inscriptions_statut_eleve:'',
        inscriptions_type:'',
        inscriptionsdate_creation : null,
        inscriptionsdate_modification :null,
        identifiantEleve:null,
        identifiantEcole:0,
        identifiantAnnee_scolaire:0,

        identifiantNiveau:'' ,
        inscriptionBadge:'',
        tenant_tenantid:'',
        inscriptionLangueVivante:'',
        libellehandicap:[]
    };



    let emptyEtatInscription={
        etat_code:'',
        etat_libelle:''
    }

    let emptystatutEleve={
        statut_code:'',
        statut_libelle:''
    }
    let emptyAnneeScoliare= {
        annee_scolaireid: 1 ,
        annee_scolaire_libelle: '' ,
        annee_scolaire_code: '',
        annee_scolaire_visible:'',
        tenant_tenantid: ''
    } ;

    let emptyStatutEleve= {
        statuts_eleveid: null ,
        statuts_elevecode: '' ,
        statuts_elevelibelle: '',
        tenant_tenantid: ''
    } ;
    let empty = {
        id :null,
        code :'' ,
        libelle :''
    };

    let emptyNiveau= {
        niveauid: null ,
        niveaucode: '' ,
        niveaulibelle: '',
        tenant_tenantid: ''
    } ;
    let emptyNiveauEnsein = {
        id :null ,
        code :'',
        libelle:''
    };
    let emptyInfos = {
        code :'',
        libelle:''
    };
    let emptyTransfert = {
        code :'N',
        libelle:'NON'
    };
    const columns = [
        {field: 'inscriptions_status', header: 'Statut Inscription'},
        {field: 'inscriptionsidEleve', header: 'N°'},
        {field: 'nomEleve', header: 'Nom'},
        {field: 'prenomEleve', header: 'Prenom'},
        {field: 'matriculeEleve', header: 'Matricule'},
        {field: 'inscriptions_type', header: 'Type Inscription'}
    ];
    const [selectedColumns, setSelectedColumns] = useState(columns);

    const [filters2, setFilters2] = useState({
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'matricule':{ value: null, matchMode: FilterMatchMode.CONTAINS },
        'nom':{ value: null, matchMode: FilterMatchMode.CONTAINS },
        'prenoms':{ value: null, matchMode: FilterMatchMode.CONTAINS },
        'libelleClasse':  { value: null, matchMode: FilterMatchMode.CONTAINS },

    });
    const [Bulletin, setBulletin] = useState(emptyBulletin);
    const [Bulletins, setBulletins] = useState(null);
    const [emptyInfo, setEmptyInfo] = useState(emptyInfos);
    const [annee, setannee] = useState(empty);
    const [annees, setannees] = useState(null);
    const [periode, setPeriode] = useState(empty);
    const [periodes, setPeriodes] = useState(null);
    const [classe, setClasse] = useState(empty);
    const [classes, setClasses] = useState(null);
    const [infosMiseAjour, setInfosMiseAjour] = useState('');
    const [numDecisionAff, setNumDecisionAff] = useState('');
    const [ecole_origine, setEcole_origine] = useState('');
    const [absenceJustifie, setAbsenceJustifie] = useState('');
    const [absenceNonJustifie, setAbsenceNonJustifie] = useState('');
    const [derniereclasse_religieuse, setDerniereclasse_religieuse] = useState(emptyNiveau);
    const [classe_precedente, setClasse_precedente] = useState(emptyNiveau);
    const [classe_actuelle, setClasse_actuelle] = useState(emptyNiveau);
    const [redoublant, setRedoublant] = useState(emptyInfos);
    const [boursier, setBoursier] = useState(emptyInfos);
    const [transfert, setTransfert] = useState(emptyInfos);
    const [langue_vivante, setLangue_vivante] = useState(emptyInfos);
    const [contact2, setContact2] = useState('');
    const [checked, setChecked] = useState(null);

    const [idModif, setIdmodif] = useState(null);
    const [inscriAvalide, setainscriAvalide]  = useState(emptyInscrAvalider);
    const [inscriAvalides, setinscriAvalides]  = useState(null);
    const [anneeScolaires, setanneeScolaires]  = useState(null);
    const [anneeScolaire, setanneeScolaire]  = useState(emptyAnneeScoliare);

    const [niveaus, setniveaus]  = useState(null);
    const [niveau, setniveau]  = useState(emptyNiveau);

    const [etatInscription, setEtatInscription]  = useState(emptyEtatInscription);
    const [statutEleve, setstatutEleve]  = useState(emptystatutEleve);
    const [globalFilterValue2, setGlobalFilterValue2] = useState('');
    const [typeOperation, setTypeOperation]  = useState(null);
    const [anneeEncours, setanneeEncours]  = useState("2021-2022");
    const [typeOperation1, settypeOperation1]  = useState("INSCRIPTION");
    const [typeOperation2, settypeOperation2]  = useState("PREINSCRIPTION");
    const [tenant, settenant]  = useState(sessionStorage.getItem('EcoleID'));
    const [liseleveInscrits, setLiseleveInscrits]  = useState(null);
    const [eleves, setEleves]  = useState(null);
    const [filters1, setFilters1] = useState(null);
    const [productDialog, setProductDialog] = useState(false);
    const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
    const [eleve, setEleve]  = useState(emptyeleve);
    const [eleveCreate, setEleveCreate]  = useState(emptyeleve);
    const [inscription, setInscription]  = useState(emptyInscriptions);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const [files, setFiles] = useState([]);
    const [file1, setFile1] = useState('');
    const [niveauEnseignement, setNiveauEnseignement] = useState(emptyNiveauEnsein);
    const [niveauEnseignements, setNiveauEnseignements] = useState(null);
    const [selectedNiveau, setSelectedNiveau] = useState(null);
    const [selectedStatut, setSelectedStatut] = useState(null);
    const [selectedSexe, setSelectedSexe] = useState(null);
    const [selectedInscrStatut, setSelectedInscrStatut] = useState(null);
    const initFilters1 = () => {
        setFilters2({
            'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
            'prenomEleve':{ value: null, matchMode: FilterMatchMode.CONTAINS },
            'inscriptions_status':{ value: null, matchMode: FilterMatchMode.CONTAINS },
            'inscriptionsidEleve':{ value: null, matchMode: FilterMatchMode.CONTAINS },
            'nomEleve':  { value: null, matchMode: FilterMatchMode.CONTAINS },
           'matriculeEleve': { value: null, matchMode: FilterMatchMode.CONTAINS },
            'inscriptions_type': { value: null, matchMode: FilterMatchMode.CONTAINS  },
            'inscriptions_statut_eleve': { value: null, matchMode: FilterMatchMode.CONTAINS  },

        });
        setGlobalFilterValue2('');
    }
    const onGlobalFilterChange2 = (e) => {
        const value = e.target.value;
        let _filters2 = { ...filters2 };
        _filters2['global'].value = value;

        setFilters2(_filters2);
        setGlobalFilterValue2(value);
    }
    const clearFilter1 = () => {
        initFilters1();
    }


    useEffect(() => {
        const bull = new BulletinsService();
        if(idModif==1) {
            bull.listBulletinsGeneres(Bulletin.libelleClasse,tenant,Bulletin.anneeLibelle,Bulletin.libellePeriode)
                .then(data => {
                    setBulletins(data)
                    setIdmodif(0)
                });
        }

    }, [idModif]);

  useEffect(() => {
        const  rapp= new RapportService() ;
        rapp.getListClasseParEcole(tenant).then(data => setClasses(data));
        rapp.listPeriode().then(data => setPeriodes(data));
        rapp.listAnnee().then(data => setannees(data));
    }, []);

    const onLoadFile1Change = (e) => {
        setFile1(e.target.files);

        setFiles(current => [...current, e.target.files[0]]) ;
        console.log("Nom fichier+++ "+file1 ) ;
        //console.log("Nom fichier "+files[0].name) ;

        let file = e.target.files;

        let reader = new FileReader();

        /*reader.readAsText(file);
        reader.onload = function() {
            console.log(reader.result);
        };*/


    };

    const formatCurrency = (value) => {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }

    const openNew = () => {
        setEleve(emptyeleve);
        setSubmitted(false);
        setProductDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
    }

    const hideDeleteProductDialog = () => {
        setDeleteProductDialog(false);
    }

    const hideDeleteProductsDialog = () => {
        setDeleteProductsDialog(false);
    }

    const enregisterPhoto = () => {


        const eleveService = new EleveService();
        if(file1 ){
            const data = new FormData();
            for(let i = 0; i < files.length; i++) {
                data.append('file', files[i]);
            }

            eleveService.chargerPhoto(data,inscriAvalide.inscriptionsidEleve)
                .then((response) => {
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Photo chargée avec succès!', life: 3000 });
                })
                .catch((e) => {
                    // toast.error('Upload Error')
                })

            console.log("idmodif"+idModif) ;

            etatInscription.etat_code="EN_ATTENTE" ;
            //setProductDialog(false);

        } else {

            toast.current.show({ severity: 'error', summary: 'Error Message', detail: 'Veuillez Charger la photo', life: 3000 });
            //setProductDialog(false);
        }


    }
    const saveInfosComplementaire = () => {
        setSubmitted(true);

        const eleveService = new EleveService();
        inscriAvalide.inscriptions_classe_actuelle= classe_actuelle.niveaulibelle ;
        inscriAvalide.inscriptions_derniereclasse_religieuse= derniereclasse_religieuse.niveaulibelle ;
        inscriAvalide.inscriptions_classe_precedente = classe_actuelle.niveaulibelle ;
        inscriAvalide.inscriptions_langue_vivante=langue_vivante.libelle ;
        inscriAvalide.inscriptions_redoublant=redoublant.libelle;
        inscriAvalide.inscriptions_langue_vivante=langue_vivante.libelle ;
        inscriAvalide.inscriptions_ecole_origine = ecole_origine ;
        inscriAvalide.inscriptions_boursier=boursier.libelle;
        inscriAvalide.inscriptionsid= inscriAvalide.inscriptionsidEleve ;
        inscriAvalide.transfert= transfert.code ;
        inscriAvalide.num_decision_affecte= numDecisionAff ;
            eleveService.miseAjourInfosComplementaire(inscriAvalide)
                .then(data => {
                    console.log(data)

                });

            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'informations mises à jour', life: 3000 });
            setNumDecisionAff('');
            setTransfert(emptyInfos);
        setainscriAvalide(emptyInscrAvalider) ;


    }
    const rechercher = () => {
        const bull = new BulletinsService();
        bull.listBulletinsGeneres(classe.libelle,tenant,annee.libelle,periode.libelle)
            .then(data => {
                setBulletins(data)
                 });

    }
    const saveProduct = () => {
        setSubmitted(true);
        //setSubmitted(true);
        console.log("SOUMMMMMM")
        const bull = new BulletinsService() ;

        bull.miseAjourHeureAbsence(Bulletin.matricule,Bulletin.ecoleId,Bulletin.anneeLibelle,Bulletin.libellePeriode,absenceJustifie,absenceNonJustifie)
            .then(data => {
                console.log(data)
                setIdmodif(1)
            setAbsenceJustifie('');
                setAbsenceNonJustifie('')

            });

        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Mise à jour effectuée! ', life: 3000 });

        setProductDialog(false);


    }

    const editProduct = (product) => {

        setBulletin({ ...product });
          setProductDialog(true);
    }

    const confirmDeleteProduct = (product) => {
        setainscriAvalide(product);
        setDeleteProductDialog(true);
    }

    const deleteProduct = () => {
        let _eleves = eleves.filter(val => val.eleveid !== eleve.eleveid);
        setEleves(_eleves);
        const eleveService = new EleveService();
        eleveService.SupprimerEleve(eleve.eleveid,eleve)
            .then(data => setEleves(_eleves));

        setDeleteProductDialog(false);
        setEleve(emptyeleve);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'eleve a été supprimer', life: 3000 });
    }

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < eleves.length; i++) {
            if (eleves[i].eleveid === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    const createId = () => {
        let id = '';
        let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < 5; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return id;
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const confirmDeleteSelected = () => {
        setDeleteProductsDialog(true);
    }

    const deleteSelectedProducts = () => {
        let _eleves = eleves.filter(val => !selectedProducts.includes(val));
        setEleves(_eleves);
        setDeleteProductsDialog(false);
        setSelectedProducts(null);

        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Products Deleted', life: 3000 });
    }



    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _eleve = { ...eleve };
        _eleve[`${name}`] = val;

        setEleve(_eleve);
    }


    const EtatInscriptions = [
        {etat_code: 'EN_ATTENTE', etat_libelle: 'EN ATTENTE'},
        {etat_code: 'VALIDEE', etat_libelle: 'VALIDEE'}

    ];
    const Sexe = [
        {sexe_code: 'MASCULIN', sexe_libelle: 'MASCULIN'},
        {sexe_code: 'FEMININ', sexe_libelle: 'FEMININ'}

    ];

    const Boursiers = [
        {code: 'B', libelle: 'B'},
        {code: '1/2B', libelle: '1/2B'}

    ];

    const Transferts = [
        {code: 'O', libelle: 'OUI'},
        {code: 'N', libelle: 'NON'}

    ];
    const LV2S = [
        {code: 'ALL', libelle: 'ALL'},
        {code: 'ESP', libelle: 'ESP'}

    ];
    const Redoublants = [
        {code: 'RED', libelle: 'RED'},
        {code: 'NRED', libelle: 'NRED'}

    ];
    const Statuts = [
        {statut_code: 'AFFECTE', statut_libelle: 'AFFECTE'},
        {statut_code: 'NON_AFFECTE', statut_libelle: 'NON AFFECTE'}
    ];

    const InscriptionTypeOperation2 = [
        'PREINSCRIPTION', 'INSCRIPTION'
    ];

    const InscriptionStatut = [
        'EN ATTENTE', 'VALIDEE'
    ];

    const InscriptionTypeOperation = [
        {inscripStatuCode: 'INSCRIPTION', inscripStatulibelle: 'INSCRIPTION'},
        {inscripStatuCode: 'PREINSCRIPTION', inscripStatulibelle: 'PREINSCRIPTION'}
    ];

    const onNiveauChange = (e) => {
        setniveau(e.value);
           }

    const onInscripStatutChange = (e) => {
        setSelectedInscrStatut(e.value);
            }



    const onAnneeColaireChange = (e) => {
        setanneeScolaire(e.value);
    }


    const onStatutChange = (e) => {
        setstatutEleve(e.value);
            }

    const onSexeChange = (e) => {
        setSelectedSexe(e.value);

    }

    const statusBodyTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">Statut</span>
                {rowData.inscriptions_statut_eleve}
            </>
        );

    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Nouveau" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />

                </div>
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <FileUpload mode="basic" accept="image/!*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" />
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        )
    }
    const IdInscriptionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">N°</span>
                {rowData.inscriptionsidEleve}
            </>
        );
    }


    const NomBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">nom</span>
                {rowData.nom}
            </>
        );
    }

    const PreNomBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Prénoms</span>
                {rowData.prenoms}
            </>
        );
    }

    const ClasseBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Classe</span>
                {rowData.libelleClasse}
            </>
        );
    }

    const heureJustiBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Heures d'absences justifiées</span>
                {rowData.heuresAbsJustifiees}
            </>
        );
    }
    const heureNonJustiBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Heures d'absences non justifiées</span>
                {rowData.heuresAbsNonJustifiees}
            </>
        );
    }

    const matriculeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">matricule</span>
                {rowData.matriculeEleve}
            </>
        );
    }



    const statutEleveBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Statut</span>
                {rowData.inscriptions_statut_eleve}
            </>
        );
    }
    const statutInscriptionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Etat</span>
                {rowData.inscriptions_status}
            </>
        );
    }
    const brancheBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Branche</span>
                {rowData.brancheLibelle}
            </>
        );
    }
    const statusInscriptionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Statut Inscription</span>
                {rowData.inscriptions_status}
            </>
        )
    }
    const prenomBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">prénom</span>
                {rowData.prenoms}
            </>
        );
    }
    const NumInscrBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Matricule</span>
                {rowData.matricule}
            </>
        );
    }


    const sexeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Sexe</span>
                {rowData.sexeEleve}
            </>
        );
    }

    const onDernierClassChange = (e) => {
         setClasse_precedente(e.value);

    } ;

    const onDernierClassReligieuseChange = (e) => {
           setDerniereclasse_religieuse(e.value);

    } ;
    const onTransfertChange = (e) => {
        setTransfert(e.value);

    } ;
    const onBoursierChange = (e) => {
         setBoursier(e.value);

    } ;
    const onRedoublantChange = (e) => {
           setRedoublant(e.value);

    } ;

    const onLangChange = (e) => {
        setLangue_vivante(e.value);

    } ;
    const onClassActuelleChange = (e) => {
          setClasse_actuelle(e.value);

    } ;

    const contactBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Cellulaire</span>
                {rowData.contactEleve}
            </>
        );
    }

    const statutInscripBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Cellulaire</span>
                {rowData.inscriptions_status}
            </>
        )
    }


    const typeInscriptionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Cellulaire</span>
                {rowData.inscriptions_type}
            </>
        )
    }
    const productDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
        </>
    );
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editProduct(rowData)} />

            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Liste des élèves</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Rechercher..." />
            </span>
        </div>
    );
    const typeItemTemplate = (option) => {
        return <span className={`customer-badge status-${option}`}>{option}</span>;
    }

    const statusItemTemplate = (option) => {
        return <span className={`customer-badge status-${option}`}>{option}</span>;
    }
    const typeOperationFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={InscriptionTypeOperation2} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={typeItemTemplate} placeholder="Sélectionnez le type" className="p-column-filter" showClear />;
    }
    const onClasseChange = (e) => {
        setClasse(e.value);
    }
    const onPeriodeChange = (e) => {
        setPeriode(e.value);
    }
    const onAnneeChange = (e) => {
        setannee(e.value);
    }
    const statutFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={InscriptionStatut} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={statusItemTemplate} placeholder="Sélectionnez le Statut" className="p-column-filter" showClear />;
    }
 /*   const productDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
            <Button label="Charger la photo" icon="pi pi-check" className="p-button-text" onClick={enregisterPhoto} />
        </>
    );*/
    const deleteProductDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />
        </>
    );
    const deleteProductsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductsDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedProducts} />
        </>
    );
    const renderHeader2 = () => {
        return (
            <div className="flex justify-content-end">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue2} onChange={onGlobalFilterChange2} placeholder="Mots clés..." />
                </span>
            </div>
        )
    }

    const valider = () => {
        //setSubmitted(true);
console.log("SOUMMMMMM")
        const bull = new BulletinsService() ;

        bull.miseAjourHeureAbsence(Bulletin.matricule,Bulletin.ecoleId,Bulletin.anneeLibelle,Bulletin.libellePeriode,absenceJustifie,absenceNonJustifie)
            .then(data => {
                console.log(data)
                setIdmodif(1)

            });

        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Mise à jour effectuée! ', life: 3000 });

        setProductDialog(false);




    }

    const header2 = renderHeader2();
    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />


                    <div className="col-12">
                        <div className="card">
                            <div className="formgrid_ ">
                                <div className=" row align-items-center">
                                    <div className=" col-lg-4 col-md-6 col-12">
                                        <label htmlFor="classe">Classe</label>
                                        <Dropdown  id="dropdown"   options={classes} value={classe} onChange={onClasseChange} optionLabel="libelle"  >
                                        </Dropdown>
                                    </div>
                                    <div className=" col-lg-3 col-md-6 col-12">
                                        <label htmlFor="dropdown">Annee</label>
                                        <Dropdown  id="dropdown"   options={annees} value={annee} onChange={onAnneeChange} optionLabel="libelle"  >
                                        </Dropdown>

                                    </div>
                                    <div className=" col-lg-3 col-md-6 col-12">
                                        <label htmlFor="dropdown">Periode</label>
                                        <Dropdown  id="dropdown"   options={periodes} value={periode} onChange={onPeriodeChange} optionLabel="libelle"  >
                                        </Dropdown>
                                    </div>
                                    <div className=" col-lg-2 col-md-6 col-12">
                                        <label htmlFor="dropdown">&nbsp;</label>
                                        <Button label="Rechercher" className="mr-2 mb-2" onClick={() => rechercher()} ></Button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card">

                        <h5>Liste Bulletins générés</h5>

                        <DataTable value={Bulletins} paginator className="p-datatable-customers" rows={10} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                                   dataKey="matricule" filters={filters2} filterDisplay="row"  responsiveLayout="scroll"
                                   globalFilterFields={['matricule','nom', 'prenoms', 'libelleClasse']}  emptyMessage="Pas de bulletins générés pour cette classe">
                            <Column filterField="matricule" header="Matricule"  body={NumInscrBodyTemplate} headerStyle={{ width: '10%', minWidth: '10rem' }}  ></Column>

                            <Column filterField="nom" header="Nom"  body={NomBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }} style={{ minWidth: '15rem' }}  ></Column>

                            <Column filterField="prenoms" header="prénoms"  body={prenomBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }} style={{ minWidth: '30rem' }} ></Column>

                            <Column field="heuresAbsJustifiees" header="Heures absences justififiées" body={heureJustiBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }} style={{ minWidth: '10rem' }}

                            ></Column>

                            <Column field="heuresAbsNonJustifiees" header="Heures absences non justififiées" body={heureNonJustiBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }} style={{ minWidth: '10rem' }}

                            ></Column>

                            <Column field="libelleClasse" header="Classe" body={ClasseBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }} style={{ minWidth: '15rem' }}

                                     ></Column>




                            <Column body={actionBodyTemplate}></Column>
                        </DataTable>
                    </div>

                    <Dialog visible={productDialog} style={{ width: '650px' }} header="Details eleve" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>

                        <div className="formgrid grid">
                            <div className="field col">
                                <InputText value={Bulletin.matricule} disabled />
                            </div>
                            <div className="field col">
                                <InputText value={Bulletin.nom} disabled />
                            </div>
                            <div className="field col">
                                <InputText value={Bulletin.prenoms} disabled />
                            </div>

                        </div>



                        <div className="formgrid grid">
                            <div className="field col">
                                <label htmlFor="absJusti">Heure d'absences justifiées</label>
                                <InputText type="text" id="absJusti" value={absenceJustifie} onChange={(e) => setAbsenceJustifie(e.target.value)} />
                            </div>

                            <div className="field col">
                                <label htmlFor="absNonJusti">Heure d'absences non justifiées</label>
                                <InputText type="text" id="absNonJusti" value={absenceNonJustifie} onChange={(e) => setAbsenceNonJustifie(e.target.value)} />
                            </div>
                        </div>

                    </Dialog>

                    <Dialog visible={deleteProductDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {eleve && <span>Voulez vous vraiment supprimer <b>{eleve.elevematricule_national}</b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteProductsDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductsDialogFooter} onHide={hideDeleteProductsDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {eleve && <span>Voulez vous vraiment supprimer les elements selectionnés?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(MiseAjourHeureAbsences, comparisonFn);
