import React, { useState, useEffect, useRef } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import classNames from 'classnames';
import { Button } from 'primereact/button';
import { GroupeEvaluationService } from '../service/GroupeEvaluationService';
import { InputText } from 'primereact/inputtext';
import { Toolbar } from 'primereact/toolbar';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { BrancheService } from '../service/BrancheService';
import { PeriodeService } from '../service/PeriodeService';
import { EvaluationService } from '../service/EvaluationService';
import { Tag } from 'primereact/tag';
import { ClasseMatiereService } from '../service/ClasseMatiereService';

const GroupeEvaluations = () => {

  let emptyGroupe = {
    id: null,
    matiere: { id: null },
    niveau: { id: null },
    periode: { id: null },
    user: sessionStorage.getItem('idUser')
  }

  const [groupeEvaluations, setGroupeEvaluations] = useState([]);
  const [evaluations, setEvaluations] = useState([]);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [globalFilter2, setGlobalFilter2] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [submittedLib, setSubmittedLib] = useState(false);
  const [openNewDialog, setOpenNewDialog] = useState(false);
  const [groupe, setGroupe] = useState(emptyGroupe);
  const [lblBtnSave, setLblBtnSave] = useState('Enregistrer');
  //ici le niveau represente la branche
  const [niveaux, setNiveaux] = useState(null);
  const [periodes, setPeriodes] = useState(null);
  const [matieres, setMatieres] = useState(null);
  const [selectedEval, setSelectedEval] = useState(null);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [hiddenTag, setHiddenTag] = useState('hidden');
  const toast = useRef(null);
  const [disabledField, setDisabledField] = useState(false);

  // const toast = useRef(null);
  const dt = useRef(null);
  const dt2 = useRef(null);

  useEffect(() => {
    const groupeEvaluationService = new GroupeEvaluationService();
    const brancheService = new BrancheService();
    const periodeService = new PeriodeService();
    brancheService.getByNiveauEnseigment(sessionStorage.getItem('niveauEnseignementId')).then(resp => {
      setNiveaux(resp)
    }
    )
    groupeEvaluationService.getByEcole(sessionStorage.getItem('EcoleID'), sessionStorage.getItem('AnneEncours')).then(
      res => {
        console.log(res);
        setGroupeEvaluations(res);
      })
    periodeService.getByPeriodicite(sessionStorage.getItem('periodiciteId')).then(resp => setPeriodes(resp))

  }, []);

  const editEvaluation = (rowData) => {
    setSubmitted(false);
    setSubmittedLib(false);
    setOpenNewDialog(true);
    setLblBtnSave("Modifier");
    setDisabledField(true);
    const groupeEvaluationService = new GroupeEvaluationService();
    const evaluationService = new EvaluationService();
    groupeEvaluationService.getById(rowData.id).then(resp => {
      populateMatieres(resp.niveau.id);
      setSelectedEval(resp.evaluations);
      console.log('eval selected : ' + resp.evaluations.length)
      evaluationService.getEvalNonGroupe(resp.annee.id, resp.ecole.id, resp.niveau?.id, resp.matiere?.id, resp.periode?.id).then(
        rep => {
          console.log('evaluations trouvées' + rep.length);
          console.log([...resp.evaluations, ...rep])
          setEvaluations([...resp.evaluations, ...rep]);
        }
      )
      setGroupe(resp);
    })

  }

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" tooltip='Modifier' onClick={() => editEvaluation(rowData)} />
        <Button icon="pi pi-times" className="p-button-rounded p-button-danger mr-2" tooltip='Supprimer' onClick={() => openDeleteDialog(rowData)} />
      </div>
    );
  }

  const nbreBodyTemplate = (rowData) => {
    return (
      <div className='ml-6'>{rowData.nbreEvaluations}</div>
    );
  }

  const setLibelleGroup = (val) => {
    let _groupe = { ...groupe };
    _groupe.libelle = val;
    setGroupe(_groupe);
    console.log(_groupe);
  }

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h3 className="m-0">Liste des groupes d'évaluations</h3>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Recherche..." />
      </span>
    </div>
  );

  const headerEvaluation = (
    <div>
      <div className='formgroup-inline bg-white mb-2'>
        <div className="my-2 col-offset-3 col-6">
          <label htmlFor='libelleGroup'> Libellé du groupe<span style={{ color: 'red' }}>*</span></label>
          <InputText type='text' value={groupe?.libelle} id='libelleGroup' onChange={(e) => setLibelleGroup(e.target.value)} placeholder='Libellé du groupe' className={classNames({ 'p-invalid': submittedLib && !(groupe?.libelle) })} />
          {submittedLib && !(groupe?.libelle) && <small style={{ color: 'red' }} className="p-invalid">Le libellé du groupe est requis.</small>}
        </div>
        <div className={hiddenTag + ' my-2 col-offset-5 col-3'}>
          <Tag icon='pi pi-info-circle' rounded={true} severity='danger' className='px-5' value='Veuillez sélectionner au moins 2 évaluations'></Tag>
        </div>
      </div>
      <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
        <span className="block mt-2 md:mt-0 col-offset-7 p-input-icon-left">
          <i className="pi pi-search" />
          <InputText type="search" onInput={(e) => setGlobalFilter2(e.target.value)} placeholder="Recherche..." />
        </span>
      </div>
    </div>
  );

  const openNew = () => {
    setOpenNewDialog(true)
    setLblBtnSave("Enregistrer");
    setDisabledField(false);
  }

  const rightToolbarTemplate = () => {
    return (
      <div className="my-2">
        <Button label="Nouveau" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
      </div>
    )
  }

  const populateMatieres = (niveau) => {
    let brancheMatiere = new ClasseMatiereService();
    brancheMatiere.getByBranche(niveau, sessionStorage.getItem('EcoleID')).then(resp => {
      // console.log(resp);
      let _matieres = [];
      for (const cm of resp) {
        _matieres.push(cm.matiere);
      }
      setMatieres(_matieres)
    })
  }

  const onSelectChange = (e, key) => {
    const val = (e.target && e.target.value) || '';
    let _groupe = { ...groupe };
    _groupe[`${key}`].id = val;
    // console.log(_groupe);
    if (key === 'niveau') {
      populateMatieres(val)
    }
    setGroupe(_groupe);
  }

  const groupeDialogHeader = (
    <div className='surface-300 p-1'>
      <h4>{lblBtnSave} un groupe d&apos;&eacute;valuations</h4>
    </div>
  );

  const hideDialog = () => {
    setSubmitted(false);
    setOpenNewDialog(false);
    setGroupe(emptyGroupe);
    setSelectedEval(null);
    setEvaluations([]);
  }

  const save = () => {
    setSubmitted(true);
    setSubmittedLib(true);
    setHiddenTag('hidden');
    if (groupe.libelle != null && groupe.evaluations != null && groupe.evaluations.length >= 2) {
      const groupeEvaluationService = new GroupeEvaluationService();
      groupeEvaluationService.saveOrUpdate(groupe).then(resp => {
        let _groupes = [...groupeEvaluations];
        let flagSave = '';
        let index = reactfindIndexById(resp.id);
        console.log(index);
        console.log(resp)
        if (index === -1) {
          flagSave = ' crée';
          _groupes.push(resp);
        } else {
          flagSave = ' modifié';
          _groupes[index] = resp;
        }
        setGroupeEvaluations(_groupes);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Groupe d évaluations ' + flagSave, life: 5000 });
        hideDialog();
      }).catch((error) => {
        if (error.response) {
          console.log('erreur response- vie ecole' + error.response.status);
        } else if (error.request) {
          console.log('erreur request- vie ecole' + error.request);
        } else {
          console.log('Error autres', error.message);
        }
        toast.current.show({ severity: 'error', summary: 'erreur', detail: error.response.data.message, life: 10000 });
      });
      console.log('handlesave')
    } else if (groupe.evaluations == null || (groupe.evaluations != null && groupe.evaluations.length <= 1)) {
      console.log('Veuillez selectionner au moins deux (2) évaluations')
      setHiddenTag('');
    }

    console.log(groupe)
    hideConfirmDialog();
  }

  const deleteGroupe = () => {
    console.log(groupe);
    const groupeEvaluationService = new GroupeEvaluationService();
    groupeEvaluationService.deleteHandle(groupe.id).then(resp => {
      let index = reactfindIndexById(groupe.id);
      if (index !== -1) {
        let _groupe = [...groupeEvaluations];
        _groupe.splice(index, 1);
        setGroupeEvaluations(_groupe);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Groupe d évaluations supprimé', life: 5000 });
      }
    }).catch((error) => {
      if (error.response) {
        console.log('erreur response- vie ecole' + error.response.status);
      } else if (error.request) {
        console.log('erreur request- vie ecole' + error.request);
      } else {
        console.log('Error autres', error.message);
      }
      toast.current.show({ severity: 'error', summary: 'erreur', detail: error.response.data.message, life: 10000 });
    });
    hideDeleteDialog();
  }

  function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  function formatDate(date) {
    const [date1, time1] = date.split('T');
    if (date1) {

      date = new Date(date1);
      return [
        padTo2Digits(date.getDate()),
        padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
      ].join('/');
    } else
      return '';
  }
  const dateBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Date</span>
        {formatDate(rowData.date)}
      </>
    );
  }
  const idBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Type</span>
        {rowData.id}
      </>
    );
  }

  const typeBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Evaluation</span>
        {rowData.type != null ? rowData.type.libelle : ''}
      </>
    );
  }

  const periodeBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Période</span>
        {rowData.periode != null ? rowData.periode.libelle : ''}
      </>
    );
  }


  const classeBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Classe</span>
        {rowData.classe != null ? rowData.classe.libelle : ''}
      </>
    );
  }


  const matiereBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Matière</span>
        {rowData.matiereEcole != null ? rowData.matiereEcole.libelle : ''}
      </>
    );
  }

  const noteSurBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">noteSur</span>
        {rowData.noteSur}
      </>
    );
  }


  const search = () => {
    const evaluationService = new EvaluationService();
    console.log(sessionStorage.getItem('AnneEncours'))
    console.log(sessionStorage.getItem('EcoleID'))
    console.log(groupe.niveau?.id)
    console.log(groupe.matiere?.id)
    console.log(groupe.periode?.id)
    evaluationService.getEvalNonGroupe(sessionStorage.getItem('AnneEncours'), sessionStorage.getItem('EcoleID'), groupe.niveau?.id, groupe.matiere?.id, groupe.periode?.id).then(
      resp => {
        console.log(resp.length);
        setEvaluations(resp);
      }
    )
  }

  const hideConfirmDialog = () => {
    setConfirmDialog(false);
  }

  const hideDeleteDialog = () => {
    setDeleteDialog(false);
    setGroupe(emptyGroupe);
  }
  const openDeleteDialog = (rowData) => {
    setGroupe(rowData);
    setDeleteDialog(true);
  }

  const openConfirmDialog = () => {
    let _groupe = { ...groupe };
    _groupe.evaluations = selectedEval;
    _groupe.ecole = { id: sessionStorage.getItem('EcoleID') };
    _groupe.annee = { id: sessionStorage.getItem('AnneEncours') };
    setGroupe(_groupe);
    setConfirmDialog(true);

  }

  const reactfindIndexById = (id) => {
    let index = -1;
    for (let i = 0; i < groupeEvaluations.length; i++) {
      if (groupeEvaluations[i].id === id) {
        index = i;
        break;
      }
    }
    return index;
  }

  const groupeDialogFooter = (
    <>
      <Button label="Annuler" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
      <Button label={lblBtnSave} icon="pi pi-check" className="p-button-text" onClick={openConfirmDialog} />
    </>
  );
  const saveDialogFooter = (
    <>
      <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={hideConfirmDialog} />
      <Button label="oui" icon="pi pi-check" className="p-button-text" onClick={save} />
    </>
  );


  const deleteDialogFooter = (
    <>
      <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={hideDeleteDialog} />
      <Button label="oui" icon="pi pi-check" className="p-button-text" onClick={deleteGroupe} />
    </>
  );


  return (

    <div className="grid crud-demo">
      <div className="card col-12">
      <Toast ref={toast} />
        <Toolbar className="mb-4" right={rightToolbarTemplate} ></Toolbar>
        <DataTable ref={dt} value={groupeEvaluations}
          dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="affichage de {first} à {last} sur {totalRecords} groupes évaluations"
          globalFilter={globalFilter} emptyMessage="Aucun enregistrement trouvé." header={header} responsiveLayout="scroll">
          <Column header='#' style={{ width: '50px' }}></Column>
          <Column header="Libellé" field='libelle' headerStyle={{ Width: '5rem' }}></Column>
          <Column header="Nbre évaluations" body={nbreBodyTemplate} field='nbreEvaluations' headerStyle={{ Width: '1rem' }}></Column>
          <Column header='Action' body={actionBodyTemplate} headerStyle={{ Width: '3rem' }}></Column>
        </DataTable>
      </div>
      <div>
        <Dialog visible={openNewDialog} header={groupeDialogHeader} modal className="p-fluid col-9" footer={groupeDialogFooter} onHide={hideDialog} >

          <div className="formgroup-inline shadow-7">
            <div className="field col-3">
              <label htmlFor="niveau"><b>Niveau <span style={{ color: 'red' }}>*</span></b></label>
              <Dropdown id="niveau" disabled={disabledField} emptyMessage='Aucune donnée disponible' value={groupe?.niveau.id} onChange={(e) => onSelectChange(e, 'niveau')} options={niveaux} required optionValue="id" optionLabel="libelle" placeholder="Selectionner le niveau" className={classNames({ 'p-invalid': submitted && !(groupe?.niveau.id) })} />
              {submitted && !(groupe?.niveau.id) && <small className="p-invalid">Le niveau est requis.</small>}
            </div>

            <div className='field col-3'>
              <label htmlFor='matiere'><b>Matière <span style={{ color: 'red' }}>*</span></b></label>
              <Dropdown id='matiere' disabled={disabledField} emptyMessage='Aucune donnée disponible' value={groupe?.matiere.id} onChange={(e) => onSelectChange(e, 'matiere')} options={matieres} required optionValue="id" optionLabel="libelle" placeholder="Selectionner la matière" className={classNames({ 'p-invalid': submitted && !(groupe?.matiere.id) })}></Dropdown>
              {submitted && !groupe?.matiere.id && <small className="p-invalid">La matière est requise.</small>}
            </div>

            <div className="field col-3">
              <label htmlFor="periode"><b>P&eacute;riode <span style={{ color: 'red' }}>*</span></b></label>
              <Dropdown id="periode" disabled={disabledField} value={groupe?.periode.id} onChange={(e) => onSelectChange(e, 'periode')} options={periodes} required optionValue="id" optionLabel="libelle" placeholder="Selectionner la période" className={classNames({ 'p-invalid': submitted && !(groupe?.periode.id) })} />
              {submitted && !(groupe?.periode.id) && <small className="p-invalid">La période est requise.</small>}
            </div>

            <div className='field col-2'>
              <Button label="Rechercher" disabled={disabledField} icon="pi pi-search" className="p-button-success mt-3" onClick={search} />
            </div>
          </div>

          <div>
            <DataTable ref={dt2} value={evaluations} selection={selectedEval} onSelectionChange={(e) => setSelectedEval(e.value)}
              dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="affichage {first} de {last} sur {totalRecords} evaluations"
              globalFilter={globalFilter2} emptyMessage="Aucune évaluation trouvée." header={headerEvaluation} responsiveLayout="scroll">

              <Column selectionMode="multiple" headerStyle={{ minWidth: '2rem' }}></Column>
              <Column field="id" header="N°" sortable body={idBodyTemplate} headerStyle={{ minWidth: '3rem' }}></Column>
              <Column field="date" header="Date" sortable body={dateBodyTemplate} headerStyle={{ width: '10%', minWidth: '6rem' }}></Column>
              <Column field="type.libelle" header="Evaluation" sortable body={typeBodyTemplate} headerStyle={{ width: '14%', minWidth: '5rem' }}></Column>
              <Column field="classe.libelle" header="Classe" body={classeBodyTemplate} headerStyle={{ width: '14%', minWidth: '7rem' }}></Column>
              <Column field="matiereEcole.libelle" header="Matière" body={matiereBodyTemplate} sortable headerStyle={{ width: '14%', minWidth: '7rem' }}></Column>
              <Column field="periode.libelle" header="Periode" sortable body={periodeBodyTemplate} headerStyle={{ width: '14%', minWidth: '5rem' }}></Column>
              <Column field="noteSur" header="Noté sur" sortable body={noteSurBodyTemplate} headerStyle={{ width: '14%', minWidth: '5rem' }}></Column>

            </DataTable>
          </div>

        </Dialog>

        <Dialog visible={confirmDialog} style={{ width: '450px' }} header="Confirmation" modal footer={saveDialogFooter} onHide={hideConfirmDialog}>
          <div className="flex align-items-center justify-content-center">
            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
            {groupe && <span>Voulez-vous lier ce groupe d&apos;&eacute;valuations</span>}
          </div>
        </Dialog>

        <Dialog visible={deleteDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteDialogFooter} onHide={hideDeleteDialog}>
          <div className="flex align-items-center justify-content-center">
            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
            {groupe && <span>Etes vous sûr de vouloir supprimer le groupe d'évaluations <b>[ {groupe.libelle} ]</b>?</span>}
          </div>
        </Dialog>
      </div>
    </div>
  )
}
const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(GroupeEvaluations, comparisonFn);