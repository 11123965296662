import React, { useState, useEffect, useRef } from 'react';
// eslint-disable-next-line
// import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { useParams, useHistory } from 'react-router-dom';
import { Button } from 'primereact/button';

import { InputText } from 'primereact/inputtext';
import { NoteService } from '../../service/NoteService';
import { InputNumber } from 'primereact/inputnumber';
import { EvaluationService } from '../../service/EvaluationService';

import { InputSwitch } from 'primereact/inputswitch';
import { Tag } from 'primereact/tag';
import { PersonnelService } from '../../service/PersonnelService';
import { PersonnelMatiereClasseService } from '../../service/PersonnelMatiereClasseService';




const Notes = () => {
    const { evalCode } = useParams();

    const toast = useRef(null);

    const [notes, setNotes] = useState(null);

    const [globalFilter, setGlobalFilter] = useState(null);

    const dt = useRef(null);

    const [evaluation, setEvaluation] = useState(null);

    const [originalNotes, setOriginalNotes] = useState([]);

    const [user, setUser] = useState('');

    const [prof, setProf] = useState('');

    const [disableSaveBtn, setDisableSaveBtn] = useState(false);

    const [exportedDatas, setExportedDatas] = useState([]);

    const [locked, setLocked] = useState(true);

    const [dateLimite, setDateLimite] = useState('');

    const [submitted, setSubmitted] = useState(false);


    const history = useHistory();

    useEffect(() => {
        const noteService = new NoteService();
        const evaluationService = new EvaluationService();

        evaluationService.getByCode(evalCode).then(data => {
            setEvaluation(data);
            if (data)
                evaluationService.isLocked(data.id).then(resp => {
                    setLocked(resp?.isLocked == null ? true : resp?.isLocked);
                    setDateLimite(resp?.dateLimite);
                });
        });
        noteService.getListAboutEvaluation(evalCode).then(data => {
            // (ci -après) utiliser pour copier les infos d'origine par valeur pour effectuer les comparaisons de données ayant changé
            let exportDataList = [];
            for (let index = 0; index < data.length; index++) {
                originalNotes.push(Object.assign({}, data[index]));
                //Formatge des données pour l export en excel
                let exportData = {};
                exportData.matricule = data[index].classeEleve.inscription.eleve.matricule;
                exportData.nomPrenom = data[index].classeEleve.inscription.eleve.nom + ' ' + data[index].classeEleve.inscription.eleve.prenom;
                exportData.note = data[index].note;
                exportData.sur = data[index].evaluation.noteSur;
                exportData.pec = data[index].pec == 1 ? 'Oui' : 'Non';
                exportDataList.push(exportData);
            }
            setNotes(data);
            setExportedDatas(exportDataList);
            // console.log(exportDataList);
        });



    }, []);

    const setPecValue = (e, row) => {

        let index = reactfindIndexByMatricule(row.classeEleve.inscription.eleve.matricule);
        let notesCopy = [...notes];

        notesCopy[index].pec = e.value === true ? 1 : 0;
        setNotes(notesCopy);
        notes[index].statut = isUpdated(notesCopy[index]) ? 'M' : '';

    }

    const getIsPec = (rowData) => {
        let index = reactfindIndexByMatricule(rowData.classeEleve.inscription.eleve.matricule);
        // console.log(index+' - '+notes[index].pec+' = ' +(typeof(notes[index].pec) == 'undefined'));
        return (notes[index].pec == 0 || typeof (notes[index].pec) === 'undefined') ? false : true;
    }

    const isUpdated = (rowData) => {
        let index = reactfindIndexByMatricule(rowData.classeEleve.inscription.eleve.matricule);

        // console.log(!(originalNotes[index].pec == rowData.pec && originalNotes[index].note == rowData.note))
        // console.log('original note pec '+originalNotes[index].pec+'---- rowdata pec '+rowData.pec);
        // console.log('originalNotes note '+originalNotes[index].note+'-- rowData note '+rowData.note);

        return !(originalNotes[index].pec == rowData.pec && originalNotes[index].note == rowData.note);
    }

    const validateBeforeSave = async () => {
        for (const elmt of notes) {
            if (elmt.note == -1) {
                toast.current.show({ severity: 'error', summary: 'Validation', detail: "Veuillez vérifier la note de l'élève " +getEleveIdentity(elmt), life: 10000 })
                return 0;
            }
        }
    }

    const save = async () => {
        // console.log(notes);
        let flat = await validateBeforeSave(notes);
        if (flat == 0) {
            return 0
        }
        setDisableSaveBtn(true);
        const noteService = new NoteService();
        noteService.handleNotes(notes).then(resp => {
            // console.log(resp);
            let originalNotesCopy = [];
            for (let index = 0; index < resp.length; index++) {
                originalNotesCopy.push(Object.assign({}, resp[index]));
            }
            setOriginalNotes(originalNotesCopy);
            setNotes(resp);
            setDisableSaveBtn(false)
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Note(s) modifiée(s)', life: 3000 }
            )
        }).catch((error) => {
            if (error.response) {
                console.log('erreur response- vie ecole- notes - save' + error.response.status);
                setDisableSaveBtn(false)
            } else if (error.request) {
                console.log('erreur request- vie ecole' + error.request);
            } else {
                console.log('Error autres', error.message);
            }
            toast.current.show({ severity: 'error', summary: 'erreur', detail: 'Une erreur s est produite', life: 3000 });
        }
        );
    }

    const reactfindIndexByMatricule = (matricule) => {
        let index = -1;
        for (let i = 0; i < notes.length; i++) {
            if (notes[i].classeEleve.inscription.eleve.matricule === matricule) {
                index = i;
                break;
            }
        }

        return index;
    }

    function validateField(matricule) {

        let index = reactfindIndexByMatricule(matricule)
        if (notes[index].note == -1) {
            return 'p-invalid';
        } else {
            return '';
        }
    }

    const updateNote = (e, r) => {
        // console.log(e.value  || 0);
        console.log(e);
        console.log('continue')
        const val = e.value || 0;
        let index = reactfindIndexByMatricule(r.classeEleve.inscription.eleve.matricule)
        let notesCopy = [...notes];
        if (e.value != null && e.value > evaluation.noteSur) {
            // exit and setting red class for wrong validation around field
            console.log('reject')
            notesCopy[index].note = -1;
            notesCopy[index].pec = 0;
            notesCopy[index].className = 'p-invalid';
            setNotes(notesCopy);
            return 0;
        }
        notesCopy[index].note = val;
        notesCopy[index].pec = 1;
        //notesCopy[index].pec = 1;
        setNotes(notesCopy);
        notes[index].statut = isUpdated(r) ? 'M' : '';

        // isUpdated(r);
        // console.log(notes[index]);
    }


    const dateTemplate = (date) => {

        return (
            <strong style={{ textDecoration: 'line-through' }}>{date.day}/{date.month}/{date.fullYear}</strong>
        );

    }

    function padTo2Digits(num) {
        return num.toString().padStart(2, '0');
    }

    const formatDate = (date) => {
        // console.log(date);
        const [dateTmp] = date.split('T');
        if (dateTmp) {
            date = new Date(dateTmp);
            return [
                padTo2Digits(date.getDate()),
                padTo2Digits(date.getMonth() + 1),
                date.getFullYear(),
            ].join('/');
        } else
            return '';
    }

    function formatHeure(date) {
        const [dateTmp] = date?.split('Z');
        if (dateTmp) {
            date = new Date(dateTmp);
            // console.log(date);
            return [
                padTo2Digits(date.getHours()),
                padTo2Digits(date.getMinutes()),
            ].join(':');
        } else
            return '';
    }

    function formatDuree(duree) {
        const dureeTmp = duree ? duree?.split('-') : ['0', '00'];
        return dureeTmp[0] + 'h' + dureeTmp[1] + 'mn';
    }

    const getUserPersonnelInfo = (id) => {
        const personnelService = new PersonnelService();
        if (id) {
            personnelService.getByUserId(id, sessionStorage.getItem('EcoleID')).then(resp => {
                setUser(resp?.nom + ' ' + resp?.prenom);
            }
            );
        }
    }

    const getProfToEvaluation = (annee, classe, matiere) => {
        const persMatClasseService = new PersonnelMatiereClasseService();
        persMatClasseService.getProfesseurByClasseAndMatiere(annee, classe, matiere).then(resp =>
            setProf(resp?.personnel.nom + ' ' + resp?.personnel.prenom)
        )
    }

    const goToPrecedent = () => {
        console.log(evaluation);
        history.push({ pathname: "/home/professionnelle/evaluationsprof", state: evaluation });
    }


    const matriculeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Niveau</span>
                {rowData.classeEleve.inscription.eleve == null ? '' : rowData.classeEleve.inscription.eleve.matricule}
            </>
        );
    }

    const getEleveIdentity = (note) => {
        return note.classeEleve.inscription.eleve.matricule + ' ' +note.classeEleve.inscription.eleve.nom + ' ' + note.classeEleve.inscription.eleve.prenom
    }

    const eleveBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Eleve</span>
                {rowData.classeEleve.inscription.eleve == null ? '' : rowData.classeEleve.inscription.eleve.nom + ' ' + rowData.classeEleve.inscription.eleve.prenom}
            </>
        );
    }

    const noteBodyTemplate = (rowData) => {
        return (
            <div>
                <InputNumber showButtons='true' disabled={locked} mode="decimal" size={2} min={0} max={evaluation == null ? 0 : evaluation.noteSur} value={rowData.note}
                    minFractionDigits={2} maxFractionDigits={2} style={{ 'minWidth': '100px' }} className={validateField(rowData.classeEleve.inscription.eleve.matricule)}
                    onChange={(e) => updateNote(e, rowData)} />
                {/* <InputMask mask="99.99" slotChar="__.__" size={2} placeholder='__.__'value={rowData.note} onChange={(e) => updateNote(e, rowData)}></InputMask> */}
            </div>
        );
    }

    const pecBodyTemplate = (rowData) => {
        return (
            <div className='flex align-items-stretch'>
                <div >
                    <InputSwitch id={'ips_' + rowData.classeEleve.inscription.eleve.matricule} disabled={locked} checked={getIsPec(rowData)} onChange={(e) => setPecValue(e, rowData)} />
                </div>
                <div className='ml-2' style={{ width: '10px' }}>
                    <Tag value="M" icon="pi pi-save" className={'p-tag-rounded' + (isUpdated(rowData) ? '' : ' hidden')} severity='warning'></Tag>
                </div>
            </div>
        )
    }

    const exportPdf = () => {
        // import('jspdf').then(jsPDF => {
        //     import('../jspdf-autotable').then(() => {
        //         const doc = new jsPDF.default(0, 0);
        //         doc.autoTable(exportColumns, notes);
        //         doc.save('products.pdf');
        //     })
        // })
    }

    const exportExcel = () => {
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(exportedDatas);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            saveAsExcelFile(excelBuffer, 'notes');
        });
    }

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(FileSaver => {
            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let EXCEL_EXTENSION = '.xlsx';
            const data = new Blob([buffer], {
                type: EXCEL_TYPE
            });
            FileSaver.saveAs(data, fileName + '_export_' + (evaluation != null ? evaluation.classe.libelle.replace(" ", "_") : 'X') + '_' + new Date().getTime() + EXCEL_EXTENSION);
        });
    }

    const cols = [
        { field: 'classeEleve.inscription.eleve.matricule', header: 'Code' },
        { field: 'classeEleve.inscription.eleve.nom', header: 'Name' },
        { field: 'classeEleve.inscription.eleve.prenom', header: 'Name' },
        { field: 'note', header: 'Note' },
        { field: 'evaluation.noteSur', header: 'Sur' }
    ];

    const exportColumns = cols.map(col => ({ title: col.header, dataKey: col.field }));

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">

            <h3 className="m-0">Saisie des Notes de l'évaluation </h3>

            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Recherche..." />
                <Button type="button" icon="pi pi-file-excel" onClick={exportExcel} className="p-button-success mx-2" tooltip="Exporter en .xlsx" />
                {/* <Button type="button" icon="pi pi-file-pdf" onClick={exportPdf} className="p-button-danger mr-2" tooltip="pdf" /> */}
            </span>

        </div>
    );

    const setColorDateLimite = (date) => {
        let dateToCompare = new Date(date?.split('Z')[0]);
        let today = new Date()

        dateToCompare.setHours(0, 0, 0, 0);
        today.setHours(0, 0, 0, 0);

        if (dateToCompare.getTime() > today.getTime())
            return 'green';
        else if (dateToCompare.getTime() == today.getTime())
            return 'orange'
        else if (dateToCompare.getTime() < today.getTime())
            return 'red';
    }

    const noteHeader = (
        <div>
            <b>Note / {evaluation == null ? '' : evaluation.noteSur} </b>
        </div>
    );

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <div className="col-12">
                        <div className="formgroup-inline">
                            <div className="col-12">
                                <label><h3><b>Evaluation N° {evaluation != null ? evaluation.id : ''} du {evaluation != null ? formatDate(evaluation.date) : ''}</b></h3></label>
                            </div>
                        </div>
                        <div className='shadow-3 p-3'>
                            <div className="formgroup-inline">
                                <div className="col-4 mb-4 lg:col-4 lg:mb-0"><b>Classe :</b>  {evaluation != null ? evaluation.classe.libelle : ''}</div>
                                <div className="col-4 mb-4 lg:col-4 lg:mb-0"><b>Matière : </b>{evaluation != null ? evaluation.matiereEcole.code + ' - ' + evaluation.matiereEcole.libelle : ''}</div>
                                <div className="col-4"><b>Type : </b>{evaluation != null ? evaluation.type.libelle : ''}</div>
                                {/* <div className="col-4 mb-4 lg:col-4 lg:mb-0"><b>Professeur :</b> </div> */}
                            </div>
                            <div className="formgroup-inline">
                                <div className="col-4"><b>Période :</b> {evaluation != null ? evaluation.periode.libelle : ''}</div>
                                <div className="col-4"><b>Date de l'évaluation:</b> {evaluation != null ? formatDate(evaluation.date) : ''} </div>
                                <div className="col-4"><b>Heure :</b> {evaluation != null ? formatHeure(evaluation.date) : ''}</div>
                            </div>
                            <div className="formgroup-inline">
                                <div className="col-4"><b>Durée :</b> {evaluation != null ? formatDuree(evaluation.duree) : ''}</div>
                                <div className="col-4"><b>Professeur:</b> {evaluation != null ? getProfToEvaluation(evaluation?.annee?.id, evaluation?.classe?.id, evaluation?.matiereEcole?.id) : ''} {prof} </div>
                                <div className="col-4"><b></b> </div>
                            </div>
                            <div className="formgroup-inline">
                                <div className="col-4"><b>Date limite de saisie :<span style={{ color: setColorDateLimite(dateLimite) }}> {dateLimite == '' ? 'N/A' : formatDate(dateLimite)}</span></b></div>
                                <div className="col-4"><b>Date de création :</b> {evaluation ? formatDate(evaluation?.dateCreation.split('Z')[0]) : ''}</div>
                                <div className="col-4"><b>Par :</b> {evaluation ? getUserPersonnelInfo(evaluation.user) : 'N/A'} {user}</div>
                            </div>
                        </div>
                        <div className="formgroup-inline col-12">
                            <div className="col-6">
                                {/* <a href='/#/home/professionnelle/evaluationsprof'> */}
                                <Button label="Précedent" icon="pi pi-arrow-circle-left" className="p-button-warning mr-2" onClick={() => goToPrecedent()} />
                                {/* </a> */}
                            </div>
                            <div className="col-6">
                                <Button label="Enregistrer" disabled={disableSaveBtn} icon="pi pi-save" className="p-button-success lg:col-offset-6 xl:col-offset-9 mr-2" onClick={save} />
                            </div>
                        </div>
                    </div>
                    <Toast ref={toast} />

                    {/* <DataTable value={recapData} emptyMessage="Aucune donnée" paginator rows={10} alwaysShowPaginator={false} responsiveLayout="scroll"
                    selectionMode="multiple" selection={selectedImportedData} onSelectionChange={onImportSelectionChange}> */}


                    <DataTable ref={dt} value={notes}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive_"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="affichage de {first} à {last} sur {totalRecords} notes"
                        globalFilter={globalFilter} emptyMessage="Aucun enregistrement trouvé." header={header} responsiveLayout="scroll">
                        <Column header="Matricule" body={matriculeBodyTemplate} headerStyle={{ Width: '2rem' }}></Column>
                        <Column header="Elève" body={eleveBodyTemplate} headerStyle={{ minWidth: '5rem' }}></Column>
                        <Column header={noteHeader} body={noteBodyTemplate} headerStyle={{ Width: '20rem' }}></Column>
                        <Column header="Pec" body={pecBodyTemplate} headerStyle={{ Width: '5rem' }}></Column>
                    </DataTable>

                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Notes, comparisonFn);
