import React, {useState, useEffect, useRef} from 'react';
import {InputText} from 'primereact/inputtext';
import {InputTextarea} from 'primereact/inputtextarea';
import {InputMask} from 'primereact/inputmask';
import {InputNumber} from 'primereact/inputnumber';
import {AutoComplete} from 'primereact/autocomplete';
import {Calendar} from 'primereact/calendar';
import {Chips} from 'primereact/chips';
import {Dropdown} from 'primereact/dropdown';
import {MultiSelect} from 'primereact/multiselect';
import {RapportService} from "../../service/rapports/RapportService";
import {Tooltip} from "primereact/tooltip";
import {Button} from "primereact/button";
import {SouscriptionService} from "../../service/operations/SouscriptionService";
import {ProgressSpinner} from "primereact/progressspinner";
import {ProgressBar} from "primereact/progressbar";
import { Toast } from 'primereact/toast';
import {InputSwitch} from "primereact/inputswitch";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import {FilterMatchMode} from "primereact/api";
import {Panel} from "primereact/panel";


const ImprimerRapports = () => {

    let emptyAnne = {
        id :null,
        code :'' ,
        libelle :''
    };
    let empty = {
        id :null,
        code :'' ,
        libelle :''
    };
    let niveauMat = {
        niveau:''
    };

    let emptyRapport = {
        code :null,
        nom :'' ,
        libelle_Fonction :'',
        descriptions:''
    };
    const toast = useRef(null);
    const [tenant, settenant]  = useState(sessionStorage.getItem('EcoleID'));
    const [niveauEnseign, setNiveauEnseign]  = useState(sessionStorage.getItem('niveauEnseignementId'));
    const [annee, setannee] = useState(empty);
    const [baseUri, setbaseUri] = useState(window.$baseUri);
    const [annees, setannees] = useState(null);
    const [periode, setPeriode] = useState(empty);
    const [periodes, setPeriodes] = useState(null);
    const [classe, setClasse] = useState(empty);
    const [classes, setClasses] = useState(null);
    const [codeEcole, setCodeEcole] = useState('01');
    const [matricule, setmatricule] = useState('');
    const [niveauMatricule, setNiveauMatricule] = useState();
    const [Listmatricule, setListmatricule] = useState([]);
    const [spin, setSpin] = useState(null);
    const [value, setValue] = useState(0);
    const interval = useRef(null);
    const [loading, setLoading] = useState(false);

    const [isNotSpinning1, setIsNotSpinning1] = useState('hidden');
    const [isNotSpinning2, setIsNotSpinning2] = useState('hidden');
    const [isNotSpinning3, setIsNotSpinning3] = useState('hidden');
    const [isNotSpinning4, setIsNotSpinning4] = useState('hidden');
    const [isNotSpinning5, setIsNotSpinning5] = useState('hidden');
    const [isNotSpinning6, setIsNotSpinning6] = useState('hidden');
    const [isNotSpinning7, setIsNotSpinning7] = useState('hidden');
    const [isNotSpinning8, setIsNotSpinning8] = useState('hidden');
    const [checked, setChecked] = useState(false);
    const [compress, setCompress] = useState(true);
    const [positionLog, setPositionLog] = useState(true);
    const [filigramme, setFiligramme] = useState(true);
    const [rapport, setRapport] = useState(emptyRapport);
    const [rapports, setRapports] = useState(null);
    const [isNotSpinning, setIsNotSpinning] = useState('hidden');
    const [globalFilterValue2, setGlobalFilterValue2] = useState('');
    const [filters2, setFilters2] = useState({
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'code':{ value: null, matchMode: FilterMatchMode.CONTAINS },
        'nom':{ value: null, matchMode: FilterMatchMode.CONTAINS },
        'description':{ value: null, matchMode: FilterMatchMode.CONTAINS }
    });



    useEffect(() => {
        const  rapp= new RapportService() ;
        rapp.getListClasseParEcole(tenant).then(data => setClasses(data));
        rapp.listPeriode().then(data => setPeriodes(data));
        rapp.listAnnee(tenant).then(data => setannees(data));
        rapp.listRapports(niveauEnseign).then(data => setRapports(data));
    }, []);


    const functions = {
        R05 :() => {
            const rapportService = new RapportService();
            setIsNotSpinning('')
            fetch(baseUri+"imprimer-rapport/pouls-rapport/"+tenant+"/docx/"+annee.libelle+"/"+periode.libelle+"/"+annee.id)
                .then(response => {
                    response.blob().then(blob => {
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement('a');
                        a.href = url;
                        a.download = 'rapport-trimestrielle'+'_'+'_'+periode.libelle+'.docx';
                        a.click();
                    });
                    setIsNotSpinning('hidden')
                });


        },
        R02 : () => {
            setIsNotSpinning('');

            fetch(baseUri+"imprimer-bulletin-list/spider-bulletin-matricule/"+tenant+"/"+periode.libelle+"/"+annee.libelle+"/"+classe.id+"/"+matricule+"/"+compress+"/"+niveauEnseign +"/"+positionLog+"/"+filigramme)
                .then(response => {
                    response.blob().then(blob => {
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement('a');
                        a.href = url;
                        a.download = 'Bulletin'+'_'+matricule+'_'+periode.libelle+'.pdf';
                        a.click();
                    });
                    setIsNotSpinning('hidden');
                    //window.location.href = response.url;
                });

        } ,
        R08 : () => {
            setIsNotSpinning('');

            fetch(baseUri+"livret-scolaire/livret-scolaire/"+matricule+"/"+tenant+"/"+annee.libelle+"/Troisième Trimestre")
                .then(response => {
                    response.blob().then(blob => {
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement('a');
                        a.href = url;
                        a.download = 'livret-scolaire'+'_'+matricule+'_'+'.pdf';
                        a.click();
                    });
                    setIsNotSpinning('hidden');
                    //window.location.href = response.url;
                });

        } ,
         R01 : () => {
            const rapportService = new RapportService();
            setIsNotSpinning('');

            fetch(baseUri+"imprimer-bulletin-list/spider-bulletin/"+tenant+"/"+periode.libelle+"/"+annee.libelle+"/"+classe.id+"/"+compress+"/"+niveauEnseign+"/"+positionLog+"/"+filigramme)
                .then(response => {
                    response.blob().then(blob => {
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement('a');
                        a.href = url;
                        a.download = 'Bulletin'+'_'+periode.libelle+'.pdf';
                        a.click();
                        setIsNotSpinning('hidden');
                    });

                    //window.location.href = response.url;
                });

        } ,
        R03 : () => {

            const rapportService = new RapportService();
            setIsNotSpinning('')
            fetch(baseUri+"imprimer-rapport-dsps/pouls-rapport-dsps/"+tenant+"/"+periode.libelle+"/"+annee.libelle)
                .then(response => {
                    response.blob().then(blob => {
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement('a');
                        a.href = url;
                        a.download = 'fichier_dspd'+'_'+'_'+periode.libelle+'.xls';
                        a.click();
                    });
                    setIsNotSpinning('hidden')
                });


        } ,
        R04 : () => {

            const rapportService = new RapportService();
            setIsNotSpinning('')
            fetch(baseUri+"imprimer-rapport-cio/pouls-rapport-cio/"+tenant+"/"+annee.libelle+"/"+periode.libelle)
                .then(response => {
                    response.blob().then(blob => {
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement('a');
                        a.href = url;
                        a.download = 'rapport cio-trimestrielle'+'_'+'_'+periode.libelle+'.xls';
                        a.click();
                    });
                    setIsNotSpinning('hidden')
                });


        } ,

         R06 : () => {
            const rapportService = new RapportService();
            setIsNotSpinning('')
            fetch(baseUri+"imprimer-rapport-annuelle/pouls-rapport/"+tenant+"/docx/"+annee.libelle+"/Troisième Trimestre")
                .then(response => {
                    response.blob().then(blob => {
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement('a');
                        a.href = url;
                        a.download = 'rapport-annuelle'+'_'+'_'+periode.libelle+'.docx';
                        a.click();
                    });
                    setIsNotSpinning('hidden')
                });
        } ,
         R07 : () => {
            const rapportService = new RapportService();
            setIsNotSpinning('');

            rapportService.matriculeParClasse(tenant,classe.libelle,periode.libelle)
                .then(data => {
                    console.log(data)
                    setListmatricule(data)
                    if(data.length<0){
                        toast.current.show({ severity: 'error', summary: 'Error Message', detail: 'Veuillez Valider ou annuler', life: 3000 });
                    } else {
                        let k= data.length -1 ;
                        for (let i = 0; i < data.length; i++) {
                            fetch(baseUri+"livret-scolaire/livret-scolaire/"+data[i].niveau+"/"+tenant+"/"+annee.libelle+"/Troisième Trimestre")
                                .then(response => {
                                    response.blob().then(blob => {
                                        let url = window.URL.createObjectURL(blob);
                                        let a = document.createElement('a');
                                        a.href = url;
                                        a.download = 'livret-scolaire'+'_'+data[i].niveau+'_'+'.pdf';
                                        a.click();
                                        if(k==i){
                                            setIsNotSpinning('hidden');
                                        }

                                    });

                                    //window.location.href = response.url;
                                });
                        }
                    }

                });

        }


    };







    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">

                <Button icon="pi pi-file" className="p-button-rounded p-button-success mr-2" onClick={() => editFile(rowData)} />
            </div>
        );
    }
    const editFile = (file) => {
        setRapport({ ...file });

        const dynamicFunctionName = file.code ;
        console.log("FonctionName "+dynamicFunctionName) ;
        const dynamicFunction = functions[dynamicFunctionName];
        dynamicFunction();
    }
    const onGlobalFilterChange2 = (e) => {
        const value = e.target.value;
        let _filters2 = { ...filters2 };
        _filters2['global'].value = value;

        setFilters2(_filters2);
        setGlobalFilterValue2(value);
    }




    const onClasseChange = (e) => {
        setClasse(e.value);
    }
    const onPeriodeChange = (e) => {
        setPeriode(e.value);
    }
    const onAnneeChange = (e) => {
        setannee(e.value);
    }
    const renderHeader2 = () => {
        return (
            <div className="flex justify-content-end">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue2} onChange={onGlobalFilterChange2} placeholder="Mots clés..." />
                </span>
            </div>
        )
    }
    const header2 = renderHeader2();
    return (
        <div className="grid">
            <div className="col-12" >
            <div className="card">
                <Panel header="Paramètres obligatoires" >
                    <p className="m-0">
                <div className="formgrid grid">
                    <div className="field col">
                        <h8>Année</h8>
                        <Dropdown  id="dropdown"   options={annees} value={annee} onChange={onAnneeChange} optionLabel="libelle"  />
                    </div>
                    <div className="field col">
                        <h8>Période</h8>
                        <Dropdown  id="dropdown"   options={periodes} value={periode} onChange={onPeriodeChange} optionLabel="libelle"  />
                    </div>


                </div>
                    </p>
                </Panel>
                <Panel header="Paramètres facultatifs" >
                    <p className="m-0">
                <div className="formgrid grid">
                    <div className="field col">
                        <h8>Classe</h8>
                        <Dropdown  id="dropdown"   options={classes} value={classe} onChange={onClasseChange} optionLabel="libelle"  />
                    </div>
                    <div className="field col">
                        <h8>Matricule élève</h8>
                        <InputText id="matricule" type="text" value={matricule} onChange={(e) => setmatricule(e.target.value)}/>
                    </div>
                    <div className="field col">
                        <h8>Format Decompresser(Bulletin)</h8>
                        <InputSwitch checked={compress} onChange={(e) => setCompress(e.value)} />
                    </div>

                    <div className="field col">
                        <h8>Supprimer Filigramme(Bulletin)</h8>
                        <InputSwitch checked={filigramme} onChange={(e) => setFiligramme(e.value)} />
                    </div>

                    <div className="field col">
                        <h8>Changer la position du logo(Bulletin)</h8>
                        <InputSwitch checked={positionLog} onChange={(e) => setPositionLog(e.value)} />
                    </div>
                </div>
                    </p>

                </Panel>


                </div>
            </div>

            <div className="col-12" >
                <div className="card">
                    <div className="flex justify-content-center align-items-center mb-4 gap-2">
                        <ProgressSpinner className={isNotSpinning}  style={{width: '50px', height: '50px'}}  strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                    </div>

                    <DataTable value={rapports} paginator className="p-datatable-customers" rows={10} header={header2} globalFilterFields={['code','nom', 'description']} filters={filters2} filterDisplay="row" >
                        <Column field="code" header="Code"></Column>
                        <Column field="nom" header="Nom du rapport"></Column>
                        <Column field="description" header="Description"></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>
                </div>
            </div>

        </div>


    )
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(ImprimerRapports, comparisonFn);
