import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { ClasseService } from '../service/ClasseService';
import { SeanceService } from '../service/SeanceService';
import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';

const OpenSeance = () => {

    let emptyClasse = { id: null, libelle: '' };
    let emptyPersonnel = { id: null, nom: '', prenom: '' }
    let emptySeance = {
        id: null,
        libelle: '',
        professeur: emptyPersonnel,
        surveillant: emptyPersonnel
    };

    const [date, setDate] = useState(null);

    const [dateF, setDateF] = useState(null);

    const [seances, setSeances] = useState(null);

    const [seance, setSeance] = useState(emptySeance);

    const [checked, setChecked] = useState(false);

    const [checkedF, setCheckedF] = useState(false);

    const [classes, setClasses] = useState([]);

    const [classe, setClasse] = useState(emptyClasse);

    const [classeF, setClasseF] = useState(emptyClasse);

    const [disabledLabel, setDisabledLabel] = useState('text-500');

    const [disabledLabelF, setDisabledLabelF] = useState('text-500');

    const [submitted, setSubmitted] = useState(null);

    const [seanceDialog, setSeanceDialog] = useState(false);

    const [genDialog, setGenDialog] = useState(false);

    const [headerTitle, setHeaderTitle] = useState("");

    const [displaySpinner, setDisplaySpinner] = useState("hidden");

    const [displayMessage, setDisplayMessage] = useState(false);

    const [messages, setMessages] = useState([]);

    const [disabledGenBtn, setDisabledGenBtn] = useState(false);

    const [seanceDetail, setSeanceDetail] = useState([]);

    const [detailDialog, setDetailDialog] = useState(false);

    const toast = useRef(null);
    const dt = useRef(null);

    const today = new Date();

    useEffect(() => {
        const classeService = new ClasseService();
        classeService.getSortedListByEcole(sessionStorage.getItem("EcoleID")).then(resp => setClasses(resp));

    }, [])

    useEffect(() => {
        seanceDetail.sort((a, b) => (a.heureDeb.split(':')[0] - b.heureDeb.split(':')[0]));

    }, [seanceDetail]);

    const onClasseChange = (e) => {
        let val = e.target.value;
        console.log(val);
        let _classe = { ...classe };
        _classe.id = val;
        setClasse(_classe);
    }

    const onClasseChangeF = (e) => {
        let val = e.target.value;
        console.log(val);
        let _classe = { ...classeF };
        _classe.id = val;
        setClasseF(_classe);
    }

    const onCheckedButton = (e) => {
        setChecked(e.checked);
        classe.id = null;
        if (e.checked)
            setDisabledLabel('text-700');
        else
            setDisabledLabel('text-500');
    }

    const onCheckedButtonF = (e) => {
        setCheckedF(e.checked);
        classeF.id = null;
        if (e.checkedF)
            setDisabledLabelF('text-700');
        else
            setDisabledLabelF('text-500');
    }

    function padTo2Digits(num) {
        return num.toString().padStart(2, '0');
    }

    function formatDate(date) {
        const [date1, time1] = date.split('T');
        if (date1) {

            date = new Date(date1);
            return [
                padTo2Digits(date.getDate()),
                padTo2Digits(date.getMonth() + 1),
                date.getFullYear(),
            ].join('/');
        } else
            return '';
    }

    const search = () => {
        setSubmitted(true);
        if (date) {
            const seancesService = new SeanceService();
            if (classe.id) {
                // console.log(Date.parse(date));
                seancesService.getDistinctListByDateAndClasse(date.toISOString().substring(0, 10).toString(), classe.id).then((resp) => setSeances(resp));
            }
            else {
                // console.log(JSON.stringify(date));
                // console.log(date.toISOString().substring(0, 10).toString());
                seancesService.getDistinctListByDate(date.toISOString().substring(0, 10).toString(), sessionStorage.getItem('EcoleID')).then((resp) => setSeances(resp));
            }
            setSubmitted(false);
        }
    }

    const openSeanceDialog = () => {
        setSeanceDialog(true);
    }

    const openDetailDialog = (rowData) => {
        setDetailDialog(true);
        displayDetail(rowData);
    }

    const seanceDialogFooter = (
        <>
            <Button label="Fermer" icon="pi pi-times" className="p-button-text" onClick={() => hideDialog()} />

        </>
    );
    const hideDialog = () => {
        setSubmitted(false);
        setSeanceDialog(false);
        setSeance(emptySeance);
        setDateF(null);

    }

    const generer = () => {
        setDisplaySpinner("");
        setDisabledGenBtn(true);
        setGenDialog(false);

        const seancesService = new SeanceService();
        seancesService.generateSeances(dateF.toISOString().substring(0, 10).toString(), classeF.id, sessionStorage.getItem('AnneEncours')).then((resp => {
            setMessages(resp);
            setDisplaySpinner("hidden");
            // console.log(resp);
            setDisplayMessage(true);
            setDisabledGenBtn(false);
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Séances générées ', life: 3000 });
        })).catch((error) => {
            if (error.response) {
                console.log('erreur response- vie ecole- seances - save' + error.response.status);
            } else if (error.request) {
                console.log('erreur request- vie ecole' + error.request);
            } else {
                console.log('Error autres', error.message);
            }
            setDisplaySpinner("hidden");

            setDisabledGenBtn(false);
            toast.current.show({ severity: 'error', summary: 'erreur', detail: 'Une erreur s est produite', life: 3000 });
        }
        );

    }

    const hideMessageDialog = () => {
        setDisplayMessage(false);
        setMessages([]);
    }

    const hideDetailDialog = () => {
        setDetailDialog(false);
        setSeanceDetail([]);
    }


    const displayDetail = (rowData) => {
        const seancesService = new SeanceService();
        const [date, time] = rowData[3].split('T');
        console.log(date);
        seancesService.getListByDateAndClasseAndStatut(date, rowData[0], rowData[2]).then((resp) => setSeanceDetail(resp));

    }

    const genDialogFooter = (
        <>
            <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={() => setGenDialog(false)} />
            <Button label="oui" icon="pi pi-check" className="p-button-text" onClick={() => generer()} />
        </>
    );

    const header = () => {
        return (
            <>
                <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                    <h3 className="m-0">Liste des séances ouvertes</h3>
                    <span className="block mt-2 md:mt-0 p-input-icon-left"></span>
                    <Button label="Ouvrir Séance" className="p-button-info w-2" onClick={() => openSeanceDialog()} />
                </div>
            </>
        );
    }

    const actionBody = (rowData) => {
        return (
            <>
                <Button icon="pi pi-list" tooltip='Detail' className="p-button-rounded p-button-primary" onClick={() => { openDetailDialog(rowData) }}></Button>
                <Button icon="pi pi-trash" disabled={true} className="p-button-rounded ml-3 p-button-danger" onClick={() => { console.log('suppression') }} />

            </>
        )
    }

    const actionDetailBody = (rowData) => {
        return (
            <>
                <Button icon="pi pi-pencil" disabled={true} tooltip='Modifier' className="p-button-rounded p-button-warning" onClick={() => { console.log('edit') }}></Button>
                <Button icon="pi pi-trash" disabled={true} className="p-button-rounded ml-3 p-button-danger" onClick={() => { console.log('suppression') }} />

            </>
        )
    }

    const bodyDate = (rowData) => {

        return (
            <>
                {/* {formatDate(rowData.dateSeance)} */}
                {formatDate(rowData[3])}
            </>
        )
    }

    const dateSeanceBody = (rowData) => {

        return (
            <>
                {formatDate(rowData.dateSeance)}

            </>
        )
    }

    const bodyClasseLibelle = (rowData) => {
        return (
            <>
                {rowData[1]}
            </>
        )
    }

    const bodyIdentiteProf = (rowData) => {
        return (
            <>
                {rowData.professeur ? rowData.professeur.nom : ''} {rowData.professeur ? rowData.professeur.prenom : ''}
            </>
        )
    }

    const bodyIdentiteSurv = (rowData) => {
        return (
            <>
                {rowData.surveillant ? rowData.surveillant.nom : ''} {rowData.surveillant ? rowData.surveillant.prenom : ''}
            </>
        )
    }

    const bodyStatut = (rowData) => {
        return (
            <>
                {rowData[2]}
            </>
        )
    }

    return (
        <div>
            <Toast ref={toast} />
            <div className='card'>
                <div className='grid'>
                    <div className='col-11 m-3 h-8rem grid'>
                        <div className="field m-1 col-4">
                            <label htmlFor='date'>Date <span className='text-red-700'>*</span></label>
                            <Calendar id="date" value={date} dateFormat="dd-mm-yy" showIcon onChange={(e) => setDate(e.value)} className={classNames({ 'p-invalid': submitted && !date })} />
                            {submitted && !date && <small className="p-invalid">Entrer la date</small>}
                        </div>
                        <div className='mt-3 m-1 col-1'>
                            <Checkbox inputId="binary" className='left-50' checked={checked} onChange={e => onCheckedButton(e)} />
                        </div>
                        <div className="field m-1 col-3">
                            <label className={disabledLabel} htmlFor='classe'>Classe</label>
                            <Dropdown id="classe" disabled={!checked} value={classe.id} options={classes} placeholder="Selectionner la classe" optionValue="id" optionLabel="libelle" onChange={(e) => onClasseChange(e)} />
                        </div>
                        <div className='col-3'>
                            <Button label='Rechercher' icon="pi pi-search" className="p-button-success m-1" onClick={() => search()}></Button>
                        </div>
                    </div>

                </div>
            </div>

            <DataTable value={seances} stripedRows header={header} responsiveLayout="scroll" emptyMessage="Aucun enregistrement trouvé">
                <Column field="dateSeance" body={bodyDate} header="Date"></Column>
                <Column field="classe.libelle" body={bodyClasseLibelle} header="Classe"></Column>
                <Column field="statut" body={bodyStatut} header="Statut"></Column>
                <Column header="Action" body={actionBody}>  </Column>
            </DataTable>

            <Dialog visible={seanceDialog} style={{ width: '60%' }} header={headerTitle} modal className="p-fluid" footer={seanceDialogFooter} onHide={hideDialog}>

                <div className='grid'>
                    <div className='surface-700 col-12'>
                        <h3 className='ml-3 pt-4'><span className='text-white'>OUVERTURE DE SEANCES</span></h3>
                    </div>

                    <div className='col-12 surface-200'>
                        <div className='grid col-12 my-3 h-9rem '>
                            <div className="m-1 col-3">
                                <label htmlFor='dateF'>Date <span className='text-red-700'>*</span></label>
                                <Calendar id="dateF" value={dateF} dateFormat="dd-mm-yy" minDate={today} showIcon onChange={(e) => setDateF(e.value)} className={classNames({ 'p-invalid': submitted && !dateF })} />
                                {submitted && !dateF && <small className="p-invalid">Entrer la date</small>}
                            </div>

                            <div className="grid m-1 col-4 p-0">
                                <div className='col-2 m-0 pt-5 pl-6'>
                                    <Checkbox inputId="binaryF" checked={checkedF} onChange={e => onCheckedButtonF(e)} />
                                </div>
                                <div className='col-8'>
                                    <label className={disabledLabelF} htmlFor='classeF'>Classe</label>
                                    <Dropdown id="classeF" disabled={!checkedF} value={classeF.id} options={classes} placeholder="Selectionner la classe" optionValue="id" optionLabel="libelle" onChange={(e) => onClasseChangeF(e)} />
                                </div>
                            </div>
                            <div className='col-3 pt-5'>
                                <Button label='Generer' disabled={disabledGenBtn} icon="pi pi-sync" className="p-button-success" onClick={() => {setGenDialog(true)}}></Button>
                            </div>
                            <div className='col-1 pt-2'>
                                <ProgressSpinner className={displaySpinner} strokeWidth='3' />
                            </div>
                        </div>

                    </div>
                </div>
            </Dialog>

            <Dialog visible={displayMessage} style={{ width: '40%' }} modal className="p-fluid" onHide={hideMessageDialog}>

                <div className='grid'>
                    <div className='bg-green-600 col-12'>
                        <h3 className='ml-3 pt-4'><span className='text-white'>RECAPITULATIF</span></h3>
                    </div>

                    <div className='col-12 surface-200'>
                        <div className="card">
                            <DataTable value={messages} header="Informations" emptyMessage="Aucune alerte notifiée" scrollable scrollHeight="300px" size="small" responsiveLayout="scroll">
                                <Column field="title" header="Titre"></Column>
                                <Column field="detail" header="Detail"></Column>
                            </DataTable>
                        </div>
                    </div>
                    <div className='col-12'>
                        <Button label='Fermer' className="p-button-success w-3 left-50" onClick={() => hideMessageDialog()}></Button>
                    </div>
                </div>
            </Dialog>

            <Dialog visible={detailDialog} style={{ width: '80%' }} modal className="p-fluid" onHide={hideDetailDialog}>

                <DataTable value={seanceDetail} scrollable scrollHeight="400px" stripedRows header="Détails des séances générées" responsiveLayout="scroll" emptyMessage="Aucun enregistrement trouvé">
                    <Column field="dateSeance" body={dateSeanceBody} header="Date"></Column>
                    <Column field="classe.libelle" header="Classe"></Column>
                    <Column field="typeActivite.libelle" header="Type séance"></Column>
                    <Column field="heureDeb" header="Heure début"></Column>
                    <Column field="heureFin" header="Heure Fin"></Column>
                    <Column field="matiere.libelle" header="Matière"></Column>
                    <Column field="professeur.nom" body={bodyIdentiteProf} header="Professeur"></Column>
                    <Column field="surveillant.nom" body={bodyIdentiteSurv} header="Surveillant"></Column>
                    <Column header="Action" body={actionDetailBody}></Column>
                </DataTable>
            </Dialog>

            <Dialog visible={genDialog} style={{ width: '450px' }} header="Confirmation" modal footer={genDialogFooter} onHide={() => { setGenDialog(false) }}>
                <div className="flex align-items-center justify-content-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {dateF && <span>Voulez-vous effectuer la génération des séances ?</span>}
                </div>
            </Dialog>

        </div>
    )
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(OpenSeance, comparisonFn);