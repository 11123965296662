import axios from 'axios';
import { BaseService } from './BaseService';
export class AnneeService extends BaseService {

    
    async deleteWithPeriodes(id) {
        return axios.get(this.getHost() + 'annee/delete-with-periodes/'+id).then(res => res.data);
    }

    async getList() {
        return axios.get(this.getHost() + 'annee/list').then(res => res.data);
    }

    async getListToCentral() {
        return axios.get(this.getHost() + 'annee/list-to-central').then(res => res.data);
    }

    async getListToCentralByNiveau(niveau) {
        return axios.get(this.getHost() + 'annee/list-to-central-niveau-enseignement?niveau='+niveau).then(res => res.data);
    }

    async getListToCentralByNiveauProjection(niveau) {
        return axios.get(this.getHost() + 'annee/list-to-central-niveau-enseignement-projection?niveau='+niveau).then(res => res.data);
    }

    async getListToEcole(ecoleId) {
        // console.log('zzzzzzzzzzzzZZZZZZZZZZZzzzzzzzz')
        return axios.get(this.getHost() + 'annee/list-to-ecole?ecole='+ecoleId).then(res => res.data);
    }

    async getById(id) {
        return axios.get(this.getHost() + 'annee/get-by-id/'+id).then(res => res.data);
    }

    async cloturer(annee) {
        return axios.post(this.getHost() + 'annee/cloture-process',annee ).then(res => res.data);
    }

    async sharing(annee){
        return axios.post(this.getHost() + 'annee/sharing', annee).then(res => res.data);
    }

    async open(annee){
        return axios.post(this.getHost() + 'annee/open', annee).then(res => res.data);
    }
    async saveInit(annee) {
        // console.log('sended value');
        console.log(annee);
        return axios.post(this.getHost() + 'annee/save-update', annee).then(res => res.data);
    }

    async save(annee) {
        // console.log('sended value');
        console.log(annee);
        return axios.post(this.getHost() + 'annee/save-update-ecole', annee).then(res => res.data);
    }

    async getInfosAnnee(ecoleId) {
        return axios.get(this.getHost() + 'annee/info-annee/'+ecoleId).then(res => res.data);
    }
}
