import axios from 'axios';
import { BaseService } from '../BaseService';
const BASE_URL ="https://pouls-scolaire.com/api/";
// const BASE_URL ="https://pouls-scolaire.com/api/";
export class ConnexionService extends BaseService {
    seConnecter(connexioDto) {
        return  axios.post(this.getHost()+ 'connexion/se-connecter', connexioDto)
            .then(res => res.data);
    }
    seConnecterAdmin(connexioDto) {
        return   axios.post(this.getHost()+ 'connexion/se-connecter-admin', connexioDto)
            .then(res => res.data);
    }

    sendEmail(destinataire ,message,objet) {
        return axios.post(this.getHost()+'sendEmail?destinataire='+destinataire+'&message='+message+'&objet='+objet).then(res => res.data);
    }
    listProfil() {
        return axios.get(this.getHost()+'profil').then(res => res.data);
    }

    listEcoleConnecte() {
        return axios.get(this.getHost()+'connecte/ecole').then(res => res.data);
    }

    infoPersonConnecte(email,idEcole) {
        return axios.get(this.getHost()+'connexion/infos-personnel-connecte/'+email+'/'+idEcole).then(res => res.data);
    }

    async infoPersonConnecteV2(email,idEcole,profil) {
        return axios.get(this.getHost()+'connexion/infos-personnel-connecte-v2/'+email+'/'+idEcole+'/'+profil).then(res => res.data);
    }

    getMainyearByEcole(idEcole) {
        return axios.get(this.getHost()+'annee/get-main-annee-by-ecole/'+idEcole).then(res => res.data);
    }

    infoPersonConnecteCandidat(email) {
        return axios.get(this.getHost()+'connexion/infos-personnel-connecte-candidat/'+email).then(res => res.data);
    }
    infosParam(email) {
        return axios.get(this.getHost()+'connexion/parametreLogin/'+email).then(res => res.data);
    }
    idUserConnecte(email) {
        return axios.get(this.getHost()+'connexion/id-utilisateur-connecte/'+email).then(res => res.data);
    }


    connexionCandidat(email,password) {
        return axios.get(this.getHost()+'connexion/checkPassword?login='+email+'&motDepasse='+password).then(res => res.data);
    }


    modifierMotDepasse(motDepasseDto) {
        return axios.put(this.getHost()+'connexion/modifier-motDePasse',motDepasseDto).then(res => res.data);
    }

}
