import axios from 'axios';
import { BaseService } from './BaseService';

export class DashBoardFondateurService extends BaseService{

    async getDatas(ecole, annee){
        return axios.get(this.getHost()+'dashboard-fondateur/eleve-block/'+ecole+'/'+annee).then(resp => resp.data);
    }
    

}
