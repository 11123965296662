import axios from 'axios';

import { BaseService } from '../BaseService';
export class FicheProfesseurService  extends BaseService{


    listProfesseurClasseMatiere(tenantId,anneeId) {
        return axios.get(this.getHost()+'matire-classe-personnel/ecole-annee/'+tenantId+'/'+anneeId).then(res => res.data);
    }


    listMatiere(tenantId) {
        return axios.get(this.getHost()+'matiere/ecole/'+tenantId).then(res => res.data);
    }

    listClasse(tenantId) {
        return axios.get(this.getHost()+'classe/ecole/'+tenantId).then(res => res.data);
    }







    listEleve() {
        return axios.get(this.getHost()+'eleve/').then(res => res.data);
    }


}
