import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { InputMask } from 'primereact/inputmask';

import { Dropdown } from 'primereact/dropdown';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { ActiviteService } from '../service/ActiviteService';
import { JourService } from '../service/JourService';
import { ClasseService } from '../service/ClasseService';
import { Checkbox } from 'primereact/checkbox';
import { HeureService } from '../service/HeureService';
// import { ClasseEleveService } from '../service/ClasseEleveService';
import { ClasseMatiereService } from '../service/ClasseMatiereService';
import { SalleService } from '../service/SalleService';
import { TypeActiviteService } from '../service/TypeActiviteService';
import { SeanceService } from '../service/SeanceService';
import { PersonnelService } from '../service/PersonnelService';
import { Calendar } from 'primereact/calendar';
import { MatiereEcoleService } from '../service/MatiereEcoleService';
import { Fieldset } from 'primereact/fieldset';
import { InputNumber } from 'primereact/inputnumber';
import { PeriodeService } from '../service/PeriodeService';
import PersonnelMatiereClasse from './PersonnelMatiereClasse';
import { PersonnelMatiereClasseService } from '../service/PersonnelMatiereClasseService';


const SeancesForm = () => {

    // let emptyHeure = {
    //     id: null,
    //     libelle: null
    // };

    let emptyJour = {
        id: null,
        libelle: null
    };

    let emptyClasse = {
        id: null,
        libelle: null
    }
    let emptyMatiere = {
        id: null,
        libelle: null
    }

    let emptySalle = {
        id: null,
        libelle: null
    }

    let emptyType = {
        id: null,
        libelle: null
    }

    // let emptyActivite = {
    //     jour: emptyJour,
    //     heureDeb: "",
    //     heureFin: "",
    //     matiere: emptyMatiere,
    //     classe: emptyClasse,
    //     salle: emptySalle,
    //     typeActivite: emptyType,
    // };

    let emptySeance = {
        jour: emptyJour,
        heureDeb: "",
        heureFin: "",
        matiere: emptyMatiere,
        classe: emptyClasse,
        salle: emptySalle,
        typeActivite: emptyType,
        professeur: { id: '' },
        evaluationIndicator: 0,
        evaluation: {
            id: 0,
            periode: { id: 0 },
            noteSur: '',
            duree: ''
        },
        surveillant: { id: '' }
    }
    const emptyPeriode = {
        id: null,
        code: '',
        libelle: ''
    }


    const [seanceDialog, setSeanceDialog] = useState(false);

    const [deleteSeanceDialog, setDeleteSeanceDialog] = useState(false);
    const [deleteSeancesDialog, setDeleteSeancesDialog] = useState(false);

    const [saveDialog, setSaveDialog] = useState(false);
    const [personnels, setPersonnels] = useState([]);
    const [professeurs, setProfesseurs] = useState([]);
    const [headerTitle, setHeaderTitle] = useState("");

    const [seance, setSeance] = useState(emptySeance);

    const [seances, setSeances] = useState(null);

    const [selectedSeances, setSelectedSeances] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);

    const toast = useRef(null);
    const dt = useRef(null);

    const [btn_lbl, setBtn_lbl] = useState("");
    const anneeId = sessionStorage.getItem('AnneEncours');
    const userId = sessionStorage.getItem('CandidatId');

    // const [activites, setActivites] = useState([]);
    // const [activitesTab, setActivitesTab] = useState([]);
    const [jours, setJours] = useState(null);
    const [heures, setHeures] = useState(null);
    const [salles, setSalles] = useState(null);
    const [disabledSaveBtn, setDisabledSaveBtn] = useState(false);
    const [classes, setClasses] = useState(null);
    const [matieres, setMatieres] = useState(null);
    const [matieresDispo, setMatieresDispo] = useState(null);
    const [hiddenDisp, setHiddenDisp] = useState("hidden");
    const [hiddenNoDisp, setHiddenNoDisp] = useState("hidden");
    const [disabled, setDisabled] = useState(false);
    const [checked, setChecked] = useState(true);
    const [chkHidden, setChkHidden] = useState('hidden');
    const [types, setTypes] = useState([]);
    const [width, setWidth] = useState("60%");

    const [periodes, setPeriodes] = useState(null);
    // const [periode, setPeriode] = useState([emptyPeriode]);
    const [isCollapse, setIsCollapse] = useState(false);

    useEffect(() => {
        const personnelService = new PersonnelService();
        const jourService = new JourService();
        const classeService = new ClasseService();
        const matiereEcoleService = new MatiereEcoleService();
        const heureService = new HeureService();
        const typeActiviteService = new TypeActiviteService();
        const seancesService = new SeanceService();

        personnelService.getListByFonction_v2(1, sessionStorage.getItem("EcoleID")).then(resp => {
            let surveillant = [{ id: '' }, ...resp];
            // console.log(surveillant);
            setPersonnels(surveillant);
        }
        );
        personnelService.getListProf().then(resp => setProfesseurs(resp));
        jourService.getList().then(data => setJours(data));
        matiereEcoleService.getByEcoleViaNiveauEnseignement(sessionStorage.getItem("EcoleID")).then(data => setMatieres(data));
        classeService.getListByEcole(sessionStorage.getItem("EcoleID")).then(data => setClasses(data));
        heureService.getList().then(data => setHeures(data));
        typeActiviteService.getListByEcole(sessionStorage.getItem('EcoleID')).then(data => setTypes(data));
        seancesService.getListStatut(anneeId, 'MAN').then(data => setSeances(data));


    }, []);

    const openNew = () => {
        setSubmitted(false);
        setWidth("60%");
        const periodeService = new PeriodeService();
        periodeService.getByPeriodicite(sessionStorage.getItem('periodiciteId')).then(res => setPeriodes(res));

        setSeanceDialog(true);
        setHeaderTitle("Créer une nouvelle séance");
        setBtn_lbl("Enregistrer");
        setDisabled(false);
        setDisabledSaveBtn(false);
        setSaveDialog(false);
        setChkHidden('hidden');
    }

    const hideDialog = () => {
        setSubmitted(false);
        setSeanceDialog(false);
        setSeance(emptySeance);
        setMatieres(null);
        // setActivitesTab([]);
        setHiddenDisp("hidden");
        setHiddenNoDisp("hidden");
        setSaveDialog(false);
        setDisabledSaveBtn(false);
        setChkHidden('hidden');
    }


    const hideDeleteSeanceDialog = () => {
        setDeleteSeanceDialog(false);
    }


    const hideDeleteSeancesDialog = () => {
        setDeleteSeancesDialog(false);
    }

    const hideSaveSeanceDialog = () => {
        setSaveDialog(false);
    }

    const saveSeance = () => {
        setSubmitted(true);
        console.log(seance);
        // console.log('date seance ::: ' + seance.dateSeance + ' - ' + !(seance.dateSeance && true));
        let flag = true;
        if (hiddenNoDisp != '' && seance.jour.id != null && seance.heureDeb != '' && seance.heureFin != '' && seance.classe.id != null && seance.matiere.id != null && seance.typeActivite.id != null && seance.dateSeance != null && seance.professeur.id != '') {
            if (seance.evaluationIndicator == 0) {
                flag = true;

            } else if (seance.evaluationIndicator == 1) {
                if (seance.evaluation.periode.id != '' && seance.evaluation.noteSur != '' && seance.evaluation.duree != '') {
                    flag = true;
                } else {
                    flag = false;
                }
            }
            if (flag) {

                let hidder = true;
                let _seances = [...seances];
                // let _activitesTab = [...activitesTab];
                let _seance = { ...seance };
                const seanceService = new SeanceService();
                _seance.statut = 'MAN';
                _seance.annee = sessionStorage.getItem('AnneEncours');
                _seance.user = sessionStorage.getItem('idUser');

                if (!_seance.surveillant)
                    _seance.surveillant = emptySeance.surveillant;
                if (_seance.surveillant.id === '')
                    _seance.surveillant.id = 0;
                if (_seance.professeur.id === '')
                    _seance.professeur.id = 0;

                if (seance.id) {
                    //console.log(_activite);
                    const index = reactfindIndexById(seance.id, seances);
                    // const indexTab = reactfindIndexById(seance.id, activitesTab);
                    // console.log(index + ' - ' + indexTab);
                    (seanceService.updateAndDisplay(_seance)).then(resp => {
                        _seances[index] = { ...resp };
                        // _activitesTab[indexTab] = { ...resp };
                        setSeances(_seances);
                        // setActivitesTab(_activitesTab);
                        // setDisabledSaveBtn(true);
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Seance modifiée', life: 3000 });
                    }).catch((error) => {
                        hidder = false;
                        if (error.response) {
                            console.log('Une Erreur empêche la modification de la séance. code ::: ' + error.response.status);
                            // console.log('Details::: ' + error.message);
                        } else if (error.request) {
                            console.log('erreur request- vie ecole' + error.request);
                        } else {
                            console.log('Error autres', error.message);
                        }
                        toast.current.show({ severity: 'error', summary: 'erreur', detail: 'Une erreur s est produite', life: 3000 });
                    }
                    );

                }
                else {
                    //_activite.id = createId();
                    _seance.profPrincipal = null;
                    // console.log(_seance);
                    (seanceService.saveAndDisplay(_seance)).then(res => {
                        _seances.push(res);
                        // _seancesTab.push(res);
                        setSeances(_seances);
                        // setActivitesTab(_activitesTab);
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Nouvelle séance créee', life: 3000 });
                    }).catch((error) => {
                        hidder = false;
                        if (error.response) {
                            console.log('Une Erreur empêche la création de la séance code ::: ' + error.response.status);
                        } else if (error.request) {
                            console.log('erreur request- vie ecole' + error.request);
                        } else {
                            console.log('Error autres', error.message);
                        }
                        toast.current.show({ severity: 'error', summary: 'erreur', detail: 'Une erreur s est produite', life: 3000 });
                    }
                    );
                }
                // setSeanceDialog(false);
                // setActivite(emptyActivite);
                setSubmitted(false);
                seance.heureDeb = "";
                seance.heureFin = "";
                setHiddenDisp("hidden");
                setHiddenNoDisp("hidden");
                if (hidder)
                    hideDialog();
            } else if (hiddenNoDisp === '') {
                toast.current.show({ severity: 'error', summary: 'Attention', detail: 'Plage Horaire Non disponible', life: 5000 });
            }
            setSaveDialog(false);
        }
    }

    const editSeance = (seance) => {
        console.log(seance);
        setHeaderTitle("Modifier une séance");
        setBtn_lbl("Modifier");
        setWidth('60%');
        setSeanceDialog(true);
        setDisabled(true);
        if (seance.evaluationIndicator == 1) {
            setChkHidden('');
            setChecked(true);
            setIsCollapse(false);
        }

        let date = new Date(seance.dateSeance.split('Z')[0]);
        let seanceTmp = { ...seance }

        const periodeService = new PeriodeService();
        periodeService.getList().then(res => setPeriodes(res));
        // console.log(new Date(seance.dateSeance.split('Z')[0]));
        seanceTmp.dateSeance = date;
        setSeance({ ...seanceTmp });

        const classeMatiereService = new ClasseMatiereService();
        // const activiteService = new ActiviteService();
        const salleService = new SalleService();
        classeMatiereService.getMatiereByBrancheViaClasse(seance.classe.id).then(
            resp => {
                let matiereTmp = [];
                // console.log(resp);
                for (let index = 0; index < resp.length; index++) {
                    matiereTmp.push(resp[index].matiere);

                }
                setMatieresDispo(matiereTmp);
            }
        );

        // activiteService.getByClasseAndJour(1, seance.classe.id, seance.jour.id).then(resp => setActivitesTab(resp));
        salleService.getSallesDispoHeures(sessionStorage.getItem('AnneEncours'), seance.classe.id, seance.jour.id, date, seance.heureDeb, seance.heureFin).then(
            resp => setSalles([...resp, ...[seance.salle]])
        )
    }

    const confirmDeleteSeance = (seance) => {
        setSeance(seance);
        setDeleteSeanceDialog(true);
    }
    // Revoir avec les try catch
    const deleteSeance = () => {
        let _seances = seances.filter(val => val.id !== seance.id);
        const seanceService = new SeanceService();
        setSeances(_seances);
        setDeleteSeanceDialog(false);
        seanceService.delete(seance.id);
        setSeance(emptySeance);
        toast.current.show({ severity: 'success', summary: 'Succès', detail: 'Seance supprimée', life: 3000 });
    }

    const headerForm = () => {
        return (
            <div className='surface-300 p-2'>
                <h2 className='m-1'>{headerTitle}</h2>
            </div>
        )
    }

    const reactfindIndexById = (id, atvs) => {
        let index = -1;
        for (let i = 0; i < atvs.length; i++) {
            if (atvs[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }
    // const createId = () => {
    //     let id = '';
    //     let chars = '0123456789';
    //     for (let i = 0; i < 5; i++) {
    //         id += chars.charAt(Math.floor(Math.random() * chars.length));
    //     }
    //     return id;
    // }

    function padTo2Digits(num) {
        return num.toString().padStart(2, '0');
    }

    function formatDate(date) {
        const [date1, time1] = date.split('T');
        if (date1) {

            date = new Date(date1);
            return [
                padTo2Digits(date.getDate()),
                padTo2Digits(date.getMonth() + 1),
                date.getFullYear(),
            ].join('/');
        } else
            return '';
    }

    // const exportCSV = () => {
    //     dt.current.exportCSV();
    // }

    const confirmDeleteSelected = () => {
        setDeleteSeancesDialog(true);
    }


    const deleteSelectedSeances = () => {
        let _seances = seances.filter(val => !selectedSeances.includes(val));
        const seanceService = new SeanceService();
        selectedSeances.map((sc) => { console.log(sc); return seanceService.delete(sc.id) });
        setSeances(_seances);
        setDeleteSeancesDialog(false);
        setSelectedSeances(null);
        toast.current.show({ severity: 'success', summary: 'succès', detail: 'Séances supprimées', life: 3000 });
    }

    const onInputBlur = (e, name) => {
        const val = e.target.value;
        console.log(val);
        let _seance = { ...seance };
        _seance[`${name}`] = val;
        setSeance(_seance);
        if (val === '') {
            setHiddenDisp("hidden");
            setHiddenNoDisp("hidden");
        }
    }

    const onInputChange = (e, name) => {
        const val = e.value;
        let _seance = { ...seance };
        _seance[`${name}`] = val;
        setSeance(_seance);

        if (name === 'dateSeance') {
            let _date = new Date(val);
            _seance.jour.id = _date.getDay() != 0 ? _date.getDay() : 7;
            _seance.dateSeance = _date;
        }

        if (name === 'heureDeb' || name === 'heureFin') {
            if (seance[`${name === 'heureDeb' ? 'heureFin' : 'heureDeb'}`] && _seance[`${name}`]) {
                let heureD = '';
                let heureF = '';
                // console.log(seance);
                const seanceService = new SeanceService();
                const salleService = new SalleService();
                if (name === 'heureDeb') {
                    heureD = _seance.heureDeb;
                    heureF = seance.heureFin;
                    // console.log(heureD);
                    // console.log(heureF);
                } else {
                    heureD = seance.heureDeb;
                    heureF = _seance.heureFin;
                    // console.log(heureD);
                    // console.log(heureF);
                }
                //Adapter avec la disponibilité par rapport aux seances et non aux activité de l emploi du temps
                seanceService.isPlageHoraireValid(sessionStorage.getItem('AnneEncours'), seance.classe.id, seance.jour.id, seance.dateSeance, heureD, heureF).then(
                    resp => {
                        if (resp) {
                            setHiddenDisp("");
                            setHiddenNoDisp("hidden");
                        } else {
                            setHiddenDisp("hidden");
                            setHiddenNoDisp("");
                        }
                    }
                );
                // if (activite.matiere.id) {
                //Adapter avec la disponibilité par rapport aux seances et non aux activité de l emploi du temps
                salleService.getSallesDispoHeures(sessionStorage.getItem('AnneEncours'), seance.classe.id, seance.jour.id, seance.dateSeance, heureD, heureF).then(
                    resp => setSalles(resp)
                )
                // }
            }
        }
    }

    const onInputEvaluationChange = (e, name) => {
        const val = e.value;
        let _seance = { ...seance };
        _seance.evaluation[`${name}`] = val;
        setSeance(_seance);
    }

    const onSelectChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _seance = { ...seance };
        if (_seance[`${name}`] == null) {
            _seance[`${name}`] = { id: '' };
            // console.log('coucou');
        }
        _seance[`${name}`].id = val;
        console.log(_seance);
        // console.log(emptySeance);
        setSeance(_seance);

        if (name === 'matiere') {
            const profClasseMatiereService = new PersonnelMatiereClasseService();
            profClasseMatiereService.getProfesseurByClasseAndMatiere(sessionStorage.getItem('AnneEncours'), seance.classe.id, val).then(
                resp => {
                    if (resp != null && resp.personnel != null) {
                        setProfesseurs([resp.personnel])
                    } else {
                        setProfesseurs([])
                    }
                }
            )
        }

        if (name === 'typeActivite') {
            for (let index = 0; index < types.length; index++) {
                if (types[index].id == val && types[index].typeSeance === 'EVAL') {
                    setChkHidden('');
                    _seance.evaluationIndicator = 1
                } else if (types[index].id == val && types[index].typeSeance === 'COURS') {
                    _seance.evaluationIndicator = 0
                    setChkHidden('hidden');
                }

            }
        }

    }

    const onSelectPeriodeChange = (e) => {
        const val = (e.target && e.target.value) || '';
        let _evaluation = {
            id: null,
            periode: {
                id: null
            }
        };
        _evaluation.periode.id = val;
        let _seance = { ...seance };
        _seance.evaluation = _evaluation;
        setSeance(_seance);

    }

    const onSelectClasseAndJourChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _seance = { ...seance };
        _seance[`${name}`].id = val;
        setSeance(_seance);
        if (name === "classe") {
            //Retrieve matieres via la branche de la classe
            // const matiereEcoleService = new MatiereEcoleService();
            // remplacer en synchro avec l'api le param ecoleid -> niv-enseignid quand la var d env dispo
            // matiereEcoleService.getByEcoleViaNiveauEnseignement(sessionStorage.getItem('EcoleID')).then(
            //     resp => setMatieresDispo(resp)
            // );

            const classeMatiereService = new ClasseMatiereService();
            const professeursClasse = new PersonnelMatiereClasseService();
            classeMatiereService.getMatiereByBrancheViaClasse(val).then(
                resp => {
                    let _matieres = [];
                    for (let index = 0; index < resp.length; index++) {
                        _matieres.push(resp[index].matiere)

                    }
                    setMatieresDispo(_matieres)
                }
            )

            professeursClasse.getByClasse(val, sessionStorage.getItem('AnneEncours')).then(
                resp => {
                    let _profs = [];
                    let _person_ids = [];
                    for (let index = 0; index < resp.length; index++) {
                        if (!_person_ids.includes(resp[index].personnel.id)) {
                            _profs.push(resp[index].personnel)
                            _person_ids.push(resp[index].personnel.id)
                        }
                    }
                    setProfesseurs(_profs)
                }
            )
        }

    }

    const onChecked = (e) => {

        if (!disabled) {
            setChecked(!e);
            let _seance = { ...seance };
            _seance.evaluationIndicator = e == true ? 1 : 0;
            setSeance(_seance);
            console.log(_seance);
            setIsCollapse(e);
        }
    }


    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Nouveau" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    <Button label="Supprimer" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedSeances || !selectedSeances.length} />
                </div>
            </React.Fragment>
        )
    }



    const classeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Classe</span>
                {rowData.classe == null ? '' : rowData.classe.libelle}
            </>
        );
    }

    const typeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Type activité</span>
                {rowData.typeActivite == null ? '' : rowData.typeActivite.libelle}
            </>
        );
    }

    const profBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Professeur</span>
                {rowData.professeur == null ? '' : (rowData.professeur.nom + ' ' + rowData.professeur.prenom)}
            </>
        );
    }

    const survBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Surveillant</span>
                {rowData.surveillant == null ? '' : rowData.surveillant.nom + ' ' + rowData.surveillant.prenom}
            </>
        );
    }

    const heureDebBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Libellé</span>
                {rowData.heureDeb}
            </>
        );
    }

    const heureFinBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Année</span>
                {rowData.heureFin}
            </>
        );
    }

    const matiereBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Niveau</span>
                {rowData.matiere == null ? '' : rowData.matiere.libelle}
            </>
        );
    }

    const jourBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Série</span>
                {rowData.dateSeance ? formatDate(rowData.dateSeance) : ''}
            </>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editSeance(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteSeance(rowData)} />
            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h3 className="m-0">Liste des séances saisies</h3>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Recherche..." />
            </span>
        </div>
    );


    const seanceDialogFooter = (
        <>
            <Button label={btn_lbl} disabled={disabledSaveBtn} icon="pi pi-check" className="p-button-primary w-3" onClick={() => setSaveDialog(true)} />
            <Button label="Fermer" icon="pi pi-times" className="p-button-danger" onClick={hideDialog} />
        </>
    );


    const deleteSeanceDialogFooter = (
        <>
            <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={hideDeleteSeanceDialog} />
            <Button label="Oui" icon="pi pi-check" className="p-button-text" onClick={deleteSeance} />
        </>
    );
    const deleteSeancesDialogFooter = (
        <>
            <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={hideDeleteSeancesDialog} />
            <Button label="oui" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedSeances} />
        </>
    );

    const saveDialogFooter = (
        <>
            <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={hideSaveSeanceDialog} />
            <Button label="oui" icon="pi pi-check" className="p-button-text" onClick={saveSeance} />
        </>
    );

    const legend = (
        <div className='text-xs'>
            <Checkbox inputId='chkCreateSeance' disabled={disabled} onChange={e => onChecked(e.checked)} className={'vertical-align-middle ' + chkHidden} checked={checked}></Checkbox><span className={chkHidden + ' text-xs vertical-align-middle text-orange-900 ml-2'}><i>Générer automatiquement l&apos;évaluation associée</i></span>
        </div>
    )

    return (
        <div className="grid crud-demo">

            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" right={leftToolbarTemplate} ></Toolbar>

                    <DataTable ref={dt} value={seances} selection={selectedSeances} onSelectionChange={(e) => setSelectedSeances(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="affichage de {first} à {last} sur {totalRecords} activités"
                        globalFilter={globalFilter} emptyMessage="Aucun enregistrement trouvé." header={header} responsiveLayout="scroll">
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                        <Column field="dateSeance" header="Date" body={jourBodyTemplate} headerStyle={{ width: '10%', minWidth: '7rem' }}></Column>
                        <Column field="classe" header="Classe" sortable body={classeBodyTemplate} headerStyle={{ width: '10%', minWidth: '6rem' }}></Column>
                        <Column field="typeActivite.libelle" header="Type" sortable body={typeBodyTemplate} headerStyle={{ width: '20%', minWidth: '6rem' }}></Column>
                        <Column field="heureDeb" header="Heure début" body={heureDebBodyTemplate} sortable headerStyle={{ width: '10%', minWidth: '7rem' }}></Column>
                        <Column field="heureFin" header="Heure Fin" body={heureFinBodyTemplate} sortable headerStyle={{ width: '10%', minWidth: '7rem' }}></Column>
                        <Column field="matiere" header="Matière" sortable body={matiereBodyTemplate} headerStyle={{ width: '20%', minWidth: '8rem' }}></Column>
                        <Column field="professeur.nom" header="Professeur" sortable body={profBodyTemplate} headerStyle={{ width: '20%', minWidth: '6rem' }}></Column>
                        <Column field="surveillant.nom" header="Surveillant" sortable body={survBodyTemplate} headerStyle={{ width: '20%', minWidth: '6rem' }}></Column>
                        <Column body={actionBodyTemplate} header="Actions" headerStyle={{ width: '20%', minWidth: '9rem' }}></Column>
                    </DataTable>

                    <Dialog visible={seanceDialog} style={{ width: '60%' }} header={headerForm} modal className="p-fluid" footer={seanceDialogFooter} onHide={hideDialog}>

                        <div className="grid" >
                            <div className="col-12">
                                <div className="grid">
                                    <div className='col-offset-1'>

                                    </div>
                                    <div className="field col-3">
                                        <label htmlFor="classe"><b>Classe </b><span className='text-red-700'>*</span></label>
                                        <Dropdown id="classe" value={seance.classe.id} disabled={disabled} onChange={(e) => onSelectClasseAndJourChange(e, 'classe')} options={classes} required optionValue="id" optionLabel="libelle" placeholder="Selectionner la classe" className={classNames({ 'p-invalid': submitted && !(seance.classe.id) })} />
                                        {submitted && !(seance.classe.id) && <small className="p-invalid">La classe est requise.</small>}
                                    </div>

                                    <div className="field col-3">
                                        <label htmlFor='date'><b>Date</b> <span className='p-invalid'>*</span></label>
                                        <Calendar id="date" value={seance.dateSeance} dateFormat="dd-mm-yy" showIcon onChange={(e) => onInputChange(e, 'dateSeance')} className={classNames({ 'p-invalid': submitted && !seance.dateSeance })} />
                                        {submitted && !(seance.dateSeance) && <small className="p-invalid">Entrer la date</small>}
                                    </div>

                                    <div className="field col-3">
                                        <label htmlFor="matiere"><b>Matière </b><span className='text-red-700'>*</span></label>
                                        <Dropdown id="matiere" value={seance.matiere.id} disabled={disabled} onChange={(e) => onSelectChange(e, 'matiere')} options={matieresDispo} required optionValue="id" optionLabel="libelle" placeholder="Selectionner la matière" className={classNames({ 'p-invalid': submitted && !(seance.matiere.id) })} />
                                        {submitted && !(seance.matiere.id) && <small className="p-invalid">La matiere est requise.</small>}
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">

                                <div className="grid">
                                    <div className='col-offset-1'>

                                    </div>
                                    <div className="field col-2">
                                        <label htmlFor="heureDeb"><b>Heure début </b><span className='text-red-700'>*</span></label>
                                        <InputMask id="heureDeb" mask="99:99" disabled={disabled} placeholder="hh:mm" required value={seance.heureDeb} onBlur={(e) => onInputBlur(e, 'heureDeb')} onComplete={(e) => onInputChange(e, 'heureDeb')} className={classNames({ 'p-invalid': submitted && !(seance.heureDeb) })}></InputMask>
                                        {submitted && !(seance.heureDeb) && <small className="p-invalid">L'heure est requise.</small>}
                                    </div>

                                    <div className="field col-2">
                                        <label htmlFor="heureFin"><b>Heure Fin </b><span className='text-red-700'>*</span></label>
                                        <InputMask id="heureFin" mask="99:99" disabled={disabled} placeholder="hh:mm" required value={seance.heureFin} onBlur={(e) => onInputBlur(e, 'heureFin')} onComplete={(e) => onInputChange(e, 'heureFin')} className={classNames({ 'p-invalid': submitted && !(seance.heureFin) })}></InputMask>
                                        {submitted && !(seance.heureFin) && <small className="p-invalid">L'heure est requise.</small>}
                                    </div>
                                    <div className="col-1 relative">
                                        <Tag className={"absolute top-50 " + hiddenDisp} severity="success" value="Disp"></Tag>
                                        <Tag className={"absolute top-50 " + hiddenNoDisp} severity="danger" value="Occp"></Tag>
                                    </div>

                                    <div className="field col-4">
                                        <label htmlFor="salle"><b>Salle disponible </b><span className='text-red-700'>*</span></label>
                                        <Dropdown id="salle" value={seance.salle.id} onChange={(e) => onSelectChange(e, 'salle')} options={salles} required optionValue="id" optionLabel="libelle" placeholder="Selectionner la salle" className={classNames({ 'p-invalid': submitted && !(seance.salle.id) })} />
                                        {submitted && !(seance.salle.id) && <small className="p-invalid">La salle est requise.</small>}
                                    </div>
                                    <div className='col-2'>

                                    </div>
                                    <div className='col-offset-1'>

                                    </div>

                                    <div className="field col-3">
                                        <label htmlFor="type"><b>Type d&apos;activit&eacute; </b><span className='text-red-700'>*</span></label>
                                        <Dropdown id="type" value={seance.typeActivite.id} disabled={disabled} onChange={(e) => onSelectChange(e, 'typeActivite')} options={types} required optionValue="id" optionLabel="libelle" placeholder="Selectionner le type d activité" className={classNames({ 'p-invalid': submitted && !(seance.typeActivite.id) })} />
                                        {submitted && !(seance.typeActivite.id) && <small className="p-invalid">Le type d&apos;activit&eacute; est requis.</small>}
                                    </div>
                                    <div className="field col-3">
                                        <label htmlFor="prof"><b>Professeur </b><span className='text-red-700'>*</span></label>
                                        <Dropdown id="prof" value={seance.professeur.id} onChange={(e) => onSelectChange(e, 'professeur')} options={professeurs} required optionValue="id" optionLabel="nom" placeholder="Selectionner le professeur" className={classNames({ 'p-invalid': submitted && !(seance.professeur.id) })} />
                                        {submitted && !(seance.professeur.id) && <small className="p-invalid">Le professeur est requis.</small>}
                                    </div>
                                    <div className="field col-3">
                                        <label htmlFor="surv"><b>Surveillant </b></label>
                                        <Dropdown id="surv" value={seance.surveillant?.id} onChange={(e) => onSelectChange(e, 'surveillant')} options={personnels} optionValue="id" optionLabel="nom" placeholder="Selectionner le surveillant" />
                                    </div>

                                    {/* <div className={chkHidden + " card-container col-offset-1 col-9 mt-3"}>
                                        <Checkbox inputId='chkCreateSeance' onChange={e => setChecked(e.checked)} className={'vertical-align-middle ' + chkHidden} checked={checked}></Checkbox><span className={chkHidden + ' text-xs vertical-align-middle text-orange-900 ml-2'}><i>Générer l&apos;évaluation associée</i></span>
                                    </div> */}

                                    <Fieldset className={chkHidden + ' col-offset-1 col-9'} legend={legend} toggleable collapsed={isCollapse} onToggle={(e) => onChecked(e.value)}>
                                        <div className="formgroup-inline">

                                            <div className="field col-5">
                                                <label htmlFor="periode"><b>Periode</b></label>
                                                <Dropdown id="periode" disabled={disabled} value={seance.evaluation?.periode?.id} onChange={(e) => onSelectPeriodeChange(e)} options={periodes} required optionValue="id" optionLabel="libelle" placeholder="Selectionner la période" className={classNames({ 'p-invalid': submitted && !(seance.evaluation?.periode?.id) })} />
                                                {submitted && !(seance.evaluation?.periode?.id) && <small className="p-invalid">La période est requise.</small>}
                                            </div>

                                            <div className='field col-3'>
                                                <label htmlFor='notesur'><b>Noté sur</b></label>
                                                <InputNumber value={seance.evaluation?.noteSur} disabled={disabled} onValueChange={(e) => onInputEvaluationChange(e, 'noteSur')} id="notesur" required showButtons mode="decimal" className={classNames({ 'p-invalid': submitted && !seance.evaluation?.noteSur })} />
                                                {submitted && !seance.evaluation?.noteSur && <small className="p-invalid">La note est requise.</small>}
                                            </div>

                                            <div className='field col-2'>
                                                <label htmlFor='duree'><b>durée</b></label>
                                                {/* <Calendar id='duree' showIcon timeOnly icon='pi pi-fw pi-clock' value={evaluation.duree} onChange={(e) => onInputChange(e, 'duree')} className={classNames({ 'p-invalid': submitted && !evaluation.duree })}></Calendar> */}
                                                <InputMask id="duree" mask="99-99" value={seance.evaluation?.duree} disabled={disabled} placeholder="hh-mm" onChange={(e) => onInputEvaluationChange(e, 'duree')} className={classNames({ 'p-invalid': submitted && !seance.evaluation?.duree })}></InputMask>
                                                {submitted && !seance.evaluation?.duree && <small className="p-invalid">La durée est requise.</small>}
                                            </div>
                                        </div>
                                    </Fieldset>

                                </div>
                            </div>
                        </div>
                    </Dialog>

                    <Dialog visible={deleteSeanceDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteSeanceDialogFooter} onHide={hideDeleteSeanceDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {seance && <span>Etes vous sûr de vouloir Supprimer la seance id [<b>{seance.id} </b>]?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteSeancesDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteSeancesDialogFooter} onHide={hideDeleteSeancesDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {seance && <span>Êtes vous sûr de vouloir supprimer les éléments sélectionnés?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={saveDialog} style={{ width: '450px' }} header="Confirmation" modal footer={saveDialogFooter} onHide={hideSaveSeanceDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {seance && <span>Voulez-vous enregistrer cette séance</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(SeancesForm, comparisonFn);