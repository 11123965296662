import axios from 'axios';
import { BaseService } from './BaseService';

export class MatiereEcoleService extends BaseService {

    getList() {
        return axios.get(this.getHost() + 'matiere-ecole/list').then(res => res.data);
    }

    getListByEcole(ecole) {
        return axios.get(this.getHost() + 'matiere-ecole/list-by-ecole/'+ecole).then(res => res.data);
    } 

    getById(id) {
        return axios.get(this.getHost() + 'matiere-ecole/get-by-id?id=' + id).then(res => res.data);
    }

    getByEcoleViaNiveauEnseignement(ecoleId) {
        return axios.get(this.getHost() + 'matiere-ecole/get-by-ecole-via-niveau-enseignement?id=' + ecoleId).then(res => res.data);
    }

    updateAndDisplay(matiereEcole) {
        return axios.post(this.getHost() + 'matiere-ecole/update-display', matiereEcole).then(res => res.data);
    }

    saveAndDisplay(matiereEcole) {
        return axios.post(this.getHost() + 'matiere-ecole/saveAndDisplay', matiereEcole).then(res => res.data);
    }

    saveAndDisplayHandle(matiereEcole, id) {
        matiereEcole.matiere.niveauEnseignement.id = id;
        return axios.post(this.getHost() + 'matiere-ecole/saveAndDisplay', matiereEcole).then(res => res.data);
     
    }
    getListByEcoleAndProfesseur(ecole, prof){
        // To do
    }


    delete(id){
        return axios.delete(this.getHost()+'matiere-ecole/delete/'+id);
    }
}