// A corriger variables

import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';

import { InputText } from 'primereact/inputtext';

import { SeanceService } from '../service/SeanceService';
import { Dialog } from 'primereact/dialog';
import { ClasseEleveService } from '../service/ClasseEleveService';
import { ToggleButton } from 'primereact/togglebutton';

const Seance = () => {

    const [globalFilter, setGlobalFilter] = useState(null);

    const toast = useRef(null);
    const dt = useRef(null);

    const [seances, setSeances] = useState([]);
    let [eleves, setEleves] = useState([]);
    const [openAppel, setOpenAppel] = useState(false);

    useEffect(() => {
        let today = new Date();
        let user_id = sessionStorage.getItem('CandidatId');
     
        const [date, time] = today.toISOString().toString().split('T');

        const seanceService = new SeanceService();
        console.log(date);
        seanceService.getListByDateAndProf(date, user_id).then(data => { console.log(data); setSeances(data) });

    }, []);

    function padTo2Digits(num) {
        return num.toString().padStart(2, '0');
    }

    function formatDate(date) {
        const [date1, time1] = date.split('T');
        if (date1) {

            date = new Date(date1);
            return [
                padTo2Digits(date.getDate()),
                padTo2Digits(date.getMonth() + 1),
                date.getFullYear(),
            ].join('/');
        } else
            return '';
    }
    const heureBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Heure</span>
                {rowData.heureDeb + ' - ' + rowData.heureFin}
            </>
        );
    }

    const dateBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Date</span>
                {formatDate(rowData.dateSeance)}
            </>
        );
    }

    const profBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Professeur</span>
                {rowData.professeur == null ? '' : rowData.professeur.nom + ' ' + rowData.professeur.prenom}
            </>
        );
    }


    const classeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Classe</span>
                {rowData.matiere != null ? rowData.classe.libelle : ''}
            </>
        );
    }


    const matiereBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Matière</span>
                {rowData.matiere != null ? rowData.matiere.libelle : ''}
            </>
        );
    }
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-book" tooltip='Liste d appel' onClick={() => onOpenAppel(rowData)} className="p-button-rounded p-button-success mr-2" />
            </div>
        );
    }

    const onOpenAppel = (rowData) => {
        const classeEleveService = new ClasseEleveService();
        // console.log('id :: ' + rowData.classe.id);
        let _eleves = [];
        classeEleveService.getByClasseAndAnnee(rowData.classe.id, 1).then(
            resp => {
                console.log(resp); resp.forEach((elmt, i) => {
                    _eleves.push(elmt.inscription.eleve);
                });
                setEleves(_eleves);
            }
        );
        setOpenAppel(true);
    }

    const onHideDialog = () => {
        setOpenAppel(false);
        setEleves([]);
    }

    const headerTitle = () => {
        return (

            <div className='card m-0 p-0'>
                <div className='grid m-0 p-0'>
                    <div className='col-12 m-0 p-0 grid'>
                        <div className="field surface-100  m-0 p-0 col-12">
                            <h3 className='m-3'>Liste de presence </h3>
                        </div>

                        <div className='col-offset-8 col-3'>
                            <Button label='Valider l&apos;appel' className="p-button-success m-1" onClick={() => console.log('coucou')}></Button>
                        </div>
                    </div>

                </div>
            </div>

        )
    }

    const dialogFooter = (
        <>
            <Button label="Fermer" icon="pi pi-times" className="p-button-text" onClick={() => onHideDialog()} />

        </>
    );
    const fullNameBody = (rowData) => {
        return (
            <>
                {rowData.nom + ' ' + rowData.prenom}
            </>
        )
    }

    const reactfindIndexById = (id, elmts) => {
        let index = -1;
        for (let i = 0; i < elmts.length; i++) {
            if (elmts[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    const onChangeToogle = (val, rd) => {
        rd.presence = val;
        let index = reactfindIndexById(rd.id, eleves)
        let _eleves = { ...eleves };
        _eleves[index] = { ...rd };
        console.log(_eleves);
        // setEleves(_eleves);
    }

    const presenceBody = (rowData) => {
        return (
            <>
                <ToggleButton checked={rowData.presence} className='w-3' onChange={(e) => onChangeToogle(e.value, rowData)} onLabel='P' offLabel='A'></ToggleButton>
            </>
        )
    }



    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h3 className="m-0">Liste des Séances du jour</h3>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Recherche..." />
            </span>
        </div>
    );

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" ></Toolbar>

                    <DataTable ref={dt} value={seances}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="affichage {first} de {last} sur {totalRecords} classes"
                        globalFilter={globalFilter} emptyMessage="Aucun enregistrement trouvé." header={header} responsiveLayout="scroll">
                        <Column field="dateSeance" header="Date" sortable body={dateBodyTemplate} headerStyle={{ width: '10%', minWidth: '6rem' }}></Column>
                        <Column field="classe" header="Classe" body={classeBodyTemplate} headerStyle={{ width: '14%', minWidth: '7rem' }}></Column>
                        <Column field="heureDeb" header="Heure" sortable body={heureBodyTemplate} headerStyle={{ width: '14%', minWidth: '8rem' }}></Column>
                        <Column field="matiere" header="Matière" body={matiereBodyTemplate} sortable headerStyle={{ width: '14%', minWidth: '7rem' }}></Column>
                        <Column field="prof" header="Professeur" sortable body={profBodyTemplate} headerStyle={{ width: '14%', minWidth: '5rem' }}></Column>
                        <Column body={actionBodyTemplate} header="Actions" headerStyle={{ width: '14%', minWidth: '9rem' }}></Column>
                    </DataTable>

                    <Dialog visible={openAppel} style={{ width: '70%' }} scrollable scrollHeight="400px" header={headerTitle} modal className="p-fluid" footer={dialogFooter} onHide={onHideDialog}>
                        <div>
                            <DataTable value={eleves} stripedRows responsiveLayout="scroll">
                                <Column field="matricule" header="Matricule"></Column>
                                <Column field="nom" header="Nom et prénom" body={fullNameBody}></Column>
                                <Column field="presence" header="Presence" body={presenceBody}></Column>
                            </DataTable>
                        </div>

                    </Dialog>

                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Seance, comparisonFn);