import axios from 'axios';
import { BaseService } from './BaseService';

export class MatiereService extends BaseService {

    getList() {
        return axios.get(this.getHost() + 'matiere/list').then(res => res.data);
    }

    getById(id) {
        return axios.get(this.getHost() + 'matiere/get-by-id?id=' + id).then(res => res.data);
    }

    getByNiveauEnseignement(ecoleId) {
        console.log('ecole - '+ecoleId);
        return axios.get(this.getHost() + 'matiere/get-by-niveau-enseignement?id=' + ecoleId).then(res => res.data);
    }

    getByNiveauEnseignementProjection(niveau) {
        console.log('niveau ens - '+niveau);
        return axios.get(this.getHost() + 'matiere/get-by-niveau-enseignement-projection?niveau=' + niveau).then(res => res.data);
    }

    updateAndDisplay(matiere) {
        return axios.post(this.getHost() + 'matiere/update-display', matiere).then(res => res.data);
    }

    saveAndDisplay(matiere) {
        return axios.post(this.getHost() + 'matiere/saveAndDisplay', matiere).then(res => res.data);
    }

    saveAndDisplayHandle(matiere, id) {
        matiere.niveauEnseignement.id = id;
        return axios.post(this.getHost() + 'matiere/saveAndDisplay', matiere).then(res => res.data);
     
    }

    delete(id){
        return axios.delete(this.getHost()+'matiere/delete/'+id);
    }
}