import axios from 'axios';
import { BaseService } from './BaseService';

const constants = {
    host: 'http://localhost:8889/gestion-ecole-api',    
  };

export class BulletinService extends BaseService{

    async save(annee, periode, classe){
        const res = await axios.get(this.getHost()+'bulletin/handle-save?annee='+annee+'&periode='+periode+'&classe='+classe);
        console.log('youpi '+res.status);
        return res.data;
    }

    async getBulletin(classe, matricule,annee, periode){
        const res = await axios.get(this.getHost()+'bulletin/get-bulletin-eleve-annee-periode?annee='+annee+'&matricule='+matricule+'&periode='+periode+'&classe='+classe);
        return res.data;
    }

    async saveAdjust(bulletinDto){
        const res = await axios.post(this.getHost()+'bulletin/apply-moyenne-adjustment',bulletinDto);
        return res.data;
    }
}