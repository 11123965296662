import axios from 'axios';
import { BaseService } from './BaseService';

const constants = {
    host: 'http://localhost:8889/gestion-ecole-api',    
  };

export class PointageService extends BaseService{

    getList(){
        
        return axios.get(this.getHost()+'pointage/list').then(res => res.data);
    }
}