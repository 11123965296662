import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Rating } from 'primereact/rating';
import { Toolbar } from 'primereact/toolbar';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
//import { ProductService } from '../service/ProductService';w

import {PersonnelsService} from '../../service/operations/PersonnelsService';
import {Dropdown} from "primereact/dropdown";
const AttribuerMatiereClasse = () => {
    let emptypersonnel = {
        personnelid: null,
        personnelcode: '',
        personnelnom: '',
        personnelprenom: '',
        personnel_lieunaissance: '',
        personnel_emprunte:'',
        personnel_contact:'',
        personneldatenaissance: null,
        tenant:emptyTenant,
        type_personnel: emptyTypePersonnel,
        personnel_status: emptyPersonnelStatut,
        fonction:emptyFonction
    };
    let emptyTenant= {
        tenantcode: '' ,
        tenantid: '' ,
        tenantlibelle: ''
    } ;

    let emptypersonnelSelect = {
        personnelid: null,
        personnelcode: '',
        personnelnom: '',
        personnelprenom: '',
        personnel_lieunaissance: '',
        personneldatenaissance: null,
        fonctionlibelle:'',
        type_personnel_liblle: '',
        personnelStatut_liblle: '',
        fonction_liblle:''
    };


    let emptypersonnelDto = {
        personnelid: null,
        personnelcode: '',
        personnelnom: '',
        personnelprenom: '',
        personnel_lieunaissance: '',
        personneldatenaissance: null,
        identifiant_type_personnel: 0,
        identifiant_personnelStatut: 0,
        identifiant_fonction:0
    };



    let emptyTypePersonnel={
        type_personnelcode: '',
        type_personnelid:null,
        type_personnellibelle:''

    };
    let emptyClasse = {
        classeid:null,
        classecode: '',
        classelibelle:''
    };
    let emptyMatiere = {
        matiereid:null,
        matierecode: '',
        matierelibelle:''
    };

    let emptyFonction={
        fonctioncode: '',
        fonctionid:null,
        fonctionlibelle:''
    };

    let emptyPersonnelStatut={
        personnel_statuscode: '',
        personnel_statusid:null,
        personnel_statulibelle:''
    };
    const [matiere, setmatiere] = useState(emptyMatiere);
    const [matieres, setmatieres] = useState(null);
    const [classe, setclasse] = useState(emptyClasse);
    const [classes, setclasses] = useState(null);
    const [typePersonnels, setTypePersonnels] = useState(null);
    const [typePersonnel, setTypePersonnel] = useState(emptyTypePersonnel);
    const [personnelSelect, setPersonnelSelect] = useState(emptypersonnelSelect);
    const [personnelSelects, setPersonnelSelects] = useState(null);
    const [fonction, setFonction] = useState(emptyFonction);
    const [fonctions, setFonctions] = useState(null);

    const [idPersonnel, setIdPersonnel] = useState(null);

    const [idModif, setIdmodif] = useState(null);

    const [personnelStatut, setPersonnelStatut] = useState(emptyPersonnelStatut);
    const [tenant, setTenant]  = useState(emptyTenant);
    const [idtenant, setIdTenant]  = useState("1");
    const [personnelStatuts, setPersonnelStatuts] = useState(null);
    const [personnels, setPersonnels] = useState(null);
    const [productDialog, setProductDialog] = useState(false);
    const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
    const [personnel, setPersonnel] = useState(emptypersonnel);
    const [personnelDto, setPersonnelDto] = useState(emptypersonnelDto);
    const [personnelDtos, setPersonnelDtos] = useState(null);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [globalFilterValue2, setGlobalFilterValue2] = useState('');

    const [filters2, setFilters2] = useState({
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'personnelcode': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        'personnelnom': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        'personnelprenom': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
       'fonction.fonctionlibelle': { value: null, matchMode: FilterMatchMode.EQUALS },
        'type_personnel.type_personnellibelle': { value: null, matchMode: FilterMatchMode.EQUALS },
        'personnel_status.personnel_statulibelle': { value: null, matchMode: FilterMatchMode.EQUALS }
    });
    const fonctionFilters = [
        'EDUCATEUR', 'PROFESSEUR'
    ];

    const toast = useRef(null);
    const dt = useRef(null);


  //  this.nameInput = React.createRef();

    useEffect(() => {
        const personnelsService = new PersonnelsService();


        personnelsService.listMatiereParEcole(idtenant)
            .then(data => {
                console.log(data)
                setmatieres(data) }) ;
        personnelsService.listClasseParEcole(idtenant)
            .then(data => {
                console.log(data)
                setclasses(data) }) ;

        personnelsService.getFonction()
            .then(data => {
                console.log(data)
                setFonctions(data) }) ;

        personnelsService.getTypePersonnel()
              .then(data => {
                console.log(data)
                setTypePersonnels(data) }) ;

        personnelsService.getPersonnelStatus()
            .then(data => {
                console.log(data)
                setPersonnelStatuts(data) }) ;

    }, []);

    useEffect(() => {
        const personnelsService = new PersonnelsService();
        personnelsService.getPersonnelParFonction("PROFESSEUR",idtenant).then(data => {
            console.log(data)
            setPersonnels(data) }) ;
        personnelSelect.personnelid = idPersonnel ;
       // setIdmodif(2);

        console.log("personnelSelect "+ personnel.personnelid) ;
  }, [idPersonnel,idModif]);



    const formatCurrency = (value) => {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }

    const openNew = () => {
        setPersonnel(emptypersonnel);
        setSubmitted(false);
        setProductDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
    }

    const hideDeleteProductDialog = () => {
        setDeleteProductDialog(false);
    }

    const hideDeleteProductsDialog = () => {
        setDeleteProductsDialog(false);
    }
   // const header2 = renderHeader2();

    const saveProduct = () => {
        setSubmitted(true);

        if (matiere.matierelibelle&& classe.classelibelle ) {

            const personnelsService = new PersonnelsService();
            personnelsService.creerPersonnelMatiereClasse(personnel.personnelid,classe.classeid,matiere.matiereid,1,idtenant) ;
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Classe attribuée avec  succès ', life: 3000 });
            setProductDialog(false);
        }
    }
    const onGlobalFilterChange2 = (e) => {
        const value = e.target.value;
        let _filters2 = { ...filters2 };
        _filters2['global'].value = value;

        setFilters2(_filters2);
        setGlobalFilterValue2(value);
    }

    const editProduct = (product) => {
         setPersonnel({ ...product });
          setProductDialog(true);
    }

    const confirmDeleteProduct = (product) => {
        setPersonnel(product);
        setDeleteProductDialog(true);
    }

    const deleteProduct = () => {
        let _personnels = personnels.filter(val => val.id !== personnel.personnelid);
        setPersonnels(_personnels);
        setDeleteProductDialog(false);
        setPersonnel(emptypersonnel);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Personnel Supprimé', life: 3000 });
    }

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < personnels.length; i++) {
            if (personnels[i].personnelid === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    const createId = () => {
        let id = '';
        let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < 5; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return id;
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const confirmDeleteSelected = () => {
        setDeleteProductsDialog(true);
    }

    const deleteSelectedProducts = () => {
        let _personnels = personnels.filter(val => !selectedProducts.includes(val));
        setPersonnels(_personnels);
        setDeleteProductsDialog(false);
        setSelectedProducts(null);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Pesonnels supprimé(e)s', life: 3000 });
    }

  /*  const onCategoryChange = (e) => {
        let _product = { ...product };
        _product['category'] = e.value;
        setProduct(_product);
    }*/

    const onMatiereChange = (e) => {

        setmatiere(e.value);
    }

    const onClasseChange = (e) => {
        setclasse(e.value);
    }

    const onTypePersonnelChange = (e) => {
        let _personnel = { ...personnel };
        _personnel['type_personnel'] = e.value;
        setPersonnel(_personnel);
    }
    const fonctionItemTemplate = (option) => {
        return <span className={`customer-badge status-${option}`}>{option}</span>;
    }
    const fonctionRowFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={fonctionFilters}   onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={fonctionItemTemplate} placeholder="Selectionner la fonction" className="p-column-filter" showClear />;
    }




    const onPersonnelStatutChange = (e) => {
        let _personnel = { ...personnel };
        _personnel['personnel_status'] = e.value;
        setPersonnel(_personnel);
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _personnel = { ...personnel };
        _personnel[`${name}`] = val;
        setPersonnel(_personnel);

    }

    const onInputNumberChange = (e, name) => {
        const val = e.value || 0;
        let _personnel = { ...personnel };
        _personnel[`${name}`] = val;

        setPersonnel(_personnel);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    {/*<Button label="Nouveau" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />*/}
                    {/*<Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedProducts || !selectedProducts.length} />*/}
                </div>
            </React.Fragment>

        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
               {/* <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" />
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />*/}
            </React.Fragment>
        )
    }

    const codeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">ID</span>
                {rowData.personnelid}
            </>
        );
    }

    const matriculeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Matricule</span>
                {rowData.personnelcode}
            </>
        );
    }

    const nomBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">NOM</span>
                {rowData.personnelnom}
            </>
        );
    }

    const prenomBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">PRENOM</span>
                {rowData.personnelprenom}
            </>
        );
    }

    const fonctionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">FONCTION</span>
                { rowData.fonction.fonctionlibelle}
            </>
        );
    }

    const typePersBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">TYPE</span>
                {rowData.type_personnel.type_personnellibelle}
            </>
        );
    }

    const statusPersBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">STATUS</span>
                {rowData.personnel_status.personnel_statulibelle}
            </>
        );
    }

    const contactBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">CONTACT</span>
                {rowData.personnel_contact}
            </>
        );
    }

    const empreinteBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">EMPREINTE</span>
                {rowData.personnel_emprunte}
            </>
        );
    }


    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editProduct(rowData)} />
                {/*<Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteProduct(rowData)} />*/}
            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Gestion personnel</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                {/*<InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />*/}
                <InputText value={globalFilterValue2} onChange={onGlobalFilterChange2} placeholder="Keyword Search" />
            </span>
        </div>
    );


        const productDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
        </>
    );
    const deleteProductDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />
        </>
    );
    const deleteProductsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductsDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedProducts} />
        </>
    );

    return (
        <div className="grid crud-demo">

            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    {/*<Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>*/}
                    <h5>Liste des Professeurs</h5>
                    <p>Vous pouvez Attribuer des matières et des classes à chaque professeur</p>
                    <DataTable ref={dt} value={personnels} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                        dataKey="personnelid" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                               filters={filters2} filterDisplay="row"  responsiveLayout="scroll"
                               globalFilterFields={['personnelcode', 'personnelnom', 'personnelprenom', 'fonction.fonctionlibelle','type_personnel.type_personnellibelle','personnel_status.personnel_statulibelle']}

                               emptyMessage="Aucun personnel trouvé." header={header} responsiveLayout="scroll">
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem'}}></Column>

                    <Column field="personnelid" header="ID" show={false} sortable body={codeBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>

                        <Column field="personnelcode" header="Matricule" sortable body={matriculeBodyTemplate} headerStyle={{ width: '10%', minWidth: '10rem' }}></Column>

                        <Column field="personnelnom" header="NOM"  sortable body={nomBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>

                        <Column field="personnelprenom" header="PRENOM"  sortable body={prenomBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>

                        <Column field="personnel_contact" header="CONTACT"  sortable body={contactBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>

                        <Column field="personnel_emprunte" header="EMPREINTE"  sortable body={empreinteBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>

                        <Column field="fonctionlibelle" header="FONCTION"  style={{ minWidth: '12rem' }}

                                body={fonctionBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }} ></Column>

                        <Column field="personneltype" header="TYPE" sortable body={typePersBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>

                        <Column field="personnelstatus" header="STATUS" sortable body={statusPersBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>

                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={productDialog} style={{ width: '650px' }} header="Details professeur" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>

                        <div className="formgrid grid">
                            <div className="field col">
                                <label htmlFor="personnelnom">Nom</label>
                                <InputText id="personnelnom" value={personnel.personnelnom +' '+ personnel.personnelprenom } disabled/>
                            </div>
                            <div className="field col">
                                <label htmlFor="matricule">Matricule</label>
                                <InputText id="matricule" value={personnel.personnelcode } disabled/>
                            </div>
                        </div>
                        <div className="formgrid grid">
                            <div className="field col">
                                <Dropdown value={matiere} options={matieres} onChange={onMatiereChange} optionLabel="matierelibelle" placeholder="Selectionnez la matiere "
                                          required autoFocus className={classNames({ 'p-invalid': submitted && !personnel.fonction })} />
                                {submitted && !matiere && <small className="p-invalid"> Matière est nécessaire.</small>}
                            </div>
                            <div className="field col">
                                <Dropdown value={classe} options={classes} onChange={onClasseChange}  optionLabel="classelibelle" placeholder="Selectionnez la classe" required autoFocus className={classNames({ 'p-invalid': submitted && !personnel.personnel_status })} />
                                {submitted && !classe && <small className="p-invalid">classe est nécessaire.</small>}

                            </div>
                        </div>

                    </Dialog>

                    <Dialog visible={deleteProductDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {personnel && <span>Are you sure you want to delete <b>{personnel.personnelcode}</b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteProductsDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductsDialogFooter} onHide={hideDeleteProductsDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {personnel && <span>Are you sure you want to delete the selected personnels?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(AttribuerMatiereClasse, comparisonFn);
