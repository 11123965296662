import axios from 'axios';
import { BaseService } from './BaseService';
const constants = {
    host: 'http://localhost:8889/gestion-ecole-api',
  };
export class EnqueteRapideService extends BaseService {

    async getListParEcoleAffandNaff(idEcole,idAnn){
        return axios.get(this.getHost()+'enquete-rapide/liste-par-ecole-aff-naff'+'/'+idEcole+'/'+idAnn).then(res => res.data);
    }

    async getListReunions(idEcole,idAnn){
        return axios.get(this.getHost()+'enquete-rapide/liste-par-ecole'+'/'+idEcole+'/'+idAnn).then(res => res.data);
    }
    async creerInfoEnqueteParNiveau(Rapide_rapideDto){
        return axios.post(this.getHost()+'enquete-rapide',Rapide_rapideDto).then(res => res.data);
    }

    async creerReunion(Rapide_rapideDto){
        return axios.post(this.getHost()+'enquete-rapide/reunion',Rapide_rapideDto).then(res => res.data);
    }


}
