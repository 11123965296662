import React, { useState, useEffect, useRef } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import classNames from 'classnames';
import { EvaluationService } from '../service/EvaluationService';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { PeriodeService } from '../service/PeriodeService';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { PersonnelService } from '../service/PersonnelService';
import { BulletinService } from '../service/BulletinService';


const ProfEvaluationStat = () => {
  let emptyPeriode = {
    id: null
  }

  let emptyEnseignant = {
    id: null
  }
  const [profStats, setProfStats] = useState({});
  const [enseignant, setEnseignant] = useState(emptyEnseignant);
  const [enseignants, setEnseignants] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [periode, setPeriode] = useState(emptyPeriode);
  const [periodes, setPeriodes] = useState([]);
  const [max, setMax] = useState(0);
  const [isSearching, setIsSearching] = useState(false);
  
  let numRow = 0;
  // const [isNotSpinning, setIsNotSpinning] = useState('hidden');
  const toast = useRef(null);


  useEffect(() => {
    const periodeService = new PeriodeService();
    periodeService.getByPeriodicite(sessionStorage.getItem('periodiciteId')).then(data => setPeriodes(data));
    const perService = new PersonnelService();
    // Le profil 8 correspond au Professeur
    perService.getListByProfil(sessionStorage.getItem('EcoleID'), 8).then(res => {
      setEnseignants(res);
    })

  }, []);

  const onSelectPeriodeChange = (e) => {
    const val = (e.target && e.target.value) || '';
    console.log(val)
    let _periode = { ...periode };
    _periode.id = val;
    setPeriode(_periode);
  }

  const onSelectEnseignantChange = (e) => {
    const val = (e.target && e.target.value) || '';
    let _enseignant = { ...enseignant };
    _enseignant.id = val;
    console.log(_enseignant)
    setEnseignant(_enseignant);
  }

  const search = () => {
    setSubmitted(true);
    if (enseignant.id && periode.id) {
      setIsSearching(true);
      // setIsNotSpinning('');

      const evaluationService = new EvaluationService();
      evaluationService.getStatistiquesProf(sessionStorage.getItem('EcoleID'), sessionStorage.getItem('AnneEncours'), periode.id, enseignant.id).then(res => {
        setProfStats(res.details);
        setMax(res.max);
        for (const [index, value] of res.details.entries()) {
          value.num = index+1
        }
        setIsSearching(false);
        // setIsNotSpinning('hidden');
      }).catch((error) => {
        if (error.response) {
          console.log('erreur response- pouls-scolaire' + error.response.status);
        } else if (error.request) {
          console.log('erreur request- pouls-scolaire' + error.request);
        } else {
          console.log('Error autres', error.message);
        }
        toast.current.show({ severity: 'error', summary: 'Erreur', detail: error.message, life: 10000 })
        setIsSearching(false);
        // setIsNotSpinning('hidden');
      });
    }
  }

  const devoirExecuteBody = (rowData) => {
    return <div>{rowData.nbreDevoirExec}</div>
  }

  const devoirNonExecuteBody = (rowData) => {
    return <div>{rowData.nbreDevoirNonExec}</div>
  }

  const interroExecuteBody = (rowData) => {
    return <div>{rowData.nbreInterroExec}</div>
  }

  const interroNonExecuteBody = (rowData) => {
    return <div>{rowData.nbreInterroNonExec}</div>
  }

  const totExecuteBody = (rowData) => {
    return <div>{rowData.nbreTotalExec}</div>
  }

  const totNonExecuteBody = (rowData) => {
    return <div>{rowData.nbreTotalNonExec}</div>
  }

  let headerGroup = <ColumnGroup>
    <Row>
      <Column header="N°" alignHeader={'center'} rowSpan={3} className='border-1'/>
      <Column header="Classe" alignHeader={'center'} rowSpan={3} className='border-1'/>
      <Column header="Matiere" alignHeader={'center'} rowSpan={3} className='border-1'/>
      <Column header="Max" alignHeader={'center'} rowSpan={3} className='border-1'/>

      <Column alignHeader={'center'} header="Devoir" colSpan={2} className='border-1' />
      <Column header="Interrogation" alignHeader={'center'} colSpan={2} className='border-1' />
      <Column header="Totaux" alignHeader={'center'} colSpan={2} className='border-1'/>
    </Row>
    <Row>
      <Column header="Exécuté" alignHeader={'center'} className='border-1'/>
      <Column header="Non exécuté" alignHeader={'center'} className='border-1'/>

      <Column header="Exécuté" alignHeader={'center'} className='border-1'/>
      <Column header="Non exécuté" alignHeader={'center'} className='border-1'/>

      <Column header="Exécuté" alignHeader={'center'} className='border-1'/>
      <Column header="Non exécuté" alignHeader={'center'} className='border-1'/>
    </Row>
  </ColumnGroup>;

  const nomPrenom = (rowData) => {
    return <div>{rowData.nom} {rowData.prenom}</div>
  }

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <div className="col-12">
            <div className="formgroup-inline">
              <div className="col-12">
                <h3><b>Statistiques des évaluations par professeur</b></h3>
              </div>
            </div>

            <div className="card grid">
              <Toast ref={toast} />
              <div className="col-12 md:col-4 lg:col-4 lg:mb-0">
                <label className="mx-3" htmlFor="classe"><b>Enseignant</b></label>
                <Dropdown id="enseignant" filter filterBy='nom' filterPlaceholder='Filtrer par le nom' value={enseignant.id} onChange={(e) => onSelectEnseignantChange(e)} options={enseignants}
                  required optionValue="id" optionLabel={nomPrenom} placeholder="Selectionner l'enseignant" className={classNames({ 'p-invalid': submitted && !(enseignant?.id) })} />
              </div>
              <div className="col-12 md:col-4 lg:col-4 lg:mb-0">
                <label className="mx-3" htmlFor="Période"><b>Période</b></label>
                <Dropdown id="periode" value={periode?.id} onChange={(e) => onSelectPeriodeChange(e)} options={periodes}
                  required optionValue="id" optionLabel="libelle" placeholder="Selectionner la période" className={classNames({ 'p-invalid': submitted && !(periode?.id) })} />
              </div>

              <div className="col-12 md:col-4 lg:col-4 lg:mb-0">
                <Button label='Rechercher' icon="pi pi-search" disabled={isSearching} className="p-button-success mr-2" onClick={search} />
                {/* <ProgressSpinner style={{ width: '30px', height: '30px' }} className={isNotSpinning} disabled={isSearching} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" /> */}
              </div>
            </div>

          </div>
          <div className="card">
            <DataTable value={profStats} headerColumnGroup={headerGroup} loading={isSearching} emptyMessage='Aucune donnée' responsiveLayout="scroll">
              <Column align={'center'} field='num'></Column>
              <Column align={'center'} field='classe' header="Moyenne"></Column>
              <Column field="matiere" header="Matiere"></Column>
              <Column align={'center'} body={max}></Column>
              <Column align={'center'} body={devoirExecuteBody}></Column>
              <Column align={'center'} body={devoirNonExecuteBody}></Column>
             
              <Column align={'center'} body={interroExecuteBody}></Column>
              <Column align={'center'}body={interroNonExecuteBody}></Column>
              
              <Column align={'center'} body={totExecuteBody}></Column>
              <Column align={'center'} body={totNonExecuteBody}></Column>
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  );
}

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(ProfEvaluationStat, comparisonFn);