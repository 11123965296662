import axios from 'axios'
import { BaseService } from './BaseService';


export class ImportEvaluationService extends BaseService {

    async importData(data, classe, annee) {
        return axios.post(this.getHost()+'evaluations-passage/import/'+classe+'/'+annee, data)
            .then(res => res.data);
    }

    async applyData(data, evImp){
        console.log(evImp);
        return axios.post(this.getHost()+'evaluations-passage/apply-data/'+evImp.matiereEcole.id+'/'+evImp.periode.id+'/'+evImp.annee.id+'/'+evImp.type.id+'/'+evImp.noteSur+'/'+evImp.user+'?date='+encodeURIComponent(evImp.date), data).then(res => res.data);
    }
}