import axios from 'axios';
import { BaseService } from './BaseService';


export class GroupeEvaluationService extends BaseService {

  async getList(annee) {
    return axios.get(this.getHost() + 'groupe-evaluations/list?annee='+annee).then(res => res.data)
  }
  async getById(id) {
    return axios.get(this.getHost() + 'groupe-evaluations/get-by-id/' + id).then(res => res.data)
  }
  async getByEcole(ecole, annee) {
    return axios.get(this.getHost() + 'groupe-evaluations/get-by-ecole-annee?ecole=' + ecole+'&annee='+annee).then(res => res.data)
  }

  async getByClasse(classe, annee) {
    return axios.get(this.getHost() + 'groupe-evaluations/get-by-classe-annee?classe=' + classe+'&annee='+annee).then(res => res.data)
  }

  async saveOrUpdate(groupe) {
    return axios.post(this.getHost() + 'groupe-evaluations/save-handle', groupe).then(res => res.data)
  }

  async deleteHandle(id){
   return axios.get(this.getHost() + 'groupe-evaluations/delete-handle/' + id).then(res => res.data)
  }
  

}