import axios from 'axios';
import { BaseService } from '../BaseService';
export class MatiereService extends BaseService {

    getAllMatiere() {
        return axios.get(this.getHost()+'matiere').then(res => res.data);
    }

    updateMatiere(idMatiere,Matiere) {
        return axios.put(this.getHost()+'matiere/'+idMatiere,Matiere).then(res => res.data);
    }

    deleteMatiere(idMatiere) {
        return axios.delete(this.getHost()+'matiere/'+idMatiere).then(res => res.data);
    }

    creerMatiere(Matiere) {
        return axios.post(this.getHost()+'matiere/',Matiere).then(res => res.data);
    }


    getProductsSmall() {
        return axios.get('assets/demo/data/products-small.json').then(res => res.data.data);
    }

    getProducts() {
        return axios.get('assets/demo/data/products.json').then(res => res.data.data);
    }

    getProductsWithOrdersSmall() {
        return axios.get('assets/demo/data/products-orders-small.json').then(res => res.data.data);
    }
}
