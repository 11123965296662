import axios from 'axios';
import { BaseService } from './BaseService';

export class EvaluationPeriodeService extends BaseService {
  async findByAnneeAndEcoleAndPeriodeAndNiveau(annee, ecole, periode, niveau) {
    return axios.get(this.getHost() + 'evaluation-periode/get-by-annee-ecole-periode-niveau/' + annee + '/' + ecole + '/' + periode + '/' + niveau).then(res => res.data)
  }

  async findByAnneeAndEcoleAndNiveau(annee, ecole, niveau) {
    return axios.get(this.getHost() + 'evaluation-periode/get-by-annee-ecole-niveau/' + annee + '/' + ecole + '/' + niveau).then(res => res.data)
  }

  async findByAnneeAndEcole(annee, ecole) {
    return axios.get(this.getHost() + 'evaluation-periode/get-by-annee-ecole/' + annee + '/' + ecole).then(res => res.data)
  }

  async findById(id) {
    return axios.get(this.getHost() + 'evaluation-periode/get-by-id/' + id).then(res => res.data)
  }

  async saveHandle(evaluationPeriode) {
    return axios.post(this.getHost() + 'evaluation-periode/save-handle', evaluationPeriode).then(resp => resp.data);
  }

  async deleteProcess(id) {
    return axios.delete(this.getHost() + 'evaluation-periode/delete-process/'+ id).then(resp => resp.data);
  }
}
