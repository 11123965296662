import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

import { Password } from 'primereact/password';

import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';

import '../../assets/login/FormDemo.css';
import '../../App.scss';

import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'prismjs/themes/prism-coy.css';
import '../../assets/demo/flags/flags.css';
import '../../assets/demo/Demos.scss';
import '../../assets/layout/layout.scss';

import {Route, useLocation} from "react-router-dom";
import { useHistory } from "react-router-dom";

import SouscripEcole from "./SouscripEcole";

import App2 from "../../App2";
//import classNames from 'classnames';



export const Connexion = ({onClick,myvisible}) => {
    // let  connexioDtoEmpty = {
    //     ecoleid: null,
    //     profilid:null,
    //     email:'4555',
    //     motdePasse:'446'
    // };

    // const [connProfil, setConnProfil] = useState('');
    const [callCompte, setcallCompte] = useState(null);

    // const [titleName, setTitleName] = useState('');
    // const location = useLocation();
    // const [countries, setCountries] = useState([]);
    const [showMessage, setShowMessage] = useState(false);
    // const [connexionDto, setConnexionDto] = useState(connexioDtoEmpty);
    // const [retourConnexion, setRetourConnexion] = useState(null);
    const [passWord, setPassWord] = useState('soum@1111');
    const [monEmail, setMonEmail] = useState('soumsmith1@gmail.com');
    const [codeEcole, setCodeEcole] = useState('01');
    const [formData, setFormData] = useState({});
    let history = useHistory();
  //  const countryservice = new CountryService();
    const defaultValues = {
        name: '',
        email: '',
        password: '',
        date: null,
        country: null,
        accept: false,
        text:''
    }

    // const Profils = [
    //     {codeProfil: 'ADMIN', libelleProfil: 'ADMINISTRATEUR'},
    //     {codeProfil: 'FOND', libelleProfil: 'FONDATEUR'}
    // ];

// eslint-disable-line react-hooks/exhaustive-deps

    const { control, formState: { errors }, handleSubmit, reset } = useForm({ defaultValues });

    const onSubmit = () => {
     /*   connexionDto.email = monEmail ;
        connexionDto.ecoleid = 1 ;
        connexionDto.profilid = 2 ;
        connexionDto.motdePasse = passWord ;
        const  connexServ= new ConnexionService() ;
        connexServ.seConnecter(connexionDto)
            .then(data => {
                console.log(data)
                setRetourConnexion(data)
            });

        localStorage.setItem("user-info",retourConnexion) ;*/
        setcallCompte(2);
       history.push('/home/professionnelle');

    }

    // const getFormErrorMessage = (name) => {
    //     return errors[name] && <small className="p-error">{errors[name].message}</small>
    // };
    // const menu = [];
    const dialogFooter = <div className="flex justify-content-center"><Button label="OK" className="p-button-text" autoFocus onClick={() => setShowMessage(false)} /></div>;
    // const passwordHeader = <h6>Choisir un mot de passe</h6>;
    // const passwordFooter = (
    //     <React.Fragment>
    //         <Divider />
    //         <p className="mt-2">Suggestions</p>
    //         <ul className="pl-2 ml-2 mt-0" style={{ lineHeight: '1.5' }}>
    //             <li>Au moins une minuscule</li>
    //             <li>Au moins une majuscule</li>
    //             <li>Au moins un chiffre</li>
    //             <li>8 caractères minimum</li>
    //         </ul>
    //     </React.Fragment>
    // );
    // const creerCompte = () => {
    //     setcallCompte(1);
    // }
    // const creerEcole = () => {
    //     setcallCompte(2);
    // }
    return (
        <div>
            {
                (() => {

                    if(callCompte===1) {
                        return (
                            <Route path="/home/professionnelle" component={App2} />
                          // <App2></App2>
                        )
                    }


                    else if (callCompte===2) {
                        return (
                          <SouscripEcole></SouscripEcole>
                        )
                    }



                    else {
                        return (

                            <div className="form-demo">
                                <Dialog visible={showMessage} onHide={() => setShowMessage(false)} position="top" footer={dialogFooter} showHeader={false} breakpoints={{ '960px': '80vw' }} style={{ width: '30vw' }}>
                                    <div className="flex justify-content-center flex-column pt-6 px-3">
                                        <i className="pi pi-check-circle" style={{ fontSize: '5rem', color: 'var(--green-500)' }}></i>
                                        <h5>Registration Successful!</h5>
                                        <p style={{ lineHeight: 1.5, textIndent: '1rem' }}>
                                            Your account is registered under name <b>{formData.name}</b> ; it'll be valid next 30 days without activation. Please check <b>{formData.email}</b> for activation instructions.
                                        </p>
                                    </div>
                                </Dialog>

                                <div className="flex flex-column align-items-center justify-content-center">

                                        <form onSubmit={handleSubmit(onSubmit)} >
                                            <div className="flex flex-column align-items-center justify-content-center">
                                            <div style={{ borderRadius: '56px', padding: '0.3rem', background: 'linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%)' }}>
                                                    <div className="w-full surface-card py-8 px-5 sm:px-8" style={{ borderRadius: '53px' }}>
                                                                <div className="text-center mb-5">

                                                                    <div className="text-900 text-3xl font-medium mb-3">Bienvenu chez Vie-ecoles!</div>
                                                                    <span className="text-600 font-medium">Connectez vous pour continuer</span>
                                                                </div>
                                                                    <div>
                                                                       <label htmlFor="email1" className="block text-900 text-xl font-medium mb-2">
                                                                        Email
                                                                    </label>
                                                                    <InputText inputid="email1" type="text" value={monEmail} onChange={(e) => setMonEmail(e.target.value)} placeholder="Adresse email" className="w-full md:w-30rem mb-5" style={{ padding: '1rem' }} />

                                                                      <label htmlFor="passWord" className="block text-900 text-xl font-medium mb-2">
                                                                            Mot de passe
                                                                        </label>
                                                                        <Password inputid="passWord" value={passWord} onChange={(e) => setPassWord(e.target.value)} placeholder="Password" toggleMask className="w-full mb-5" inputClassName='w-full p-3 md:w-30rem'></Password>

                                                                        <label htmlFor="codeEcole" className="block text-900 text-xl font-medium mb-2">
                                                                            Code ecole
                                                                        </label>
                                                                        <InputText inputid="codeEcole" value={codeEcole} onChange={(e) => setCodeEcole(e.target.value)}type="text" placeholder="Code ecole" className="w-full md:w-30rem mb-5" style={{ padding: '1rem' }} />


                                                                    </div>
                                                        <Button type="submit" label="Submit" className="w-full p-3 text-xl" ></Button>

                                                    </div>
                                            </div>
                                            </div>
                                        </form>

                                </div>


                            </div>

                        )
                    }
                })()
            }
        </div>




    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

//export default React.memo(Connexion, comparisonFn);
export default Connexion;
