import axios from 'axios';

// const this.getHost() ="https://pouls-scolaire.com/api/";
import { BaseService } from '../BaseService';
export class BulletinsService extends BaseService{


    listBulletinsGeneres(classe,idEcole,libelleAnnee,libelleTrimetre) {
        return axios.get(this.getHost()+'imprimer-bulletin/bulletin-genere-par-classe/'+classe+'/'+idEcole+'/'+libelleAnnee+'/'+libelleTrimetre).then(res => res.data);
    }

    miseAjourHeureAbsence(matricule,idEcole,libelleAnnee,libelleTrimetre,heureAbsenJusti,heureAbsenNonJusti) {
        return axios.put(this.getHost()+'imprimer-bulletin/mise-a-jours-heures-absences/'+matricule+'/'+idEcole+'/'+libelleAnnee+'/'+libelleTrimetre+'/'+heureAbsenJusti+'/'+heureAbsenNonJusti).then(res => res.data);
    }



}
