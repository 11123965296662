import axios from 'axios';
import { BaseService } from './BaseService';

export class SalleService extends BaseService{

    getList(){
        return axios.get(this.getHost()+'salle/list').then(res => res.data);
    }

    getByEcole(ecole){
        console.log('salle - getByEcole '+ecole)
        return axios.get(this.getHost()+'salle/list-by-ecole?ecole='+ecole).then(res => res.data);
    }
   
    getSallesDispoHeures(annee, classe, jour,dateSeance, heureDeb, heureFin){
        console.log(dateSeance);
        let date = (dateSeance !== null ? dateSeance.toISOString().split('T')[0] : '');
        return axios.get(this.getHost()+'salle/get-salles-dispo-heures?annee='+annee+'&classe='+classe+'&jour='+jour+'&date='+date+'&heureDeb='+heureDeb+'&heureFin='+heureFin).then(res => res.data);
    }
    
// A actualiser
    save(salle){
        axios.post(this.getHost()+'salle/save',salle);
    }

    update(salle){
       return axios.post(this.getHost()+'salle/update',salle).then(response => response.data);
    }

    updateAndDisplay(salle){
        return axios.post(this.getHost()+'salle/update-display',salle).then(res => res.data);
     }
     
    saveAndDisplay(salle){
        return axios.post(this.getHost()+'salle/saveAndDisplay',salle).then(res => res.data);        
    }


    delete(id){
        return axios.delete(this.getHost()+'salle/delete/'+id);
    }
}
 