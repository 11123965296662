import React, {useRef, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Password } from 'primereact/password';
import { InputNumber } from 'primereact/inputnumber';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { classNames } from 'primereact/utils';
import '../../assets/login/FormDemo.css';
import '../../App.scss';
import App from "../../App";
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'prismjs/themes/prism-coy.css';
import '../../assets/demo/flags/flags.css';
import '../../assets/demo/Demos.scss';
import '../../assets/layout/layout.scss';
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import { ProgressBar } from 'primereact/progressbar';
import {AppConfig} from "../../AppConfig";
import {CSSTransition} from "react-transition-group";
import {useLocation} from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Tooltip } from 'primereact/tooltip';
import ScrollToTop from "../../ScrollToTop";
import {AutoComplete} from "primereact/autocomplete";
import {SouscriptionService} from '../../service/operations/SouscriptionService';
import Connexion from "./Connexion";
import moment from "moment";
import {Chips} from "primereact/chips";
import {InputMask} from "primereact/inputmask";
import {MultiSelect} from "primereact/multiselect";
import {InputTextarea} from "primereact/inputtextarea";
import {ParentService} from "../../service/parametre/ParentService";

import  '../css/style.css';
import ConnexionCandidat from "./ConnexionCandidat";
import PageAccueil from "./PageAccueil";


//import classNames from 'classnames';



export const CompteProfesseur = () => {

    let emptyDomaineFormation = {
        domaine_formationid :null ,
        domaine_formation_code :'',
        domaine_formation_libelle:''
     };
    let emptyTypeAutorisation = {
        idtype_autorisationid :null,
        type_autorisation_code :'' ,
        type_autorisation_libelle :''
    };

    let emptySexe = {
        sexe_code :'',
        sexe_libelle :''
    };
    let emptyfonction = {
        fonctionid :null ,
        fonctioncode :'',
        fonctionlibelle:''
    };
    let emptyNiveauEtude = {
        niveau_etudeid :null ,
        niveau_etude_code :'',
        niveau_etude_libelle:''
    };
    let emptyAnneeExperience = {
        nbreAnnee_code :0 ,
        nbreAnnee_libelle :''
    };
    const defaultValues2 = {
        sous_attent_personnid: null,
        sous_attent_personn_nom: '',
        sous_attent_personn_prenom: '',
        sous_attent_personn_email: '',
        sous_attent_personn_login: '',
        sous_attent_personn_sexe: '',
        sous_attent_personn_diplome_recent:'',
        sous_attent_personn_date_naissance: null,
        sous_attent_personn_nbre_annee_experience:'',
        sous_attent_personn_lien_cv:'',
        niveau_etudeIdentifiant:'',
        sous_attent_personncode:'',
        identifiantdomaine_formation:'',
        fonctionidentifiant:null,
        type_autorisation_idtype_autorisationid:null ,

        sous_attent_personn_password:'',
        sous_attent_personn_donnee: null,
       sous_attent_personn_contact :'',
       sous_attent_personn_lien_piece:'',
       sous_attent_personn_lien_autorisation :''
    }
    const defaultValues3 = {
        sous_attent_personnid: null,
        sous_attent_personn_nom: '',
        sous_attent_personn_prenom: '',
        sous_attent_personn_email: '',
        sous_attent_personn_sexe: '',
        sous_attent_personn_diplome_recent:'',
        sous_attent_personn_date_naissance: null,
        sous_attent_personn_nbre_annee_experience:0,
        sous_attent_personn_lien_cv:'',
        niveau_etudeIdentifiant:'',
        sous_attent_personncode:'',
        identifiantdomaine_formation:'',
        sous_attent_personn_login:'',
        sous_attent_personn_password:''
    }

    const [contact, setcontact] = useState('');
     const [retourApi, setretourApi] = useState('');
    const [motDepass, setmotDepass] = useState('');
    const [email, setemail] = useState('');
    const [login, setLogin] = useState('');
    const [message, setmessage] = useState('');
    const [nom, setnom] = useState('');
    const [prenom, setprenom] = useState('');
    const [diplomeRecent, setdiplomeRecent] = useState('');
    const [date_naissance, setdate_naissance] = useState('05/11/1990');
    const [nbre_annee_experience, setnbre_annee_experience] = useState(emptyAnneeExperience);
    const [personn_lien_cv, setpersonn_lien_cv] = useState('');
    const [personncode, setpersonncode] = useState('');
      const [modif, setmodif] = useState(1);
    const [modif2, setmodif2] = useState(1);

    const [sexe, setSexe] = useState(emptySexe);
    const [typeAutorisation, settypeAutorisation] = useState(emptyTypeAutorisation);
    const [typeAutorisations, settypeAutorisations] = useState([]);
    const [domaineFormation, setDomaineFormation] = useState(emptyDomaineFormation);
    const [fonction, setFonction] = useState(emptyfonction);
    const [fonctions, setFonctions] = useState([]);
    const [domaineFormations, setDomaineFormations] = useState([]);
    const [inscriptionAttente, setInscriptionAttente] = useState((defaultValues2));
    const [inscriptionAttentes, setInscriptionAttentes] = useState([]);

    const [niveauEtude, setNiveauEtude] = useState(emptyNiveauEtude);
    const [niveauEtudes, setNiveauEtudes]= useState([]);

    const [callCompte, setcallCompte] = useState(null);
    const [layoutMode, setLayoutMode] = useState('static');
    const [layoutColorMode, setLayoutColorMode] = useState('light')
    const [inputStyle, setInputStyle] = useState('outlined');
    const [ripple, setRipple] = useState(true);
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
    const [titleName, setTitleName] = useState('');
    const location = useLocation();
    const [countries, setCountries] = useState([]);
    const [autoValue, setAutoValue] = useState(null);
    const [showMessage, setShowMessage] = useState(false);
    const [showMessage2, setShowMessage2] = useState(false);
    const [selectedAutoValue, setSelectedAutoValue] = useState(null);
    const [autoFilteredValue, setAutoFilteredValue] = useState([]);
    const [formData, setFormData] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const [file0, setFile0] = useState('');
    const [file1, setFile1] = useState('');
    const [files, setFiles] = useState([]);
    const [fileLong, setFileLong] = useState(0);
    const [productDialog, setProductDialog] = useState(false);
    let history = useHistory();

    const [totalSize, setTotalSize] = useState(0);
    const toast = useRef(null);
    const fileUploadRef = useRef(null);

    const onSubmitFile = (e) => {
        const  domainServ= new SouscriptionService();
        domainServ.checkpseudo(login)
            .then(data => {
                console.log(data)
                if(data==='Ce login est déjà utilisé!'){
                    toast.current.show({ severity: 'error', summary: 'Message d"erreur', detail: 'Ce login est déjà utilisé!', life: 3000 });
                } else {
                    setSubmitted(true);
                    console.log("files "+files) ;
                    console.log("Longueur Fichier "+files.length) ;
                    const data = new FormData();

                    if ( motDepass.trim()&& login.trim()
                        && email.trim() && nom.trim() && prenom.trim()&& domaineFormation.domaine_formation_code.trim() && niveauEtude.niveau_etude_code.trim() &&
                        sexe.sexe_code.trim()  && contact.trim() && nbre_annee_experience.nbreAnnee_code && date_naissance && fonction.fonctioncode.trim()&& file0 && file1 ) {

                        for(let i = 0; i < files.length; i++) {
                            data.append('file', files[i]);
                        }

                        const  domainServ= new SouscriptionService() ;
                        domainServ.chargerFichiers(data)
                            .then((response) => {

                                saveSouscr();

                            })
                            .catch((e) => {
                                // toast.error('Upload Error')
                            })


                    } else {
                        toast.current.show({severity:'error', summary: 'Vie-ecoles: Messages d"erreur', detail:'Veuillez saisir les champs obligatoires', life: 3000});

                        // showError();
                    }


                }

            });



    };
    const onLoadFileChange = (e) => {
        setFiles(e.target.files)
    };
    const onLoadFile0Change = (e) => {
        setFile0(e.target.files);
        console.log(e.target.files) ;
        setFiles(current => [...current, e.target.files[0]]) ;


    };
    const onLoadFile1Change = (e) => {
        setFile1(e.target.files);
        setFiles(current => [...current, e.target.files[0]]) ;

    };

    const onLoadFile2Change = (e) => {
        console.log(e.target.files) ;
        setFiles(current => [...current, e.target.files[0]]) ;
    };

    const openNew = () => {
       setProductDialog(true);
    }

    const onUpload = () => {
        toast.current.show({severity: 'info', summary: 'Success', detail: 'File Uploaded'});
    }
    /*const productDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={onSubmitFile} />
        </>
    );*/
    const hideDialog = () => {
      //  setSubmitted(false);
        setProductDialog(false);
    }
    const searchCountry = (event) => {
        setTimeout(() => {
            if (!event.query.trim().length) {
                setAutoFilteredValue([...autoValue]);
            }
            else {
                setAutoFilteredValue(autoValue.filter((country) => {
                    return country.name.toLowerCase().startsWith(event.query.toLowerCase());
                }));
            }
        }, 250);
    };


    useEffect(() => {
 const  domainServ= new SouscriptionService() ;
        domainServ.listDomaineFormation().then(data => setDomaineFormations(data));
        domainServ.listNiveau_etude().then(data => setNiveauEtudes(data));
        domainServ.listTypeAutorisation().then(data => settypeAutorisations(data));
        domainServ.listfonction("fondateur").then(data => setFonctions(data));

    }, []);




    useEffect(() => {
        const  domainServ= new SouscriptionService() ;
        console.log("modif--- "+modif)
        let message ;
        let subject ;
        subject="VOS PARAMETRES DE CONNEXION A POULS-SCOLAIRE"
        message ="Vos paramètres de connexion pour la suite du processus :  \n"+"Login: "+login+ "\n"+"    Mot de passe:"+motDepass;
        if (modif==2){
            domainServ.creerSoucriptProfesseur(inscriptionAttente)
                .then(data => {
                    console.log(data)
                    setretourApi(data)

                    if(data!=="EXISTE_DEJA !"){
                        setShowMessage(true)
                        domainServ.sendEmail(email,message,subject);
                    } else {
                        setShowMessage2(true)
                    }

                });

        }

    }, [modif]);



    const { control, formState: { errors }, handleSubmit, reset } = useForm({ defaultValues3 });



    const retourner = () => {

        setShowMessage(false);
        setInscriptionAttente(defaultValues2);
       // setcallCompte(1);
        setDomaineFormation(emptyDomaineFormation);
        setFonction(emptyfonction);
        settypeAutorisation(emptyTypeAutorisation);
        setNiveauEtude(emptyNiveauEtude);

        setmotDepass('') ;
        setemail('') ;
        setnom('');
        setprenom('');
        setSexe(emptySexe);
        setnbre_annee_experience(emptyAnneeExperience);
        setdate_naissance('');
        setdiplomeRecent('');
        setFiles([]) ;
        setFile0('');
        setFile1('');
        setcontact('');
        window.location.reload(false);
    }
    const onTypeParenSexeChange = (e) => {
        setSexe(e.value);
    } ;
    const onNiveauEtudeChange = (e) => {
        setNiveauEtude(e.value);

    } ;
    const onNbreExperienceChange = (e) => {
        setnbre_annee_experience(e.value);

    } ;
    const onDomaineFormationChange = (e) => {
        setDomaineFormation(e.value);
    } ;

    const onTypeAutorisationChange = (e) => {
        settypeAutorisation(e.value);
    } ;
    const onFonctionChange = (e) => {
        setFonction(e.value);
    } ;

    const Sexes = [
        {sexe_code: 'MASCULIN', sexe_libelle: 'MASCULIN'},
        {sexe_code: 'FEMININ', sexe_libelle: 'FEMININ'}

    ];



    const NbreAnneExperiences = [
        {nbreAnnee_code: 1, nbreAnnee_libelle: '01'},{nbreAnnee_code: 2, nbreAnnee_libelle: '02'},{nbreAnnee_code: 3, nbreAnnee_libelle: '03'},
        {nbreAnnee_code: 4, nbreAnnee_libelle: '04'},{nbreAnnee_code: 5, nbreAnnee_libelle: '05'},{nbreAnnee_code: 6, nbreAnnee_libelle: '06'},
        {nbreAnnee_code: 7, nbreAnnee_libelle: '07'},{nbreAnnee_code: 8, nbreAnnee_libelle: '08'},{nbreAnnee_code: 9, nbreAnnee_libelle: '09'},
        {nbreAnnee_code: 10, nbreAnnee_libelle: '10'},{nbreAnnee_code: 11, nbreAnnee_libelle: '11'},{nbreAnnee_code: 12, nbreAnnee_libelle: '12'},
        {nbreAnnee_code: 13, nbreAnnee_libelle: '13'},{nbreAnnee_code: 14, nbreAnnee_libelle: '14'},{nbreAnnee_code: 15, nbreAnnee_libelle: '15'},
        {nbreAnnee_code: 16, nbreAnnee_libelle: '16'},{nbreAnnee_code: 17, nbreAnnee_libelle: '17'},{nbreAnnee_code: 18, nbreAnnee_libelle: '18'},
        {nbreAnnee_code: 19, nbreAnnee_libelle: '19'},{nbreAnnee_code: 20, nbreAnnee_libelle: '20'},{nbreAnnee_code: 21, nbreAnnee_libelle: '21'},
        {nbreAnnee_code: 22, nbreAnnee_libelle: '22'},{nbreAnnee_code: 23, nbreAnnee_libelle: '23'},{nbreAnnee_code: 24, nbreAnnee_libelle: '24'},
        {nbreAnnee_code: 25, nbreAnnee_libelle: '25'},{nbreAnnee_code: 26, nbreAnnee_libelle: '26'},{nbreAnnee_code: 27, nbreAnnee_libelle: '27'},
        {nbreAnnee_code: 28, nbreAnnee_libelle: '28'},{nbreAnnee_code: 29, nbreAnnee_libelle: '29'},{nbreAnnee_code: 30, nbreAnnee_libelle: '30'}

    ];

    const showError = () => {
        toast.current.show({severity:'error', summary: 'Error Message', detail:'Message Content', life: 3000});
    }
    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };
    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';

    }
    const customBase64Uploader = async (event) => {
        // convert file to base64 encoded
        const file = event.files[0];
        const reader = new FileReader();
        let blob = await fetch(file.objectURL).then(r => r.blob()); //blob:url
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
            const base64data = reader.result;
            console.log(base64data);
            inscriptionAttente.sous_attent_personn_donnee= base64data ;
        }
    }
    const saveSouscr = () => {
            const  domainServ= new SouscriptionService() ;


        inscriptionAttente.identifiantdomaine_formation = domaineFormation.domaine_formationid ;
        inscriptionAttente.fonctionidentifiant = fonction.fonctionid ;
        inscriptionAttente.type_autorisation_idtype_autorisationid = typeAutorisation.idtype_autorisationid ;
        inscriptionAttente.niveau_etudeIdentifiant = niveauEtude.niveau_etudeid ;
        inscriptionAttente.sous_attent_personn_login = login ;
        inscriptionAttente.sous_attent_personn_password= motDepass ;
        inscriptionAttente.sous_attent_personn_email= email ;
        inscriptionAttente.sous_attent_personn_nom = nom ;
        inscriptionAttente.sous_attent_personn_prenom = prenom ;
        inscriptionAttente.sous_attent_personn_sexe = sexe.sexe_code ;
        inscriptionAttente.sous_attent_personn_nbre_annee_experience = nbre_annee_experience.nbreAnnee_code ;
        inscriptionAttente.sous_attent_personn_date_naissance = moment(date_naissance).utc().format('YYYY-MM-DD')
        inscriptionAttente.sous_attent_personn_diplome_recent = diplomeRecent ;
        if (files.length===3){
            inscriptionAttente.sous_attent_personn_lien_cv=files[0].name ;
            inscriptionAttente.sous_attent_personn_lien_autorisation =files[1].name ;
            inscriptionAttente.sous_attent_personn_lien_piece =files[2].name ;
        } else {
            inscriptionAttente.sous_attent_personn_lien_cv=files[0].name ;
            inscriptionAttente.sous_attent_personn_lien_autorisation =files[1].name ;
        }

        inscriptionAttente.sous_attent_personn_contact= contact ;
        setmodif(2);




    }

    const dialogFooter = <div className="flex justify-content-center"><Button label="OK" className="p-button-text" autoFocus onClick={() => retourner()} /></div>;
    const passwordHeader = <h6>Choisir un mot de passe</h6>;
    const passwordFooter = (
        <React.Fragment>
            <Divider />
            <p className="mt-2">Suggestions</p>
            <ul className="pl-2 ml-2 mt-0" style={{ lineHeight: '1.5' }}>
                <li>Au moins une minuscule</li>
                <li>Au moins une majuscule</li>
                <li>Au moins un chiffre</li>
                <li>8 caractères minimum</li>
            </ul>
        </React.Fragment>
    );

    return (
        <div className="card">
            {/*<form  onSubmit={onSubmitFile}>*/}
            <h5>Créer son compte Pouls-scolaire</h5>
            <div>
                <Toast ref={toast} />
            </div>
            <div className="grid p-fluid mt-3">
                <Dialog visible={showMessage} onHide={() => setShowMessage(false)} position="top" footer={dialogFooter} showHeader={false} breakpoints={{ '960px': '80vw' }} style={{ width: '30vw' }}>
                    <div className="flex justify-content-center flex-column pt-6 px-3">
                        <i className="pi pi-check-circle" style={{ fontSize: '5rem', color: 'var(--green-500)' }}></i>
                        <h5>{retourApi}</h5>

                        <p style={{ lineHeight: 1.5, textIndent: '1rem' }}>
                            Votre demande a éte enregistrée sous le nom  <b>{nom}</b> <b>{prenom}</b> ; Votre compte sera bientôt activé. Veuillez verifier l'adresse <b>{email}</b> pour la suite du processus.
                        </p>
                    </div>
                </Dialog>

                <Dialog visible={showMessage2} onHide={() => setShowMessage2(false)} position="top" footer={dialogFooter} showHeader={false} breakpoints={{ '960px': '80vw' }} style={{ width: '30vw' }}>
                    <div className="flex justify-content-center flex-column pt-6 px-3">
                        <i className="pi pi-ban" style={{ fontSize: '5rem', color: 'var(--red-500)' }}></i>
                        <h5>Ce Compte existe déjà!</h5>
                        <p style={{ lineHeight: 1.5, textIndent: '1rem' }}>
                            Veuillez lancer le processus de récuperation de mot de passe   afin de recevoir vos informations d'identifications par mail!
                        </p>
                    </div>
                </Dialog>

                <div className="field col-12 md:col-4">
                                <span className="p-float-label">
                                    <InputText type="text" id="Email" value={email} onChange={(e) => setemail(e.target.value)} required />
                                    <label htmlFor="Email">Email*</label>
                                </span>
                </div>

                <div className="field col-12 md:col-4">
                                <span className="p-float-label">
                                    <InputText type="text" id="login" value={login} onChange={(e) => setLogin(e.target.value)} required />
                                    <label htmlFor="login">Login*</label>
                                </span>
                </div>
                <div className="field col-12 md:col-4">
                                            <span className="p-float-label">
                                                <Password value={motDepass} onChange={(e) => setmotDepass(e.target.value)} required toggleMask />
                                                     <label htmlFor="password">Mot de passe*</label>
                                            </span>
                </div>



                <div className="field col-12 md:col-4">
                            <span className="p-float-label">
                                <InputText type="text" id="Nom" value={nom} onChange={(e) => setnom(e.target.value)} required />
                                <label htmlFor="Nom">Nom*</label>
                            </span>
                </div>

                <div className="field col-12 md:col-4">
                                <span className="p-float-label">
                                    <InputText type="text" id="Prenom" value={prenom} onChange={(e) => setprenom(e.target.value)}  required />
                                    <label htmlFor="Prenom">Prénon*</label>
                                </span>
                </div>
                <div className="field col-12 md:col-4">
                                <span className="p-float-label">
                                    <InputText type="text" id="contact" value={contact} onChange={(e) => setcontact(e.target.value)}  required />
                                    <label htmlFor="contact">Contact*</label>
                                </span>
                </div>

                <div className="field col-12 md:col-4">
                                <span className="p-float-label">
                        <Calendar showIcon  required id="basic" value={date_naissance} onChange={(e) => setdate_naissance(e.value)} dateFormat="dd/mm/yy" />
                         <label htmlFor="basic">Date de naissance*(dd/mm/yyyy )</label>
                                </span>
                </div>


                <div className="field col-12 md:col-4">
                            <span className="p-float-label">
                              <Dropdown  id="dropdown"  required  options={Sexes} value={sexe} onChange={onTypeParenSexeChange} optionLabel="sexe_libelle"  >
                                 </Dropdown>
                                   <label htmlFor="dropdown">Sexe*</label>
                            </span>
                </div>
                <div className="field col-12 md:col-4">
                        <span className="p-float-label">
                            <InputText type="text" id="DiplomeRecent"  value={diplomeRecent}  onChange={(e) => setdiplomeRecent(e.target.value)} />
                            <label htmlFor="DiplomeRecent">Diplôme recent</label>
                        </span>
                </div>
                <div className="field col-12 md:col-4">
                            <span className="p-float-label">
                               <Dropdown id="nbreAnneeExperience"   required options={NbreAnneExperiences} value={nbre_annee_experience} onChange={onNbreExperienceChange} optionLabel="nbreAnnee_libelle"  ></Dropdown>
                                                <label htmlFor="nbreAnneeExperience">Nombre d'année d'experience*</label>
                            </span>
                </div>
                <div className="field col-12 md:col-4">
                            <span className="p-float-label">
                              <Dropdown id="domaineFormation"  required options={domaineFormations} value={domaineFormation} onChange={onDomaineFormationChange} optionLabel="domaine_formation_libelle" filter showClear filterBy="domaine_formation_libelle"
                              ></Dropdown>
                              <label htmlFor="domaineFormation">Domaine de formation*</label>
                            </span>
                </div>
                <div className="field col-12 md:col-4">
                        <span className="p-float-label">
                          <Dropdown id="niveauEtude"   required options={niveauEtudes} value={niveauEtude} onChange={onNiveauEtudeChange} optionLabel="niveau_etude_libelle"

                          ></Dropdown>
                                                <label htmlFor="niveauEtude">Niveau d'etude*</label>
                        </span>
                </div>
                <div className="field col-12 md:col-4">
                        <span className="p-float-label">
                          <Dropdown id="fonction"    required options={fonctions} value={fonction} onChange={onFonctionChange} optionLabel="fonctionlibelle"
                          ></Dropdown>
                          <label htmlFor="fonction">Fonction *</label>
                        </span>
                </div>


                <div className="field col-12 md:col-4">
                        <span className="p-float-label">
                          <Dropdown id="typeAutorisation"   options={typeAutorisations} value={typeAutorisation} onChange={onTypeAutorisationChange} optionLabel="type_autorisation_libelle"
                          ></Dropdown>
                          <label htmlFor="typeAutorisation">type autorisation</label>
                        </span>
                </div>



                <div className="field col-12 ">

                    <div className="formgrid grid">
                        <div className="field col">
                                          <label htmlFor="file1">Charger votre pièce*</label>
                            <InputText required id="file1" type="file" onChange={onLoadFile0Change}
                                       multiple="" />

                        </div>
                        <div className="field col">
                            <label htmlFor="file2">Charger votre cv*</label>
                            <InputText required id="file2" type="file" onChange={onLoadFile1Change}
                                       multiple=""/>
                        </div>
                        <div className="field col">
                            <label htmlFor="file3">Charger l'autorisation d'enseigner</label>
                            <InputText  id="file3" type="file" onChange={onLoadFile2Change}
                                        multiple=""/>
                        </div>

                    </div>
                    <div className="col-12 md:col-2">
                        <div className="card p-fluid">
                            <div className="field">
                                <Button label="Envoyer" onClick={onSubmitFile} className="mt-2"/>
                            </div>
                            {/* <div className="field">
                                <Button label="Retourner" className="p-button-link"   onClick={retourner}/>
                                </div>*/}
                        </div>
                    </div>
                </div>
            </div>

            {/*</form>*/}
        </div>

    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

//export default React.memo(Login, comparisonFn);
export default CompteProfesseur;
