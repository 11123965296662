import React, { useState, useEffect, useRef } from 'react';
// eslint-disable-next-line
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { AnneeService } from '../service/AnneeService';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Tag } from 'primereact/tag';
import { RadioButton } from 'primereact/radiobutton';
import './css/ButtonDemo.css';
import { PeriodiciteService } from '../service/PeriodiciteService';
import { NiveauEnseignementService } from '../service/NiveauEnseignementService';
import { Calendar } from 'primereact/calendar';
import { PeriodeService } from '../service/PeriodeService';
import 'primereact/resources/themes/saga-blue/theme.css';
import { BiBroadcast } from "react-icons/bi";
import { AiOutlineMinus } from "react-icons/ai";
import { ProgressBar } from 'primereact/progressbar';


const OpenAnnee = () => {

    const [annee, setAnnee] = useState();
    const [annees, setAnnees] = useState([]);
    const [globalFilter, setGlobalFilter] = useState(null);
    const dt = useRef(null);
    const toast = useRef(null);
    const [submitted, setSubmitted] = useState(false);
    const [anneeDialog, setAnneeDialog] = useState(false);
    const [headerTitle, setHeaderTitle] = useState("");
    const [anneeToDelete, setAnneeToDelete] = useState(null)
    const [anneeToShare, setAnneeToShare] = useState({});
    const [niveauxEnseignement, setNiveauxEnseignement] = useState([]);
    const [periodicites, setPeriodicites] = useState([]);
    const [periodes, setPeriodes] = useState([]);
    const [selectedPeriodicite, setSelectedPeriodicite] = useState({ id: 2 });
    const [marqueurIndex, setMarqueurIndex] = useState(0);
    const [calendarList, setCalendarList] = useState([]);
    const [myArrayDeb, setMyArrayDeb] = useState(new Array(12).fill(null));
    const [myArrayFin, setMyArrayFin] = useState(new Array(12).fill(null));
    const [typeAction, setTypeAction] = useState('new');
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [openShareConfirmDialog, setOpenShareConfirmDialog] = useState(false);

    useEffect(() => {
        const anneeService = new AnneeService();
        anneeService.getListToCentral().then(resp => setAnnees(resp));
        // console.log('Use effect method trigged');
    }, [annee]);

    const onInputChange = (e, name) => {
        // console.log(e)
        let val = (e.target && e.target.value) || '';
        if (val === '' && (name === 'anneeDebut' || name === 'nbreEval'))
            val = 0;
        let _annee = { ...annee };
        // console.log(typeof (val));
        // console.log(val);
        _annee[`${name}`] = parseInt(val);
        if (name === 'anneeDebut' && val)
            _annee.anneeFin = parseInt(val) + 1;

        // console.log(_annee);

        setAnnee(_annee);
    }

    const onSelectChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _annee = { ...annee };
        _annee[`${name}`] = { id: val };
        // console.log(_annee);
        setAnnee(_annee);
    }
    const hideDialog = () => {
        setSubmitted(false);
        setAnneeDialog(false);
        initVariables();
    }

    const initVariables = () => {
        setCalendarList([]);
        setMyArrayDeb(new Array(12).fill(null));
        setMyArrayFin(new Array(12).fill(null));
        setSelectedPeriodicite({ id: 2 });
        setAnnee({});
    }

    const openNew = async () => {
        setSubmitted(false);
        setAnneeDialog(true);
        setHeaderTitle("Initialiser une année scolaire");
        setTypeAction('new');
        const periodiciteService = new PeriodiciteService();
        const periodeService = new PeriodeService();
        const periodicitesList = await periodiciteService.getList();
        const niveauService = new NiveauEnseignementService();
        setPeriodicites(periodicitesList);
        niveauService.getList().then((res) => {
            // console.log(res);
            setNiveauxEnseignement(res);
        })
        for (const periodicite of periodicitesList) {
            if (periodicite.isDefault != null && periodicite.isDefault === 'DEFAULT') {
                const periodesList = await periodeService.getByPeriodicite(periodicite.id);
                const newMarqueurIndex = periodesList.findIndex(objet => objet.isfinal === 'O');
                let _annee = { ...annee };
                _annee.periodicite = periodicite;
                setAnnee(_annee);
                setMarqueurIndex(newMarqueurIndex === -1 ? periodesList.length : newMarqueurIndex);
                setPeriodes(periodesList);
            }
        }
    }

    const buildDateArrays = async (tab) => {
        let _debArray = [];
        let _finArray = [];
        if (tab !== null && tab.length > 0) {
            for (let index = 0; index < tab.length; index++) {
                if (tab[index].id != null && tab[index].id.includes('deb')) {
                    let date = new Date(tab[index].value.split('Z')[0]);
                    _debArray[tab[index].id.split('_')[1]] = date;
                }
                if (tab[index].id != null && tab[index].id.includes('fin')) {
                    let date = new Date(tab[index].value.split('Z')[0]);
                    _finArray[tab[index].id.split('_')[1]] = date;;
                }
            }
            setMyArrayDeb(_debArray);
            setMyArrayFin(_finArray);
        }
    }

    const edit = async (rowData) => {
        setSubmitted(false);
        setAnneeDialog(true);
        setTypeAction('update');
        const periodiciteService = new PeriodiciteService();
        const periodeService = new PeriodeService();
        const periodicitesList = await periodiciteService.getList();
        const niveauService = new NiveauEnseignementService();
        setPeriodicites(periodicitesList);
        niveauService.getList().then((res) => {
            setNiveauxEnseignement(res);
        })

        const periodesList = await periodeService.getByPeriodicite(rowData.periodicite.id);
        const newMarqueurIndex = periodesList.findIndex(objet => objet.isfinal === 'O');
        setMarqueurIndex(newMarqueurIndex === -1 ? periodesList.length : newMarqueurIndex);
        setPeriodes(periodesList);
        setSelectedPeriodicite(rowData.periodicite);
        setAnnee(rowData);
        setHeaderTitle("Modifier une année scolaire");
        await buildDateArrays(rowData.anneePeriodes);
        setCalendarList(rowData.anneePeriodes);

    }

    async function beforeSave() {
        let _annee = { ...annee };
        _annee.anneePeriodes = calendarList;
        _annee.niveau = 'CENTRAL';
        _annee.user = sessionStorage.getItem('idUser');
        // console.log(_annee);
        // bonne pratique de mise a jour
        setAnnee(prevAnnee => ({ ...prevAnnee, ..._annee }));
        return _annee;
    }

    const reactfindIndexById = (id, tab) => {
        let index = -1;
        for (let i = 0; i < tab.length; i++) {
            if (tab[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    const save = async () => {
        let anneeService = new AnneeService();
        setSubmitted(true);
        console.log((marqueurIndex + 1) * 2);
        console.log(calendarList.length);
        console.log(annee.anneeDebut != null);
        console.log(annee.niveauEnseignement?.id != null);
        console.log(annee.nbreEval != null);
        console.log(annee.periodicite?.id != null);
        if (annee.anneeDebut != null && annee.niveauEnseignement?.id != null && annee.nbreEval != null && annee.periodicite?.id != null && calendarList.length == ((marqueurIndex + 1) * 2)) {

            let finalAnnee = await beforeSave();
            anneeService.saveInit(finalAnnee).then((res) => {
                console.log(typeAction);
                // console.log(res);
                let _annees = [...annees];
                if (typeAction === 'new') {
                    _annees.push(res);
                } else if (typeAction === 'update') {
                    const index = reactfindIndexById(finalAnnee.id, _annees);
                    _annees[index] = res;
                }
                setAnnees(_annees);
                hideDialog();
                toast.current.show({ severity: 'success', summary: 'Succes', detail: typeAction === 'new' ? 'Nouvelle année Créee' : 'Année modifiée', life: 3000 });
            }).catch((error) => {
                // console.log('EREUR ::: ' + error.message);
                hideDialog();
                let detailTxt = '';
                if (error.response) {
                    console.log('erreur response- pouls-scolaire' + error.response.status);
                    detailTxt = error.response.data.message;
                } else if (error.request) {
                    console.log('erreur request- pouls-scolaire' + error.request);
                    console.log(error.request);
                    detailTxt = 'Connexion refusée ou interrompue! veuillez vérifier votre connexion internet ou votre parefeu!';
                } else {
                    console.log('Error autres', error.message);
                    detailTxt = error.message;
                }
                
                console.log(detailTxt);
                // console.log(error.response);
                toast.current.show({ severity: 'error', summary: 'erreur', detail: detailTxt, life: 10000 });
            });

        }
    }


    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h3 className="m-0">Liste des années scolaires </h3>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Recherche..." />
            </span>
        </div>
    );

    const anneeDialogFooter = (
        <>
            <Button label="Annuler" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Enregistrer" icon="pi pi-check" className="p-button-text" onClick={save} />
        </>
    );

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Nouveau" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    {/* <Button label="Supprimer" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedAnnees || !selectedAnnees.length} /> */}
                </div>
            </React.Fragment>
        )
    }

    const del = (ap) => {
        setDeleteDialog(true)
        setAnneeToDelete(ap);
    }

    const openSharingDialog = (annee) => {
        console.log(' annee to share');
        console.log(annee);
        setAnneeToShare(annee);
        setOpenShareConfirmDialog(true);
    }

    const actionButtons = (rowData) => {
        return (
            <div className="button-demo">
                <div className={'template ' + (rowData.hide === 'O' ? ' hidden ' : '') + ' ' + (rowData.statut === 'DIFFUSE' ? ' hidden' : '')}>
                    <Button title="Modifier" icon="pi pi-pencil" onClick={() => edit(rowData)} className="p-button-sm p-button-warning" />
                    <Button title="Supprimer" icon="pi pi-trash" onClick={() => del(rowData)} className="p-button-danger p-button-sm" />
                    <Button title="Diffuser" className="twitter p-0 p-button-sm" onClick={() => openSharingDialog(rowData)}>
                        <i className="pi pi-send px-2"></i>
                        <span className="px-2">Diffuser</span>
                    </Button>
                </div>

                <div className={(rowData.hide === 'O' ? ' hidden ' : '') + ' ' + (rowData.statut === 'DIFFUSE' ? '' : 'hidden')}>
                    <Tag className='px-3 py-2' severity='success' value='DIFFUSE'></Tag>
                </div>
                <div className={(rowData.hide === 'O' ? '' : ' hidden ')}><ProgressBar className='p-3' style={{ width: '50%' }} mode="indeterminate" /></div>
            </div >
        )
    }
    const onChangePeriodicite = async (periodicite) => {
        const periodeService = new PeriodeService();
        let _annee = { ...annee };
        _annee.periodicite = { ...periodicite };
        setAnnee(_annee);
        setSelectedPeriodicite(periodicite);
        const periodesList = await periodeService.getByPeriodicite(periodicite.id);
        const newMarqueurIndex = periodesList.findIndex(objet => objet.isfinal === 'O');
        setMarqueurIndex(newMarqueurIndex === -1 ? periodesList.length : newMarqueurIndex);
        setPeriodes(periodesList);
        setCalendarList([]);
        setMyArrayDeb(new Array(12).fill(null));
        setMyArrayFin(new Array(12).fill(null));
    }

    const deleteAnnee = () => {
        const anneeService = new AnneeService();
        console.log('hi');
        anneeService.deleteWithPeriodes(anneeToDelete.id).then(res => {
            console.log(res);
            let _annees = annees.filter(val => val.id !== anneeToDelete.id);
            setAnnees(_annees);
            toast.current.show({ severity: 'success', summary: 'Succès', detail: 'Année supprimée', life: 3000 })

        }).catch((error) => {
            if (error.response) {
                console.log('erreur response- vie ecole' + error.response.status);
            } else if (error.request) {
                console.log('erreur request- vie ecole' + error.request);
            } else {
                console.log('Error autres', error.message);
            }
            toast.current.show({ severity: 'error', summary: 'erreur', detail: error.message, life: 3000 });
        });
        hideDeleteDialog();

    }

    const headerBlock = () => {
        return (

            <div className='m-3 text-2xl pl-2 py-3 surface-300'>
                {headerTitle}
            </div>
        )
    }
    const hideDeleteDialog = () => {
        setAnneeToDelete(null);
        setDeleteDialog(false);
    }
    const hideShareDialog = () => {
        setAnneeToShare({});
        setOpenShareConfirmDialog(false);
    }

    const activeProgressBar = async () => {
        let index = reactfindIndexById(anneeToShare.id, annees);
        let _annees = [...annees];

        if (index !== -1) {
            if (_annees[index]?.hide !== 'O')
                _annees[index].hide = 'O';
            else
                _annees[index].hide = '';
            setAnnees(_annees);
            console.log('_annees');
            console.log(_annees);
        }
        hideShareDialog();
    }

    const sharingProcess = async () => {
        await activeProgressBar();
        console.log(anneeToShare);
        console.log(annees);

        const anneeService = new AnneeService();
        anneeService.sharing(anneeToShare).then(res => {
            let _annees = [...annees];
            // _annees.push(res);
            let index = reactfindIndexById(res.id, _annees);
            _annees[index] = res;
            setAnnees(_annees);

            toast.current.show({ severity: 'success', summary: 'Succes', detail: 'Processus de diffusion effectué', life: 3000 });
        }).catch((error) => {
            if (error.response) {
                console.log('erreur response- vie ecole' + error.response.status);
            } else if (error.request) {
                console.log('erreur request- vie ecole' + error.request);
            } else {
                console.log('Error autres', error.message);
            }
            activeProgressBar();
            toast.current.show({ severity: 'error', summary: 'erreur', detail: error.message, life: 3000 });
        });
    }

    const deleteDialogFooter = (
        <>
            <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={hideDeleteDialog} />
            <Button label="Oui" icon="pi pi-check" className="p-button-text" onClick={deleteAnnee} />
        </>
    );

    const shareDialogFooter = (rowData) => {
        return (
            <>
                <Button label="Annuler" icon="pi pi-times" className="p-button-outlined p-button-warning" onClick={hideShareDialog} />
                <Button label="DIFFUSER" icon={<BiBroadcast className='mx-2' size='1.2em' />} className="p-button-info" onClick={sharingProcess}></Button>
            </>)
    };
    const headerDialog = () => {
        return (
            <div className='border-bottom-2 font-bold text-2xl py-1 surface-200'>
                Confirmation
            </div>
        )
    }

    const onCalendarChange = (e) => {
        let calendar = {
            id: e.target.id,
            value: e.target.value
        };
        let index = -1;
        let [typeIndex, valIndex] = e.target.id.split('_');
        // stockage des valeurs dans les constantes dédié pour conserver la valeur à l'écran dans le calendrier
        if (typeIndex === 'deb') {
            let _myArrayDeb = { ...myArrayDeb };
            _myArrayDeb[valIndex] = e.target.value;
            setMyArrayDeb(_myArrayDeb);
        }

        if (typeIndex === 'fin') {
            let _myArrayFin = { ...myArrayFin };
            _myArrayFin[valIndex] = e.target.value;
            setMyArrayFin(_myArrayFin);
        }

        calendarList.forEach((elmt, idx) => {
            if (elmt.id === calendar.id)
                index = idx;
        })

        if (index === -1) {
            console.log('new');
            calendarList.push(calendar)
        } else {
            console.log('update');
            calendarList[index] = calendar;
        }
        // console.log(calendarList);

    };

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" right={leftToolbarTemplate} ></Toolbar>
                    <DataTable ref={dt} value={annees}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} header={header} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="affichage de {first} à {last} sur {totalRecords} notes"
                        globalFilter={globalFilter} emptyMessage="Aucun enregistrement trouvé." responsiveLayout="scroll">
                        <Column header="Niveau Enseignement" field='niveauEnseignement.libelle' headerStyle={{ Width: '1rem' }}></Column>
                        <Column header="Libellé" field='libelle' headerStyle={{ minWidth: '5rem' }}></Column>
                        <Column header="Périodicité" field='periodicite.libelle' headerStyle={{ Width: '1rem' }}></Column>
                        <Column header="Action" headerStyle={{ Width: '1rem' }} body={actionButtons}></Column>
                    </DataTable>

                    <Dialog visible={anneeDialog} style={{ width: '65%' }} scrollable scrollHeight="400px" header={headerBlock} modal className="p-fluid shadow-2" footer={anneeDialogFooter} onHide={hideDialog}>
                        <div className="mt-3 grid col-12 p-fluid formgrid align-items-center justify-content-center">
                            <div className="col-1 ">
                                <label className='pt-2' htmlFor="anneeDeb"><b>Année</b></label>
                            </div>
                            <div className="col-1 md:col-1">
                                <InputNumber value={annee?.anneeDebut} onBlur={(e) => onInputChange(e, 'anneeDebut')} id="anneeDebut" min={0} required autoFocus useGrouping={false} style={{ maxwidth: '250px' }} className={classNames({ 'p-invalid': submitted && !annee?.anneeDebut })} />
                            </div>
                            <span className='font-bold mx-1'><AiOutlineMinus /></span>
                            <div className="col-1 md:col-1">
                                <InputNumber value={annee?.anneeFin} useGrouping={false} disabled='disabled' id="anneeFin" style={{ maxwidth: '250px' }} />
                            </div>
                        </div>
                        <div className='grid fluid formgrid align-items-center justify-content-center '>
                            <div className="col-3 md:col-3 mt-2 ">
                                <label className='pt-2 ' htmlFor="anneeDeb"><b>Niveau d&apos;enseignement</b></label>
                            </div>
                            <div>
                                <Dropdown id='niveau' value={annee?.niveauEnseignement?.id} options={niveauxEnseignement} optionValue='id' optionLabel='libelle' onChange={(e) => onSelectChange(e, 'niveauEnseignement')} emptyMessage="Aucun niveau disponible" className={classNames({ 'p-invalid': submitted && !annee?.niveauEnseignement?.id })} placeholder="Selectionner un niveau" />
                            </div>
                        </div>
                        <div className='grid  mt-2 fluid formgrid align-items-center justify-content-center '>
                            <div className="col-2 mt-2 ">
                                <label className='pt-2 field' htmlFor="nbreEval"><b>Nombre d&apos;&eacute;valuations</b></label>
                            </div>
                            <div className='col-1'>
                                <InputNumber min={1} max={9} value={annee?.nbreEval} onBlur={(e) => onInputChange(e, 'nbreEval')} id="nbreEval" className={classNames({ 'p-invalid': submitted && !annee?.nbreEval })} />
                            </div>
                        </div>

                        <div className='grid surface-100 fluid formgrid align-items-center justify-content-center '>
                            <div className="field col-2 mt-2 ">
                                <label className='pt-2' ><b>P&eacute;riodicit&eacute;</b></label>
                            </div>
                            <div className='grid fluid formgrid align-items-center justify-content-center'>
                                {periodicites.map((obj, index) => {
                                    return (
                                        <div key={index} className="p-field-radiobutton mx-2">
                                            <RadioButton inputId={obj.id} name="periodicite" value={obj} onChange={(e) => onChangePeriodicite(e.value)} checked={selectedPeriodicite.id === obj.id} />
                                            <label htmlFor={obj.id}>{obj.libelle}</label>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className='grid  col-12'>
                            <div className='grid col-12 my-0 '>
                                <div className='col-offset-3 col-2 surface-300'>Période</div>
                                <div className='col-2 surface-300'>Date Debut </div>
                                <div className='col-2 surface-300'>Date Fin</div>
                            </div>
                            {
                                periodes.filter((obj, indx) => indx <= marqueurIndex).map((per, index) => {
                                    // console.log(marqueurIndex)
                                    return (
                                        <div key={per.id} className='grid col-12 my-0 '>
                                            <div className='col-offset-3 col-2 py-3 surface-100'>{per.libelle}</div>
                                            <div className='col-2 surface-100'><Calendar id={'deb_' + per.id} value={myArrayDeb[per.id]} dateFormat="dd/mm/yy" onChange={(e) => onCalendarChange(e)} className={classNames({ 'p-invalid': submitted && !(myArrayDeb[per.id]) })} showIcon></Calendar> </div>
                                            <div className='col-2 surface-100'><Calendar id={'fin_' + per.id} value={myArrayFin[per.id]} dateFormat="dd/mm/yy" onChange={(e) => onCalendarChange(e)} className={classNames({ 'p-invalid': submitted && !(myArrayFin[per.id]) })} showIcon></Calendar></div>
                                        </div>
                                    )
                                })}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteDialog} style={{ width: '450px' }} header={headerDialog} modal footer={deleteDialogFooter} onHide={hideDeleteDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {anneeToDelete && <span>Etes vous sûr de vouloir Supprimer <b>{anneeToDelete.libelle} [{anneeToDelete.niveauEnseignement?.libelle} - {anneeToDelete.periodicite?.libelle}]</b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={openShareConfirmDialog} style={{ width: '600px' }} header={headerDialog} modal footer={shareDialogFooter} onHide={hideShareDialog}>
                        <div className="text-center justify-content-center">

                            {anneeToShare && <span>Vous vous apprêtez à <span className='font-bold text-lg p-error-text'>diffuser</span> <b>{anneeToShare.libelle} [{anneeToShare.niveauEnseignement?.libelle} - {anneeToShare.periodicite?.libelle}]</b>, En êtes-vous sûr?</span>}
                        </div>

                        <div className='flex align-items-center justify-content-center mt-3 text-center font-semibold'><i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} /><Tag severity='danger' className='p-2 text-lg'>Vous ne pourrai plus y apporter de modification une fois diffusée</Tag></div>
                    </Dialog>

                </div>
            </div>
        </div>
    )
}
const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(OpenAnnee, comparisonFn);