import React, { useState, useEffect, useRef } from 'react';

import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';

import { Dropdown } from 'primereact/dropdown';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';

import { SalleService } from '../service/SalleService';

const Salles = () => {

    let emptySalle = {
        code: '',
        libelle: '',
        ecole : {id : sessionStorage.getItem('EcoleID')},
        niveauEtage: ''
    };

    const [salleDialog, setSalleDialog] = useState(false);

    const [deleteSalleDialog, setDeleteSalleDialog] = useState(false);
    const [deleteSallesDialog, setDeleteSallesDialog] = useState(false);

    const [update, setUpdate] = useState(false);
    const [headerTitle, setHeaderTitle] = useState("");

    const [salle, setSalle] = useState(emptySalle);

    const [selectedSalles, setSelectedClasses] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);

    const toast = useRef(null);
    const dt = useRef(null);


    const [salles, setSalles] = useState(null);

    let etagesList = [{id:1,libelle:'Niveau 0'},{id:2,libelle:'1er Etage'},{id:3,libelle:'2ème Etage'},{id:4,libelle:'3ème Etage'},{id:5,libelle:'4ème Etage'}]

    const [etages, setEtages] = useState(etagesList);


    const [width, setWidth] = useState("500px");

    useEffect(() => {
        const salleService = new SalleService();
        salleService.getByEcole(sessionStorage.getItem('EcoleID')).then(data => setSalles(data));
    }, []);


    const openNew = () => {
        setSubmitted(false);
        setWidth("500px");
        setUpdate(false);
        setSalle(emptySalle);

        setSalleDialog(true);
        setHeaderTitle("Créer une nouvelle salle");
    }

    const hideDialog = () => {
        setSubmitted(false);
        setSalleDialog(false);
    }


    const hideDeleteClasseDialog = () => {
        setDeleteSalleDialog(false);
    }


    const hideDeleteClassesDialog = () => {
        setDeleteSallesDialog(false);
    }

    const saveSalle = () => {
        setSubmitted(true);
        if (salle.code != null && salle.code.trim() && salle.libelle != null && salle.libelle.trim()) {

            let _salles = [...salles];
            let _salle = { ...salle };
            const salleService = new SalleService();

            if (salle.id) {
                console.log(_salle);
                const index = reactfindIndexById(salle.id);

                (salleService.updateAndDisplay(_salle)).then(resp => {
                    _salles[index] = { ...resp };
                    setSalles(_salles);
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Salle modifiée', life: 3000 });
                }).catch((error) => {
                    if (error.response) {
                        console.log('erreur response- vie ecole' + error.response.status);
                    } else if (error.request) {
                        console.log('erreur request- vie ecole' + error.request);
                    } else {
                        console.log('Error autres', error.message);
                    }
                    toast.current.show({ severity: 'error', summary: 'erreur', detail: error.message, life: 3000 });
                }
                );

            }
            else {

                console.log(_salle);
                _salle.niveauEtage = null;
                (salleService.saveAndDisplay(_salle)).then(res => {
                    _salles.push(res);
                    setSalles(_salles);
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Nouvelle salle créee ' + _salle.code, life: 3000 });
                }).catch((error) => {
                    if (error.response) {
                        console.log('erreur response- vie ecole' + error.response.status);
                    } else if (error.request) {
                        console.log('erreur request- vie ecole' + error.request);
                    } else {
                        console.log('Error autres', error.message);
                    }
                    toast.current.show({ severity: 'error', summary: 'erreur', detail: 'Une erreur s est produite', life: 3000 });
                });

            }
            //  setSalles(_salles);
            setSalleDialog(false);
            setSalle(emptySalle);
            setSubmitted(false);
        }
    }


    const editClasse = (salle) => {

        setHeaderTitle("Modifier une salle");
        setWidth('1000px');
        setUpdate(true);

        setSalle({ ...salle });

        console.log(salle);

        setSalleDialog(true);
    }



    const confirmDeleteClasse = (salle) => {
        setSalle(salle);
        setDeleteSalleDialog(true);
    }



    const deleteSalle = () => {
        const salleService = new SalleService();

        salleService.delete(salle.id).then( () => {
            let _salles = salles.filter(val => val.id !== salle.id);
            setSalles(_salles);
            toast.current.show({ severity: 'success', summary: 'Succès', detail: 'Salle supprimée', life: 3000 })

        }).catch((error) => {
            if (error.response) {
                console.log('erreur response- vie ecole' + error.response.status);
            } else if (error.request) {
                console.log('erreur request- vie ecole' + error.request);
            } else {
                console.log('Error autres', error.message);
            }
            toast.current.show({ severity: 'error', summary: 'erreur', detail: error.message, life: 3000 });
        });
        setSalle(emptySalle);
        setDeleteSalleDialog(false);

    }

    const reactfindIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < salles.length; i++) {
            if (salles[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    const confirmDeleteSelected = () => {
        setDeleteSallesDialog(true);
    }


    const deleteSelectedClasses = () => {
        let _salles = salles.filter(val => !selectedSalles.includes(val));
        const salleService = new SalleService();
        selectedSalles.map((salle) => { return salleService.delete(salle.id) });
        setSalles(_salles);
        setDeleteSallesDialog(false);
        setSelectedClasses(null);
        toast.current.show({ severity: 'success', summary: 'succès', detail: 'Salles supprimées', life: 3000 });
    }


    const onInputClasseChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _salle = { ...salle };
        _salle[`${name}`] = val;
        setSalle(_salle);
        console.log(salle);
    }


    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Nouveau" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    <Button label="Supprimer" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedSalles || !selectedSalles.length} />
                </div>
            </React.Fragment>
        )
    }



    const codeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Code</span>
                {rowData.code == null ? '' : rowData.code}
            </>
        );
    }

    const libelleBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Libellé</span>
                {rowData.libelle == null ? '' : rowData.libelle}
            </>
        );
    }

    const niveauBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Niveau</span>
                {rowData.branche.niveau == null ? '' : rowData.branche.niveau.libelle}
            </>
        );
    }

    const serieBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Série</span>
                {rowData.branche.serie == null ? '' : rowData.branche.serie.code}
            </>
        );
    }

    // const ppBodyTemplate = (rowData) => {
    //     return (
    //         <>
    //             <span className="p-column-title">PP</span>
    //             {rowData.profPrincipal == null ? '' : rowData.profPrincipal.nom + ' ' + rowData.profPrincipal.prenoms}
    //         </>
    //     );
    // }


    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editClasse(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteClasse(rowData)} />
            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h3 className="m-0">Liste des salles de classe</h3>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Recherche..." />
            </span>
        </div>
    );


    const salleDialogFooter = (
        <>
            <Button label="Annuler" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Enregistrer" icon="pi pi-check" className="p-button-text" onClick={saveSalle} />
        </>
    );


    const deleteSalleDialogFooter = (
        <>
            <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={hideDeleteClasseDialog} />
            <Button label="Oui" icon="pi pi-check" className="p-button-text" onClick={deleteSalle} />
        </>
    );
    const deleteSallesDialogFooter = (
        <>
            <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={hideDeleteClassesDialog} />
            <Button label="oui" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedClasses} />
        </>
    );

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" right={leftToolbarTemplate} ></Toolbar>

                    <DataTable ref={dt} value={salles} selection={selectedSalles} onSelectionChange={(e) => setSelectedClasses(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="affichage {first} de {last} sur {totalRecords} salles"
                        globalFilter={globalFilter} emptyMessage="Aucun enregistrement trouvé." header={header} responsiveLayout="scroll">
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                        <Column field="code" header="Code" sortable body={codeBodyTemplate}></Column>
                        <Column field="libelle" header="Libellé" sortable body={libelleBodyTemplate} ></Column>
                        {/* <Column field="pp" header="PP" body={ppBodyTemplate} sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>  */}
                        <Column body={actionBodyTemplate} header="Actions" headerStyle={{ width: '14%', minWidth: '9rem' }}></Column>
                    </DataTable>

                    <Dialog visible={salleDialog} style={{ width: width }} header={headerTitle} modal className="p-fluid" footer={salleDialogFooter} onHide={hideDialog}>

                        <div className="formgroup-inline">
                            <div className="p-fluid" style={{ width: '450px' }}>
                                <div className="field col-12">
                                    <label htmlFor="code"><b>Code</b></label>
                                    <InputText value={salle.code} onChange={(e) => onInputClasseChange(e, 'code')} id="code" required autoFocus className={classNames({ 'p-invalid': submitted && !salle.code })} />
                                    {submitted && !salle.code && <small className="p-invalid">Le code est requis.</small>}
                                </div>
                                <div className="field col-12">
                                    <label htmlFor="libelle"><b>Libellé</b></label>
                                    <InputText id="libelle" value={salle.libelle} onChange={(e) => onInputClasseChange(e, 'libelle')} required className={classNames({ 'p-invalid': submitted && !salle.libelle })} />
                                    {submitted && !salle.libelle && <small className="p-invalid">Le libellé est requis.</small>}
                                </div>

                                <div className="field col-12">
                                    <label htmlFor="etage"><b>Etage</b></label>
                                    <Dropdown id="etage" value={salle.niveauEtage} onChange={(e) => onInputClasseChange(e, 'niveauEtage')} options={etages} optionValue="id" optionLabel="libelle" placeholder="Selectionner le niveau" />

                                </div>

                            </div>

                        </div>
                    </Dialog>

                    <Dialog visible={deleteSalleDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteSalleDialogFooter} onHide={hideDeleteClasseDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {salle && <span>Etes vous sûr de vouloir Supprimer la salle <b>{salle.code} - {salle.libelle}</b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteSallesDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteSallesDialogFooter} onHide={hideDeleteClassesDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {salle && <span>Êtes vous sûr de vouloir supprimer les éléments sélectionnés?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Salles, comparisonFn);
