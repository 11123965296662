const constants = {
    host: 'http://localhost:9000/',
};

export class BaseDevService {

    getHost() {
        return constants["host"];
    }
}
