import axios from 'axios';
import { BaseService } from './BaseService';

export class PeriodeService extends BaseService{

    async getList(){
        return axios.get(this.getHost()+'periodes/list').then(res => res.data);
    }

    async getByPeriodicite(periodiciteId){
        console.log('periodicite id ::: '+periodiciteId);
        return axios.get(this.getHost()+'periodes/list-by-periodicite?id='+periodiciteId).then(res => res.data);
    }

    async getListPeriodicitePastAndCurrent(periodiciteId, ecoleId){
        console.log('periodicite id ::: '+periodiciteId+' ecole id '+ecoleId);
        return axios.get(this.getHost()+'periodes/list-periodicite-past-and-current?id='+periodiciteId+'&ecoleId='+ecoleId).then(res => res.data);
    }

    
}