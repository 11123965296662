import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import '@splidejs/splide/dist/css/splide.min.css';


const TestimonialArea = () => {
  // Tableau d'objets pour stocker les témoignages
  const testimonials = [
    {
      id: 1,
      name: "FOFANA MOHAMED",
      job: "Etudiant",
      //image: "assets/img/testimonial/1/admin-2.jpg",
      rating: 5,
      content:
        "«Cette application  m'a beaucoup aidée à rester organisée tout au long de l'année scolaire. Je peux facilement consulter mon emploi du temps, accéder aux notes des cours et même communiquer avec mes professeurs. Cela m'évite tellement de tracas par rapport aux méthodes traditionnelles. Je la recommande à tous mes camarades d'études.»",
    },
    {
      id: 2,
      name: "KOUAKOU ANGE MICKAEL",
      job: "Etudiant",
      image: "assets/img/testimonial/1/admin-3.jpg",
      rating: 5,
      content:
        "« Un outil indispensable pour les étudiants\" \"Grâce à cette application, j'ai pu suivre mes progrès scolaires en temps réel et soumettre mes devoirs en ligne. La possibilité de recevoir des notifications sur les examens ou les devoirs à rendre est un vrai plus. C'est devenu un outil indispensable pour mes études. En plus, l'interface est très agréable et facile à utiliser.»",
    },
    {
      id: 3,
      name: "SANGARE MOUSSA",
      job: "Etudiant",
      image: "assets/img/testimonial/1/admin-5.jpg",
      rating: 5,
      content:
        "«Pratique pour suivre les résultats et les activités\" \"Avec cette application, je peux facilement accéder à mes résultats de manière rapide et sécurisée. J'aime aussi le fait de pouvoir voir toutes les activités scolaires à venir, cela me permet de mieux planifier mon temps. C'est un excellent moyen de rester informé sans avoir à demander à plusieurs reprises des informations à l'administration. »",
    },
    {
        id: 1,
        name: "AMANI BODOHIN",
        job: "Professeur de Mathématique au Collège Moderne de Bouaflé",
        image: "assets/img/testimonial/1/admin-2.jpg",
        rating: 5,
        content:
          "« Une grande aide pour la gestion de mes cours\" \"En tant que professeur, cette application m'a considérablement facilité la vie. Je peux planifier mes cours, partager des ressources avec les élèves et suivre leur progression en quelques clics. La fonctionnalité de messagerie m'aide à garder un contact direct avec les étudiants, même en dehors des heures de classe. Je suis très satisfait du système.»",
      },
      {
        id: 2,
        name: "KOUAME JOEL ",
        job: "Professeur de Français au Collège Moderne de Seguela",
        image: "assets/img/testimonial/1/admin-3.jpg",
        rating: 5,
        content:
          "« Simplifie la communication avec les étudiants\" \"Je trouve l'application très efficace pour gérer mes interactions avec les étudiants. Je peux leur envoyer des messages, des rappels et même des documents en un seul endroit. Cela m'aide à garder tout le monde à jour sans avoir à utiliser plusieurs plateformes. Je la recommande fortement à tous les enseignants.»",
      },

  ];

  return (
    <section className="testimonial-area pt-40 pb-40">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-8 col-md-10">
            <div className="section-area mb-55 section-area-top text-center">
              <span className="section-subtitle">Avis des Utilisateurs</span>
              <h2 className="section-title mb-20">Ce que disent nos Utilisateurs</h2>
              <p className="section-text">
                  À travers une combinaison de cours interactifs, d'accès à des ressources pédagogiques, et de discussions en ligne,
                  les apprenants bénéficieront d'une plateforme complète qui leur offrira une solide fondation dans leurs matières d'études.
                  L'application facilite également la communication avec les enseignants et l'accès à des retours réguliers,
                  permettant aux apprenants d'optimiser leur apprentissage et leur progression tout au long de l'année scolaire.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="testimonial-wrap">
      <Splide
        options={{
          type: "loop",
          perPage: 4,
          autoplay: true,
          pagination: true,
          arrows: true,
          gap: '24px',
          breakpoints: {
            768: { // Jusqu'à une largeur de 768px (taille mobile)
              perPage: 1, // Afficher un seul témoignage par page
            },
            1024: { // Jusqu'à une largeur de 1024px (taille tablette)
              perPage: 2, // Afficher trois témoignages par page sur tablette
            },
          },
        }}
      >
          {/* Parcours du tableau d'objets testimonials */}
          {testimonials.map((testimonial) => (
            <SplideSlide key={testimonial.id}>
              <div className="testimonial-item">
                <div className="testimonial-top">
                  <div className="testimonial-admin">
                    {/*<div className="testimonial-admin-img">
                      <img src={testimonial.image} alt={testimonial.name} />
                    </div>*/}
                    <div className="testimonial-admin-info">
                      <h5>{testimonial.name}</h5>
                      <span>{testimonial.job}</span>
                    </div>
                  </div>
                  <div className="testimonial-rating">
                    <ul>
                      {/* Boucle pour afficher les étoiles */}
                      {[...Array(testimonial.rating)].map((_, index) => (
                        <li key={index}>
                          <i className="fa-solid fa-star" />
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="testimonial-content">
                  <p>{testimonial.content}</p>
                </div>
              </div>
            </SplideSlide>
          ))}
        </Splide>
      </div>
    </section>
  );
};

export default TestimonialArea;
