import axios from 'axios';

import { BaseService } from '../BaseService';
export class PersonnelsService extends BaseService{

    getTypePersonnel() {
        return axios.get(this.getHost()+'type-personnel/').then(res => res.data);
    }


    getPersonnelStatus() {
        return axios.get(this.getHost()+'personnel-status/').then(res => res.data);
    }

    getFonction() {
        return axios.get(this.getHost()+'fonction/').then(res => res.data);
    }

    getPersonnel(idtenant) {
        return axios.get(this.getHost()+'personnel/'+idtenant).then(res => res.data);
    }

    getPersonnelParFonction(Libellefonction,idtenant) {
        return axios.get(this.getHost()+'personnel/par-fonction/'+Libellefonction+'/'+idtenant).then(res => res.data);
    }

    getPersonnelByID(identifiantPersonnel) {
        return axios.get(this.getHost()+'personnel/'+identifiantPersonnel).then(res => res.data);
    }

    creerPersonnel(personnelDto) {
        return axios.post(this.getHost()+'personnel/',personnelDto)
            .then(res => res.data);
    }

    modifierPersonnel(personnelDto) {
        return axios.put(this.getHost()+'personnel/',personnelDto)
            .then(res => res.data);
    }

    deletePersonnelByID(identifiantPersonnel) {
        return axios.delete(this.getHost()+'personnel/'+identifiantPersonnel).then(res => res.data);
    }
    listMatiereParEcole(tenantId) {
        return axios.get(this.getHost()+'matiere/ecole/'+tenantId).then(res => res.data);
    }

    listClasseParEcole(tenantId) {
        return axios.get(this.getHost()+'classe/ecole/'+tenantId).then(res => res.data);
    }

    creerPersonnelMatiereClasse(personnelId,classeId,MatiereId ,anneeId,tenantId) {
        return axios.post(this.getHost()+'matire-classe-personnel/'+personnelId+'/'+classeId +'/'+  MatiereId +'/'+  anneeId +'/'+tenantId)
            .then(res => res.data);
    }

   modifierPersonnelMatiereClasse(entityId,classeId,MatiereId ) {
        return axios.post(this.getHost()+'matire-classe-personnel/'+entityId+'/'+classeId +'/'+  MatiereId )
            .then(res => res.data);
    }

    supprimerPersonnelMatiereClasse(entityId) {
        return axios.delete(this.getHost()+'matire-classe-personnel/'+entityId  )
            .then(res => res.data);
    }



}
