import axios from 'axios';
import { BaseService } from './BaseService';



const ecole = sessionStorage.getItem('EcoleID');
export class ClasseService extends BaseService {

    getList(){
        return axios.get(this.getHost()+'classes/list').then(res => res.data);
    }
    getListByEcole(ecoleId){
        return axios.get(this.getHost()+'classes/list-by-ecole?ecole='+ecoleId).then(res => res.data);
    }

    async getListStudentClasseIdLibelleByMatriculeAndAnnee(matricule, annee){
        return axios.get(this.getHost()+'classes/list-classe-by-matricule-annee?matricule='+matricule+'&annee='+annee).then(res => res.data);
    }

    getListMatierByEcoleClasseWeb(ecoleId ,classeId){
        return axios.get(this.getHost()+'imprimer-matrice-classe/matieres-ecole-web/'+ecoleId+'/'+classeId).then(res => res.data);
    }

    getListProcesVerEval(classeId,code,matricule){
        return axios.get(this.getHost()+'imprimer-proces-verbal/infos-by-matricule/'+classeId+'/'+code+'/'+matricule).then(res => res.data);
    }
    getSortedListByEcole(ecoleId){
        return axios.get(this.getHost()+'classes/list-by-ecole-sorted?ecole='+ecoleId).then(res => res.data);
    }

    getListAllFields(ecoleId){
        return axios.get(this.getHost()+'classes/list-populate-by-ecole?ecole='+ecoleId).then(res => res.data);
    }

    getListAllFieldsAll(ecoleId){
        return axios.get(this.getHost()+'classes/list-all-populate-by-ecole?ecole='+ecoleId).then(res => res.data);
    }

    getById(id){

        return axios.get(this.getHost()+'classes/'+id).then(res => res.data);
    }

    getByBranche(branche, ecoleId){

        return axios.get(this.getHost()+'classes/get-by-branche?branche='+branche+'&ecole='+ecoleId).then(res => res.data);
    }


    getVisibleByBranche(branche, ecoleId){
        return axios.get(this.getHost()+'classes/get-visible-by-branche?branche='+branche+'&ecole='+ecoleId).then(res => res.data);
    }

    save(classe){
        axios.post(this.getHost()+'classes/save',classe);
    }

    update(classe){
       return axios.post(this.getHost()+'classes/update',classe).then(response => response.data);
    }

    updateAndDisplay(classe){
        return axios.post(this.getHost()+'classes/update-display',classe).then(res => res.data);
     }

    saveAndDisplay(classe){
        return axios.post(this.getHost()+'classes/saveAndDisplay',classe).then(res => res.data);
    }


    delete(id){
        return axios.delete(this.getHost()+'classes/delete/'+id);
    }
}
