import React from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';


const EventCarousel = () => {


    const events = [
        {
          id: 1,
          image: 'assets/img/event/1/1.jpg',
          location: 'GHI SERVICES',
          time: '',
          title: 'CALCUL DES MOYENNES',
          link: '#'
        },
        {
          id: 2,
          image: 'assets/img/event/1/2.jpg',
          location: 'GHI SERVICES',
          time: '',
          title: 'Vérification des Cahiers de texte et des progressions',
          link: '#'
        },
        {
          id: 3,
          image: 'assets/img/event/1/1.jpg',
          location: 'GHI SERVICES',
          time: '',
          title: 'Tirage des rapports trimestriels',
          link: '#'
        }
      ];




  return (
    <section className="event-area">
      <img src="assets/img/event/1/bg.jpg" alt="Fond d'événement" className="event-bg-img" />
      <div className="event-wrap pt-120 pb-40">
        <div className="container">
          <div className="row align-items-center mb-30">
            <div className="col-xl-8 col-lg-8 col-md-8">
              <div className="event-section-area mb-20">
                <div className="section-area">
                  <span className="section-subtitle">Conférence sur l'éducation</span>
                  <h2 className="section-title mb-0">Événements à venir</h2>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4">
              <div className="event-navigation mb-30">
                <div className="event-prev">
                  <i className="fa-thin fa-arrow-left" />
                </div>
                <div className="event-next">
                  <i className="fa-light fa-arrow-right" />
                </div>
              </div>
            </div>
          </div>

          <Splide options={{ type: 'loop', gap: '30px', perPage: 3 ,
            breakpoints: {
              768: { // Jusqu'à une largeur de 768px (taille mobile)
                perPage: 1, // Afficher un seul témoignage par page
              },
              1024: { // Jusqu'à une largeur de 1024px (taille tablette)
                perPage: 2, // Afficher trois témoignages par page sur tablette
              },
            },
          }}>
            {events.map((event) => (
              <SplideSlide key={event.id}>
                <div className="event-item">
                  <div className="event-img">
                    <a href={event.link}>
                      <img src={event.image} alt={event.title} />
                    </a>
                  </div>
                  <div className="event-content">
                    <div className="event-content-meta">
                      <span><i className="fa-thin fa-location-dot" /> {event.location}</span>
                      <span><i className="fa-thin fa-clock" /> {event.time}</span>
                    </div>
                    <h5 className="event-content-title">
                      <a href={event.link}>{event.title}</a>
                    </h5>
                    {/* <a href={event.link} className="t-theme-btn theme-btn event-btn">
                      Obtenir un ticket
                    </a> */}
                  </div>
                </div>
              </SplideSlide>
            ))}
          </Splide>
        </div>
      </div>
    </section>
  );
};

export default EventCarousel;
