import React, { useState, useEffect, useRef } from 'react';

import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';

import { Dropdown } from 'primereact/dropdown';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { ClasseService } from '../service/ClasseService';
import { AnneeService } from '../service/AnneeService';
import { BrancheService } from '../service/BrancheService';
import { LangueService } from '../service/LangueService';
import { ToggleButton } from 'primereact/togglebutton';

import { TabView, TabPanel } from 'primereact/tabview';
import { ClasseEleveService } from '../service/ClasseEleveService';
import PersonnelMatiereClasse from './PersonnelMatiereClasse';
import { PersonnelMatiereClasseService } from '../service/PersonnelMatiereClasseService';

const MyClasses = () => {

    let emptyAnnee = {
        id: null,
        code: "",
        libelle: ""
    };


    let emptyBranche = {
        id: null,
        code: "",
        libelle: "",

    };

    let emptyEcole = {
        id: null,
    };

    let emptyLangue = {
        id: "0",
        code: "",
        libelle: "Selectionner la langue"
    };


    let emptyClasse = {
        annee: emptyAnnee,
        branche: emptyBranche,
        code: '',
        id: null,
        langueVivante: emptyLangue,
        libelle: '',
        profPrincipal: null,
        ecole: emptyEcole,
        visible: 1,
        effectif: 0
    };

    const [classeDialog, setClasseDialog] = useState(false);

    const [deleteClasseDialog, setDeleteClasseDialog] = useState(false);
    const [deleteClassesDialog, setDeleteClassesDialog] = useState(false);

    const [update, setUpdate] = useState(false);
    const [headerTitle, setHeaderTitle] = useState("");

    const [classe, setClasse] = useState(emptyClasse);

    const [selectedClasses, setSelectedClasses] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);

    const toast = useRef(null);
    const dt = useRef(null);


    const [classes, setClasses] = useState(null);

    const [annees, setAnnees] = useState(null);
    const [branches, setBranches] = useState(null);

    const [langues, setLangues] = useState(null);
    const [professeurs, setProfesseurs] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [emploiTemps, setEmploiTemps] = useState(null);
    const [effectif, setEffectif] = useState(0);

    const [width, setWidth] = useState("500px");

    useEffect(() => {
        const classeService = new ClasseService();
        const anneeService = new AnneeService();
        const brancheService = new BrancheService();
        const langueService = new LangueService();

        classeService.getListAllFieldsAll(sessionStorage.getItem("EcoleID")).then(data => setClasses(data));
        anneeService.getList().then(data => setAnnees(data));
        brancheService.getByNiveauEnseigment(sessionStorage.getItem("EcoleID")).then(data => setBranches(data));
        langueService.getList().then(data => {

            setLangues([emptyLangue, ...data]);
        })
    }, []);


    const openNew = () => {
        setSubmitted(false);
        setWidth("500px");
        setUpdate(false);
        setClasse(emptyClasse);

        setClasseDialog(true);
        setHeaderTitle("Créer une nouvelle classe");
    }

    const hideDialog = () => {
        setSubmitted(false);
        setClasseDialog(false);
    }


    const hideDeleteClasseDialog = () => {
        setDeleteClasseDialog(false);
    }


    const hideDeleteClassesDialog = () => {
        setDeleteClassesDialog(false);
    }

    const saveClasse = () => {
        setSubmitted(true);
        if (classe.code != null && classe.code.trim() && classe.libelle != null && classe.libelle.trim() && classe.branche.id != null && classe.effectif != null) {

            let _classes = [...classes];
            let _classe = { ...classe };
            const classeService = new ClasseService();

            if (_classe.annee.id == null)
                _classe.annee = null;
            if (_classe.langueVivante.id == 0)
                _classe.langueVivante = null;
            if (_classe.branche.id == null)
                _classe.branche = null;
            if (classe.id) {
                console.log(_classe);
                const index = reactfindIndexById(classe.id);

                (classeService.updateAndDisplay(_classe)).then(resp => {
                    _classes[index] = { ...resp };
                    setClasses(_classes);
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Classe modifiée', life: 3000 });
                }).catch((error) => {
                    if (error.response) {
                        console.log('erreur response- vie ecole' + error.response.status);
                    } else if (error.request) {
                        console.log('erreur request- vie ecole' + error.request);
                    } else {
                        console.log('Error autres', error.message);
                    }
                    toast.current.show({ severity: 'error', summary: 'erreur', detail: 'Une erreur s est produite', life: 3000 });
                }
                );

            }
            else {
                // console.log(sessionStorage.getItem('EcoleID'));
                _classe.id = createId();
                _classe.profPrincipal = null;
                _classe.ecole.id = sessionStorage.getItem('EcoleID');
                console.log(_classe);
                (classeService.saveAndDisplay(_classe)).then(res => {
                    _classes.push(res);
                    setClasses(_classes);
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Nouvelle classe créee ' + _classe.code, life: 3000 });
                }).catch((error) => {
                    if (error.response) {
                        console.log('erreur response- vie ecole' + error.response.status);
                    } else if (error.request) {
                        console.log('erreur request- vie ecole' + error.request);
                    } else {
                        console.log('Error autres', error.message);
                    }
                    toast.current.show({ severity: 'error', summary: 'erreur', detail: 'Une erreur s est produite', life: 3000 });
                });

            }
            //  setClasses(_classes);
            setClasseDialog(false);
            setClasse(emptyClasse);
            setSubmitted(false);
        }
    }


    const editClasse = (classe) => {

        setHeaderTitle("Modifier une classe");
        setWidth('1000px');
        setUpdate(true);
        const classeEleveService = new ClasseEleveService();
        const persMatClasseService = new PersonnelMatiereClasseService();
        classe.annee = (!classe.annee ? emptyAnnee : classe.annee);
        classe.branche = (!classe.branche ? emptyBranche : classe.branche);
        classe.langueVivante = (!classe.langueVivante ? emptyLangue : classe.langueVivante);
        classeEleveService.getEffectifByClasseAndAnnee(classe.id, sessionStorage.getItem('AnneEncours')).then(data => setEffectif(data));
        persMatClasseService.getByClasse(classe.id, sessionStorage.getItem('AnneEncours')).then(
            resp => {
                console.log(resp);
                setProfesseurs(resp);
            }
        )
        setClasse({ ...classe });

        console.log(classe);

        setClasseDialog(true);
    }



    const confirmDeleteClasse = (classe) => {
        setClasse(classe);
        setDeleteClasseDialog(true);
    }



    const deleteClasse = () => {
        const classeService = new ClasseService();

        classeService.delete(classe.id).then(res => {
            let _classes = classes.filter(val => val.id !== classe.id);
            setClasses(_classes);
            toast.current.show({ severity: 'success', summary: 'Succès', detail: 'Classe supprimée', life: 3000 })

        }).catch((error) => {
            if (error.response) {
                console.log('erreur response- vie ecole' + error.response.status);
            } else if (error.request) {
                console.log('erreur request- vie ecole' + error.request);
            } else {
                console.log('Error autres', error.message);
            }
            toast.current.show({ severity: 'error', summary: 'erreur', detail: error.message, life: 3000 });
        });
        setClasse(emptyClasse);
        setDeleteClasseDialog(false);

    }

    const reactfindIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < classes.length; i++) {
            if (classes[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }
    const createId = () => {
        let id = '';
        let chars = '0123456789';
        for (let i = 0; i < 5; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return id;
    }

    // const exportCSV = () => {
    //     dt.current.exportCSV();
    // }

    const confirmDeleteSelected = () => {
        setDeleteClassesDialog(true);
    }


    const deleteSelectedClasses = () => {
        let _classes = classes.filter(val => !selectedClasses.includes(val));
        const classeService = new ClasseService();
        selectedClasses.map((classe) => { return classeService.delete(classe.id) });
        setClasses(_classes);
        setDeleteClassesDialog(false);
        setSelectedClasses(null);
        toast.current.show({ severity: 'success', summary: 'succès', detail: 'Classes supprimées', life: 3000 });
    }


    const onInputClasseChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _classe = { ...classe };
        _classe[`${name}`] = val;
        setClasse(_classe);
        console.log(classe);
    }

    const onInputNumberChange = (e, name) => {

        const val = e.value;
        let _classe = { ...classe };
        _classe[`${name}`] = val;
        setClasse(_classe);
        console.log(_classe);
    }

    const onSelectClasseChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _classe = { ...classe };
        _classe[`${name}`].id = val;
        // console.log(_classe);
        setClasse(_classe);
    }

    const onCheckVisible = (e) => {
        let _classe = { ...classe };
        _classe.visible = (e.value ? 1 : 0);
        setClasse(_classe);
        console.log(_classe);
    }


    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Nouveau" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    {/* <Button label="Supprimer" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedClasses || !selectedClasses.length} /> */}
                </div>
            </React.Fragment>
        )
    }



    const codeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Code</span>
                {rowData.code == null ? '' : rowData.code}
            </>
        );
    }

    const visibleBody = (rowData) => {
        return (
            <>
                <i className={rowData?.visible === 1 ? 'pi pi-eye mr-2' : 'pi pi-eye-slash mr-2'} ></i>
            </>
        )
    }

    const libelleBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Libellé</span>
                {rowData.libelle == null ? '' : rowData.libelle}
            </>
        );
    }

    const anneeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Année</span>
                {rowData.annee == null ? '' : rowData.annee.libelle}
            </>
        );
    }

    const niveauBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Niveau</span>
                {rowData.branche.niveau == null ? '' : rowData.branche.niveau.libelle}
            </>
        );
    }

    const serieBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Série</span>
                {rowData.branche.serie == null ? '' : rowData.branche.serie.code}
            </>
        );
    }

    // const ppBodyTemplate = (rowData) => {
    //     return (
    //         <>
    //             <span className="p-column-title">PP</span>
    //             {rowData.profPrincipal == null ? '' : rowData.profPrincipal.nom + ' ' + rowData.profPrincipal.prenoms}
    //         </>
    //     );
    // }

    const lv2BodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">LV2</span>
                {rowData.langueVivante == null ? '' : rowData.langueVivante.libelle}
            </>
        );
    }

    const effectifBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Effectif</span>
                {rowData.effectif}
            </>
        );
    }


    const filiereBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Filière</span>
                {rowData.branche.filiere != null ? rowData.branche.filiere.libelle : ''}
            </>
        );
    }


    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editClasse(rowData)} />
                {/* <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteClasse(rowData)} /> */}
            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h3 className="m-0">Liste des classes</h3>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Recherche..." />
            </span>
        </div>
    );


    const classeDialogFooter = (
        <>
            <Button label="Annuler" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Enregistrer" icon="pi pi-check" className="p-button-text" onClick={saveClasse} />
        </>
    );


    const deleteClasseDialogFooter = (
        <>
            <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={hideDeleteClasseDialog} />
            <Button label="Oui" icon="pi pi-check" className="p-button-text" onClick={deleteClasse} />
        </>
    );
    const deleteClassesDialogFooter = (
        <>
            <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={hideDeleteClassesDialog} />
            <Button label="oui" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedClasses} />
        </>
    );

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" right={leftToolbarTemplate} ></Toolbar>

                    <DataTable ref={dt} value={classes} selection={selectedClasses} onSelectionChange={(e) => setSelectedClasses(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="affichage {first} de {last} sur {totalRecords} classes"
                        globalFilter={globalFilter} emptyMessage="Aucun enregistrement trouvé." header={header} responsiveLayout="scroll">
                        {/* <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column> */}
                        <Column headerStyle={{ width: '3rem' }} body={visibleBody}></Column>
                        <Column field="code" header="Code" sortable body={codeBodyTemplate} headerStyle={{ width: '10%', minWidth: '6rem' }}></Column>
                        {/* <Column field="annee.libelle" header="Année" sortable body={anneeBodyTemplate} headerStyle={{ width: '14%', minWidth: '8rem' }}></Column> */}
                        <Column field="filiere" header="Filière" body={filiereBodyTemplate} headerStyle={{ width: '14%', minWidth: '7rem' }}></Column>
                        <Column field="niveau" header="Niveau" body={niveauBodyTemplate} sortable headerStyle={{ width: '14%', minWidth: '7rem' }}></Column>
                        <Column field="libelle" header="Libellé" sortable body={libelleBodyTemplate} headerStyle={{ width: '14%', minWidth: '5rem' }}></Column>
                        <Column field="serie" header="Série" body={serieBodyTemplate} sortable headerStyle={{ width: '14%', minWidth: '4rem' }}></Column>
                        <Column field="languevivante" header="LV2" body={lv2BodyTemplate} sortable headerStyle={{ width: '14%', minWidth: '9rem' }}></Column>
                        <Column field="effectif" header="Effectif max" body={effectifBodyTemplate} sortable headerStyle={{ width: '14%', minWidth: '5rem' }}></Column>
                        {/* <Column field="pp" header="PP" body={ppBodyTemplate} sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>  */}
                        <Column body={actionBodyTemplate} header="Actions" headerStyle={{ width: '14%', minWidth: '9rem' }}></Column>
                    </DataTable>

                    <Dialog visible={classeDialog} style={{ width: width }} header={headerTitle} modal className="p-fluid" footer={classeDialogFooter} onHide={hideDialog}>

                        <div className="formgroup-inline">
                            <div className="card p-fluid" style={{ width: '450px' }}>
                                <div className="field col-12">
                                    <label htmlFor="code"><b>Code</b></label>
                                    <InputText value={classe.code} onChange={(e) => onInputClasseChange(e, 'code')} id="code" required autoFocus className={classNames({ 'p-invalid': submitted && !classe.code })} />
                                    {submitted && !classe.code && <small className="p-invalid">Le code est requis.</small>}
                                </div>
                                <div className="field col-12">
                                    <label htmlFor="libelle"><b>Libellé</b></label>
                                    <InputText id="libelle" value={classe.libelle} onChange={(e) => onInputClasseChange(e, 'libelle')} required className={classNames({ 'p-invalid': submitted && !classe.libelle })} />
                                    {submitted && !classe.libelle && <small className="p-invalid">Le libellé est requis.</small>}
                                </div>
                                <div className="field col-12">
                                    <label htmlFor="effectif"><b>Effectif Maximal</b></label>
                                    <InputNumber id="effectif" min={effectif == null ? 0 : effectif} value={classe.effectif} onValueChange={(e) => onInputNumberChange(e, 'effectif')} required className={classNames({ 'p-invalid': submitted && !classe.effectif })} />
                                    {submitted && !classe.effectif && <small className="p-invalid">L'effectif est requis.</small>}
                                </div>
                                {/* <div className="field col-12">
                                    <label htmlFor="annee"><b>Année scolaire</b></label>
                                    <Dropdown id="annee" value={classe.annee.id} onChange={(e) => onSelectClasseChange(e, 'annee')} options={annees} required optionValue="id" optionLabel="libelle" placeholder="Selectionner l'année" className={classNames({ 'p-invalid': submitted && !(classe.annee.id) })} />
                                    {submitted && !(classe.annee.id) && <small className="p-invalid">L'année est requise.</small>}
                                </div> */}

                                <div className="field col-12">
                                    <label htmlFor="branche"><b>branche</b></label>
                                    <Dropdown id="branche" value={classe.branche.id} onChange={(e) => onSelectClasseChange(e, 'branche')} required options={branches} optionValue="id" optionLabel="libelle" placeholder="Selectionner la branche" className={classNames({ 'p-invalid': submitted && !classe.branche.id })} />
                                    {submitted && !classe.branche.id && <small className="p-invalid">La branche est requise.</small>}
                                </div>


                                <div className="field col-12">
                                    <label htmlFor="langueVivante"><b>LV2</b></label>
                                    <Dropdown id="langueVivante" value={classe.langueVivante.id} onChange={(e) => onSelectClasseChange(e, 'langueVivante')} options={langues} optionValue="id" optionLabel="libelle" placeholder="Selectionner la langue" />
                                </div>

                                <div className="field col-12">
                                    <label htmlFor="visible" style={{ width: '100%' }}><b>Visibilité</b></label>
                                    <ToggleButton id="visible" style={{ width: '30%' }} checked={classe.visible} onChange={(e) => onCheckVisible(e)} onLabel="Visible" offLabel="Masquer" onIcon="pi pi-eye" offIcon="pi pi-eye-slash" />
                                </div>

                            </div>
                            {update &&
                                (<div style={{ width: '50%' }}>
                                    <h5> </h5>
                                    <TabView >
                                        <TabPanel header="Liste des professeurs">
                                            <DataTable value={professeurs} paginator className="p-datatable-gridlines" showGridlines rows={10}
                                                dataKey="id" loading={loading} responsiveLayout="scroll"
                                                emptyMessage="Aucune donnée trouvée">
                                                <Column header="Matière" field='matiere.libelle' style={{ minWidth: '10rem' }} />
                                                <Column header="Professeur" field='personnel.nom' style={{ minWidth: '10rem' }} />
                                                <Column header="Coef." field='matiere.coef' style={{ minWidth: '5rem' }} />
                                            </DataTable></TabPanel>
                                        <TabPanel header="Emploi du temps">
                                            <DataTable value={emploiTemps} paginator className="p-datatable-gridlines" showGridlines rows={10}
                                                dataKey="id" loading={loading2} responsiveLayout="scroll"
                                                emptyMessage="Aucune donnée trouvée">
                                                <Column header="Jour" style={{ minWidth: '8rem' }} />
                                                <Column header="Heure Deb" style={{ minWidth: '7rem' }} />
                                                <Column header="Heure Fin" style={{ minWidth: '7rem' }} />
                                                <Column header="Matière" style={{ minWidth: '9rem' }} />
                                            </DataTable>
                                        </TabPanel>

                                    </TabView>
                                </div>)
                            }
                        </div>
                    </Dialog>

                    <Dialog visible={deleteClasseDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteClasseDialogFooter} onHide={hideDeleteClasseDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {classe && <span>Etes vous sûr de vouloir Supprimer la classe <b>{classe.code} - {classe.libelle}</b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteClassesDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteClassesDialogFooter} onHide={hideDeleteClassesDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {classe && <span>Êtes vous sûr de vouloir supprimer les éléments sélectionnés?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(MyClasses, comparisonFn);