import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { InputText } from 'primereact/inputtext';
import classNames from 'classnames';
import { Dropdown } from 'primereact/dropdown';
// import { MatiereService } from '../service/MatiereService';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { PersonnelService } from '../service/PersonnelService';
import { PersonnelMatiereClasseService } from '../service/PersonnelMatiereClasseService';
import { ClasseService } from '../service/ClasseService';
import { Chart } from 'primereact/chart';

import { ClasseEleveService } from '../service/ClasseEleveService';
import { MatiereEcoleService } from '../service/MatiereEcoleService';


const PersonnelMatiereClasse = () => {

    let emptyPersonnel = {
        id: null,
        nom: "",
        prenom: ""

    }

    let emptyMatiere = {
        id: null,
        code: "",
        libelle: ""
    }

    let emptyClasse = {
        id: null,
        code: "",
        libelle: ""
    }

    let emptyAnnee = {
        id: null,
    }

    let emptyPersClasseMat = {
        matiere: emptyMatiere,
        personnel: emptyPersonnel,
        classe: emptyClasse,
        annee: emptyAnnee,
        user : sessionStorage.getItem("idUser")
    }

    let emptyStat = {
        classe: null,
        effectifTotal: 0,
        effectifActuel: 0,
        moyenneTop: 0,
        moyenneDown: 0,
        moyenne: 0,
        evaluationTot: 0,
        evaluationActelle: 0,
        nbreHeureCours: 0,
        nbreHeureAbs: 0

    }
    const [globalFilter, setGlobalFilter] = useState(null);

    const toast = useRef(null);
    const dt = useRef(null);
    // const [nom, setnom] = useState('');
    // const [prenom, setprenom] = useState('');
    const [personnel, setPersonnel] = useState(emptyPersonnel);
    const [matieres, setMatieres] = useState(null);
    const [matiere, setMatiere] = useState(emptyMatiere);
    const [personnelMatiereClasse, setPersonnelMatiereClasse] = useState(emptyPersClasseMat);
    const [personnelMatiereClasses, setPersonnelMatiereClasses] = useState(null);
    const [persMatClassesFiche, setPersMatClassesFiche] = useState(null);
    const [classeMatieres, setClasseMatieres] = useState(null);
    const [openForm, setOpenForm] = useState(false);
    const [persMatClasseTab, setPersMatClasseTab] = useState([]);
    const [persMatClasseTabTemp, setPersMatClasseTabTemp] = useState([]);
    const [persMatClasseTabTemp2, setPersMatClasseTabTemp2] = useState([]);
    const [stat, setStat] = useState(emptyStat);
    // const [presence, setPresence] = useState(0);
    // const [absence, setAbsence] = useState(0);

    const [professeurs, setProfesseurs] = useState(null);
    const [classes, setClasses] = useState(null);
    const [effectifAct, setEffectifAct] = useState(0);
    const [deletePmcDialog, setDeletePmcDialog] = useState(false);
    const [submitted, setSubmitted] = useState(null);
    const [visibled, setVisibled] = useState(false);
    const [selectedCustomer2, setSelectedCustomer2] = useState(null);
    const [chartData, setChartData] = useState({
        labels: ['Presence', 'Absence'],
        datasets: [
            {
                data: [1, 0],
                backgroundColor: [
                    "#FF6384",
                    "#36A2EB"

                ],
                hoverBackgroundColor: [
                    "#FF6384",
                    "#36A2EB"

                ]
            }]
    });

    // CHART EDFAULT VALUE

    const [chartData1] = useState({
        labels: ['Presence', 'Absence'],
        datasets: [
            {
                data: [20, 5],
                backgroundColor: [
                    "#FF6384",
                    "#36A2EB"

                ],
                hoverBackgroundColor: [
                    "#FF6384",
                    "#36A2EB"

                ]
            }]
    });

    const [chartData2] = useState({
        labels: ['Presence', 'Absence'],
        datasets: [
            {
                data: [15, 1],
                backgroundColor: [
                    "#FF6384",
                    "#36A2EB"

                ],
                hoverBackgroundColor: [
                    "#FF6384",
                    "#36A2EB"

                ]
            }]
    });

    const [lightOptions] = useState({
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                }
            }
        }
    });

    useEffect(() => {
        const matiereEcoleService = new MatiereEcoleService();
        matiereEcoleService.getByEcoleViaNiveauEnseignement(sessionStorage.getItem("EcoleID")).then(res => setMatieres(res));
    }, [chartData]);


    const getStatProf = (rowData) => {
        // effectif total de la classe
        let _stat = { ...emptyStat };
        _stat.effectifTotal = rowData.classe.effectif;
        // effectif actuel de la classe
        const classeEleveService = new ClasseEleveService();

        classeEleveService.getEffectifByClasseAndAnnee(rowData.classe.id, rowData.annee.id).then(res => { setEffectifAct(res); });
        _stat.effectifActuel = effectifAct;
        setStat(_stat);
        if (rowData.classe.id === 1) {
            setChartData(chartData1);
        } else {
            setChartData(chartData2);
        }

        setSelectedCustomer2(rowData);
    }

    const onSelectMatiereChange = (e) => {
        const val = (e.target && e.target.value) || '';
        const matiereEcoleService = new MatiereEcoleService();
        matiereEcoleService.getById(val).then(resp => setMatiere(resp));

        const personnelMatiereClasseService = new PersonnelMatiereClasseService();
        personnelMatiereClasseService.getByMatiere(val).then(resp => setPersonnelMatiereClasses(resp));
    }

    const openFiche = (data) => {
        const personnelMatiereClasseService = new PersonnelMatiereClasseService();
        setPersonnel(data.personnel);
        personnelMatiereClasseService.getByProfesseur(sessionStorage.getItem('AnneEncours'), data.personnel.id, sessionStorage.getItem('EcoleID')).then(resp => setClasseMatieres(resp));
        setVisibled(true);
    }

    const onHide = () => {
        setVisibled(false);
        setPersMatClassesFiche(null);
    }

    const onSelectChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _persMatClasse = { ...personnelMatiereClasse };
        _persMatClasse[`${name}`].id = val;
        setPersonnelMatiereClasse(_persMatClasse);

        let filterpers = [...persMatClasseTabTemp];

        if (name === 'classe') {
            const persMatClasService = new PersonnelMatiereClasseService();
            persMatClasService.getByClasse(val, sessionStorage.getItem('AnneEncours')).then(res => {
                let _pmlist = [];
                _pmlist = _pmlist.concat(res);
                setPersMatClasseTab(_pmlist);
                setPersMatClasseTabTemp(_pmlist);
                filterpers = [..._pmlist];
            });
        }
        if (name === 'matiere') {
            if (persMatClasseTabTemp.length > 0) {
                let _pmlist = [];
                filterpers = [];
                let _pers = { ...personnelMatiereClasse };
                _pmlist = persMatClasseTabTemp.filter(obj => obj.matiere.id == val);
                setPersMatClasseTab(_pmlist);
                setPersMatClasseTabTemp2(_pmlist);
                filterpers = [..._pmlist];
                _pers.personnel = emptyPersonnel;
                setPersonnelMatiereClasse(_pers);

                console.log(filterpers);
            }
        }
        /**
         * A decommenter si volonté de tri professeur
         */
        // if (name === 'personnel') {
        //     if (persMatClasseTabTemp.length > 0) {
        //         let _pmlist = [...persMatClasseTabTemp2];
        //         console.log(_pmlist);

        //         _pmlist = persMatClasseTabTemp2.filter(obj => obj.personnel.id == val);
        //         setPersMatClasseTab(_pmlist);
        //     }
        // }
    }

    const onShow = (rowData) => {
        /* const personnelMatiereClasseService = new PersonnelMatiereClasseService();
        setPersonnel(rowData.personnel)
        personnelMatiereClasseService.getByProfesseur(rowData).then(resp => setPersMatClassesFiche(resp));
     */
    }

    const reactfindIndexById = (id, atvs) => {
        let index = -1;
        for (let i = 0; i < atvs.length; i++) {
            if (atvs[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }



    const save = () => {
        setSubmitted(true);
        // console.log('coucou');
        // console.log(evaluation.type.id != null);
        if (personnelMatiereClasse.personnel.id != null && personnelMatiereClasse.classe.id != null && personnelMatiereClasse.matiere.id != null) {
            // console.log('coco');
            let _persMatClasseTab = [...persMatClasseTab];
            let _personnelMatiereClasse = { ...personnelMatiereClasse };
            const personnelMatiereClasseService = new PersonnelMatiereClasseService();
            _personnelMatiereClasse.annee.id = sessionStorage.getItem('AnneEncours');

            if (personnelMatiereClasse.id) {
                // console.log(evaluation);
                // const index = reactfindIndexById(personnelMatiereClasse.id, _persMatClasseTab);
                // Cas de modification

                // (evaluationService.updateAndDisplay(_evaluation)).then(resp => {
                //     _evaluations[index] = { ...resp };
                //     setEvaluations(_evaluations);
                //     toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Evaluation modifiée', life: 3000 });
                // }).catch((error) => {
                //     if (error.response) {
                //         console.log('erreur response- vie ecole- evaluation - save' + error.response.status);
                //     } else if (error.request) {
                //         console.log('erreur request- vie ecole' + error.request);
                //     } else {
                //         console.log('Error autres', error.message);
                //     }
                //     toast.current.show({ severity: 'erreur', summary: 'erreur', detail: error.message, life: 3000 });
                // }
                // );

            }
            else {
                // _evaluation.annee = createId();
                console.log(_personnelMatiereClasse);
                (personnelMatiereClasseService.saveAndDisplay(_personnelMatiereClasse)).then(res => {
                    console.log(res);

                    _persMatClasseTab = [res];
                    setPersMatClasseTab(_persMatClasseTab);
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Nouvelle Affection créee ', life: 5000 });
                }).catch((error) => {
                    if (error.response) {
                        console.log('erreur response- vie ecole' + error.response.status);
                    } else if (error.request) {
                        console.log('erreur request- vie ecole' + error.request);
                    } else {
                        console.log('Error autres', error.message);
                    }
                    toast.current.show({ severity: 'error', summary: 'erreur', detail: error.response.data.message, life: 10000 });
                });
            }
            setSubmitted(false);
            setPersonnelMatiereClasse(emptyPersClasseMat);
        }
    }
    const codeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Classe</span>
                {rowData.personnel != null ? rowData.personnel.code : ''}
            </>
        );
    }


    const nomBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Matière</span>
                {rowData.personnel != null ? rowData.personnel.nom + ' ' + rowData.personnel.prenom : ''}
            </>
        );
    }
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-user" tooltip='voir fiche' className="p-button-rounded p-button-info p-button-outlined mr-2" onClick={() => openFiche(rowData)} />
                <Button icon="pi pi-times" tooltip='Supprimer' className="p-button-rounded p-button-danger p-button-outlined mr-2" onClick={() => confirmDeletePmc(rowData)} />
            </div>
        );
    }
    const confirmDeletePmc = (pmc) => {
        pmc.user=sessionStorage.getItem("idUser");
        setPersonnelMatiereClasse(pmc);
        setDeletePmcDialog(true);
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h3 className="m-0">Matière sélectionnée : {matiere.libelle}</h3>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Recherche..." />
            </span>
        </div>
    );

    const openNew = () => {
        setOpenForm(true);
        const personnelService = new PersonnelService();
        const classeService = new ClasseService();
        // le profil professeur  a pour id 8 (à reflechir).
        personnelService.getListByProfil(sessionStorage.getItem('EcoleID'), 8).then(resp => {
            console.log(resp)
            setProfesseurs(resp);
        });
        classeService.getSortedListByEcole(sessionStorage.getItem("EcoleID")).then(resp => setClasses(resp));
    }

    const onHideForm = () => {
        setOpenForm(false);
        setPersMatClasseTab([]);
        setPersonnelMatiereClasse(emptyPersClasseMat);
    }

    const rigthToolbarContent = (
        <React.Fragment>
            <div className="my-2">
                <Button label="Nouveau" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                {/* <Button label="Supprimer" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedClasses || !selectedClasses.length} /> */}
            </div>
        </React.Fragment>
    )

    const nomPrenom = (val) => {
        return (
            <>
                {val.nom + ' ' + val.prenom}
            </>
        )
    }
    const nomPrenomBody = (val) => {
        return (
            <>
                {val.personnel ? val.personnel.nom + ' ' + val.personnel.prenom : ''}
            </>
        )
    }

    const formDialogFooter = () => {
        return (
            <div className='grid col-12'>
                <div className='col-offset-8 col-4'>
                    <Button label="Fermer" className=" p-button-primary" onClick={onHideForm} />
                </div>
            </div>
        )
    }

    const deletePmc = () => {
        const personnelMatiereClasseService = new PersonnelMatiereClasseService();

        personnelMatiereClasseService.deleteByStatus(personnelMatiereClasse).then(res => {
            let _pmcs = personnelMatiereClasses.filter(val => val.id !== personnelMatiereClasse.id);
            setPersonnelMatiereClasses(_pmcs);
            toast.current.show({ severity: 'success', summary: 'Succès', detail: 'Matière retirée au professeur', life: 3000 })

        }).catch((error) => {
            if (error.response) {
                console.log('erreur response- vie ecole' + error.response.status);
            } else if (error.request) {
                console.log('erreur request- vie ecole' + error.request);
            } else {
                console.log('Error autres', error.message);
            }
            toast.current.show({ severity: 'error', summary: 'erreur', detail: error.message, life: 3000 });
        });
        setPersonnelMatiereClasse(emptyPersClasseMat);
        setDeletePmcDialog(false);

    }

    const hideDeletePmcDialog = () => {
        setDeletePmcDialog(false);
        setPersonnelMatiereClasse(emptyPersClasseMat);
    }

    const labelTemplate = (row) => {
    return <>
    {row.libelle} ({row.categorie.libelle})

    </>
    }

    const deletePmcDialogFooter = (
        <>
            <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={hideDeletePmcDialog} />
            <Button label="Oui" icon="pi pi-check" className="p-button-text" onClick={deletePmc} />
        </>
    );

    // const onSelectRow = () => {
    //     console.log(selectedCustomer2);
    // }


    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <div>
                        <label><h3><b>Liste des Professeurs par Matière</b></h3></label>
                    </div>
                    <div className="md:col-offset-4">
                    </div>
                    <div className="col-12 md:col-3 lg:col-3 lg:mb-0">
                        <label className="mx-3" htmlFor="matiere"><b>Matière</b></label>
                        <Dropdown id="matiere" value={matiere.id} filter filterBy="matiere.libelle" onChange={(e) => onSelectMatiereChange(e)} options={matieres}
                            required optionValue="id" optionLabel={labelTemplate} placeholder="Selectionner la matière" className={classNames({ 'p-invalid': submitted && !(matiere.id) })} />
                    </div>
                </div>
            </div>

            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar right={rigthToolbarContent} className="mb-4"></Toolbar>
                    <DataTable ref={dt} value={personnelMatiereClasses}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="affichage {first} de {last} sur {totalRecords} classes"
                        globalFilter={globalFilter} emptyMessage="Aucun enregistrement trouvé." header={header} responsiveLayout="scroll">
                        {/* <Column field="date" header="Date" sortable body={dateBodyTemplate} headerStyle={{ width: '10%', minWidth: '6rem' }}></Column>
                        <Column field="type" header="Type" sortable body={typeBodyTemplate} headerStyle={{ width: '14%', minWidth: '8rem' }}></Column> */}
                        <Column field="personnel.code" header="Matricule" body={codeBodyTemplate} headerStyle={{ width: '14%', minWidth: '7rem' }}></Column>
                        <Column field="personnel.nom" header="Nom et Prénoms" body={nomBodyTemplate} sortable headerStyle={{ width: '14%', minWidth: '7rem' }}></Column>
                        <Column field="classe.libelle" header="Classe" sortable headerStyle={{ width: '14%', minWidth: '5rem' }}></Column>
                        {/* <Column field="observations" header="Observations" sortable body={obsBodyTemplate} headerStyle={{ width: '14%', minWidth: '5rem' }}></Column> */}
                        <Column body={actionBodyTemplate} header="Actions" headerStyle={{ width: '14%', minWidth: '9rem' }}></Column>
                    </DataTable>

                    {<Dialog visible={visibled} onHide={onHide} onShow={onShow} breakpoints={{ '960px': '75vw', '640px': '100vw' }} style={{ width: '70vw' }}>
                        <h3 className="m-4 text-center surface-200 p-2">FICHE INDIVIDUELLE DU PROFESSEUR</h3>
                        <div className="grid">

                            <div className='col-12 md:col-1 lg:col-1'>
                                <img src={`images/profil/image-profile.png`} alt='ma photo' className="shadow-2" width={100} />
                            </div>
                            <div className='grid col-10 md:col-10 lg:col-10 m-3'>
                                <div className='col-6 md:col-5 lg:col-5 mx-4'>
                                    <div><b>Matricule : {personnel.code}</b></div>
                                </div>
                                <div className='col-6 md:col-6 lg:col-6 '>
                                    <div><b>Sexe : </b>Masculin</div>
                                </div>
                                <div className='col-12 md:col-5 lg:col-5 mx-4'>
                                    <div><b>Nom et Prénoms : {personnel.nom} {personnel.prenom}</b></div>
                                </div>

                            </div>

                        </div>
                        <div className='grid'>
                            <div className='col-12'>

                                <h5 className="m-4">Liste des classes en charge</h5>
                            </div>
                            <div className="col-8">
                                <DataTable value={classeMatieres} stateStorage="local" selection={selectedCustomer2} onSelectionChange={e => { getStatProf(e.value); console.log(e.value) }} selectionMode="single" responsiveLayout="scroll">
                                    <Column field="classe.libelle" header="Classe"></Column>
                                    <Column field="matiere.libelle" header="Matiere"></Column>
                                </DataTable>
                            </div>
                            <div className='grid card col-4 px-1 pt-1 mt-1'>
                                <div className='col-12'>
                                    <p className='grid surface-200 p-3'><b>Statistiques</b></p>
                                </div>
                                <div className='col-7'>

                                    <div className='col-12'>
                                        <label htmlFor='nbrEval'>Effectif Tot. :</label> <span id='nbrEval'> <b>{effectifAct} / {stat.effectifTotal}</b></span>
                                    </div>

                                    <div className='col-12'>
                                        <label htmlFor='nbrEval'>Evaluations Tot. :</label> <span id='nbrEval'> <b>2 / 4</b></span>
                                    </div>

                                    <div className='col-12'>
                                        <label htmlFor='nbrEval'>Moyenne <i className="pi pi-arrow-up-right text-green-700 mr-2"></i>:</label> <span id='nbrEval'><b>0 / 20 </b></span>
                                    </div>

                                    <div className='col-12'>
                                        <label htmlFor='nbrEval'>Moyenne <i className="pi pi-arrow-right mr-2"></i>:</label> <span id='nbrEval'> <b>0 / 20</b></span>
                                    </div>

                                    <div className='col-12'>
                                        <label htmlFor='nbrEval'>Moyenne <i className="pi pi-arrow-down-right text-pink-700 mr-2"></i>:</label> <span id='nbrEval'> <b>0 / 20</b></span>
                                    </div>
                                </div>
                                <div className='col-5'>

                                    <div className="flex justify-content-center">
                                        <Chart type="doughnut" refresh data={chartData} options={lightOptions} style={{ width: '250px' }} />
                                    </div>
                                </div>


                            </div>
                        </div>
                    </Dialog>}

                </div>
            </div>

            <Dialog visible={openForm} style={{ width: '70%' }} header="Affecter une matière à un professeur " footer={formDialogFooter} modal className="p-fluid" onHide={onHideForm}>
                <div className='grid'>
                    <div className='col-4'>

                        <div className="col-12">
                            <label className="mx-3" htmlFor="classe"><b>Classe</b></label>
                            <Dropdown id="classe" value={personnelMatiereClasse.classe.id} onChange={(e) => onSelectChange(e, 'classe')} options={classes}
                                required optionValue="id" optionLabel="libelle" placeholder="Selectionner la classe" className={classNames({ 'p-invalid': submitted && !(personnelMatiereClasse.classe.id) })} />
                        </div>
                        <div className="col-12">
                            <label className="mx-3" htmlFor="matiere"><b>Matière</b></label>
                            <Dropdown id="matiere" value={personnelMatiereClasse.matiere.id} onChange={(e) => onSelectChange(e, 'matiere')} options={matieres}
                                required optionValue="id" optionLabel={labelTemplate} placeholder="Selectionner la matière" className={classNames({ 'p-invalid': submitted && !(personnelMatiereClasse.matiere.id) })} />
                        </div>
                        <div className='col-12'>
                            <label className="mx-3" htmlFor="prof"><b>Professeur</b></label>
                            <Dropdown id='prof' value={personnelMatiereClasse.personnel.id} options={professeurs} onChange={(e) => onSelectChange(e, 'personnel')}
                                optionValue='id' optionLabel={nomPrenom} placeholder='selectionner le professeur' className={classNames({ 'p-invalid': submitted && !(personnelMatiereClasse.personnel.id) })}></Dropdown>
                        </div>


                    </div>
                    <div className='col-6 col-offset-2'>

                        <DataTable value={persMatClasseTab} scrollable scrollHeight="400px" emptyMessage='En attente d affectation ...' header='Affectations crées' stripedRows responsiveLayout="scroll">
                            <Column field="classe.libelle" header="Classe"></Column>
                            <Column field="matiere.libelle" header="Matiere"></Column>
                            <Column field={nomPrenomBody} header="Professeur" ></Column>
                        </DataTable>
                    </div>
                    <div className='col-3 col-offset-1'>
                        <Button label="Enregistrer" icon="pi pi-save" className="p-button-success" onClick={() => save()} />
                    </div>

                </div>
            </Dialog>

            <Dialog visible={deletePmcDialog} style={{ width: '450px' }} header="Confirm" modal footer={deletePmcDialogFooter} onHide={hideDeletePmcDialog}>
                <div className="flex align-items-center justify-content-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {personnelMatiereClasse && <span>Etes vous sûr de vouloir retirer la matiere <b>{personnelMatiereClasse.matiere.libelle} au professeur {personnelMatiereClasse.personnel.nom}</b>?</span>}
                </div>
            </Dialog>

        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(PersonnelMatiereClasse, comparisonFn);
