import axios from 'axios';

// const this.getHost() ="https://pouls-scolaire.com/api/";
import { BaseService } from '../BaseService';
export class PublicationsService extends BaseService{


    listTypeOffre() {
        return axios.get(this.getHost()+'type_offre').then(res => res.data);
    }
    listNiveauEtude() {
        return axios.get(this.getHost()+'niveau_etude').then(res => res.data);
    }

    listProfils() {
        return axios.get(this.getHost()+'fonctions/list').then(res => res.data);
    }
    postulerOffre(postuler) {
        return axios.post(this.getHost()+'publications/postuler',postuler).then(res => res.data);
    }

    listPublications(idEcole) {
        return axios.get(this.getHost()+'publications/'+idEcole).then(res => res.data);
    }

    fermerOffre(idPub) {
        return axios.put(this.getHost()+'publications/fermer/'+idPub).then(res => res.data);
    }
    listPublicationParEcole(idEcole,periode) {
        return axios.get(this.getHost()+'publications/candidat-postuler/ecole/'+idEcole+'/'+periode).then(res => res.data);
    }
    listPublicationParCandidat(idCandidat) {
        return axios.get(this.getHost()+'publications/candidat/'+idCandidat).then(res => res.data);
    }
    rechercherPublications(ListNiveau,idEcole,periode,typeFonction,typeOfre) {
        return axios.get(this.getHost()+'publications/rechercher?ListNiveau='+ListNiveau+'&idEcole='+idEcole+'&periode='+periode+'&typeFonction='+typeFonction+'&typeOfre='+typeOfre).then(res => res.data);
    }

    creerPublication(publication) {
        return axios.post(this.getHost()+'publications',publication).then(res => res.data);
    }

    modifierPublication(publication) {
        return axios.put(this.getHost()+'publications',publication).then(res => res.data);
    }


}
