import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Toolbar } from 'primereact/toolbar';
import FileViewer from 'react-file-viewer';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import {EleveService} from '../../service/operations/EleveService';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';

import {Dropdown} from "primereact/dropdown";
import {SouscriptionService} from "../../service/operations/SouscriptionService";
import EmptyPage from "../../pages/EmptyPage";
import {Route} from "react-router-dom";
import { Tooltip } from 'primereact/tooltip';
import { InputTextarea } from 'primereact/inputtextarea';
import emailjs from '@emailjs/browser';

const SouscriptionAvalider = () => {
    const form = useRef();
    let emptyDomaineFormation = {
        domaine_formationid :null ,
        domaine_formation_code :'',
        domaine_formation_libelle:''
    };

    let sendEmailEmpty = {
        subject :'Bonjour SOUM',
        user_name :'SOUMSMITH1',
        user_email:'soumsmith1@gmail.com',
        message:'Juste un text'
    };

    let emptyMessage = {
        message_personnel_sujet :'',
        message_personnel_message :'',
        idEmetteur:null,
        message:''
    };

    let emptySexe = {
        sexe_code :'',
        sexe_libelle :''
    };
    let emptyNiveauEtude = {
        niveau_etudeid :null ,
        niveau_etude_code :'',
        niveau_etude_libelle:''
    };
    let emptyAnneeExperience = {
        nbreAnnee_code :0 ,
        nbreAnnee_libelle :''
    };
    let emptyEtatInscription={
        etat_code:'',
        etat_libelle:''
    } ;
    let souscriptionValidaEmpty= {
        idsouscrip:null ,
        statuts: '',
        messageRefus:''
    } ;
    let emptyfonction = {
        fonctionid :null ,
        fonctioncode :'',
        fonctionlibelle:''
    } ;
    const [sexe, setSexe] = useState(emptySexe);
    const [etatInscription, setetatInscription]  = useState(emptyEtatInscription);
    const [souscVali, setsouscVali] = useState(souscriptionValidaEmpty);
    const [niveauEtude, setNiveauEtude] = useState(emptyNiveauEtude);
    const [domaineFormation, setDomaineFormation] = useState(emptyDomaineFormation);
    const [mfonction, setmfonction] = useState(emptyfonction);
    const defaultValues2 = {
        sous_attent_personnid: null,
        sous_attent_personn_nom: '',
        sous_attent_personn_prenom: '',
        sous_attent_personn_email: '',
        sous_attent_personn_sexe: '',
        sous_attent_personn_diplome_recent:'',
        sous_attent_personn_date_naissance: null,
        sous_attent_personn_nbre_annee_experience:null,
        sous_attent_personn_lien_cv:'',
        niveau_etude:niveauEtude,
        sous_attent_personncode:'',
        domaine_formation:domaineFormation,
        fonction:mfonction,
        sous_attent_personn_login:'',
        sous_attent_personn_password:'',
        sous_attent_personn_donnee: null,
        sous_attent_personn_contact :'',
        sous_attent_personn_lien_piece:'',
        sous_attent_personn_lien_autorisation :''
    }

    const columns = [
        {field: 'inscriptions_status', header: 'Statut Inscription'},
        {field: 'inscriptionsidEleve', header: 'N°'},
        {field: 'nomEleve', header: 'Nom'},
        {field: 'prenomEleve', header: 'Prenom'},
        {field: 'matriculeEleve', header: 'Matricule'},
        {field: 'inscriptions_type', header: 'Type Inscription'}
    ];
    const [selectedColumns, setSelectedColumns] = useState(columns);

    const [filters2, setFilters2] = useState({
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'sous_attent_personnid':{ value: null, matchMode: FilterMatchMode.STARTS_WITH },
        'sous_attent_personn_nom':{ value: null, matchMode: FilterMatchMode.CONTAINS },
        'sous_attent_personn_prenom':{ value: null, matchMode: FilterMatchMode.CONTAINS },
        'sous_attent_personn_nbre_annee_experience':  { value: null, matchMode: FilterMatchMode.CONTAINS } ,
        'domaine_formation.domaine_formation_libelle':  { value: null, matchMode: FilterMatchMode.CONTAINS } ,
        'sous_attent_personn_email':  { value: null, matchMode: FilterMatchMode.CONTAINS } ,
        'fonction.fonctionlibelle':  { value: null, matchMode: FilterMatchMode.CONTAINS }
    });
    const [emailObject, setEmailObject] = useState(sendEmailEmpty);
    const [message, setMessage] = useState(emptyMessage);
    const [userName, setUserName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userMessage, setUserMessage] = useState('');
    const [idModif, setIdmodif] = useState(null);
    const [statut, setstatut] = useState("EN ATTENTE");
    
    const [idUser, setIdUser] = useState(sessionStorage.getItem('idUser'));
    const [domaineFormations, setDomaineFormations] = useState([]);
    const [motifRefu, setmotifRefu] = useState('');
    const [souscriptionAttente, setsouscriptionAttente] = useState((defaultValues2));
    const [souscriptionAttentes, setsouscriptionAttentes] = useState([]);

    const [niveauEtudes, setNiveauEtudes]= useState([]);;
    const [globalFilterValue2, setGlobalFilterValue2] = useState('');
    const [typeOperation, setTypeOperation]  = useState(null);
    const [anneeEncours, setanneeEncours]  = useState("2021-2022");
    const [typeOperation1, settypeOperation1]  = useState("INSCRIPTION");
    const [typeOperation2, settypeOperation2]  = useState("PREINSCRIPTION");
    const [tenant, settenant]  = useState("1");
    
    const [eleves, setEleves]  = useState(null);
    const [filters1, setFilters1] = useState(null);
    const [productDialog, setProductDialog] = useState(false);
    const [FileDialog, setFileDialog] = useState(false);
    const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);

    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const [baseUri, setbaseUri] = useState(window.$baseUri);
   const [fileExten, setExtentfileName] = useState('');
    const [fileUrl, setFileUrl] = useState(baseUri+'souscription-personnel/ouvrir-fichier/');
    const [file, setfile] = useState('');
    const initFilters1 = () => {
        setFilters2({
            'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
            'prenomEleve':{ value: null, matchMode: FilterMatchMode.STARTS_WITH },
            'inscriptions_status':{ value: null, matchMode: FilterMatchMode.STARTS_WITH },
            'inscriptionsidEleve':{ value: null, matchMode: FilterMatchMode.STARTS_WITH },
            'nomEleve':  { value: null, matchMode: FilterMatchMode.STARTS_WITH },
           'matriculeEleve': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            'inscriptions_type': { value: null, matchMode: FilterMatchMode.EQUALS  }
        });
        setGlobalFilterValue2('');
    }
    const onGlobalFilterChange2 = (e) => {
        const value = e.target.value;
        let _filters2 = { ...filters2 };
        _filters2['global'].value = value;

        setFilters2(_filters2);
        setGlobalFilterValue2(value);
    }
    const clearFilter1 = () => {
        initFilters1();
    }
    const sendEmail = (e) => {
      //  e.preventDefault();
        emailjs.sendForm('service_p5b4z05', 'template_khmrq5i',form.current, 'rQ8B8i3jHyGwChdlj')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
    };



    useEffect(() => {
        const souscrService = new SouscriptionService();
          souscrService.listSouscriptionAvalider(statut)
            .then(data => {
            console.log(data)
            setsouscriptionAttentes(data)
        });

    }, [idModif]);

    const formatCurrency = (value) => {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }


    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
    }

    const hideFileDialog = () => {
         setFileDialog(false);
    }

    const hideDeleteProductDialog = () => {
        setDeleteProductDialog(false);
    }

    const hideDeleteProductsDialog = () => {
        setDeleteProductsDialog(false);
    }

    const openFile1 = (fileName) => {
        if(!fileName || fileName.length === 0) {
            toast.current.show({severity:'info', summary: 'Vie-ecoles', detail:'Pas de fichier disponible', life: 3000});
        } else {

            setfile(fileUrl+fileName) ;
            setExtentfileName( fileName.split(".").pop()) ;
            setFileDialog(true);

        }

    }




    const saveProduct = () => {
        setIdmodif(0);
        setSubmitted(true);

      const souscrService = new SouscriptionService();
        if(etatInscription.etat_libelle.trim()==="VALIDEE" ){
            souscVali.messageRefus= motifRefu ;
            souscVali.statuts = etatInscription.etat_libelle ;
            souscVali.idsouscrip = souscriptionAttente.sous_attent_personnid ;
            //Construire objet Message
            message.idEmetteur = idUser ;
            message.message_personnel_sujet ="TRAITEMENT DE LA DEMANDE DE CREATION DE COMPTE"
            message.message_personnel_message = "Votre demande a été "+etatInscription.etat_libelle ;

            souscrService.validerSouscription(souscVali)
                .then(data => {
                 console.log(data)
                setIdmodif(1)
              });
            souscrService.envoyeAdminMess(souscriptionAttente.sous_attent_personnid,message);

            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Souscription traitée ', life: 3000 });
            setProductDialog(false);
            setetatInscription(emptyEtatInscription);
            setsouscVali(souscriptionValidaEmpty)
            setmotifRefu('') ;
         }  else if(etatInscription.etat_libelle==="REFUSEE" && motifRefu.trim())  {
            souscVali.messageRefus= motifRefu ;
            souscVali.statuts = etatInscription.etat_libelle ;
            souscVali.idsouscrip = souscriptionAttente.sous_attent_personnid ;
            //Construire objet Message
            message.idEmetteur = idUser ;
            message.message_personnel_sujet ="TRAITEMENT DE LA DEMANDE DE CREATION DE COMPTE"
            message.message_personnel_message = "Votre demande a été "+etatInscription.etat_libelle +" ,"+"Motif: "+ motifRefu ;
            souscrService.validerSouscription(souscVali)
                .then(data => {
                    console.log(data)
                    setIdmodif(1)
                });
            souscrService.envoyeAdminMess(souscriptionAttente.sous_attent_personnid,message);
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Souscription traitée ', life: 3000 });
            setProductDialog(false);
            setetatInscription(emptyEtatInscription);
            setsouscVali(souscriptionValidaEmpty)
            setmotifRefu('') ;
        }

        else if(etatInscription.etat_libelle==="REFUSEE" && motifRefu.trim()==="")  {
            toast.current.show({ severity: 'error', summary: 'Error Message', detail: 'Veuillez renseigner le motif ', life: 3000 });
        }
        else  {
          toast.current.show({ severity: 'error', summary: 'Error Message', detail: 'Veuillez traiter la demande ou annuler', life: 3000 });
            //setProductDialog(false);
        }


    }


    const viewFiles = () => {
        setFileDialog(true);
    }

    const EtatInscriptions = [
        {etat_code: 'REFUSEE', etat_libelle: 'REFUSEE'},
        {etat_code: 'VALIDEE', etat_libelle: 'VALIDEE'}

    ];
    const Sexe = [
        {sexe_code: 'MASCULIN', sexe_libelle: 'MASCULIN'},
        {sexe_code: 'FEMININ', sexe_libelle: 'FEMININ'}

    ];

    const Statuts = [
        {statut_code: 'AFFECTE', statut_libelle: 'AFFECTE'},
        {statut_code: 'NON_AFFECTE', statut_libelle: 'NON AFFECTE'}
    ];

    const InscriptionTypeOperation2 = [
        'PREINSCRIPTION', 'INSCRIPTION'
    ];

    const InscriptionStatut = [
        'EN ATTENTE', 'VALIDEE'
    ];

    const InscriptionTypeOperation = [
        {inscripStatuCode: 'INSCRIPTION', inscripStatulibelle: 'INSCRIPTION'},
        {inscripStatuCode: 'PREINSCRIPTION', inscripStatulibelle: 'PREINSCRIPTION'}
    ];


    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    {/*<Button label="Nouveau" icon="pi pi-plus" className="p-button-success mr-2" onClick={viewFiles} />*/}
                </div>
            </React.Fragment>
        )
    }

    const IdsouscriptionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">N°</span>
                {rowData.sous_attent_personnid}
            </>
        );
    }


    const NomBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">nom</span>
                {rowData.sous_attent_personn_nom}
            </>
        );
    }

    const PreNomBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Prénom</span>
                {rowData.sous_attent_personn_prenom}
            </>
        );
    }

    const emailBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Email</span>
                {rowData.sous_attent_personn_email}
            </>
        );
    }


    const sexeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Email</span>
                {rowData.sous_attent_personn_sexe}
            </>
        );
    }
    const domaineFormationBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Domaine de formation</span>
                {rowData.domaine_formation.domaine_formation_libelle}
            </>
        );
    }
    const diplomeRecentBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Diplôme récent</span>
                {rowData.sous_attent_personn_diplome_recent}
            </>
        );
    }

    const statutEleveBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Statut</span>
                {rowData.inscriptions_statut_eleve}
            </>
        );
    }
    const nomBreAnneeExperienceBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Nombre année d'experienxe</span>
                {rowData.sous_attent_personn_nbre_annee_experience}
            </>
        );
    }

    const fonctionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Fonction</span>
                {rowData.fonction.fonctionlibelle}
            </>
        );
    }

    const statutInscriptionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Etat</span>
                {rowData.inscriptions_status}
            </>
        );
    }

    const dateNaissanceBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Date naissance</span>
                {rowData.Date_naissanceEleve}
            </>
        );
    }





    const contactBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Cellulaire</span>
                {rowData.contactEleve}
            </>
        );
    }

    const statutInscripBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Cellulaire</span>
                {rowData.inscriptions_status}
            </>
        )
    }


    const typeInscriptionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Cellulaire</span>
                {rowData.inscriptions_type}
            </>
        )
    }
    const editProduct = (souscrip) => {
        setsouscriptionAttente({ ...souscrip });
        setUserName('GAIN SARL') ;
        setUserEmail('soumsmith1@gmail.com') ;
        setUserMessage('Bonjour Monsieur '+souscrip.sous_attent_personn_nom+' '+souscrip.sous_attent_personn_prenom +'\n'+
            'Veuillez utiliser ces paramètres pour vous connecter à notre application'+'\n');
        setProductDialog(true);
    }
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editProduct(rowData)} />
               </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Liste des élèves</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Rechercher..." />
            </span>
        </div>
    );
    const typeItemTemplate = (option) => {
        return <span className={`customer-badge status-${option}`}>{option}</span>;
    }

    const statusItemTemplate = (option) => {
        return <span className={`customer-badge status-${option}`}>{option}</span>;
    }
    const typeOperationFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={InscriptionTypeOperation2} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={typeItemTemplate} placeholder="Sélectionnez le type" className="p-column-filter" showClear />;
    }

    const statutFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={InscriptionStatut} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={statusItemTemplate} placeholder="Sélectionnez le Statut" className="p-column-filter" showClear />;
    }
    const productDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
        </>
    );

    const FileDialogFooter = (
        <>
           <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={hideFileDialog} />
        </>
    );




    const renderHeader2 = () => {
        return (
            <div className="flex justify-content-end">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue2} onChange={onGlobalFilterChange2} placeholder="Mots clés..." />
                </span>
            </div>
        )
    }
    const header2 = renderHeader2();
    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} ></Toolbar>

                    <div className="card">
                        <h5>Liste des souscription en attentes</h5>
                        <p>Liste des souscription en attentes de validation </p>
                        <DataTable value={souscriptionAttentes} paginator className="p-datatable-customers" size="small" rows={10}
                                   dataKey="sous_attent_personnid" filters={filters2} filterDisplay="row"  responsiveLayout="scroll"
                                   globalFilterFields={['sous_attent_personnid','sous_attent_personn_nom', 'sous_attent_personn_prenom','sous_attent_personn_nbre_annee_experience','domaine_formation.domaine_formation_libelle', 'sous_attent_personn_email','fonction.fonctionlibelle']} header={header2} emptyMessage="Pas de souscription  en attente pour ce critère de choix!">

                            <Column field="sous_attent_personnid" header="N°" filterField="sous_attent_personnid" body={IdsouscriptionBodyTemplate}  />

                            <Column header="Nom" filterField="sous_attent_personn_nom" style={{ minWidth: '15rem' }} body={NomBodyTemplate}  />

                            <Column filterField="sous_attent_personn_prenom" header="Prénom"  body={PreNomBodyTemplate} style={{ minWidth: '18rem' }} />

                            <Column filterField="sous_attent_personn_email" header="Email"  body={emailBodyTemplate} style={{ minWidth: '18rem' }} />

                            <Column filterField="sous_attent_personn_sexe" header="Sexe"  body={sexeBodyTemplate}  style={{ minWidth: '18rem' }} />

                            <Column filterField="domaine_formation.domaine_formation_libelle" header="Domaine de formation"  body={domaineFormationBodyTemplate} style={{ minWidth: '18rem' }} />

                            <Column filterField="sous_attent_personn_nbre_annee_experience" header="Année d'expérience"  body={nomBreAnneeExperienceBodyTemplate} />
                            <Column filterField="fonction.fonctionlibelle" header="Fonction"  body={fonctionBodyTemplate}  style={{ minWidth: '18rem' }}/>
                            <Column body={actionBodyTemplate}></Column>
                        </DataTable>
                    </div>

                    <Dialog visible={productDialog} style={{ width: '650px' }} header="Details souscripteur" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                        <form ref={form} onSubmit={saveProduct}>
                        <div className="formgrid grid">
                            <div className="field col">
                                <label htmlFor="name1">Diplôme récent</label>
                                <InputText value={souscriptionAttente.sous_attent_personn_diplome_recent===""? "NEANT":souscriptionAttente.sous_attent_personn_diplome_recent } disabled />
                            </div>

                            <div className="field col">
                                <label htmlFor="name1">Date de naissance</label>
                                <InputText value={souscriptionAttente.sous_attent_personn_date_naissance} disabled />
                            </div>
                            <div className="field col">
                                <label htmlFor="name1">Contact</label>
                                <InputText value={souscriptionAttente.sous_attent_personn_contact} disabled />
                            </div>

                            </div>






                        <div className="formgrid grid">
                        <div className="field col">
                            <Dropdown value={etatInscription} onChange={(e) => setetatInscription(e.value)} options={EtatInscriptions} optionLabel="etat_libelle" placeholder="Valider l'inscription" />
                        </div>
                        </div>

                        <div className="formgrid grid">
                            <div className="field col">
                                <InputTextarea value={motifRefu}  onChange={(e) => setmotifRefu(e.target.value)} rows={5} cols={30} autoResize />
                            </div>
                        </div>


                            <input type="text" name="user_name" value={userName} onChange={(e) => setUserName(e.target.value)}  hidden={true} />

                            <input type="email" name="user_email" value={userEmail} onChange={(e) => setUserEmail(e.target.value)} hidden={true} />

                            <textarea name="message" value={userMessage} onChange={(e) => setUserMessage(e.target.value)} hidden={true} />
                            <input type="submit" value="Send" hidden={true} />
                        </form>
                        <div className="flex align-items-center export-buttons">
                            <Tooltip target=".export-buttons>button" position="bottom" />
                            <Button type="button" data-pr-tooltip="Fichier1" icon="pi pi-file" className="mr-2" onClick={() => openFile1(souscriptionAttente.sous_attent_personn_lien_cv)}  />
                            <Button data-pr-tooltip="Fichier2" icon="pi pi-file" className="mr-2" onClick={() => openFile1(souscriptionAttente.sous_attent_personn_lien_piece)}  />
                            <Button data-pr-tooltip="Fichier3" icon="pi pi-file" className="mr-2" onClick={() => openFile1(souscriptionAttente.sous_attent_personn_lien_autorisation)}  />
                        </div>
                    </Dialog>

                    <Dialog visible={FileDialog} style={{ width: '750px', height:'800px'}}  modal className="p-fluid"  onHide={hideFileDialog}>
                        <FileViewer
                            fileType={fileExten}

                            filePath={file}
                        />
                    </Dialog>


                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(SouscriptionAvalider, comparisonFn);
