import axios from 'axios';

import { BaseService } from '../BaseService';
export class InscriptionService extends BaseService{

    creerInscription(IdentificationsEleve) {
        return axios.post(this.getHost()+'inscriptions/',IdentificationsEleve).then(res => res.data);
    }


}
