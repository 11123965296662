import axios from 'axios';
import { BaseService } from './BaseService';

export class ProgressionSeanceService extends BaseService {

  async handleSave(psList) {
    console.log('prg_seance ', psList)
    return axios.post(this.getHost() + 'progression-seance/handle-save', psList).then(res => res.data);
  }

  async getBySeanceAndPosition(seanceId, position){
  return axios.get(this.getHost() + 'progression-seance/get-by-seance-and-position?seance='+seanceId+'&position='+position).then(res => res.data);
  }
  
}