import React, { useState, useEffect, useRef } from 'react';
// eslint-disable-next-line
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { AnneeService } from '../service/AnneeService';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Tag } from 'primereact/tag';
import { RadioButton } from 'primereact/radiobutton';
import './css/ButtonDemo.css';
import { PeriodiciteService } from '../service/PeriodiciteService';
import { NiveauEnseignementService } from '../service/NiveauEnseignementService';
import { Calendar } from 'primereact/calendar';
import { PeriodeService } from '../service/PeriodeService';
import 'primereact/resources/themes/saga-blue/theme.css';
import { BiWindowOpen } from "react-icons/bi";
import { FaLockOpen } from "react-icons/fa";
import { HiLockClosed } from "react-icons/hi";
import { IoMdWarning } from "react-icons/io";
import PuffLoader from "react-spinners/PuffLoader";
import { EvaluationPeriodeService } from '../service/EvaluationPeriodeService';
import { BrancheService } from '../service/BrancheService';
import { TypeActiviteService } from '../service/TypeActiviteService';

const EvaluationPeriode = () => {

  let emptyEvalPeriode = {
    annee: { id: sessionStorage.getItem('AnneEncours') },
    niveau: { id: null },
    ecole: { id: sessionStorage.getItem('EcoleID') },
    numero: 0,
    periode: { id: null },
    typeEvaluation: { id: null },
    user: sessionStorage.getItem('idUser')
  }

  const [annee, setAnnee] = useState();
  const [annees, setAnnees] = useState([]);
  const [evalPeriodes, setEvalPeriodes] = useState([]);
  const [evaluationPeriode, setEvaluationPeriode] = useState(emptyEvalPeriode);
  const [evaluationSaved, setEvaluationSaved] = useState([]);
  const [niveaux, setNiveaux] = useState([]);
  const [types, setTypes] = useState([]);
  const [evaluationListSaved, setEvaluationListSaved] = useState([]);
  const [globalFilter, setGlobalFilter] = useState(null);
  const dt = useRef(null);
  const toast = useRef(null);
  const [submitted, setSubmitted] = useState(false);
  const [evaluationPeriodeDialog, setEvaluationPeriodeDialog] = useState(false);
  const [headerTitle, setHeaderTitle] = useState("");
  const [anneeToCloture, setAnneeToCloture] = useState(null)
  const [anneeToOpenOrClose, setAnneeToOpenOrClose] = useState({});
  const [anneeOuverte, setAnneeOuverte] = useState({});
  const [niveauxEnseignement, setNiveauxEnseignement] = useState([]);
  const [periodicites, setPeriodicites] = useState([]);
  const [periodes, setPeriodes] = useState([]);
  const [selectedPeriodicite, setSelectedPeriodicite] = useState({ id: 2 });
  const [marqueurIndex, setMarqueurIndex] = useState(0);
  const [datasList, setDatasList] = useState([]);
  const [myArrayDeb, setMyArrayDeb] = useState(new Array(12).fill(null));
  const [myArrayFin, setMyArrayFin] = useState(new Array(12).fill(null));
  const [myArrayLim, setMyArrayLim] = useState(new Array(12).fill(null));
  const [myArrayNbEval, setMyArrayNbEval] = useState(new Array(12).fill(null));
  const [clotureSpinner, setClotureSpinner] = useState(false);
  const [typeAction, setTypeAction] = useState('new');
  const [clotureDialog, setClotureDialog] = useState(false);
  const [openShareConfirmDialog, setOpenShareConfirmDialog] = useState(false);
  const [flatIsOuvert, setFlatIsOuvert] = useState(false);
  const [toDelete, setToDelete] = useState(emptyEvalPeriode);
  const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false);


  useEffect(() => {

    const evaluationPeriodeService = new EvaluationPeriodeService();
    evaluationPeriodeService.findByAnneeAndEcole(sessionStorage.getItem('AnneEncours'), sessionStorage.getItem('EcoleID')).then(resp => {
      setEvalPeriodes(resp);
    }).catch((error) => {
      if (error.response) {
        console.log('erreur response- pouls-scolaire' + error.response.status);
      } else if (error.request) {
        console.log('erreur request- pouls-scolaire' + error.request);
      } else {
        console.log('Error autres', error.message);
      }
      toast.current.show({ severity: 'error', summary: 'Erreur', detail: error.message, life: 10000 })

    });

    console.log('Use effect method trigged');
    // console.log(infosAnnee);
  }, [annee]);



  const onNumeroInputChange = (e) => {
    let val = e.value;
    console.log('numero ::: ' + val);
    let _evaluationPeriode = { ...evaluationPeriode };
    _evaluationPeriode.numero = val;
    setEvaluationPeriode(_evaluationPeriode);
  }


  const onSelectChange = (e, name) => {
    (async () => {
      const val = (e.target && e.target.value) || '';
      let _evaluationPeriode = { ...evaluationPeriode };
      _evaluationPeriode[`${name}`] = { id: val };

      if (name === 'periode' || name === 'niveau') {
        console.log(name);
        console.log(evaluationPeriode.periode.id)
        console.log(evaluationPeriode.niveau.id)
        if (_evaluationPeriode[`periode`].id != null && _evaluationPeriode[`niveau`].id != null) {
          console.log('coucou');
          const evalPeriodeService = new EvaluationPeriodeService();
          await evalPeriodeService.findByAnneeAndEcoleAndPeriodeAndNiveau(sessionStorage.getItem('AnneEncours'), sessionStorage.getItem('EcoleID'), _evaluationPeriode.periode.id, _evaluationPeriode.niveau.id).then(resp => {
            console.log(typeof (resp))
            if (resp != null && resp != '') {
              _evaluationPeriode.typeEvaluation = resp.typeEvaluation;
              _evaluationPeriode.numero = resp.numero;
              // setEvaluationPeriode(_evaluationPeriode);
              console.log(resp);
            }
          })
        }
      }

      setEvaluationPeriode(_evaluationPeriode);
    })();
  }
  const hideDialog = () => {
    setSubmitted(false);
    setEvaluationPeriodeDialog(false);
    setEvaluationPeriode(emptyEvalPeriode);
  }

  const edit = async (rowData) => {
    console.log(rowData);
    setSubmitted(false);
    setEvaluationPeriodeDialog(true);
    const evaluationPeriodeService = new EvaluationPeriodeService();


    const periodeService = new PeriodeService();
    const brancheService = new BrancheService();
    const typeEvaluationService = new TypeActiviteService();
    setHeaderTitle("Définition des évaluations par période");
    periodeService.getByPeriodicite(sessionStorage.getItem('periodiciteId')).then(resp => {
      setPeriodes(resp);
    }).catch((error) => {
      if (error.response) {
        console.log('erreur response- Pouls scolaire' + error.response.status);
      } else if (error.request) {
        console.log('erreur request- Pouls scolaire' + error.request);
      } else {
        console.log('Error autres', error.message);
      }
    });

    brancheService.getByNiveauEnseigment(sessionStorage.getItem('EcoleID')).then(resp => {
      setNiveaux(resp);
    }).catch((error) => {
      if (error.response) {
        console.log('erreur response- Pouls scolaire' + error.response.status);
      } else if (error.request) {
        console.log('erreur request- Pouls scolaire' + error.request);
      } else {
        console.log('Error autres', error.message);
      }
    });

    typeEvaluationService.getListByEcole(sessionStorage.getItem('EcoleID')).then(resp => {
      setTypes(resp);
    }).catch((error) => {
      if (error.response) {
        console.log('erreur response- Pouls scolaire' + error.response.status);
      } else if (error.request) {
        console.log('erreur request- Pouls scolaire' + error.request);
      } else {
        console.log('Error autres', error.message);
      }
    });
    ///////////


    evaluationPeriodeService.findById(rowData.id).then(resp => {
      setEvaluationPeriode(resp);
      setEvaluationListSaved([resp]);
    }).catch((error) => {
      if (error.response) {
        console.log('erreur response- Pouls scolaire' + error.response.status);
      } else if (error.request) {
        console.log('erreur request- Pouls scolaire' + error.request);
      } else {
        console.log('Error autres', error.message);
      }
    });

  }

  const reactfindIndexById = (id, tab) => {
    let index = -1;
    for (let i = 0; i < tab.length; i++) {
      if (tab[i].id === id) {
        index = i;
        break;
      }
    }

    return index;
  }


  const save = () => {
    let evaluationPeriodeService = new EvaluationPeriodeService();
    setSubmitted(true);
    console.log(evaluationPeriode);
    if (evaluationPeriode.annee.id != null && evaluationPeriode.ecole.id != null && evaluationPeriode.periode.id != null && evaluationPeriode.niveau.id != null && evaluationPeriode.typeEvaluation.id != null && evaluationPeriode.numero != '' && evaluationPeriode.numero != 0) {
      evaluationPeriodeService.saveHandle(evaluationPeriode).then(res => {
        console.log(res);
        let indexTab = reactfindIndexById(res.id, evaluationListSaved);
        let indexList = reactfindIndexById(res.id, evalPeriodes);
        let _evaluationListSaved = [...evaluationListSaved];
        let _evalPeriodes = [...evalPeriodes];
        let _evaluationPeriode = emptyEvalPeriode;
        if (indexTab === -1) {
          _evaluationListSaved.push(res);
        } else {
          _evaluationListSaved[indexTab] = res;
        }
        setEvaluationListSaved(_evaluationListSaved);

        if (indexList === -1) {
          _evalPeriodes.push(res);
        } else {
          _evalPeriodes[indexList] = res;
        }
        setEvalPeriodes(_evalPeriodes);
        setSubmitted(false);
        _evaluationPeriode.periode = res.periode;
        setEvaluationPeriode(_evaluationPeriode);
        toast.current.show({ severity: 'success', summary: 'Succes', detail: 'Evaluation définie', life: 10000 });

      }).catch((error) => {
        // hideDialog();
        toast.current.show({ severity: 'error', summary: 'erreur', detail: error.message, life: 10000 });
      });

    }
  }


  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h3 className="m-0">Liste des périodes d&apos;&eacute;valuation </h3>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Recherche..." />
      </span>
    </div>
  );

  const evalPeriodeDialogFooter = (
    <div className='border-3 surface-border p-2'>
      {/* {console.log(anneeOuverte)} */}
      <Button label="Annuler" icon="pi pi-times" className="p-button-outlined" onClick={hideDialog} />
      <Button label="Enregistrer" icon="pi pi-check" disabled={false} className="p-button-outlined p-button-success mr-5" onClick={() => save()} />
      {/* <Button label="Ouvrir Année" icon="pi pi-check" className={(annee?.statut === 'DIFFUSE' && Object.keys(anneeOuverte).length === 0) ? "p-button-danger mx-5" : 'hidden'} onClick={open} /> */}
    </div>
  );

  const deleteDialog = (etat, obj) => {
    setOpenDeleteConfirmDialog(etat);
    setToDelete(obj);
  }

  const actionButtons = (rowData) => {
    return (
      <div className="button-demo">
        <div>
          <Button title="Details" icon="pi pi-pencil" onClick={() => edit(rowData)} className="p-button-sm p-button-warning" />
          <Button title="Supprimer" icon="pi pi-times" onClick={() => deleteDialog(true, rowData)} className="p-button-sm p-button-danger" />
        </div>
      </div >
    )
  }

  const displayStatut = (rowData) => {
    return (
      <span>{rowData.typeEvaluation.libelle} N&deg; {rowData.numero}</span>
    )
  }

  const headerBlock = () => {
    return (
      <div className='m-3 text-2xl pl-2 py-3 surface-300'>
        {headerTitle}
      </div>
    )
  }

  const openNew = () => {
    setSubmitted(false);
    setEvaluationPeriodeDialog(true);
    // setEvaluation({ ...emptyEvaluation })
    // setLblBtnSave('Enregistrer');
    const periodeService = new PeriodeService();
    const brancheService = new BrancheService();
    const typeEvaluationService = new TypeActiviteService();
    setHeaderTitle("Définition des évaluations par période");
    periodeService.getByPeriodicite(sessionStorage.getItem('periodiciteId')).then(resp => {
      setPeriodes(resp);
    }).catch((error) => {
      if (error.response) {
        console.log('erreur response- Pouls scolaire' + error.response.status);
      } else if (error.request) {
        console.log('erreur request- Pouls scolaire' + error.request);
      } else {
        console.log('Error autres', error.message);
      }
    });

    brancheService.getByNiveauEnseigment(sessionStorage.getItem('EcoleID')).then(resp => {
      setNiveaux(resp);
    }).catch((error) => {
      if (error.response) {
        console.log('erreur response- Pouls scolaire' + error.response.status);
      } else if (error.request) {
        console.log('erreur request- Pouls scolaire' + error.request);
      } else {
        console.log('Error autres', error.message);
      }
    });

    typeEvaluationService.getListByEcole(sessionStorage.getItem('EcoleID')).then(resp => {
      setTypes(resp);
    }).catch((error) => {
      if (error.response) {
        console.log('erreur response- Pouls scolaire' + error.response.status);
      } else if (error.request) {
        console.log('erreur request- Pouls scolaire' + error.request);
      } else {
        console.log('Error autres', error.message);
      }
    });
  }

  const rightToolbarTemplate = () => {
    return (

      <div className="my-2">
        {/* <Button label="Importer" icon="pi pi-file" className="p-button-info mx-2" onClick={openImport} disabled={!classe?.id} /> */}
        <Button label="Nouveau" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
      </div>

    )
  }

  const headerDialog = () => {
    return (
      <div className='border-bottom-2 font-bold text-2xl py-1 surface-200'>
        Confirmation
      </div>
    )
  }

  const deleteProcess = () => {
    const evaluationPeriodeService = new EvaluationPeriodeService();
    evaluationPeriodeService.deleteProcess(toDelete.id).then(
      resp => {
        let _index = reactfindIndexById(toDelete.id, evalPeriodes);
        let _list = [... evalPeriodes];
        if(_index !== -1){
        _list.splice(_index,1);
        }
        console.log(resp);
        setEvalPeriodes(_list);
        deleteDialog(false, emptyEvalPeriode);

        toast.current.show({ severity: 'success', summary: 'Succes', detail: 'Suppression effectuée', life: 10000 });
      }

    ).catch((error) => {
      if (error.response) {
        console.log('erreur response- Pouls scolaire' + error.response.status);
      }
      deleteDialog(false, emptyEvalPeriode);
      toast.current.show({ severity: 'error', summary: 'Erreur', detail: "Suppression non effectuée", life: 10000 });
    });
  }

  const deleteDialogFooter = () => {
    return (
      <>
        <Button label="Annuler" icon="pi pi-times" className="p-button-outlined p-button-warning" onClick={hideDeleDialog} />
        <Button label="Supprimer" icon="pi pi-trash" className="p-button-danger" onClick={() => deleteProcess()}></Button>
      </>)
  };

  const hideDeleDialog = () => {
    deleteDialog(false, emptyEvalPeriode);
  }

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar className="mb-4" right={rightToolbarTemplate} ></Toolbar>
          <DataTable ref={dt} value={evalPeriodes}
            dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} header={header} className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="affichage de {first} à {last} sur {totalRecords} notes"
            globalFilter={globalFilter} emptyMessage="Aucun enregistrement trouvé." responsiveLayout="scroll">
            <Column header="Année" field='annee.libelle' headerStyle={{ Width: '1rem' }}></Column>
            <Column header="Période" field='periode.libelle' headerStyle={{ minWidth: '5rem' }}></Column>
            <Column header="Niveau" field='niveau.libelle' headerStyle={{ Width: '1rem' }}></Column>
            <Column header="Type evaluation" headerStyle={{ Width: '1rem' }} body={displayStatut}></Column>
            <Column header="Action" headerStyle={{ Width: '1rem' }} body={actionButtons}></Column>
          </DataTable>

          <Dialog visible={evaluationPeriodeDialog} style={{ width: '70%' }} header={headerBlock} footer={evalPeriodeDialogFooter} modal className="p-fluid shadow-2" onHide={hideDialog}>
            <div className='grid col-12'>
              <div className='lg:col-4 md:col-4 sm:col-12'>
                <div className='col-12'>
                  <label className="mx-3" htmlFor="periode"><b>Periode</b></label>
                  <Dropdown id="periode" value={evaluationPeriode.periode.id} onChange={(e) => onSelectChange(e, 'periode')} options={periodes}
                    required optionValue="id" optionLabel="libelle" placeholder="Sélectionner la période" className={classNames({ 'p-invalid': submitted && !(evaluationPeriode.periode.id) })} />
                </div>
                <div className='col-12'>
                  <label className="mx-3" htmlFor="niveau"><b>Niveau</b></label>
                  <Dropdown id="niveau" value={evaluationPeriode.niveau.id} onChange={(e) => onSelectChange(e, 'niveau')} options={niveaux}
                    required optionValue="id" optionLabel="libelle" placeholder="Sélectionner le niveau" className={classNames({ 'p-invalid': submitted && !(evaluationPeriode.niveau.id) })} />
                </div>
                <div className='col-12'>
                  <label className="mx-3" htmlFor="type"><b>Type d&apos;&eacute;valuation</b></label>
                  <Dropdown id='type' value={evaluationPeriode.typeEvaluation.id} options={types} onChange={(e) => onSelectChange(e, 'typeEvaluation')}
                    optionValue='id' optionLabel='libelle' placeholder="Sélectionner le type d'évaluation" className={classNames({ 'p-invalid': submitted && !(evaluationPeriode.typeEvaluation.id) })}></Dropdown>
                </div>
                <div className='col-12'>
                  <label className="mx-3" htmlFor="numero"><b>Num&eacute;ro de l'&eacute;valuation</b></label>
                  <InputNumber id='numero' min={0} value={evaluationPeriode.numero} onChange={(e) => { onNumeroInputChange(e) }}
                    style={{ width: '100px' }} className={classNames({ 'p-invalid': submitted && !(evaluationPeriode.numero) })}></InputNumber>
                </div>
              </div>
              <div className='lg:col-8 md:col-8 sm:col-12'>
                <div className='shadow-3'>
                  <DataTable value={evaluationListSaved} emptyMessage="Aucune donnée" header="Evaluations périodes Enregistrées" size="small" responsiveLayout="scroll" style={{ height: '250px' }} >
                    <Column field="annee.libelle" header="Année"></Column>
                    <Column field="periode.libelle" header="Période"></Column>
                    <Column field="niveau.libelle" header="Niveau"></Column>
                    <Column field="typeEvaluation.libelle" header="Type Eval."></Column>
                    <Column field="numero" header="Numéro"></Column>
                  </DataTable>
                </div>
              </div>
            </div>
          </Dialog>

          <Dialog visible={openDeleteConfirmDialog} style={{ width: '500px' }} header={headerDialog} modal footer={deleteDialogFooter} onHide={hideDeleDialog}>
            <div className="flex align-items-center justify-content-center">
              <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
              <span>Voulez-vous supprimer <b>{toDelete.typeEvaluation.libelle} N&deg; {toDelete.numero}</b> ?</span>
            </div>
          </Dialog>
        </div>
      </div>
    </div>
  )
}
const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(EvaluationPeriode, comparisonFn);