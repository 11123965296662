import React, { useState, useEffect, useRef } from 'react';

import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
// import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { MatiereService } from '../service/MatiereService';
import { NiveauEnseignementService } from '../service/NiveauEnseignementService';
import { CategorieMatiereService } from '../service/CategorieMatiereService';
import { InputNumber } from 'primereact/inputnumber';
import {FormService} from "../service/FormService";

const Form = () => {

    let GetManyRequestDto = {
        auditActeur :'string',
        auditSession:'string',
        page :page
    }


    let projection = {
        noms: []
    }
    let champ = {
        nom:'',
        valeur:''
    }

    let criteres = {
        champ : champ
    }

    let filtre = {
        criteres : criteres
    }

    let page = {
        index : 0 ,
        taille : 10
    }


    let emptyNiveau = {
        id: null,

    }

    let emptyMatiereParent = {
        id: null
    };

    let emptyMatiere = {
        code: '',
        libelle: '',
        niveauEnseignement: emptyNiveau,
        matiereParent: { id: 0 },
        categorie: { id: null },
        numOrdre : 0,
        pec: 1,
        bonus: 0
    };

    let emptyForm = {
        code: '',
        libelle: ''
    };

    let emptyMatieres = [{
        id: 0,
        libelle: 'Selectionner une matière ...',

    }];

    const [matiereDialog, setMatiereDialog] = useState(false);

    const [deleteMatiereDialog, setDeleteMatiereDialog] = useState(false);
    const [deleteMatieresDialog, setDeleteMatieresDialog] = useState(false);

    const [update, setUpdate] = useState(false);
    const [headerTitle, setHeaderTitle] = useState("");

    const [matiere, setMatiere] = useState(emptyMatiere);

    const [selectedMatieres, setSelectedMatieres] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);

    const toast = useRef(null);
    const dt = useRef(null);


    const [matieres, setMatieres] = useState([emptyMatiere]);
    const [niveaux, setNiveaux] = useState(null);
    const [categories, setCategories] = useState(null);

    const [width, setWidth] = useState("700px");

    const [form, setForm] = useState(null);
    const [forms, setForms] = useState(null);

    useEffect(() => {
        const formService = new FormService();
        formService.getList(GetManyRequestDto).then(data => {
           setForms(data)
                console.log(data);
        }).catch((error) => {
            if (error.response) {
                console.log('erreur response- formulaire' + error.response.status);
            } else if (error.request) {
                console.log('erreur request- Formulaire' + error.request);
            } else {
                console.log('Error autres', error.message);
            }
            setForms([]);        }
        );


    }, []);


    const openNew = () => {
        setSubmitted(false);
        // setWidth("700px");
        setUpdate(false);
        setMatiere(emptyMatiere);

        setMatiereDialog(true);
        setHeaderTitle("Créer une nouvelle Matière");
    }

    const hideDialog = () => {
        setSubmitted(false);
        setMatiereDialog(false);
    }


    const hideDeleteClasseDialog = () => {
        setDeleteMatiereDialog(false);
    }


    const hideDeleteClassesDialog = () => {
        setDeleteMatieresDialog(false);
    }

    const saveMatiere = () => {
        setSubmitted(true);
        if (matiere.code != null && matiere.code.trim()!== '' && matiere.libelle != null && matiere.libelle.trim()!== '' && matiere.niveauEnseignement.id != null && matiere.categorie.id != null) {
            // console.log('coucou');
            console.log(matiere);
            let _matieres = [...matieres];
            let _matiere = { ...matiere };
            const matiereService = new MatiereService();


            if (matiere.id) {
                console.log(_matiere);
                const index = reactfindIndexById(matiere.id);

                (matiereService.updateAndDisplay(_matiere)).then(resp => {
                    _matieres[index] = { ...resp };
                    setMatieres(_matieres);
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Matière modifiée', life: 3000 });
                }).catch((error) => {
                    if (error.response) {
                        console.log('erreur response- vie ecole' + error.response.status);
                    } else if (error.request) {
                        console.log('erreur request- vie ecole' + error.request);
                    } else {
                        console.log('Error autres', error.message);
                    }
                    toast.current.show({ severity: 'error', summary: 'erreur', detail: error.message, life: 3000 });
                }
                );

            }
            else {

                (matiereService.saveAndDisplay(_matiere)).then(res => {
                    console.log(res);
                    _matieres.push(res);
                    setMatieres(_matieres);
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Nouvelle matière créee ' + _matiere.code, life: 3000 });
                }).catch((error) => {
                    if (error.response) {
                        console.log('erreur response- vie ecole' + error.response.status);
                    } else if (error.request) {
                        console.log('erreur request- vie ecole' + error.request);
                    } else {
                        console.log('Error autres', error.message);
                    }
                    toast.current.show({ severity: 'error', summary: 'erreur', detail: error.message, life: 3000 });
                })
            }


            //  setSalles(_salles);
            setMatiereDialog(false);
            setMatiere(emptyMatiere);
            setSubmitted(false);
        }
    }


    const editMatiere = (matiere) => {

        setHeaderTitle("Modifier une Matière");
        setWidth('1000px');
        setUpdate(true);

        let _matiere = { ...matiere }
        if (!_matiere.matiereParent)
            _matiere.matiereParent = { id: 0 };
        setMatiere({ ..._matiere });
        setMatiereDialog(true);
    }



    const confirmDeleteClasse = (matiere) => {
        let _matiere = { ...matiere }
        if (!_matiere.matiereParent) {
            _matiere.matiereParent = { id: null };
        }
        setMatiere(_matiere);
        setDeleteMatiereDialog(true);
    }



    const deleteMatiere = () => {
        const matiereService = new MatiereService();

        matiereService.delete(matiere.id).then(() => {
            let _matieres = matieres.filter(val => val.id !== matiere.id);
            setMatieres(_matieres);
            toast.current.show({ severity: 'success', summary: 'Succès', detail: 'Matière supprimée', life: 3000 })

        }).catch((error) => {
            if (error.response) {
                console.log('erreur response- vie ecole' + error.response.status);
            } else if (error.request) {
                console.log('erreur request- vie ecole' + error.request);
            } else {
                console.log('Error autres', error.message);
            }
            toast.current.show({ severity: 'error', summary: 'erreur', detail: error.message, life: 3000 });
        });
        setMatiere(emptyMatiere);
        setDeleteMatiereDialog(false);

    }

    const reactfindIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < matieres.length; i++) {
            if (matieres[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    const confirmDeleteSelected = () => {
        setDeleteMatieresDialog(true);
    }


    const deleteSelectedMatieres = () => {
        let _salles = matieres.filter(val => !selectedMatieres.includes(val));
        const matiereService = new MatiereService();
        selectedMatieres.map((salle) => { return matiereService.delete(salle.id) });
        setMatieres(_salles);
        setDeleteMatieresDialog(false);
        setSelectedMatieres(null);
        toast.current.show({ severity: 'success', summary: 'succès', detail: 'Matieres supprimées', life: 3000 });
    }


    const onInputMatiereChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _matiere = { ...matiere };
        if (name === 'matiereParent') {
            if (val === '')
                _matiere[`${name}`] = { ...{ id: 0 } };
            else
                _matiere[`${name}`].id = (val === '' ? null : val);
        } else
            _matiere[`${name}`] = val;
        setMatiere(_matiere);
        console.log(_matiere);
        // console.log(val);
        // console.log(e);
    }

    const onInputOrdre = (e, name) => {
        const val = (e.value);
        let _matiere = { ...matiere };
            _matiere[`${name}`] = val;
        setMatiere(_matiere);
        console.log(_matiere);
        // console.log(val);
        // console.log(e);
    }

    const onInputPec = (e) => {
        let _matiere = { ...matiere };
        _matiere.pec = e;
        if(e == 1)
        _matiere.bonus = 0;

        setMatiere(_matiere);
        // console.log(matiere);
    }

    const onInputBonus = (e) => {
        let _matiere = { ...matiere };
        _matiere.bonus = e;
        if(e == 1)
        _matiere.pec = 0;

        setMatiere(_matiere);
        // console.log(matiere);
    }


    const onSelectMatiereChange = (e, name) => {

        let _matiere = { ...matiere };

        _matiere[`${name}`].id = e;
        console.log(_matiere);
        // console.log(e);
        setMatiere(_matiere);

    }


    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Nouveau" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    {/* <Button label="Supprimer" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedMatieres || !selectedMatieres.length} /> */}
                    <Button label="Supprimer" icon="pi pi-trash" className="p-button-danger hidden" onClick={confirmDeleteSelected} disabled={true} />
                </div>
            </React.Fragment>
        )
    }



    const codeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Code</span>
                {rowData.code == null ? '' : rowData.donnees.code}
            </>
        );
    }

    const libelleBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Libellé</span>
                {rowData.libelle == null ? '' : rowData.donnees.libelle}
            </>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editMatiere(rowData)} />
                <Button icon="pi pi-trash" disabled={true} className="p-button-rounded p-button-warning mt-2 hidden" onClick={() => confirmDeleteClasse(rowData)} />
            </div>
        );
    }

    const parentBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Parent</span>
                {rowData?.matiereParent?.libelle}
            </>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h3 className="m-0">Liste des matières</h3>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Recherche..." />
            </span>
        </div>
    );


    const salleDialogFooter = (
        <>
            <Button label="Annuler" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Enregistrer" icon="pi pi-check" className="p-button-text" onClick={saveMatiere} />
        </>
    );


    const deleteSalleDialogFooter = (
        <>
            <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={hideDeleteClasseDialog} />
            <Button label="Oui" icon="pi pi-check" className="p-button-text" onClick={deleteMatiere} />
        </>
    );
    const deleteSallesDialogFooter = (
        <>
            <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={hideDeleteClassesDialog} />
            <Button label="oui" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedMatieres} />
        </>
    );

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" right={leftToolbarTemplate} ></Toolbar>

                    <DataTable ref={dt} value={forms}
                        dataKey="donnees.identifiant" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="affichage {first} de {last} sur {totalRecords} matieres"
                        globalFilter={globalFilter} emptyMessage="Aucun enregistrement trouvé." header={header} responsiveLayout="scroll">
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                        <Column field="donnees.code" header="Code" sortable body={codeBodyTemplate}></Column>
                        <Column field="donnees.libelle" header="Libellé" sortable body={libelleBodyTemplate} ></Column>

                        <Column body={actionBodyTemplate} header="Actions" headerStyle={{ width: '14%', minWidth: '9rem' }}></Column>
                    </DataTable>

                    <Dialog visible={matiereDialog} style={{ width: '40%' }} header={headerTitle} modal className="p-fluid" footer={salleDialogFooter} onHide={hideDialog}>
                        <div className="mt-3 grid  p-fluid">
                            <div className="field col-4">
                                <label htmlFor="code"><b>Code</b></label>
                                <InputText value={matiere.code}  onChange={(e) => onInputMatiereChange(e, 'code')} id="code" required autoFocus style={{ maxwidth: '250px' }} className={classNames({ 'p-invalid': submitted && !matiere.code })} />
                                {submitted && !matiere.code && <small className="p-invalid">Le code est requis.</small>}
                            </div>
                            <div className="field col-8">
                                <label htmlFor="libelle"><b>Libellé</b></label>
                                <InputText id="libelle" value={matiere.libelle} onChange={(e) => onInputMatiereChange(e, 'libelle')} required className={classNames({ 'p-invalid': submitted && !matiere.libelle })} />
                                {submitted && !matiere.libelle && <small className="p-invalid">Le libellé est requis.</small>}
                            </div>

                            <div className="field col-12">
                                <label htmlFor="niveau"><b>Niveau Enseignement</b></label>
                                <Dropdown id="niveau" value={matiere.niveauEnseignement.id} disabled={update} onChange={(e) => onSelectMatiereChange(e.value, 'niveauEnseignement')} options={niveaux} optionValue="id" optionLabel="libelle" placeholder="Selectionner le niveau" className={classNames({ 'p-invalid': submitted && !matiere.niveauEnseignement.id })} />
                                {submitted && !matiere.niveauEnseignement.id && <small className="p-invalid">Le niveau est requis.</small>}
                                {/* <MultiSelect id='niveau' value={matiere.niveauEnseignement.id} options={niveaux} onChange={(e) => onSelectMatiereChange(e.value, 'niveauEnseignement')} optionValue="id" optionLabel="libelle" placeholder="Selectioner un niveau" display="chip" /> */}
                            </div>

                            <div className="field col-12">
                                <label htmlFor="cat"><b>Catégorie Matière</b></label>
                                <Dropdown id="cat" value={matiere.categorie.id} onChange={(e) => onSelectMatiereChange(e.value, 'categorie')} options={categories} optionValue="id" optionLabel="libelle" placeholder="Selectionner la catégorie" className={classNames({ 'p-invalid': submitted && !matiere.categorie.id })} />
                                {submitted && !matiere.categorie.id && <small className="p-invalid">La catégorie est requise.</small>}
                            </div>

                            <div className="field col-12">
                                <label htmlFor="parent"><b>Matière parent</b></label>
                                <Dropdown id="parent" value={matiere.matiereParent.id} onChange={(e) => onInputMatiereChange(e, 'matiereParent')} options={matieres} optionValue="id" optionLabel="libelle" placeholder="Selectionner la matière parent" />
                            </div>

                            <div className="field col-12">
                                <label htmlFor="ordre"><b>Numero d'ordre</b></label>
                                <InputNumber value={matiere.numOrdre} onValueChange={(e) => onInputOrdre(e, 'numOrdre')} id="ordre" min={0} style={{ width: '100px' }} className="m-2" ></InputNumber>

                            </div>

                            <div className="field col-offset-7">
                                <InputSwitch name='pec' className='mr-2' tooltip='Activer ou désactiver pour prendre ou non en compte la matière dans le calcul des moyennes générales' trueValue={1} falseValue={0} checked={matiere.pec} onChange={(e) => { onInputPec(e.value) }} />
                                <label htmlFor="pec"><b>P.E.C<span className='ml-1 font-italic text-xs text-500'>* Prise En Compte dans le calcul des moyennes génerales</span></b></label>
                            </div>
                            <div className="field col-offset-7">
                                <InputSwitch name='bonus' className='mr-2' tooltip='Prendre en compte en tant que matière bonus' trueValue={1} falseValue={0} checked={matiere.bonus} onChange={(e) => { onInputBonus(e.value) }}/>
                                <div className='ml-1 pb-3 font-italic text-700' htmlFor="bonus"><b>Bonus?</b></div>
                            </div>
                        </div>

                    </Dialog>

                    <Dialog visible={deleteMatiereDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteSalleDialogFooter} onHide={hideDeleteClasseDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {matiere && <span>Etes vous sûr de vouloir Supprimer la matière <b>{matiere.code} - {matiere.libelle}</b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteMatieresDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteSallesDialogFooter} onHide={hideDeleteClassesDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {matiere && <span>Êtes vous sûr de vouloir supprimer les éléments sélectionnés?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Form, comparisonFn);
