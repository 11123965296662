import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';

import { InputText } from 'primereact/inputtext';
import { PointageService } from '../service/PointageService';


const PointageBadge = () => {

    const [globalFilter, setGlobalFilter] = useState(null);

    const toast = useRef(null);
    const dt = useRef(null);

    const [pointages, setPointages] = useState(null);

    useEffect(() => {
        const pointageService = new PointageService();
                
        pointageService.getList().then(res => setPointages(res));
    }, []);


    const dateBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Date</span>
                {rowData.datePointage}
            </>
        );
    }

    const carteBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Carte</span>
                {rowData.carte == null ? '' : rowData.carte.id}
            </>
        );
    }

    const pointeuseBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Pointeuse</span>
                {rowData.pointeuse == null ? '' : rowData.pointeuse.libelle}
            </>
        );
    }

    const obsBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Observations</span>
                {rowData.observation }
            </>
        );
    }

 
    const classeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Classe</span>
                {rowData.classe != null ? rowData.classe.libelle : ''} 
            </>
        );
    }


    const nomBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Matière</span>
                {rowData.eleve != null ? rowData.eleve.nom+' '+ rowData.eleve.prenom  : ''}
            </>
        );
    }
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                {/* <Button icon="pi pi-book" tooltip='Liste d appel' className="p-button-rounded p-button-success mr-2" /> */}
            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h3 className="m-0">Pointages : Liste des Pointages Badgeuses</h3>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Recherche..." />
            </span>
        </div>
    );

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" ></Toolbar>
                    <DataTable ref={dt} value={pointages}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="affichage {first} de {last} sur {totalRecords} classes"
                        globalFilter={globalFilter} emptyMessage="Aucun enregistrement trouvé." header={header} responsiveLayout="scroll">
                        <Column field="date" header="Date" sortable body={dateBodyTemplate} headerStyle={{ width: '10%', minWidth: '6rem' }}></Column>
                        <Column field="carte" header="Carte ID" sortable body={carteBodyTemplate} headerStyle={{ width: '14%', minWidth: '8rem' }}></Column>
                        <Column field="nom" header="Nom" body={nomBodyTemplate} sortable headerStyle={{ width: '14%', minWidth: '7rem' }}></Column>
                        <Column field="classe" header="Classe" body={classeBodyTemplate} headerStyle={{ width: '14%', minWidth: '7rem' }}></Column>
                        <Column field="pointeuse" header="Pointeuse" sortable body={pointeuseBodyTemplate} headerStyle={{ width: '14%', minWidth: '5rem' }}></Column>
                        <Column field="observations" header="Observations" sortable body={obsBodyTemplate} headerStyle={{ width: '14%', minWidth: '5rem' }}></Column>
                        <Column body={actionBodyTemplate} header="Actions" headerStyle={{ width: '14%', minWidth: '9rem' }}></Column>
                    </DataTable>
                    
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(PointageBadge, comparisonFn);