import axios from 'axios';
import { BaseService } from './BaseService';
const constants = {
    host: 'http://localhost:8889/gestion-ecole-api',    
  };
export class BrancheService extends BaseService {

    async getList(){       
        return axios.get(this.getHost()+'branche/list').then(res => res.data);
    }

    async getByNiveauEnseigment(ecoleId){        
        return axios.get(this.getHost()+'branche/get-by-niveau-enseignement?ecole='+ecoleId).then(res => res.data);
    }

    async getByNiveauEnseigmentProjection(niveauId){        
        return axios.get(this.getHost()+'branche/get-by-niveau-enseignement-projection?niveau='+niveauId).then(res => res.data);
    }
}