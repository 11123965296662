import React, { useState, useEffect, useRef } from 'react';

import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';

import { FilterMatchMode, FilterOperator } from 'primereact/api';

import moment from "moment";
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';

import {Dropdown} from "primereact/dropdown";
import {SouscriptionService} from "../../service/operations/SouscriptionService";
import {useHistory} from "react-router-dom";


const EspacePersonnel = () => {
    const form = useRef();
    let history = useHistory();

    let emptyDomaineFormation = {
        domaine_formationid :null ,
        domaine_formation_code :'',
        domaine_formation_libelle:''
    };

    let sendEmailEmpty = {
        subject :'Bonjour SOUM',
        user_name :'SOUMSMITH1',
        user_email:'soumsmith1@gmail.com',
        message:'Juste un text'
    };

    let emptySexe = {
        sexe_code :'',
        sexe_libelle :''
    };
    let emptyNiveauEtude = {
        niveau_etudeid :null ,
        niveau_etude_code :'',
        niveau_etude_libelle:''
    };
    let emptyTypeAutorisation = {
        idtype_autorisationid :null,
        type_autorisation_code :'' ,
        type_autorisation_libelle :''
    };
    let emptyAnneeExperience = {
        nbreAnnee_code :0 ,
        nbreAnnee_libelle :''
    };
    let emptyEtatInscription={
        etat_code:'',
        etat_libelle:''
    } ;
    let souscriptionValidaEmpty= {
        idsouscrip:null ,
        statuts: '',
        messageRefus:''
    } ;
    let emptyfonction = {
        fonctionid :null ,
        fonctioncode :'',
        fonctionlibelle:''
    } ;
    let candidatObject = null;
    const [niveauEtude, setNiveauEtude] = useState(emptyNiveauEtude);
    const [domaineFormation, setDomaineFormation] = useState(emptyDomaineFormation);
    const [mfonction, setmfonction] = useState(emptyfonction);
    
    const [domaineFormations, setDomaineFormations] = useState([]);
    const [domaineLibelle, setDomaineLibelle] = useState('');
    const defaultValues2 = {
        sous_attent_personnid: null,
        sous_attent_personn_nom: '',
        sous_attent_personn_prenom: '',
        sous_attent_personn_email: '',
        sous_attent_personn_sexe: '',
        sous_attent_personn_diplome_recent:'',
        sous_attent_personn_date_naissance: null,
        sous_attent_personn_nbre_annee_experience:null,
        sous_attent_personn_lien_cv:'',
        niveau_etude:niveauEtude,
        sous_attent_personncode:'',
        domaine_formation:domaineFormation,
        fonction:mfonction,
        sous_attent_personn_login:'',
        sous_attent_personn_password:'',
        sous_attent_personn_donnee: null,
        sous_attent_personn_contact :'',
        sous_attent_personn_lien_piece:'',
        sous_attent_personn_statut:'',
        sous_attent_personn_lien_autorisation :''
    }

    const columns = [
        {field: 'inscriptions_status', header: 'Statut Inscription'},
        {field: 'inscriptionsidEleve', header: 'N°'},
        {field: 'nomEleve', header: 'Nom'},
        {field: 'prenomEleve', header: 'Prenom'},
        {field: 'matriculeEleve', header: 'Matricule'},
        {field: 'inscriptions_type', header: 'Type Inscription'}
    ];
    const [selectedColumns, setSelectedColumns] = useState(columns);

    const [filters2, setFilters2] = useState({
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'sous_attent_personnid':{ value: null, matchMode: FilterMatchMode.STARTS_WITH },
        'sous_attent_personn_nom':{ value: null, matchMode: FilterMatchMode.CONTAINS },
        'sous_attent_personn_prenom':{ value: null, matchMode: FilterMatchMode.CONTAINS },
        'sous_attent_personn_nbre_annee_experience':  { value: null, matchMode: FilterMatchMode.CONTAINS } ,
        'domaine_formation.domaine_formation_libelle':  { value: null, matchMode: FilterMatchMode.CONTAINS } ,
        'sous_attent_personn_email':  { value: null, matchMode: FilterMatchMode.CONTAINS } ,
        'fonction.fonctionlibelle':  { value: null, matchMode: FilterMatchMode.CONTAINS }
    });


    const [date_naissance, setdate_naissance] = useState();
    const [isLoggedIn, setIsLoggedIn] = useState(sessionStorage.getItem('isLoggedIn'));

    const [loadPage, setloadPage] = useState(sessionStorage.getItem('refresh'));
    const [diplomeRecent, setdiplomeRecent] = useState('');
    const [nbre_annee_experience, setnbre_annee_experience] = useState(emptyAnneeExperience);

    const [sexe, setSexe] = useState(emptySexe);
    const [retourApi, setretourApi]  = useState(null);
   
    
    const [niveauLibelle, setNiveauLibelle] = useState('');
    const [niveauEtudes, setNiveauEtudes]= useState([]);
    const [typeAutorisation, settypeAutorisation] = useState(emptyTypeAutorisation);
    const [typeAutorisations, settypeAutorisations] = useState([]);
    const [motifRefu, setmotifRefu] = useState('');
    const [inscriptionAttente, setinscriptionAttente] = useState((defaultValues2));

    const [email, setemail] = useState('');
    const [nom, setnom] = useState('');
    const [prenom, setprenom] = useState('');
     const [contact, setcontact] = useState('');

    

    const [globalFilterValue2, setGlobalFilterValue2] = useState('');

    const [productDialog, setProductDialog] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [FileDialog, setFileDialog] = useState(false);
    const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);

    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [fonction, setFonction] = useState(emptyfonction);
    const [fonctions, setFonctions] = useState([]);
    const toast = useRef(null);
    const dt = useRef(null);
    const [file0, setFile0] = useState('');
    const [file1, setFile1] = useState('');
    const [files, setFiles] = useState([]);

    const [modif, setmodif] = useState(1);

    const onGlobalFilterChange2 = (e) => {
        const value = e.target.value;
        let _filters2 = { ...filters2 };
        _filters2['global'].value = value;

        setFilters2(_filters2);
        setGlobalFilterValue2(value);
    }

    useEffect(() => {
        history.push("/home/candidat/index");
        console.log("/home/candidat/index")
    }, [history]);

    useEffect(() => {
        history.push("/home/candidat/index");
        console.log("/home/candidat/index")
    }, []);

/*    useEffect(() => {
        handleLogin();

    }, [isLoggedIn]);

    const handleLogin = () => {
        if (isLoggedIn) {
            window.location.reload()
            setIsLoggedIn(sessionStorage.setItem('isLoggedIn',false)) ;
        }
    };*/



    useEffect(() => {
        const  domainServ= new SouscriptionService() ;
               domainServ.listDomaineFormation().then(data => setDomaineFormations(data));
               domainServ.listNiveau_etude().then(data => setNiveauEtudes(data));
               domainServ.listTypeAutorisation().then(data => settypeAutorisations(data));
               domainServ.listfonction("fondateur").then(data => setFonctions(data));

           }, []);



   useEffect(() => {
        const  domainServ= new SouscriptionService() ;
       // console.log("modif--- "+modif)
        if (modif==2){
            domainServ.creerSoucriptProfesseur(inscriptionAttente)
                .then(data => {
                   // console.log(data)
                    setretourApi(data)
                    toast.current.show({severity:'info', summary: 'Vie-ecoles', detail:'Modifier avec succès!', life: 3000});
                    //setShowMessage(true)

                });

        }

    }, [modif]);



    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
    }

    const hideFileDialog = () => {
         setFileDialog(false);
    }



    const viewFiles = () => {
        setFileDialog(true);
    }



    const InscriptionTypeOperation2 = [
        'PREINSCRIPTION', 'INSCRIPTION'
    ];

    const InscriptionStatut = [
        'EN ATTENTE', 'VALIDEE'
    ];

    const InscriptionTypeOperation = [
        {inscripStatuCode: 'INSCRIPTION', inscripStatulibelle: 'INSCRIPTION'},
        {inscripStatuCode: 'PREINSCRIPTION', inscripStatulibelle: 'PREINSCRIPTION'}
    ];


    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Nouveau" icon="pi pi-plus" className="p-button-success mr-2" onClick={viewFiles} />
                </div>
            </React.Fragment>
        )
    }






    const dialogFooter = <div className="flex justify-content-center"><Button label="OK" className="p-button-text" autoFocus onClick={() => setShowMessage(false)} /></div>;



    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Liste des élèves</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Rechercher..." />
            </span>
        </div>
    );
    const typeItemTemplate = (option) => {
        return <span className={`customer-badge status-${option}`}>{option}</span>;
    }

    const statusItemTemplate = (option) => {
        return <span className={`customer-badge status-${option}`}>{option}</span>;
    }
    const typeOperationFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={InscriptionTypeOperation2} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={typeItemTemplate} placeholder="Sélectionnez le type" className="p-column-filter" showClear />;
    }

    const statutFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={InscriptionStatut} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={statusItemTemplate} placeholder="Sélectionnez le Statut" className="p-column-filter" showClear />;
    }
    const onSubmitFile = () => {
        toast.current.show({severity:'error', summary: 'Vie-ecoles: Messages d"erreur', detail:'Veuillez saisir les champs obligatoires', life: 3000});

        // showError();

        setSubmitted(true);
        console.log("files "+files) ;
        console.log("Longueur Fichier "+files.length) ;
        const data = new FormData();

        if (email.trim() && nom.trim() && prenom.trim()&& domaineFormation.domaine_formation_code.trim() && niveauEtude.niveau_etude_code.trim() &&
            sexe.sexe_code.trim()  && contact.trim() && nbre_annee_experience.nbreAnnee_code && date_naissance && fonction.fonctioncode.trim()&& file0 && file1 ) {

            for(let i = 0; i < files.length; i++) {
                data.append('file', files[i]);
            }

            const  domainServ= new SouscriptionService() ;
            domainServ.chargerFichiers(data)
                .then((response) => {

                    saveSouscr();

                })
                .catch((e) => {
                    // toast.error('Upload Error')
                })


        } else {
            toast.current.show({severity:'error', summary: 'Vie-ecoles: Messages d"erreur', detail:'Veuillez saisir les champs obligatoires', life: 3000});

            // showError();
        }

    };
    const productDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={onSubmitFile} />
        </>
    );
    const onLoadFile0Change = (e) => {
        setFile0(e.target.files);
        console.log(e.target.files) ;
        setFiles(current => [...current, e.target.files[0]]) ;


    };
    const onLoadFile1Change = (e) => {
        setFile1(e.target.files);
        setFiles(current => [...current, e.target.files[0]]) ;


    };

    const onLoadFile2Change = (e) => {
        console.log(e.target.files) ;
        setFiles(current => [...current, e.target.files[0]]) ;
    };
    
    const FileDialogFooter = (
        <>
           <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={hideFileDialog} />
        </>
    );
    const onSexeChange = (e) => {
        setSexe(e.value);
    }

    useEffect(() => {
        if(sessionStorage.getItem('connectcandidat')==='ok'){
           const souscrService = new SouscriptionService();
            souscrService.infosProfil(sessionStorage.getItem('CandidatId'))
                .then(data => {
                  console.log(["MON DATA ",data])
                    setDomaineLibelle(data.domaine_formation.domaine_formation_libelle);
                    setNiveauLibelle(data.niveau_etude.niveau_etude_libelle) ;
                    setinscriptionAttente(data) })

        }

    }, []);


    const renderHeader2 = () => {
        return (
            <div className="flex justify-content-end">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue2} onChange={onGlobalFilterChange2} placeholder="Mots clés..." />
                </span>
            </div>
        )
    }
    const header2 = renderHeader2();


     const saveSouscr = () => {
        const  domainServ= new SouscriptionService() ;
        inscriptionAttente.identifiantdomaine_formation = domaineFormation.domaine_formationid ;
    inscriptionAttente.fonctionidentifiant = fonction.fonctionid ;
    inscriptionAttente.type_autorisation_idtype_autorisationid = typeAutorisation.idtype_autorisationid ;
        inscriptionAttente.niveau_etudeIdentifiant = niveauEtude.niveau_etudeid ;

        inscriptionAttente.sous_attent_personn_email= email ;
        inscriptionAttente.sous_attent_personn_nom = nom ;
        inscriptionAttente.sous_attent_personn_prenom = prenom ;
        inscriptionAttente.sous_attent_personn_sexe = sexe.sexe_code ;
        inscriptionAttente.sous_attent_personn_nbre_annee_experience = nbre_annee_experience.nbreAnnee_code ;
        inscriptionAttente.sous_attent_personn_date_naissance = moment(date_naissance).utc().format('YYYY-MM-DD')
        inscriptionAttente.sous_attent_personn_diplome_recent = diplomeRecent ;
        if (files.length===3){
            inscriptionAttente.sous_attent_personn_lien_cv=files[0].name ;
            inscriptionAttente.sous_attent_personn_lien_autorisation =files[1].name ;
            inscriptionAttente.sous_attent_personn_lien_piece =files[2].name ;
        } else {
            inscriptionAttente.sous_attent_personn_lien_cv=files[0].name ;
            inscriptionAttente.sous_attent_personn_lien_autorisation =files[1].name ;
        }
        setSubmitted(false);
        setProductDialog(false);
        inscriptionAttente.sous_attent_personn_contact= contact ;
        setmodif(2);

}

    return (
        <div className="grid p-fluid">
            <div className="col-12 md:col-8">
                <div className="card">
                    <Toast ref={toast} />

                    <div className="col-12">
                        <div className="card">
                            <h5>Accueil</h5>
                            <div className="field grid">
                                <label htmlFor="nom" className="col-12 mb-2 md:col-2 md:mb-0">Nom:</label>
                                <div className="col-12 md:col-10">
                                    <InputText id="nom" type="text" value={inscriptionAttente.sous_attent_personn_nom == null ? '' :inscriptionAttente.sous_attent_personn_nom}  disabled/>
                                </div>
                            </div>
                            <div className="field grid">
                                <label htmlFor="prenom" className="col-12 mb-2 md:col-2 md:mb-0">Prénom:</label>
                                <div className="col-12 md:col-10">
                                    <InputText id="prenom" type="text" value={inscriptionAttente.sous_attent_personn_prenom == null ? '' :inscriptionAttente.sous_attent_personn_prenom}  disabled/>
                                </div>
                            </div>
                            <div className="field grid">
                                <label htmlFor="email" className="col-12 mb-2 md:col-2 md:mb-0">Email:</label>
                                <div className="col-12 md:col-10">
                                    <InputText id="email" type="text" value={inscriptionAttente.sous_attent_personn_email == null ? '' :inscriptionAttente.sous_attent_personn_email}   disabled/>
                                </div>
                            </div>
                            <div className="field grid">
                                <label htmlFor="sexe" className="col-12 mb-2 md:col-2 md:mb-0">Sexe:</label>
                                <div className="col-12 md:col-10">
                                    <InputText id="sexe" type="text" value={inscriptionAttente.sous_attent_personn_sexe == null ? '' :inscriptionAttente.sous_attent_personn_sexe}   disabled/>
                                </div>
                            </div>
                            <div className="field grid">
                                <label htmlFor="dateNaissance" className="col-12 mb-2 md:col-2 md:mb-0">Date de naissance:</label>
                                <div className="col-12 md:col-10">
                                    <InputText id="dateNaissance" type="text" value={inscriptionAttente.sous_attent_personn_date_naissance == null ? '' :inscriptionAttente.sous_attent_personn_date_naissance}   disabled/>
                                </div>
                            </div>
                            <div className="field grid">
                                <label htmlFor="diplomeRecent" className="col-12 mb-2 md:col-2 md:mb-0">Diplôme récent:</label>
                                <div className="col-12 md:col-10">
                                    <InputText id="diplomeRecent" type="text" value={inscriptionAttente.sous_attent_personn_diplome_recent===""? "NEANT":inscriptionAttente.sous_attent_personn_diplome_recent}  disabled/>
                                </div>
                            </div>
                            <div className="field grid">
                                <label htmlFor="nbreAnneExperience" className="col-12 mb-2 md:col-2 md:mb-0">Nombre d'année d'expérience:</label>
                                <div className="col-12 md:col-10">
                                    <InputText id="nbreAnneExperience" type="text" value={inscriptionAttente.sous_attent_personn_nbre_annee_experience == null ? '' :inscriptionAttente.sous_attent_personn_nbre_annee_experience}  disabled/>
                                </div>
                            </div>

                            <div className="field grid">
                                <label htmlFor="Contact" className="col-12 mb-2 md:col-2 md:mb-0">Contact:</label>
                                <div className="col-12 md:col-10">
                                    <InputText id="Contact" type="text" disabled value={inscriptionAttente.sous_attent_personn_contact == null ? '' :inscriptionAttente.sous_attent_personn_contact}  />
                                </div>
                            </div>

                        </div>
                    </div>

                    <Dialog visible={showMessage} onHide={() => setShowMessage(false)} position="top" footer={dialogFooter} showHeader={false} breakpoints={{ '960px': '80vw' }} style={{ width: '30vw' }}>
                        <div className="flex justify-content-center flex-column pt-6 px-3">
                            <i className="pi pi-check-circle" style={{ fontSize: '5rem', color: 'var(--green-500)' }}></i>
                            <h5>{"RECRUTEMENT DU PERSONNEL"}</h5>
                            <p style={{ lineHeight: 1.5, textIndent: '1rem' }}>
                               {retourApi}
                            </p>
                        </div>
                    </Dialog>

                </div>
            </div>
            <div className="col-12 md:col-4">

                <div class="card">
                    <p class="underline">STATUT COMPTE PERSONNEL</p>
                        <div class="shadow-8 m-3 text-orange-500 w-12rem h-6rem surface-overlay border-round border-1 border-gray-500 font-bold m-2 flex align-items-center justify-content-center">
                            <span>{inscriptionAttente.sous_attent_personn_statut}  </span>
                        </div>
                </div>

                <div class="card">
                   <div className="field grid">
                        <label htmlFor="domaine" className="col-12 mb-2 md:col-2 md:mb-0">Domaine de formation:</label>
                        <div className="col-12 md:col-10">
                            <InputText id="domaine" type="text" value={domaineLibelle == null ? '' :domaineLibelle}  disabled/>
                        </div>
                    </div>

                    <div className="field grid">
                        <label htmlFor="Niveau" className="col-12 mb-2 md:col-2 md:mb-0">Niveau d'étude:</label>
                        <div className="col-12 md:col-10">
                            <InputText id="Niveau" type="text" value={niveauLibelle == null ? '' :niveauLibelle }  disabled/>
                        </div>
                    </div>


                </div>


            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

//export default React.memo(EspacePersonnel, comparisonFn);
export default EspacePersonnel;
