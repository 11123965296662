import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import {Route, useLocation} from 'react-router-dom';

import {LoginAppTopbar} from "./LoginAppTopbar";
import { AppFooter } from './AppFooter';

import { AppConfig } from './AppConfig';



import PrimeReact from 'primereact/api';
import { Tooltip } from 'primereact/tooltip';

import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'prismjs/themes/prism-coy.css';
import './assets/demo/flags/flags.css';
import './assets/demo/Demos.scss';
import './assets/layout/layout.scss';
import './App.scss';


import {AppTopbar} from "./AppTopbar";
import {AppMenu} from "./AppMenu";
import {CSSTransition} from "react-transition-group";

// menu candidat
import EspacePersonnel from "./components/login/EspacePersonnel";
import ModifierMotDepassPersonnel from "./components/login/ModifierMotDepassPersonnel";
import DocumentsPersonnel from "./components/login/DocumentsPersonnel";

import BoiteReceptionCandidat from "./components/login/BoiteReceptionCandidat";
import BoiteEnvoieFondateur from "./components/login/BoiteEnvoieFondateur";

import ConnexionCandidat from "./components/login/ConnexionCandidat";
const App3 = () => {
    const [layoutMode, setLayoutMode] = useState('static');
    const [layoutColorMode, setLayoutColorMode] = useState('light')
    const [inputStyle, setInputStyle] = useState('outlined');
    const [ripple, setRipple] = useState(true);

    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
    const copyTooltipRef = useRef();
    const location = useLocation();
   

    PrimeReact.ripple = true;

    let menuClick = false;
    let mobileTopbarMenuClick = false;




    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
    }, [mobileMenuActive]);

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);




    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    }

    const onRipple = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value)
    }

    const onLayoutModeChange = (mode) => {
        setLayoutMode(mode)
    }

    const onColorModeChange = (mode) => {
        setLayoutColorMode(mode)
    }

    const onWrapperClick = (event) => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }

        if (!mobileTopbarMenuClick) {
            setMobileTopbarMenuActive(false);
        }

        mobileTopbarMenuClick = false;
        menuClick = false;
    }

    const onToggleMenuClick = (event) => {
        menuClick = true;

        if (isDesktop()) {
            if (layoutMode === 'overlay') {
                if (mobileMenuActive === true) {
                    setOverlayMenuActive(true);
                }

                setOverlayMenuActive((prevState) => !prevState);
                setMobileMenuActive(false);
            }
            else if (layoutMode === 'static') {
                setStaticMenuInactive((prevState) => !prevState);
            }
        }
        else {
            setMobileMenuActive((prevState) => !prevState);
        }

        event.preventDefault();
    }

    const onSidebarClick = () => {
        menuClick = true;
    }

    const onMobileTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        setMobileTopbarMenuActive((prevState) => !prevState);
        event.preventDefault();
    }

    const onMobileSubTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        event.preventDefault();
    }

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
    }
    const isDesktop = () => {
        return window.innerWidth >= 992;
    }

    const menu = [

        {
            label: 'ESPACE CANDIDAT', icon: 'pi pi-fw pi-sitemap',
            items: [
                { label: "Mon profilXXX", icon: "pi pi-fw pi-check-square", to: "/home/candidat/monProfil" },
                { label: "Login et mot de passe", icon: "pi pi-fw pi-check-square", to: "/home/candidat/ModifierMotdePasse" },
                { label: "Mes documents", icon: "pi pi-fw pi-check-square", to: "/home/candidat/MesDocuments" },
                {
                    label: 'Ma Messagerie', icon: 'pi pi-fw pi-bookmark',
                    items: [
                        {label: 'Messages envoyés', icon: 'pi pi-fw pi-send', to: '/home/candidat/messageEnvoyes'},
                        { label: 'Messages réçus', icon: 'pi pi-fw pi-inbox', to: '/home/candidat/messagerecus'},

                    ]
                }

            ]
        }
    ];

    const addClass = (element, className) => {
        if (element.classList)
            element.classList.add(className);
        else
            element.className += ' ' + className;
    }

    const removeClass = (element, className) => {
        if (element.classList)
            element.classList.remove(className);
        else
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }

    const wrapperClass = classNames('layout-wrapper', {
        'layout-overlay': layoutMode === 'overlay',
        'layout-static': layoutMode === 'static',
        'layout-static-sidebar-inactive': staticMenuInactive && layoutMode === 'static',
        'layout-overlay-sidebar-active': overlayMenuActive && layoutMode === 'overlay',
        'layout-mobile-sidebar-active': mobileMenuActive,
        'p-input-filled': inputStyle === 'filled',
        'p-ripple-disabled': ripple === false,
        'layout-theme-light': layoutColorMode === 'light'
    });
    if(sessionStorage.getItem('connect')==='1'){
        return (
            <div className={wrapperClass} onClick={onWrapperClick}>
                <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

                <AppTopbar onToggleMenuClick={onToggleMenuClick} layoutColorMode={layoutColorMode}
                           mobileTopbarMenuActive={mobileTopbarMenuActive} onMobileTopbarMenuClick={onMobileTopbarMenuClick} onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick} />

                <div className="layout-sidebar" onClick={onSidebarClick}>
                    <AppMenu model={menu} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />
                </div>

                <div className="layout-main-container">
                    <div className="layout-main">
                        <Route path="/home/candidat/index" exact render={() => <EspacePersonnel colorMode={layoutColorMode} location={location} />} />

                        <Route path="/home/candidat/monProfil" component={EspacePersonnel} />
                        <Route path="/home/candidat/ModifierMotdePasse" component={ModifierMotDepassPersonnel} />
                        <Route path="/home/candidat/MesDocuments" component={DocumentsPersonnel} />
                        <Route path="/home/candidat/messageEnvoyes" component={BoiteEnvoieFondateur} />
                        <Route path="/home/candidat/messageRecus" component={BoiteReceptionCandidat} />

                    </div>

                    {/*<AppFooter layoutColorMode={layoutColorMode} />*/}
                </div>

                <AppConfig rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange}
                           layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange} />

                <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
                    <div className="layout-mask p-component-overlay"></div>
                </CSSTransition>

            </div>

        );

    } else {
        return (
            <div >

                <LoginAppTopbar onToggleMenuClick={onToggleMenuClick} layoutColorMode={layoutColorMode}
                                mobileTopbarMenuActive={mobileTopbarMenuActive} onMobileTopbarMenuClick={onMobileTopbarMenuClick} onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick} />



                <div className="layout-main-container">
                    <div className="layout-main">

                        <Route path="/home/candidat/connexion" exact render={() => <ConnexionCandidat  />} />

                    </div>

                    <AppFooter layoutColorMode={layoutColorMode} />
                </div>

                <AppConfig rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange}
                           layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange} />


            </div>
        )

    }






}

export default App3;
