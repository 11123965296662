import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Toolbar } from 'primereact/toolbar';

import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import {EleveService} from '../../service/operations/EleveService';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';

import {Dropdown} from "primereact/dropdown";
import {SouscriptionService} from "../../service/operations/SouscriptionService";
import EmptyPage from "../../pages/EmptyPage";
import {Route} from "react-router-dom";
import { Tooltip } from 'primereact/tooltip';
import { InputTextarea } from 'primereact/inputtextarea';
import emailjs from '@emailjs/browser';
import {ConnexionService} from "../../service/operations/ConnexionService";

const InfosConnexPersonnelEcole = () => {
    const form = useRef();

    let emptyDomaineFormation = {
        domaine_formationid :null ,
        domaine_formation_code :'',
        domaine_formation_libelle:''
    };

    let sendEmailEmpty = {
        subject :'Bonjour SOUM',
        user_name :'SOUMSMITH1',
        user_email:'soumsmith1@gmail.com',
        message:'Juste un text'
    };

    let emptySexe = {
        sexe_code :'',
        sexe_libelle :''
    };
    let emptyNiveauEtude = {
        niveau_etudeid :null ,
        niveau_etude_code :'',
        niveau_etude_libelle:''
    };
    let emptyAnneeExperience = {
        nbreAnnee_code :0 ,
        nbreAnnee_libelle :''
    };
    let emptyEtatInscription={
        etat_code:'',
        etat_libelle:''
    } ;
    let souscriptionValidaEmpty= {
        idsouscrip:null ,
        statuts: '',
        messageRefus:''
    } ;
    let emptyEcole = {
        ecoleid: null,
        ecolecode: '',
        ecoleclibelle: ''
    };
    let emptyfonction = {
        fonctionid :null ,
        fonctioncode :'',
        fonctionlibelle:''
    } ;

    const defaultValues2 = {
        nom:'',
        prenom:'',
        ecole:'',
        login:'',
        motPasse:'',
    }

    const columns = [
        {field: 'inscriptions_status', header: 'Statut Inscription'},
        {field: 'inscriptionsidEleve', header: 'N°'},
        {field: 'nomEleve', header: 'Nom'},
        {field: 'prenomEleve', header: 'Prenom'},
        {field: 'matriculeEleve', header: 'Matricule'},
        {field: 'inscriptions_type', header: 'Type Inscription'}
    ];
    const [selectedColumns, setSelectedColumns] = useState(columns);

    const [filters2, setFilters2] = useState({
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'nom':{ value: null, matchMode: FilterMatchMode.STARTS_WITH },
        'prenom':{ value: null, matchMode: FilterMatchMode.CONTAINS },
        'ecole':{ value: null, matchMode: FilterMatchMode.CONTAINS },
        'login':  { value: null, matchMode: FilterMatchMode.CONTAINS } ,
        'motPasse':  { value: null, matchMode: FilterMatchMode.CONTAINS } ,

    });
    const [emailObject, setEmailObject] = useState(sendEmailEmpty);
    const [userName, setUserName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userMessage, setUserMessage] = useState('');
    const [idModif, setIdmodif] = useState(null);
    const [statut, setstatut] = useState("VALIDEE");
    const [souscVali, setsouscVali] = useState(souscriptionValidaEmpty);
    const [sexe, setSexe] = useState(emptySexe);
    const [retourApi, setretourApi]  = useState(null);
    const [domaineFormation, setDomaineFormation] = useState(emptyDomaineFormation);
    const [mfonction, setmfonction] = useState(emptyfonction);
    const [domaineFormations, setDomaineFormations] = useState([]);
    const [motifRefu, setmotifRefu] = useState('');
    const [souscriptionAttente, setsouscriptionAttente] = useState((defaultValues2));
    const [souscriptionAttentes, setsouscriptionAttentes] = useState([]);
    const [ecoles, setEcoles] = useState([]);
    const [ecole, setEcole] = useState(emptyEcole);

    const [niveauEtude, setNiveauEtude] = useState(emptyNiveauEtude);
    const [niveauEtudes, setNiveauEtudes]= useState([]);;
    const [globalFilterValue2, setGlobalFilterValue2] = useState('');
    const [typeOperation, setTypeOperation]  = useState(null);
    const [anneeEncours, setanneeEncours]  = useState("2021-2022");
    const [typeOperation1, settypeOperation1]  = useState("INSCRIPTION");
    const [typeOperation2, settypeOperation2]  = useState("PREINSCRIPTION");
    const [tenant, settenant]  = useState(sessionStorage.getItem('EcoleID'));
    const [etatInscription, setetatInscription]  = useState(emptyEtatInscription);
    const [eleves, setEleves]  = useState(null);
    const [filters1, setFilters1] = useState(null);
    const [productDialog, setProductDialog] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [FileDialog, setFileDialog] = useState(false);
    const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
    const [modif, setModif]  = useState(null);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    const [selectedNiveau, setSelectedNiveau] = useState(null);
    const [selectedStatut, setSelectedStatut] = useState(null);
    const [selectedSexe, setSelectedSexe] = useState(null);
    const [selectedInscrStatut, setSelectedInscrStatut] = useState(null);
    const initFilters1 = () => {
        setFilters2({
            'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
            'prenomEleve':{ value: null, matchMode: FilterMatchMode.STARTS_WITH },
            'inscriptions_status':{ value: null, matchMode: FilterMatchMode.STARTS_WITH },
            'inscriptionsidEleve':{ value: null, matchMode: FilterMatchMode.STARTS_WITH },
            'nomEleve':  { value: null, matchMode: FilterMatchMode.STARTS_WITH },
           'matriculeEleve': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            'inscriptions_type': { value: null, matchMode: FilterMatchMode.EQUALS  }
        });
        setGlobalFilterValue2('');
    }
    const onGlobalFilterChange2 = (e) => {
        const value = e.target.value;
        let _filters2 = { ...filters2 };
        _filters2['global'].value = value;

        setFilters2(_filters2);
        setGlobalFilterValue2(value);
    }
    const clearFilter1 = () => {
        initFilters1();
    }
    const onEcoleChange = (e) => {
        setEcole(e.value);
    };

    useEffect(()=>{
        if(modif===1){
            setShowMessage(true);
        }
    },[modif])


    useEffect(() => {
        const connexSer = new ConnexionService();
        connexSer.listEcoleConnecte().then(data => setEcoles(data));

    }, []);

    const formatCurrency = (value) => {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }


    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
    }

    const hideFileDialog = () => {
         setFileDialog(false);
    }

    const hideDeleteProductDialog = () => {
        setDeleteProductDialog(false);
    }

    const hideDeleteProductsDialog = () => {
        setDeleteProductsDialog(false);
    }

    const openFile1 = (fileName) => {
    if(fileName.trim()==="") {
        toast.current.show({severity:'info', summary: 'Vie-ecoles', detail:'Pas de fichier disponible', life: 3000});
    } else {
        const souscrService = new SouscriptionService();
        souscrService.ouvrirFichier(fileName);
    }

    }




    const saveProduct = () => {
        setIdmodif(0);
        setSubmitted(true);

      const souscrService = new SouscriptionService();
        if(etatInscription.etat_libelle.trim()==="VALIDEE" ){
            souscVali.messageRefus= motifRefu ;
            souscVali.statuts = etatInscription.etat_libelle ;
            souscVali.idsouscrip = souscriptionAttente.sous_attent_personnid ;
            souscrService.validerRecruterPersonnel(tenant,souscriptionAttente.sous_attent_personnid)
                .then(data => {
                 console.log(data)
                 setretourApi(data)
                    setModif(1)
                setIdmodif(1)
              });

            setProductDialog(false);
            setetatInscription(emptyEtatInscription);
            setsouscVali(souscriptionValidaEmpty)
            setmotifRefu('') ;
         }

        else  {
          toast.current.show({ severity: 'error', summary: 'Error Message', detail: 'Veuillez traiter la demande ou annuler', life: 3000 });
            //setProductDialog(false);
        }


    }


    const viewFiles = () => {
        setFileDialog(true);
    }

    const EtatInscriptions = [
        {etat_code: '-------', etat_libelle: '------'},
        {etat_code: 'VALIDEE', etat_libelle: 'VALIDEE'}

    ];
    const Sexe = [
        {sexe_code: 'MASCULIN', sexe_libelle: 'MASCULIN'},
        {sexe_code: 'FEMININ', sexe_libelle: 'FEMININ'}

    ];

    const Statuts = [
        {statut_code: 'AFFECTE', statut_libelle: 'AFFECTE'},
        {statut_code: 'NON_AFFECTE', statut_libelle: 'NON AFFECTE'}
    ];

    const InscriptionTypeOperation2 = [
        'PREINSCRIPTION', 'INSCRIPTION'
    ];

    const InscriptionStatut = [
        'EN ATTENTE', 'VALIDEE'
    ];

    const InscriptionTypeOperation = [
        {inscripStatuCode: 'INSCRIPTION', inscripStatulibelle: 'INSCRIPTION'},
        {inscripStatuCode: 'PREINSCRIPTION', inscripStatulibelle: 'PREINSCRIPTION'}
    ];

    const valider = () => {
        const souscrService = new SouscriptionService();
        souscrService.infosListeConnexion(ecole.ecoleid)
            .then(data => {
                console.log(data)
                setsouscriptionAttentes(data)

            });
    }


    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <label htmlFor="codeEcole" className="block text-900 text-xl font-medium mb-2">
                        Ecole
                    </label>
                    <Dropdown id="codeEcole" options={ecoles} value={ecole == null ? '' : ecole} onChange={onEcoleChange} optionLabel="ecoleclibelle" filter showClear filterBy="ecoleclibelle"
                    ></Dropdown>
                    <Button label="Rechercher" className="p-button-info" className="p-button-success mr-2" onClick={valider} />
                </div>
            </React.Fragment>
        )
    }

    const IdsouscriptionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">N°</span>
                {rowData.personnelid}
            </>
        );
    }


    const NomBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">nom</span>
                {rowData.nom}
            </>
        );
    }

    const PreNomBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Prénoms</span>
                {rowData.prenom}
            </>
        );
    }

    const emailBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Email</span>
                {rowData.sous_attent_personn_email}
            </>
        );
    }


    const sexeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Email</span>
                {rowData.sous_attent_personn_sexe}
            </>
        );
    }
    const ecoleBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Ecole</span>
                {rowData.ecole}
            </>
        );
    }
    const diplomeRecentBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Diplôme récent</span>
                {rowData.sous_attent_personn_diplome_recent}
            </>
        );
    }

    const statutEleveBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Statut</span>
                {rowData.inscriptions_statut_eleve}
            </>
        );
    }
    const loginBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Login</span>
                {rowData.login}
            </>
        );
    }

    const motDePasseBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Mot de passe</span>
                {rowData.motPasse}
            </>
        );
    }

    const statutInscriptionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Etat</span>
                {rowData.inscriptions_status}
            </>
        );
    }

    const dateNaissanceBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Date naissance</span>
                {rowData.Date_naissanceEleve}
            </>
        );
    }





    const contactBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Cellulaire</span>
                {rowData.contactEleve}
            </>
        );
    }

    const statutInscripBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Cellulaire</span>
                {rowData.inscriptions_status}
            </>
        )
    }


    const typeInscriptionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Cellulaire</span>
                {rowData.inscriptions_type}
            </>
        )
    }
    const editProduct = (souscrip) => {
        setsouscriptionAttente({ ...souscrip });
        setUserName('GAIN SARL') ;
        setUserEmail('soumsmith1@gmail.com') ;
        setUserMessage('Bonjour Monsieur '+souscrip.sous_attent_personn_nom+' '+souscrip.sous_attent_personn_prenom +'\n'+
            'Veuillez utiliser ces paramètres pour vous connecter à notre application'+'\n');
        setProductDialog(true);
    }
    const dialogFooter = <div className="flex justify-content-center"><Button label="OK" className="p-button-text" autoFocus onClick={() => setShowMessage(false)} /></div>;

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                {/*<Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editProduct(rowData)} />*/}

               </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Liste des élèves</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Rechercher..." />
            </span>
        </div>
    );
    const typeItemTemplate = (option) => {
        return <span className={`customer-badge status-${option}`}>{option}</span>;
    }

    const statusItemTemplate = (option) => {
        return <span className={`customer-badge status-${option}`}>{option}</span>;
    }
    const typeOperationFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={InscriptionTypeOperation2} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={typeItemTemplate} placeholder="Sélectionnez le type" className="p-column-filter" showClear />;
    }

    const statutFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={InscriptionStatut} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={statusItemTemplate} placeholder="Sélectionnez le Statut" className="p-column-filter" showClear />;
    }
    const productDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            {/*<Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />*/}
        </>
    );

    const FileDialogFooter = (
        <>
           <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={hideFileDialog} />
        </>
    );




    const renderHeader2 = () => {
        return (
            <div className="flex justify-content-end">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue2} onChange={onGlobalFilterChange2} placeholder="Mots clés..." />
                </span>
            </div>
        )
    }
    const header2 = renderHeader2();
    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    {<Toolbar className="mb-4" left={leftToolbarTemplate} ></Toolbar>}

                    <div className="card">
                        <h5>Informations de connexion</h5>
                        <p>Liste du personnel par ecole</p>
                        <DataTable value={souscriptionAttentes} paginator className="p-datatable-customers" rows={5}
                                   dataKey="sous_attent_personnid" filters={filters2} filterDisplay="row"  responsiveLayout="scroll"
                                   globalFilterFields={['nom','prenom', 'ecole','login','motPasse']} header={header2} emptyMessage="Pas de souscription  en attente pour ce critère de choix!">
                            <Column header="Nom" filterField="personnelnom" style={{ minWidth: '15rem' }} body={NomBodyTemplate}  />
                            <Column  header="Prénoms" filterField="personnelprenom" body={PreNomBodyTemplate} style={{ minWidth: '18rem' }} />

                            <Column   header="Login" body={loginBodyTemplate} style={{ minWidth: '18rem' }}  />
                            <Column header="Mot de passe" filterField="Mot de passe"  body={motDePasseBodyTemplate}  style={{ minWidth: '18rem' }}/>

                        </DataTable>
                    </div>






                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(InfosConnexPersonnelEcole, comparisonFn);
