import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';

import { Toolbar } from 'primereact/toolbar';

import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import {EleveService} from '../../service/operations/EleveService';
import {PublicationsService} from '../../service/operations/PublicationsService';


import {Dropdown} from "primereact/dropdown";

import {FilterMatchMode} from "primereact/api";
import {Password} from "primereact/password";
import {Calendar} from "primereact/calendar";
import moment from "moment/moment";
import {InputTextarea} from "primereact/inputtextarea";

const CreerPublication = () => {
    let emptyPublication = {
        id:null,
        code :'' ,
        libelle :0,
        date_creation:null,
        ecole_ecoleid :null,
        type_offre_id :null,
        niveau_id :null,
        idNivPub :null,
        experience:null ,
        lieu : '',
        date_limite :null,
        fonction_fonctionid: null,
        statut:''
    };
    let emptyPublicationSelect = {
        id:null,
        code :'' ,
        libelle :0,
        date_creation:null,
        ecole_ecoleid :null,
        libelle_ecole :'',
        libelle_offre :'',
        libelle_niveau :'',
        identifiantPubNiv :null,
        libelle_fonction :'',
        type_offre_id :null,
        niveauId :null,
        idNivPub :null,
        experience:null ,
        lieu : '',
        date_limite :null,
        fonction_fonctionid: null,
        statut:''
    };


    let emptyTypeOffre= {
        id:null,
        code:'',
        libelle:''
    } ;

    let emptyFonction= {
        fonctionid:null,
        fonctioncode:'',
        fonctionlibelle:''
    } ;

    let emptyNiveauEtude= {
        niveau_etudeid:null,
        niveau_etude_code:'',
        niveau_etude_libelle:''
    } ;

    let emptyAnneeScoliare= {
        annee_scolaireid: 1 ,
        annee_scolaire_libelle: '' ,
        annee_scolaire_code: '',
        annee_scolaire_visible:''
    } ;


    const searchInput = React.createRef();
    const [anneeScolaires, setanneeScolaires]  = useState(null);

    const [anneeScolaire, setanneeScolaire]  = useState(emptyAnneeScoliare);

    const [branches, setBranches]  = useState(null);
    const [niveaus, setniveaus]  = useState(null);

    const [publication, setPublication]  = useState(emptyPublication);
    const [publications, setPublications]  = useState(null);
    const [publicationSelect, setPublicationSelect]  = useState(emptyPublicationSelect);
    const [publicationSelects, setPublicationSelects]  = useState(null);
    const [typeOffre, setTypeOffre]  = useState(emptyTypeOffre);
    const [typeOffres, setTypeOffres]  = useState(null);
    const [profil, setProfil]  = useState(emptyTypeOffre);
    const [profils, setProfils]  = useState(null);
    const [niveauEtude, setNiveauEtude]  = useState(emptyNiveauEtude);
    const [niveauEtudes, setNiveauEtudes]  = useState(null);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [vcode, setvcode]  = useState('');
    const [statut, setstatut]  = useState(emptyTypeOffre);
    const [vlibelle, setvlibelle]  = useState('');
    const [tenant, settenant]  = useState(sessionStorage.getItem('EcoleID'));
    const [vtype_offre_id, setvtype_offre_id]  = useState(null);
    const [vniveau_id, setvniveau_id]  = useState(null);
    const [vexperience, setvexperience]  = useState('');
    const [vlieu, setvlieu]  = useState('');
    const [vdate_limite, setvdate_limite]  = useState(null);
    const [vfonction_fonctionid, setvfonction_fonctionid]  = useState(null);
    const [showMessage, setShowMessage] = useState(false);
    const [modif, setModif]  = useState(null);
    const [productDialog, setProductDialog] = useState(false);
    const [productModifDialog, setProductModifDialog] = useState(false);
    const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
    const [idPubl, setidPubl] = useState(null);

    const [retourApi, setretourApi]  = useState(null);

    const [submitted, setSubmitted] = useState(false);
    const [submittedModif, setSubmittedModif] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const [globalFilterValue2, setGlobalFilterValue2] = useState('');
    const [selectedNiveau, setSelectedNiveau] = useState(null);
    const [selectedStatut, setSelectedStatut] = useState(null);

    const [selectedInscrStatut, setSelectedInscrStatut] = useState(null);
    const [filters2, setFilters2] = useState({
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'id':{ value: null, matchMode: FilterMatchMode.CONTAINS },
        'code':{ value: null, matchMode: FilterMatchMode.CONTAINS },
        'libelle':{ value: null, matchMode: FilterMatchMode.CONTAINS },
        'type_offre':  { value: null, matchMode: FilterMatchMode.CONTAINS },
        'libelle_niveau': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'experience': { value: null, matchMode: FilterMatchMode.CONTAINS  },
        'libelle_fonction': { value: null, matchMode: FilterMatchMode.CONTAINS  },
        'lieu': { value: null, matchMode: FilterMatchMode.CONTAINS  } ,
        'date_limite': { value: null, matchMode: FilterMatchMode.CONTAINS  }
    });
    const initFilters1 = () => {
        setFilters2({
            'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
            'eleve_matricule':{ value: null, matchMode: FilterMatchMode.STARTS_WITH },
            'elevenom':{ value: null, matchMode: FilterMatchMode.STARTS_WITH },
            'eleveprenom':{ value: null, matchMode: FilterMatchMode.STARTS_WITH },
            'eleve_sexe':  { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            'elevecellulaire': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            'classelibelle': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            'inscriptions_statut_eleve': { value: null, matchMode: FilterMatchMode.EQUALS  }
        });
        setGlobalFilterValue2('');
    }
    const clearFilter1 = () => {
        initFilters1();
    }

    const statuts = [
        {code: 'OUVERT', libelle: ''},
        {code: 'VALIDEE', libelle: 'VALIDEE'}

    ];

    useEffect(() => {
        const publicationsService = new PublicationsService();
          initFilters1();

        publicationsService.listTypeOffre().then(data => {
            console.log(data)
            setTypeOffres(data)
        });

        publicationsService.listNiveauEtude().then(data => {
            console.log(data)
            setNiveauEtudes(data)
        });


        publicationsService.listPublications(tenant).then(data => {
            console.log(data)
            setPublicationSelects(data)
        });



        publicationsService.listProfils().then(data => {
            console.log(data)
            setProfils(data)
        });

    }, []);

    useEffect(() => {
        const publicationsService = new PublicationsService();
        publicationsService.listPublications(tenant).then(data => {
            console.log(data)
            setPublicationSelects(data)
            setModif(null);
        });
    },[modif]);




    const formatCurrency = (value) => {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }

    const openNew = () => {
        //setEleve(emptyeleve);
        setSubmitted(false);
        setProductDialog(true);
    }
    const onGlobalFilterChange2 = (e) => {
        const value = e.target.value;
        let _filters2 = { ...filters2 };
        _filters2['global'].value = value;

        setFilters2(_filters2);
        setGlobalFilterValue2(value);
    }
    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
    }

    const hideModifDialog = () => {
        setSubmittedModif(false);
        setProductModifDialog(false);

    }


    const hideDeleteProductDialog = () => {
        setDeleteProductDialog(false);
    }

    const hideDeleteProductsDialog = () => {
        setDeleteProductsDialog(false);
    }
    const saveModifProduct = () =>{

    }
    const demandeFermer = () => {
        const publicationService = new PublicationsService();
        publicationService.creerPublication(publication).then(data => {
            console.log(data)
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Offre créée avec succès ', life: 3000 });
            setModif(1);
            setProductDialog(false);
        });
    }

    const fermerOffre = () => {
        const publicationService = new PublicationsService();
        publicationService.fermerOffre(idPubl).then(data => {
            console.log(data)
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Offre a été fermée! ', life: 3000 });
            setModif(1);
            setDeleteProductDialog(false);
        });
    }


   const saveProduct = () => {

        setSubmitted(true);
        const publicationService = new PublicationsService();

console.log("informations v"+vlibelle+' lieu  '+vlieu+' experience  '+ vexperience+'date limite '+vdate_limite+'fonction  '+profil.id+ ' niveau  '+niveauEtude.niveau_etudeid +' type offre '+typeOffre.id);
        if(vlibelle&&vlieu&&vexperience&&vdate_limite&&profil.id&&niveauEtude.niveau_etudeid&&typeOffre.id){
            publication.code= vcode ;
            publication.lieu= vlieu ;
            publication.libelle= vlibelle ;
            publication.experience=vexperience ;
            publication.ecole_ecoleid= tenant ;
            publication.niveau_id = niveauEtude.niveau_etudeid ;
            publication.fonction_fonctionid = profil.id ;
            publication.type_offre_id = typeOffre.id ;
            publication.date_limite = moment(vdate_limite).utc().format('YYYY-MM-DD')
            publicationService.creerPublication(publication).then(data => {
                console.log(data)
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Offre créée avec succès ', life: 3000 });
               setModif(1);
                setProductDialog(false);
            });

        } else {
            toast.current.show({severity:'error', summary: 'vie-ecoles', detail:'Veuillez renseigner les champs obligatoires', life: 3000});
        }


    }
    const editProduct = (pub) => {
        //setLiseleveInscrit({ ...product });
        setPublicationSelect(pub)

       /* publication.code= vcode ;
        publication.lieu= vlieu ;
        publication.libelle= vlibelle ;
        publication.experience=vexperience ;
        publication.ecole_ecoleid= tenant ;
        publication.niveau_id = niveauEtude.niveau_etudeid ;
        publication.fonction_fonctionid = profil.id ;
        publication.type_offre_id = typeOffre.id ;
        publication.date_limite = moment(vdate_limite).utc().format('YYYY-MM-DD')

        niveauEtude.niveau_etudeid = pub.niveau_id ;
        niveauEtude.niveau_etude_libelle =pub.libelle ;
        profil.id = pub.fonction_fonctionid ;
        profil.libelle = pub.libelle_fonction ;
        typeOffre.id = pub.type_offre_id ;
        typeOffre.libelle = pub.libelle_offre ;

        setvexperience(pub.experience) ;
        setvcode(pub.code);
        setvlibelle(pub.libelle);
        setvlieu(pub.lieu);
*/
        setProductModifDialog(true);
    }

    const confirmDeleteProduct = (product) => {
       // setEleve(product);
        setPublicationSelect(product.id);
        setidPubl(product.id);
        console.log(product.id);
        setDeleteProductDialog(true);
    }





    const createId = () => {
        let id = '';
        let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < 5; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return id;
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const confirmDeleteSelected = () => {
        setDeleteProductsDialog(true);
    }




    const Sexe = [
        {sexe_code: 'MASCULIN', sexe_libelle: 'MASCULIN'},
        {sexe_code: 'FEMININ', sexe_libelle: 'FEMININ'}

    ];

    const Statuts = [
        {statut_code: 'AFFECTE', statut_libelle: 'AFFECTE'},
        {statut_code: 'NON_AFFECTE', statut_libelle: 'NON AFFECTE'},
    ];

    const InscriptionTypeOperation = [
        {inscripStatuCode: 'INSCRIPTION', inscripStatulibelle: 'INSCRIPTION'},
        {inscripStatuCode: 'PREINSCRIPTION', inscripStatulibelle: 'PREINSCRIPTION'}
    ];



    const onInscripStatutChange = (e) => {
        setSelectedInscrStatut(e.value);
            }



    const onAnneeColaireChange = (e) => {
        setanneeScolaire(e.value);
    }



    const onNiveauEtudeChange = (e) => {
        setNiveauEtude(e.value);
        setvniveau_id(niveauEtude.niveau_etudeid);
    }

    const onProfilEtudeChange = (e) => {
        setProfil(e.value);
        setvfonction_fonctionid(profil.id);

    }
    const onTypeOffreChange = (e) => {
        setTypeOffre(e.value);
        setvtype_offre_id(typeOffre.id);

    }



    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Nouveau" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    {/*<Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedProducts || !selectedProducts.length} />*/}
                </div>
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" />
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        )
    }

    const libelleNiveauBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Niveau </span>
                {rowData.libelle_niveau}
            </>
        );
    }


    const LibelleTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Libelle offre</span>
                {rowData.libelle}
            </>
        );
    }

    const TypeOffreTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Type d'offre</span>
                {rowData.libelle_offre}
            </>
        );
    }
    const NumInscrBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">N°</span>
                {rowData.id}
            </>
        );
    }

    const codeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Statut</span>
                {rowData.statut}
            </>
        );
    }



    const statutBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Statut</span>
                {rowData.inscriptions_statut_eleve}
            </>
        );
    }

    const dateNaissanceBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Date naissance</span>
                {rowData.date_naissanceEleve}
            </>
        );
    }

    const dateLimiteBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Date limite</span>
                {rowData.date_limite}
            </>
        );
    }
    const lieuBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Lieu</span>
                {rowData.lieu}
            </>
        );
    }
    const LV2BodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">LV2</span>
                {rowData.inscriptionLangueVivante}
            </>
        );
    }

    const BadgeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Badge</span>
                {rowData.inscriptionBadge}
            </>
        );
    }

    const contactBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Cellulaire</span>
                {rowData.contactEleve}
            </>
        );
    }





    const imageBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Image</span>
                <img src={`assets/demo/images/product/${rowData.image}`} alt={rowData.image} className="shadow-2" width="100" />
            </>
        )
    }

    const priceBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Price</span>
                {formatCurrency(rowData.price)}
            </>
        );
    }

    const categoryBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Category</span>
                {rowData.category}
            </>
        );
    }
    const typeItemTemplate = (option) => {
        return <span className={`customer-badge status-${option}`}>{option}</span>;
    }
    const StatutFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={Statuts} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={typeItemTemplate} placeholder="Sélectionnez le statut" className="p-column-filter" showClear />;
    }

    const profilBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Profil</span>
                {rowData.libelle_fonction}
            </>
        )
    }

    const experienceBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Expérience</span>
                {rowData.experience}
            </>
        )
    }
    const dialogFooter = <div className="flex justify-content-center"><Button label="OK" className="p-button-text" autoFocus onClick={() => setShowMessage(false)} /></div>;

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editProduct(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteProduct(rowData)} />
             </div>
        );
    }
    const renderHeader2 = () => {
        return (
            <div className="flex justify-content-end">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue2} onChange={onGlobalFilterChange2} placeholder="Mots clés..." />
                </span>
            </div>
        )
    }
    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Liste des élèves</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Rechercher..." />
            </span>
        </div>
    );

    const productDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
        </>
    );
    const productModifDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideModifDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveModifProduct} />
        </>
    );

    const deleteProductDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
           <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={fermerOffre} />
        </>
    );
    const deleteProductsDialogFooter = (
        <>
            <Button label="Oui" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductsDialog} />
            {/*<Button label="Non" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedProducts} />*/}
        </>
    );
    const header2 = renderHeader2();
    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <h5>Liste des offres d'emploi </h5>

                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} ></Toolbar>

                    <DataTable value={publicationSelects} paginator className="p-datatable-customers" rows={5} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                               dataKey="id" filters={filters2} filterDisplay="row"  responsiveLayout="scroll"
                               globalFilterFields={['id','code', 'libelle', 'type_offre','libelle_niveau','experience','libelle_fonction','lieu','date_limite']} header={header2} emptyMessage="Pas d'elèves trouvés avec ces critères." responsiveLayout="scroll">

                        <Column filterField="id" header="N°"  body={NumInscrBodyTemplate} headerStyle={{ width: '10%', minWidth: '10rem' }}  ></Column>

                        <Column filterField="libelle" header="Libelle Poste"  body={LibelleTemplate} headerStyle={{ width: '80%', minWidth: '10rem' }}  ></Column>
                        <Column filterField="statut" header="Statut"  body={codeBodyTemplate} headerStyle={{ width: '10%', minWidth: '10rem' }} ></Column>


                        {<Column body={actionBodyTemplate}></Column>}
                    </DataTable>

                    <Dialog visible={showMessage} onHide={() => setShowMessage(false)} position="top" footer={dialogFooter} showHeader={false} breakpoints={{ '960px': '80vw' }} style={{ width: '30vw' }}>
                        <div className="flex justify-content-center flex-column pt-6 px-3">
                            <i className="pi pi-check-circle" style={{ fontSize: '5rem', color: 'var(--green-500)' }}></i>
                            <h5>{"Demande d'inscription"}</h5>
                            <p style={{ lineHeight: 1.5, textIndent: '1rem' }}>
                                {retourApi}
                            </p>
                        </div>
                    </Dialog>
                    <Dialog visible={productModifDialog} style={{ width: '650px' }} header="Informations sur l'offre" modal className="p-fluid"  onHide={hideModifDialog}>
                        <div className="field">
                            <label htmlFor="typeOffre">Type d'offre</label>
                            <InputText disabled id="typeOffre" value={publicationSelect.libelle_offre} />
                        </div>
                        <div className="field">
                            <label htmlFor="libelle_niveau">Niveau</label>
                            <InputText disabled id="libelle_niveau" value={publicationSelect.libelle_niveau} />
                        </div>

                        <div className="field">
                            <label htmlFor="experience">Expérience</label>
                            <InputText disabled id="experience" value={publicationSelect.experience} />
                        </div>

                        <div className="field">
                            <label htmlFor="profil">Profil</label>
                            <InputText disabled id="profil" value={publicationSelect.libelle_fonction} />
                        </div>

                        <div className="field">
                            <label htmlFor="lieu">Lieu</label>
                            <InputText  disabled id="lieu" value={publicationSelect.lieu} />
                        </div>

                        <div className="field">
                            <label htmlFor="datecreation">Date de création</label>
                            <InputText  className="text-orange-500 "disabled id="datecreation" value={publicationSelect.date_creation} />

                        </div>

                        <div className="field">
                            <label htmlFor="datelimite">Date limite</label>
                            <InputText  className="text-orange-500 "disabled id="datecreation" value={publicationSelect.date_limite} />

                        </div>
                    </Dialog>
                    {/*dialog for modif */}
                    <Dialog visible={productDialog} style={{ width: '650px' }} header="Créer une offre" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                        <div className="formgrid grid">
                            <div className="field col">
                                <span className="p-float-label">
                                    <InputText value={vcode}  onChange={(e) => setvcode(e.target.value)}  required/>
                                    <label htmlFor="libelle">Code l'offre *</label>
                                </span>
                            </div>
                        </div>

                        <div className="formgrid grid">
                              <div className="field col">
                                <span className="p-float-label">
                                    <InputTextarea value={vlibelle}  onChange={(e) => setvlibelle(e.target.value)} rows={5} cols={30} autoResize required/>
                                    <label htmlFor="libelle">Commenter l'offre *</label>
                                </span>
                            </div>
                        </div>
                        <div className="formgrid grid">
                            <div className="field col">
                                 <span className="p-float-label">
                            <InputText type="text" id="experience" value={vexperience} onChange={(e) => setvexperience(e.target.value)} required />
                            <label htmlFor="experience">Expérience*</label>
                                </span>
                            </div>
                            <div className="field col">
                                 <span className="p-float-label">
                            <InputText type="text" id="lieu" value={vlieu} onChange={(e) => setvlieu(e.target.value)} required />
                            <label htmlFor="lieu">Lieu de l'emploi*</label>
                                </span>
                            </div>

                            <div className="field col">
                                    <span className="p-float-label">
                                        <Calendar showIcon showButtonBar view="month" required id="basic" value={vdate_limite} onChange={(e) => setvdate_limite(e.value)} dateFormat="dd/mm/yy" />
                                         <label htmlFor="basic">Date limite *(dd/mm/yyyy)</label>
                                </span>
                            </div>

                        </div>
                            <div className="formgrid grid">
                                <div className="field col">
                                    <Dropdown value={niveauEtude} options={niveauEtudes} onChange={onNiveauEtudeChange} optionLabel="niveau_etude_libelle" placeholder="Selectionnez le niveau*" />
                                </div>

                                <div className="field col">
                                    <Dropdown value={profil} options={profils} onChange={onProfilEtudeChange} optionLabel="libelle" placeholder="Selectionnez le profil*" />
                                </div>

                                    <div className="field col">
                                        <Dropdown value={typeOffre} options={typeOffres} onChange={onTypeOffreChange} optionLabel="libelle" placeholder="Selectionnez le type d'offre*" />
                                    </div>

                            </div>

                     {/*   <div className="formgrid grid">
                            <div className="field col">
                                <Dropdown value={statut} onChange={(e) => setstatut(e.value)} options={statuts} optionLabel="libelle" placeholder="Postuler à cette offre" />
                            </div>
                        </div>*/}




                    </Dialog>


                    {/*End dialog for modif */}

                    <Dialog visible={deleteProductDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            { <span>Voulez vous vraiment supprimer cette offre  <b> </b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteProductsDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductsDialogFooter} onHide={hideDeleteProductsDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            { <span>Voulez vous vraiment supprimer les elements selectionnés?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(CreerPublication, comparisonFn);
