import React, {useState, useEffect} from 'react';
import {InputText} from 'primereact/inputtext';
import {InputTextarea} from 'primereact/inputtextarea';
import {InputMask} from 'primereact/inputmask';
import {InputNumber} from 'primereact/inputnumber';
import {AutoComplete} from 'primereact/autocomplete';
import {Calendar} from 'primereact/calendar';
import {Chips} from 'primereact/chips';
import {Dropdown} from 'primereact/dropdown';
import {MultiSelect} from 'primereact/multiselect';
import {RapportService} from "../../service/rapports/RapportService";
import {Tooltip} from "primereact/tooltip";
import {Button} from "primereact/button";
import {SouscriptionService} from "../../service/operations/SouscriptionService";

const RapportGlobal = () => {
    const [countries, setCountries] = useState([]);
    const [filteredCountries, setFilteredCountries] = useState(null);
    const [value1, setValue1] = useState('');
    const [baseUri, setbaseUri] = useState(window.$baseUri);
    const [value2, setValue2] = useState('');
    const [codeEcole, setCodeEcole] = useState(sessionStorage.getItem('EcoleID'));
    const [certifMatriculeEleve, setcertifMatriculeEleve] = useState('7485696Z');
    const [certifrequentfMatriculeEleve, setCertiffrequentMatriculeEleve] = useState('5410M');
    const [matriculeAgent, setMatriculeAgent] = useState('01452J');
    const [classe, setClasse] = useState('6ième1');
    const [value5, setValue5] = useState('');
    const [value6, setValue6] = useState('');
    const [value7, setValue7] = useState('');
    const [value8, setValue8] = useState('');
    const [value9, setValue9] = useState([]);
    const [value10, setValue10] = useState(null);
    const [selectClasse, setSelectclasse] = useState(null);

    const classes = [
        {name: '6ième1', code: '6ième1'},
        {name: '5ième1', code: '5ième1'},
        {name: '4ième1', code: '4ième1'},
        {name: '3ième1', code: '3ième1'},
    ];

    const opencertifile = () => {

        fetch(baseUri+"imprimer-rapport/pouls-rapport/"+codeEcole+"/"+"docx")
            .then(response => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = 'Rapport-Pouls-scolaire.docx';
                    a.click();
                });
                //window.location.href = response.url;
            });






    }

    const opencertifile2 = (codeEcole,matricule) => {
        const rapportService = new RapportService();
        rapportService.connexionJasper();
        rapportService.Certificattravail(codeEcole,matriculeAgent) ;
    }
    const opencertifile4 = (codeEcole,matricule) => {
        const rapportService = new RapportService();
        rapportService.connexionJasper();
        rapportService.Certificatfrequentation(codeEcole,matricule) ;
    }
    const opencertifile3 = (codeEcole,classe) => {
        const rapportService = new RapportService();
        rapportService.connexionJasper();
        rapportService.tableauHonneurParClasse(codeEcole,selectClasse.code) ;
    }
    const onClasseChange = (e) => {
        setSelectclasse(e.value);
    }
    const searchCountry = (event) => {
        // in a real application, make a request to a remote url with the query and
        // return filtered results, for demo we filter at client side
        const filtered = [];
        const query = event.query;
        for (let i = 0; i < countries.length; i++) {
            const country = countries[i];
            if (country.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
                filtered.push(country);
            }
        }
        setFilteredCountries(filtered);
    };

    return (
        <div className="grid">
            <div className="col-12 md:col-6">
                <div className="card">
                    <h5>Rapport général</h5>

                    <Button label="Valider" className="mr-2 mb-2" onClick={() => opencertifile()} ></Button>
                   </div>
            </div>


        </div>
    )
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(RapportGlobal, comparisonFn);
