import axios from 'axios'
import { BaseService } from './BaseService';

const ecole = sessionStorage.getItem('EcoleID');
export class InscriptionService extends BaseService {

    getByBrancheAndAnneeAndStatut(annee, branche, statut) {
        return axios.get(this.getHost()+'inscription/retrieve-by-branche-annee-statut/'+annee+'/?branche='+branche+'&statut='+statut+'&ecole='+ecole)
            .then(res => res.data);
    }

    getByElevesToAttribClasse(annee, branche, statut) {
        return axios.get(this.getHost()+'inscription/retrieve-to-attrib-classe/'+annee+'/?branche='+branche+'&statut='+statut+'&ecole='+ecole)
            .then(res => res.data);
    }
}