import React from 'react';

export const AppFooter = (props) => {

    return (
        <div className="layout-footer">
            <img src={props.layoutColorMode === 'light' ? 'assets/layout/images/logo-gain.png' : 'assets/layout/images/logo-gain.png'} alt="Logo" height="20" className="mr-2" />
           <p> &copy; Copyright, 2022
            <span className="font-medium ml-2">GHI SERVICES</span></p>
        </div>
    );
}
