import axios from 'axios';
import { BaseService } from './BaseService';


export class FonctionService extends BaseService{

    async getList() {
        return axios.get(this.getHost()+'fonctions/list').then(res => res.data)
    }

}