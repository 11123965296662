import axios from 'axios';
import { BaseService } from './BaseService';

const constants = {
    host: 'http://localhost:8889/gestion-ecole-api',
  };

export class NoteService extends BaseService{

    async getList(){
        const res = await axios.get(this.getHost()+'notes/list');
        return res.data;
    }

    async getListByEvaluation(evaluation){
        const res = await axios.get(this.getHost()+'notes/list-by-evaluation/'+evaluation);
        return res.data;
    }

    async getListByClasse(classe){
        const res = await axios.get(this.getHost()+'notes/list-by-classe/'+classe);
        return res.data;
    }

    async getListAboutEvaluation(evaluation){
        const res = await axios.get(this.getHost()+'notes/list-about-evaluation/'+evaluation);

        return res.data;
    }

    async handleNotes(notes){
        const res = await axios.post(this.getHost()+'notes/handle-notes',notes);
        return res.data;
    }

    async getMoyennesAndNotes(classe,annee,periode){
        const res = await axios.get(this.getHost()+'notes/list-classe-notes/'+classe+'/'+annee+'/'+periode);
        return res.data
    }

    async getMoyennesAndNotesMatricule(matricule,annee,periode){
        const res = await axios.get(this.getHost()+'notes/list-matricule-notes-moyennes/'+matricule+'/'+annee+'/'+periode);
        return res.data
    }

    async getMoyennesMatiereAndNotes(classe,matiere,annee,periode){
        const res = await axios.get(this.getHost()+'notes/list-classe-matiere-notes/'+classe+'/'+matiere+'/'+annee+'/'+periode);
        return res.data
    }

    async getMoyennesMatiereAndNotesMatricule(matricule,matiere,annee,periode){
        const res = await axios.get(this.getHost()+'notes/list-matricule-matiere-notes/'+matricule+'/'+matiere+'/'+annee+'/'+periode);
        return res.data
    }

    async getEleveNotesDto(matricule,classe,annee,periode){
        const res = await axios.get(this.getHost()+'notes/list-notes-eleve-by-periode-dto/'+matricule+'/'+classe+'/'+annee+'/'+periode);
        return res.data
    }

}
