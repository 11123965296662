// App.js
import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css"; 
import './Banner.css'

const bannerData = [
  {banner:"assets/img/banner/9/1.jpg"},
  // {banner:"assets/img/banner/9/2.jpg"},
  // {banner:"assets/img/banner/9/3.jpg"},
  {banner:"assets/img/banner/9/6.jpg"},

  // {
  //   banner:"assets/img/banner/9/6.jpg",
  //   topShapes: [
  //     "assets/img/banner/1/6.jpg",
  //     // "assets/img/banner/1/3.png",
  //     // "assets/img/banner/1/5.png"
  //   ],
  //   title: "Master The Skills Drive Your Career.",
  //   subtitle: "Online Learning Platform",
  //   description: "Through a combination of lectures, readings, and discussions, students will gain a solid foundation in educational psychology.",
  //   buttonText: "View All Course",
  //   mainImage: "assets/img/banner/1/banner-img.png",
  //   authorImage: "assets/img/banner/1/author.jpg",
  //   authorName: "Brian Cumin",
  //   authorQuote: "“Lorem ipsum dolorous rises various qualm quique id dam connecter easum commode impediment”.",
  //   rating: 4.5,
  //   reviews: "3.4k Reviews",
  //   congrats: "Congratulations",
  //   shapes: [
  //     "assets/img/banner//shape_1.png",
  //     "assets/img/banner/1/2.png",
  //     "assets/img/banner/1/4.png"
  //   ]
  // },
  // {
  //   topShapes: [
  //     "assets/img/banner/1/1.png",
  //     "assets/img/banner/1/3.png",
  //     "assets/img/banner/1/5.png"
  //   ],
  //   title: "Master The Skills Drive Your Career.",
  //   subtitle: "Online Learning Platform",
  //   description: "Through a combination of lectures, readings, and discussions, students will gain a solid foundation in educational psychology.",
  //   buttonText: "View All Course",
  //   mainImage: "assets/img/banner/1/banner-img.png",
  //   authorImage: "assets/img/banner/1/author.jpg",
  //   authorName: "Brian Cumin",
  //   authorQuote: "“Lorem ipsum dolorous rises various qualm quique id dam connecter easum commode impediment”.",
  //   rating: 4.5,
  //   reviews: "3.4k Reviews",
  //   congrats: "Congratulations",
  //   shapes: [
  //     "assets/img/banner/1/shape_1.png",
  //     "assets/img/banner/1/2.png",
  //     "assets/img/banner/1/4.png"
  //   ]
  // }
  // Ajoutez d'autres objets pour plus de bannières si nécessaire
];

const BannerSlide = ({ data }) => {
  return (
    <>
    <div className="single-banner_ container-fluid container-custom-1 p-0" >
        <img className={`banner-top-shape`} src={data.banner} alt="" style={{borderRadius:'20px'}}/>
    </div>
    
    {/* <div className="single-banner container-fluid container-custom-1 p-0">
      {data.topShapes.map((src, index) => (
        <img key={index} className={`banner-top-shape-${index + 1}`} src={src} alt="" />
      ))}
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-6 col-lg-5 col-md-8">
            <div className="banner-content">
              <div className="section-area">
                <span className="section-subtitle">{data.subtitle}</span>
                <h1 className="section-title">{data.title}<img src="assets/img/banner/1/line.png" alt="" /></h1>
                <p className="section-text">{data.description}</p>
                <a href="#" className="theme-btn theme-btn-big">{data.buttonText}</a>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-7 d-none d-lg-block">
            <div className="banner-right">
              <div className="banner-img">
                <img src={data.mainImage} alt="" />
              </div>
              <div className="banner-meta">
                <div className="banner-inner-meta banner-meta-info">
                  <img className="inner-img" src="assets/img/banner/1/info_icon.png" alt="" />
                  <div className="banner-inner-info">
                    <div className="banner-info-img">
                      <img src={data.authorImage} alt="" />
                      <span className="banner-info-icon"><i className="fa-solid fa-star"></i></span>
                    </div>
                    <div className="banner-info-text">
                      <h5>{data.authorName}</h5>
                      <p>{data.authorQuote}</p>
                    </div>
                  </div>
                </div>
                <div className="banner-inner-meta banner-meta-rating">
                  <span><i className="fa-solid fa-star"></i>{data.rating} ({data.reviews})</span>
                  <h5>{data.congrats}</h5>
                </div>
              </div>
              <div className="banner-right-shape">
                {data.shapes.map((src, index) => (
                  <img key={index} className={`banner-shape-${index + 1}`} src={src} alt="" />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> */}

    </>
    
  );
}

const App = () => {
  // Vérifie si bannerData contient plus d'un objet
  const shouldRenderSlider = bannerData.length > 1;

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
    <div className="App">
      {shouldRenderSlider && (
        <Slider {...settings}>
          {bannerData.map((data, index) => (
            <BannerSlide key={index} data={data} />
          ))}
        </Slider>
      )}
      {!shouldRenderSlider && (
        <BannerSlide data={bannerData[0]} />
      )}
    </div>
  );
}

export default App;
