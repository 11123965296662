import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Accueil  from './components/login/Accueil';

export const LoginAppTopbar = (props) => {

    return (
       
       <div className="layout-topbar">
            <div className='container' style={{width:'100%', margin: '0 auto_', display:'flex_'}}>
                <Link to="/" className="layout-topbar-logo">
                    <img src={props.layoutColorMode === 'light' ? 'assets/layout/images/logo-gain.png' : 'assets/layout/images/logo-gain.png'} alt="logo"/>
                    <span>Pouls-scolaire</span>
                </Link>

                {/* <Accueil /> */}
            </div>
        </div>
    );
}
