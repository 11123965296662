// CourseComponent.js
import React, { useState } from 'react';

// Données des cours pour l'onglet "Tout Voir"
const allCourses = [
  {
    id: 1,
    title: "Créez votre premier site interactif !",
    image: "assets/img/course/1/1.jpg",
    university: "GHI SERVICES",
    lessons:"" ,
    students: "",
    originalPrice: "",
    discountedPrice: "",
    description: "Apprenez les bases de la programmation web et créez un site interactif à partir de zéro. Ce cours vous guide à travers HTML, CSS, et JavaScript, pour vous aider à maîtriser les compétences essentielles de développement web.",
    features: [
      "Apprenez à structurer vos pages avec HTML5.",
      "Apprenez à structurer vos pages avec HTML5.",
      "Ajoutez de l'interactivité grâce à JavaScript."
    ]
  },
  {
    id: 2,
    title: "Initiation à l’Intelligence Artificielle pour les Lycéens",
    image: "assets/img/course/2/2.jpg",
    university: "GHI SERVICES",
    lessons: "",
    students: "",
    originalPrice: "",
    discountedPrice: "",
    description: "Ce cours offre une introduction captivante à l’intelligence artificielle (IA) pour les lycéens. Découvrez comment les algorithmes, l'apprentissage automatique et la robotique transforment notre monde.",
    features: [
      "Introduction aux concepts fondamentaux de l'IA.",
      "Création de vos premiers algorithmes d'apprentissage automatique.",
      "Exploration de cas d'utilisation réels de l'IA dans la vie quotidienne."
    ]
  },
  {
    id: 1,
    title: "Fondamentaux de la Comptabilité : Préparez-vous pour l'université",
    image: "assets/img/course/1/1.jpg",
    university: "GHI SERVICES",
    lessons: "",
    students: "",
    originalPrice: "",
    discountedPrice: "",
    description: "Maîtrisez les bases de la comptabilité avant de rejoindre l'université. Ce cours est conçu pour initier les lycéens aux concepts clés tels que les bilans, les états financiers et la gestion des comptes.",
    features: [
      "Comprendre les principes de la comptabilité générale.",
      "Analyse des états financiers et gestion de comptes.",
      "Études de cas pratiques pour renforcer vos compétences."
    ]
  },
  {
    id: 2,
    title: "Les Bases de la Robotique : Construisez votre Premier Robot",
    image: "assets/img/course/2/2.jpg",
    university: "GHI SERVICES",
    lessons: "",
    students: "",
    originalPrice: "",
    discountedPrice: "",
    description: "Plongez dans l'univers de la robotique et apprenez à construire et programmer votre propre robot. Ce cours vous familiarisera avec les concepts de mécanique, électronique, et programmation embarquée.",
    features: [
      "Introduction aux composants de base d'un robot.",
      "Programmation de robots avec Arduino",
      "Projets pratiques pour développer votre propre robot fonctionnel."
    ]
  },
  // Ajoutez d'autres cours pour l'onglet "Tout Voir" si nécessaire
];

// Données des cours pour l'onglet "Tendances"
const trendingCourses = [
  {
    id: 3,
    title: "Introduction à la Science des Données",
    image: "assets/img/course/3/3.jpg",
    university: "GHI SERVICES",
    lessons: "",
    students: "",
    originalPrice: "",
    discountedPrice: "",
    description: "Explorez les bases de la science des données et du machine learning.",
    features: [
      "Analyse de données avec Python",
      "Bases du machine learning",
      "Techniques de visualisation de données"
    ]
  },
  {
    id: 4,
    title: "Initiation au Design Graphique : Créez des Visuels Professionnels",
    image: "assets/img/course/4/1.jpg",
    university: "GHI SERVICES",
    lessons: "",
    students: "",
    originalPrice: "",
    discountedPrice: "",
    description: "Découvrez les fondamentaux du design graphique et apprenez à créer des visuels captivants. Ce cours vous guidera à travers les outils de conception modernes comme Photoshop, Illustrator et Canva.",
    features: [
      "Maîtrisez les bases du design avec Adobe Photoshop.",
      "Créez des logos et des affiches avec Illustrator.",
      "Appliquez les principes du design pour produire des visuels de qualité."
    ]
  },
  // Ajoutez d'autres cours pour l'onglet "Tendances" si nécessaire
];

const CourseComponent = () => {
  const [activeTab, setActiveTab] = useState('home');

  // Choisir les cours en fonction de l'onglet actif
  const courses = activeTab === 'home' ? allCourses : trendingCourses;

  return (
    <section className="course-area">
      <div className="containers -fluid containers_-custom-1 p-0">
        <div className="course-wrap pt-120 pb-90">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="course-section-area text-center">
                  <div className="section-area section-area-top">
                    <span className="section-subtitle">Cours à la Une</span>
                    <h2 className="section-title mb-20">Explorez les Cours à la Une</h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <div className="course-tab">
                  <ul className="nav nav-pills" id="pills-tab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button
                        className={`nav-link ${activeTab === 'home' ? 'active' : ''}`}
                        id="pills-home-tab"
                        onClick={() => setActiveTab('home')}
                        type="button"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected={activeTab === 'home'}
                      >
                        Tout Voir
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className={`nav-link ${activeTab === 'profile' ? 'active' : ''}`}
                        id="pills-profile-tab"
                        onClick={() => setActiveTab('profile')}
                        type="button"
                        role="tab"
                        aria-controls="pills-profile"
                        aria-selected={activeTab === 'profile'}
                      >
                        Tendances
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="course-inner">
              <div className="tab-content" id="pills-tabContent">
                <div
                  className={`tab-pane fade ${activeTab === 'home' ? 'show active' : ''}`}
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                  tabIndex="0"
                >
                  <div className="row">
                    {courses.map((course) => (
                      <div key={course.id} className="col-xxl-3 col-lg-4 col-md-6">
                        <div className="course-item mb-30">
                          <div className="course-img">
                            <img src={course.image} alt={course.title} />
                          </div>
                          <div className="course-content">
                            <div className="course-content-top">
                              <div className="course-top-icon">
                                <img src="assets/img/course/1/v1.png" alt="" />
                              </div>
                              <div className="course-top-title">
                                <h6>{course.university}</h6>
                              </div>
                            </div>
                            <h5 className="course-content-title">
                              <a href="#">{course.title}</a>
                            </h5>
                            <div className="course-content-bottom">
                              <div className="course-bottom-info">
                                <span>
                                  <i className="fa-thin fa-book-blank"></i>
                                  {course.lessons}
                                </span>
                                <span>
                                  <i className="fa-thin fa-user-group"></i>
                                  {course.students}
                                </span>
                              </div>
                              {/* <div className="course-bottom-price">
                                <span>
                                  <del>${course.originalPrice}</del>${course.discountedPrice}
                                </span>
                              </div> */}
                            </div>
                          </div>
                          <div className="course-hover-content">
                            <div className="course-hover-content-top">
                              <div className="course-top-icon">
                                <img src="assets/img/course/1/v1.png" alt="" />
                              </div>
                              <div className="course-top-title">
                                <h6>{course.university}</h6>
                              </div>
                            </div>
                            <h5 className="course-hover-content-title">
                              <a href="course-details.html">{course.title}</a>
                            </h5>
                            <p className="course-hover-content-text">{course.description}</p>
                            <ul className="course-hover-content-list">
                              {course.features.map((feature, index) => (
                                <li key={index}>
                                  <i className="fa-thin fa-check"></i>
                                  {feature}
                                </li>
                              ))}
                            </ul>
                            {/* <div className="course-hover-content-btn">
                              <div className="course-hover-cart-btn">
                                <a href="#" className="theme-btn course-btn">
                                  Ajouter au Panier
                                </a>
                              </div>
                              <div className="course-hover-fav-btn">
                                <a href="#">
                                  <i className="fa-thin fa-heart"></i>
                                </a>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div
                  className={`tab-pane fade ${activeTab === 'profile' ? 'show active' : ''}`}
                  id="pills-profile"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                  tabIndex="0"
                >
                  <div className="row">
                    {trendingCourses.map((course) => (
                      <div key={course.id} className="col-xxl-3 col-lg-4 col-md-6">
                        <div className="course-item mb-30">
                          <div className="course-img">
                            <img src={course.image} alt={course.title} />
                          </div>
                          <div className="course-content">
                            <div className="course-content-top">
                              <div className="course-top-icon">
                                <img src="assets/img/course/1/v1.png" alt="" />
                              </div>
                              <div className="course-top-title">
                                <h6>{course.university}</h6>
                              </div>
                            </div>
                            <h5 className="course-content-title">
                              <a href="#">{course.title}</a>
                            </h5>
                            <div className="course-content-bottom">
                              <div className="course-bottom-info">
                                <span>
                                  <i className="fa-thin fa-book-blank"></i>
                                  {course.lessons}
                                </span>
                                <span>
                                  <i className="fa-thin fa-user-group"></i>
                                  {course.students}
                                </span>
                              </div>
                              <div className="course-bottom-price">
                                <span>
                                  <del>${course.originalPrice}</del>${course.discountedPrice}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="course-hover-content">
                            <div className="course-hover-content-top">
                              <div className="course-top-icon">
                                <img src="assets/img/course/1/v1.png" alt="" />
                              </div>
                              <div className="course-top-title">
                                <h6>{course.university}</h6>
                              </div>
                            </div>
                            <h5 className="course-hover-content-title">
                              <a href="course-details.html">{course.title}</a>
                            </h5>
                            <p className="course-hover-content-text">{course.description}</p>
                            <ul className="course-hover-content-list">
                              {course.features.map((feature, index) => (
                                <li key={index}>
                                  <i className="fa-thin fa-check"></i>
                                  {feature}
                                </li>
                              ))}
                            </ul>
                            <div className="course-hover-content-btn">
                              <div className="course-hover-cart-btn">
                                <a href="#" className="theme-btn course-btn">
                                  Ajouter au Panier
                                </a>
                              </div>
                              <div className="course-hover-fav-btn">
                                <a href="#">
                                  <i className="fa-thin fa-heart"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CourseComponent;
