import axios from 'axios';
import { BaseService } from './BaseService';
import {BaseDevService} from "./BaseDevService";

export class FormService extends BaseDevService {

    getList(GetManyRequestDto) {
        return axios.post(this.getHost() + 'formulaires/obtention/plusieurs',GetManyRequestDto).then(res => res.data);
    }
}
