import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Toolbar } from 'primereact/toolbar';

import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import {EleveService} from '../../service/operations/EleveService';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';

import {Dropdown} from "primereact/dropdown";

const AttribuerClasse = () => {
    let emptyInscrAvalider = {
        idEleveInscrit: null,
        inscriptionsidEleve:null,
        nomEleve :'' ,
        prenomEleve :'',
        matriculeEleve:'',
        Date_naissanceEleve :null,
        sexeEleve :'',
        contactEleve :null,
        inscriptions_statut_eleve :null,
        inscriptions_status:'VALIDEE' ,
        inscriptions_processus:'EN_COURS',
        inscriptions_type : null
    };
    let emptyeleve = {
        eleve_mail :'' ,
        eleve_numero_extrait_naiss :0,
        eleveadresse:'',
        elevecellulaire :'',
        elevecode :'',
        elevedate_etabli_extrait_naiss :null,
        elevedate_naissance :null,
        eleveid:null ,
        idTenant : '',
        eleveSexe : '',
        elevelieu_etabliss_etrait_naissance :'',
        elevelieu_naissance:'',
        elevenom:'',
        eleveprenom:'',
        elevematricule_national:'',
        parentList:[]
    };

    let emptyInscriptions = {
        inscriptionsid :null ,
        inscriptionscode :'',
        inscriptions_ecole_origine:'',
        inscriptions_classe_precedente :'',
        inscriptions_derniereclasse_religieuse :'',
        inscriptions_classe_actuelle :'',
        inscriptions_delete :false,
        inscriptions_status : '',
        inscriptions_redoublant:'',
        inscriptions_boursier:'',
        inscriptions_statut_eleve:'',
        inscriptions_type:'',
        inscriptionsdate_creation : null,
        inscriptionsdate_modification :null,
        identifiantEleve:null,
        identifiantEcole:0,
        identifiantAnnee_scolaire:0,

        identifiantNiveau:'' ,
        inscriptionBadge:'',
        tenant_tenantid:'',
        inscriptionLangueVivante:'',
        libellehandicap:[]
    };
let emptyClasse={
    classecode: '',
    classeid: null,
    classelibelle:''
} ;
    let emptyTenant= {
        tenantcode: '' ,
        tenantid: 21 ,
        tenantlibelle: '',
        tenant_tenantid: ''
    } ;

    let emptyEtatInscription={
        etat_code:'',
        etat_libelle:''
    }

    let emptystatutEleve={
        statut_code:'',
        statut_libelle:''
    }
    let emptyAnneeScoliare= {
        annee_scolaireid: null ,
        annee_scolaire_libelle: '' ,
        annee_scolaire_code: '',
        annee_scolaire_visible:'',
        tenant_tenantid: ''
    } ;

    let emptyStatutEleve= {
        statuts_eleveid: null ,
        statuts_elevecode: '' ,
        statuts_elevelibelle: '',
        tenant_tenantid: ''
    } ;

    let emptyNiveau= {
        niveauid: null ,
        niveaucode: '' ,
        niveaulibelle: '',
        tenant_tenantid: ''
    } ;


    const columns = [
        {field: 'inscriptions_status', header: 'Statut Inscription'},
        {field: 'inscriptionsidEleve', header: 'N°'},
        {field: 'nomEleve', header: 'Nom'},
        {field: 'prenomEleve', header: 'Prenom'},
        {field: 'matriculeEleve', header: 'Matricule'},
        {field: 'inscriptions_type', header: 'Type Inscription'}
    ];
    const [selectedColumns, setSelectedColumns] = useState(columns);

    const [filters2, setFilters2] = useState({
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'prenomEleve':{ value: null, matchMode: FilterMatchMode.STARTS_WITH },
        'inscriptions_status':{ value: null, matchMode: FilterMatchMode.STARTS_WITH },
        'inscriptionsidEleve':{ value: null, matchMode: FilterMatchMode.STARTS_WITH },
        'nomEleve':  { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        'matriculeEleve': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        'inscriptions_type': { value: null, matchMode: FilterMatchMode.EQUALS  }
    });
    const [idModif, setIdmodif] = useState(null);
    const [inscriAvalide, setainscriAvalide]  = useState(emptyInscrAvalider);
    const [inscriAvalides, setinscriAvalides]  = useState(null);
    const [anneeScolaires, setanneeScolaires]  = useState(null);
    const [anneeScolaire, setanneeScolaire]  = useState(emptyAnneeScoliare);
    const [countEleveParClasse, setCountEleveParClasse] = useState(null);
    const [niveaus, setniveaus]  = useState(null);
    const [niveau, setniveau]  = useState(emptyNiveau);
    const [classe, setclasse]  = useState(emptyClasse);
    const [classes, setclasses]  = useState(null);
      const [etatInscription, setEtatInscription]  = useState(emptyEtatInscription);
    const [statutEleve, setstatutEleve]  = useState(emptystatutEleve);
    const [globalFilterValue2, setGlobalFilterValue2] = useState('');
    const [typeOperation, setTypeOperation]  = useState(null);
    const [anneeEncours, setanneeEncours]  = useState("2021-2022");
    const [typeOperation1, settypeOperation1]  = useState("INSCRIPTION");
    const [typeOperation2, settypeOperation2]  = useState("PREINSCRIPTION");
    const [tenant, settenant]  = useState("1");

    const [eleves, setEleves]  = useState(null);
    const [filters1, setFilters1] = useState(null);
    const [productDialog, setProductDialog] = useState(false);
    const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
    const [eleve, setEleve]  = useState(emptyeleve);
    const [eleveCreate, setEleveCreate]  = useState(emptyeleve);
    const [inscription, setInscription]  = useState(emptyInscriptions);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    const [selectedNiveau, setSelectedNiveau] = useState(null);
    const [selectedStatut, setSelectedStatut] = useState(null);
    const [selectedSexe, setSelectedSexe] = useState(null);
    const [selectedInscrStatut, setSelectedInscrStatut] = useState(null);
    const initFilters1 = () => {
        setFilters2({
            'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
            'prenomEleve':{ value: null, matchMode: FilterMatchMode.STARTS_WITH },
            'inscriptions_status':{ value: null, matchMode: FilterMatchMode.STARTS_WITH },
            'inscriptionsidEleve':{ value: null, matchMode: FilterMatchMode.STARTS_WITH },
            'nomEleve':  { value: null, matchMode: FilterMatchMode.STARTS_WITH },
           'matriculeEleve': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            'inscriptions_type': { value: null, matchMode: FilterMatchMode.EQUALS  }
        });
        setGlobalFilterValue2('');
    }
    const onGlobalFilterChange2 = (e) => {
        const value = e.target.value;
        let _filters2 = { ...filters2 };
        _filters2['global'].value = value;

        setFilters2(_filters2);
        setGlobalFilterValue2(value);
    }
    const clearFilter1 = () => {
        initFilters1();
    }
    const onColumnToggle = (event) => {
        let selectedColumns = event.value;
        let orderedSelectedColumns = columns.filter(col => selectedColumns.some(sCol => sCol.field === col.field));
        setSelectedColumns(orderedSelectedColumns);
    }
    useEffect(() => {
        const eleveService = new EleveService();
        initFilters1();
        eleveService.listInscriptionAvalider(tenant,inscriAvalide.inscriptions_status,inscriAvalide.inscriptions_processus)
            .then(data => {
                console.log(data)
                setinscriAvalides(data)
            });

        eleveService.listClasseParEcole(tenant)
            .then(data => {
                console.log(data)
                setclasses(data)
            });

        eleveService.listNiveau(tenant).then(data => {
            console.log(data)
            setniveaus(data)
        });

        eleveService.AnneeScolaire(tenant).then(data => {
            console.log(data)
            setanneeScolaire(data)
        });



    }, []);

    useEffect(() => {
        const eleveService = new EleveService();

        eleveService.nombreEleveParClasse(tenant,classe.classeid)
            .then(data => {
                console.log(data)
                setCountEleveParClasse(data)
            });

    }, [classe]);


    useEffect(() => {
        const eleveService = new EleveService();

        eleveService.listInscriptionAvalider(tenant,inscriAvalide.inscriptions_status,inscriAvalide.inscriptions_processus)
            .then(data => {
            console.log(data)
            setinscriAvalides(data)
        });

    }, [idModif]);

    const formatCurrency = (value) => {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }

    const openNew = () => {
        setEleve(emptyeleve);
        setSubmitted(false);
        setProductDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
    }

    const hideDeleteProductDialog = () => {
        setDeleteProductDialog(false);
    }

    const hideDeleteProductsDialog = () => {
        setDeleteProductsDialog(false);
    }

    const saveProduct = () => {
        setSubmitted(true);

        const eleveService = new EleveService();
        if(classe.classeid ){

            eleveService.attribuerClasse(inscriAvalide.idEleveInscrit,classe.classeid,anneeScolaire.annee_scolaireid,inscriAvalide.inscriptionsidEleve,tenant)
                .then(data => {
                console.log(data)
                   setIdmodif(2);
            });
            setclasse(emptyClasse) ;
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Classe affectée avec succès ', life: 3000 });

            setProductDialog(false);

        } else {

            toast.current.show({ severity: 'error', summary: 'Error Message', detail: 'Veuillez Choisir la classe', life: 3000 });
            //setProductDialog(false);
        }


    }

    const editProduct = (product) => {
        setainscriAvalide({ ...product });
        setProductDialog(true);
    }

    const confirmDeleteProduct = (product) => {
        setainscriAvalide(product);
        setDeleteProductDialog(true);
    }

    const deleteProduct = () => {
        let _eleves = eleves.filter(val => val.eleveid !== eleve.eleveid);
        setEleves(_eleves);
        const eleveService = new EleveService();
        eleveService.SupprimerEleve(eleve.eleveid,eleve)
            .then(data => setEleves(_eleves));

        setDeleteProductDialog(false);
        setEleve(emptyeleve);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'eleve a été supprimer', life: 3000 });
    }

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < eleves.length; i++) {
            if (eleves[i].eleveid === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    const createId = () => {
        let id = '';
        let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < 5; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return id;
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const confirmDeleteSelected = () => {
        setDeleteProductsDialog(true);
    }

    const deleteSelectedProducts = () => {
        let _eleves = eleves.filter(val => !selectedProducts.includes(val));
        setEleves(_eleves);
        setDeleteProductsDialog(false);
        setSelectedProducts(null);

        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Products Deleted', life: 3000 });
    }



    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _eleve = { ...eleve };
        _eleve[`${name}`] = val;

        setEleve(_eleve);
    }


    const EtatInscriptions = [
        {etat_code: 'EN_ATTENTE', etat_libelle: 'EN ATTENTE'},
        {etat_code: 'VALIDEE', etat_libelle: 'VALIDEE'}

    ];
    const Sexe = [
        {sexe_code: 'MASCULIN', sexe_libelle: 'MASCULIN'},
        {sexe_code: 'FEMININ', sexe_libelle: 'FEMININ'}

    ];

    const Statuts = [
        {statut_code: 'AFFECTE', statut_libelle: 'AFFECTE'},
        {statut_code: 'NON_AFFECTE', statut_libelle: 'NON AFFECTE'}
    ];

    const InscriptionTypeOperation2 = [
        'PREINSCRIPTION', 'INSCRIPTION'
    ];

    const InscriptionStatut = [
        'EN ATTENTE', 'VALIDEE'
    ];

    const InscriptionTypeOperation = [
        {inscripStatuCode: 'INSCRIPTION', inscripStatulibelle: 'INSCRIPTION'},
        {inscripStatuCode: 'PREINSCRIPTION', inscripStatulibelle: 'PREINSCRIPTION'}
    ];

    const onNiveauChange = (e) => {
        setniveau(e.value);
           }

    const onInscripStatutChange = (e) => {
        setSelectedInscrStatut(e.value);
            }



    const onAnneeColaireChange = (e) => {
        setanneeScolaire(e.value);
    }


    const onStatutChange = (e) => {
        setstatutEleve(e.value);
            }

    const onSexeChange = (e) => {
        setSelectedSexe(e.value);

    }

    const onClasseChange = (e) => {
        let _classe = { ...classe };
        _classe['classe'] = e.value;
        setclasse(_classe);
    }

    const statusBodyTemplate = (rowData) => {
        return <span className={`customer-badge status-${rowData.status}`}>{rowData.status}</span>;
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Nouveau" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />

                </div>
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <FileUpload mode="basic" accept="image/!*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" />
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        )
    }
    const IdInscriptionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">N°</span>
                {rowData.inscriptionsidEleve}
            </>
        );
    }


    const NomBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">nom</span>
                {rowData.nomEleve}
            </>
        );
    }

    const PreNomBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Prénom</span>
                {rowData.prenomEleve}
            </>
        );
    }

    const matriculeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">matricule</span>
                {rowData.matriculeEleve}
            </>
        );
    }



    const statutEleveBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Statut</span>
                {rowData.inscriptions_statut_eleve}
            </>
        );
    }
    const statutInscriptionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Etat</span>
                {rowData.inscriptions_status}
            </>
        );
    }

    const dateNaissanceBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Date naissance</span>
                {rowData.Date_naissanceEleve}
            </>
        );
    }


    const sexeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Sexe</span>
                {rowData.sexeEleve}
            </>
        );
    }




    const contactBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Cellulaire</span>
                {rowData.contactEleve}
            </>
        );
    }

    const statutInscripBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Cellulaire</span>
                {rowData.inscriptions_status}
            </>
        )
    }


    const typeInscriptionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Cellulaire</span>
                {rowData.inscriptions_type}
            </>
        )
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editProduct(rowData)} />

            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Liste des élèves</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Rechercher..." />
            </span>
        </div>
    );
    const typeItemTemplate = (option) => {
        return <span className={`customer-badge status-${option}`}>{option}</span>;
    }

    const statusItemTemplate = (option) => {
        return <span className={`customer-badge status-${option}`}>{option}</span>;
    }
    const typeOperationFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={InscriptionTypeOperation2} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={typeItemTemplate} placeholder="Sélectionnez le type" className="p-column-filter" showClear />;
    }

    const statutFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={InscriptionStatut} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={statusItemTemplate} placeholder="Sélectionnez le Statut" className="p-column-filter" showClear />;
    }
    const productDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
        </>
    );
    const deleteProductDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />
        </>
    );
    const deleteProductsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductsDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedProducts} />
        </>
    );
    const renderHeader2 = () => {
        return (
            <div className="flex justify-content-end">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue2} onChange={onGlobalFilterChange2} placeholder="Mots clés..." />
                </span>
            </div>
        )
    }
    const header2 = renderHeader2();
    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} ></Toolbar>

                    <div className="card">
                        <h5>Affecter un elève </h5>
                        <p>Affectation d'un élève dans une classe</p>
                        <DataTable value={inscriAvalides} paginator className="p-datatable-customers" rows={5} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                                   dataKey="inscriptionsidEleve" filters={filters2} filterDisplay="row"  responsiveLayout="scroll"
                                   globalFilterFields={['inscriptionsidEleve','nomEleve', 'matriculeEleve', 'prenomEleve','inscriptions_status']} header={header2} emptyMessage="Pas d'inscription en attente pour ce critère de choix!">
                            <Column selectionMode="multiple" headerStyle={{ width: '3rem'}}></Column>

                            <Column field="inscriptionsidEleve" header="N°" filterField="inscriptionsidEleve" body={IdInscriptionBodyTemplate} filter filterPlaceholder="Rechercher par le code " style={{ minWidth: '5' }} headerStyle={{ width: '5%', minWidth: '10rem' }} />

                            <Column header="Matricule" filterField="matriculeEleve" style={{ minWidth: '15rem' }} body={matriculeBodyTemplate} filter filterPlaceholder="Rechercher " />

                            <Column filterField="nomEleve" header="Nom" filterField="nomEleve" body={NomBodyTemplate} filter filterPlaceholder="Rechercher" style={{ minWidth: '18rem' }} />


                            <Column filterField="prenomEleve" header="Prenom" filterField="prenomEleve" body={PreNomBodyTemplate} filter filterPlaceholder="Rechercher" style={{ minWidth: '18rem' }} />

                            <Column field="inscriptions_type" header="Type Inscription"  body={typeInscriptionBodyTemplate} headerStyle={{ width: '14%', minWidth: '15rem' }}
                                    showFilterMenu={false} filterMenuStyle={{ width: '14rem' } } style={{ minWidth: '12rem' }}filter filterElement={typeOperationFilterTemplate}
                            ></Column>

                            <Column field="inscriptions_status" header="Statut Inscription"  body={statutInscriptionBodyTemplate} headerStyle={{ width: '14%', minWidth: '18rem' }}
                                    showFilterMenu={false} filterMenuStyle={{ width: '14rem' } } style={{ minWidth: '15rem' }}
                            ></Column>

                            <Column body={actionBodyTemplate}></Column>
                        </DataTable>
                    </div>

                    <Dialog visible={productDialog} style={{ width: '650px' }} header="Details eleve" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                        <div className="formgrid grid">
                            <div className="field col">
                                 <InputText value={anneeScolaire.annee_scolaire_libelle} disabled />
                            </div>
                            <div className="field col">
                                <InputText value={typeOperation1} disabled />

                            </div>

                        </div>


                                <div className="field">
                                <InputText value={inscriAvalide.matriculeEleve} disabled />
                                    </div>
                            <div className="field">
                                <InputText value={inscriAvalide.nomEleve} disabled />
                            </div>

                            <div className="field">
                                <InputText value={inscriAvalide.prenomEleve} disabled />
                            </div>


                        <div className="formgrid grid">
                            <div className="field col">
                                <Dropdown value={classe} options={classes} onChange={(e) => setclasse(e.value)} optionLabel="classelibelle" placeholder="Selectionnez la classe" required autoFocus className={classNames({ 'p-invalid': submitted && !classe })} />
                                {submitted && !classe && <small className="p-invalid">classe est nécessaire.</small>}
                                <div className="text-900 font-medium text-xl">{countEleveParClasse} elève(s) dans la classe</div>
                            </div>

                        </div>


                    </Dialog>

                    <Dialog visible={deleteProductDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {eleve && <span>Voulez vous vraiment supprimer <b>{eleve.elevematricule_national}</b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteProductsDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductsDialogFooter} onHide={hideDeleteProductsDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {eleve && <span>Voulez vous vraiment supprimer les elements selectionnés?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(AttribuerClasse, comparisonFn);
