import axios from 'axios';

// const this.getHost() ="https://pouls-scolaire.com/api/";
import { BaseService } from '../BaseService';
export class EleveService extends BaseService{


    listInscriptionAvalider(tenant,statut,processus) {
        return axios.get(this.getHost()+'inscriptions/en-attente/'+tenant+'/'+statut+'/'+processus).then(res => res.data);
    }

    listClasseParEcole(tenant) {
        return axios.get(this.getHost()+'classe/ecole/'+tenant).then(res => res.data);
    }

   nombreEleveParClasse(tenant,classe) {
        return axios.get(this.getHost()+'eleve-classe/count/'+tenant+'/'+classe).then(res => res.data);
    }

    listEleveClasse(identifAnnee) {
        return axios.get(this.getHost()+'eleve-classe/eleve-inscrit-classe/'+identifAnnee).then(res => res.data);
    }

    listEleveInscrit(idEcole,idAnnee,myStatus,typeInscription) {
        return axios.get(this.getHost()+'inscriptions/statuts/'+idEcole+'/'+idAnnee+'/'+myStatus+'/'+typeInscription).then(res => res.data);
    }

    listTouslesEleveInscrit(idEcole,idAnnee,typeInscription) {
        return axios.get(this.getHost()+'inscriptions/allInscription/'+idEcole+'/'+idAnnee+'/'+typeInscription).then(res => res.data);
    }
    listDesElevesInscritsParClasse(idEcole,idAnnee) {
        return axios.get(this.getHost()+'inscriptions/list-eleve-classe/'+idEcole+'/'+idAnnee).then(res => res.data);
    }

    chargerPhoto = async(photo,inscriptionId) =>{
        return await axios.put(this.getHost()+'inscriptions/charger-photo/'+inscriptionId,photo).then(res => res.data);
    }

    supprimerPhoto = async(inscriptionId) =>{
        return await axios.delete(this.getHost()+'inscriptions/delete-photo/'+inscriptionId).then(res => res.data);
    }


    chargerParam(photo) {
        return axios.put(this.getHost()+'inscriptions/charger-image-paramatre/',photo).then(res => res.data);
    }

    validerInscription(inscriptionId) {
        return axios.put(this.getHost()+'inscriptions/valider-inscription/'+inscriptionId).then(res => res.data);
    }

    displayPhoto(inscriptionId) {
        return axios.get(this.getHost()+'inscriptions/get-image-by-inscription/'+inscriptionId).then(res => res.data);
    }

    verifierMiseAjour(inscriptionDto) {
        return axios.get(this.getHost()+'inscriptions/verifier-infos-a-jour/'+inscriptionDto).then(res => res.data);
    }
    miseAjourInfosComplementaire(inscriptionDto) {
        return axios.put(this.getHost()+'inscriptions/infos-complementaires/',inscriptionDto).then(res => res.data);
    }
    importerIEtCreerEleves(idEcole,idAnneeScolaire,typeOperation,idBranche,lisimscrip) {
        return axios.post(this.getHost()+'eleve/importer-eleve/'+idEcole+'/'+idAnneeScolaire+'/'+ typeOperation +'/'+idBranche,lisimscrip)
            .then(res => res.data);
    }

    importerIEtUpdateEleves(idEcole,idAnneeScolaire,typeOperation,idBranche,lisimscrip) {
        return axios.put(this.getHost()+'eleve/importer-eleve/'+idEcole+'/'+idAnneeScolaire+'/'+ typeOperation +'/'+idBranche,lisimscrip)
            .then(res => res.data);
    }



    attribuerClasse(ideleve,idClasse,anneeScoliare,inscriptionId,tenant) {
        return axios.post(this.getHost()+'eleve-classe/'+ideleve+'/'+idClasse+'/'+ anneeScoliare+'/'+inscriptionId+'/'+tenant)
            .then(res => res.data);
    }


    creerEleve(idAnneeScolaire,idEcole,statutEleve,typeOperation,idBranche,Eleve) {
        return axios.post(this.getHost()+'eleve/'+idAnneeScolaire+'/'+idEcole+'/'+statutEleve+'/'+typeOperation+'/'+idBranche,Eleve)
            .then(res => res.data);
    }

    modifierEleve(idEleve,Eleve) {
        return axios.put(this.getHost()+'eleve/'+idEleve,Eleve).then(res => res.data);
    }

    SupprimerEleve(idEleve,Eleve) {
        return axios.delete(this.getHost()+'eleve/'+idEleve,Eleve).then(res => res.data);
    }

    creerInscription(IdentificationsEleve) {
        return axios.post(this.getHost()+'inscriptions/',IdentificationsEleve).then(res => res.data);
    }

    AnneeScolaire(codeTenant) {
        return axios.get(this.getHost()+'annee/get-main-annee-by-ecole/'+codeTenant).then(res => res.data);
    }

    listStatutEleve(codeTenant) {
        return axios.get(this.getHost()+'statutEleve/ecole/'+codeTenant).then(res => res.data);
    }

    listNiveau(codeTenant) {
        return axios.get(this.getHost()+'niveau/ecole/'+codeTenant).then(res => res.data);
    }

    listBranche() {
        return axios.get(this.getHost()+'branche/list').then(res => res.data);
    }

    listBrancheByEcole(ecole) {
        return axios.get(this.getHost()+'branche/get-by-niveau-enseignement?ecole='+ecole).then(res => res.data);
    }


    listEleve() {
        return axios.get(this.getHost()+'eleve/').then(res => res.data);
    }


}
