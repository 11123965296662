import React, { useState, useEffect, useRef } from 'react';
// eslint-disable-next-line
// import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { useParams } from 'react-router-dom';
import { Button } from 'primereact/button';

import { Dropdown } from 'primereact/dropdown';
import { Toolbar } from 'primereact/toolbar';
// import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { ClasseEleveService } from '../service/ClasseEleveService';
import { InputNumber } from 'primereact/inputnumber';
import { EvaluationService } from '../service/EvaluationService';
import { ClasseService } from '../service/ClasseService';
import { BrancheService } from '../service/BrancheService';
import { Dialog } from 'primereact/dialog';
import { PickList } from 'primereact/picklist';
import '../assets/demo/PickListDemo.css';
import { ClasseAnneeService } from '../service/ClasseAnneeService';
import { InscriptionService } from '../service/InscriptionService';



const ClasseEleve = () => {
    let EmptyBranche = { id: null };
    let EmptyClasse = {
        id: null,
        libelle: null
    };
    let EmptyInscription = { id: null }
    let emptyClasseEleve = {
        id: null,
        inscription: EmptyInscription,
        classe: EmptyClasse
    }

    let EmptyEleve = {
        id: null,
        matricule: "",
        nom: "",
        prenom: ""
    }

    const toast = useRef(null);

    const [classeEleves, setClasseEleves] = useState(null);
    const [branche, setBranche] = useState(EmptyBranche);
    const [branches, setBranches] = useState(null);
    const [classes, setClasses] = useState(null);
    const [classe, setClasse] = useState(EmptyClasse);
    const [showClasseDisplayed, setShowClasseDisplayed] = useState(null);
    const [showDeleteDialog, setShowDeleteDialog] = useState(null);
    const [showSaveDialog, setShowSaveDialog] = useState(null);
    const [disabled, setDisabled] = useState(true);
    const [disabledAddbtn, setDisabledAddbtn] = useState(true);
    const [hiddenTable, setHiddenTable] = useState("hidden");
    const [globalFilter, setGlobalFilter] = useState(null);
    const [openDialog, setOpenDialog] = useState(null);
    const [classeEleveToDelete, setClasseEleveToDelete] = useState(emptyClasseEleve);
    const [classeSelected, setClasseSelected] = useState(EmptyClasse);
    const [eleveToPullOff, setEleveToPullOff] = useState(EmptyEleve);
    const [effectif, setEffectif] = useState(0);
    const [effectifTemp, setEffectifTemp] = useState(0);
    const [effectifMax, setEffectifMax] = useState(0);
    const [disabledSaveBtn, setDisabledSaveBtn] = useState(false);

    const [niveauEnseign, setNiveauEnseign]  = useState(sessionStorage.getItem('niveauEnseignementId'));

    const [source, setSource] = useState([]);
    const [target, setTarget] = useState([]);


    const dt = useRef(null);



    useEffect(() => {

        const brancheService = new BrancheService();
        brancheService.getByNiveauEnseigment(sessionStorage.getItem('EcoleID')).then(data => setBranches(data));

    }, []);

    useEffect(() => {

        if(effectifTemp>effectifMax){
            console.log('effectif superieur a l effectif autorisé');
            setDisabledSaveBtn(true);
        } else {
            setDisabledSaveBtn(false);
        }

        if(effectifTemp == effectif){
            setDisabledSaveBtn(true);
        }

    }, [effectifTemp,effectif]);

    const onChange = (event) => {
        setEffectifTemp(effectif + event.target.length);

        setSource(event.source);
        setTarget(event.target);
    }

    const itemTemplate = (item) => {
        return (
            <div>
                <div className="m-0 border-bottom-1 p-2 border-blue-200 text-base">
                    {item.eleve.matricule} - <span className="font-semibold">{item.eleve.nom} {item.eleve.prenom}</span>
                </div>
            </div>
        );
    }
    const getActeurLabel = () => {
        return niveauEnseign > 2 ? 'Etudiant' : 'Elève';
    };

    const getActeurLabels = () => {
        return niveauEnseign > 2 ? 'Etudiants' : 'Elèves';
    };
    const search = () => {
        setHiddenTable("");
        setShowClasseDisplayed(classe.libelle);
        setEffectifMax(classe.effectif);
        setDisabledAddbtn(false);
        const classeEleveService = new ClasseEleveService();
        classeEleveService.getByClasseAndAnnee(classe.id, sessionStorage.getItem('AnneEncours')).then(data => {setClasseEleves(data); console.log(data)});
        classeEleveService.getEffectifByClasseAndAnnee(classe.id, sessionStorage.getItem('AnneEncours')).then(data => {setEffectif(data); setEffectifTemp(data);});
    }

    const headerTitle = (
        <div className="card flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h3 className="m-0"> Affecter un elève dans la classe de {classe.libelle} </h3>

        </div>
    );

    const save = () => {
        const classeEleveService = new ClasseEleveService();
        const _classeEleves = [...classeEleves];
        console.log('---target');
        console.log(target);
        classeEleveService.handleSave(classe, target).then(resp => {
            console.log('---response');
            console.log(resp);
            _classeEleves.push(...resp);
            setClasseEleves(_classeEleves);
            setEffectif(effectifTemp);
            setShowSaveDialog(false);
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Opération bien effectuée', life: 10000 }
            )
            hideDialog();
        }).catch((error) => {
            if (error.response) {
                console.log('erreur response- vie ecole- notes - save' + error.response.status);
            } else if (error.request) {
                console.log('erreur request- vie ecole' + error.request);
            } else {
                console.log('Error autres', error.message);
            }
            toast.current.show({ severity: 'error', summary: 'erreur', detail: 'Une erreur s est produite', life: 10000 });
        }
        );
    }



    const hideDialog = () => {
        setOpenDialog(false);
    }
    const deleteDialog = (ce) => {
        console.log(ce.inscription.eleve);
        setClasseEleveToDelete(ce);
        setClasseSelected(ce.classe);
        setEleveToPullOff(ce.inscription.eleve);
        setShowDeleteDialog(true);
    }

    const saveDialog = () => {
        // setClasseEleveToDelete(ce);
        // setClasseSelected(ce.classe);
        // setEleveToPullOff(ce.inscription.eleve);
        setShowSaveDialog(true);
    }

    const onChangeBranche = (e) => {
        const val = (e.target && e.target.value) || '';
        // console.log(val);
        let classeService = new ClasseService();
        let _branche = { ...branche }
        classeService.getVisibleByBranche(val, sessionStorage.getItem('EcoleID')).then(data => setClasses(data));
        _branche.id = val;

        setDisabled(true);
        setDisabledAddbtn(true);
        // setVisibleTable(false);
        setBranche(_branche);

    }

    const onChangeClasse = (e) => {
        const val = (e.target && e.target.value) || '';
        let classeService = new ClasseService();
        classeService.getById(val).then(data => {
            setClasse(data);
            if (showClasseDisplayed != '' && data.libelle != '' && data.libelle === showClasseDisplayed) {
                setDisabledAddbtn(false);
            } else {
                setDisabledAddbtn(true);
            }
        });
        if (val != null) {
            setDisabled(false);
            setDisabledAddbtn(false);
        } else {
            setDisabled(true);
            setDisabledAddbtn(true);
        }
    }



    const matriculeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Niveau</span>
                {rowData.inscription.eleve == null ? '' : rowData.inscription.eleve.matricule}
            </>
        );
    }

    const nomPrenomBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Eleve</span>
                {rowData.inscription.eleve == null ? '' : rowData.inscription.eleve.nom + ' ' + rowData.inscription.eleve.prenom}
            </>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div>
                {/* <InputNumber showButtons='true' size={2} min={0} max={evaluation == null ? 0 : evaluation.noteSur} value={rowData.note} onChange={(e) => updateNote(e, rowData)} /> */}
                <Button icon="pi pi-sign-out" className="p-button-rounded p-button-danger" tooltip='Retirer élève' aria-label="Cancel" onClick={() => { deleteDialog(rowData) }} />
            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h3 className="m-0 text-400"> <span className="underline">Classe</span> : {showClasseDisplayed}  <span className="m-0 text-400 text-lg

">( {effectif} élève(s) /{effectifMax})</span></h3>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Recherche..." />
                <Button disabled={disabledAddbtn} label="Ajouter" icon="pi pi-plus" className="p-button-success ml-2 mr-2" onClick={(e) => openForm(e)} />
            </span>
        </div>
    );

    const classeEleveDialogFooter = (
        <>
            <Button label="Annuler" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Enregistrer" icon="pi pi-check" className="p-button-text" disabled={disabledSaveBtn} onClick={saveDialog} />
        </>
    );

    const hideDeleteDialog = () => {
        setShowDeleteDialog(false);
    }
    const hideSaveDialog = () => {
        setShowSaveDialog(false);
    }

    const deleteClasseEleve = () => {
        const classeEleveService = new ClasseEleveService();
        classeEleveService.delete(classeEleveToDelete.id).then(res =>{
            let _classeEleves = classeEleves.filter(elmt => elmt.id !== classeEleveToDelete.id);
            setClasseEleves(_classeEleves);
            setEffectif(effectif-1);
            toast.current.show({ severity: 'success', summary: 'Succès', detail: 'Eleve retiré', life: 10000 });
        }
        ).catch((error) => {
            if (error.response) {
                console.log('erreur response- Pouls Scolaire ' + error.response.status);
            } else if (error.request) {
                console.log('erreur request- Pouls Scolaire ' + error.request);
            } else {
                console.log('Error autres', error.message);
            }
            console.log(error.response.data);
            toast.current.show({ severity: 'error', summary: 'Erreur', detail:  error.response.data, life: 10000 });
        });
        setShowDeleteDialog(false);
    }

    const openForm = () => {

        const inscriptionService = new InscriptionService();
        setSource([]);
        setTarget([]);
        // console.log(sessionStorage.getItem("AnneEncours")+' '+ branche.id+' '+ "VALIDEE")
        inscriptionService.getByElevesToAttribClasse(sessionStorage.getItem("AnneEncours"), branche.id, "VALIDEE").then(data => setSource(data));
        setOpenDialog(true);
    }
    const deleteDialogFooter = (
        <>
            <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={hideDeleteDialog} />
            <Button label="Oui" icon="pi pi-check" className="p-button-text" onClick={deleteClasseEleve} />
        </>
    );

    const saveDialogFooter = (
        <>
            <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={hideSaveDialog} />
            <Button label="Oui" icon="pi pi-check" className="p-button-text" onClick={save} />
        </>
    );

    const targetHeader = (
        <>
            <div>
                Elève(s) sélectionné(s)  <span> [<i>Estim.:</i> {effectifTemp}/{effectifMax} ]</span>
            </div>
        </>
    );

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <div className="col-12">
                        <div>
                            <div className="col-12">
                                <label><h3><b>Liste des {getActeurLabels()} par classe</b></h3></label>
                            </div>
                            <div className="card formgroup-inline">
                                <div className="col-3">
                                    <h5>Branche <span className="text-pink-500">*</span></h5>
                                    <Dropdown id='branche' value={branche.id} filter  options={branches} onChange={(e) => onChangeBranche(e)} optionValue="id" optionLabel="libelle" placeholder="Selectionner une branche" />
                                </div>
                                <div className="col-3">
                                    <h5>Classe <span className="text-pink-500">*</span></h5>
                                    <Dropdown id='classe' value={classe.id} options={classes} onChange={(e) => onChangeClasse(e)} optionValue="id" optionLabel="libelle" placeholder="Selectionner une classe" />
                                </div>
                                <div className="relative h-6rem col-6">
                                    <div className="absolute bottom-0 left-0">
                                        <Button label="Afficher" disabled={disabled} icon="pi pi-search" className="p-button-success mr-2" onClick={(e) => search(e)} />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <Toast ref={toast} />
                    <div className={hiddenTable}>
                        <DataTable ref={dt} value={classeEleves}
                            dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className=" datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="affichage de {first} à {last} sur {totalRecords} notes"
                            globalFilter={globalFilter} emptyMessage="Aucun enregistrement trouvé." header={header} responsiveLayout="scroll">
                            <Column header="Matricule" body={matriculeBodyTemplate} headerStyle={{ Width: '2rem' }}></Column>
                            <Column header="Nom &amp; Prenom" body={nomPrenomBodyTemplate} headerStyle={{ minWidth: '5rem' }}></Column>
                            <Column header="Action" body={actionBodyTemplate} headerStyle={{ Width: '1rem' }}></Column>
                        </DataTable>
                    </div>

                    <Dialog visible={openDialog} style={{ width: '1200px' }} header={headerTitle} modal className="p-fluid" footer={classeEleveDialogFooter} onHide={hideDialog}>
                        <div className="picklist-demo">
                            <div className="card">
                                <PickList source={source} target={target} itemTemplate={itemTemplate} sourceHeader="Elèves sans affectation de classe" targetHeader={targetHeader}
                                    sourceStyle={{ height: '342px' }} targetStyle={{ height: '342px' }} onChange={onChange}
                                    filterBy="matricule" showSourceFilter="true" sourceFilterPlaceholder="Rechercher par matricule" targetFilterPlaceholder="Rechercher par matricule" />
                            </div>
                        </div>
                    </Dialog>

                    <Dialog visible={showDeleteDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteDialogFooter} onHide={hideDeleteDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {classe && <span>Etes vous sûr de vouloir retirer l&apos;élève de matricule <b>[{eleveToPullOff.matricule}]</b> de la classe <b>[{showClasseDisplayed}]</b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={showSaveDialog} style={{ width: '450px' }} header="Confirmation" modal footer={saveDialogFooter} onHide={hideSaveDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {classe && <span>Voulez-vous ajouter à la classe <b>[{showClasseDisplayed}]</b>?</span>}
                        </div>
                    </Dialog>

                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(ClasseEleve, comparisonFn);
