import axios from 'axios';
import { BaseService } from './BaseService';

const ecoleId = sessionStorage.getItem('EcoleID');

export class ClasseEleveService extends BaseService {


    getList(){
        return axios.get(this.getHost()+'classe-eleve/list').then(res => res.data);
    }

    save(classeAnnee){
        axios.post(this.getHost()+'classe-eleve/save',classeAnnee);
    }

    async handleSave(classe, inscriptions){
       return axios.post(this.getHost()+'classe-eleve/handle-save/'+classe.id, inscriptions).then(response => response.data);
    }

    async getById(id){
        return axios.get(this.getHost()+'classe-eleve/get/'+id).then(response => response.data);
    }

    async getByClasseAndAnnee(classe,annee){
        console.log(classe+' '+annee);
        return axios.get(this.getHost()+'classe-eleve/retrieve-by-classe/'+classe+'/'+annee).then(response => response.data);
    }

    async getEffectifByClasseAndAnnee(classe,annee){
        return axios.get(this.getHost()+'classe-eleve/get-count-by-classe/'+classe+'/'+annee).then(response => response.data);
    }

    async getByAnnee(annee){
        return axios.get(this.getHost()+'classe-eleve/retrieve-by-annee/'+annee).then(response => response.data);
    }

    async getByBrancheAndAnnee(branche,annee){
        return axios.get(this.getHost()+'classe-eleve/retrieve-by-branch/'+branche+'/'+annee).then(response => response.data);
    }

    async update(classeAnnee){
       return axios.post(this.getHost()+'classe-eleve/update',classeAnnee).then(response => response.data);
    }

    async updateAndDisplay(classeAnnee){
        return axios.post(this.getHost()+'classe-eleve/update-display',classeAnnee).then(res => res.data);
     }
     
     async saveAndDisplay(classeAnnee){
        return axios.post(this.getHost()+'classe-eleve/saveAndDisplay',classeAnnee).then(res => res.data);        
    }


    delete(id){
        return axios.delete(this.getHost()+'classe-eleve/delete/'+id);
    }
}
 