import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { useParams } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';

import { Dropdown } from 'primereact/dropdown';
import { Toolbar } from 'primereact/toolbar';
// import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { NoteService } from '../service/NoteService';
import { InputNumber } from 'primereact/inputnumber';
import { Messages } from 'primereact/messages';
import { EvaluationService } from '../service/EvaluationService';
import { ClasseService } from '../service/ClasseService';
import { PeriodeService } from '../service/PeriodeService';
import { ClasseAnneeService } from '../service/ClasseAnneeService';
import { BulletinService } from '../service/BulletinService';
import { ClasseMatiereService } from '../service/ClasseMatiereService';
import { PersonnelMatiereClasseService } from '../service/PersonnelMatiereClasseService';
import { ProgressSpinner } from 'primereact/progressspinner';
import { ClasseMatierePeriodeService } from '../service/ClasseMatierePeriodeService';
import { AbsenceService } from '../service/AbsenceService';


const MoyennesParent = () => {

    let emptyClasse = {
        id: null,
        libelle: ''
    }

    let emptyClasseAnnee = {
        id: null,
        classe: emptyClasse
    }

    let emptyPeriode = {
        id: null
    }


    const toast = useRef(null);

    const [loading, setLoading] = useState(true);

    const [globalFilter, setGlobalFilter] = useState(null);

    const btn = useRef(null);


    const [expandedRows, setExpandedRows] = useState(null);


    // const [classeAnnees, setClasseAnnees] = useState(null);

    const [classes, setClasses] = useState([]);

    const [classe, setClasse] = useState(emptyClasse);

    // const [classeAnnee, setClasseAnnee] = useState(emptyClasseAnnee);


    const [periode, setPeriode] = useState(emptyPeriode);

    const [periodes, setPeriodes] = useState(null);
    const [matricule, setmatricule] = useState('');

    const [moyenneEleves, setMoyenneEleves] = useState(null);

    const [submitted, setSubmitted] = useState(null);

    const [saveFlag, setSaveFlag] = useState(true);

    const [isClassedTab, setIsClassedTab] = useState([]);

    const [classedTabToSave, setClasseTabToSave] = useState([]);

    const userId = sessionStorage.getItem('CandidatId');

    const annee = sessionStorage.getItem('AnneEncours');

    const [isSearching, setIsSearching] = useState(false);

    const [isNotSpinning, setIsNotSpinning] = useState('hidden');

    const [hiddenFlatUpdate, setHiddenFlatUpdate] = useState(true);

    const [searchBtnLbl, setSearchBtnLbl] = useState("Rechercher");

    const msgs = useRef(null);

    useEffect(() => {

        const classeService = new ClasseService();
        classeService.getListByEcole(sessionStorage.getItem("EcoleID")).then(data => setClasses(data));
         const periodeService = new PeriodeService();
         setLoading(false);
         periodeService.getByPeriodicite(sessionStorage.getItem('periodiciteId')).then(data => setPeriodes(data));
    }, []);

    const onSelectClasseChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        // console.log(val);
        let _classe = { ...classe };
        // console.log(_classe);
        if (name !== null)
            _classe[`${name}`].id = val;
        else
            _classe.id = val;

        setClasse(_classe);
    }

    const onSelectPeriodeChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _periode = { ...periode };
        if (name !== null)
            _periode[`${name}`].id = val;
        else
            _periode.id = val;
        setPeriode(_periode);
    }

    const setCheckedIsClassed = (rowData, val) => {
        // console.log(val);
        let _isClassedTab = [...isClassedTab];
        let _rankObj = { ..._isClassedTab[rowData.getIndex] };
        let classeMatierePeriodeService = new ClasseMatierePeriodeService();
        val == false ? _rankObj.isClassed = 'N' : _rankObj.isClassed = 'O';
        //console.log(_isClassedTab);
        // _isClassedTab[rowData.getIndex] = _rankObj;
        // display update message

        // console.log(_rankObj);
        //handleClassedTab(_rankObj);

        //Effectuer l'enregistrement après chaque action de l'état de classement de l'élève
        classeMatierePeriodeService.marquageClassement(_rankObj).then(res => {
            console.log(res);
            // _rankObj.isClassed = res;
            _isClassedTab[rowData.getIndex] = _rankObj;
            setIsClassedTab(_isClassedTab);
            setSaveFlag(true);
            setSearchBtnLbl("Actualiser");
            setHiddenFlatUpdate(false);
        }).catch((error) => {
            console.log("except");
            console.log(error.response.data);
            toast.current.show({ severity: 'error', summary: 'erreur', detail: error.response.data, life: 5000 });
        })
    }


    const setAbsence = (rowData, val, input, e) => {

        const inputElmt = document.getElementById(e.originalEvent.target.id);
        console.log(inputElmt);
        let _isClassedTab = [...isClassedTab];
        let _absObj = { ..._isClassedTab[rowData.getIndex] };
        let absenceService = new AbsenceService();
        if (input === 'J')
            _absObj.absJustifiee = val;
        if (input === 'NJ')
            _absObj.absNonJustifiee = val;

        // console.log(_absObj);
        //Effectuer l'enregistrement après chaque action de l'état de classement de l'élève
        absenceService.saveHandle([_absObj]).then(res => {
            console.log(res);
            e.originalEvent.target.classList.remove('p-invalid');
            // _rankObj.isClassed = res;
            _isClassedTab[rowData.getIndex] = _absObj;
            setIsClassedTab(_isClassedTab);
            // setSaveFlag(true);
            // console.log(_isClassedTab);
            // setHiddenFlatUpdate(false);
        }).catch((error) => {
            e.originalEvent.target.classList.add('p-invalid');
            console.log("except");
            console.log(error.response.data);
            toast.current.show({ severity: 'error', summary: 'erreur', detail: error.response.data, life: 5000 });
        })
    }

    // utiliser cette méthode dans le cas où on doit effectuer les modifications en base après avoir effectuer l'ensemble
    // de ces modifications sur l'ecran
    const handleClassedTab = (objClassed) => {
        let _list = [...classedTabToSave];
        let index = -1;
        for (let i = 0; i < classedTabToSave.length; i++) {
            // annee, classe, periode sont ignorée car les dtos sont dejà spécifiques
            if (objClassed.eleve.id == classedTabToSave[i].eleve.id) {
                console.log('index = ' + i);
                index = i;
                break;
            }
        }

        if (index === -1) {
            _list.push(objClassed);
        } else {
            _list[index] = objClassed;
            // console.log(_list[index].isClassed);
        }
        setClasseTabToSave(_list);
        // console.log(classedTabToSave);
        // console.log(objClassed);
    }

    const save = () => {
        setSaveFlag(true);
        const bulletinService = new BulletinService;
        bulletinService.save(sessionStorage.getItem('AnneEncours'), periode.id, classe.id).then((resp) => {
            msgs.current.clear();
            setSaveFlag(false);
            toast.current.show({ severity: 'success', summary: 'Sauvegarde des bulletins', detail: resp, life: 25000 });
        }).catch((error) => {
            setSaveFlag(false);
            let detailTxt = '';
            msgs.current.clear();
            if (error.response) {
                console.log('erreur response- pouls-scolaire' + error.response.status);
                detailTxt = error.response.data;
            } else if (error.request) {
                console.log('erreur request- pouls-scolaire' + error.request);
                console.log(error.request);
                detailTxt = 'Connexion refusée ou interrompue! veuillez vérifier votre connexion internet ou votre parefeu!';
            } else {
                console.log('Error autres', error.message);
                detailTxt = error.message;
            }
            msgs.current.show([
                { severity: 'error', summary: 'Erreur : ', detail: detailTxt, sticky: true }
            ]);
            console.log(detailTxt);
            toast.current.show({ severity: 'error', summary: 'erreur', detail: detailTxt, life: 10000 });
        }
        );
    }

    // const expandAll = () => {
    //     let _expandedRows = {};
    //     // products.forEach(p => _expandedRows[`${p.id}`] = true);
    //     setExpandedRows(_expandedRows);
    // }
    // const collapseAll = () => {
    //     setExpandedRows(null);
    // }

    const rowExpansionTemplate = (data) => {
        return (
            <div className="orders-subtable">

                <DataTable value={data.notesMatiereMap} responsiveLayout="scroll">
                    <Column field="key.libelle" body={matiereBodyTemplate} header="Matière"></Column>
                    <Column field="value" header="Notes" body={noteBodyTemplate}></Column>
                    <Column field="key.coef" header="Coef." ></Column>
                    <Column field="key.moyenne" header="Moyenne" ></Column>
                    <Column field="key.rang" header="Rang" body={rangBodyTemplate} ></Column>
                    <Column field="key.appreciation" header="Appreciation" body={apprBodyTemplate}></Column>

                </DataTable>
            </div>
        );
    }
    const noteBodyTemplate = (rowData) => {
        return (
            <div className='grid'>
                {rowData.value.map(note => {
                    if (note.evaluation.pec === 1) {
                        return (

                            <div className='col-2 md:col-1 lg:col-2 mx-1' style={{ minWidth: '40px' }} key={note.id}>
                                {note.note}/{note.evaluation.noteSur}
                            </div>

                        );
                    }
                })}
            </div>
        );
    }
    const matiereBodyTemplate = (rowData) => {
        console.log(rowData);
        return (
            <div>
                {rowData?.key?.pec == 1 ? <i className='pi pi-circle-fill'></i> : <i className='pi pi-circle'></i>} {rowData?.key?.libelle} {rowData?.key?.matiereParent == null ? '' : '( ' + rowData?.key?.matiereParent.libelle + ' )'} {rowData?.key?.bonus == 1 ? <i className='p-badge p-badge-success'><b> B </b></i> : ''}
            </div>
        );
    }
    const rangBodyTemplate = (rowData) => {
        return (
            <div>

                {rowData?.key?.eleveMatiereIsClassed == 'N' ? 'NC' : (rowData.key.rang == 1 ? rowData.key.rang + ' er' : rowData.key.rang + ' ème')}
            </div>
        )
    }

    const apprBodyTemplate = (rowData) => {
        return (
            <div>
                <span className={`moyenne-badge status-${getAppreciationStatus(rowData.key.appreciation)}`}>{rowData.key.appreciation}</span>
            </div>
        )
    }

    const getIsRanked = (rowData) => {
        // console.log(isClassedTab);
        for (let index = 0; index < isClassedTab.length; index++) {
            if (rowData.eleve.id == isClassedTab[index].eleve.id) {
                // console.log (isRankedTab[index]);
                rowData.getIndex = index;
                return isClassedTab[index].isClassed;
            }
        }
    }

    const search = () => {
        setSubmitted(true);
        let _isClassedTab = [];
        if (periode.id) {
            setIsSearching(true);
            setIsNotSpinning('');

            let noteService = new NoteService();
            // console.log('SEARCH');
            // console.log(sessionStorage.getItem('AnneEncours'));
            noteService.getMoyennesAndNotesMatricule(matricule, annee, periode.id).then(res => {
                msgs.current.clear();
                // setIsClassedTab([]);
                setMoyenneEleves(res);
                for (let index = 0; index < res.length; index++) {
                    let isClassedObj = {
                        eleve: res[index].eleve,
                        periode: res[index].periode,
                        classe: res[index].classe,
                        annee: res[index].annee,
                        isClassed: res[index].isClassed,
                        absJust: res[index].absJustifiee,
                        absNonJust: res[index].absNonJustifiee
                    }
                    _isClassedTab.push(isClassedObj);
                }
                // console.log(res);
                setIsClassedTab(_isClassedTab);
                // console.log(isRankedTab);
                setSearchBtnLbl("Rechercher");
                setLoading(false);
                if (res == null) {
                    toast.current.show({ severity: 'warn', summary: 'Erreur lors du processus', detail: '', life: 10000 });
                } else if (res.length == 0) {
                    setSaveFlag(true);
                    toast.current.show({ severity: 'warn', summary: 'Aucune donnée trouvée', detail: '', life: 10000 });
                } else
                    setSaveFlag(false);

                setIsSearching(false);
                setIsNotSpinning('hidden');
                setHiddenFlatUpdate(true);
            }
            ).catch((error) => {
                let detailTxt = '';
                msgs.current.clear();
                if (error.response) {
                    console.log('erreur response- pouls-scolaire' + error.response.status);
                    detailTxt = error.response.data?.message;
                } else if (error.request) {
                    console.log('erreur request- pouls-scolaire' + error.request);
                    console.log(error.request);
                    detailTxt = 'Connexion refusée ou interrompue! veuillez vérifier votre connexion internet ou votre parefeu!';
                } else {
                    console.log('Error autres', error.message);
                    detailTxt = error.message;
                }
                msgs.current.show([
                    { severity: 'error', summary: 'Erreur : ', detail: detailTxt, sticky: true }
                ]);
                toast.current.show({ severity: 'error', summary: 'Erreur', detail: detailTxt, life: 10000 })
                setIsSearching(false);
                setIsNotSpinning('hidden');
            });
        }
    }
    // Le css de la gestion des badge est dans le fichier src/assets/demo/Badge.scss    --- enjoy you !!!
    const getAppreciationStatus = (appreciation) => {
        let appreciationStatus = appreciation.split(" ");
        let statusBuilder = '';

        for (let index = 0; index < appreciationStatus.length; index++) {
            if (index == 0)
                statusBuilder = appreciationStatus[index].substring(0, 2).trim();
            else {
                statusBuilder = statusBuilder + appreciationStatus[index].substring(0, 1).trim();
                break;
            }
        }

        // console.log(appreciation);

        // console.log(statusBuilder);
        return statusBuilder;
    }

    const eleveBodyTemplate = (rowData) => {
        return (
            <div className='grid'>
                <div className='col-12 md:col-1 lg:col-1'>
                    <img src={`images/profil/${rowData.eleve.sexe == 'MASCULIN' ? 'image-profile.png' : 'image-profile-femme.png'}`} alt='ma photo' className="shadow-2" width={100} />
                </div>
                <div className='grid col-10 md:col-10 lg:col-10'>
                    <div className='col-6 md:col-5 lg:col-5 mx-4'>
                        <div><b>Matricule : </b>{rowData.eleve.matricule}</div>
                    </div>
                    <div className='col-3 md:col-3 lg:col-3 '>
                        <div><b>Sexe : </b>Masculin</div>
                    </div>
                    <div className='col-2 md:col-2 lg:col-2 ml-4'>
                        <label className="p-checkbox-label"><b>Est classé : </b> <span>{getIsRanked(rowData) !== 'N' ? 'Oui' : 'Non'} </span></label>
                        <Checkbox onChange={e => setCheckedIsClassed(rowData, e.checked)} checked={getIsRanked(rowData) !== 'N'} disabled={true} ></Checkbox>
                    </div>
                    <div className='col-1 md:col-1 lg:col-1 mx-1'>

                    </div>
                    <div className='col-12 md:col-5 lg:col-5 mx-4'>
                        <div><b>Nom et Prénoms : </b>{rowData.eleve.nom} {rowData.eleve.prenom}</div>
                    </div>
                    <div className='col-12 md:col-2 lg:col-2 '>
                        <div><b>Moyenne Générale : </b>{rowData.moyenne}</div>
                    </div>
                    <div className='col-12 md:col-2 lg:col-2 ml-4'>
                        <div><span className={`first-badge status-${rowData.rang == 1 ? 'PRM' : ''}`} ><b>Rang : </b>{rowData.isClassed != 'N' ? (rowData.rang == 1 ? rowData.rang + ' er' : rowData.rang + ' ème') : 'NC'} </span> </div>
                    </div>
                    <div className='col-12 md:col-4 lg:col-2'>
                        <div><span className={`moyenne-badge status-${getAppreciationStatus(rowData.appreciation)}`}> {rowData.appreciation} </span> </div>
                    </div>

                    <div className='col-12 md:col-4 lg:col-2 ml-4'>
                        <div><b> Abs. Just. </b><InputNumber disabled={true} ref={btn} min={0} size={2} inputId={"absJ_" + rowData.eleve.matricule} value={rowData.absJust} onValueChange={(e) => setAbsence(rowData, e.value, 'J', e)} /></div>
                    </div>
                    <div className='col-12 md:col-4 lg:col-3'>
                        <div><b> Abs. Non Just. </b><InputNumber disabled={true}  ref={btn} min={0} size={2} inputId={"absNJ_" + rowData.eleve.matricule} value={rowData.absNonJust} onValueChange={(e) => setAbsence(rowData, e.value, 'NJ', e)} /> </div>
                    </div>
                </div>

            </div>
        );
    }



    const header = (
        <div>

            <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                <h3 className="m-0"> </h3>
                <span className="block mt-2 md:mt-0 p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Recherche..." />
                </span>
            </div>

        </div>
    );

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <div className="col-12">
                        <div className="formgroup-inline">
                            <div className="col-12">
                                <label><h3><b>Bulletin partiel</b></h3></label>
                            </div>
                        </div>
                        <div className="card grid">
                            <div className="md:col-offset-2">
                            </div>
                            <div className="col-12 md:col-3 lg:col-3 lg:mb-0">
                                <label className="mx-3" htmlFor="matricule"><b>Matricule élève</b></label>
                                <InputText id="matricule" style={{ height: '30px' }} type="text" value={matricule}  onChange={(e) => setmatricule(e.target.value)}/>


                            </div>
                            <div className="col-12 md:col-3 lg:col-3 lg:mb-0">
                                <label className="mx-3" htmlFor="Période"><b>Période</b></label>
                                <Dropdown id="periode" value={periode.id} onChange={(e) => onSelectPeriodeChange(e, null)} options={periodes}
                                    required optionValue="id" optionLabel="libelle" placeholder="Selectionner la période"  style={{ height: '30px' }} className={'col-8 p-0 '+classNames({ 'p-invalid': submitted && !(periode.id) })} />
                            </div>

                            <div className="col-12 md:col-3 lg:col-3 lg:mb-0">
                                <Button label={searchBtnLbl} icon="pi pi-search" disabled={isSearching} className="p-button-success mr-2" onClick={search} />
                                <ProgressSpinner style={{ width: '30px', height: '30px' }} className={isNotSpinning} disabled={isSearching} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                            </div>
                            <Messages ref={msgs} className='col-12' ></Messages>
                        </div>
                    </div>
                    <Toast ref={toast} />
                    <div>
                        <DataTable value={moyenneEleves} paginator rows={10} loading={loading} rowsPerPageOptions={[5, 10, 25]} dataKey="eleve.matricule" expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)} responsiveLayout="scroll"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            globalFilter={globalFilter} currentPageReportTemplate="affichage de {first} à {last} sur {totalRecords} Elèves" rowExpansionTemplate={rowExpansionTemplate} header={header}>
                            <Column expander style={{ width: '3em' }} />
                            <Column field="eleve.matricule" body={eleveBodyTemplate} />
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(MoyennesParent, comparisonFn);
