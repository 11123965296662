import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { InputMask } from 'primereact/inputmask';

import { Dropdown } from 'primereact/dropdown';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { ActiviteService } from '../service/ActiviteService';
import { JourService } from '../service/JourService';
import { ClasseService } from '../service/ClasseService';
// import { MatiereEcoleService } from '../service/MatiereService';
import { HeureService } from '../service/HeureService';
// import { ClasseEleveService } from '../service/ClasseEleveService';
import { ClasseMatiereService } from '../service/ClasseMatiereService';
import { SalleService } from '../service/SalleService';
import { TypeActiviteService } from '../service/TypeActiviteService';
import { MatiereEcoleService } from '../service/MatiereEcoleService';
import { PersonnelMatiereClasseService } from '../service/PersonnelMatiereClasseService';


const Activites = () => {

    // let emptyHeure = {
    //     id: null,
    //     libelle: null
    // };

    let emptyJour = {
        id: null,
        libelle: null
    };

    let emptyClasse = {
        id: null,
        libelle: null
    }
    let emptyMatiere = {
        id: null,
        libelle: null
    }

    let emptySalle = {
        id: null,
        libelle: null
    }

    let emptyType = {
        id: null,
        libelle: null
    }

    let emptyActivite = {
        jour: emptyJour,
        heureDeb: "",
        heureFin: "",
        matiere: emptyMatiere,
        classe: emptyClasse,
        salle: emptySalle,
        typeActivite: emptyType,
        user : sessionStorage.getItem('idUser')
    };

    const [activiteDialog, setActiviteDialog] = useState(false);

    const [deleteActiviteDialog, setDeleteActiviteDialog] = useState(false);
    const [deleteActivitesDialog, setDeleteActivitesDialog] = useState(false);

    const [saveDialog, setSaveDialog] = useState(false);
    // const [update, setUpdate] = useState(false);
    const [headerTitle, setHeaderTitle] = useState("");

    const [activite, setActivite] = useState(emptyActivite);

    const [selectedActivites, setSelectedActivites] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);

    const toast = useRef(null);
    const dt = useRef(null);

    const [btn_lbl, setBtn_lbl] = useState("");

    const [activites, setActivites] = useState([]);
    const [activitesTab, setActivitesTab] = useState([]);
    const [jours, setJours] = useState(null);
    const [heures, setHeures] = useState(null);
    const [salles, setSalles] = useState(null);
    const [disabledSaveBtn, setDisabledSaveBtn] = useState(false);
    const [classes, setClasses] = useState(null);
    const [matieresEcole, setMatieresEcole] = useState(null);
    const [matieresDispo, setMatieresDispo] = useState(null);
    const [hiddenDisp, setHiddenDisp] = useState("hidden");
    const [hiddenNoDisp, setHiddenNoDisp] = useState("hidden");
    const [disabled, setDisabled] = useState(false);
    // const [type, setType] = useState([]);
    const [types, setTypes] = useState([]);
    const [width, setWidth] = useState("80%");

    useEffect(() => {
        const activiteService = new ActiviteService();
        const jourService = new JourService();
        const classeService = new ClasseService();
        const matiereEcoleService = new MatiereEcoleService();
        const heureService = new HeureService();
        const typeActiviteService = new TypeActiviteService();

        activiteService.getListByEcole(sessionStorage.getItem('EcoleID')).then(data => {setActivites(data);console.log(data)});
        jourService.getList().then(data => setJours(data));
        matiereEcoleService.getListByEcole(sessionStorage.getItem('EcoleID')).then(data => setMatieresEcole(data));
        classeService.getListByEcole(sessionStorage.getItem("EcoleID")).then(data => setClasses(data));
        heureService.getList().then(data => setHeures(data));
        // typeActiviteService.getList().then(data => setTypes(data));
        typeActiviteService.getListByEcole(sessionStorage.getItem('EcoleID')).then(data => setTypes(data));

        
        // niveauService.getList().then(data => setNiveaux(data));
        // anneeService.getList().then(data => setAnnees(data));
        // brancheService.getList().then(data => setBranches(data));
        // serieService.getList().then(data => setSeries(data));
        // filiereService.getList().then(data => setFilieres(data));
        // langueService.getList().then(data => setLangues(data))
    }, []);

    useEffect(() => {
        activitesTab.sort((a, b) => (a.heureDeb.split(':')[0] - b.heureDeb.split(':')[0]));
        activites.sort(function (a, b) {
            if (a.jour.id == b.jour.id) {
                return parseInt(a.heureDeb.split(':')[0]) - parseInt(b.heureDeb.split(':')[0]);
            }
            return a.jour.id - b.jour.id;

        })
    }, [activites, activitesTab]);


    const openNew = () => {
        setSubmitted(false);
        setWidth("80%");
        // setUpdate(false);
        // setClasse(emptyClasse);
        // setFiliere(emptyFiliere);
        // setBranche(emptyBranche);
        // setSerie(emptySerie);
        // setAnnee(emptyAnnee);
        // setNiveau(emptyNiveau);
        // setLangueVivante(emptyLangue);
        setActiviteDialog(true);
        setHeaderTitle("Créer une nouvelle activité");
        setBtn_lbl("Enregistrer");
        setDisabled(false);
        setDisabledSaveBtn(false);
        setSaveDialog(false);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setActiviteDialog(false);
        setActivite(emptyActivite);
        setMatieresDispo(null);
        // setMatieres(null);
        setActivitesTab([]);
        setHiddenDisp("hidden");
        setHiddenNoDisp("hidden");
        setSaveDialog(false);
    }


    const hideDeleteActiviteDialog = () => {
        setDeleteActiviteDialog(false);
    }


    const hideDeleteActivitesDialog = () => {
        setDeleteActivitesDialog(false);
    }

    const hideSaveActiviteDialog = () => {
        setSaveDialog(false);
    }

    const saveActivite = () => {
        setSubmitted(true);
        if (hiddenNoDisp != '' && activite.jour.id != null && activite.heureDeb != '' && activite.heureFin != '' && activite.classe.id != null && activite.matiere.id != null && activite.typeActivite.id != null) {

            let _activites = [...activites];
            let _activitesTab = [...activitesTab];
            let _activite = { ...activite };
            let _ecole = { id: sessionStorage.getItem('EcoleID') };
            _activite.annee = sessionStorage.getItem('AnneEncours');
            _activite.ecole = _ecole;
            const activiteService = new ActiviteService();


            if (activite.id) {
                //console.log(_activite);
                const index = reactfindIndexById(activite.id, activites);
                const indexTab = reactfindIndexById(activite.id, activitesTab);
                // console.log(index + ' - ' + indexTab);
                (activiteService.updateAndDisplay(_activite)).then(resp => {
                    _activites[index] = { ...resp };
                    _activitesTab[indexTab] = { ...resp };
                    setActivites(_activites);
                    setActivitesTab(_activitesTab);
                    setDisabledSaveBtn(true);
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Activité modifiée', life: 3000 });
                }).catch((error) => {
                    if (error.response) {
                        console.log('erreur response- vie ecole- activite - save' + error.response.status);
                    } else if (error.request) {
                        console.log('erreur request- vie ecole' + error.request);
                    } else {
                        console.log('Error autres', error.message);
                    }
                    toast.current.show({ severity: 'error', summary: 'erreur', detail: 'Une erreur s est produite', life: 3000 });
                }
                );

            }
            else {
                //_activite.id = createId();
                _activite.profPrincipal = null;
                (activiteService.saveAndDisplay(_activite)).then(res => {
                    console.log(res);
                    _activites.push(res);
                    _activitesTab.push(res);
                    setActivites(_activites);
                    setActivitesTab(_activitesTab);
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Nouvelle activité créee ', life: 3000 });
                }).catch((error) => {
                    if (error.response) {
                        console.log('erreur response- vie ecole- activite - save' + error.response.status);
                    } else if (error.request) {
                        console.log('erreur request- vie ecole' + error.request);
                    } else {
                        console.log('Error autres', error.message);
                    }
                    toast.current.show({ severity: 'error', summary: 'erreur', detail: 'Une erreur s est produite', life: 3000 });
                }
                );

            }
            // setActiviteDialog(false);
            // setActivite(emptyActivite);
            setSubmitted(false);
            activite.heureDeb = "";
            activite.heureFin = "";
            setHiddenDisp("hidden");
            setHiddenNoDisp("hidden");
        } else if (hiddenNoDisp === '') {
            toast.current.show({ severity: 'error', summary: 'Attention', detail: 'Plage Horaire Non disponible', life: 5000 });
        }
        setSaveDialog(false);
    }

    const editActivite = (activite) => {

        setHeaderTitle("Modifier une activité");
        setBtn_lbl("Modifier");
        setWidth('80%');
        setActivite({ ...activite });
        setActiviteDialog(true);
        setDisabled(true);
        setDisabledSaveBtn(false);

        const classeMatiereService = new ClasseMatiereService();
        const activiteService = new ActiviteService();
        const salleService = new SalleService();
        classeMatiereService.getMatiereByBrancheViaClasse(activite.classe.id).then(
            resp => {
                let matiereTmp = [];
                // console.log(resp);
                for (let index = 0; index < resp.length; index++) {
                    matiereTmp.push(resp[index].matiere);

                }
                setMatieresDispo(matiereTmp);
            }
        );

        activiteService.getByClasseAndJour(sessionStorage.getItem('AnneEncours'), activite.classe.id, activite.jour.id).then(resp => setActivitesTab(resp));
        salleService.getSallesDispoHeures(sessionStorage.getItem('AnneEncours'), activite.classe.id, activite.jour.id,null, activite.heureDeb, activite.heureFin).then(
            resp => setSalles([...resp, ...[activite.salle]])
        )
    }

    const confirmDeleteActivite = (activite) => {
        setActivite(activite);
        setDeleteActiviteDialog(true);
    }

    const deleteActivite = () => {
        let _activites = activites.filter(val => val.id !== activite.id);
        const activiteService = new ActiviteService();
        setActivites(_activites);
        setDeleteActiviteDialog(false);
        activiteService.delete(activite.id);
        setActivite(emptyActivite);
        toast.current.show({ severity: 'success', summary: 'Succès', detail: 'Activité supprimée', life: 3000 });
    }

    const reactfindIndexById = (id, atvs) => {
        let index = -1;
        for (let i = 0; i < atvs.length; i++) {
            if (atvs[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }
    // const createId = () => {
    //     let id = '';
    //     let chars = '0123456789';
    //     for (let i = 0; i < 5; i++) {
    //         id += chars.charAt(Math.floor(Math.random() * chars.length));
    //     }
    //     return id;
    // }

    // const exportCSV = () => {
    //     dt.current.exportCSV();
    // }

    const confirmDeleteSelected = () => {
        setDeleteActivitesDialog(true);
    }


    const deleteSelectedActivites = () => {
        let _activites = activites.filter(val => !selectedActivites.includes(val));
        const activiteService = new ActiviteService();
        selectedActivites.map((activite) => { return activiteService.delete(activite.id) });
        setActivites(_activites);
        setDeleteActivitesDialog(false);
        setSelectedActivites(null);
        toast.current.show({ severity: 'success', summary: 'succès', detail: 'Classes supprimées', life: 3000 });
    }

    const onInputBlur = (e, name) => {
        const val = e.target.value;
        console.log(val);
        let _activite = { ...activite };
        _activite[`${name}`] = val;
        setActivite(_activite);
        if (val === '') {
            setHiddenDisp("hidden");
            setHiddenNoDisp("hidden");
        }
    }

    const onInputChange = (e, name) => {
        const val = e.value;
        let _activite = { ...activite };
        _activite[`${name}`] = val;
        setActivite(_activite);

        if (name === 'heureDeb' || name === 'heureFin') {
            if (activite[`${name === 'heureDeb' ? 'heureFin' : 'heureDeb'}`] && _activite[`${name}`]) {
                let heureD = '';
                let heureF = '';
                console.log(activite);
                const activiteService = new ActiviteService();
                const salleService = new SalleService();
                if (name === 'heureDeb') {
                    heureD = _activite.heureDeb;
                    heureF = activite.heureFin;
                    // console.log(heureD);
                    // console.log(heureF);
                } else {
                    heureD = activite.heureDeb;
                    heureF = _activite.heureFin;
                    // console.log(heureD);
                    // console.log(heureF);
                }
                activiteService.isPlageHoraireValid(1, activite.classe.id, activite.jour.id, heureD, heureF).then(
                    resp => {
                        if (resp) {
                            setHiddenDisp("");
                            setHiddenNoDisp("hidden");
                        } else {
                            setHiddenDisp("hidden");
                            setHiddenNoDisp("");
                        }
                    }
                );
                // if (activite.matiere.id) {
                salleService.getSallesDispoHeures(1, activite.classe.id, activite.jour.id,null, heureD, heureF).then(
                    resp => setSalles(resp)
                )
                // }
            }
        }
    }

    const onSelectChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _activite = { ...activite };
        _activite[`${name}`].id = val;
        setActivite(_activite);
        // if (name === 'matiere' && activite.heureDeb && activite.heureFin && activite.classe.id && activite.jour.id) {
        //     const salleService = new SalleService();
        //     salleService.getSallesDispoHeures(1, activite.classe.id, activite.jour.id, activite.heureDeb, activite.heureFin).then(
        //         resp => setSalles(resp)
        //     )
        // }
    }

    const onSelectClasseAndJourChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _activite = { ...activite };
        _activite[`${name}`].id = val;
        setActivite(_activite);
        if (name === "classe") {

            const classeMatiereService = new ClasseMatiereService();
            const professeursClasse = new PersonnelMatiereClasseService();
            classeMatiereService.getMatiereByBrancheViaClasse(val).then(
                resp => {
                    let _matieres = [];
                    for (let index = 0; index < resp.length; index++) {
                        _matieres.push(resp[index].matiere)

                    }
                    setMatieresDispo(_matieres)
                }
            )
        }
        if (activite.classe.id && activite.jour.id) {
            //get list emploi du temps du jour 
            const activiteService = new ActiviteService();
            activiteService.getByClasseAndJour(sessionStorage.getItem('AnneEncours'), activite.classe.id, activite.jour.id).then(resp => setActivitesTab(resp));
            // console.log(parseInt('03:00'.split(':')[0], 10) - parseInt('01:01'.split(':')[0], 10));
        }
    }


    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Nouveau" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    <Button label="Supprimer" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedActivites || !selectedActivites.length} />
                </div>
            </React.Fragment>
        )
    }



    const classeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Code</span>
                {rowData.classe == null ? '' : rowData.classe.libelle}
            </>
        );
    }

    const heureDebBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Libellé</span>
                {rowData.heureDeb}
            </>
        );
    }

    const heureFinBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Année</span>
                {rowData.heureFin}
            </>
        );
    }

    const matiereBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Niveau</span>
                {rowData.matiere == null ? '' : rowData.matiere.libelle}
            </>
        );
    }

    const jourBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Série</span>
                {rowData.jour == null ? '' : rowData.jour.libelle}
            </>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editActivite(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteActivite(rowData)} />
            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h3 className="m-0">Liste des emplois du temps</h3>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Recherche..." />
            </span>
        </div>
    );


    const activiteDialogFooter = (
        <>
            <Button label={btn_lbl} disabled={disabledSaveBtn} icon="pi pi-check" className=" p-button-primary w-3" onClick={() => setSaveDialog(true)} />
            <Button label="Fermer" icon="pi pi-times" className="p-button-danger" onClick={hideDialog} />

        </>
    );


    const deleteActiviteDialogFooter = (
        <>
            <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={hideDeleteActiviteDialog} />
            <Button label="Oui" icon="pi pi-check" className="p-button-text" onClick={deleteActivite} />
        </>
    );
    const deleteActivitesDialogFooter = (
        <>
            <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={hideDeleteActivitesDialog} />
            <Button label="oui" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedActivites} />
        </>
    );

    const saveDialogFooter = (
        <>
            <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={hideSaveActiviteDialog} />
            <Button label="oui" icon="pi pi-check" className="p-button-text" onClick={saveActivite} />
        </>
    );

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" right={leftToolbarTemplate} ></Toolbar>

                    <DataTable ref={dt} value={activites} selection={selectedActivites} onSelectionChange={(e) => setSelectedActivites(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="affichage de {first} à {last} sur {totalRecords} activités"
                        globalFilter={globalFilter} emptyMessage="Aucun enregistrement trouvé." header={header} responsiveLayout="scroll">
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                        <Column field="jour.libelle" header="Jour" body={jourBodyTemplate} headerStyle={{ width: '20%', minWidth: '7rem' }}></Column>
                        <Column field="classe.libelle" header="Classe" sortable body={classeBodyTemplate} headerStyle={{ width: '20%', minWidth: '6rem' }}></Column>
                        <Column field="heureDeb" header="Heure début" body={heureDebBodyTemplate} sortable headerStyle={{ width: '20%', minWidth: '7rem' }}></Column>
                        <Column field="heureFin" header="Heure Fin" body={heureFinBodyTemplate} sortable headerStyle={{ width: '20%', minWidth: '7rem' }}></Column>
                        <Column field="matiere.libelle" header="Matière" sortable body={matiereBodyTemplate} headerStyle={{ width: '20%', minWidth: '8rem' }}></Column>
                        <Column body={actionBodyTemplate} header="Actions" headerStyle={{ width: '20%', minWidth: '9rem' }}></Column>
                    </DataTable>

                    <Dialog visible={activiteDialog} style={{ width: width }} header={headerTitle} modal className="p-fluid" footer={activiteDialogFooter} onHide={hideDialog}>

                        <div className="grid" >
                            <div className="col-7">
                                <div className="col-12">
                                    <div className="grid">
                                        <div className="field col-5">
                                            <label htmlFor="classe"><b>Classe </b></label>
                                            <Dropdown id="classe" value={activite.classe.id} disabled={disabled} onChange={(e) => onSelectClasseAndJourChange(e, 'classe')} options={classes} required optionValue="id" optionLabel="libelle" placeholder="Selectionner la classe" className={classNames({ 'p-invalid': submitted && !(activite.classe.id) })} />
                                            {submitted && !(activite.classe.id) && <small className="p-invalid">La classe est requise.</small>}
                                        </div>
                                        <div className="field col-5">
                                            <label htmlFor="jour"><b>Jour</b></label>
                                            <Dropdown id="jour" value={activite.jour.id} disabled={disabled} onChange={(e) => onSelectClasseAndJourChange(e, 'jour')} options={jours} optionValue="id" optionLabel="libelle" required placeholder="Selectionner le jour" className={classNames({ 'p-invalid': submitted && !activite.jour.id })} />
                                            {submitted && !activite.jour.id && <small className="p-invalid">Le jour est requis.</small>}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="grid">
                                        <div className="field col-2">
                                            <label htmlFor="heureDeb"><b>Heure début </b></label>
                                            <InputMask id="heureDeb" mask="99:99" disabled={disabled} placeholder="hh:mm" required value={activite.heureDeb} onBlur={(e) => onInputBlur(e, 'heureDeb')} onComplete={(e) => onInputChange(e, 'heureDeb')} className={classNames({ 'p-invalid': submitted && !(activite.heureDeb) })}></InputMask>
                                            {submitted && !(activite.heureDeb) && <small className="p-invalid">L'heure est requise.</small>}
                                        </div>

                                        <div className="field col-2">
                                            <label htmlFor="heureFin"><b>Heure Fin </b></label>
                                            <InputMask id="heureFin" mask="99:99" disabled={disabled} placeholder="hh:mm" required value={activite.heureFin} onBlur={(e) => onInputBlur(e, 'heureFin')} onComplete={(e) => onInputChange(e, 'heureFin')} className={classNames({ 'p-invalid': submitted && !(activite.heureFin) })}></InputMask>
                                            {submitted && !(activite.heureFin) && <small className="p-invalid">L'heure est requise.</small>}
                                        </div>
                                        <div className="col-1 relative">
                                            <Tag className={"absolute top-50 " + hiddenDisp} severity="success" value="Disp"></Tag>
                                            <Tag className={"absolute top-50 " + hiddenNoDisp} severity="danger" value="Occp"></Tag>
                                        </div>

                                        <div className="field col-5">
                                            <label htmlFor="matiere"><b>Matière </b></label>
                                            <Dropdown id="matiere" value={activite.matiere.id} onChange={(e) => onSelectChange(e, 'matiere')} options={matieresDispo} required optionValue="id" optionLabel="libelle" placeholder="Selectionner la matière" className={classNames({ 'p-invalid': submitted && !(activite.matiere.id) })} />
                                            {submitted && !(activite.matiere.id) && <small className="p-invalid">La matiere est requise.</small>}
                                        </div>

                                        <div className="field col-5">
                                            <label htmlFor="salle"><b>Salle disponible </b></label>
                                            <Dropdown id="salle" value={activite.salle.id} onChange={(e) => onSelectChange(e, 'salle')} options={salles} required optionValue="id" optionLabel="libelle" placeholder="Selectionner la salle" className={classNames({ 'p-invalid': submitted && !(activite.salle.id) })} />
                                            {submitted && !(activite.salle.id) && <small className="p-invalid">La salle est requise.</small>}
                                        </div>
                                        <div className="field col-5">
                                            <label htmlFor="type"><b>Type d&apos;activit&eacute; </b></label>
                                            <Dropdown id="type" value={activite.typeActivite.id} onChange={(e) => onSelectChange(e, 'typeActivite')} options={types} required optionValue="id" optionLabel="libelle" placeholder="Selectionner le type d activité" className={classNames({ 'p-invalid': submitted && !(activite.typeActivite.id) })} />
                                            {submitted && !(activite.typeActivite.id) && <small className="p-invalid">Le type d&apos;activit&eacute; est requis.</small>}
                                        </div>
                                        <div className="grid col-10">

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card col-5">
                                <DataTable value={activitesTab} header="Emploi du temps défini" size="small" scrollable scrollHeight="250px" responsiveLayout="scroll">
                                    <Column field="heureDeb" header="Heure debut"></Column>
                                    <Column field="heureFin" header="Heure Fin"></Column>
                                    <Column field="matiere.libelle" header="Matière"></Column>
                                    <Column field="salle.libelle" header="Salle"></Column>
                                </DataTable>
                            </div>

                        </div>
                    </Dialog>

                    <Dialog visible={deleteActiviteDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteActiviteDialogFooter} onHide={hideDeleteActiviteDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {activite && <span>Etes vous sûr de vouloir Supprimer l&apos;activite id [<b>{activite.id} </b>]?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteActivitesDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteActivitesDialogFooter} onHide={hideDeleteActivitesDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {activite && <span>Êtes vous sûr de vouloir supprimer les éléments sélectionnés?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={saveDialog} style={{ width: '450px' }} header="Confirmation" modal footer={saveDialogFooter} onHide={hideSaveActiviteDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {activite && <span>Voulez-vous enregistrer cette activité</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Activites, comparisonFn);