import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { MultiSelect } from 'primereact/multiselect';
import { Toolbar } from 'primereact/toolbar';

import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import {EleveService} from '../../service/operations/EleveService';
import {PublicationsService} from '../../service/operations/PublicationsService';


import {Dropdown} from "primereact/dropdown";

import {FilterMatchMode} from "primereact/api";
import {Password} from "primereact/password";
import {Calendar} from "primereact/calendar";
import moment from "moment/moment";
import {ConnexionService} from "../../service/operations/ConnexionService";
import {Message} from "primereact/message";

const CandidatOffres = () => {
    let emptyPublication = {
        id:null,
        code :'' ,
        libelle :0,
        date_creation:null,
        ecole_ecoleid :null,
        type_offre_id :null,
        niveau_id :null,
        idNivPub :null,
        experience:null ,
        lieu : '',
        date_limite :null,
        fonction_fonctionid: null,
        statut:''
    };
    let emptyPublicationSelect = {
        id:null,
        code :'' ,
        libelle :0,
        date_creation:null,
        ecole_ecoleid :null,
        libelle_ecole :'',
        libelle_offre :'',
        libelle_niveau :'',
        identifiantPubNiv :null,
        libelle_fonction :'',
        type_offre_id :null,
        niveauId :null,
        idNivPub :null,
        experience:null ,
        lieu : '',
        date_limite :null,
        fonction_fonctionid: null,
        statut:''
    };
    let emptyEcole = {
        ecoleid: 0,
        ecolecode: '',
        ecoleclibelle: ''
    };

    let emptyTypeOffre= {
        id:0,
        code:'',
        libelle:''
    } ;

    let emptyFonction= {
        fonctionid:null,
        fonctioncode:'',
        fonctionlibelle:''
    } ;

    let emptyPostuler= {
        id:null,
        sous_attent_personn_sous_attent_personnid:null,
        publication_id:null
    } ;

    let emptyNiveauEtude= {
        niveau_etudeid:0,
        niveau_etude_code:'',
        niveau_etude_libelle:''
    } ;

    let emptyAnneeScoliare= {
        annee_scolaireid: 1 ,
        annee_scolaire_libelle: '' ,
        annee_scolaire_code: '',
        annee_scolaire_visible:''
    } ;

    const [selectedCities, setSelectedCities] = useState(null);
    const cities = [
        { name: 'New York', code: 'NY' },
        { name: 'Rome', code: 'RM' },
        { name: 'London', code: 'LDN' },
        { name: 'Istanbul', code: 'IST' },
        { name: 'Paris', code: 'PRS' }
    ];
    const valideOp = [
        {code: '', libelle: ''},
        {code: 'VALIDEE', libelle: 'VALIDEE'}

    ];
    const periodes = [
        {code: 0, libelle: "Aujourd'hui"},
        {code: 3, libelle: "Moins de trois jours"},
        {code: 7, libelle: "Moins de 1 semaine"},
        {code: 14, libelle: "Moins de 2 semaines"},
        {code: 30, libelle: "Moins de 1 Mois"},
        {code: 90, libelle: "Moins de 3 Mois"}

    ];
    const searchInput = React.createRef();
    const [anneeScolaires, setanneeScolaires]  = useState(null);

    const [anneeScolaire, setanneeScolaire]  = useState(emptyAnneeScoliare);

    const [branches, setBranches]  = useState(null);
    const [niveaus, setniveaus]  = useState(null);

    const [publication, setPublication]  = useState(emptyPublication);
    const [publications, setPublications]  = useState(null);
    const [publicationSelect, setPublicationSelect]  = useState(emptyPublicationSelect);
    const [publicationSelects, setPublicationSelects]  = useState(null);
    const [typeOffre, setTypeOffre]  = useState(emptyTypeOffre);
    const [typeOffres, setTypeOffres]  = useState(null);
    const [profil, setProfil]  = useState(emptyTypeOffre);
    const [postuler, setPostuler]  = useState(emptyPostuler);
    const [valiOfrr, setValiOfrr]  = useState(emptyTypeOffre);
    const [profils, setProfils]  = useState(null);
    const [niveauEtude, setNiveauEtude]  = useState(emptyNiveauEtude);
    const [niveauEtudes, setNiveauEtudes]  = useState(null);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [vcode, setvcode]  = useState('');
    const [vlibelle, setvlibelle]  = useState('');
    const [tenant, settenant]  = useState(0);
    const [vtype_offre_id, setvtype_offre_id]  = useState(0);
    const [vniveau_id, setvniveau_id]  = useState(0);
    const [vexperience, setvexperience]  = useState('');
    const [vlieu, setvlieu]  = useState('');
    const [vdate_limite, setvdate_limite]  = useState(null);
    const [vfonction_fonctionid, setvfonction_fonctionid]  = useState(0);
    const [showMessage, setShowMessage] = useState(false);
    const [modif, setModif]  = useState(null);
    const [productDialog, setProductDialog] = useState(false);
    const [productModifDialog, setProductModifDialog] = useState(false);
    const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);


    const [retourApi, setretourApi]  = useState(null);

    const [submitted, setSubmitted] = useState(false);
    const [submittedModif, setSubmittedModif] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const [globalFilterValue2, setGlobalFilterValue2] = useState('');
    const [selectedNiveau, setSelectedNiveau] = useState(null);
    const [selectedStatut, setSelectedStatut] = useState(null);
    const [ecoles, setEcoles] = useState([]);
    const [ecole, setEcole] = useState(emptyEcole);
    const [vPostuler, setvPostuler] = useState('');
    const [selectedInscrStatut, setSelectedInscrStatut] = useState(null);
    const [periode, setPeriode]  = useState(emptyTypeOffre);

    const [filters2, setFilters2] = useState({
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'id':{ value: null, matchMode: FilterMatchMode.CONTAINS },
        'code':{ value: null, matchMode: FilterMatchMode.CONTAINS },
        'libelle':{ value: null, matchMode: FilterMatchMode.CONTAINS },
        'libelle_offre':  { value: null, matchMode: FilterMatchMode.CONTAINS },
        'libelle_niveau': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'experience': { value: null, matchMode: FilterMatchMode.CONTAINS  },
        'libelle_fonction': { value: null, matchMode: FilterMatchMode.CONTAINS  },
        'lieu': { value: null, matchMode: FilterMatchMode.CONTAINS  } ,
        'date_limite': { value: null, matchMode: FilterMatchMode.CONTAINS  },
        'libelle_ecole': { value: null, matchMode: FilterMatchMode.CONTAINS  }
    });
    const initFilters1 = () => {
        setFilters2({
            'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
            'id':{ value: null, matchMode: FilterMatchMode.CONTAINS },
            'code':{ value: null, matchMode: FilterMatchMode.CONTAINS },
            'libelle':{ value: null, matchMode: FilterMatchMode.CONTAINS },
            'type_offre':  { value: null, matchMode: FilterMatchMode.CONTAINS },
            'libelle_niveau': { value: null, matchMode: FilterMatchMode.CONTAINS },
            'experience': { value: null, matchMode: FilterMatchMode.CONTAINS  },
            'libelle_fonction': { value: null, matchMode: FilterMatchMode.CONTAINS  },
            'lieu': { value: null, matchMode: FilterMatchMode.CONTAINS  } ,
            'date_limite': { value: null, matchMode: FilterMatchMode.CONTAINS  },
            'libelle_ecole': { value: null, matchMode: FilterMatchMode.CONTAINS  }
        });
        setGlobalFilterValue2('');
    }
    const clearFilter1 = () => {
        initFilters1();
    }



    useEffect(() => {
        const publicationsService = new PublicationsService();
          initFilters1();

        publicationsService.listTypeOffre().then(data => {
            console.log(data)
            setTypeOffres(data)
        });

        publicationsService.listNiveauEtude().then(data => {
            console.log(data)
            setNiveauEtudes(data)
        });

        publicationsService.listProfils().then(data => {
            console.log(data)
            setProfils(data)
        });
        const connexSer = new ConnexionService();
        connexSer.listEcoleConnecte().then(data => setEcoles(data));
       publicationsService.rechercherPublications(0,0,0,0,0).then(data => {
            console.log(data)
            setPublicationSelects(data)
        });
    }, []);

    useEffect(() => {

    }, []);

    useEffect(() => {
        const publicationsService = new PublicationsService();
        publicationsService.rechercherPublications(niveauEtude.niveau_etudeid,ecole.ecoleid,periode.code,profil.id,typeOffre.id).then(data => {
            console.log(data)
            setPublicationSelects(data)
        });
    } , [niveauEtude,ecole,profil,typeOffre,periode]);

    const formatCurrency = (value) => {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }

    const openNew = () => {
       /* settenant(ecole.ecoleid);
        setvniveau_id(niveauEtudes.niveau_etudeid);
        setvtype_offre_id(typeOffre.id);
        setvfonction_fonctionid(profil.id);*/
        const publicationsService = new PublicationsService();
        publicationsService.rechercherPublications(niveauEtude.niveau_etudeid,tenant,0,vfonction_fonctionid,vtype_offre_id).then(data => {
            console.log(data)
            setPublicationSelects(data)
        });
    }
    const onGlobalFilterChange2 = (e) => {
        const value = e.target.value;
        let _filters2 = { ...filters2 };
        _filters2['global'].value = value;

        setFilters2(_filters2);
        setGlobalFilterValue2(value);
    }
    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
    }

    const hideModifDialog = () => {
        setSubmittedModif(false);
        setProductModifDialog(false);

    }


    const hideDeleteProductDialog = () => {
        setDeleteProductDialog(false);
    }

    const hideDeleteProductsDialog = () => {
        setDeleteProductsDialog(false);
    }
    const saveModifProduct = () =>{

    }

   const saveProduct = () => {

        setSubmitted(true);
        const publicationService = new PublicationsService();

        if(valiOfrr.libelle ==="VALIDEE"){
            postuler.publication_id = publicationSelect.id ;
            postuler.sous_attent_personn_sous_attent_personnid = sessionStorage.getItem('CandidatId') ;
            publicationService.postulerOffre(postuler).then(data => {
                console.log(data)
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Votre demande a bien été enregistrée ', life: 3000 });
                setProductDialog(false);
            });

        } else {
            toast.current.show({severity:'error', summary: 'vie-ecoles', detail:"Veuillez valider l'offre avant de soumettre votre demande", life: 3000});
        }


    }
    const editProduct = (pub) => {
        setPublicationSelect(pub);

        setProductDialog(true);

    }

    const confirmDeleteProduct = (product) => {
       // setEleve(product);
        setDeleteProductDialog(true);
    }


    const onEcoleChange = (e) => {
        setEcole(e.value);
    settenant(ecole.ecoleid)

    };


    const createId = () => {
        let id = '';
        let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < 5; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return id;
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const confirmDeleteSelected = () => {
        setDeleteProductsDialog(true);
    }




    const Sexe = [
        {sexe_code: 'MASCULIN', sexe_libelle: 'MASCULIN'},
        {sexe_code: 'FEMININ', sexe_libelle: 'FEMININ'}

    ];

    const Statuts = [
        {statut_code: 'AFFECTE', statut_libelle: 'AFFECTE'},
        {statut_code: 'NON_AFFECTE', statut_libelle: 'NON AFFECTE'},
    ];

    const InscriptionTypeOperation = [
        {inscripStatuCode: 'INSCRIPTION', inscripStatulibelle: 'INSCRIPTION'},
        {inscripStatuCode: 'PREINSCRIPTION', inscripStatulibelle: 'PREINSCRIPTION'}
    ];



    const onInscripStatutChange = (e) => {
        setSelectedInscrStatut(e.value);
            }



    const onAnneeColaireChange = (e) => {
        setanneeScolaire(e.value);
    }



    const onNiveauEtudeChange = (e) => {
        setNiveauEtude(e.value)
        setvniveau_id(niveauEtude.niveau_etudeid);
        console.log("Niveau "+ vniveau_id)
        console.log("Niveau2 "+ niveauEtude.niveau_etudeid)
       // setvniveau_id(niveauEtude.niveau_etudeid);
    }

    const onProfilEtudeChange = (e) => {
        setProfil(e.value);
        setvfonction_fonctionid(profil.id);

    }
    const onTypeOffreChange = (e) => {
        setTypeOffre(e.value);
        setvtype_offre_id(typeOffre.id);

    }



    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Nouveau" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    {/*<Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedProducts || !selectedProducts.length} />*/}
                </div>
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="formgrid grid">
                    <div className="field col">
                        <Dropdown value={niveauEtude} options={niveauEtudes}  onChange={(e) => setNiveauEtude(e.value)} optionLabel="niveau_etude_libelle" placeholder="Par niveau(x)" />
                    </div>

                    <div className="field col">

                        <Dropdown value={profil} options={profils} onChange={(e) => setProfil(e.value)} optionLabel="libelle" placeholder="Par emploi" />
                    </div>


                </div>


                <div className="formgrid grid">
                    <div className="field col">
                        <Dropdown id="codeEcole" options={ecoles} value={ecole == null ? '' : ecole} onChange={(e) => setEcole(e.value)} optionLabel="ecoleclibelle" filter showClear filterBy="ecoleclibelle"  placeholder="Par école"
                        ></Dropdown>
                    </div>

                    <div className="field col">
                        <Dropdown value={typeOffre} options={typeOffres} onChange={(e) => setTypeOffre(e.value)} optionLabel="libelle" placeholder="Par type d'offre" />
                    </div>

                    <div className="field col">
                        <Dropdown value={periode} options={periodes} onChange={(e) => setPeriode(e.value)} optionLabel="libelle" placeholder="Par période" />
                    </div>
                </div>
            </React.Fragment>
        )
    }

    const libelleNiveauBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Niveau </span>
                {rowData.libelle_niveau}
            </>
        );
    }


    const LibelleTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Libelle offre</span>
                {rowData.libelle}
            </>
        );
    }

    const TypeOffreTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Type d'offre</span>
                {rowData.libelle_offre}
            </>
        );
    }
    const NumInscrBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">N°</span>
                {rowData.id}
            </>
        );
    }

    const codeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Code</span>
                {rowData.code}
            </>
        );
    }

    const ecoleBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Ecole</span>
                {rowData.libelle_ecole}
            </>
        );
    }


    const statutBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Statut</span>
                {rowData.inscriptions_statut_eleve}
            </>
        );
    }

    const dateNaissanceBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Date naissance</span>
                {rowData.date_naissanceEleve}
            </>
        );
    }

    const dateLimiteBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Date limite</span>
                {rowData.date_limite}
            </>
        );
    }
    const lieuBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Lieu</span>
                {rowData.lieu}
            </>
        );
    }
    const LV2BodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">LV2</span>
                {rowData.inscriptionLangueVivante}
            </>
        );
    }

    const BadgeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Badge</span>
                {rowData.inscriptionBadge}
            </>
        );
    }

    const contactBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Cellulaire</span>
                {rowData.contactEleve}
            </>
        );
    }





    const imageBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Image</span>
                <img src={`assets/demo/images/product/${rowData.image}`} alt={rowData.image} className="shadow-2" width="100" />
            </>
        )
    }

    const priceBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Price</span>
                {formatCurrency(rowData.price)}
            </>
        );
    }

    const categoryBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Category</span>
                {rowData.category}
            </>
        );
    }
    const typeItemTemplate = (option) => {
        return <span className={`customer-badge status-${option}`}>{option}</span>;
    }
    const StatutFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={Statuts} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={typeItemTemplate} placeholder="Sélectionnez le statut" className="p-column-filter" showClear />;
    }

    const profilBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Profil</span>
                {rowData.libelle_fonction}
            </>
        )
    }

    const experienceBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Expérience</span>
                {rowData.experience}
            </>
        )
    }
    const dialogFooter = <div className="flex justify-content-center"><Button label="OK" className="p-button-text" autoFocus onClick={() => setShowMessage(false)} /></div>;

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editProduct(rowData)} />
             </div>
        );
    }
    const renderHeader2 = () => {
        return (
            <div className="flex justify-content-center">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue2} onChange={onGlobalFilterChange2} placeholder="Mots clés..." />
                </span>
            </div>
        )
    }
    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Liste des élèves</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Rechercher..." />
            </span>
        </div>
    );

    const productDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
        </>
    );
    const productModifDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideModifDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveModifProduct} />
        </>
    );

    const deleteProductDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            {/*<Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />*/}
        </>
    );
    const deleteProductsDialogFooter = (
        <>
            <Button label="Oui" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductsDialog} />
            {/*<Button label="Non" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedProducts} />*/}
        </>
    );
    const header2 = renderHeader2();
    return (
        <div className="grid crud-demo">

                <div className="card">
                    <Toast ref={toast} />

                    <Toolbar className="mb-4" left={rightToolbarTemplate} ></Toolbar>


                    <DataTable value={publicationSelects} paginator size="small"  className="p-datatable-customers" rows={15} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                               dataKey="id" filters={filters2} filterDisplay="row"  responsiveLayout="scroll"
                               globalFilterFields={['id','code','libelle_ecole', 'libelle', 'libelle_offre','libelle_niveau','experience','libelle_fonction','lieu','date_limite']} header={header2} emptyMessage="Pas d'elèves trouvés avec ces critères." responsiveLayout="scroll">

                        <Column filterField="id" header="N°"  body={NumInscrBodyTemplate} headerStyle={{ width: '10%', minWidth: '10rem' }}  ></Column>


                        <Column filterField="libelle_ecole" header="Ecole"  body={ecoleBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }} ></Column>

                        <Column filterField="libelle" header="Libelle Poste"  body={LibelleTemplate} headerStyle={{ width: '78%', minWidth: '10rem' }}  ></Column>

                        {<Column body={actionBodyTemplate}></Column>}
                    </DataTable>

                    <Dialog visible={showMessage} onHide={() => setShowMessage(false)} position="top" footer={dialogFooter} showHeader={false} breakpoints={{ '960px': '80vw' }} style={{ width: '30vw' }}>
                        <div className="flex justify-content-center flex-column pt-6 px-3">
                            <i className="pi pi-check-circle" style={{ fontSize: '5rem', color: 'var(--green-500)' }}></i>
                            <h5>{"Demande d'inscription"}</h5>
                            <p style={{ lineHeight: 1.5, textIndent: '1rem' }}>
                                {retourApi}
                            </p>
                        </div>
                    </Dialog>

                       {/*dialog for modif */}
                    <Dialog visible={productDialog} style={{ width: '650px' }} header="Valider l' offre" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                        <div className="field">
                            <label htmlFor="typeOffre">Type d'offre</label>
                            <InputText disabled id="typeOffre" value={publicationSelect.libelle_offre} />
                        </div>
                        <div className="field">
                            <label htmlFor="libelle_niveau">Niveau</label>
                            <InputText disabled id="libelle_niveau" value={publicationSelect.libelle_niveau} />
                        </div>

                        <div className="field">
                            <label htmlFor="experience">Expérience</label>
                            <InputText disabled id="experience" value={publicationSelect.experience} />
                        </div>

                        <div className="field">
                            <label htmlFor="profil">Profil</label>
                            <InputText disabled id="profil" value={publicationSelect.libelle_fonction} />
                        </div>

                        <div className="field">
                            <label htmlFor="lieu">Lieu</label>
                            <InputText  disabled id="lieu" value={publicationSelect.lieu} />
                        </div>

                        <div className="field">
                            <label htmlFor="datecreation">Date de création</label>
                            <InputText  className="text-orange-500 "disabled id="datecreation" value={publicationSelect.date_creation} />

                        </div>

                        <div className="field">
                            <label htmlFor="datelimite">Date limite</label>
                            <InputText  className="text-orange-500 "disabled id="datecreation" value={publicationSelect.date_limite} />

                        </div>


                        <div className="formgrid grid">
                            <div className="field col">
                                <Dropdown value={valiOfrr} onChange={(e) => setValiOfrr(e.value)} options={valideOp} optionLabel="libelle" placeholder="Postuler à cette offre" />
                            </div>
                        </div>




                    </Dialog>


                    {/*End dialog for modif */}

                    <Dialog visible={deleteProductDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            { <span>Voulez vous vraiment supprimer <b> </b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteProductsDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductsDialogFooter} onHide={hideDeleteProductsDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            { <span>Voulez vous vraiment supprimer les elements selectionnés?</span>}
                        </div>
                    </Dialog>
                </div>

        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(CandidatOffres, comparisonFn);
