import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Rating } from 'primereact/rating';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import {MatiereService} from "../../service/MatiereService";
import {Dropdown} from "primereact/dropdown";
import {SouscriptionService} from "../../service/operations/SouscriptionService";
import {EleveService} from "../../service/operations/EleveService";
import Connexion from "./Connexion";
import { Password } from 'primereact/password';
import { set } from 'react-hook-form';
import { Message } from 'primereact/message';
import { TabView, TabPanel } from 'primereact/tabview';
import {FilterMatchMode} from "primereact/api";
import {Checkbox} from "primereact/checkbox";
import {EnqueteRapideService} from "../../service/EnqueteRapideService";
import {RapportService} from "../../service/rapports/RapportService";
import {ProgressSpinner} from "primereact/progressspinner";
const EnqueteRapideEntree = () => {
    let emptyProduct = {
        matiereid: null,
        matierecode: '',
        matierecoefficien: 0,
        matierelibelle: ''

    };



    let emptyEnqueteSelectDto = {
        libelleBranche :'' ,
        idBranche :null,
        nombreNAff:null,
        nombreAff:null ,
        idAnn:null ,
        idEcole:null
    };

    let emptyReunion = {
        idAnneeScolaire : null,
        idEcole:null ,
        perAdmi :null ,
        conseilInter :null,
        conseilProfesseur:null,
        conseilProfesPrincip:null,
        vconseilEnseigne:null,
        professClassExame:null,
        unitePedagogi:null,
        parentsEleve:null,
        perAdmichefClasse:null,
        chefClasse:null
    };







    let emptyInfos4 = {
        id:'',
        libelle:''
    };
    const [baseUri, setbaseUri] = useState(window.$baseUri);
    const [isNotSpinning, setIsNotSpinning] = useState('hidden');
    const [productDialog, setProductDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [modif, setmodif] = useState(null);
    const [perAdmi, setperAdmi] = useState(false);
    const [conseilInter, setconseilInter] = useState(false);
    const [conseilProfesseur, setconseilProfesseur] = useState(false);
    const [conseilProfesPrincip, setconseilProfesPrincip] = useState(false);
    const [conseilEnseigne, setconseilEnseigne] = useState(false);
    const [professClassExame, setprofessClassExame] = useState(false);
    const [unitePedagogi, setunitePedagogi] = useState(false);
    const [parentsEleve, setparentsEleve] = useState(false);
    const [perAdmichefClasse, setperAdmichefClasse] = useState(false);
    const [chefClasse, setchefClasse] = useState(false);
    const [tenant, settenant]  = useState(sessionStorage.getItem('EcoleID'));
    const [infoEnqueteBranche, setinfoEnqueteBranche] = useState(emptyEnqueteSelectDto);
    const [infoEnqueteBranches, setinfoEnqueteBranches] = useState(null);
    const [infoReunion, setInfoReunion] = useState(emptyReunion);
    const [infoReunions, setInfoReunions] = useState(null);
    const [branche, setBranche]  = useState(emptyInfos4);
    const [branches, setBranches]  = useState(null);
    const [nombreNAff, setnombreNAff]  = useState(null);
    const [nombreAff, setnombreAff]  = useState(null);
    const [message, setMessage]  = useState(null);
   //const [anneeCourant, setanneeCourant]  = useState(sessionStorage.getItem('AnneEncours'));
    const [anneeCourant, setanneeCourant]  = useState('1');


    const toast = useRef(null);
    const dt = useRef(null);

    const [filters2, setFilters2] = useState({
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'sousc_atten_etablissid':{ value: null, matchMode: FilterMatchMode.CONTAINS },
        'sousc_atten_etablisscode':{ value: null, matchMode: FilterMatchMode.CONTAINS },
        'sousc_atten_etabliss_nom':{ value: null, matchMode: FilterMatchMode.CONTAINS },
        'sousc_atten_etabliss_tel':  { value: null, matchMode: FilterMatchMode.CONTAINS } ,
        'sousc_atten_etabliss_email':  { value: null, matchMode: FilterMatchMode.CONTAINS } ,
        'sousc_atten_etabliss_indication':  { value: null, matchMode: FilterMatchMode.CONTAINS } ,
        'zone_zone.zonelibelle':  { value: null, matchMode: FilterMatchMode.CONTAINS } ,
        'commune_commune.communelibelle':  { value: null, matchMode: FilterMatchMode.CONTAINS },
        'niveau_Enseignement_obj.libelle':  { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const formatCurrency = (value) => {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }

    const openNew = () => {

        setSubmitted(false);
        setProductDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
    }





 useEffect(() => {

     const  enquete= new EnqueteRapideService() ;

     enquete.getListParEcoleAffandNaff(tenant ,anneeCourant).then(data => {
         setinfoEnqueteBranches(data);

     } ) ;

     enquete.getListReunions(tenant ,anneeCourant).then(data => {
         setInfoReunions(data)
         setconseilInter(data.conseilInter);
         setconseilProfesseur(data.conseilProfesseur);
         setconseilProfesPrincip(data.conseilProfesPrincip);
         setconseilEnseigne(data.conseilEnseigne);
         setprofessClassExame(data.professClassExame);
         setunitePedagogi(data.unitePedagogi);
         setparentsEleve(data.parentsEleve);
         setperAdmichefClasse(data.perAdmichefClasse);
         setchefClasse(data.chefClasse);
     }) ;


     const eleveService = new EleveService();
     eleveService.listBrancheByEcole(tenant).then(data => {
         console.log(data)
         setBranches(data)
     });

    }, []);

    useEffect(() => {
        const  enquete= new EnqueteRapideService() ;
        if(modif==2) {
            enquete.getListParEcoleAffandNaff(tenant ,anneeCourant).then(data => {
                setinfoEnqueteBranches(data);

            } ) ;
        }
       setmodif(null);


    } , [modif]);



    const editProduct = (rowData) => {
        branche.id = rowData.idBranche ;
        setnombreAff(rowData.nombreAff) ;
        setnombreNAff(rowData.nombreNAff) ;

        setProductDialog(true);
    }



    const createId = () => {
        let id = '';
        let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < 5; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return id;
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }


    const saveInfosBranche = () => {
        setSubmitted(true);
        const  enquete= new EnqueteRapideService() ;
        infoEnqueteBranche.idAnn =anneeCourant ;
        infoEnqueteBranche.idEcole = tenant ;
        infoEnqueteBranche.idBranche = branche.id ;
        infoEnqueteBranche.nombreNAff = nombreNAff ;
        infoEnqueteBranche.nombreAff = nombreAff ;



        enquete.creerInfoEnqueteParNiveau(infoEnqueteBranche)
            .then((response) => {
            setmodif(2)
            })
            .catch((e) => {
                // toast.error('Upload Error')
            })
        setProductDialog(false);
        toast.current.show({ severity: 'success', summary: 'Succès', detail: 'Informations enregistrées avec succès!', life: 3000 });
    }



    const saveInfosReunion = () => {
        setSubmitted(true);
        const  enquete= new EnqueteRapideService() ;

        infoReunion.perAdmi = perAdmi ;
        infoReunion.conseilInter = conseilInter ;
        infoReunion.conseilProfesseur = conseilProfesseur ;
        infoReunion.conseilProfesPrincip = conseilProfesPrincip ;
        infoReunion.conseilEnseigne = conseilEnseigne;
        infoReunion.professClassExame = professClassExame;
        infoReunion.unitePedagogi = unitePedagogi;
        infoReunion.parentsEleve = parentsEleve;
        infoReunion.perAdmichefClasse = perAdmichefClasse;
        infoReunion.chefClasse = chefClasse;
        infoReunion.idAnneeScolaire = anneeCourant;
        infoReunion.idEcole = tenant;


        enquete.creerReunion(infoReunion)
            .then((response) => {

                setMessage(response)
                setmodif(1) ;

            })
            .catch((e) => {
                // toast.error('Upload Error')
            })

        toast.current.show({ severity: 'success', summary: 'Succès', detail: 'Informations enregistrées avec succès!', life: 3000 });
    }
    const onClassActuelleChange = (e) => {
        console.log("e+++"+ e.value)
        let _branche= {...branche} ;
        _branche.id = e.value ;
        setBranche(_branche)


        console.log("Change "+branche.id +" "+ branche.libelle);
        // setClasse_actuelle(e.value);

    } ;

    const imprimerRapportRapid = () => {

        const rapportService = new RapportService();
        setIsNotSpinning('')
        fetch(baseUri+"raport-rapide-de-rentree/imprimer/"+tenant+"/"+ anneeCourant)
            .then(response => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = 'Raport-rapide-de-rentree'+'.pdf';
                    a.click();
                });
                setIsNotSpinning('hidden')
            });


    }
    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="field">
                    <ProgressSpinner className={isNotSpinning}  style={{width: '50px', height: '50px'}}  strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                </div>
                <Button label="Télécharger" icon="pi pi-upload" className="p-button-help" onClick={imprimerRapportRapid} />
            </React.Fragment>
        )
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Toast ref={toast} />
                {/*<Button label="Ajouter" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />*/}
                <Button label="Enregistrer les réunions" icon="pi pi-save" className="p-button-success mr-2" onClick={saveInfosReunion}  />
                <Button label="Ajouter les informations de présence" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew}  />

                 </React.Fragment>
        )
    }


    const LibelleBranchBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Niveau</span>
                {rowData.libelleBranche}
            </>
        );
    }

    const AffBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Nombre Affecté</span>
                {rowData.nombreAff}
            </>
        );
    }

    const AffNonBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Nombre Non  Affecté</span>
                {rowData.nombreNAff}
            </>
        );
    }

    const idBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">N°</span>
                {rowData.idBranche}
            </>
        );
    }


    const enqueteDialogFooter = (
        <>
            <Button label="Annuler" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Enregistrer" icon="pi pi-check" className="p-button-text" onClick={saveInfosBranche} />
        </>
    );



    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                 <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editProduct(rowData)} />
                {/*<Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteProduct(rowData)} />*/}
            </div>
        );
    }
    const renderHeader2 = () => {
        return (
            <div className="flex justify-content-end">
                <span className="p-input-icon-left">

                </span>
            </div>
        )
    }
    const header2 = renderHeader2();




    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <div className="card p-fluid">
                        <h5> <Message text= "Reunion tenues avec :" severity={'info'} /> </h5>
                        <div className="formgrid grid">
                            <div className="field col">
                                <label htmlFor="perAdmi">Personnel administratif</label>
                                <Checkbox onChange={e => setperAdmi(e.checked)} checked={perAdmi}></Checkbox>
                            </div>
                            <div className="field col">
                                <label htmlFor="conseilInter">Conseil intérieur</label>
                                <Checkbox onChange={e => setconseilInter(e.checked)} checked={conseilInter}></Checkbox>
                            </div>
                            <div className="field col">
                                <label htmlFor="conseilProfesseur">Conseil des professeurs</label>
                                <Checkbox onChange={e => setconseilProfesseur(e.checked)} checked={conseilProfesseur}></Checkbox>
                            </div>

                            <div className="field col">
                                <label htmlFor="conseilProfesPrincip">Professeurs principaux</label>
                                <Checkbox onChange={e => setconseilProfesPrincip(e.checked)} checked={conseilProfesPrincip}></Checkbox>
                            </div>

                            <div className="field col">
                                <label htmlFor="conseilEnseigne">Conseil d'enseignement</label>
                                <Checkbox onChange={e => setconseilEnseigne(e.checked)} checked={conseilEnseigne}></Checkbox>
                            </div>



                        </div>

                        <div className="formgrid grid">
                            <div className="field col">
                                <label htmlFor="professClassExame">Professeurs des classes d'examen</label>
                                <Checkbox onChange={e => setprofessClassExame(e.checked)} checked={professClassExame}></Checkbox>
                            </div>
                            <div className="field col">
                                <label htmlFor="parentsEleve">Parents d'élèves</label>
                                <Checkbox onChange={e => setparentsEleve(e.checked)} checked={parentsEleve}></Checkbox>
                            </div>

                            <div className="field col">
                                <label htmlFor="chefClasse">Chefs de classe</label>
                                <Checkbox onChange={e => setchefClasse(e.checked)} checked={chefClasse}></Checkbox>
                            </div>

                            <div className="field col">
                                <label htmlFor="unitePedagogi">Unités Pédagogiques</label>
                                <Checkbox onChange={e => setunitePedagogi(e.checked)} checked={unitePedagogi}></Checkbox>
                            </div>

                        </div>

                    </div>
                    <Toolbar className="mb-4" left={leftToolbarTemplate}  right={rightToolbarTemplate}></Toolbar>

                    <DataTable ref={dt} value={infoEnqueteBranches }
                        dataKey="sousc_atten_etablisscode" paginator size="small" rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} école"
                               filters={filters2} filterDisplay="row"

                                  emptyMessage="Aucune enquête enregistrée." header={header2} responsiveLayout="scroll">

                        <Column field="idBranche" header="N°" sortable body={idBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>

                        <Column field="libelleBranche" header="Niveau" sortable body={LibelleBranchBodyTemplate} headerStyle={{ width: '50%', minWidth: '10rem' }}></Column>

                        <Column field="nombreAff" header="Nombre Affecté" sortable body={AffBodyTemplate} headerStyle={{ width: '20%', minWidth: '10rem' }}></Column>

                        <Column field="nombreNAff" header="Nombre non Affecté" sortable body={AffNonBodyTemplate} headerStyle={{ width: '20%', minWidth: '10rem' }}></Column>


                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>


                    <Dialog visible={productDialog} style={{ width: '900px' }} header={"Informations sur les élèves présents Par niveau"}  modal className="p-fluid"  onHide={hideDialog} footer={enqueteDialogFooter}>

                        <div className="formgrid grid">

                            <div className="field col">
                                <label htmlFor="classeActu">Branche </label>
                                <Dropdown id="classeActu" value = {branche.id} options={branches}  onChange={onClassActuelleChange}  optionValue="id" optionLabel="libelle" ></Dropdown>
                            </div>

                            <div className="field col">
                                <label htmlFor="nombreAffecte">Nombre affecté</label>
                                <InputText type="text" id="nombreAffecte" value={nombreAff} onChange={(e) => setnombreAff(e.target.value)} />
                            </div>
                            <div className="field col">
                                <label htmlFor="nombreNAffecte">Nombre Non  affecté</label>
                                <InputText type="text" id="nombreNAffecte" value={nombreNAff} onChange={(e) => setnombreNAff(e.target.value)} />
                            </div>
                        </div>


                    </Dialog>

                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

//export default React.memo(SouscripEcole, comparisonFn);
export default EnqueteRapideEntree;
