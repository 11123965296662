import axios from 'axios';
import { BaseService } from './BaseService';

export class DetailProgressionService extends BaseService {

  async save(detail) {
    // console.log(detail)
    return axios.post(this.getHost() + 'detail-progression/handle-save', detail).then(res => res.data);
  }

  async getByProgression(progressionId) {
    return axios.get(this.getHost() + 'detail-progression/get-by-progression/' + progressionId).then(res => res.data);
  }


  async handleDelete(ids) {
    console.log(ids);
    return axios.post(this.getHost() + 'detail-progression/handle-delete', ids).then(res => res.data);
  }
}