import React from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css'; // Import Splide CSS
import './Section.css';
import './Category.css';

const categories = [
  {
    title: 'Maternelle',
    items: 10,
    imageUrl: 'assets/img/category/1/1.jpg',
    link: 'course.html',
  },
  {
    title: 'CP',
    items: 15,
    imageUrl: 'assets/img/category/1/2.jpg',
    link: 'course.html',
  },
  {
    title: 'CE1',
    items: 12,
    imageUrl: 'assets/img/category/1/3.jpg',
    link: 'course.html',
  },
  {
    title: 'CE2',
    items: 14,
    imageUrl: 'assets/img/category/1/4.jpg',
    link: 'course.html',
  },
  {
    title: 'CM1',
    items: 18,
    imageUrl: 'assets/img/category/1/4.jpg',
    link: 'course.html',
  },
  {
    title: 'CM2',
    items: 16,
    imageUrl: 'assets/img/category/1/4.jpg',
    link: 'course.html',
  },
  {
    title: '6ème',
    items: 20,
    imageUrl: 'assets/img/category/1/4.jpg',
    link: 'course.html',
  },
  {
    title: '5ème',
    items: 22,
    imageUrl: 'assets/img/category/1/4.jpg',
    link: 'course.html',
  },
  {
    title: '4ème',
    items: 25,
    imageUrl: 'assets/img/category/1/4.jpg',
    link: 'course.html',
  },
  {
    title: '3ème',
    items: 24,
    imageUrl: 'assets/img/category/1/4.jpg',
    link: 'course.html',
  },
  {
    title: 'Seconde',
    items: 28,
    imageUrl: 'assets/img/category/1/4.jpg',
    link: 'course.html',
  },
  {
    title: 'Première',
    items: 26,
    imageUrl: 'assets/img/category/1/4.jpg',
    link: 'course.html',
  },
  {
    title: 'Terminale',
    items: 30,
    imageUrl: 'assets/img/category/1/4.jpg',
    link: 'course.html',
  },
];


const CategorySlider = () => {
  return (
    <section id='categorie-area' className="category-area pt-100 pb-40">
      <div className="container">
        <div className="row">
          <div className="col-xl-3 col-lg-3">
            <div className="category-left pb-60">
              <div className="section-area">
                <span className="section-subtitle">Parcourir les niveaux</span>
                <h2 className="section-title mb-20">Niveau scolaire</h2>
              </div>
              <div className="category-navigation">
                <div className="category-prev">
                  <i className="fa-thin fa-arrow-left" />
                </div>
                <div className="category-next">
                  <i className="fa-light fa-arrow-right" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-9 col-lg-9">
            <div className="category-wrap">
              <div className="category-shape">
                <img
                  src="assets/img/category/1/shape-1.png"
                  alt=""
                  className="category-shape-1"
                />
                <img
                  src="assets/img/category/1/shape-2.png"
                  alt=""
                  className="category-shape-2"
                />
                <img
                  src="assets/img/category/1/shape-3.png"
                  alt=""
                  className="category-shape-3"
                />
                <img
                  src="assets/img/category/1/shape-4.png"
                  alt=""
                  className="category-shape-4"
                />
              </div>
              <Splide
                options={{
                  perPage: 4,
                  pagination: false,
                  arrows: true,
                  breakpoints: {
                    640: {
                      perPage: 1,
                    },
                    768: {
                      perPage: 1,
                    },
                    1024: {
                      perPage: 2,
                    },
                  },
                }}
                className="category-active swiper pb-60"
              >
                {categories.map((category, index) => (
                  <SplideSlide key={index} className="category-item mx-5">
                    <div className="category-img">
                      <img src={category.imageUrl} alt={category.title} />
                    </div>
                    <div className="category-content">
                      <h5>
                        <a href={category.link}>{category.title}</a>
                      </h5>

                    </div>
                  </SplideSlide>
                ))}
              </Splide>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CategorySlider;
