import React, { useState, useEffect, useRef } from 'react';

import classNames from 'classnames';
import { Route, useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
// import {useHistory } from 'react';
import { AppTopbar } from '../../AppTopbar';
import { AppFooter } from '../../AppFooter';
import { AppMenu } from '../../AppMenu';

import { AppConfig } from '../../AppConfig'

import { SchoolService } from '../../service/SchoolService';
// import App2 from "../../App2";
import App3 from "../../App3.js";
import PrimeReact from 'primereact/api';
import { Tooltip } from 'primereact/tooltip';
import { LoginAppTopbar } from "../../LoginAppTopbar";
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'prismjs/themes/prism-coy.css';
import '../../assets/demo/flags/flags.css';
import '../../assets/demo/Demos.scss';
import '../../assets/layout/layout.scss';
import '../../App.scss';
// import 'react-app-polyfill/ie11';
// import { useHistory } from "react-router-dom";

import SouscripEcole from "./SouscripEcole";
import ImprimerRapports from "../Rapports/ImprimerRapports";
import Connexion from "./Connexion";

import Accueil from "./Accueil";
import HomePage from "./HomePage";

import CompteProfesseur from "./CompteProfesseur";

import ConnexionCandidat from "./ConnexionCandidat";
import AjouterSouscripEcole from "./AjouterSouscripEcole";
import EspacePersonnel from "./EspacePersonnel";
import ModifierMotDepassPersonnel from "./ModifierMotDepassPersonnel";
import DocumentsPersonnel from "./DocumentsPersonnel";
import BoiteEnvoieFondateur from "./BoiteEnvoieFondateur";
// import BoiteReceptionCandidat from "./BoiteReceptionCandidat";
import ImportEleve from "../subComponents/ImportEleve";
import RapportGlobal from "../Rapports/RapportGlobal";
import ListePersonnelEcole from "./ListePersonnelEcole";
import RecrutementPersonnel from "./RecrutementPersonnel";
import DocumentsImprimables from "../Rapports/DocumentsImprimables";
// import BoiteReceptionCandidat from "./BoiteReceptionCandidat";
// import FormLayoutDemo from "../FormLayoutDemo";
import BoiteReceptionAdmin from "./BoiteReceptionAdmin";
import BoiteEnvoiAdmin from "./BoiteEnvoiAdmin";
import PostulerOffres from "../subComponents/PostulerOffres";
import EnqueteRapideEntree from "./EnqueteRapideEntree";
import MoyennesMatiereParent from "../MoyennesMatiereParent";
import MoyenneEleveParent from "../MoyenneEleveParent";
import SouscriptionAvalider from "./SouscriptionAvalider";
import InscriptionAvalider from "../subComponents/InscriptionAvalider";
import DashBoardProfesseur from '../Dashbord/DashboardProfesseur';
import DashboardDirecteurEtude from "../Dashbord/DashboardDirecteurEtude";
import MesSouscriptionEcole from "./MesSouscriptionEcole";

import Inscription from "../subComponents/Inscription";
import Notes from "../Views/Notes";

import ValiderEcole from "./ValiderEcole";
import Personnel from "../subComponents/Personnel";
import FicheProfesseur from "../subComponents/FicheProfesseur";
import AttribuerMatiereClasse from "../subComponents/AttribuerMatiereClasse";
import SouscriptionAvaliderFondateur from './SouscriptionAvaliderFondateur';
import ModifierSouscripEcole from "./ModifierSouscripEcole";
import MyClasses from "../MyClasses";
import Activites from "../Activites";
import OpenSeance from "../OpenSeance";
import Seances from "../Seances";
import SeancesForm from "../SeancesForm";
import Pointage from "../Pointage";
import PointageBadge from "../PointageBadge";
import evaluationProf from "../EvaluationProf";
import Moyennes from "../Moyennes";
import Salles from "../Salles";
import ClasseEleve from "../ClasseEleve";
import PersonnelMatiereClasse from "../PersonnelMatiereClasse";
import Matiere from "../Matiere";
import AjouterPanierPersonnel from "./AjouterPanierPersonnel";
import MessagerieFondateur from "./MessagerieFondateur";
import MonPanierPersonnel from "./MonPanierPersonnel";
import BoiteReceptionFondateur from "./BoiteReceptionFondateur";
import AttribuerProfil from "./AttribuerProfil";
import DesActiverProfil from './DesActiverProfil';
import CreerPublication from "../subComponents/CreerPublication";
import CandidatOffres from "../subComponents/CandidatOffres";
import ModifierCompteProfesseur from './ModifierCompteProfesseur';

import ListeDesFondateur from './ListeDesFondateur';

import MesDemandeEcole from './MesDemandeEcole';
import PersonnelClasse from '../PersonnelClasse';
import CoefficientsMatiere from '../CoefficientsMatiere';
import ListeEcoleValider from './ListeEcoleValider';
import DashBoardFondateur from '../Dashbord/DashBoardFondateur';
import ListCandidatOffre from "../subComponents/ListCandidatOffre";
import EspaceFondateur from './EspaceFondateur';
import OpenAnnee from '../OpenAnnee';
import AnneeScolaireInit from '../AnneeScolaireInit';
import InfosConnexPersonnelEcole from "./InfosConnexPersonnelEcole";
import ListOffrePourCandidats from "../subComponents/ListOffrePourCandidats";

import MatiereEcole from '../MatiereEcole';
import MoyennesMatiere from '../MoyennesMatiere';
import EvaluationProf from "../EvaluationProf";
import EvaluationParent from "../EvaluationParent";
import ActivitesParent from "../ActivitesParent";
import MoyennesParent from "../MoyennesParent";
// import MigrationBtn from '../MigrationBtn';
import MiseAjourHeureAbsences from "./MiseAjourHeureAbsences";
import CertificatTravail from "../Rapports/CertificatTravail";
import { CandidatTopbar } from "../../CandidatTopbar";
import DashEvaluations from '../DashEvaluations';
import ListInscriptionClasse from "../subComponents/ListInscriptionClasse";
import ProcesVerbal from "../ProcesVerbal";
import DashNotes from '../Views/DashNotes';
import GroupeEvaluations from '../GroupeEvaluations';
import EvaluationPeriode from '../EvaluationPeriode';
import MoyennesBulletin from "../MoyennesBulletin";
import AdjustMoyenne from '../AdjustMoyenne';
import ProfEvaluationStat from '../ProfEvaluationStat';
import Progression from '../Progression';

const PageAccueil = () => {

    // let  sessionVarEmpty = {
    //     annee_id: null,
    //     ecole_id:null,
    //     connect:'',
    //     profil:'',
    //     nomprenom:'',
    //     personnelid:null
    // };
    // let  infoCandidatEmpty = {
    //     candidatid: null,
    //     candidat_nom:'',
    //     candidat_prenom:'',
    //     candidat_email:'',
    //     connect:''
    // };


    // const toast = useRef(null);
    // const [callCompte, setcallCompte] = useState(null);
    // const currentPathRef = useRef("/");
    const [con, setConn] = useState(sessionStorage.getItem('connect'));
    // const [isLoggedIn, setIsLoggedIn] = useState(sessionStorage.getItem('isLoggedIn'));
    const [layoutMode, setLayoutMode] = useState('static');
    const [layoutColorMode, setLayoutColorMode] = useState('light')
    const [inputStyle, setInputStyle] = useState('outlined');
    const [ripple, setRipple] = useState(true);
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
    const copyTooltipRef = useRef();
    const location = useLocation();
    const [menuFondateur, setMenuFondateur] = useState([]);
    const [niveauEnseign, setNiveauEnseign]  = useState(sessionStorage.getItem('niveauEnseignementId'));
    //const [niveauEnseign, setNiveauEnseign]  = useState(3);
    // const [obj, setObj] = useState({id:'YOyo'});

    // let history = useHistory();
    PrimeReact.ripple = true;

    let menuClick = false;
    let mobileTopbarMenuClick = false;
    const getActeurLabel = () => {
        return niveauEnseign >2 ? 'Etudiant' : 'Elève';
    };

    let getActeurLabels = () => {
        return niveauEnseign >2 ? 'Etudiants' : 'Elèves';
    };

    useEffect(() => {

        sessionStorage.getItem('connect');
        if (sessionStorage.getItem('niveauEnseignementId')) {
            if (sessionStorage.getItem('niveauEnseignementId') != 1) {
                _menuFondateur[2].items[1].items.splice(1, 1);
            } else {
                _menuFondateur[2].items[1].items.splice(3, 1);
            }
            setMenuFondateur(_menuFondateur);
        }
        getActeurLabels();
    }, [mobileMenuActive]);

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    }

    const onRipple = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value)
    }

    const onLayoutModeChange = (mode) => {
        setLayoutMode(mode)
    }

    const onColorModeChange = (mode) => {
        setLayoutColorMode(mode)
    }

    const onWrapperClick = (event) => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }

        if (!mobileTopbarMenuClick) {
            setMobileTopbarMenuActive(false);
        }

        mobileTopbarMenuClick = false;
        menuClick = false;
    }

    const onToggleMenuClick = (event) => {
        menuClick = true;

        if (isDesktop()) {
            if (layoutMode === 'overlay') {
                if (mobileMenuActive === true) {
                    setOverlayMenuActive(true);
                }

                setOverlayMenuActive((prevState) => !prevState);
                setMobileMenuActive(false);
            }
            else if (layoutMode === 'static') {
                setStaticMenuInactive((prevState) => !prevState);
            }
        }
        else {
            setMobileMenuActive((prevState) => !prevState);
        }

        event.preventDefault();
    }

    const onSidebarClick = () => {
        menuClick = true;
    }

    const onMobileTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        setMobileTopbarMenuActive((prevState) => !prevState);
        event.preventDefault();
    }

    const onMobileSubTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;
        event.preventDefault();
    }






    useEffect(() => {
        setConn(sessionStorage.getItem('connect'))
        //  window.location.reload(false);
    }, [sessionStorage.getItem('connectcandidat')]);


    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
    }
    const isDesktop = () => {
        return window.innerWidth >= 992;
    }


    // const addClass = (element, className) => {
    //     if (element.classList)
    //         element.classList.add(className);
    //     else
    //         element.className += ' ' + className;
    // }

    // const removeClass = (element, className) => {
    //     if (element.classList)
    //         element.classList.remove(className);
    //     else
    //         element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    // }

    const wrapperClass = classNames('layout-wrapper', {
        'layout-overlay': layoutMode === 'overlay',
        'layout-static': layoutMode === 'static',
        'layout-static-sidebar-inactive': staticMenuInactive && layoutMode === 'static',
        'layout-overlay-sidebar-active': overlayMenuActive && layoutMode === 'overlay',
        'layout-mobile-sidebar-active': mobileMenuActive,
        'p-input-filled': inputStyle === 'filled',
        'p-ripple-disabled': ripple === false,
        'layout-theme-light': layoutColorMode === 'light'
    });


    // const [schools, setSchools] = useState([]);

    // const responsiveOptions = [
    //     {
    //         breakpoint: '1024px',
    //         numVisible: 3,
    //         numScroll: 3
    //     },
    //     {
    //         breakpoint: '600px',
    //         numVisible: 2,
    //         numScroll: 2
    //     },
    //     {
    //         breakpoint: '480px',
    //         numVisible: 1,
    //         numScroll: 1
    //     }
    // ];
    const menuEducateur = [
        {
            label: 'TABLEAU DE BORD',
            items: [{
                label: 'Tableau de bord', icon: 'pi pi-fw pi-home', to: '/home/professionnelle'
            }]
        },

        {
            label: 'Inscription', icon: 'pi pi-fw pi-search',
            items: [
                { label: `Liste des ${getActeurLabels()}`, icon: "pi pi-fw pi-bookmark", to: "/home/professionnelle/importEleve" },
                { label: `Identification ${getActeurLabels()}`, icon: "pi pi-fw pi-bookmark", to: "/home/professionnelle/identification" },
                { label: 'Inscription à Valider', icon: 'pi pi-fw pi-id-card', to: '/home/professionnelle/inscriptionAvalider' },
                // { label: 'Attribution de classe', icon: 'pi pi-fw pi-id-card', to: '/home/professionnelle/AttributionClasse' },
            ]
        },
        {
            label: 'Affectations', icon: 'pi pi-fw pi-bookmark',
            items: [
                { label: `Classe-${getActeurLabels()}`, icon: 'pi pi-fw pi-chevron-circle-right', to: '/home/professionnelle/eleveclasse' },

                { label: 'Professeur - Matiere', icon: 'pi pi-fw pi-chevron-circle-right', to: '/home/professionnelle/profmatiereclasse' },
            ]
        },


    ];
    const menuAdmin = [
        {
            label: 'TABLEAU DE BORD',
            items: [{
                label: 'Tableau de bord', icon: 'pi pi-fw pi-home', to: '/home/professionnelle'
            }]
        },

        {
            label: 'ADMINISTRATION', icon: 'pi pi-fw pi-sitemap',
            items: [


                { label: "Valider une ecoles", icon: "pi pi-fw pi-check-square", to: "/home/professionnelle/validerEcole" },

                { label: "Liste des ecoles validées", icon: "pi pi-fw pi-check-square", to: "/home/professionnelle/listEcolevalider" },
                { label: 'Initialiser année', icon: 'pi pi-fw pi-pencil', to: '/home/professionnelle/initannee' },

                { label: "Valider personnels", icon: "pi pi-fw pi-check-square", to: "/home/professionnelle/souscriptionAvalider" },


                { label: "Valider Fondateur", icon: "pi pi-fw pi-check-square", to: "/home/professionnelle/souscriptionAvaliderFondateur" },

                { label: "Liste des Fondateurs validé", icon: "pi pi-fw pi-check-square", to: "/home/professionnelle/listeFondateurValid" },

                { label: "Informations de connexion", icon: "pi pi-fw pi-check-square", to: "/home/professionnelle/infosConnexion" },

                /* { label: "affecter profil ", icon: "pi pi-fw pi-check-square", to: "/home/professionnelle/AffecterProfil" }, */
                { label: "Désactiver profil utilisateur", icon: "pi pi-fw pi-check-square", to: "/home/professionnelle/desactiverProfilUser" },
                // { label: "Migration process", icon: "pi pi-fw pi-check-square", to: "/home/professionnelle/migration" },
                /* { label: "Désactiver profil Ecole", icon: "pi pi-fw pi-check-square", to: "/home/professionnelle/desactiverProfilEcole" }, */

                {
                    label: 'Ma Messagerie', icon: 'pi pi-fw pi-bookmark',
                    items: [
                        { label: 'Messages envoyés', icon: 'pi pi-fw pi-send', to: '/home/professionnelle/messageEnvoyes' },
                        { label: 'Messages réçus', icon: 'pi pi-fw pi-inbox', to: '/home/professionnelle/messagerecus' },

                    ]
                },
                { label: 'Matières', icon: 'pi pi-fw pi-building', to: '/home/professionnelle/matieres' },
                { label: 'Progression', icon: 'pi pi-fw pi-list', to: '/home/professionnelle/refprogression' }
                // {label: 'Année scolaire', icon: 'pi pi-fw pi-building', to: '/home/professionnelle/annee'}

            ]
        }

        ,

    ];

    const menuProfesseur = [
        {
            label: 'TABLEAU DE BORD',
            items: [{
                label: 'Tableau de bord', icon: 'pi pi-fw pi-home', to: '/home/professionnelle'
            }]
        },


        {
            label: 'Gestion des classes et moyennes', icon: 'pi pi-fw pi-sitemap',
            items: [
                // { label: 'Classes', icon: 'pi pi-fw pi-building', to: '/classes' },

                {
                    label: 'Classes', icon: 'pi pi-fw pi-bookmark',
                    items: [

                        { label: 'Séances', icon: 'pi pi-fw pi-pencil', to: '/home/professionnelle/seances' },

                    ]
                },
                { label: 'Evaluations', icon: 'pi pi-fw pi-id-card', to: '/home/professionnelle/evaluationsprof' },

                {
                    label: 'Notes & Moyennes', icon: 'pi pi-fw pi-circle-fill',
                    items: [
                        { label: 'Moyennes par matières', icon: 'pi pi-fw pi-send', to: '/home/professionnelle/moyennesmatiere' },


                    ]

                },
                { label: 'Heure Absences ', icon: 'pi pi-fw pi-inbox', to: '/home/professionnelle/heureAbsence' },
                {
                    label: 'Ma Messagerie', icon: 'pi pi-fw pi-bookmark',
                    items: [
                        { label: 'Messages envoyés', icon: 'pi pi-fw pi-send', to: '/home/professionnelle/messageEnvoyes' },
                        { label: 'Messages réçus', icon: 'pi pi-fw pi-inbox', to: '/home/professionnelle/messagerecus' },

                    ]
                },
            ]
        },


    ];

    let _menuFondateur = [
        {
            label: 'TABLEAU DE BORD',
            items: [{
                label: 'Tableau de bord', icon: 'pi pi-fw pi-home', to: '/home/professionnelle'
            }]
        },

        {
            label: 'FONDATEUR', icon: 'pi pi-fw pi-sitemap',
            items: [
                { label: "Ajouter au panier", icon: "pi pi-fw pi-check-square", to: "/home/professionnelle/AjouterPanier" },
                { label: "Mon panier", icon: "pi pi-fw pi-check-square", to: "/home/professionnelle/MonPanier" },
                {
                    label: 'Ma Messagerie', icon: 'pi pi-fw pi-bookmark',
                    items: [
                        { label: 'Messages envoyés', icon: 'pi pi-fw pi-send', to: '/home/professionnelle/messageEnvoyes' },
                        { label: 'Messages réçus', icon: 'pi pi-fw pi-inbox', to: '/home/professionnelle/messagerecus' },

                    ]
                },
                { label: "Créer une offre d'emploi", icon: "pi pi-fw pi-check-square", to: "/home/professionnelle/CreerUneOffre" },
                { label: "Liste des candidats par publication ", icon: "pi pi-fw pi-check-square", to: "/home/professionnelle/ListCandidatOffre" },
                { label: "Recruter un Agent", icon: "pi pi-fw pi-check-square", to: "/home/professionnelle/RecrutementPersonnel" },
                { label: "Mon personnel", icon: "pi pi-fw pi-check-square", to: "/home/professionnelle/ListePersonnel" },
                { label: "Affecter un profil", icon: "pi pi-fw pi-check-square", to: "/home/professionnelle/AffecterProfil" },


            ]
        },

        {
            label: 'Gestion des classes et moyennes', icon: 'pi pi-fw pi-sitemap',
            items: [
                // { label: 'Classes', icon: 'pi pi-fw pi-building', to: '/classes' },

                {
                    label: 'Classes', icon: 'pi pi-fw pi-bookmark',
                    items: [
                        { label: 'Liste des classes', icon: 'pi pi-fw pi-building', to: '/home/professionnelle/classes' },
                        { label: 'Séances', icon: 'pi pi-fw pi-pencil', to: '/home/professionnelle/seances' },
                        { label: 'Saisir séances', icon: 'pi pi-fw pi-pencil', to: '/home/professionnelle/seancesForm' },
                        { label: 'Ouverture de seances', icon: 'pi pi-fw pi-pencil', to: '/home/professionnelle/openSeances' },
                        { label: 'Emploi du temps', icon: 'pi pi-fw pi-calendar-minus', to: '/home/professionnelle/activites' },
                        { label: 'Journal pointage Badgeuse', icon: 'pi pi-fw pi-check-circle', to: '/home/professionnelle/pointages' },
                        { label: 'Enrollement Badge', icon: 'pi pi-fw pi-users', to: '/home/professionnelle/pointagesBadge' },
                    ]
                },
                {
                    label: 'Evaluations', icon: 'pi pi-fw pi-bookmark',
                    items: [
                        { label: 'Définir évaluations', icon: 'pi pi-fw pi-id-card', to: '/home/professionnelle/dashevaluations' },

                        { label: 'Définir période d\'évaluation', icon: 'pi pi-fw pi-id-card', to: '/home/professionnelle/evaluationperiodes' },
                        { label: 'Regrouper évaluations', icon: 'pi pi-fw pi-id-card', to: '/home/professionnelle/groupeevaluations' },
                        { label: 'Statistiques exécution', icon: 'pi pi-fw pi-id-card', to: '/home/professionnelle/statparprof' }
                    ]
                },
                { label: 'Notes & Moyennes', icon: 'pi pi-fw pi-circle-fill', to: '/home/professionnelle/moyennes' },
                { label: 'Ajuster moyennes', icon: 'pi pi-fw pi-id-card', to: '/home/professionnelle/adjustMoyenne' },
                { label: 'PV Evaluation', icon: 'pi pi-fw pi-circle-fill', to: '/home/professionnelle/pvEvaluation' }
            ]
        },

        {
            label: 'Affectations', icon: 'pi pi-fw pi-bookmark',
            items: [
                { label: `Classe-${getActeurLabels()}`, icon: 'pi pi-fw pi-chevron-circle-right', to: '/home/professionnelle/eleveclasse' },

                { label: 'Professeur - Matiere', icon: 'pi pi-fw pi-chevron-circle-right', to: '/home/professionnelle/profmatiereclasse' },
                { label: 'Personnel - Classe', icon: 'pi pi-fw pi-chevron-circle-right', to: '/home/professionnelle/personelclasse' },
            ]
        },
        {
            label: 'Parametrage', icon: 'pi pi-fw pi-check-square',
            items: [
                { label: 'Ouverture/Cloture année', icon: 'pi pi-fw pi-pencil', to: '/home/professionnelle/openannee' },
                { label: 'Salles', icon: 'pi pi-fw pi-id-card', to: '/home/professionnelle/salles' },

                { label: 'Coefficients des matières', icon: 'pi pi-fw pi-pencil', to: '/home/professionnelle/coefficients' },

                { label: 'Matières', icon: 'pi pi-fw pi-building', to: '/home/professionnelle/matieresecole' }
            ]
        },
        {
            label: 'Inscription', icon: 'pi pi-fw pi-search',
            items: [
                { label: `Importer des ${getActeurLabels()}`, icon: "pi pi-fw pi-bookmark", to: "/home/professionnelle/importEleve" },
                { label: `Identification ${getActeurLabels()}`, icon: "pi pi-fw pi-bookmark", to: "/home/professionnelle/identification" },
                { label: 'Inscription à Valider', icon: 'pi pi-fw pi-id-card', to: '/home/professionnelle/inscriptionAvalider' },
                { label: `Liste des ${getActeurLabels()} par classe`, icon: 'pi pi-fw pi-id-card', to: '/home/professionnelle/listeInscriptionClasse' },
                // { label: 'Attribution de classe', icon: 'pi pi-fw pi-id-card', to: '/home/professionnelle/AttributionClasse' },
            ]
        },
        {
            label: 'RAPPORT', icon: 'pi pi-fw pi-search',
            items: [


                { label: "Rapports", icon: "pi pi-fw pi-bookmark", to: "/home/professionnelle/autresDocuments" },
                { label: "Enquete rapide de rentrée", icon: "pi pi-fw pi-bookmark", to: "/home/professionnelle/enqueteRentree" },
                { label: "Certificat de travail", icon: "pi pi-fw pi-bookmark", to: "/home/professionnelle/certificatTravail" },

            ]
        }

        ,

    ];
    const menu = [

        {
            label: 'ESPACE CANDIDAT', icon: 'pi pi-fw pi-sitemap',
            items: [
                { label: "postuler à une offre", icon: "pi pi-fw pi-check-square", to: "/home/candidat/CandidatPostuler" },
                { label: "Mes demandes d'offre", icon: "pi pi-fw pi-check-square", to: "/home/candidat/DemandeOffres" },
                { label: "Mon profil", icon: "pi pi-fw pi-check-square", to: "/home/candidat/monProfil" },
                { label: "Login et mot de passe", icon: "pi pi-fw pi-check-square", to: "/home/candidat/ModifierMotdePasse" },
                { label: "Mes documents", icon: "pi pi-fw pi-check-square", to: "/home/candidat/MesDocuments" },

                // { label: "Mes ecoles", icon: "pi pi-fw pi-check-square", to: "/home/candidat/MesEtablissements" },

                { label: "Modfifier informations personnelles", icon: "pi pi-fw pi-check-square", to: "/home/candidat/ModifierInfosPerson" },
                {
                    label: 'Ma Messagerie', icon: 'pi pi-fw pi-bookmark',
                    items: [
                        { label: 'Messages envoyés', icon: 'pi pi-fw pi-send', to: '/home/candidat/messageEnvoyes' },
                        { label: 'Messages réçus', icon: 'pi pi-fw pi-inbox', to: '/home/candidat/messagerecus' },

                    ]
                }

            ]
        }
    ];

    const menuParent = [
        {
            label: 'TABLEAU DE BORD',
            items: [{
                label: 'Tableau de bord', icon: 'pi pi-fw pi-home', to: '/home/compteParent'
            }]
        },


        {
            label: 'Note et moyennes', icon: 'pi pi-fw pi-sitemap',
            items: [

                { label: 'Suivi des notes', icon: 'pi pi-fw pi-id-card', to: '/home/compteParent/evaluationsforparent' },
                // { label: 'Note par evaluations', icon: 'pi pi-fw pi-id-card', to: '/home/compteParent/evaluationsprof' },
                { label: 'Notes et moyennes', icon: 'pi pi-fw pi-circle-fill', to: '/home/compteParent/notes' },
                { label: 'Bulletin partiel', icon: 'pi pi-fw pi-circle-fill', to: '/home/compteParent/moyennes' }
            ]
        },

        /*  {
              label: 'Programme', icon: 'pi pi-fw pi-bookmark',
              items: [
                  { label: 'Emploi du temps', icon: 'pi pi-fw pi-calendar-minus', to: '/home/compteParent/activites' },
              ]
          },*/


    ];
    const CandidatFondateur = [

        {
            label: 'ESPACE CANDIDAT', icon: 'pi pi-fw pi-sitemap',
            items: [

                { label: "Mon profil", icon: "pi pi-fw pi-check-square", to: "/home/candidat/monProfil" },
                { label: "Login et mot de passe", icon: "pi pi-fw pi-check-square", to: "/home/candidat/ModifierMotdePasse" },
                // { label: "Mes documents", icon: "pi pi-fw pi-check-square", to: "/home/candidat/MesDocuments" },
                { label: "Mes écoles", icon: "pi pi-fw pi-check-square", to: "/home/candidat/MesEcoles" },
                { label: "Modfifier mes écoles", icon: "pi pi-fw pi-check-square", to: "/home/candidat/ModifierEcole" },
                { label: "Ajouter une nouvelle ecole", icon: "pi pi-fw pi-check-square", to: "/home/candidat/AjouterNewEcole" },
                {
                    label: 'Ma Messagerie', icon: 'pi pi-fw pi-bookmark',
                    items: [
                        { label: 'Messages envoyés', icon: 'pi pi-fw pi-send', to: '/home/candidat/messageEnvoyes' },
                        { label: 'Messages réçus', icon: 'pi pi-fw pi-inbox', to: '/home/candidat/messagerecus' },

                    ]
                }

            ]
        }
    ];



    // const schoolTemplate = (school) => {
    //     return (
    //         <div className="product-item">
    //             <div>
    //                 <div className="p-mb-3 text-center">
    //                     <div>
    //                         <img src={`images/schools/${school.image}`} width="100px" onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={school.name} className="product-image" />
    //                     </div>
    //                     <div className='font-bold text-lg'>{school.libelle}</div>
    //                 </div>
    //             </div>
    //         </div>
    //     );
    // }

    // const dt = useRef(null);


    // useEffect(() => {

    //     const schoolService = new SchoolService();
    //      schoolService.getSchools().then(data => setSchools(data));

    // }, []);


    // const creerEtablissement = () => {
    //     setcallCompte(2);
    // }
    // const creerCompte = () => {
    //     setcallCompte(1);
    // }

    if (sessionStorage.getItem('accueil') === '1') {
        return (
            <div >
                <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />
                <LoginAppTopbar onToggleMenuClick={onToggleMenuClick} layoutColorMode={layoutColorMode}
                    mobileTopbarMenuActive={mobileTopbarMenuActive} onMobileTopbarMenuClick={onMobileTopbarMenuClick} onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick} />
                <div className="layout-main-container">
                    <div className="layout-main">
                        <Route path="/home/candidat/connexion" component={ConnexionCandidat} />
                    </div>

                    <AppFooter layoutColorMode={layoutColorMode} />
                </div>

                <AppConfig rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange}
                    layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange} />

                <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
                    <div className="layout-mask p-component-overlay"></div>
                </CSSTransition>
                <div>



                </div>
            </div>

        )

    } else if (con == '1' && sessionStorage.getItem('fonction') !== 'FONDATEUR') {
        return (
            <div className={wrapperClass} onClick={onWrapperClick}>
                <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

                <CandidatTopbar onToggleMenuClick={onToggleMenuClick} layoutColorMode={layoutColorMode}
                    mobileTopbarMenuActive={mobileTopbarMenuActive} onMobileTopbarMenuClick={onMobileTopbarMenuClick} onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick} />

                <div className="layout-sidebar" onClick={onSidebarClick}>
                    <AppMenu model={menu} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />
                </div>

                <div className="layout-main-container">
                    <div className="layout-main">
                        <Route path="/home/candidat/index" exact render={() => <EspacePersonnel />} />
                        <Route path="/home/candidat/monProfil" component={EspacePersonnel} />

                        <Route path="/home/candidat/DemandeOffres" component={ListOffrePourCandidats} />
                        <Route path="/home/candidat/CandidatPostuler" component={CandidatOffres} />
                        <Route path="/home/candidat/MesEtablissements" component={MesDemandeEcole} />
                        <Route path="/home/candidat/ModifierMotdePasse" component={ModifierMotDepassPersonnel} />
                        {/*<Route path="/home/candidat/ModifierEcole" component = {ModifierSouscripEcole} />*/}
                        <Route path="/home/candidat/ModifierInfosPerson" component={ModifierCompteProfesseur} />
                        <Route path="/home/candidat/messageEnvoyes" component={BoiteEnvoieFondateur} />
                        {/* <Route path="/home/candidat/messageRecus" component={BoiteReceptionCandidat} /> */}

                    </div>

                    {/*<AppFooter layoutColorMode={layoutColorMode} />*/}
                </div>

                <AppConfig rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange}
                    layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange} />

                <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
                    <div className="layout-mask p-component-overlay"></div>
                </CSSTransition>

            </div>
        )


    }
    else if (con == '1' && sessionStorage.getItem('fonction') === 'FONDATEUR') {
        return (
            <div className={wrapperClass} onClick={onWrapperClick}>
                <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

                <CandidatTopbar onToggleMenuClick={onToggleMenuClick} layoutColorMode={layoutColorMode}
                    mobileTopbarMenuActive={mobileTopbarMenuActive} onMobileTopbarMenuClick={onMobileTopbarMenuClick} onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick} />

                <div className="layout-sidebar" onClick={onSidebarClick}>
                    <AppMenu model={CandidatFondateur} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />
                </div>

                <div className="layout-main-container">
                    <div className="layout-main">
                        <Route path="/home/candidat/index" exact render={() => <EspaceFondateur />} />
                        <Route path="/home/candidat/monProfil" component={EspaceFondateur} />
                        {/*<Route path="/home/candidat/MesEtablissements" component={MesDemandeEcole} />*/}
                        <Route path="/home/candidat/ModifierMotdePasse" component={ModifierMotDepassPersonnel} />
                        <Route path="/home/candidat/ModifierEcole" component={ModifierSouscripEcole} />
                        <Route path="/home/candidat/AjouterNewEcole" component={AjouterSouscripEcole} />
                        <Route path="/home/candidat/MesEcoles" component={MesSouscriptionEcole} />
                        {/*<Route path="/home/candidat/ModifierInfosPerson" component = {ModifierCompteProfesseur} />*/}
                        {/*<Route path="/home/candidat/MesDocuments" component={DocumentsPersonnel} />*/}
                        <Route path="/home/candidat/messageEnvoyes" component={BoiteEnvoieFondateur} />
                        {/* <Route path="/home/candidat/messageRecus" component={BoiteReceptionCandidat} /> */}

                    </div>

                    {/*<AppFooter layoutColorMode={layoutColorMode} />*/}
                </div>

                <AppConfig rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange}
                    layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange} />

                <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
                    <div className="layout-mask p-component-overlay"></div>
                </CSSTransition>

            </div>
        )


    }
    else if (sessionStorage.getItem('connect') === '2' && sessionStorage.getItem('profil') === 'Fondateur') {
        return (
            <div className={wrapperClass} onClick={onWrapperClick}>
                <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

                <AppTopbar onToggleMenuClick={onToggleMenuClick} layoutColorMode={layoutColorMode}
                    mobileTopbarMenuActive={mobileTopbarMenuActive} onMobileTopbarMenuClick={onMobileTopbarMenuClick} onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick} />

                <div className="layout-sidebar" onClick={onSidebarClick}>
                    <AppMenu model={menuFondateur} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />
                </div>

                <div className="layout-main-container">
                    <div className="layout-main">
                        <Route path="/home/professionnelle" exact render={() => <DashBoardFondateur profil='du Fondateur' />} />
                        <Route path="/home/professionnelle/enqueteRentree" component={EnqueteRapideEntree} />
                        <Route path="/home/professionnelle/ListCandidatOffre" component={ListCandidatOffre} />
                        <Route path="/home/professionnelle/imprimerDocument" component={ImprimerRapports} />
                        <Route path="/home/professionnelle/autresDocuments" component={DocumentsImprimables} />
                        <Route path="/home/professionnelle/rapportGlobal" component={RapportGlobal} />
                        <Route path="/home/professionnelle/ListePersonnel" component={ListePersonnelEcole} />
                        <Route path="/home/professionnelle/RecrutementPersonnel" component={RecrutementPersonnel} />
                        <Route path="/home/professionnelle/importEleve" component={ImportEleve} />
                        <Route path="/home/professionnelle/coefficients" component={CoefficientsMatiere} />
                        <Route exact path="/home/professionnelle/dashevaluations" component={DashEvaluations} />
                        <Route exact path="/home/professionnelle/evaluationperiodes" component={EvaluationPeriode} />
                        <Route path="/home/professionnelle/dashevaluations/notes/:evalCode" component={DashNotes} />
                        <Route path="/home/professionnelle/personelclasse" component={PersonnelClasse} />
                        <Route path="/home/professionnelle/souscriptionAvalider" component={SouscriptionAvalider} />
                        <Route path="/home/professionnelle/inscriptionAvalider" component={InscriptionAvalider} />
                        <Route path="/home/professionnelle/identification" component={Inscription} />
                        <Route path="/home/professionnelle/groupeevaluations" component={GroupeEvaluations} />
                        <Route path="/home/professionnelle/personnel" component={Personnel} />
                        <Route path="/home/professionnelle/ficheProfesseur" component={FicheProfesseur} />
                        <Route path="/home/professionnelle/ListeProfesseur" component={AttribuerMatiereClasse} />
                        <Route path="/home/professionnelle/AffecterProfil" component={AttribuerProfil} />
                        <Route path="/home/professionnelle/classes" component={MyClasses} />
                        <Route path="/home/professionnelle/activites" component={Activites} />
                        <Route path="/home/professionnelle/openSeances" component={OpenSeance} />
                        <Route path="/home/professionnelle/seances" component={Seances} />
                        <Route path="/home/professionnelle/seancesForm" component={SeancesForm} />
                        <Route path="/home/professionnelle/pointages" component={Pointage} />
                        <Route path="/home/professionnelle/pointagesBadge" component={PointageBadge} />
                        <Route path="/home/professionnelle/adjustMoyenne" component={AdjustMoyenne} />
                        <Route path="/home/professionnelle/evaluations/notes/:evalCode" component={Notes} />
                        <Route path="/home/professionnelle/moyennes" component={Moyennes} />
                        <Route path="/home/professionnelle/salles" component={Salles} />
                        <Route path="/home/professionnelle/eleveclasse" component={ClasseEleve} />
                        <Route path="/home/professionnelle/profmatiereclasse" component={PersonnelMatiereClasse} />
                        <Route path="/home/professionnelle/AjouterPanier" component={AjouterPanierPersonnel} />
                        <Route path="/home/professionnelle/MessagerieFondateur" component={MessagerieFondateur} />
                        <Route path="/home/professionnelle/MonPanier" component={MonPanierPersonnel} />
                        <Route path="/home/professionnelle/monProfil" component={EspacePersonnel} />
                        <Route path="/home/professionnelle/ModifierMotdePasse" component={ModifierMotDepassPersonnel} />
                        <Route path="/home/professionnelle/MesDocuments" component={DocumentsPersonnel} />
                        <Route path="/home/professionnelle/messageEnvoyes" component={BoiteEnvoieFondateur} />
                        <Route path="/home/professionnelle/messageRecus" component={BoiteReceptionFondateur} />
                        <Route path="/home/professionnelle/matieres" component={Matiere} />
                        <Route path="/home/professionnelle/CreerUneOffre" component={CreerPublication} />
                        <Route path="/home/professionnelle/matieresecole" component={MatiereEcole} />
                        <Route path="/home/professionnelle/openannee" component={OpenAnnee} />
                        <Route path="/home/professionnelle/certificatTravail" component={CertificatTravail} />
                        <Route path="/home/professionnelle/listeInscriptionClasse" component={ListInscriptionClasse} />
                        <Route path="/home/professionnelle/pvEvaluation" component={ProcesVerbal} />
                        <Route path="/home/professionnelle/statparprof" component={ProfEvaluationStat} />

                    </div>

                    {/*<AppFooter layoutColorMode={layoutColorMode} />*/}
                </div>

                <AppConfig rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange}
                    layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange} />

                <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
                    <div className="layout-mask p-component-overlay"></div>
                </CSSTransition>

            </div>
        )
    }

    else if (sessionStorage.getItem('connect') === '2' && sessionStorage.getItem('profil') === 'Directeur des études(DE)') {
        return (
            <div className={wrapperClass} onClick={onWrapperClick}>
                <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

                <AppTopbar onToggleMenuClick={onToggleMenuClick} layoutColorMode={layoutColorMode}
                    mobileTopbarMenuActive={mobileTopbarMenuActive} onMobileTopbarMenuClick={onMobileTopbarMenuClick} onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick} />

                <div className="layout-sidebar" onClick={onSidebarClick}>
                    <AppMenu model={menuFondateur} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />
                </div>

                <div className="layout-main-container">
                    <div className="layout-main">
                        <Route path="/home/professionnelle" exact render={() => <DashBoardFondateur profil='du Directeur des études' />} />
                        <Route path="/home/professionnelle/enqueteRentree" component={EnqueteRapideEntree} />
                        <Route path="/home/professionnelle/ListCandidatOffre" component={ListCandidatOffre} />
                        <Route path="/home/professionnelle/imprimerDocument" component={ImprimerRapports} />
                        <Route path="/home/professionnelle/autresDocuments" component={DocumentsImprimables} />
                        <Route path="/home/professionnelle/rapportGlobal" component={RapportGlobal} />
                        <Route path="/home/professionnelle/ListePersonnel" component={ListePersonnelEcole} />
                        <Route path="/home/professionnelle/RecrutementPersonnel" component={RecrutementPersonnel} />
                        <Route path="/home/professionnelle/importEleve" component={ImportEleve} />
                        <Route path="/home/professionnelle/coefficients" component={CoefficientsMatiere} />
                        <Route exact path="/home/professionnelle/dashevaluations" component={DashEvaluations} />
                        <Route path="/home/professionnelle/dashevaluations/notes/:evalCode" component={DashNotes} />
                        <Route path="/home/professionnelle/personelclasse" component={PersonnelClasse} />
                        <Route path="/home/professionnelle/souscriptionAvalider" component={SouscriptionAvalider} />
                        <Route path="/home/professionnelle/inscriptionAvalider" component={InscriptionAvalider} />
                        <Route path="/home/professionnelle/identification" component={Inscription} />
                        <Route path="/home/professionnelle/groupeevaluations" component={GroupeEvaluations} />
                        <Route path="/home/professionnelle/personnel" component={Personnel} />
                        <Route path="/home/professionnelle/ficheProfesseur" component={FicheProfesseur} />
                        <Route path="/home/professionnelle/ListeProfesseur" component={AttribuerMatiereClasse} />
                        <Route path="/home/professionnelle/AffecterProfil" component={AttribuerProfil} />
                        <Route path="/home/professionnelle/classes" component={MyClasses} />
                        <Route path="/home/professionnelle/activites" component={Activites} />
                        <Route path="/home/professionnelle/openSeances" component={OpenSeance} />
                        <Route path="/home/professionnelle/seances" component={Seances} />
                        <Route path="/home/professionnelle/seancesForm" component={SeancesForm} />
                        <Route path="/home/professionnelle/pointages" component={Pointage} />
                        <Route path="/home/professionnelle/pointagesBadge" component={PointageBadge} />
                        {/* <Route exact path="/home/professionnelle/evaluations" component={evaluationProf} /> */}
                        <Route path="/home/professionnelle/evaluations/notes/:evalCode" component={Notes} />
                        <Route path="/home/professionnelle/moyennes" component={Moyennes} />
                        <Route path="/home/professionnelle/salles" component={Salles} />
                        <Route path="/home/professionnelle/eleveclasse" component={ClasseEleve} />
                        <Route path="/home/professionnelle/profmatiereclasse" component={PersonnelMatiereClasse} />
                        <Route path="/home/professionnelle/AjouterPanier" component={AjouterPanierPersonnel} />
                        <Route path="/home/professionnelle/MessagerieFondateur" component={MessagerieFondateur} />
                        <Route path="/home/professionnelle/MonPanier" component={MonPanierPersonnel} />
                        <Route path="/home/professionnelle/monProfil" component={EspacePersonnel} />
                        <Route path="/home/professionnelle/ModifierMotdePasse" component={ModifierMotDepassPersonnel} />
                        <Route path="/home/professionnelle/MesDocuments" component={DocumentsPersonnel} />
                        <Route path="/home/professionnelle/messageEnvoyes" component={BoiteEnvoieFondateur} />
                        <Route path="/home/professionnelle/messageRecus" component={BoiteReceptionFondateur} />
                        <Route path="/home/professionnelle/matieres" component={Matiere} />
                        <Route path="/home/professionnelle/CreerUneOffre" component={CreerPublication} />
                        <Route path="/home/professionnelle/matieresecole" component={MatiereEcole} />
                        <Route path="/home/professionnelle/openannee" component={OpenAnnee} />
                        <Route path="/home/professionnelle/certificatTravail" component={CertificatTravail} />
                        <Route path="/home/professionnelle/listeInscriptionClasse" component={ListInscriptionClasse} />
                        <Route path="/home/professionnelle/pvEvaluation" component={ProcesVerbal} />



                    </div>

                    {/*<AppFooter layoutColorMode={layoutColorMode} />*/}
                </div>

                <AppConfig rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange}
                    layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange} />

                <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
                    <div className="layout-mask p-component-overlay"></div>
                </CSSTransition>

            </div>
        )
    }

    else if (sessionStorage.getItem('connect') === '2' && sessionStorage.getItem('profil') === 'Professeur') {
        return (
            <div className={wrapperClass} onClick={onWrapperClick}>
                <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

                <AppTopbar onToggleMenuClick={onToggleMenuClick} layoutColorMode={layoutColorMode}
                    mobileTopbarMenuActive={mobileTopbarMenuActive} onMobileTopbarMenuClick={onMobileTopbarMenuClick} onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick} />

                <div className="layout-sidebar" onClick={onSidebarClick}>
                    <AppMenu model={menuProfesseur} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />
                </div>

                <div className="layout-main-container">
                    <div className="layout-main">
                        <Route path="/home/professionnelle" exact render={() => <DashBoardProfesseur />} />
                        {/* <Route path="/home/professionnelle" exact render={() => <DashboardDirecteurEtude />} /> */}
                        <Route path="home/professionnelle/evaluations/notes/:evalCode" component={Notes} />
                        <Route path="/home/professionnelle/ficheProfesseur" component={FicheProfesseur} />
                        <Route path="/home/professionnelle/ListeProfesseur" component={AttribuerMatiereClasse} />
                        <Route exact path="/home/professionnelle/evaluationsprof" component={evaluationProf} />
                        <Route path="/home/professionnelle/classes" component={MyClasses} />

                        <Route path="/home/professionnelle/seances" component={Seances} />
                        <Route path="/home/professionnelle/seancesForm" component={SeancesForm} />
                        <Route path="/home/professionnelle/pointages" component={Pointage} />
                        <Route path="/home/professionnelle/pointagesBadge" component={PointageBadge} />
                        {/* <Route exact path="/home/professionnelle/evaluations" component={evaluationProf} /> */}
                        <Route path="/home/professionnelle/evaluationsprof/notes/:evalCode" component={Notes} />
                        <Route path="/home/professionnelle/moyennes" component={Moyennes} />
                        <Route path="/home/professionnelle/moyennesmatiere" component={MoyennesMatiere} />
                        <Route path="/home/professionnelle/messageEnvoyes" component={BoiteEnvoieFondateur} />
                        <Route path="/home/professionnelle/messageRecus" component={BoiteReceptionFondateur} />
                        <Route path="/home/professionnelle/heureAbsence" component={MiseAjourHeureAbsences} />
                    </div>

                    {/*<AppFooter layoutColorMode={layoutColorMode} />*/}
                </div>

                <AppConfig rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange}
                    layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange} />

                <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
                    <div className="layout-mask p-component-overlay"></div>
                </CSSTransition>

            </div>
        )
    }

    else if (sessionStorage.getItem('connect') === '2' && sessionStorage.getItem('profil') === 'Educateur') {
        return (
            <div className={wrapperClass} onClick={onWrapperClick}>
                <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

                <AppTopbar onToggleMenuClick={onToggleMenuClick} layoutColorMode={layoutColorMode}
                    mobileTopbarMenuActive={mobileTopbarMenuActive} onMobileTopbarMenuClick={onMobileTopbarMenuClick} onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick} />

                <div className="layout-sidebar" onClick={onSidebarClick}>
                    <AppMenu model={menuEducateur} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />
                </div>

                <div className="layout-main-container">
                    <div className="layout-main">
                        <Route path="/home/professionnelle" exact render={() => <DashboardDirecteurEtude />} />
                        <Route path="/home/professionnelle/souscriptionAvalider" component={SouscriptionAvalider} />
                        <Route path="/home/professionnelle/inscriptionAvalider" component={InscriptionAvalider} />
                        <Route path="/home/professionnelle/identification" component={Inscription} />
                        <Route path="/home/professionnelle/importEleve" component={ImportEleve} />
                        <Route path="/home/professionnelle/eleveclasse" component={ClasseEleve} />
                        <Route path="/home/professionnelle/profmatiereclasse" component={PersonnelMatiereClasse} />
                    </div>

                    {/*<AppFooter layoutColorMode={layoutColorMode} />*/}
                </div>

                <AppConfig rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange}
                    layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange} />

                <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
                    <div className="layout-mask p-component-overlay"></div>
                </CSSTransition>

            </div>
        )
    }
    else if (sessionStorage.getItem('connect') === '2' && sessionStorage.getItem('profil') === 'Admin') {
        return (
            <div className={wrapperClass} onClick={onWrapperClick}>
                <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

                <AppTopbar onToggleMenuClick={onToggleMenuClick} layoutColorMode={layoutColorMode}
                    mobileTopbarMenuActive={mobileTopbarMenuActive} onMobileTopbarMenuClick={onMobileTopbarMenuClick} onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick} />

                <div className="layout-sidebar" onClick={onSidebarClick}>
                    <AppMenu model={menuAdmin} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />
                </div>

                <div className="layout-main-container">
                    <div className="layout-main">
                        <Route path="/home/professionnelle" exact render={() => <ValiderEcole />} />
                        <Route path="/home/professionnelle/validerEcole" component={ValiderEcole} />
                        <Route path="/home/professionnelle/souscriptionAvalider" component={SouscriptionAvalider} />
                        <Route path="/home/professionnelle/desactiverProfilUser" component={DesActiverProfil} />
                        <Route path="/home/professionnelle/AffecterProfil" component={AttribuerProfil} />
                        <Route path="/home/professionnelle/souscriptionAvaliderFondateur" component={SouscriptionAvaliderFondateur} />
                        <Route path="/home/professionnelle/listeFondateurValid" component={ListeDesFondateur} />
                        <Route path="/home/professionnelle/messageEnvoyes" component={BoiteEnvoiAdmin} />
                        <Route path="/home/professionnelle/messageRecus" component={BoiteReceptionAdmin} />
                        <Route path="/home/professionnelle/listEcolevalider" component={ListeEcoleValider} />
                        <Route path="/home/professionnelle/matieres" component={Matiere} />
                        <Route path="/home/professionnelle/annee" component={OpenAnnee} />
                        <Route path="/home/professionnelle/infosConnexion" component={InfosConnexPersonnelEcole} />
                        <Route path="/home/professionnelle/refprogression" component={Progression} />
                        {/* <Route path="/home/professionnelle/migration" component={MigrationBtn} /> */}
                        <Route path="/home/professionnelle/initannee" component={AnneeScolaireInit} />

                        {/* <Route path="/home/professionnelle/desactiverProfilEcole" component={DesactiverProfilEcole2} /> */}

                    </div>

                    {/*<AppFooter layoutColorMode={layoutColorMode} />*/}
                </div>

                <AppConfig rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange}
                    layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange} />

                <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
                    <div className="layout-mask p-component-overlay"></div>
                </CSSTransition>

            </div>
        )
    }
    else if (sessionStorage.getItem('CompteParent') === '1') {
        return (
            <div className={wrapperClass} onClick={onWrapperClick}>
                <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

                <AppTopbar onToggleMenuClick={onToggleMenuClick} layoutColorMode={layoutColorMode}
                    mobileTopbarMenuActive={mobileTopbarMenuActive} onMobileTopbarMenuClick={onMobileTopbarMenuClick} onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick} />

                <div className="layout-sidebar" onClick={onSidebarClick}>
                    <AppMenu model={menuParent} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />
                </div>

                <div className="layout-main-container">
                    <div className="layout-main">
                        <Route path="/home/compteParent" exact render={() => <MoyenneEleveParent />} />
                        <Route exact path="/home/compteParent/evaluationsforParent" component={MoyenneEleveParent} />
                        <Route path="/home/compteParent/evaluationsprof" component={EvaluationParent} />
                        <Route path="/home/compteParent/activites" component={ActivitesParent} />
                        <Route path="/home/compteParent/notes" component={MoyennesMatiereParent} />
                        <Route path="/home/compteParent/moyennes" component={MoyennesBulletin} />
                    </div>

                    {/*<AppFooter layoutColorMode={layoutColorMode} />*/}
                </div>

                <AppConfig rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange}
                    layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange} />

                <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
                    <div className="layout-mask p-component-overlay"></div>
                </CSSTransition>

            </div>
        )

    }

    else {
        return (
            <div >
                <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />
                {/* <LoginAppTopbar onToggleMenuClick={onToggleMenuClick} layoutColorMode={layoutColorMode}
                    mobileTopbarMenuActive={mobileTopbarMenuActive} onMobileTopbarMenuClick={onMobileTopbarMenuClick} onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick} /> */}
                
                <Route path="/" exact render={() => <HomePage />} />
                
                <div className="layout-main-container">
                    <div className="layout-main">
                        <Route path="/home/professionnelle/creerSonCompte" component={CompteProfesseur} />
                        <Route path="/home/candidat/Postuler" component={PostulerOffres} />
                       


                        {/* <Accueil /> */}
                        <Route path="/home/professionnelle/inscrireEtablissement" component={SouscripEcole} />
                        <Route path="/home/professionnelle/connexion" component={Connexion} />
                        <Route path="/home/candidat/connexion" component={App3} />
                        <Route path="/home/candidat/monProfil" component={EspacePersonnel} />
                    </div>

                    <AppFooter layoutColorMode={layoutColorMode} />
                </div>

                <AppConfig rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange}
                    layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange} />

                <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
                    <div className="layout-mask p-component-overlay"></div>
                </CSSTransition>
                <div>



                </div>
            </div>

        )

    }


}

// const comparisonFn = function (prevProps, nextProps) {
//     return prevProps.location.pathname === nextProps.location.pathname;
// };

//export default React.memo(PageAccueil, comparisonFn);
export default PageAccueil;
