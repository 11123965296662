import axios from 'axios';
import { BaseService } from './BaseService';
export class AbsenceService extends BaseService{

    async saveHandle(objList){
        // console.log(objList);
        const res = await axios.post(this.getHost() + 'absence-eleve/save-list-process', objList);
        return res.data;
    }
}
