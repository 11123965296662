import axios from 'axios';
import { BaseService } from './BaseService';

export class EvaluationService extends BaseService {
    getList(){
        return axios.get(this.getHost()+'evaluations/list').then(res => res.data);
    }

    getByCode(code){
        return axios.get(this.getHost()+'evaluations/code/'+code).then(res => res.data);
    }

    getByClasseAndMatiere(annee,classe, matiere){
        return axios.get(this.getHost()+'evaluations/get-classe-matiere?anneeId='+annee+'&classeId='+classe+'&matiereId='+matiere).then(res => res.data);
    }

    async getEvalNonGroupe(annee,ecole, niveau, matiere, periode){
        return axios.get(this.getHost()+'evaluations/get-non-groupe?annee='+annee+'&ecole='+ecole+'&niveau='+niveau+'&matiere='+matiere+'&periode='+periode).then(res => res.data);
    }

    getCountByClasseAndMatiere(annee, classe, matiere){
        return axios.get(this.getHost()+'evaluations/get-count-classe-matiere?anneeId='+annee+'&classeId='+classe+'&matiereId='+matiere).then(res => res.data);
    }

    getByClasseAndMatiereAndPeriode(classe, matiere,periode, annee){
        return axios.get(this.getHost()+'evaluations/get-classe-matiere-periode?classeId='+classe+'&matiereId='+matiere+'&periodeId='+periode+'&annee='+annee).then(res => res.data);
    }

    getByMatricule(classe, matiere,periode, annee){
        return axios.get(this.getHost()+'evaluations/get-classe-matiere-periode?classeId='+classe+'&matiereId='+matiere+'&periodeId='+periode+'&annee='+annee).then(res => res.data);
    }

    async getStatistiquesProf (ecole, annee,periode, id){
        return axios.get(this.getHost()+'evaluations/statistique-prof/'+ecole+'/'+annee+'/'+periode+'/'+id).then(res => res.data);
    }

    save(evaluation){
        axios.post(this.getHost()+'evaluations/save',evaluation);
    }

    update(evaluation){
       return axios.post(this.getHost()+'evaluations/update',evaluation).then(response => response.data);
    }

    updateAndDisplay(evaluation){
        return axios.post(this.getHost()+'evaluations/update-display',evaluation).then(res => res.data);
     }

    saveAndDisplay(evaluation){
        return axios.post(this.getHost()+'evaluations/saveAndDisplay',evaluation).then(res => res.data);
    }

    async isLocked(evalId){
        return axios.get(this.getHost()+'evaluations/is-locked/'+evalId).then(res => res.data);
    }

    delete(id, user){
        return axios.delete(this.getHost()+'evaluations/delete-handle/'+id+'/'+user);
    }
}
