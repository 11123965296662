import axios from 'axios';
import { BaseService } from './BaseService';

export class JourService extends BaseService {
    
    getList(){
        return axios.get(this.getHost()+'jour/list').then(res => res.data)
        .catch((error)=>{console.log('Erreur du service getList',error.message)});
        
    }
}