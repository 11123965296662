import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from "react-router-dom";
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { InputMask } from 'primereact/inputmask';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { EvaluationService } from '../service/EvaluationService';
import { PeriodeService } from '../service/PeriodeService';
// import { TypeEvaluationService } from '../service/TypeEvaluationService';
import { InputSwitch } from 'primereact/inputswitch';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { FileUpload } from 'primereact/fileupload';
// import { ClasseService } from '../service/ClasseService';
// import { MatiereService } from '../service/MatiereService';
import { PersonnelMatiereClasseService } from '../service/PersonnelMatiereClasseService';
import { FileService } from '../service/FileService';
// import { ConnectedOverlayScrollHandler } from 'primereact/utils';
import { ImportEvaluationService } from '../service/ImportEvaluationService';
import { TypeActiviteService } from '../service/TypeActiviteService';
import {ClasseService} from "../service/ClasseService";
import {EleveService} from "../service/operations/EleveService";
import {RapportService} from "../service/rapports/RapportService";
import {ProgressSpinner} from "primereact/progressspinner";


const ProcesVerbal = () => {

    const emptyType = {
        id: null,
        code: '',
        libelle: ''
    }

    // const emptyEvalEleve = {

    //     matricule: '',
    //     notes: []
    // }

    const emptyMatiere = {
        id: null,
        code: '',
        libelle: ''
    }

    const emptyProcesVerbal = {
        id: null,
        noteObtenu: '',
        appreciation: '',
        chapitreDeLecon: '',
        observation: '',
        moyenneEvaluation: '',
        forteNote: '',
        faibleNote: '',
        nbreNoteInf_egal_8_50: '',
        nbreNoteSup_8_50_inf_9_99: '',
        nbreNoteSup10: '',
        nbreNoteSup12: '',
        pour8_50: '',
        pour_8_50_inf_9_99: '',
        pour_nbreNoteSup10: '',
        pour_nbreNoteSup12: '',


    }

    const emptyClasse = {
        id: null,
        code: '',
        libelle: ''
    }
    const emptyPeriode = {
        id: null,
        code: '',
        libelle: ''
    }
    const emptyAnnee = {
        id: sessionStorage.getItem('AnneEncours')
    }

    const emptyEvaluation = {
        id: null,
        code: '',
        date: '',
        eleve: null,
        heure: '',
        duree: '',
        etat: '',
        note: '',
        noteSur: 0,
        dateLimite: '',
        type: emptyType,
        matiereEcole: emptyMatiere,
        classe: emptyClasse,
        periode: emptyPeriode,
        annee: emptyAnnee,
        user: sessionStorage.getItem('idUser')

    }
    const [globalFilter, setGlobalFilter] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const toast = useRef(null);
    const dt = useRef(null);
    const [evaluations, setEvaluations] = useState([]);
    const [evaluation, setEvaluation] = useState(emptyEvaluation);
    const [evalDialog, setEvalDialog] = useState(false);
    const [importDialog, setImportDialog] = useState(false);
    const [classes, setClasses] = useState([]);
    const [distinctClasses, setDistinctClasses] = useState([]);
    const [classe, setClasse] = useState(null);
    const [periodes, setPeriodes] = useState(null);
    const [periode, setPeriode] = useState(emptyPeriode);
    const [types, setTypes] = useState(null);
    const [matieres, setMatieres] = useState(null);
    const [matiere, setMatiere] = useState(emptyMatiere);
    const [procesVerbals, setprocesVerbals] = useState(null);
    const [procesVerbal, setprocesVerbal] = useState(emptyProcesVerbal);
    const [matiereImport, setMatiereImport] = useState(emptyMatiere);
    const [periodeImport, setPeriodeImport] = useState(emptyPeriode);
    const [matricule, setmatricule] = useState('');
    const [codeEva, setCodeEva] = useState('');
    const [noteObtenu, setnoteObtenu] = useState('');
    const [isNotSpinning, setIsNotSpinning] = useState('hidden');
    const [baseUri, setbaseUri] = useState(window.$baseUri);

    const width = "1000px";

    const [profMatClasseSelect, setProfmatClassesSelect] = useState(null);
    const [profMatClassesMatiereSelect, setProfMatClassesMatiereSelect] = useState(null);

    const [disabled, setdisabled] = useState(true);
    const userId = sessionStorage.getItem('CandidatId');
    const anneeId = sessionStorage.getItem('AnneEncours');

    const [importedData, setImportedData] = useState([]);
    const [selectedImportedData, setSelectedImportedData] = useState([]);
    const [importedCols, setImportedCols] = useState([{ field: '', header: '' }]);

    const fileService = new FileService();
    // const [maxEval, setEvalEleve] = useState(null);
    const [evalEleves, setEvalEleves] = useState([]);
    const [disabledImportSaveBtn, setDisabledImportSaveBtn] = useState(true);
    const [recapDialog, setRecapDialog] = useState(false);
    const [recapData, setRecapData] = useState([]);
    const [disabledUpdate, setDisabledUpdate] = useState(false);
    const [lblBtnSave, setLblBtnSave] = useState('');

    const location = useLocation();
    const data = location.state;

       useEffect(async () => {
           const classeService = new ClasseService();
        const periodeService = new PeriodeService();
           periodeService.getByPeriodicite(sessionStorage.getItem('periodiciteId')).then(res => setPeriodes(res));

           classeService.getListByEcole(sessionStorage.getItem("EcoleID")).then(data => setClasses(data));


    }, []);
    useEffect(() => {
        if(classe!=null) {
            const classeService = new ClasseService();
            classeService.getListMatierByEcoleClasseWeb(sessionStorage.getItem("EcoleID"),classe.id).then(data => setMatieres(data));

        }

    }, [classe]);
    const initFromNotesPage = () => {
        // console.log(data);
        const persMatClasService = new PersonnelMatiereClasseService();
        let _profMatClassesMatiereSelect = [];
        persMatClasService.getByProfesseurAndClasse(userId, data?.classe?.id, sessionStorage.getItem('AnneEncours')).then(res => {
            _profMatClassesMatiereSelect = _profMatClassesMatiereSelect.concat(res);
            setProfMatClassesMatiereSelect(_profMatClassesMatiereSelect);
            setMatiere(data.matiereEcole);
            setClasse(data.classe);
            setPeriode(data.periode);
            setdisabled(false);
            const evaluationService = new EvaluationService();
            evaluationService.getByClasseAndMatiereAndPeriode(data?.classe?.id, data?.matiereEcole?.id, data?.periode?.id, sessionStorage.getItem("AnneEncours")).then(res => {
                setEvaluations(res);
            });

        });
        // console.log(evaluation)

    }


    const onSelectChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _evaluation = { ...evaluation };
        _evaluation[`${name}`].id = val;
        setEvaluation(_evaluation);

        if (name === 'classe') {
            const persMatClasService = new PersonnelMatiereClasseService();
            let _matieres = [];
            // _matiere.id = val;
            // setMatiere(_matiere);
            // console.log('prof-id est ::: ' + userId);
            persMatClasService.getByProfesseurAndClasse(userId, val, sessionStorage.getItem('AnneEncours')).then(res => {
                for (let i = 0; i < res.length; i++) {
                    // console.log(res[i].matiere);
                    _matieres.push(res[i].matiere);
                }
                setMatieres(_matieres);
            });

        }
        // console.log(_evaluation);
    }

    const onSearchSelectChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        if (name === 'classe') {
            const persMatClasService = new PersonnelMatiereClasseService();
            let _classe = { ...classe };
            let _profMatClassesMatiereSelect = [];
            _classe.id = val;
            setClasse(_classe);

            // console.log(_profMatClassesMatiereSelect);
        }

        if (name === 'matiere') {
            let _matiere = { ...matiere };
            _matiere.id = val;
            setMatiere(_matiere);
            if (periode.id !== null && _matiere.id !== '')
                setdisabled(false);
            else
                setdisabled(true);
        }

        if (name === 'matiereImport') {
            let _matiere = { ...matiereImport };
            _matiere.id = val;
            setMatiereImport(_matiere);
            console.log(matiereImport);
        }


        if (name === 'periode') {
            let _periode = { ...periode };
            _periode.id = val;
            setPeriode(_periode);

            if (_periode.id !== null && matiere.id !== null)
                setdisabled(false);
            else
                setdisabled(true);
        }

        if (name === 'periodeImport') {
            let _periode = { ...periode };
            _periode.id = val;
            setPeriodeImport(_periode);
        }

    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _evaluation = { ...evaluation };
        _evaluation[`${name}`] = val;
        setEvaluation(_evaluation);
        // console.log('+++++++'+evaluation.noteSur+'+++'+val);
    }

    const editEvaluation = (rowData) => {

          setCodeEva(rowData.code);
        imprimerProcesVerbal(rowData.code)

    }

    const dateBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Date</span>
                {formatDate(rowData.date)}
            </>
        );
    }

    const idBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Type</span>
                {rowData.id}
            </>
        );
    }

    const typeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Evaluation</span>
                {rowData.type != null ? rowData.type.libelle : ''}
            </>
        );
    }

    const periodeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Période</span>
                {rowData.periode != null ? rowData.periode.libelle : ''}
            </>
        );
    }


    const classeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Classe</span>
                {rowData.classe != null ? rowData.classe.libelle : ''}
            </>
        );
    }


    const matiereBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Matière</span>
                {rowData.matiereEcole != null ? rowData.matiereEcole.libelle : ''}
            </>
        );
    }

    const noteSurBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">noteSur</span>
                {rowData.noteSur}
            </>
        );
    }
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">

                    <ProgressSpinner className={isNotSpinning}  style={{width: '50px', height: '50px'}}  strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />

                <Button icon="pi pi-file-pdf" className="p-button-rounded p-button-success mr-2" onClick={() => editEvaluation(rowData)} />
            </div>
        );
    }

    const pecBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Prise en compte dans le calcul des moyennes</span>
                <InputSwitch name='pec' className='ml-2' trueValue={1} falseValue={0} checked={rowData.pec} onChange={(e) => { setPecValue(e.value, rowData) }} />
            </>
        );
    }
    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                  {/*  <Button label="Importer" icon="pi pi-file" className="p-button-info mx-2" onClick={openImport} disabled={!classe?.id} />
*/}
                </div>
            </React.Fragment>
        )
    }



    function padTo2Digits(num) {
        return num.toString().padStart(2, '0');
    }

    function formatDate(date) {
        const [date1, time1] = date.split('T');
        if (date1) {

            date = new Date(date1);
            return [
                padTo2Digits(date.getDate()),
                padTo2Digits(date.getMonth() + 1),
                date.getFullYear(),
            ].join('/');
        } else
            return '';
    }
    const openNew = () => {
        setSubmitted(false);
        setEvalDialog(true);
        setEvaluation({ ...emptyEvaluation })
        setLblBtnSave('Enregistrer');
        const persMatClasService = new PersonnelMatiereClasseService();
        persMatClasService.getByProfesseur(sessionStorage.getItem("AnneEncours"), sessionStorage.getItem('CandidatId'), sessionStorage.getItem('EcoleID')).then(res => {
            let _classes = [];
            let filter = [];
            for (let i = 0; i < res.length; i++) {
                if (!filter.includes(res[i].classe.code)) {
                    filter.push(res[i].classe.code)
                    _classes.push(res[i].classe);
                }
            }
            setClasses(_classes);
            // console.log(_classes);
        }
        );

        // setHeaderTitle("Créer une nouvelle évaluation");
    }

    const openImport = () => {
        setImportDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setEvalDialog(false);

    }

    const hideImportDialog = () => {
        setImportDialog(false);
        //clear();
    }

    const hideRecapDialog = () => {

        setPeriodeImport(emptyPeriode);
        setPeriodeImport([]);
        setRecapDialog(false);
        setRecapData([]);
        setSubmitted(false);
        //clear();
    }

    const reactfindIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < evaluations.length; i++) {
            if (evaluations[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    }

    const findClasseById = (id) => {
        let current = {};
        for (let i = 0; i < distinctClasses.length; i++) {
            if (distinctClasses[i].id === id) {
                current = { ...distinctClasses[i] };
                break;
            }
        }
        // console.log(current);
        return current;
    }

    const save = () => {
        setSubmitted(true);
        // console.log(evaluation);
        // console.log(evaluation.type.id != null);
        // _evaluation.user = sessionStorage.getItem('idUser');
        if (evaluation.type.id != null && evaluation.periode.id != null && evaluation.matiereEcole.id != null && evaluation.classe.id != null && evaluation.noteSur != '' && evaluation.date != '' && evaluation.duree != '') {
            let _evaluations = [...evaluations];
            let _evaluation = { ...evaluation };
            console.log(_evaluation);
            const evaluationService = new EvaluationService();
            if (evaluation.id) {
                // console.log(evaluation);
                const index = reactfindIndexById(evaluation.id);

                (evaluationService.updateAndDisplay(_evaluation)).then(resp => {
                    _evaluations[index] = { ...resp };
                    setEvaluations(_evaluations);
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Evaluation modifiée', life: 3000 });
                }).catch((error) => {
                    if (error.response) {
                        console.log('erreur response- vie ecole- evaluation - save' + error.response.status);
                    } else if (error.request) {
                        console.log('erreur request- vie ecole' + error.request);
                    } else {
                        console.log('Error autres', error.message);
                    }
                    toast.current.show({ severity: 'erreur', summary: 'erreur', detail: error.message, life: 3000 });
                }
                );

            }
            else {
                // _evaluation.annee = createId();
                console.log(_evaluation);
                (evaluationService.saveAndDisplay(_evaluation)).then(res => {
                    if (_evaluations.length > 0) {
                        if (_evaluations[0].annee.id == res.annee.id && _evaluations[0].classe.id == res.classe.id && _evaluations[0].matiereEcole.id == res.matiere.id && _evaluations[0].periode.id == res.periode.id) {

                            _evaluations.push(res);
                            setEvaluations(_evaluations);
                        }
                    }
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Nouvelle evaluation créee ' + _evaluation.code, life: 3000 });
                }).catch((error) => {
                    if (error.response) {
                        console.log('erreur response- vie ecole' + error.response.status);
                    } else if (error.request) {
                        console.log('erreur request- vie ecole' + error.request);
                    } else {
                        console.log('Error autres', error.message);
                    }
                    toast.current.show({ severity: 'error', summary: 'erreur', detail: error.message, life: 3000 });
                });
            }
            setSubmitted(false);
            setEvaluation(emptyEvaluation);
            setEvalDialog(false);
        }
    }
    const importation = () => {
        console.log('lancement importation');
        let importService = new ImportEvaluationService();
        console.log(evalEleves.length);
        importService.importData(evalEleves, classe.id, anneeId).then(
            resp => {
                // toast.current.show({ severity: 'success', summary: resp.title, detail: resp.detail, life: 3000 });
                clear();
                setDisabledImportSaveBtn(true);
                hideImportDialog()
                setRecapDialog(true);
                setRecapData(resp);
                console.log(resp);
            }
        ).catch((error) => {
            if (error.response) {
                console.log('erreur response- vie ecole' + error.response.status);
            } else if (error.request) {
                console.log('erreur request- vie ecole' + error.request);
            } else {
                console.log('Error autres', error.message);
            }
            toast.current.show({ severity: 'error', summary: 'erreur', detail: error.message, life: 3000 });
        });
    }

    const getEvalWithNewPecValue = (rowData, val) => {
        rowData.pec = val
        return rowData;
    }

    const getToogleCrtl = () => {
        let i = 0;
        evaluations.forEach((ev) => {
            if (ev.pec === 1) {
                i++;
            }
        })

        return i < 15;
    }

    const search = () => {
        const evaluationService = new EvaluationService();
        evaluationService.getByClasseAndMatiereAndPeriode(classe.id, matiere.id, periode.id, sessionStorage.getItem("AnneEncours")).then(res => {
            setEvaluations(res);
            if (res.length == 0)
                toast.current.show({ severity: 'warn', summary: 'Aucune donnée trouvée', detail: '', life: 3000 });

        });


    }
    const imprimerProcesVerbal = (code) => {

        const rapportService = new RapportService();
        setIsNotSpinning('')
        fetch(baseUri+"imprimer-proces-verbal/imprimer-proces-verbal/"+classe.id+"/"+code)
            .then(response => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = 'Procès verbal evaluation'+'_'+'_'+periode.libelle+'.docx';
                    a.click();
                });
                setIsNotSpinning('hidden')
            });


    }
    const setPecValue = (val, rowData) => {
        let index = reactfindIndexById(rowData.id)
        const evaluationService = new EvaluationService();
        // console.log('index '+index);


        if (getToogleCrtl() || val === 0) {
            evaluationService.updateAndDisplay(getEvalWithNewPecValue(rowData, val)).then(
                resp => {
                    let _evaluations = [...evaluations];
                    _evaluations[index].pec = val;
                    setEvaluations(_evaluations);
                    // console.log(resp);
                    // echo();
                }
            ).catch((error) => {
                if (error.response) {
                    console.log('erreur response- vie ecole' + error.response.status);
                } else if (error.request) {
                    console.log('erreur request- vie ecole' + error.request);
                } else {
                    console.log('Error autres', error.message);
                }
                toast.current.show({ severity: 'error', summary: 'erreur', detail: error.message, life: 3000 });
            });
        } else {
            toast.current.show({ severity: 'error', summary: 'Attention', detail: 'Nombre de selection atteint', life: 3000 });
        }
    }

    const cleanTab = (data) => {
        let obj = [];
        for (let i = 0; i < data.length; i++) {
            //    console.log(data[i]);
            if (typeof (data[i].matricule) !== 'undefined') {
                obj.push(data[i]);
            }
        }
        return obj;
    }

    const cleanImportedData = (data) => {
        let obj = [];
        for (let i = 0; i < data.length; i++) {
            //    console.log(data[i]);
            if (typeof (data[i].Matricule) !== 'undefined') {
                obj.push(data[i]);
            }
        }
        return obj;
    }

    const toCapitalize = (s) => {
        return s.charAt(0).toUpperCase() + s.slice(1);
    }


    const clear = () => {
        setImportedData([]);
        setSelectedImportedData([]);
        setImportedCols([{ field: '', header: 'En-tete' }]);
        setDisabledImportSaveBtn(true);
    }
    const onImportSelectionChange = (e) => {
        setSelectedImportedData(e.value);
        const detail = e.value.map(d => Object.values(d)[0]).join(', ');
        toast.current.show({ severity: 'info', summary: 'Ligne selectionnée', detail, life: 3000 });
    }



    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h3 className="m-0">Evaluations</h3>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Recherche..." />
            </span>
        </div>
    );

    const headerEvalDialog = (
        <div>
            <h4>Importer des évaluations de la classe {findClasseById(classe?.id).libelle}</h4>
        </div>
    )
    const headerEvaluationDialog = (
        <div>
            <h4>{lblBtnSave} une évaluation</h4>
        </div>
    );

    const headerAppliquerDialog = (
        <>
            <Button label="Annuler" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="ok" icon="pi pi-check" className="p-button-text" onClick={save} />
        </>
    );
    const evalDialogFooter = (
        <>
            <Button label="Annuler" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="ok" icon="pi pi-check" className="p-button-text" onClick={save} />
        </>
    );

    const importDialogFooter = (
        <>
            <Button label="Annuler" icon="pi pi-times" className="p-button-text" onClick={hideImportDialog} />
            <Button label="Enregistrer" icon="pi pi-check" disabled={disabledImportSaveBtn} onClick={importation} />
        </>
    );

    const notesBody = (rowData, column) => {
        return (
            <>
                {
                    rowData.noteloaders[parseInt(column.column.props.header.split(' ')[1]) - 1].note
                }
            </>
        )
    }

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="grid card py-7">

                    <div className="col-12 md:col-4 lg:col-3 lg:mb-0">
                        <label className="mx-3" htmlFor="classe"><b>Classe</b></label>
                        <Dropdown id="classe" value={classe?.id} onChange={(e) => onSearchSelectChange(e, 'classe')} options={classes}
                                  required optionValue="id" style={{ width: '250px' }} optionLabel="libelle" placeholder="Selectionner la classe" />
                    </div>

                    <div className="col-12 md:col-4 lg:col-3 lg:mb-0">
                        <label className="mx-3" htmlFor="matiere"><b>Matière</b></label>
                        <Dropdown id="matiere" style={{ width: '250px' }} value={matiere?.id} onChange={(e) => onSearchSelectChange(e, 'matiere')} options={matieres}
                            required optionValue="id" optionLabel="libelle" placeholder="Selectionner la matière" />
                    </div>

                    <div className="col-12 md:col-4 lg:col-3 lg:mb-0">
                        <label className="mx-3" htmlFor="periode"><b>Période</b></label>
                        <Dropdown id="periode" style={{ width: '250px' }} value={periode?.id} onChange={(e) => onSearchSelectChange(e, 'periode')} options={periodes}
                            required optionValue="id" optionLabel="libelle" placeholder="Selectionner la période" />
                    </div>

                    <div className="col-12 md:col-4 lg:col-3 lg:mb-0 mb-0 mt-auto">
                        <Button label="Rechercher" icon="pi pi-search" disabled={disabled} onClick={search} />
                    </div>

                </div>
            </div>
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" right={rightToolbarTemplate} ></Toolbar>
                    <DataTable ref={dt} value={evaluations}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="affichage {first} de {last} sur {totalRecords} evaluations"
                        globalFilter={globalFilter} emptyMessage="Aucun enregistrement trouvé." header={header} responsiveLayout="scroll">
                        <Column field="id" header="N°" sortable body={idBodyTemplate}></Column>
                        <Column field="date" header="Date" sortable body={dateBodyTemplate} headerStyle={{ width: '10%', minWidth: '6rem' }}></Column>
                        <Column field="type" header="Evaluation" sortable body={typeBodyTemplate} headerStyle={{ width: '14%', minWidth: '5rem' }}></Column>
                        <Column field="matiere" header="Matière" body={matiereBodyTemplate} sortable headerStyle={{ width: '14%', minWidth: '7rem' }}></Column>
                        <Column field="periode.libelle" header="Periode" sortable body={periodeBodyTemplate} headerStyle={{ width: '14%', minWidth: '5rem' }}></Column>

                        <Column body={actionBodyTemplate} header="Actions" headerStyle={{ width: '20%', minWidth: '9rem' }}></Column>
                    </DataTable>

                </div>
            </div>

            <Dialog visible={evalDialog} style={{ width: '85%', minHeight: '70%' }} footer={evalDialogFooter} modal className="p-fluid" onHide={hideDialog} >
                <div className="formgrid grid">
                    <div className="field col">
                        <label htmlFor="chapitreDeLecon">Chapitre de la léçon</label>
                        <InputText disabled id="chapitreDeLecon" value={procesVerbal.chapitreDeLecon} />
                    </div>
                    <div className="field col">
                        <label htmlFor="noteSur">Evaluation sur:</label>
                        <InputText disabled id="noteSur" value={procesVerbal.noteSur} />
                    </div>
                </div>

                <div className="formgrid grid">
                    <div className="field col">
                        <label htmlFor="noteObtenu">Note Obtenu</label>
                        <InputText disabled id="noteObtenu"  value={procesVerbal.noteObtenu} />
                    </div>
                    <div className="field col">
                        <label htmlFor="appreciation">Appréciation</label>
                        <InputText   disabled id="appreciation" value={procesVerbal.appreciation} />
                    </div>
                    <div className="field col">
                        <label htmlFor="observation">Observation</label>
                        <InputText  disabled id="observation" value={procesVerbal.observation} />
                    </div>

                </div>
                <div className="formgrid grid">
                    <div className="field col">
                        <label htmlFor="forteNote">La plus forte note</label>
                        <InputText   disabled id="forteNote" value={procesVerbal.forteNote} />
                    </div>
                    <div className="field col">
                        <label htmlFor="faibleNote">La plus faible note</label>
                        <InputText  disabled id="faibleNote" value={procesVerbal.forteNote} />
                    </div>
                </div>
            </Dialog>
        </div>

    );
}


const comparisonFn = function (prevProps, nextProps) {
   // return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(ProcesVerbal, comparisonFn);
