import axios from 'axios';
import { BaseService } from './BaseService';

export class CoefficientService extends BaseService{


// set annee and ecole as parameters
    async getListByBranche(branche, ecole){
        return axios.get(this.getHost()+'classe-matiere/get-by-branche?branche='+branche+'&ecole='+ecole).then(resp => resp.data);
    }

    async getAllListByBrancheViaClasse(branche, ecole){
        return axios.get(this.getHost()+'classe-matiere/get-all-by-branche-via-classe?branche='+branche+'&ecole='+ecole).then(resp => resp.data);
    }

    async save(coefs){
        return axios.post(this.getHost()+'classe-matiere/maj-coefficients',coefs).then(resp => resp.data);
    }

}
