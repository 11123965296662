import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Rating } from 'primereact/rating';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import {MatiereService} from "../../service/MatiereService";
import {Dropdown} from "primereact/dropdown";
import {SouscriptionService} from "../../service/operations/SouscriptionService";
import {EleveService} from "../../service/operations/EleveService";
import Connexion from "./Connexion";
import { Password } from 'primereact/password';
import { set } from 'react-hook-form';
import { Message } from 'primereact/message';
import { TabView, TabPanel } from 'primereact/tabview';
import {FilterMatchMode} from "primereact/api";
const ModifierSouscripEcole = () => {
    let emptyProduct = {
        matiereid: null,
        matierecode: '',
        matierecoefficien: 0,
        matierelibelle: ''

    };
    const infoProfil = {
        sous_attent_personnid: null,
        sous_attent_personn_nom: '',
        sous_attent_personn_prenom: '',
        sous_attent_personn_email: '',
        sous_attent_personn_motifrefus:'',
        sous_attent_personn_statut:'',
        sous_attent_personn_contact:'',
        sous_attent_personn_contact2:''
    }


    let emptypays = {
        paysid :null ,
        payslibelle :'',
        payscode:''
    };
    let emptyRapportEtablisse = {
        etab_denomination :'' ,
        etab_num_decision_ouverture :'',
        etab_code_etablissement:'',
        etab_situation_geographique:'',
        etab_adresse:'',
        etab_telephone:'',
        etab_fax:'',
        etab_email:'',
        etab_id_ecole:null
    };

    let emptyRapportDirecteurEtude = {
        direct_adresse :'' ,
        direct_telephone :'',
        direct_cellulaire:'',
        direct_email:'',
        direct_numero_autorisation_enseigner:'',
        direct_nom_prenom:'',
        direct_code_etablissement:'',
        direct_id_ecole:null,
        emailDirecteurEtude:'',
        autoriSatDirecteurEtude:''
    };

    let emptyRapportFondateur = {
        fon_nomPrenoms :'' ,
        fon_fonction :'',
        fon_adresse:'',
        fon_telephone:'',
        fon_cellulaire:'',
        fon_email:'',
        fon_code_etablissement:'',
        fon_id_ecole:null
    };

    let emptyfonction = {
        fonctionid :null ,
        fonctioncode :'',
        fonctionlibelle:''
    };

    let emtyzone = {
         zoneid :null ,
        zonecode :'',
        zonelibelle:'',
        commune_communeid: null
    };

    let emptyDirectionGeneral = {
        id :null ,
        code :'',
        libelle:''
    };

    let emptyNiveauEnsein = {
        id :null ,
        code :'',
        libelle:''
    };

    let emptyVille = {
        villeid :null,
        villecode :'',
        villelibelle:'',
        myDirection_regionale:null
    };

    let emptyCommune = {
        communeid :null,
        communecode :'',
        communelibelle:'',
        ville_villeid:null
    };

    const listEcoleEmpty = {
        sousc_atten_etablissid: null,
          sousc_atten_etabliss_email: '',
        sousc_atten_etabliss_indication:'',
       sousc_atten_etabliss_nom:'',
        sousc_atten_etabliss_tel:'',
        sousc_atten_etablisscode:'',
        sousc_atten_etabliss_lien_autorisa:'',
        sousc_atten_etabliss_lien_logo:'',
          ville_villeid:null,
          zone_zoneid:null,
          niveau_Enseignement_id:null,
        commune_communeid:null
    }
    const [directionGen, setDirectionGen] = useState(emptyDirectionGeneral);
    const [commune, setCommune] = useState(emptyCommune);
    const [zone, setzone] = useState(emtyzone);
    const [oldzone, setoldzone] = useState(emtyzone);
    const [niveauEnseignement, setNiveauEnseignement] = useState(emptyNiveauEnsein);
    const [ville, setville] = useState(emptyVille);
    const [pays, setpays] = useState(emptypays);
    const [payss, setpayss] = useState(null);
    const defaultValues2 = {
        sousc_atten_etablissid: null,
        sousc_atten_etablisscode: '',
        sousc_atten_etabliss_nom: '',
        sousc_atten_etabliss_fondateur: '',
        sousc_atten_etabliss_tel: '',
        sousc_atten_etabliss_email:'',
        sousc_atten_etabliss_indication: '',
        sousc_atten_etabliss_lien_autorisa:'',
        sousc_atten_etabliss_lien_logo:'',
       // Niveau_Enseignement_id:null ,
       niveau_Enseignement_id:niveauEnseignement,
       niveau_Enseignement_obj :niveauEnseignement,
        ville_ville: ville,
        zone_zone: zone,
        pays : pays,
        commune_commune: commune,
       ville_villeid: null,
       zone_zoneid:null,
        commune_communeid:null,
        myDirection_regionale:directionGen,
        sousc_atten_etabliss_status:null ,
        adresse :'',
        signataire:'',
        num_decision_ouverture:''

    }
    const [listEcole, setListEcole] = useState(listEcoleEmpty);
    const [idCandidat, setIdCandidat]  = useState(sessionStorage.getItem('CandidatId'));
    const [globalFilterValue2, setGlobalFilterValue2] = useState('');
    const [listEcoles, setListEcoles] = useState([]);
    const [fonction, setFonction] = useState(emptyfonction);
    const [modif, setModif]  = useState(null);
    
    
    const [niveauEnseignements, setNiveauEnseignements] = useState(null);
    
    const [villes, setvilles] = useState([]);
   
    const [zones, setzones] = useState([]);
    const [zoneactuel, setZoneActuel] = useState();
   
    const [directionGens, setDirectionGens] = useState([]);
   
    const [communes, setCommunes] = useState([]);
    const [code, setcode] = useState('');
    const [signataire, setSignataire] = useState('');
    const [etabli, setEtabli] = useState(defaultValues2);
    const [etablis, setEtablis] = useState([]);
    const [retourApi, setretourApi] = useState('');
    const [nomEtablisse, setnomEtablisse] = useState('');
    const [nomFondateur, setnomFondateur] = useState('');
    const [motDepass, setmotDepass] = useState('');
    const [login, setLogin] = useState('');
    const [prenomFondateur, setPrenomFondateur] = useState('');
    const [telphoneEta, settelphoneEta] = useState('');
    const [telphoneEta2, settelphoneEta2] = useState('');
    const [etabliss_tel, setEtabliss_tel] = useState('');
    const [fonctionFon, setfonctionFon] = useState('FONDATEUR');
    const [emailEcole, setemailEcole] = useState('');
    const [indicationEcole, setindicationEcole] = useState('');
    const [showMessage, setShowMessage] = useState(false);
    const [matieres, setMatieres] = useState(null);
    const [productDialog, setProductDialog] = useState(false);
    const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
    const [matiere, setMatiere]  = useState(emptyProduct);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [submitted1, setSubmitted1] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [modif2, setmodif2] = useState(1);
    const [modif3, setmodif3] = useState(null);
    const [files, setFiles] = useState([]);
    const [file2, setFile2] = useState('');
    const [file1, setFile1] = useState('');
    const [logo, setLogo] = useState('');
    const [logos, setLogos] = useState([]);
    const [filigrane, setFiligrane] = useState('');
    const [faxe, setFaxe] = useState('');
    const [adresse, setAdresse] = useState('');
    const [num_decision_ouverture, setNum_decision_ouverture] = useState('');
    const [filigranes, setFiligranes] = useState([]);
    const [rapEtablissement, setRapEtablissement] = useState(emptyRapportEtablisse);
    const [directEtude, setDirectEtude] = useState(emptyRapportDirecteurEtude);
    const [rapFondateur, setRapFondateur] = useState(emptyRapportDirecteurEtude);
    const [adressDir, setAdressDir] = useState('');
    const [telephoneDir, setTelephoneDir] = useState('');
    const [cellulaireDir, setCellulaireDir] = useState('');
    const [emailDir, setEmailDir] = useState('');
    const [numAutoEnseinDir, setNumAutoEnseinDir] = useState('');
    const [nom_prenomDir, setNom_prenomDir] = useState('');
    const [code_etabDir, setCode_etabDir] = useState('');
    const [id_ecoleDir, setid_ecoleDir] = useState('');
    const [autoriSatDir, setAutoriSatDir] = useState('');
    const toast = useRef(null);
    const dt = useRef(null);

    const [filters2, setFilters2] = useState({
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'sousc_atten_etablissid':{ value: null, matchMode: FilterMatchMode.CONTAINS },
        'sousc_atten_etablisscode':{ value: null, matchMode: FilterMatchMode.CONTAINS },
        'sousc_atten_etabliss_nom':{ value: null, matchMode: FilterMatchMode.CONTAINS },
        'sousc_atten_etabliss_tel':  { value: null, matchMode: FilterMatchMode.CONTAINS } ,
        'sousc_atten_etabliss_email':  { value: null, matchMode: FilterMatchMode.CONTAINS } ,
        'sousc_atten_etabliss_indication':  { value: null, matchMode: FilterMatchMode.CONTAINS } ,
        'zone_zone.zonelibelle':  { value: null, matchMode: FilterMatchMode.CONTAINS } ,
        'commune_commune.communelibelle':  { value: null, matchMode: FilterMatchMode.CONTAINS },
        'niveau_Enseignement_obj.libelle':  { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const onGlobalFilterChange2 = (e) => {
        const value = e.target.value;
        let _filters2 = { ...filters2 };
        _filters2['global'].value = value;

        setFilters2(_filters2);
        setGlobalFilterValue2(value);
    }
    const formatCurrency = (value) => {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }

    const openNew = () => {
        setcode('');
        setpays(emptypays);
        setville(emptyVille );
        setzone(emtyzone) ;
        setindicationEcole('');
        setEtabliss_tel('');
        setemailEcole('');
        setnomEtablisse('');
         setEtabli(defaultValues2);
        setSubmitted(false);
        setProductDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
    }

    const hideDeleteProductDialog = () => {
        setDeleteProductDialog(false);
    }

    const hideDeleteProductsDialog = () => {
        setDeleteProductsDialog(false);
    }
    const onVilleChange = (e) => {
     setville(e.value);

    } ;


    const onPaysChange = (e) => {
        setpays(e.value ) ;
        console.log("Pays "+pays.payslibelle) ;
        setmodif2(2);
    } ;

    const onDirectionRegioChange = (e) => {
        setDirectionGen(e.value ) ;
        console.log("Direction Generale "+ directionGen.libelle) ;
       // setmodif2(2);
    } ;

    const onCommuneChange = (e) => {
        setCommune(e.value ) ;
        console.log("commune "+commune.communelibelle) ;
        //setmodif2(2);
    } ;


    const onZoneChange = (e) => {
    setzone(e.value);
    } ;

    const onNiveauEnseignChange = (e) => {
        setNiveauEnseignement(e.value);
        } ;

 useEffect(() => {
        const  domainServ= new SouscriptionService() ;
     domainServ.listZone().then(data => setzones(data)) ;
    }, []);



    useEffect(() => {
        const  domainServ= new SouscriptionService() ;
        if(ville.villelibelle.trim()){
           // setCommune(emptyCommune);
       domainServ.listcommuneParVille(ville.villeid).then(data => {
           setCommunes(data)

       }) ;

       //console.log("pays.paysid"+pays.paysid);
        }
    }, [ville]);




  useEffect(() => {
        const  domainServ= new SouscriptionService() ;
        if(directionGen.libelle.trim()){
            //setville(emptyVille);
       domainServ.listVilleParDirect(directionGen.id).then(data => setvilles(data)) ;
       //console.log("pays.paysid"+pays.paysid);
        }
    }, [directionGen]);


    useEffect(() => {
        const  domainServ= new SouscriptionService() ;
        if(pays.payslibelle.trim()){
            //setpays(emptypays);
       domainServ.listDirectionParPays(pays.paysid).then(data => setDirectionGens(data)) ;
       //console.log("pays.paysid"+pays.paysid);
        }

    }, [pays]);


    useEffect(() => {
        const  domainServ= new SouscriptionService() ;
        if(directionGen.libelle.trim()){
       domainServ.listDirectionParPays(pays.paysid).then(data => setDirectionGens(data)) ;
       //console.log("pays.paysid"+pays.paysid);
        }

    }, [directionGen]);




    useEffect(() => {
        const  domainServ= new SouscriptionService() ;

      domainServ.listPays().then(data => setpayss(data));
        domainServ.listNiveauEnseignement().then(data => setNiveauEnseignements(data));
        domainServ.getFondateurId(fonctionFon).then(data => setFonction(data));
          //domainServ.listZone().then(data => setzones(data));
        domainServ.getSouscipteurEtblissAmodifier(idCandidat).then(data =>
            setEtablis(data)
         );


        domainServ.infosProfil(idCandidat).then(data => {
                setnomFondateur(data.sous_attent_personn_nom)
                setPrenomFondateur(data.sous_attent_personn_prenom)
                settelphoneEta(data.sous_attent_personn_contact)
                settelphoneEta2(data.sous_attent_personn_contact2)
                setLogin(data.sous_attent_personn_email)

                //setpays(data)
        } );

    }, []);






    useEffect(()=>{
        if(modif===1){

            setShowMessage(true);
          window.location.reload(false);
        }
    },[modif])

    const saveProduct = () => {
        setSubmitted(true);

        if (commune.communecode.trim() &&code.trim()&&nomEtablisse.trim()&&
            emailEcole.trim()&&indicationEcole.trim()&&etabliss_tel.trim()&&niveauEnseignement.libelle.trim()) {

            ///etabli.pays_pays.payslibelle = pays.payslibelle;

            let _etablis = [...etablis];
            let _etabli = { ...etabli };

            if (etabli.sousc_atten_etablisscode) {
                console.log("etabli.sousc_atten_etablisscode "+ etabli.sousc_atten_etablisscode);
              const index = findIndexById(etabli.sousc_atten_etablisscode) ;
                   //const index = etabli.sousc_atten_etablisscode ;
                      console.log("index "+ index);
                _etablis[index].sousc_atten_etabliss_indication =  indicationEcole;

                _etablis[index].sousc_atten_etabliss_tel = etabliss_tel;
                _etablis[index].sousc_atten_etabliss_email =emailEcole ;
                _etablis[index].sousc_atten_etabliss_nom = nomEtablisse;

                _etablis[index].sousc_atten_etabliss_indication = indicationEcole;
                _etablis[index].sousc_atten_etablisscode = code ;
                _etablis[index].ville_villeid =ville.villeid ;

                _etablis[index].ville_ville = ville;
                _etablis[index].niveau_Enseignement_id= niveauEnseignement.id ;
                _etablis[index].niveau_Enseignement_obj = niveauEnseignement;
                _etablis[index].adresse = adresse;
                _etablis[index].signataire = signataire;
                _etablis[index].num_decision_ouverture = num_decision_ouverture;

                _etablis[index].pays = pays;
                if(commune.communecode) {
                    _etablis[index].commune_commune = commune ;
                    _etablis[index].commune_communeid= commune.communeid ;
                }
                if (directionGen.code) {
                        _etablis[index].myDirection_regionale =  directionGen;
                }
                if(zone) {
                    _etablis[index].zone_zoneid = zone.zoneid ;
                }
                _etablis[index].zone_zone = zone ;


                if(file1 && file2){
                    _etablis[index].sousc_atten_etabliss_lien_logo= files[0].name ;
                    _etablis[index].sousc_atten_etabliss_lien_autorisa= files[1].name ;

                } else if (file1) {
                    _etablis[index].sousc_atten_etabliss_lien_logo= files[0].name ;
                } else if(file2) {
                    _etablis[index].sousc_atten_etabliss_lien_autorisa= files[0].name ;
                }


             //toast.current.show({ severity: 'success', summary: 'Vies-ecole', detail: 'Ecole modifiée avec succès', life: 3000 });
            }
            else {

                 _etabli.sousc_atten_etabliss_tel = etabliss_tel;
                 _etabli.sousc_atten_etabliss_email =emailEcole ;
                _etabli.sousc_atten_etabliss_nom = nomEtablisse;
                _etabli.sousc_atten_etabliss_lien_autorisa= files[0].name ;
                _etabli.sousc_atten_etabliss_indication = indicationEcole;
                _etabli.sousc_atten_etablisscode = code ;
                _etabli.ville_villeid =ville.villeid ;
                _etabli.commune_communeid= commune.communeid ;
                _etabli.zone_zoneid = zone.zoneid ;
               // console.log("zone.zoneid "+ zone.zoneid) ;
                _etabli.ville_ville = ville;
                _etabli.myDirection_regionale = directionGen;
                _etabli.niveau_Enseignement_id= niveauEnseignement.id ;
                _etabli.niveau_Enseignement_obj = niveauEnseignement;
                _etabli.adresse = adresse;
                _etabli.signataire = signataire;
                _etabli.num_decision_ouverture = num_decision_ouverture;
                _etabli.zone_zone = zone ;
                _etabli.pays = pays;
                _etabli.commune_commune=commune ;
                _etablis.push(_etabli);
                if(files.length===2){
                    _etabli.sousc_atten_etabliss_lien_logo= files[0].name ;
                    _etabli.sousc_atten_etabliss_lien_autorisa= files[1].name ;

                } else if (file1) {
                    _etabli.sousc_atten_etabliss_lien_logo= files[0].name ;
                } else if(file2) {
                    _etabli.sousc_atten_etabliss_lien_autorisa= files[0].name ;
                }

              //  toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Created', life: 3000 });
            }
            setEtablis(_etablis) ;
        setProductDialog(false);
            setEtabliss_tel('') ;
            setemailEcole('');
            setnomEtablisse('');
            setindicationEcole('');
            setcode('');
            setzone(emtyzone) ;
            setville(emptyVille) ;
            setpays(emptypays) ;
            setNiveauEnseignement(emptyNiveauEnsein);
            setCommune(emptyCommune);
            setDirectionGen(emptyDirectionGeneral) ;
            setEtabli(defaultValues2) ;

          } else {
            toast.current.show({severity:'error', summary: 'Erreur', detail:'Veuillez renseigner les champs obligatoires', life: 3000});
        }
    }

    const editProduct = (rowData) => {
        setpays(emptypays);
        setDirectionGen(emptyDirectionGeneral);
        setville(emptyVille) ;
        setCommune(emptyCommune);
       setzone(emtyzone);

          setEtabli({ ...rowData });
          setpays(rowData.pays) ;
          setDirectionGen(rowData.myDirection_regionale);
        setville(rowData.ville_ville) ;
        setCommune(rowData.commune_commune) ;

        console.log("oldzone "+oldzone) ;
          setzone(rowData.zone_zone);
       // setoldzoneid(rowData.zone_zone==null? rowData.zone_zone.zoneid:null) ;
        //console.log("oldZoneID "+oldzoneid) ;
       setNiveauEnseignement(rowData.niveau_Enseignement_obj);
       setindicationEcole(rowData.sousc_atten_etabliss_indication);
       setcode(rowData.sousc_atten_etablisscode) ;
       setemailEcole(rowData.sousc_atten_etabliss_email)
       setnomEtablisse(rowData.sousc_atten_etabliss_nom) ;
       setEtabliss_tel(rowData.sousc_atten_etabliss_tel) ;
       setAdresse(rowData.adresse);
       setSignataire(rowData.signataire);
       setNum_decision_ouverture(rowData.num_decision);

       //
        setProductDialog(true);
    }

    const confirmDeleteProduct = (product) => {
        setEtabli(product);
        setDeleteProductDialog(true);
    }


    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < etablis.length; i++) {
            if (etablis[i].sousc_atten_etablisscode === id) {
                console.log("xxxx"+etablis[i].sousc_atten_etablisscode )
                index = i;
                break;
            }
        }

        return index;
    }

    const createId = () => {
        let id = '';
        let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < 5; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return id;
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const initMessage = () => {
        setShowMessage(false);
        setModif(null);
    }

    const confirmDeleteSelected = () => {
        setDeleteProductsDialog(true);
    }

    const deleteSelectedProducts = () => {
        setSubmitted1(true);
        if(telphoneEta.trim()&&nomFondateur.trim()&&prenomFondateur.trim()){
            let _matieres = etablis.filter(val => !selectedProducts.includes(val));
            setEtablis(_matieres);

            let _etablis = [...listEcoles];

            if(selectedProducts.length>1) {
                toast.current.show({severity:'error', summary: 'Erreur', detail:'Vous ne pouvez que modifier qu"une seule ligne ', life: 3000});
            }

            for(let i=0; i < selectedProducts.length; i++){
                let _etabli = { ...listEcole };

                _etabli.zone_zoneid = selectedProducts[i].zone_zoneid ;
                _etabli.ville_villeid = selectedProducts[i].ville_villeid ;
                _etabli.sousc_atten_etablisscode = selectedProducts[i].sousc_atten_etablisscode ;
                _etabli.sousc_atten_etabliss_email = selectedProducts[i].sousc_atten_etabliss_email ;
                _etabli.sousc_atten_etabliss_nom = selectedProducts[i].sousc_atten_etabliss_nom ;
                _etabli.sousc_atten_etabliss_indication= selectedProducts[i].sousc_atten_etabliss_indication ;
                _etabli.sousc_atten_etabliss_lien_autorisa = selectedProducts[i].sousc_atten_etabliss_lien_autorisa ;
                _etabli.sousc_atten_etabliss_lien_logo = selectedProducts[i].sousc_atten_etabliss_lien_logo ;
                _etabli.niveau_Enseignement_id = selectedProducts[i].niveau_Enseignement_id ;
                _etabli.commune_communeid= selectedProducts[i].commune_communeid ;
                _etabli.sousc_atten_etablissid=selectedProducts[i].sousc_atten_etablissid ;
                _etabli.adresse = selectedProducts[i].adresse ;
                _etabli.nom_signataire=  selectedProducts[i].signataire ;
                _etabli.numero_decision_ouver = selectedProducts[i].numero_decision_ouver
                //_etabli.niveau_Enseignement_id = 1;
                _etabli.sousc_atten_etabliss_tel = selectedProducts[i].sousc_atten_etabliss_tel ;
                console.log("_etabli "+ selectedProducts[i].id);
                console.log("_etabli ville "+ selectedProducts[i].ville_villeid);
                _etablis.push(_etabli) ;
            }
            setListEcoles(_etablis) ;
            const data = new FormData();
            for(let i = 0; i < files.length; i++) {
             data.append('file', files[i]);
            }
             const  domainServ= new SouscriptionService() ;
            const SouscrService = new SouscriptionService();

          if(files.length>0){
              domainServ.chargerFichiers(data)
                  .then((response) => {
                      SouscrService.modifierSoucripEcole(telphoneEta,telphoneEta2,idCandidat,nomFondateur,prenomFondateur,login,_etablis).then(data => {
                          console.log(data)
                          setretourApi(data)
                          setSelectedProducts(null);
                          // setfonctionFon('');
                          setnomFondateur('');
                          setPrenomFondateur('') ;
                          settelphoneEta('') ;
                          settelphoneEta2('') ;
                          setmotDepass('');
                          setLogin('');
                          setFiles([]);
                          setDeleteProductsDialog(false);
                          setSubmitted1(false);
                          setModif(1);
                      });

                  })
                  .catch((e) => {
                      // toast.error('Upload Error')
                  })
          } else {
              SouscrService.modifierSoucripEcole(telphoneEta,telphoneEta2,idCandidat,nomFondateur,prenomFondateur,login,_etablis).then(data => {
                  console.log(data)
                  setretourApi(data)
                  setSelectedProducts(null);
                  // setfonctionFon('');
                  setnomFondateur('');
                  setPrenomFondateur('') ;
                  settelphoneEta('') ;
                  settelphoneEta2('') ;
                  setmotDepass('');
                  setLogin('');
                  setFiles([]);
                  setDeleteProductsDialog(false);
                  setSubmitted1(false);
                  setModif(1);
              });
          }



        } else {
            toast.current.show({severity:'error', summary: 'Erreur', detail:'Veuillez renseigner les champs obligatoires', life: 3000});
        }

    }
    const onLoadFile2Change = (e) => {
        setFile2(e.target.files);

        console.log(e.target.files) ;

        setFiles(current => [...current, e.target.files[0]]) ;
        console.log("Nom fichier2+++ "+file2 ) ;
        //console.log("Nom fichier "+files[0].name ) ;

    };
    const onLoadFile1Change = (e) => {
        setFile1(e.target.files);

        setFiles(current => [...current, e.target.files[0]]) ;
        console.log("Nom fichier+++ "+file1 ) ;
        //console.log("Nom fichier "+files[0].name) ;


    };

    const onLoadLogoChange = (e) => {
        setLogo(e.target.files);

        setLogos(current => [...current, e.target.files[0]]) ;
        console.log("Nom fichier+++ "+logo ) ;
        //console.log("Nom fichier "+files[0].name) ;

    };

    const onLoadFiligraneChange = (e) => {
        setFiligrane(e.target.files);

        setFiligranes(current => [...current, e.target.files[0]]) ;
       // console.log("Nom fichier+++ "+logo ) ;
        //console.log("Nom fichier "+files[0].name) ;

    };

    const enregisterPhoto = () => {

        const SouscrService = new SouscriptionService();
            if(logo ){
            const data = new FormData();
            for(let i = 0; i < logos.length; i++) {
                data.append('file', logos[i]);
            }

                SouscrService.chargerPhoto(data,code ,niveauEnseignement.id)
                .then((response) => {
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Informations mises à jour avec succès!', life: 3000 });
                })
                .catch((e) => {
                    // toast.error('Upload Error')
                })



        } else {

            toast.current.show({ severity: 'error', summary: 'Error Message', detail: 'Veuillez Charger la photo du bulletin', life: 3000 });
            //setProductDialog(false);
        }


    }
    const saveInfosSupplementaire = () => {

        const SouscrService = new SouscriptionService();
        rapEtablissement.etab_adresse= adresse ;
        rapEtablissement.etab_code_etablissement= code ;
        rapEtablissement.etab_fax = faxe;
        rapEtablissement.etab_email= emailEcole ;
        rapEtablissement.etab_denomination= nomEtablisse ;
        rapEtablissement.etab_num_decision_ouverture= num_decision_ouverture ;
        rapEtablissement.etab_telephone= telphoneEta ;
        rapEtablissement.etab_situation_geographique= indicationEcole ;
        rapEtablissement.etab_id_ecole= 31 ;

        SouscrService.miseAjourRapportInfosEtabliss(rapEtablissement)
            .then((response) => {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Informations mises à jour avec succès!', life: 3000 });
            })
            .catch((e) => {
                // toast.error('Upload Error')
            })


    }
    const saveInfosDirecteurEtude = () => {

        const SouscrService = new SouscriptionService();
         directEtude.direct_adresse = adressDir ;
        directEtude.direct_telephone= telephoneDir ;
        directEtude.direct_cellulaire= cellulaireDir;
        directEtude.direct_email= emailDir ;
        directEtude.direct_numero_autorisation_enseigner= numAutoEnseinDir ;
        directEtude.direct_nom_prenom = nom_prenomDir ;
        directEtude.direct_code_etablissement= code ;
        directEtude.direct_id_ecole= null;


        SouscrService.miseAjourRapportInfosEtabliss(rapEtablissement)
            .then((response) => {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Informations mises à jour avec succès!', life: 3000 });
            })
            .catch((e) => {
                // toast.error('Upload Error')
            })


    }



    const enregisterFiligrane = () => {

        const SouscrService = new SouscriptionService();
        if(filigrane ){
            const data = new FormData();
            for(let i = 0; i < filigranes.length; i++) {
                data.append('file', filigranes[i]);
            }

            SouscrService.chargerFiligrane(data,code,niveauEnseignement.id)
                .then((response) => {
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Filigrane chargée avec succès!', life: 3000 });
                })
                .catch((e) => {
                    // toast.error('Upload Error')
                })



        } else {

            toast.current.show({ severity: 'error', summary: 'Error Message', detail: "Veuillez Charger l'image traité", life: 3000 });
            //setProductDialog(false);
        }


    }




    const onCategoryChange = (e) => {
        let _product = { ...matiere };
        _product['category'] = e.value;
        setMatiere(_product);
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _matiere = { ...matiere };
        _matiere[`${name}`] = val;

        setMatiere(_matiere);
    }

    const onInputChange1 = (e, name) => {
        const val = (e.target && e.target.value) || '';

    }

    const onInputNumberChange = (e, name) => {
        const val = e.value || 0;
        let _matiere = { ...matiere };
        _matiere[`${name}`] = val;

        setMatiere(_matiere);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                {/*<Button label="Ajouter" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />*/}
                <Button label="Appliquer les modifications" icon="pi pi-plus" className="p-button-success mr-2" onClick={confirmDeleteSelected} disabled={!selectedProducts || !selectedProducts.length} />
                 </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedProducts || !selectedProducts.length} />

            </React.Fragment>
        )
    }

    const libelleBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Nom</span>
                {rowData.sousc_atten_etabliss_nom}
            </>
        );
    }

const zoneBodyTemplate2 = (rowData) => {
        return (
            <>
                <span className="p-column-title">zone</span>

                {rowData.zone_zone==null? '': rowData.zone_zone.zonelibelle}
            </>
        );
    }



    const niveanEnseignBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Niveau Enseignement</span>
                {rowData.niveau_Enseignement_obj.libelle}
            </>
        );
    }
    const autoriEnseignBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Fichier (1)</span>
                {rowData.sousc_atten_etabliss_lien_autorisa}
            </>
        );
    }

    const logoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Fichier (2)</span>
                {rowData.sousc_atten_etabliss_lien_logo}
            </>
        );
    }

    const villeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Ville</span>
                {rowData.ville_ville.villelibelle}
            </>
        );
    }
    const communeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">commune</span>
                {rowData.commune_commune.communelibelle}
            </>
        );
    }
    const telephoneBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Téléphone</span>
                {rowData.sousc_atten_etabliss_tel}
            </>
        );
    }

    const emailBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Email</span>
                {rowData.sousc_atten_etabliss_email}
            </>
        );
    }
    const indicationBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Email</span>
                {rowData.sousc_atten_etabliss_indication}
            </>
        );
    }

    const codeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Code</span>
                {rowData.sousc_atten_etablisscode}
            </>
        );
    }

    const idBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">N°</span>
                {rowData.sousc_atten_etablissid}
            </>
        );
    }




    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Name</span>
                {rowData.name}
            </>
        );
    }

    const imageBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Image</span>
                <img src={`assets/demo/images/product/${rowData.image}`} alt={rowData.image} className="shadow-2" width="100" />
            </>
        )
    }

    const priceBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Price</span>
                {formatCurrency(rowData.price)}
            </>
        );
    }

    const categoryBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Category</span>
                {rowData.category}
            </>
        );
    }

    const ratingBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Reviews</span>
                <Rating value={rowData.rating} readonly cancel={false} />
            </>
        );
    }

    const statusBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Status</span>
                <span className={`product-badge status-${rowData.inventoryStatus.toLowerCase()}`}>{rowData.inventoryStatus}</span>
            </>
        )
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                 <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editProduct(rowData)} />
                {/*<Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteProduct(rowData)} />*/}
            </div>
        );
    }
    const renderHeader2 = () => {
        return (
            <div className="flex justify-content-end">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue2} onChange={onGlobalFilterChange2} placeholder="Mots clés..." />
                </span>
            </div>
        )
    }
    const header2 = renderHeader2();
    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Souscription écoles</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );
    const dialogFooter = <div className="flex justify-content-center"><Button label="OK" className="p-button-text" autoFocus onClick={() => initMessage()} /></div>;
    const productDialogFooter = (
        <>


        </>
    );
    const deleteProductDialogFooter = (
        <>
            <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            {/*<Button label="Oui" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />*/}
        </>
    );
    const deleteProductsDialogFooter = (
        <>
            <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductsDialog} />
            <Button label="Oui" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedProducts} />
        </>
    );

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <div className="card p-fluid">
                        <div className="formgrid grid">
                            <div className="field col">
                                <span className="p-float-label">
                                    <InputText type="text" id="fonction" disabled value={fonctionFon} onChange={(e) => setfonctionFon(e.target.value)} className={classNames({ 'p-invalid': submitted1 && !fonctionFon})} />
                                    <label htmlFor="fonction">Fonction</label>
                                </span>
                            </div>

                            <div className="field col">
                                <span className="p-float-label">
                                    <InputText type="text" id="nomFondateur" value={nomFondateur} onChange={(e) => setnomFondateur(e.target.value)} required autoFocus className={classNames({ 'p-invalid': submitted1 && !nomFondateur})}/>
                                    <label htmlFor="nomFondateur">Nom du fondateur*</label>
                                </span>
                            </div>
                        </div>

                        <div className="formgrid grid">
                            <div className="field col">
                                <span className="p-float-label">
                                    <InputText type="text" id="prenomFondateur" value={prenomFondateur} onChange={(e) => setPrenomFondateur(e.target.value)} className={classNames({ 'p-invalid': submitted1 && !prenomFondateur})} />
                                    <label htmlFor="prenomFondateur">Prénom  du fondateur*</label>
                                </span>
                            </div>
                            <div className="field col">
                                <span className="p-float-label">
                                    <InputText type="text" id="telephone" value={telphoneEta} onChange={(e) => settelphoneEta(e.target.value)} className={classNames({ 'p-invalid': submitted1 && !telphoneEta})} />
                                    <label htmlFor="telephone">Téléphone  du fondateur*</label>
                                </span>
                            </div>

                            <div className="field col">
                                <span className="p-float-label">
                                    <InputText type="text" id="telephone" value={telphoneEta2} onChange={(e) => settelphoneEta2(e.target.value)} className={classNames({ 'p-invalid': submitted1 && !telphoneEta})} />
                                    <label htmlFor="telephone">Deuxième Téléphone  du fondateur</label>
                                </span>
                            </div>
                        </div>
                        <div className="formgrid grid">

                            <div className="field col">
                            <span className="p-float-label">
                                    <InputText type="text" id="Email" disabled value={login} onChange={(e) => setLogin(e.target.value)} required />
                                    <label htmlFor="Email">Email personnel*</label>
                                </span>
                            </div>
                        </div>

                    </div>
                    <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>

                    <DataTable ref={dt} value={etablis} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                        dataKey="sousc_atten_etablisscode" paginator size="small" rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} école"
                               filters={filters2} filterDisplay="row"
                               globalFilterFields={['sousc_atten_etablissid','sousc_atten_etablisscode', 'sousc_atten_etabliss_nom','sousc_atten_etabliss_tel','sousc_atten_etabliss_email', 'sousc_atten_etabliss_indication',
                                   'sousc_atten_etabliss_indication','ville_ville','commune_commune.communelibelle','Niveau_Enseignement_id']}
                                  emptyMessage="Aucune école enregistrée." header={header2} responsiveLayout="scroll">
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem'}}></Column>
                        <Column field="sousc_atten_etablissid" header="N°" sortable body={idBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>

                        <Column field="sousc_atten_etablisscode" header="Code" sortable body={codeBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>

                        <Column field="sousc_atten_etabliss_nom" header="Nom" sortable body={libelleBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>

                        <Column field="sousc_atten_etabliss_tel" header="Téléphone" sortable body={telephoneBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>

                        <Column field="sousc_atten_etabliss_email" header="Email" sortable body={emailBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>

                        <Column field="sousc_atten_etabliss_indication" header="Indication" sortable body={indicationBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>

                        {/*<Column field="ville.ville.villelibelle" header="Ville" sortable body={villeBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
*/}
                        <Column field="commune_commune.communelibelle" header="Commune" sortable body={communeBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>

                   <Column field="zone_zone.zonelibelle" header="Zone" sortable body={ etabli.zone_zone  == null ? '' :zoneBodyTemplate2} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>

                        <Column field="niveau_Enseignement_obj.libelle" header="Niveau d'enseignement" sortable body={niveanEnseignBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="sousc_atten_etabliss_lien_autorisa" header="Fichier(2)" body={autoriEnseignBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>

                        <Column field="sousc_atten_etabliss_lien_logo" header="Fichier(1)"  body={logoBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>

                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={showMessage} onHide={() => setShowMessage(false)} position="top" footer={dialogFooter} showHeader={false} breakpoints={{ '960px': '80vw' }} style={{ width: '30vw' }}>
                        <div className="flex justify-content-center flex-column pt-6 px-3">
                            <i className="pi pi-check-circle" style={{ fontSize: '5rem', color: 'var(--green-500)' }}></i>
                            <h5>{"Demande de création d'école"}</h5>
                            <p style={{ lineHeight: 1.5, textIndent: '1rem' }}>
                                {retourApi}
                            </p>
                        </div>
                    </Dialog>

                    <Dialog visible={productDialog} style={{ width: '900px' }} header={"Détails école"}  modal className="p-fluid"  onHide={hideDialog}>

                        <TabView>
                            <TabPanel header="Informarmation sur l'établissement">
                                <div className="formgrid grid">
                                    <div className="field col">
                                        <label htmlFor="pays">Pays <span className="text-orange-500" > *</span></label>
                                        <Dropdown id="pays" options={payss} value={pays} onChange={onPaysChange} optionLabel="payslibelle" required autoFocus ></Dropdown>
                                    </div>
                                    <div className="field col">
                                        <label htmlFor="direcReg">Direction Regionale <span className="text-orange-500" > *</span> </label>
                                        <Dropdown id="direcReg" options={directionGens} value={directionGen} onChange={onDirectionRegioChange} optionLabel="libelle" required autoFocus ></Dropdown>
                                    </div>
                                </div>
                                <div className="formgrid grid">

                                    <div className="field col">
                                        <label htmlFor="ville1">Ville <span className="text-orange-500" > *</span></label>
                                        <Dropdown id="ville1" options={villes} value={ville} onChange={onVilleChange} optionLabel="villelibelle"></Dropdown>
                                    </div>
                                    <div className="field col">
                                        <label htmlFor="commune1">Commune  <span className="text-orange-500" > *</span></label>
                                        <Dropdown id="commune1" options={communes} value={commune} onChange={onCommuneChange} optionLabel="communelibelle"></Dropdown>
                                    </div>

                                    {<div className="field col">
                                        <label htmlFor="zone">Zone</label>
                                        <Dropdown id="zone" options={zones} value={zone} onChange={onZoneChange}optionLabel="zonelibelle"  ></Dropdown>
                                    </div>}
                                </div>

                                <div className="formgrid grid">
                                    <div className="field col">
                                        <label htmlFor="nomEtablisse">Nom de l' Etablissement <span className="text-orange-500" > *</span> </label>

                                        <InputText type="text" id="nomEtablisse" value={nomEtablisse} onChange={(e) => setnomEtablisse(e.target.value)} className={classNames({ 'p-invalid': submitted && !nomEtablisse})}/>


                                    </div>

                                    <div className="field col">
                                        <label htmlFor="emailEcole">Email de l'Etablissement <span className="text-orange-500" > *</span></label>

                                        <InputText type="text" id="emailEcole" value={emailEcole} onChange={(e) => setemailEcole(e.target.value)}   className={classNames({ 'p-invalid': submitted && !emailEcole})}/>

                                    </div>
                                    <div className="field col">
                                        <label htmlFor="niveauEn">Niveau d'enseignement  <span className="text-orange-500" > *</span> </label>
                                        <Dropdown id="niveauEn" options={niveauEnseignements} value={niveauEnseignement} onChange={onNiveauEnseignChange}optionLabel="libelle"  ></Dropdown>
                                    </div>

                                </div>


                                <div className="formgrid grid">
                                    <div className="field col">
                                            <span className="p-float-label">
                                                <InputText type="text" id="telephonEcole" value={etabliss_tel} onChange={(e) => setEtabliss_tel(e.target.value)} />
                                                <label htmlFor="telephonEcole">Téléphone de l'Etablissement <span className="text-orange-500" > *</span> </label>
                                            </span>
                                    </div>
                                    <div className="field col">
                                            <span className="p-float-label">
                                                <InputText type="text" id="code" disabled value={code} onChange={(e) => setcode(e.target.value)}  className={classNames({ 'p-invalid': submitted && !code})}/>
                                                <label htmlFor="code">Code Etablissement  <span className="text-orange-500" > *</span> </label>
                                            </span>
                                    </div>

                                </div>

                                <div className="formgrid grid">
                                    <div className="field col">
                                        <label htmlFor="file3">Charger l'autorisation de création (.pdf)</label>
                                        <InputText  accept=".pdf, .PDF"  required  id="file3" type="file" onChange={onLoadFile2Change}
                                                    multiple=""/>
                                    </div>

                                    <div className="field col">
                                        <label htmlFor="file2">Charger logo (jpeg)</label>
                                        <InputText  accept=".jpg, .jpeg"  required id="file2" type="file" onChange={onLoadFile1Change}
                                                    multiple=""/>
                                    </div>

                                </div>


                                <div className="field grid">
                                    <div className="col-12 md:col-12">
                                    <span className="p-float-label">
                                        <InputText type="text" id="indicationEcole" value={indicationEcole} onChange={(e) => setindicationEcole(e.target.value)} />
                                        <label htmlFor="indicationEcole">Indication de l'Etablissement  <span className="text-orange-500" > *</span> </label>
                                    </span>
                                    </div>
                                </div>

                                <div className="formgrid grid">
                                    <div className="field col">
                                        <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
                                    </div>

                                    <div className="field col">
                                        <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
                                    </div>
                                </div>



                            </TabPanel>
                            <TabPanel header="Infos supplementaire établissement">
                                <div className="formgrid grid">
                                    <div className="field col">
                                         <span className="p-float-label">
                                                <InputText type="text" id="signataire" value={signataire} onChange={(e) => setSignataire(e.target.value)} />
                                                <label htmlFor="signataire">Signataire  <span className="text-orange-500" > </span> </label>
                                            </span>
                                    </div>

                                    <div className="field col">
                                            <span className="p-float-label">
                                                <InputText type="text" id="adresse" value={adresse} onChange={(e) => setAdresse(e.target.value)} />
                                                <label htmlFor="adresse"> Adresse </label>
                                            </span>
                                    </div>
                                </div>


                                <div className="formgrid grid">
                                    <div className="field col">
                                        <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
                                    </div>

                                    <div className="field col">
                                        <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
                                    </div>
                                </div>

                            </TabPanel>
                            <TabPanel header="Paramètre des bulletins">

                                <div className="formgrid grid">
                                <div className="field col">

                                    <label htmlFor="file1">Charger logo pour bulletin</label>
                                    <InputText  accept=".jpg, .jpeg"  required id="file1" type="file" onChange={onLoadLogoChange}
                                                multiple=""/>
                                </div>
                                    <div className="field col">
                                        <label htmlFor="niveauEn">Niveau d'enseignement  <span className="text-orange-500" > *</span> </label>
                                        <Dropdown id="niveauEn" options={niveauEnseignements} value={niveauEnseignement} onChange={onNiveauEnseignChange}optionLabel="libelle"  disabled={true} ></Dropdown>
                                    </div>
                                </div>

                                    <div className="formgrid grid">
                                    <div className="field col">
                                        <Button label="Cliquer ici pour charger les informations"  className="p-button-text" onClick={enregisterPhoto} />
                                    </div>
                                    </div>
                                <div className="formgrid grid">
                                <div className="field col">
                                    <Message text="Assurez vous de charger une grande image avec un fond transparent et une opacité reduite" />
                                    <label htmlFor="file2">Charger filigrane pour bulletin</label>
                                    <InputText  accept=".jpg, .jpeg"  required id="file2" type="file" onChange={onLoadFiligraneChange}
                                                multiple=""/>
                                    <div className="field col">
                                        <label htmlFor="niveauEn">Niveau d'enseignement  <span className="text-orange-500" > *</span> </label>
                                        <Dropdown id="niveauEn" options={niveauEnseignements} value={niveauEnseignement} onChange={onNiveauEnseignChange}optionLabel="libelle" disabled={true}   ></Dropdown>
                                    </div>
                                    <Button label="Cliquer ici pour charger le filigrane" className="p-button-text" onClick={enregisterFiligrane} />

                                </div>

                                    </div>

                            </TabPanel>

                           {/* <TabPanel header="Infos Directeur des études">
                                <div className="formgrid grid">
                                    <div className="field col">
                                            <span className="p-float-label">
                                                <InputText type="text" id="NomPrenom" value={nom_prenomDir} onChange={(e) => setNom_prenomDir(e.target.value)} />
                                                <label htmlFor="NomPrenom"> Nom et Prénom  </label>
                                            </span>
                                    </div>

                                    <div className="field col">
                                            <span className="p-float-label">
                                                <InputText type="text" id="EmailD" value={emailDir} onChange={(e) => setEmailDir(e.target.value)} />
                                                <label htmlFor="EmailD"> Email  </label>
                                            </span>
                                    </div>
                                </div>
                                <div className="formgrid grid">
                                    <div className="field col">
                                         <span className="p-float-label">
                                                <InputText type="text" id="TeleD" value={telephoneDir} onChange={(e) => setTelephoneDir(e.target.value)} />
                                                <label htmlFor="TeleD"> Téléphone  </label>
                                            </span>
                                    </div>

                                    <div className="field col">
                                         <span className="p-float-label">
                                                <InputText type="text" id="CelD" value={cellulaireDir} onChange={(e) => setCellulaireDir(e.target.value)} />
                                                <label htmlFor="CelD"> Cellulaire  </label>
                                            </span>
                                    </div>

                                    <div className="field col">
                                         <span className="p-float-label">
                                                <InputText type="text" id="AdrD" value={adressDir} onChange={(e) => setAdressDir(e.target.value)} />
                                                <label htmlFor="AdrD"> Adresse  </label>
                                            </span>
                                    </div>
                                </div>
                                <div className="formgrid grid">
                                    <div className="field col">
                                        <span className="p-float-label">
                                            <InputText type="text" id="numero_autor" value={numAutoEnseinDir} onChange={(e) => setNumAutoEnseinDir(e.target.value)} />
                                            <label htmlFor="numero_autor"> Numero d'autorisation d'enseigner  </label>
                                            </span>
                                    </div>
                                    <div className="field col">
                                        <span className="p-float-label">
                                            <InputText type="text" id="numero_autor" value={numAutoEnseinDir} onChange={(e) => setNumAutoEnseinDir(e.target.value)} />
                                            <label htmlFor="numero_autor"> Autorisation Directeur des études  </label>
                                            </span>
                                    </div>
                                </div>

                                <div className="formgrid grid">
                                    <div className="field col">
                                        <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveInfosSupplementaire} />
                                    </div>

                                    <div className="field col">
                                        <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
                                    </div>
                                </div>

                            </TabPanel>*/}



                        </TabView>


                    </Dialog>

                    <Dialog visible={deleteProductDialog} style={{ width: '450px' }} header="Confirmer" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {etabli && <span>Voulez vous supprimer cette ligne?<b>{etabli.sousc_atten_etabliss_nom}</b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteProductsDialog} style={{ width: '450px' }} header="Confirmer" modal footer={deleteProductsDialogFooter} onHide={hideDeleteProductsDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {etabli && <span>Voulez vous enregistrer cette modification?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

//export default React.memo(SouscripEcole, comparisonFn);
export default ModifierSouscripEcole;
