import axios from 'axios';
import { BaseService } from '../BaseService';
const BASE_URL ="http://localhost:83/jasperserver/rest_v2/reports/Reports/";

export class RapportService  extends BaseService{

    listRapports(IdNiveau) {
        return axios.get(this.getHost()+'rapports/'+IdNiveau).then(res => res.data);
    }

    listAnnee(idEcole) {
        return axios.get(this.getHost()+'annee/list-opened-or-closed-to-ecole?ecole='+idEcole).then(res => res.data);
    }

    listPeriode() {
        return axios.get(this.getHost()+'periodes/list').then(res => res.data);
    }

    getListClasse(){
        return axios.get(this.getHost()+'classes/list').then(res => res.data);
    }

    getListClasseParEcole(idEcole){
        return axios.get(this.getHost()+'classes/list-populate-by-ecole?ecole='+idEcole).then(res => res.data);
    }
    imprimerBulletin(pdf,matricule,idEcole,libelleAnne) {
        return axios.get(this.getHost()+"imprimer-bulletin/details-bulletin/"+pdf+"/"+matricule+"/"+idEcole+"/"+libelleAnne).then(res => res.data);
    }

    connexionJasper() {
        return axios.get("http://localhost:83/jasperserver/rest_v2/login?j_username=jasperadmin&j_password=jasperadmin").then(res => res.data);
    }

    CertificatScolarite(codeEleve,matricule) {
        return axios.get(BASE_URL+'Documents_administratifs/Certificat_de_scolarite.pdf?j_username=jasperadmin&j_password=jasperadmin&codeEcole='+codeEleve+'&matriculeEleve='+matricule).then(res => res.data);
    }

    Certificattravail(codeEleve,matriculeAgent) {
        return axios.get(BASE_URL+'Documents_administratifs/Certificat_de_travail.pdf?j_username=jasperadmin&j_password=jasperadmin&codeEcole='+codeEleve+'&matriculeAgent='+matriculeAgent).then(res => res.data);
    }

    matriculeParClasse(idEcole,classe,periode) {
        return axios.get(this.getHost()+'imprimer-bulletin/list-matricule-par-classe/'+idEcole+'/'+classe+'/'+periode).then(res => res.data);
    }


    Certificatfrequentation(codeEcole,matricule) {
        return axios.get(BASE_URL+'Documents_administratifs/Certificat_de_frequentation.pdf?j_username=jasperadmin&j_password=jasperadmin&codeEcole='+codeEcole+'&matricule='+matricule).then(res => res.data);
    }


    tableauHonneurParClasse(codeEleve,classe) {
        return axios.get(BASE_URL+'Documents_administratifs/DetailTableauHonneur.pdf?j_username=jasperadmin&j_password=jasperadmin&codeEcole='+codeEleve+'&classe='+classe).then(res => res.data);
    }
    rapportGlobal(codeEcole) {
        return axios.get(BASE_URL+'Rapports_Pedagogiques/RapportGlobal2.pdf?j_username=jasperadmin&j_password=jasperadmin&codeEcole='+codeEcole).then(res => res.data);
    }

}
