import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { CoefficientService } from '../service/CoefficientService';
import { BrancheService } from '../service/BrancheService';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
// import { MatiereService } from '../service/MatiereService';
import { MatiereEcoleService } from '../service/MatiereEcoleService';

const CoefficientMatiere = () => {


    const [coefficients, setCoefficients] = useState(null);

    const [coefsChanged, setCoefsChanged] = useState([]);
    const [branches, setBranches] = useState(null);
    const [branche, setBranche] = useState(null);

    const [submitted, setSubmitted] = useState(false);
    const [matiere, setMatiere] = useState({id:null});
    const [matieresEcole, setMatieresEcole] = useState([]);
    const [coefficient, setCoefficient] = useState({});
    const [matiereDialog, setMatiereDialog] = useState(false);
    const [headerTitle, setHeaderTitle] = useState("");
    const [disabledSave, setDisabledSave] = useState(false);

    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    let emptyCoef = {
        id: null,
        coef: 1
    }

    useEffect(() => {
        const brancheService = new BrancheService();
        setDisabledSave(true);
        brancheService.getByNiveauEnseigment(sessionStorage.getItem('EcoleID')).then(resp => setBranches(resp));

    }, []);


    const onSelectChange = (e) => {
        const val = (e.target && e.target.value) || '';
        const coefficientService = new CoefficientService();

        let _branche = { ...branche };
        let index = findIndexExist(val, branches);
        console.log(index);
        _branche = branches[index];
        // _branche.id = val;
        setBranche(_branche);
        coefficientService.getListByBranche(val, sessionStorage.getItem('EcoleID')).then(
            resp => setCoefficients(resp)
        )

    }

    const onSelectMatiereChange = (e) => {
        const val = (e.target && e.target.value) || '';
        console.log(val);
        let _matiere = { ...matiere };
        _matiere.id = val;
        setMatiere(_matiere);
    }

    const update = () => {
        const coefService = new CoefficientService();
        console.log(coefsChanged);
        if (coefsChanged.length > 0) {

            coefService.save(coefsChanged).then(
                resp => {
                    setCoefficients(resp);
                    setCoefsChanged([]);
                    setDisabledSave(true);
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Opération bien effectuée', life: 3000 });
                }
            ).catch((error) => {
                if (error.response) {
                    console.log('erreur response- vie ecole- seances - save' + error.response.status);
                } else if (error.request) {
                    console.log('erreur request- vie ecole' + error.request);
                } else {
                    console.log('Error autres', error.message);
                }
                toast.current.show({ severity: 'error', summary: 'Successful', detail: error.response.data, life: 3000 });
            });
        }
    }

    const save = () => {
        setSubmitted(true);
        console.log(matiere);
        console.log(coefficient);
        if (matiere?.id!= null && Object.keys(coefficient).length !== 0 ) {

            const coefService = new CoefficientService();
            let _coef = { ...coefficient };
            let _ecole = { id: sessionStorage.getItem('EcoleID') };
            _coef.id = 0;
            _coef.matiere = { ...matiere };
            _coef.branche = branche;
            _coef.ecole = { ..._ecole };

            let _coef_toList = [_coef];
            console.log(_coef_toList);
            coefService.save(_coef_toList).then(
                resp => {
                    setCoefficients(resp);
                    //setCoefsChanged([]);
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Opération bien effectuée', life: 3000 });
                    hideDialog();
                }
            ).catch((error) => {
                if (error.response) {
                    console.log('erreur response- vie ecole- seances - save' + error.response.status);
                    console.log(error.response);
                } else if (error.request) {
                    console.log('erreur request- vie ecole' + error.request);
                } else {
                    console.log('Error autres', error.message);
                }
                toast.current.show({ severity: 'error', summary: 'Erreur', detail: error.response.data, life: 3000 });
            })
        }
    }

    const findIndexExist = (id, list) => {
        let index = -1;
        for (let i = 0; i < list.length; i++) {
            if (list[i].id == id) {
                index = i;
                break;
            }
        }

        return index;
    }

    const addCoefForSave = (e) => {
        // console.log(e.target.value + '  ' + e.target.id);
        let id = e.target.id.split("_")[1];
        let index;
        let index_2;
        let _coefsChanged = [...coefsChanged];
        let value = e.target.value;
        let _coef = { ...emptyCoef };
        let _ecole = { id: sessionStorage.getItem('EcoleID') };
        let _branche = {};
        _coef.id = id;
        _coef.coef = value;

        // console.log(_coef);

        index = findIndexExist(id, _coefsChanged);
        index_2 = findIndexExist(id, coefficients);

        _branche = { ...coefficients[index_2].branche };
        // console.log(_branche);
        _coef.branche = { ...branche };
        _coef.ecole = { ..._ecole };
        _coef.matiere = { ...coefficients[index_2].matiere };

        if (index >= 0) {
            _coefsChanged[index].coef = value;
        } else {
            _coefsChanged = _coefsChanged.concat([_coef]);
        }

        setCoefsChanged(_coefsChanged);
        setDisabledSave(false);

        console.log(_coefsChanged);
    }

    const onInputCoefChange = (e) => {
        let _coef = { ...coefficient }
        _coef.coef = (e.target && e.target.value) || 1;
        console.log(_coef);
        setCoefficient(_coef);
    }

    const openNew = () => {
        setSubmitted(false);
        setMatiereDialog(true);
        setHeaderTitle("Associer une matière à une branche");

        let matiereEcoleService = new MatiereEcoleService();

        matiereEcoleService.getByEcoleViaNiveauEnseignement(sessionStorage.getItem('EcoleID')).then(
            resp => setMatieresEcole(resp)
        )
        console.log('coefficient ::: ');
        console.log(coefficient);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setMatiereDialog(false);
        setCoefficient({});
        setMatiere({id:null});
    }

    const brancheBodyTemplate = (row) => (

        <div>
            {row.branche.libelle}
        </div>
    );
    const matiereBodyTemplate = (row) => (

        <div>
            {row.matiere.libelle}
        </div>
    );
    const coefBodyTemplate = (row) => (

        <div>
            <InputNumber id={'input_' + row.id} style={{ width: '50px' }} min={0} value={row.coef} onValueChange={addCoefForSave}></InputNumber>
        </div>
    );

    const rigthToolbarContent = (
        <React.Fragment>
            <div className="my-2">
                <Button label="Enregistrer" disabled={disabledSave} icon="pi pi-save" className="p-button-success mr-2" onClick={update} />
                <Button label="Ajouter" icon="pi pi-plus" className="p-button-info" onClick={openNew} disabled={!branche?.id} />
            </div>
        </React.Fragment>
    )

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h3> Liste des matières par branches</h3>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Recherche..." />
            </span>
        </div>
    );

    const matiereDialogFooter = (
        <>
            <Button label="Annuler" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Enregistrer" icon="pi pi-check" className="p-button-text" onClick={save} />
        </>
    );

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <div>
                        <label><h3><b>Définir les coefficients des matières</b></h3></label>
                    </div>
                    <div className="md:col-offset-4">
                    </div>
                    <div className="col-12 md:col-4 lg:col-4 lg:mb-0">
                        <label className="mx-3" htmlFor="typePersonnel"><b>Branche</b></label>
                        <Dropdown id="typePersonnel" value={branche?.id} filter onChange={(e) => onSelectChange(e)} options={branches}
                            required optionValue="id" optionLabel="libelle" placeholder="Selectionner la branche" />
                    </div>

                </div>
            </div>

            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar right={rigthToolbarContent} className="mb-4"></Toolbar>

                    <DataTable ref={dt} value={coefficients}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="affichage {first} de {last} sur {totalRecords} classes"
                        globalFilter={globalFilter} emptyMessage="Aucun enregistrement trouvé." header={header} responsiveLayout="scroll">
                        <Column field="branche.id" header="Branche" body={brancheBodyTemplate} headerStyle={{ width: '14%', minWidth: '7rem' }}></Column>
                        <Column field="matiere.id" header="Matière" body={matiereBodyTemplate} headerStyle={{ width: '14%', minWidth: '7rem' }}></Column>
                        <Column field="coefficient.id" header="Coef." body={coefBodyTemplate} headerStyle={{ width: '14%', minWidth: '7rem' }}></Column>
                    </DataTable>
                </div>
            </div>

            <Dialog visible={matiereDialog} style={{ width: '40%' }} header={headerTitle} modal className="p-fluid" footer={matiereDialogFooter} onHide={hideDialog}>


                <div className="mt-3 grid p-fluid">

                    <div className="field col-8">
                        <label htmlFor="libelle"><b>Branche</b></label>
                        <InputText id="libelle" value={branche?.libelle} disabled='disabled' required className={classNames({ 'p-invalid': submitted && !branche?.libelle })} />
                    </div>

                    <div className="field col-8">
                        <label htmlFor="matiere"><b>Matiere</b></label>
                        <Dropdown id="matiere" value={matiere?.id} onChange={(e) => onSelectMatiereChange(e)} options={matieresEcole} optionValue="id" optionLabel="libelle" placeholder="Selectionner la matière" className={classNames({ 'p-invalid': submitted && !matiere?.id })} />
                        {submitted && !matiere.id && <small className="p-invalid">La matière est requise.</small>}
                    </div>

                    <div className="field col-8">
                        <label htmlFor="coef"><b>Coefficient</b></label>
                        <InputNumber id='coef' className={'mx-2 '+classNames({ 'p-invalid': submitted && !coefficient?.id})} style={{ width: '50px' }} min={1} value={coefficient?.coef} mode='decimal' useGrouping={false} onValueChange={(e) => onInputCoefChange(e)}></InputNumber>
                        {submitted && !coefficient?.id && <small className="p-invalid">Le coefficient est requis.</small>}
                    </div>

                </div>

            </Dialog>

        </div>
    );

}
const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(CoefficientMatiere, comparisonFn);
