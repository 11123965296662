import React, { useRef } from 'react';
import FileViewer from 'react-file-viewer';

const FileDemo = () => {
    const toast = useRef(null);
    const file = 'https://pouls-scolaire.com/api/souscription-personnel/ouvrir-fichier/ecole2.jpg'
    const type = 'PDF'


    return (
        <FileViewer
            fileType={type}

            filePath={file}
        />
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(FileDemo, comparisonFn);
