import axios from 'axios';
import { BaseService } from './BaseService';

const constants = {
    host: 'http://localhost:8889/gestion-ecole-api',    
  };

export class HeureService extends BaseService {

    getList(){
        return axios.get(this.getHost()+'heures/list').then(res => res.data)
        .catch((error)=>{console.log('Erreur du service getList - HeureService',error.message)}); 
    }
}