import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';

import { Toolbar } from 'primereact/toolbar';

import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import {EleveService} from '../../service/operations/EleveService';


import {Dropdown} from "primereact/dropdown";

import {FilterMatchMode} from "primereact/api";
import {ProgressSpinner} from "primereact/progressspinner";

const Inscription = () => {
    let emptyeleve = {
        eleve_mail :'' ,
        eleve_numero_extrait_naiss :0,
        eleveadresse:'',
        elevecellulaire :'',
        elevecode :'',
        elevedate_etabli_extrait_naiss :null,
        elevedate_naissance :null,
        eleveid:null ,
        eleveSexe : '',
        elevelieu_etabliss_etrait_naissance :'',
        elevelieu_naissance:'',
        elevenom:'',
        eleveprenom:'',
        elevematricule_national:'',
        parentList:[]
    };

    let emptyListeEleve= {
        idEleveInscrit:null,
        inscriptionsidEleve:null,
        nomEleve:'',
        prenomEleve:'',
        matriculeEleve:'',
        date_naissanceEleve:null,
        sexeEleve:'',
        contactEleve:'',
        inscriptions_statut_eleve:'',
        inscriptions_status:'',
        inscriptions_type:'',
        inscriptions_processus:'',
        brancheid: null ,
        brancheLibelle:''

    }





    let emptyClasse= {
        classeid:null,
        classecode:'',
        classelibelle:'',
    } ;

    let emptyEleveClasse= {
        classelibelle:"",
        eleve_matricule:'',
        eleve_sexe:'',
        elevenom:'',
        eleveprenom:'',
        elevecellulaire:'',
        elevedate_naissance:'',
        inscriptions_statut_eleve:''
    } ;

    let emptyInscriptions = {
        inscriptionsid :null ,
        inscriptionscode :'',
        inscriptions_ecole_origine:'',
        inscriptions_classe_precedente :'',
        inscriptions_derniereclasse_religieuse :'',
        inscriptions_classe_actuelle :'',
        inscriptions_delete :false,
        inscriptions_status : '',
        inscriptions_redoublant:'',
        inscriptions_boursier:'',
        inscriptions_statut_eleve:'',
        inscriptions_type:'',
        inscriptionsdate_creation : null,
        inscriptionsdate_modification :null,
        identifiantEleve:null,
        identifiantEcole:0,
        identifiantAnnee_scolaire:0,
        identifiantBranche:0,
        inscriptionBadge:'',
        tenant_tenantid:'',
        inscriptions_langue_vivante:'',
        inscriptions_code_interne:'',
        inscriptions_contact1:'',
        inscriptions_contact2:'',
        libellehandicap:[]
    };

    let emptyTenant= {
        tenantcode: '' ,
        tenantid: 21 ,
        tenantlibelle: '',
        tenant_tenantid: ''
    } ;

    let emptystatutEleve={
        statut_code:'',
        statut_libelle:''
    }
    let emptyAnneeScoliare= {
        id: null ,
        libelle: '' ,
        annee_scolaire_code: '',
        annee_scolaire_visible:''
    } ;

    let emptyStatutEleve= {
        statuts_eleveid: null ,
        statuts_elevecode: '' ,
        statuts_elevelibelle: '',
        tenant_tenantid: ''
    } ;
    let emptyBranche= {
        id: null ,
        libelle: ''
    } ;

    let emptySexe= {
        sexe_code: '' ,
        sexe_libelle: ''
    } ;


    let emptyNiveau= {
        niveauid: null ,
        niveaucode: '' ,
        niveaulibelle: '',
        tenant_tenantid: ''
    } ;

    const dropdownValues = [
        { name: 'New York', code: 'NY' },
        { name: 'Rome', code: 'RM' },
        { name: 'London', code: 'LDN' },
        { name: 'Istanbul', code: 'IST' },
        { name: 'Paris', code: 'PRS' }
    ];

    const searchInput = React.createRef();
    const [anneeScolaires, setanneeScolaires]  = useState(null);
    const [anneeScolaire, setanneeScolaire]  = useState(emptyAnneeScoliare);
    const [branche, setBranche]  = useState(emptyBranche);
    const [branches, setBranches]  = useState(null);
    const [niveaus, setniveaus]  = useState(null);
    const [niveau, setniveau]  = useState(emptyNiveau);
    const [liseleveclasse, setliseleveclasse]  = useState(emptyEleveClasse);
    const [liseleveclasses, setliseleveclasses]  = useState(null);
    const [liseleveInscrit, setLiseleveInscrit]  = useState(emptyListeEleve);
    const [liseleveInscrits, setLiseleveInscrits]  = useState(null);
    const [isNotSpinning, setIsNotSpinning] = useState('hidden');
    const [statutEleve, setstatutEleve]  = useState(emptystatutEleve);
    const [showMessage, setShowMessage] = useState(false);
    const [typeOperation, setTypeOperation]  = useState(null);
    const [anneeEncours, setAnneeEncours] = useState(sessionStorage.getItem('AnneEncours'));
    const [typeOperation1, settypeOperation1]  = useState("INSCRIPTION");
    const [typeOperation2, settypeOperation2]  = useState("PREINSCRIPTION");
    const [tenant, settenant]  = useState(sessionStorage.getItem('EcoleID'));
    const [codeInterne, setCodeInterne]  = useState('');
    const [contact1, setContact1]  = useState('');
    const [contact2, setContact2]  = useState('');
    const [eleves, setEleves]  = useState(null);
    const [modif, setModif]  = useState(null);
    const [productDialog, setProductDialog] = useState(false);
    const [productModifDialog, setProductModifDialog] = useState(false);
    const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
    const [eleve, setEleve]  = useState(emptyeleve);
    const [eleveCreate, setEleveCreate]  = useState(emptyeleve);
    const [retourApi, setretourApi]  = useState(null);
    const [inscription, setInscription]  = useState(emptyInscriptions);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [submittedModif, setSubmittedModif] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const [globalFilterValue2, setGlobalFilterValue2] = useState('');
    const [selectedNiveau, setSelectedNiveau] = useState(null);
    const [selectedStatut, setSelectedStatut] = useState(null);
    const [selectedSexe, setSelectedSexe] = useState(emptySexe);
    const [selectedInscrStatut, setSelectedInscrStatut] = useState(null);
    const [filters2, setFilters2] = useState({
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'prenomEleve':{ value: null, matchMode: FilterMatchMode.CONTAINS },
        'inscriptions_status':{ value: null, matchMode: FilterMatchMode.CONTAINS },
        'inscriptionsidEleve':{ value: null, matchMode: FilterMatchMode.CONTAINS },
        'nomEleve':  { value: null, matchMode: FilterMatchMode.CONTAINS },
        'matriculeEleve': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'inscriptions_statut_eleve': { value: null, matchMode: FilterMatchMode.CONTAINS  },
        'sexe': { value: null, matchMode: FilterMatchMode.CONTAINS  },
        'brancheLibelle': { value: null, matchMode: FilterMatchMode.CONTAINS  }
    });
    const initFilters1 = () => {
        setFilters2({
            'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
            'eleve_matricule':{ value: null, matchMode: FilterMatchMode.STARTS_WITH },
            'elevenom':{ value: null, matchMode: FilterMatchMode.STARTS_WITH },
            'eleveprenom':{ value: null, matchMode: FilterMatchMode.STARTS_WITH },
            'eleve_sexe':  { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            'elevecellulaire': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            'classelibelle': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            'inscriptions_statut_eleve': { value: null, matchMode: FilterMatchMode.EQUALS  }
        });
        setGlobalFilterValue2('');
    }
    const clearFilter1 = () => {
        initFilters1();
    }

    useEffect(()=>{

        if(retourApi==="DEMANDE D'INSCRIPTION EFFECTUEE AVEC SUCCES!"){
            setShowMessage(true);
            setProductDialog(false);
            setSelectedSexe(null);
            setBranche(emptyBranche);
            setstatutEleve(emptystatutEleve);
        } else if (retourApi==="Insription entaméé ou déjà terminé. Veuille effectuer une recherche avec le matricule de cet elève!") {
            setShowMessage(true);
            setProductDialog(false);
            setSelectedSexe(null);
            setBranche(emptyBranche);
            setstatutEleve(emptystatutEleve);
        }

        else {
           // toast.current.show({severity:'error', summary: 'vie-ecoles', detail:'Erreur lors de la création ', life: 3000});
        }
    },[modif])

    useEffect(() => {
        const eleveService = new EleveService();
          initFilters1();

        eleveService.listBrancheByEcole(tenant).then(data => {
            console.log(data)
            setBranches(data)
        });
        eleveService.AnneeScolaire(tenant).then(data => {
            console.log(data)
            setanneeScolaire(data)
        });
    }, []);


    useEffect(() => {
        setIsNotSpinning('');
        const eleveService = new EleveService();
        eleveService.listEleveInscrit(tenant,anneeEncours,'EN_ATTENTE','INSCRIPTION').then(data => {
            console.log(data)
            setLiseleveInscrits(data)
            setIsNotSpinning('hidden');
        });
    }, [modif]);

    useEffect(() => {
        setIsNotSpinning('');
        const eleveService = new EleveService();
        eleveService.listEleveInscrit(tenant,anneeEncours,'EN_ATTENTE','INSCRIPTION').then(data => {
            console.log(data)
            setLiseleveInscrits(data)
            setIsNotSpinning('hidden');
        });
    }, [anneeScolaire]);



    const formatCurrency = (value) => {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }

    const openNew = () => {
        setEleve(emptyeleve);
        setSubmitted(false);
        setProductDialog(true);
    }
    const onGlobalFilterChange2 = (e) => {
        const value = e.target.value;
        let _filters2 = { ...filters2 };
        _filters2['global'].value = value;

        setFilters2(_filters2);
        setGlobalFilterValue2(value);
    }
    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
    }

    const hideModifDialog = () => {
        setSubmittedModif(false);
        setProductModifDialog(false);
        setBranche(emptyBranche);
    }


    const hideDeleteProductDialog = () => {
        setDeleteProductDialog(false);
    }

    const hideDeleteProductsDialog = () => {
        setDeleteProductsDialog(false);
    }
    const saveModifProduct = () =>{

    }

    const saveProduct = () => {
        setSubmitted(true);
        const eleveService = new EleveService();
        if(eleve.elevematricule_national.trim()&& eleve.elevenom.trim()&& eleve.eleveprenom.trim()&& branche.id
            && selectedSexe.sexe_code.trim()&& statutEleve.statut_code&& inscription.inscriptions_code_interne.trim()){
            inscription.identifiantAnnee_scolaire= anneeEncours ;
            inscription.inscriptions_statut_eleve =statutEleve ;
            inscription.identifiantEcole= Number(tenant) ;
            inscription.identifiantBranche = branche.id ;
              eleve.eleveSexe= selectedSexe.sexe_code ;
                  eleveService.creerEleve(inscription.identifiantEcole,anneeEncours,statutEleve.statut_code,typeOperation1,inscription.identifiantBranche,eleve).then(data => {
                console.log(data)
                setretourApi(data)
            setModif(1);
            });

        } else {
            toast.current.show({severity:'error', summary: 'vie-ecoles', detail:'Veuillez renseigner les champs obligatoires', life: 3000});
        }


    }



    const editProduct = (product) => {
        setLiseleveInscrit({ ...product });
        setProductModifDialog(true);
    }

    const confirmDeleteProduct = (product) => {
        setEleve(product);
        setDeleteProductDialog(true);
    }

    const deleteProduct = () => {
        let _eleves = eleves.filter(val => val.eleveid !== eleve.eleveid);
        setEleves(_eleves);
        const eleveService = new EleveService();
        eleveService.SupprimerEleve(eleve.eleveid,eleve)
            .then(data => setEleves(_eleves));

        setDeleteProductDialog(false);
        setEleve(emptyeleve);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'eleve a été supprimer', life: 3000 });
    }

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < eleves.length; i++) {
            if (eleves[i].eleveid === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    const createId = () => {
        let id = '';
        let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < 5; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return id;
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const confirmDeleteSelected = () => {
        setDeleteProductsDialog(true);
    }

    const deleteSelectedProducts = () => {
        let _eleves = eleves.filter(val => !selectedProducts.includes(val));
        setEleves(_eleves);
        setDeleteProductsDialog(false);
        setSelectedProducts(null);

        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Products Deleted', life: 3000 });
    }



    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _eleve = { ...eleve };
        _eleve[`${name}`] = val;
        setEleve(_eleve);
    }

    const onInputChange2 = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _inscrip = { ...inscription };
        _inscrip[`${name}`] = val;
        setInscription(_inscrip);
    }


    const Sexe = [
        {sexe_code: 'MASCULIN', sexe_libelle: 'MASCULIN'},
        {sexe_code: 'FEMININ', sexe_libelle: 'FEMININ'}

    ];

    const Statuts = [
        {statut_code: 'AFFECTE', statut_libelle: 'AFFECTE'},
        {statut_code: 'NON_AFFECTE', statut_libelle: 'NON AFFECTE'},
    ];

    const InscriptionTypeOperation = [
        {inscripStatuCode: 'INSCRIPTION', inscripStatulibelle: 'INSCRIPTION'},
        {inscripStatuCode: 'PREINSCRIPTION', inscripStatulibelle: 'PREINSCRIPTION'}
    ];

    const onNiveauChange = (e) => {
        setniveau(e.value);
           }
    const onBrancheChange = (e) => {
        setBranche(e.value);
    }

    const onInscripStatutChange = (e) => {
        setSelectedInscrStatut(e.value);
            }



    const onAnneeColaireChange = (e) => {
        setanneeScolaire(e.value);
    }


    const onStatutChange = (e) => {
        setstatutEleve(e.value);
            }

    const onSexeChange = (e) => {
        setSelectedSexe(e.value);

    }



    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Nouveau" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    {/*<Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedProducts || !selectedProducts.length} />*/}
                </div>
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" />
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        )
    }

    const prenomBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">prénom</span>
                {rowData.prenomEleve}
            </>
        );
    }


    const NomBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">nom</span>
                {rowData.nomEleve}
            </>
        );
    }
    const NumInscrBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">N°</span>
                {rowData.inscriptionsidEleve}
            </>
        );
    }

    const matriculeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">matricule</span>
                {rowData.matriculeEleve}
            </>
        );
    }



    const statutBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Statut</span>
                {rowData.inscriptions_statut_eleve}
            </>
        );
    }

    const dateNaissanceBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Date naissance</span>
                {rowData.date_naissanceEleve}
            </>
        );
    }

    const brancheBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Branche</span>
                {rowData.brancheLibelle}
            </>
        );
    }
    const sexeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Sexe</span>
                {rowData.sexeEleve}
            </>
        );
    }
    const LV2BodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">LV2</span>
                {rowData.inscriptionLangueVivante}
            </>
        );
    }

    const BadgeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Badge</span>
                {rowData.inscriptionBadge}
            </>
        );
    }

    const contactBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Cellulaire</span>
                {rowData.contactEleve}
            </>
        );
    }


    const codeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Code</span>
                {rowData.eleveid}
            </>
        );
    }


    const imageBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Image</span>
                <img src={`assets/demo/images/product/${rowData.image}`} alt={rowData.image} className="shadow-2" width="100" />
            </>
        )
    }

    const priceBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Price</span>
                {formatCurrency(rowData.price)}
            </>
        );
    }

    const categoryBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Category</span>
                {rowData.category}
            </>
        );
    }
    const typeItemTemplate = (option) => {
        return <span className={`customer-badge status-${option}`}>{option}</span>;
    }
    const StatutFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={Statuts} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={typeItemTemplate} placeholder="Sélectionnez le statut" className="p-column-filter" showClear />;
    }

    const statusInscriptionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Statut Inscription</span>
                {rowData.inscriptions_status}
            </>
        )
    }

    const statusBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Statut Elève</span>
                {rowData.inscriptions_statut_eleve}
            </>
        )
    }
    const dialogFooter = <div className="flex justify-content-center"><Button label="OK" className="p-button-text" autoFocus onClick={() => setShowMessage(false)} /></div>;

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editProduct(rowData)} />
             </div>
        );
    }
    const renderHeader2 = () => {
        return (
            <div className="flex justify-content-end">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue2} onChange={onGlobalFilterChange2} placeholder="Mots clés..." />
                </span>
            </div>
        )
    }
    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Liste des élèves</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Rechercher..." />
            </span>
        </div>
    );

    const productDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
        </>
    );
    const productModifDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideModifDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveModifProduct} />
        </>
    );

    const deleteProductDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />
        </>
    );
    const deleteProductsDialogFooter = (
        <>
            <Button label="Oui" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductsDialog} />
            <Button label="Non" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedProducts} />
        </>
    );
    const header2 = renderHeader2();
    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} ></Toolbar>
                    <ProgressSpinner className={isNotSpinning}  style={{width: '50px', height: '50px'}}  strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />

                    <DataTable value={liseleveInscrits} paginator className="p-datatable-customers" rows={10} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                               dataKey="inscriptionsidEleve" filters={filters2} filterDisplay="row"  responsiveLayout="scroll"
                               globalFilterFields={['inscriptionsidEleve','nomEleve', 'matriculeEleve', 'prenomEleve','inscriptions_status','inscriptions_statut_eleve','eleve_sexe','brancheLibelle']} header={header2} emptyMessage="Pas d'elèves trouvés avec ces critères." responsiveLayout="scroll">


                        {/*<Column selectionMode="multiple" headerStyle={{ width: '3rem'}}></Column>*/}

                        <Column filterField="inscriptionsidEleve" header="N°"  body={NumInscrBodyTemplate} headerStyle={{ width: '10%', minWidth: '5rem' }}  ></Column>

                        <Column filterField="matriculeEleve" header="Matricule"  body={matriculeBodyTemplate} headerStyle={{ width: '14%', minWidth: '5rem' }} ></Column>

                        <Column filterField="NomEleve" header="Nom"  body={NomBodyTemplate} headerStyle={{ width: '14%', minWidth: '5rem' }}  ></Column>

                        <Column filterField="prenomEleve" header="prénom"  body={prenomBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }} ></Column>

                        <Column field="inscriptions_statut_eleve" header="Statut" body={statusBodyTemplate} headerStyle={{ width: '14%', minWidth: '5rem' }}
                                showFilterMenu={false} filterMenuStyle={{ width: '14rem' } } style={{ minWidth: '12rem' }}
                        ></Column>
                        <Column field="inscriptions_status" header="Status Inscription"  body={statusInscriptionBodyTemplate} headerStyle={{ width: '14%', minWidth: '5rem' }}
                                showFilterMenu={false}  ></Column>
                        <Column field="eleve_sexe" header="Sexe"  body={sexeBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="brancheLibelle" header="Branche"  body={brancheBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>

                        {/*<Column body={actionBodyTemplate}></Column>*/}
                    </DataTable>

                    <Dialog visible={showMessage} onHide={() => setShowMessage(false)} position="top" footer={dialogFooter} showHeader={false} breakpoints={{ '960px': '80vw' }} style={{ width: '30vw' }}>
                        <div className="flex justify-content-center flex-column pt-6 px-3">
                            <i className="pi pi-check-circle" style={{ fontSize: '5rem', color: 'var(--green-500)' }}></i>
                            <h5>{"Demande d'inscription"}</h5>
                            <p style={{ lineHeight: 1.5, textIndent: '1rem' }}>
                                {retourApi}
                            </p>
                        </div>
                    </Dialog>

                       {/*dialog for modif */}
                    <Dialog visible={productModifDialog} style={{ width: '650px' }} header="Modifier eleve" modal className="p-fluid" footer={productModifDialogFooter} onHide={hideModifDialog}>


                        <div className="formgrid grid">
                            <div className="field col">
                                <InputText value={anneeScolaire.libelle} disabled />
                            </div>
                            <div className="field col">
                                <InputText value={typeOperation1} disabled />

                            </div>

                        </div>
                        <div className="formgrid grid">
                        <div className="field col">
                            <label htmlFor="elevematricule_national">Matricule</label>
                            <InputText disabled id="elevematricule_national" value={liseleveInscrit.matriculeEleve} />
                        </div>
                            <div className="field col">
                                <label htmlFor="branche">Branche</label>
                                <InputText value={liseleveInscrit.brancheLibelle}  id="branche"  disabled />
                            </div>
                            <div className="field col">
                                <label htmlFor="sexe">Sexe</label>
                                <InputText value={liseleveInscrit.sexeEleve}  id="sexe"  disabled />
                            </div>

                            <div className="field col">
                                <label htmlFor="statut">Statut</label>
                                <InputText value={liseleveInscrit.inscriptions_statut_eleve}  id="statut"  disabled />
                            </div>

                        </div>

                        <div className="formgrid grid">
                        <div className="field col">
                            <label htmlFor="elevenom">Nom</label>
                            <InputText id="elevenom" value={liseleveInscrit.nomEleve} onChange={(e) => onInputChange(e, 'elevenom')}  />

                        </div>

                        <div className="field col">
                            <label htmlFor="eleveprenom">Prénom</label>
                            <InputText id="eleveprenom" value={liseleveInscrit.prenomEleve} onChange={(e) => onInputChange(e, 'eleveprenom')} />

                        </div>

                    <div className="field col">
                                <label htmlFor="contactEleve">Contact</label>
                                <InputText id="contactEleve" value={liseleveInscrit.contactEleve} onChange={(e) => onInputChange(e, 'contactEleve')} />

                    </div>

                        </div>


                        <div className="formgrid grid">

                            <div className="field col">
                                    <Dropdown value={setSelectedSexe} options={Sexe} onChange={onSexeChange} optionLabel="sexe_code" placeholder="Selectionnez le Sexe" />
                            </div>

                            <div className="field col">
                                <Dropdown value={statutEleve} onChange={(e) => setstatutEleve(e.value)} options={Statuts} optionLabel="statut_libelle" placeholder="Selectionnez le statut" />
                            </div>

                            <div className="field col">
                                <Dropdown value={branche} options={branches} onChange={onBrancheChange} optionLabel="libelle" placeholder="Selectionnez la branche" />
                            </div>
                        </div>



                    </Dialog>


                    {/*End dialog for modif */}





                    <Dialog visible={productDialog} style={{ width: '700px' }} header="Détails élève" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                        <div className="formgrid grid">
                            <div className="field col">
                                 <InputText value={anneeScolaire.libelle} disabled />
                            </div>
                            <div className="field col">
                                <InputText value={typeOperation1} disabled />

                            </div>

                        </div>
                            <div className="field">
                                <label htmlFor="elevematricule_national">Matricule*</label>
                                <InputText  id="elevematricule_national" value={eleve.elevematricule_national} onChange={(e) => onInputChange(e, 'elevematricule_national')}
                                           required autoFocus className={classNames({ 'p-invalid': submitted && !eleve.elevematricule_national })} />
                                {submitted && !eleve.elevematricule_national && <small className="p-invalid">Matricule est obligatoire.</small>}
                            </div>
                            <div className="field">
                                <label htmlFor="elevenom">Nom*</label>
                                <InputText id="elevenom" value={eleve.elevenom} onChange={(e) => onInputChange(e, 'elevenom')} required  className={classNames({ 'p-invalid': submitted && !eleve.elevenom })} />
                                {submitted && !eleve.elevenom && <small className="p-invalid">Nom est obligatoire.</small>}
                            </div>

                            <div className="field">
                                <label htmlFor="eleveprenom">Prénom*</label>
                                <InputText id="eleveprenom" value={eleve.eleveprenom} onChange={(e) => onInputChange(e, 'eleveprenom')}
                                 required className={classNames({ 'p-invalid': submitted && !eleve.eleveprenom })} />
                                {submitted && !eleve.eleveprenom && <small className="p-invalid">Prénom est obligatoire.</small>}
                            </div>

                        <div className="formgrid grid">

                            <div className="field col">
                                <Dropdown value={selectedSexe} options={Sexe} onChange={onSexeChange} optionLabel="sexe_code" placeholder="Selectionnez le Sexe*" />
                            </div>

                          <div className="field col">
                              <Dropdown value={statutEleve} onChange={(e) => setstatutEleve(e.value)} options={Statuts} optionLabel="statut_libelle" placeholder="Selectionnez le statut*" />
                          </div>

                            <div className="field col">
                                <Dropdown value={branche} options={branches} onChange={onBrancheChange} optionLabel="libelle" filter showClear filterBy="libelle" placeholder="Selectionnez la branche*" />
                            </div>
                        </div>

                        <div className="formgrid grid">
                            <div className="field col">
                                <label htmlFor="inscriptions_code_interne">Code interne</label>
                                <InputText id="inscriptions_code_interne" value={inscription.inscriptions_code_interne} onChange={(e) => onInputChange2(e, 'inscriptions_code_interne')} />
                            </div>

                            <div className="field col">
                                <label htmlFor="inscriptions_contact1">Contact1</label>
                                <InputText id="inscriptions_contact1" value={inscription.inscriptions_contact1} onChange={(e) => onInputChange2(e, 'inscriptions_contact1')} />

                            </div>

                            <div className="field col">
                                <label htmlFor="contact2">Contact2</label>
                                <InputText id="inscriptions_contact2" value={inscription.inscriptions_contact2} onChange={(e) => onInputChange2(e, 'inscriptions_contact2')} />

                            </div>

                        </div>


                    </Dialog>

                    <Dialog visible={deleteProductDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {eleve && <span>Voulez vous vraiment supprimer <b>{eleve.elevematricule_national}</b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteProductsDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductsDialogFooter} onHide={hideDeleteProductsDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {eleve && <span>Voulez vous vraiment supprimer les elements selectionnés?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Inscription, comparisonFn);
