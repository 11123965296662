import React, { useState, useEffect, useRef } from 'react';

import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { MatiereService } from '../service/MatiereService';
import { NiveauEnseignementService } from '../service/NiveauEnseignementService';
import { CategorieMatiereService } from '../service/CategorieMatiereService';
import { MatiereEcoleService } from '../service/MatiereEcoleService';

const Matiere = () => {

    let emptyNiveau = {
        id: null,
    }

    // let emptyMatiereParent = {
    //     id: null
    // };

    let emptyMatiere = {
        code: '',
        libelle: '',
        niveauEnseignement: emptyNiveau,
        matiereParent: null,
        categorie: { id: null },
        pec: 1,
        bonus : 0
    };

    let emptyMatiereEcole = {
        matiere : emptyMatiere,
        pec : 1,
        ecole : {id : null},
        code : '',
        libelle : '',
        bonus : 0
    }

    let emptyMatieresEcole = [{
        id: null,
        libelle: 'Selectionner une matière ...',

    }];

    const [matiereDialog, setMatiereDialog] = useState(false);

    const [deleteMatiereDialog, setDeleteMatiereDialog] = useState(false);
    const [deleteMatieresDialog, setDeleteMatieresDialog] = useState(false);

    const [update, setUpdate] = useState(false);
    const [headerTitle, setHeaderTitle] = useState("");

    const [matiereEcole, setMatiereEcole] = useState(emptyMatiereEcole);

    const [selectedMatieres, setSelectedMatieres] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);

    const toast = useRef(null);
    const dt = useRef(null);


    const [matieresEcole, setMatieresEcole] = useState([emptyMatiereEcole]);
    const [niveaux, setNiveaux] = useState(null);
    const [categories, setCategories] = useState(null);
    // const [loading, setLoading] = useState(false);


    const [width, setWidth] = useState("700px");

    useEffect(() => {
        const matiereEcoleService = new MatiereEcoleService();
        const niveauEnseignementService = new NiveauEnseignementService();
        const categorieMatiereService = new CategorieMatiereService();
        matiereEcoleService.getListByEcole(sessionStorage.getItem('EcoleID')).then(data => {
            if(data.length!=0){
                setMatieresEcole([...emptyMatieresEcole, ...data]);
            }else{
                setMatieresEcole([...data]);
            }
            console.log(data);
        }).catch((error) => {
            if (error.response) {
                console.log('erreur response- vie ecole' + error.response.status);
            } else if (error.request) {
                console.log('erreur request- vie ecole' + error.request);
            } else {
                console.log('Error autres', error.message);
            }
            setMatieresEcole([]);
         }
        );
        niveauEnseignementService.getList().then(data => setNiveaux(data));
        categorieMatiereService.getList().then(data => setCategories(data));

    }, []);


    const openNew = () => {
        setSubmitted(false);
        // setWidth("700px");
        setUpdate(false);
        setMatiereEcole(emptyMatiereEcole);

        setMatiereDialog(true);
        setHeaderTitle("Créer une nouvelle Matière");
    }

    const hideDialog = () => {
        setSubmitted(false);
        setMatiereDialog(false);
    }


    const hideDeleteClasseDialog = () => {
        setDeleteMatiereDialog(false);
    }


    const hideDeleteClassesDialog = () => {
        setDeleteMatieresDialog(false);
    }

    const saveMatiere = () => {
        setSubmitted(true);
        // console.log(matiereEcole);
        if (matiereEcole.code != null && matiereEcole.code.trim() && matiereEcole.libelle != null && matiereEcole.libelle.trim() && matiereEcole.categorie != null) {
            let _matieresEcole = [...matieresEcole];
            let _matiereEcole = { ...matiereEcole };
            const matiereEcoleService = new MatiereEcoleService();

            if (matiereEcole.id) {
                // console.log(_matiereEcole);
                const index = reactfindIndexById(matiereEcole.id);

                (matiereEcoleService.updateAndDisplay(_matiereEcole)).then(resp => {
                    _matieresEcole[index] = { ...resp };
                    setMatieresEcole(_matieresEcole);
                    console.log(resp);
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Matière modifiée', life: 3000 });
                }).catch((error) => {
                    if (error.response) {
                        console.log('erreur response- vie ecole' + error.response.status);
                    } else if (error.request) {
                        console.log('erreur request- vie ecole' + error.request);
                    } else {
                        console.log('Error autres', error.message);
                    }
                    toast.current.show({ severity: 'error', summary: 'erreur', detail: error.message, life: 3000 });
                }
                );
            }
            else {
                (matiereEcoleService.saveAndDisplay(_matiereEcole)).then(res => {
                    console.log(res);
                    _matieresEcole.push(res);
                    setMatieresEcole(_matieresEcole);
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Nouvelle matière créee ' + _matiereEcole.code, life: 3000 });
                }).catch((error) => {
                    if (error.response) {
                        console.log('erreur response- vie ecole' + error.response.status);
                    } else if (error.request) {
                        console.log('erreur request- vie ecole' + error.request);
                    } else {
                        console.log('Error autres', error.message);
                    }
                    toast.current.show({ severity: 'error', summary: 'erreur', detail: error.message, life: 3000 });
                })
            }
            //  setSalles(_salles);
            setMatiereDialog(false);
            setMatiereEcole(emptyMatiereEcole);
            setSubmitted(false);
        }
    }


    const editMatiere = (matiere) => {

        setHeaderTitle("Modifier une Matière");
        setWidth('1000px');
        setUpdate(true);

        let _matiere = { ...matiere }
        if (!_matiere.matiereParent)
            _matiere.matiereParent = { id: null };
        // console.log(_matiere);
        setMatiereEcole({ ..._matiere });
        setMatiereDialog(true);
    }



    const confirmDeleteClasse = (matiere) => {
        let _matiere = { ...matiere }
        if (!_matiere.matiereParent){
            _matiere.matiereParent = null ;
        }
       setMatiereEcole(_matiere);
        setDeleteMatiereDialog(true);
    }



    const deleteMatiere = () => {
        const matiereService = new MatiereService();
        matiereService.delete(matiereEcole.id).then(() => {
            let _matieres = matieresEcole.filter(val => val.id !== matiereEcole.id);
            setMatieresEcole(_matieres);
            toast.current.show({ severity: 'success', summary: 'Succès', detail: 'Matière supprimée', life: 3000 })
        }).catch((error) => {
            if (error.response) {
                console.log('erreur response- vie ecole' + error.response.status);
            } else if (error.request) {
                console.log('erreur request- vie ecole' + error.request);
            } else {
                console.log('Error autres', error.message);
            }
            toast.current.show({ severity: 'error', summary: 'erreur', detail: error.message, life: 3000 });
        });
        setMatiereEcole(emptyMatiereEcole);
        setDeleteMatiereDialog(false);
    }

    const reactfindIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < matieresEcole.length; i++) {
            if (matieresEcole[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    }

    const confirmDeleteSelected = () => {
        setDeleteMatieresDialog(true);
    }


    const deleteSelectedMatieres = () => {
        let _salles = matieresEcole.filter(val => !selectedMatieres.includes(val));
        const matiereService = new MatiereService();
        selectedMatieres.map((salle) => { return matiereService.delete(salle.id) });
        setMatieresEcole(_salles);
        setDeleteMatieresDialog(false);
        setSelectedMatieres(null);
        toast.current.show({ severity: 'success', summary: 'succès', detail: 'Matieres supprimées', life: 3000 });
    }


    const onInputMatiereChange = (e, name) => {
        const val = e.target.value || '';
        let _matiere = { ...matiereEcole };
        _matiere[`${name}`] = val;
        setMatiereEcole(_matiere);
        // console.log(_matiere);
        console.log(val);
        // console.log(e);
    }

    const onInputNumberChange = (e, name) => {
        const val = e.value || 0;
        let _matiere = { ...matiereEcole };
        _matiere[`${name}`] = val;
        setMatiereEcole(_matiere);
        // console.log(_matiere);
        console.log(val);
        // console.log(e);
    }

    const onInputPec = (e) => {
        let _matiere = { ...matiereEcole };
        _matiere.pec = e;
        if(e == 1)
        _matiere.bonus = 0;
        setMatiereEcole(_matiere);
        // console.log(matiere);
    }
    const onInputBonus = (e) => {
        let _matiere = { ...matiereEcole };
        _matiere.bonus = e;
        if(e == 1)
        _matiere.pec = 0;
        
        setMatiereEcole(_matiere);
        // console.log(matiere);
    }

    const onSelectMatiereChange = (e, name) => {

        let _matiere = { ...matiereEcole };

        _matiere[`${name}`] = {id : e};
        // console.log(_matiere);
        console.log(e);
        setMatiereEcole(_matiere);

    }


    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Nouveau" icon="pi pi-plus" className="p-button-success mr-2 hidden" disabled={true} onClick={openNew} />
                    <Button label="Supprimer" icon="pi pi-trash" className="p-button-danger hidden" onClick={confirmDeleteSelected} disabled={true} />
                    {/* <Button label="Supprimer" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedMatieres || !selectedMatieres.length} /> */}
                </div>
            </React.Fragment>
        )
    }



    const codeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Code</span>
                {rowData.matiere?.code == null ? '' : rowData.matiere?.code}
            </>
        );
    }

    const codeSourceBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Code</span>
                {rowData?.code == null ? '' : rowData.code}
            </>
        );
    }

    const libelleBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Libellé</span>
                {rowData.libelle == null ? '' : rowData.libelle}
            </>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editMatiere(rowData)} />
                <Button icon="pi pi-trash" disabled={true} className="p-button-rounded p-button-warning mt-2 hidden" onClick={() => confirmDeleteClasse(rowData)} />
            </div>
        );
    }

    const parentBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Parent</span>
                {rowData?.matiereParent == null ? '' : rowData?.matiereParent.libelle}
            </>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h3 className="m-0">Liste des matières</h3>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Recherche..." />
            </span>
        </div>
    );


    const salleDialogFooter = (
        <>
            <Button label="Annuler" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Modifier" icon="pi pi-check" className="p-button-text" onClick={saveMatiere} />
        </>
    );


    const deleteSalleDialogFooter = (
        <>
            <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={hideDeleteClasseDialog} />
            <Button label="Oui" icon="pi pi-check" className="p-button-text" onClick={deleteMatiere} />
        </>
    );
    const deleteSallesDialogFooter = (
        <>
            <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={hideDeleteClassesDialog} />
            <Button label="oui" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedMatieres} />
        </>
    );

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" right={leftToolbarTemplate} ></Toolbar>

                    <DataTable ref={dt} value={matieresEcole.filter((val) => val.id !== null)} selection={selectedMatieres} onSelectionChange={(e) => setSelectedMatieres(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="affichage {first} de {last} sur {totalRecords} matieres"
                        globalFilter={globalFilter} emptyMessage="Aucun enregistrement trouvé." header={header} responsiveLayout="scroll">
                        {/* <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column> */}
                        <Column field="matiere.code" header="Source" sortable body={codeBodyTemplate}></Column>
                        <Column field="code" header="Code" sortable body={codeSourceBodyTemplate}></Column>
                        <Column field="libelle" header="Libellé" sortable body={libelleBodyTemplate} ></Column>
                        <Column field="numOrdre" header="Num. ordre" sortable ></Column>
                        <Column field="categorie.libelle" header="Catégorie" sortable ></Column>
                        <Column field="matiereParent.id" body={parentBodyTemplate} header="Parent" sortable ></Column>
                        <Column body={actionBodyTemplate} header="Actions" headerStyle={{ width: '14%', minWidth: '9rem' }}></Column>
                    </DataTable>

                    <Dialog visible={matiereDialog} style={{ width: '40%' }} header={headerTitle} modal className="p-fluid" footer={salleDialogFooter} onHide={hideDialog}>


                        <div className="mt-3 grid  p-fluid">
                            <div className="field col-12">
                                <div className='text-xl font-bold surface-200 p-2'> Reférence Source [ <span className='font-italic font-semi-bold'>{matiereEcole.matiere?.code} - {matiereEcole.matiere?.libelle}</span> ]</div>
    
                            </div>
                            <div className="field col-8">
                                <label htmlFor="code"><b>Code</b></label>
                                <InputText id="code" value={matiereEcole.code} onChange={(e) => onInputMatiereChange(e, 'code')} required className={classNames({ 'p-invalid': submitted && !matiereEcole.code })} />
                                {submitted && !matiereEcole.code && <small className="p-invalid">Le code est requis.</small>}
                            </div>
                            <div className="field col-8">
                                <label htmlFor="libelle"><b>Libellé</b></label>
                                <InputText id="libelle" value={matiereEcole.libelle} onChange={(e) => onInputMatiereChange(e, 'libelle')} required className={classNames({ 'p-invalid': submitted && !matiereEcole.libelle })} />
                                {submitted && !matiereEcole.libelle && <small className="p-invalid">Le libellé est requis.</small>}
                            </div>
                            <div className="field col-8">
                                <label htmlFor="ordre"><b>Numéro d'ordre</b></label>
                                <InputNumber id="ordre" value={matiereEcole.numOrdre} onChange={(e) => onInputNumberChange(e, 'numOrdre')} required className={classNames({ 'p-invalid': submitted && !matiereEcole.numOrdre })} style={{width:'150px', marginLeft:'10px'}} />
                                {submitted && !matiereEcole.numOrdre && <small className="p-invalid">Le numero d ordre est requis.</small>}
                            </div>

                            {/* <div className="field col-12">
                                <label htmlFor="niveau"><b>Niveau Enseignement</b></label>
                                <Dropdown id="niveau" disabled={true} value={matiereEcole.matiere?.niveauEnseignement.id} onChange={(e) => onSelectMatiereChange(e.value, 'niveauEnseignement')} options={niveaux} optionValue="id" optionLabel="libelle" placeholder="Selectionner le niveau" className={classNames({ 'p-invalid': submitted && !matiereEcole.matiere?.niveauEnseignement.id })} />
                                {submitted && !matiereEcole.matiere?.niveauEnseignement.id && <small className="p-invalid">Le niveau est requis.</small>}
                            </div> */}

                            <div className="field col-12">
                                <label htmlFor="cat"><b>Catégorie Matière</b></label>
                                <Dropdown id="cat" value={matiereEcole.categorie?.id} onChange={(e) => onSelectMatiereChange(e.value, 'categorie')} options={categories} optionValue="id" optionLabel="libelle" placeholder="Selectionner la catégorie" className={classNames({ 'p-invalid': submitted && !matiereEcole?.categorie?.id })} />
                                {submitted && !matiereEcole?.categorie?.id && <small className="p-invalid">La catégorie est requise.</small>}
                            </div>

                            <div className="field col-12">
                                <label htmlFor="parent"><b>Matière parent</b></label>
                                <Dropdown id="parent" value={matiereEcole?.matiereParent?.id} onChange={(e) => onSelectMatiereChange(e.value, 'matiereParent')} options={matieresEcole} optionValue="id" optionLabel="libelle" placeholder="Selectionner la matière parent" />
                            </div>

                            <div className="field col-offset-7">
                                <InputSwitch name='pec' className='mr-2' tooltip='Activer ou désactiver pour prendre ou non en compte la matière dans le calcul des moyennes générales' trueValue={1} falseValue={0} checked={matiereEcole.pec} onChange={(e) => { onInputPec(e.value) }} />
                                <label htmlFor="pec"><b>P.E.C<span className='ml-1 font-italic text-xs text-500'>* Prise En Compte dans le calcul des moyennes génerales</span></b></label>
                            </div>
                            <div className="field col-offset-7">
                                <InputSwitch name='bonus' className='mr-2' tooltip='Prendre en compte en tant que matière bonus' trueValue={1} falseValue={0} checked={matiereEcole.bonus} onChange={(e) => { onInputBonus(e.value) }}/>
                                <div className='ml-1 pb-3 font-italic text-700' htmlFor="bonus"><b>Bonus?</b></div>
                            </div>
                        </div>

                    </Dialog>

                    <Dialog visible={deleteMatiereDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteSalleDialogFooter} onHide={hideDeleteClasseDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {matiereEcole && <span>Etes vous sûr de vouloir Supprimer la matière <b>{matiereEcole.code} - {matiereEcole.libelle}</b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteMatieresDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteSallesDialogFooter} onHide={hideDeleteClassesDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {matiereEcole && <span>Êtes vous sûr de vouloir supprimer les éléments sélectionnés?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Matiere, comparisonFn);