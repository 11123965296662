import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Toolbar } from 'primereact/toolbar';
import { useHistory } from "react-router-dom";
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import FileViewer from 'react-file-viewer';
import {Dropdown} from "primereact/dropdown";
import {SouscriptionService} from "../../service/operations/SouscriptionService";
import EmptyPage from "../../pages/EmptyPage";
import {Route} from "react-router-dom";
import { Tooltip } from 'primereact/tooltip';
import { InputTextarea } from 'primereact/inputtextarea';
import emailjs from '@emailjs/browser';
import { useRouter } from 'next/router';

const AjouterPanierPersonnel = () => {
    const form = useRef();

    let emptyDomaineFormation = {
        domaine_formationid :null ,
        domaine_formation_code :'',
        domaine_formation_libelle:''
    };
let history= useHistory();
    let sendEmailEmpty = {
        subject :'Bonjour SOUM',
        user_name :'SOUMSMITH1',
        user_email:'soumsmith1@gmail.com',
        message:'Juste un text'
    };

    let emptySexe = {
        sexe_code :'',
        sexe_libelle :''
    };

    let emptyPanier = {
        identifiant_ecole :null,
        identifiant_personnel :null,
        panier_personnel_date_creation:null
    };

    let emptyNiveauEtude = {
        niveau_etudeid :null ,
        niveau_etude_code :'',
        niveau_etude_libelle:''
    };
    let emptyAnneeExperience = {
        nbreAnnee_code :0 ,
        nbreAnnee_libelle :''
    };
    let emptyEtatInscription={
        etat_code:'',
        etat_libelle:''
    } ;
    let souscriptionValidaEmpty= {
        idsouscrip:null ,
        statuts: '',
        messageRefus:''
    } ;
    let emptyfonction = {
        fonctionid :null ,
        fonctioncode :'',
        fonctionlibelle:''
    } ;
    const [niveauEtude, setNiveauEtude] = useState(emptyNiveauEtude);
    const [emailObject, setEmailObject] = useState(sendEmailEmpty);
    const [userName, setUserName] = useState('');
    const [panier, setPanier] = useState(emptyPanier);
    const [userEmail, setUserEmail] = useState('');
    const [userMessage, setUserMessage] = useState('');
    const [idModif, setIdmodif] = useState(null);
    const [statut, setstatut] = useState("VALIDEE");
    const [souscVali, setsouscVali] = useState(souscriptionValidaEmpty);
    const [sexe, setSexe] = useState(emptySexe);
    const [retourApi, setretourApi]  = useState(null);
    const [domaineFormation, setDomaineFormation] = useState(emptyDomaineFormation);
    const [mfonction, setmfonction] = useState(emptyfonction);
    const [domaineFormations, setDomaineFormations] = useState([]);
    const [motifRefu, setmotifRefu] = useState('');
    const defaultValues2 = {
        sous_attent_personnid: null,
        sous_attent_personn_nom: '',
        sous_attent_personn_prenom: '',
        sous_attent_personn_email: '',
        sous_attent_personn_sexe: '',
        sous_attent_personn_diplome_recent:'',
        sous_attent_personn_date_naissance: null,
        sous_attent_personn_nbre_annee_experience:null,
        sous_attent_personn_lien_cv:'',
        niveau_etude:niveauEtude,
        sous_attent_personncode:'',
        domaine_formation:domaineFormation,
        fonction:mfonction,
        sous_attent_personn_login:'',
        sous_attent_personn_password:'',
        sous_attent_personn_donnee: null,
        sous_attent_personn_contact :'',
        sous_attent_personn_lien_piece:'',
        sous_attent_personn_lien_autorisation :''
    }

    const columns = [
        {field: 'inscriptions_status', header: 'Statut Inscription'},
        {field: 'inscriptionsidEleve', header: 'N°'},
        {field: 'nomEleve', header: 'Nom'},
        {field: 'prenomEleve', header: 'Prenom'},
        {field: 'matriculeEleve', header: 'Matricule'},
        {field: 'inscriptions_type', header: 'Type Inscription'}
    ];
    const [selectedColumns, setSelectedColumns] = useState(columns);

    const [filters2, setFilters2] = useState({
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'sous_attent_personnid':{ value: null, matchMode: FilterMatchMode.STARTS_WITH },
        'sous_attent_personn_nom':{ value: null, matchMode: FilterMatchMode.CONTAINS },
        'sous_attent_personn_prenom':{ value: null, matchMode: FilterMatchMode.CONTAINS },
        'sous_attent_personn_nbre_annee_experience':  { value: null, matchMode: FilterMatchMode.CONTAINS } ,
        'domaine_formation.domaine_formation_libelle':  { value: null, matchMode: FilterMatchMode.CONTAINS } ,
        'sous_attent_personn_email':  { value: null, matchMode: FilterMatchMode.CONTAINS } ,
        'fonction.fonctionlibelle':  { value: null, matchMode: FilterMatchMode.CONTAINS }
    });

    const [souscriptionAttente, setsouscriptionAttente] = useState((defaultValues2));
    const [souscriptionAttentes, setsouscriptionAttentes] = useState([]);


    const [niveauEtudes, setNiveauEtudes]= useState([]);;
    const [globalFilterValue2, setGlobalFilterValue2] = useState('');
    const [typeOperation, setTypeOperation]  = useState(null);
    const [anneeEncours, setanneeEncours]  = useState("2021-2022");
    const [typeOperation1, settypeOperation1]  = useState("INSCRIPTION");
    const [typeOperation2, settypeOperation2]  = useState("PREINSCRIPTION");
    const [tenant, settenant]  = useState(sessionStorage.getItem('EcoleID'));
    const [etatInscription, setetatInscription]  = useState(emptyEtatInscription);
    const [eleves, setEleves]  = useState(null);
    const [filters1, setFilters1] = useState(null);
    const [productDialog, setProductDialog] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [FileDialog, setFileDialog] = useState(false);
    const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
    const [modif, setModif]  = useState(0);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const [baseUri, setbaseUri] = useState(window.$baseUri);
    const [fileExten, setExtentfileName] = useState('');
    const [fileUrl, setFileUrl] = useState(baseUri+'souscription-personnel/ouvrir-fichier/');
    const [file, setfile] = useState('');
    const initFilters1 = () => {
        setFilters2({
            'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
            'prenomEleve':{ value: null, matchMode: FilterMatchMode.STARTS_WITH },
            'inscriptions_status':{ value: null, matchMode: FilterMatchMode.STARTS_WITH },
            'inscriptionsidEleve':{ value: null, matchMode: FilterMatchMode.STARTS_WITH },
            'nomEleve':  { value: null, matchMode: FilterMatchMode.STARTS_WITH },
           'matriculeEleve': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            'inscriptions_type': { value: null, matchMode: FilterMatchMode.EQUALS  }
        });
        setGlobalFilterValue2('');
    }
    const onGlobalFilterChange2 = (e) => {
        const value = e.target.value;
        let _filters2 = { ...filters2 };
        _filters2['global'].value = value;

        setFilters2(_filters2);
        setGlobalFilterValue2(value);
    }
    const clearFilter1 = () => {
        initFilters1();
    }
    const sendEmail = (e) => {
      //  e.preventDefault();
        emailjs.sendForm('service_p5b4z05', 'template_khmrq5i',form.current, 'rQ8B8i3jHyGwChdlj')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
    };

    useEffect(()=>{
        if(modif===1){
            setShowMessage(true);
            console.log("modif "+modif) ;
        }
    },[modif])


    useEffect(() => {
        const souscrService = new SouscriptionService();
          souscrService.listSouscriptionAvalider(statut)
            .then(data => {
            console.log(data)
            setsouscriptionAttentes(data)

        });

    }, [idModif]);

    const formatCurrency = (value) => {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }


    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
    }

    const hideFileDialog = () => {
         setFileDialog(false);
    }

    const hideDeleteProductDialog = () => {
        setDeleteProductDialog(false);
    }

    const hideDeleteProductsDialog = () => {
        setDeleteProductsDialog(false);
    }

    const openFile1 = (fileName) => {
        if(!fileName || fileName.length === 0) {
            toast.current.show({severity:'info', summary: 'Vie-ecoles', detail:'Pas de fichier disponible', life: 3000});
        } else {

            setfile(fileUrl+fileName) ;
            setExtentfileName( fileName.split(".").pop()) ;
            setFileDialog(true);

        }

    }




    const saveProduct = () => {
        setIdmodif(0);
        setSubmitted(true);

      const souscrService = new SouscriptionService();
        if(etatInscription.etat_libelle.trim()==="AJOUTER" ){
            panier.identifiant_personnel=souscriptionAttente.sous_attent_personnid ;
            panier.identifiant_ecole= tenant ;
            souscrService.ajouterPanierPersonnel(panier)
                .then(data => {
                 console.log(data)
                 setretourApi(data)
                    setModif(1)
                setIdmodif(1)
              });
           // sendEmail();
            setProductDialog(false);
            setetatInscription(emptyEtatInscription);
            setPanier(emptyPanier) ;
            setmotifRefu('') ;
            setretourApi(null);
         }

        else  {
          toast.current.show({ severity: 'error', summary: 'Error Message', detail: 'Veuillez traiter la demande ou annuler', life: 3000 });
            //setProductDialog(false);
        }


    }


    const viewFiles = () => {
        setFileDialog(true);
    }

    const EtatInscriptions = [
        {etat_code: '-------', etat_libelle: '------'},
        {etat_code: 'AJOUTER', etat_libelle: 'AJOUTER'}

    ];
    const Sexe = [
        {sexe_code: 'MASCULIN', sexe_libelle: 'MASCULIN'},
        {sexe_code: 'FEMININ', sexe_libelle: 'FEMININ'}

    ];

    const Statuts = [
        {statut_code: 'AFFECTE', statut_libelle: 'AFFECTE'},
        {statut_code: 'NON_AFFECTE', statut_libelle: 'NON AFFECTE'}
    ];

    const InscriptionTypeOperation2 = [
        'PREINSCRIPTION', 'INSCRIPTION'
    ];

    const InscriptionStatut = [
        'EN ATTENTE', 'VALIDEE'
    ];

    const InscriptionTypeOperation = [
        {inscripStatuCode: 'INSCRIPTION', inscripStatulibelle: 'INSCRIPTION'},
        {inscripStatuCode: 'PREINSCRIPTION', inscripStatulibelle: 'PREINSCRIPTION'}
    ];


    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Nouveau" icon="pi pi-plus" className="p-button-success mr-2" onClick={viewFiles} />
                </div>
            </React.Fragment>
        )
    }

    const IdsouscriptionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">N°</span>
                {rowData.sous_attent_personnid}
            </>
        );
    }


    const NomBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">nom</span>
                {rowData.sous_attent_personn_nom}
            </>
        );
    }

    const PreNomBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Prénom</span>
                {rowData.sous_attent_personn_prenom}
            </>
        );
    }

    const emailBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Email</span>
                {rowData.sous_attent_personn_email}
            </>
        );
    }


    const sexeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Email</span>
                {rowData.sous_attent_personn_sexe}
            </>
        );
    }
    const domaineFormationBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Domaine de formation</span>
                {rowData.domaine_formation.domaine_formation_libelle}
            </>
        );
    }
    const diplomeRecentBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Diplôme récent</span>
                {rowData.sous_attent_personn_diplome_recent}
            </>
        );
    }

    const statutEleveBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Statut</span>
                {rowData.inscriptions_statut_eleve}
            </>
        );
    }
    const nomBreAnneeExperienceBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Nombre année d'experienxe</span>
                {rowData.sous_attent_personn_nbre_annee_experience}
            </>
        );
    }

    const fonctionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Fonction</span>
                {rowData.fonction.fonctionlibelle}
            </>
        );
    }

    const statutInscriptionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Etat</span>
                {rowData.inscriptions_status}
            </>
        );
    }

    const dateNaissanceBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Date naissance</span>
                {rowData.Date_naissanceEleve}
            </>
        );
    }





    const contactBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Cellulaire</span>
                {rowData.contactEleve}
            </>
        );
    }

    const statutInscripBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Cellulaire</span>
                {rowData.inscriptions_status}
            </>
        )
    }


    const typeInscriptionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Cellulaire</span>
                {rowData.inscriptions_type}
            </>
        )
    }
    const reloadPage = () => {
        setShowMessage(false);

      //  history.push('/home/AjouterPanier')
        }
    const editProduct = (souscrip) => {
        setModif(0);
        setsouscriptionAttente({ ...souscrip });
        setUserName('GAIN SARL') ;
        setUserEmail('soumsmith1@gmail.com') ;
        setUserMessage('Bonjour Monsieur '+souscrip.sous_attent_personn_nom+' '+souscrip.sous_attent_personn_prenom +'\n'+
            'Veuillez utiliser ces paramètres pour vous connecter à notre application'+'\n');
        setProductDialog(true);
    }
    const dialogFooter = <div className="flex justify-content-center"><Button label="OK" className="p-button-text" autoFocus onClick={() => reloadPage()} /></div>;

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editProduct(rowData)} />
               </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Liste des élèves</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Rechercher..." />
            </span>
        </div>
    );
    const typeItemTemplate = (option) => {
        return <span className={`customer-badge status-${option}`}>{option}</span>;
    }

    const statusItemTemplate = (option) => {
        return <span className={`customer-badge status-${option}`}>{option}</span>;
    }
    const typeOperationFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={InscriptionTypeOperation2} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={typeItemTemplate} placeholder="Sélectionnez le type" className="p-column-filter" showClear />;
    }

    const statutFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={InscriptionStatut} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={statusItemTemplate} placeholder="Sélectionnez le Statut" className="p-column-filter" showClear />;
    }
    const productDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
        </>
    );

    const FileDialogFooter = (
        <>
           <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={hideFileDialog} />
        </>
    );




    const renderHeader2 = () => {
        return (
            <div className="flex justify-content-end">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue2} onChange={onGlobalFilterChange2} placeholder="Mots clés..." />
                </span>
            </div>
        )
    }
    const header2 = renderHeader2();
    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    {/*<Toolbar className="mb-4" left={leftToolbarTemplate} ></Toolbar>*/}

                    <div className="card">
                        <h5>Liste du personnel validé par GAIN SARL</h5>
                        <p>Liste des souscriptions validé par le GHI SERVICES SARL </p>
                        <DataTable value={souscriptionAttentes} paginator className="p-datatable-customers" rows={5}
                                   dataKey="sous_attent_personnid" filters={filters2} filterDisplay="row"  responsiveLayout="scroll"
                                   globalFilterFields={['sous_attent_personnid','sous_attent_personn_nom', 'sous_attent_personn_prenom','sous_attent_personn_nbre_annee_experience','domaine_formation.domaine_formation_libelle', 'sous_attent_personn_email','fonction.fonctionlibelle']} header={header2} emptyMessage="Pas de souscription  en attente pour ce critère de choix!">

                            <Column field="sous_attent_personnid" header="N°" filterField="sous_attent_personnid" body={IdsouscriptionBodyTemplate}  />

                            <Column header="Nom" filterField="sous_attent_personn_nom" style={{ minWidth: '15rem' }} body={NomBodyTemplate}  />

                            <Column  header="Prénom" filterField="sous_attent_personn_prenom" body={PreNomBodyTemplate} style={{ minWidth: '18rem' }} />

                            <Column  header="Email" filterField="sous_attent_personn_email" body={emailBodyTemplate} style={{ minWidth: '18rem' }} />

                            <Column  header="Sexe" filterField="sous_attent_personn_sexe" body={sexeBodyTemplate}  style={{ minWidth: '18rem' }} />

                            <Column  header="Domaine de formation" filterField="domaine_formation.domaine_formation_libelle" body={domaineFormationBodyTemplate} style={{ minWidth: '18rem' }} />

                            <Column filterField="sous_attent_personn_nbre_annee_experience" header="Année d'expérience"  body={nomBreAnneeExperienceBodyTemplate} />
                            <Column filterField="fonction.fonctionlibelle" header="Fonction"  body={fonctionBodyTemplate}  style={{ minWidth: '18rem' }}/>
                            <Column body={actionBodyTemplate}></Column>
                        </DataTable>
                    </div>

                    <Dialog visible={showMessage} onHide={() => setShowMessage(false)} position="top" footer={dialogFooter} showHeader={false} breakpoints={{ '960px': '80vw' }} style={{ width: '30vw' }}>
                        <div className="flex justify-content-center flex-column pt-6 px-3">
                            <i className="pi pi-check-circle" style={{ fontSize: '5rem', color: 'var(--green-500)' }}></i>
                            <h5>{"GERER SON PANIER RECRUTEMENT"}</h5>
                            <p style={{ lineHeight: 1.5, textIndent: '1rem' }}>
                               {retourApi}
                            </p>
                        </div>
                    </Dialog>

                    <Dialog visible={productDialog} style={{ width: '650px' }} header="Details personnel" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                        <form ref={form} onSubmit={saveProduct}>
                        <div className="formgrid grid">
                            <div className="field col">
                                <label htmlFor="name1">Diplôme récent</label>
                                <InputText value={souscriptionAttente.sous_attent_personn_diplome_recent===""? "NEANT":souscriptionAttente.sous_attent_personn_diplome_recent } disabled />
                            </div>

                            <div className="field col">
                                <label htmlFor="name1">Date de naissance</label>
                                <InputText value={souscriptionAttente.sous_attent_personn_date_naissance} disabled />
                            </div>
                            <div className="field col">
                                <label htmlFor="name1">Contact</label>
                                <InputText value={souscriptionAttente.sous_attent_personn_contact} disabled />
                            </div>

                            </div>



                        <div className="formgrid grid">
                        <div className="field col">
                            <Dropdown value={etatInscription} onChange={(e) => setetatInscription(e.value)} options={EtatInscriptions} optionLabel="etat_libelle" placeholder="Ajouter dans le panier" />
                        </div>
                        </div>




                            <input type="text" name="user_name" value={userName} onChange={(e) => setUserName(e.target.value)}  hidden={true} />

                            <input type="email" name="user_email" value={userEmail} onChange={(e) => setUserEmail(e.target.value)} hidden={true} />

                            <textarea name="message" value={userMessage} onChange={(e) => setUserMessage(e.target.value)} hidden={true} />
                            <input type="submit" value="Send" hidden={true} />
                        </form>
                        <div className="flex align-items-center export-buttons">
                            <Tooltip target=".export-buttons>button" position="bottom" />
                            <Button type="button" data-pr-tooltip="Fichier1" icon="pi pi-file" className="mr-2" onClick={() => openFile1(souscriptionAttente.sous_attent_personn_lien_cv)}  />
                            <Button data-pr-tooltip="Fichier2" icon="pi pi-file" className="mr-2" onClick={() => openFile1(souscriptionAttente.sous_attent_personn_lien_piece)}  />
                            <Button data-pr-tooltip="Fichier3" icon="pi pi-file" className="mr-2" onClick={() => openFile1(souscriptionAttente.sous_attent_personn_lien_autorisation)}  />
                        </div>
                    </Dialog>

                    <Dialog visible={FileDialog} style={{ width: '750px', height:'800px'}}  modal className="p-fluid"  onHide={hideFileDialog}>
                        <FileViewer
                            fileType={fileExten}

                            filePath={file}
                        />
                    </Dialog>


                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(AjouterPanierPersonnel, comparisonFn);
