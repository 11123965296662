import axios from 'axios';
import { BaseService } from './BaseService';


export class ClasseMatiereService extends BaseService {

    async getMatiereByBrancheViaClasse(classe) {
        return axios.get(this.getHost() + 'classe-matiere/get-by-branche-via-classe?classe=' + classe).then(res => res.data);
    }

    async getByBranche(branche, ecole) {
        return axios.get(this.getHost() + 'classe-matiere/get-by-branche?branche=' + branche+'&ecole='+ecole).then(res => res.data);
    }
    

}
