import axios from 'axios';
import { BaseService } from './BaseService';

export class ProgressionService extends BaseService {

  async save(progression) {
    console.log(progression)
    return axios.post(this.getHost() + 'progression/handle-save', progression).then(res => res.data);
  }

  async getByAnnee(anneeId) {
    return axios.get(this.getHost() + 'progression/get-by-annee/' + anneeId).then(res => res.data);
  }

  async getBySeance(seanceId) {
    return axios.get(this.getHost() + 'progression/get-by-seance/' + seanceId).then(res => res.data);
  }

  async handleDelete(id) {
    return axios.get(this.getHost() + 'progression/handle-delete/'+ id).then(res => res.data);
  }
}