import axios from 'axios';
import { BaseService } from '../BaseService';
export class ParentService extends  BaseService{

    getAllParent() {
        return axios.get('parent').then(res => res.data);
    }

    updateMatiere(idParent,Parent) {
        return axios.put(this.getHost()+'parent/'+idParent,Parent).then(res => res.data);
    }

    deleteParent(idParent) {
        return axios.delete(this.getHost()+'parent/'+idParent).then(res => res.data);
    }

    creerParent(Parent) {
        return axios.post(this.getHost()+'parent/',Parent).then(res => res.data);
    }


    getProductsSmall() {
        return axios.get('assets/demo/data/products-small.json').then(res => res.data.data);
    }

    getProducts() {
        return axios.get('assets/demo/data/products.json').then(res => res.data.data);
    }

    getProductsWithOrdersSmall() {
        return axios.get('assets/demo/data/products-orders-small.json').then(res => res.data.data);
    }
}
