import React from "react";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css'; // Import Splide CSS

const brandData = [
  {
    hoverImg: "images/partenaire/academie_elite.jpg",
    mainImg: "images/partenaire/academie_elite.jpg", // Exemple d'utilisation du même chemin pour mainImg
    link: "#",
  },
  {
    hoverImg: "images/partenaire/adam_marshall.png",
    mainImg: "images/partenaire/adam_marshall.png",
    link: "#",
  },
  {
    hoverImg: "images/partenaire/ale_ahin.jpeg",
    mainImg: "images/partenaire/ale_ahin.jpeg",
    link: "#",
  },
  // {
  //   hoverImg: "images/partenaire/Amsterdam_Deenatype",
  //   mainImg: "images/partenaire/Amsterdam_Deenatype",
  //   link: "#",
  // },
  // {
  //   hoverImg: "images/partenaire/amsterdam_deenatype.zip",
  //   mainImg: "images/partenaire/amsterdam_deenatype.zip",
  //   link: "#",
  // },
  {
    hoverImg: "images/partenaire/anouanze.jpeg",
    mainImg: "images/partenaire/anouanze.jpeg",
    link: "#",
  },
  {
    hoverImg: "images/partenaire/bagnon.jpeg",
    mainImg: "images/partenaire/bagnon.jpeg",
    link: "#",
  },
  {
    hoverImg: "images/partenaire/bel_air.jpeg",
    mainImg: "images/partenaire/bel_air.jpeg",
    link: "#",
  },
  {
    hoverImg: "images/partenaire/cofe_cesa.jpg",
    mainImg: "images/partenaire/cofe_cesa.jpg",
    link: "#",
  },
  {
    hoverImg: "images/partenaire/couronne.jpeg",
    mainImg: "images/partenaire/couronne.jpeg",
    link: "#",
  },
  {
    hoverImg: "images/partenaire/cp_tanda.jpeg",
    mainImg: "images/partenaire/cp_tanda.jpeg",
    link: "#",
  },
  {
    hoverImg: "images/partenaire/cpmte.jpeg",
    mainImg: "images/partenaire/cpmte.jpeg",
    link: "#",
  },
  {
    hoverImg: "images/partenaire/csm_koumassi.jpeg",
    mainImg: "images/partenaire/csm_koumassi.jpeg",
    link: "#",
  },
  {
    hoverImg: "images/partenaire/csm_niangon.png",
    mainImg: "images/partenaire/csm_niangon.png",
    link: "#",
  },
  {
    hoverImg: "images/partenaire/degre.jpeg",
    mainImg: "images/partenaire/degre.jpeg",
    link: "#",
  },
  {
    hoverImg: "images/partenaire/essect.png",
    mainImg: "images/partenaire/essect.png",
    link: "#",
  },
  {
    hoverImg: "images/partenaire/etabaz.jpg",
    mainImg: "images/partenaire/etabaz.jpg",
    link: "#",
  },
  {
    hoverImg: "images/partenaire/faidherbe.jpeg",
    mainImg: "images/partenaire/faidherbe.jpeg",
    link: "#",
  },
  {
    hoverImg: "images/partenaire/fontaine.jpeg",
    mainImg: "images/partenaire/fontaine.jpeg",
    link: "#",
  },
  {
    hoverImg: "images/partenaire/gadouan.jpeg",
    mainImg: "images/partenaire/gadouan.jpeg",
    link: "#",
  },
  {
    hoverImg: "images/partenaire/gaston_angbonon.jpeg",
    mainImg: "images/partenaire/gaston_angbonon.jpeg",
    link: "#",
  },
  {
    hoverImg: "images/partenaire/gnakan.jpeg",
    mainImg: "images/partenaire/gnakan.jpeg",
    link: "#",
  },
  {
    hoverImg: "images/partenaire/gs_marie_oceane.jpeg",
    mainImg: "images/partenaire/gs_marie_oceane.jpeg",
    link: "#",
  },
  {
    hoverImg: "images/partenaire/gue_pascal.jpeg",
    mainImg: "images/partenaire/gue_pascal.jpeg",
    link: "#",
  },
  {
    hoverImg: "images/partenaire/horeb_elites.jpeg",
    mainImg: "images/partenaire/horeb_elites.jpeg",
    link: "#",
  },
  {
    hoverImg: "images/partenaire/igon.jpeg",
    mainImg: "images/partenaire/igon.jpeg",
    link: "#",
  },
  {
    hoverImg: "images/partenaire/issa_traore.jpeg",
    mainImg: "images/partenaire/issa_traore.jpeg",
    link: "#",
  },
  {
    hoverImg: "images/partenaire/kadje.jpeg",
    mainImg: "images/partenaire/kadje.jpeg",
    link: "#",
  },
  {
    hoverImg: "images/partenaire/kahouli.jpeg",
    mainImg: "images/partenaire/kahouli.jpeg",
    link: "#",
  },
  {
    hoverImg: "images/partenaire/kavoetcheva.jpeg",
    mainImg: "images/partenaire/kavoetcheva.jpeg",
    link: "#",
  },
  {
    hoverImg: "images/partenaire/kouadio_adja.jpeg",
    mainImg: "images/partenaire/kouadio_adja.jpeg",
    link: "#",
  },
  {
    hoverImg: "images/partenaire/logo_tech_tanda.jpeg",
    mainImg: "images/partenaire/logo_tech_tanda.jpeg",
    link: "#",
  },
  {
    hoverImg: "images/partenaire/makinde.jpeg",
    mainImg: "images/partenaire/makinde.jpeg",
    link: "#",
  },
  {
    hoverImg: "images/partenaire/marie_adiake.jpeg",
    mainImg: "images/partenaire/marie_adiake.jpeg",
    link: "#",
  },
  {
    hoverImg: "images/partenaire/marie_elibou.png",
    mainImg: "images/partenaire/marie_elibou.png",
    link: "#",
  },
  {
    hoverImg: "images/partenaire/memel_fote.png",
    mainImg: "images/partenaire/memel_fote.png",
    link: "#",
  },
  {
    hoverImg: "images/partenaire/nid_fatima.jpeg",
    mainImg: "images/partenaire/nid_fatima.jpeg",
    link: "#",
  },
  {
    hoverImg: "images/partenaire/olympique.jpg",
    mainImg: "images/partenaire/olympique.jpg",
    link: "#",
  },
  {
    hoverImg: "images/partenaire/oyofo.bmp",
    mainImg: "images/partenaire/oyofo.bmp",
    link: "#",
  },
  {
    hoverImg: "images/partenaire/paradis.jpeg",
    mainImg: "images/partenaire/paradis.jpeg",
    link: "#",
  },
  {
    hoverImg: "images/partenaire/pierre_marie.jpeg",
    mainImg: "images/partenaire/pierre_marie.jpeg",
    link: "#",
  },
  {
    hoverImg: "images/partenaire/racine_divo.jpeg",
    mainImg: "images/partenaire/racine_divo.jpeg",
    link: "#",
  },
  {
    hoverImg: "images/partenaire/racine_technique.jpeg",
    mainImg: "images/partenaire/racine_technique.jpeg",
    link: "#",
  },
  {
    hoverImg: "images/partenaire/savant_1.jpeg",
    mainImg: "images/partenaire/savant_1.jpeg",
    link: "#",
  },
  {
    hoverImg: "images/partenaire/semailles.jpeg",
    mainImg: "images/partenaire/semailles.jpeg",
    link: "#",
  },
  {
    hoverImg: "images/partenaire/sinai.jpeg",
    mainImg: "images/partenaire/sinai.jpeg",
    link: "#",
  },
  {
    hoverImg: "images/partenaire/tanda.jpeg",
    mainImg: "images/partenaire/tanda.jpeg",
    link: "#",
  },
  {
    hoverImg: "images/partenaire/talib_bouama.jpeg",
    mainImg: "images/partenaire/talib_bouama.jpeg",
    link: "#",
  },
  {
    hoverImg: "images/partenaire/village_enfants.jpeg",
    mainImg: "images/partenaire/village_enfants.jpeg",
    link: "#",
  },
  {
    hoverImg: "images/partenaire/village_3e.jpg",
    mainImg: "images/partenaire/village_3e.jpg",
    link: "#",
  },
];

const BrandSlider = () => {
  return (
    <div className="brand-area  mb-50">
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="brand-content">
              <h5>340+ Universités et Entreprises de Premier Plan</h5>
            </div>
          </div>
        </div>
        <div className="brand-wrap">
          <Splide
            options={{
              perPage: 8, // Number of brands per page
              pagination: false,
              arrows: true,
              breakpoints: {
                640: {
                  perPage: 1,
                },
                768: {
                  perPage: 2,
                },
                1024: {
                  perPage: 3,
                },
              },
            }}
            className="brand-active"
          >
            {brandData.map((brand, index) => (
              <SplideSlide key={index} className="swiper-slide">
                <div className="brand-item">
                  <a href={brand.link}>
                    <img
                      src={brand.hoverImg}
                      alt=""
                      height={100}
                      className="brand-hover-img"
                    />
                    <img
                      src={brand.mainImg}
                      alt=""
                      height={100}
                      className="brand-main-img"
                    />
                  </a>
                </div>
              </SplideSlide>
            ))}
          </Splide>
        </div>
      </div>
    </div>
  );
};

export default BrandSlider;
