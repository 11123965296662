import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import {Route, useLocation} from 'react-router-dom';

// import {LoginAppTopbar} from "./LoginAppTopbar";
import { AppFooter } from './AppFooter';

import { AppConfig } from './AppConfig';

// import Dashboard from './components/Dashboard';

// import Documentation from './components/Documentation';
// import scrollToTop from "./ScrollToTop";

import Crud from './pages/Crud';

import EmptyPage from './pages/EmptyPage';
import TimelineDemo from './pages/TimelineDemo';

import PrimeReact from 'primereact/api';
import { Tooltip } from 'primereact/tooltip';

import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'prismjs/themes/prism-coy.css';
import './assets/demo/flags/flags.css';
import './assets/demo/Demos.scss';
import './assets/layout/layout.scss';
import './App.scss';
import Inscription from "./components/subComponents/Inscription";
import RapportGlobal from "./components/Rapports/RapportGlobal";
import AttribuerClasse from "./components/subComponents/AttribuerClasse";
import Personnel from "./components/subComponents/Personnel";
import InputDemo from './components/InputDemo';
import FicheProfesseur from "./components/subComponents/FicheProfesseur";
import Login from "./components/login/Login";
import FormLayoutDemo from "./components/FormLayoutDemo";
import FloatLabelDemo from "./components/FloatLabelDemo";
import InvalidStateDemo from "./components/InvalidStateDemo";
import ButtonDemo from "./components/ButtonDemo";
import TableDemo from "./components/TableDemo";
import ListDemo from "./components/ListDemo";
import TreeDemo from "./components/TreeDemo";
import PanelDemo from "./components/PanelDemo";
import OverlayDemo from "./components/OverlayDemo";
import MediaDemo from "./components/MediaDemo";
import MenuDemo from "./components/MenuDemo";
import MessagesDemo from "./components/MessagesDemo";
// import BlocksDemo from "./components/BlocksDemo";
import IconsDemo from "./components/IconsDemo";
import FileDemo from "./components/FileDemo";
import ChartDemo from "./components/ChartDemo";
import MiscDemo from "./components/MiscDemo";
import DashboardDirecteurEtude from "./components/Dashbord/DashboardDirecteurEtude";
import DashboardProfesseur from "./components/Dashbord/DashboardProfesseur";
import InscriptionAvalider from "./components/subComponents/InscriptionAvalider";
import SouscriptionAvalider from "./components/login/SouscriptionAvalider";
import RecrutementPersonnel from "./components/login/RecrutementPersonnel";
import MessagerieFondateur from "./components/login/MessagerieFondateur";


// import App from "./App";
// import ScrollToTop from "./ScrollToTop";
import Connexion from "./components/login/Connexion";
import {AppTopbar} from "./AppTopbar";
import {AppMenu} from "./AppMenu";
import {CSSTransition} from "react-transition-group";
// import {CreerEcole2} from "./components/login/CreerEcole2";
import ImportEleve from "./components/subComponents/ImportEleve";
import SouscripEcole from "./components/login/SouscripEcole";
import compteProfesseur, {CompteProfesseur} from "./components/login/CompteProfesseur";
import ValiderEcole from "./components/login/ValiderEcole";
import AttribuerMatiereClasse from "./components/subComponents/AttribuerMatiereClasse";
import ListePersonnelEcole from "./components/login/ListePersonnelEcole";
import ImprimerRapports from "./components/Rapports/ImprimerRapports";
import AjouterPanierPersonnel from "./components/login/AjouterPanierPersonnel";
import MonPanierPersonnel from "./components/login/MonPanierPersonnel";
import EspacePersonnel from "./components/login/EspacePersonnel";
import ModifierMotDepassPersonnel from "./components/login/ModifierMotDepassPersonnel";
import DocumentsPersonnel from "./components/login/DocumentsPersonnel";
// import MessageriePersonnel from "./components/login/MessageriePersonnel";
// import MessagerieCandidat from "./components/login/MessagerieCandidat";
import MyClasses from './components/MyClasses';
import Activites from './components/Activites';
import Seances from './components/Seances';
import Salles from './components/Salles';
import ClasseEleve from './components/ClasseEleve';
// import EvaluationProf from './components/EvaluationProf';
import PointageBadge from './components/PointageBadge';
// import Evaluation from './components/Evaluation';
import Notes from './components/Views/Notes';
import Moyennes from './components/Moyennes';
import Pointage from './components/Pointage';
import evaluationProf from "./components/EvaluationProf";
import PersonnelMatiereClasse from './components/PersonnelMatiereClasse';
import BoiteReceptionFondateur from "./components/login/BoiteReceptionFondateur";
import BoiteEnvoieFondateur from "./components/login/BoiteEnvoieFondateur";
import matiere from "./components/Matiere";
import SeancesForm from "./components/SeancesForm";
import OpenSeance from "./components/OpenSeance";

const App2 = () => {
    const [layoutMode, setLayoutMode] = useState('static');
    const [layoutColorMode, setLayoutColorMode] = useState('light')
    const [inputStyle, setInputStyle] = useState('outlined');
    const [ripple, setRipple] = useState(true);
    // const [myvisible, setMyvisible] = useState(sessionStorage.getItem('my-menu'));
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
    const copyTooltipRef = useRef();
    const location = useLocation();
    // let history = useHistory();

    PrimeReact.ripple = true;

    let menuClick = false;
    let mobileTopbarMenuClick = false;

    // const increment = () => {
    //     const textForStorage = 'OK'
    //     sessionStorage.setItem('my-menu', textForStorage);
    //     setMyvisible(sessionStorage.getItem('my-menu'));
    // }






    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
    }, [mobileMenuActive]);

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    }

    const onRipple = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value)
    }

    const onLayoutModeChange = (mode) => {
        setLayoutMode(mode)
    }

    const onColorModeChange = (mode) => {
        setLayoutColorMode(mode)
    }

    const onWrapperClick = (event) => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }

        if (!mobileTopbarMenuClick) {
            setMobileTopbarMenuActive(false);
        }

        mobileTopbarMenuClick = false;
        menuClick = false;
    }

    const onToggleMenuClick = (event) => {
        menuClick = true;

        if (isDesktop()) {
            if (layoutMode === 'overlay') {
                if (mobileMenuActive === true) {
                    setOverlayMenuActive(true);
                }

                setOverlayMenuActive((prevState) => !prevState);
                setMobileMenuActive(false);
            }
            else if (layoutMode === 'static') {
                setStaticMenuInactive((prevState) => !prevState);
            }
        }
        else {
            setMobileMenuActive((prevState) => !prevState);
        }

        event.preventDefault();
    }

    const onSidebarClick = () => {
        menuClick = true;
    }

    const onMobileTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        setMobileTopbarMenuActive((prevState) => !prevState);
        event.preventDefault();
    }

    const onMobileSubTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        event.preventDefault();
    }

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
    }
    const isDesktop = () => {
        return window.innerWidth >= 992;
    }

    const menuProfesseur = [
        {
            label: 'TABLEAU DE BORD',
            items: [{
                label: 'Tableau de bord', icon: 'pi pi-fw pi-home', to: '/home/professionnelle'
            }]
        },


        {
            label: 'Gestion des classes et moyennes', icon: 'pi pi-fw pi-sitemap',
            items: [
                // { label: 'Classes', icon: 'pi pi-fw pi-building', to: '/classes' },

                {
                    label: 'Classes', icon: 'pi pi-fw pi-bookmark',
                    items: [
                        {label: 'Liste des classes', icon: 'pi pi-fw pi-building', to: '/home/professionnelle/classes'},
                        { label: 'Séances', icon: 'pi pi-fw pi-pencil', to: '/home/professionnelle/seances'},
                        { label: 'Saisir séances', icon: 'pi pi-fw pi-pencil', to: '/home/professionnelle/seancesForm'},
                        { label: 'Ouverture de seances', icon: 'pi pi-fw pi-pencil', to: '/home/professionnelle/openSeances'},
                        { label: 'Emploi du temps', icon: 'pi pi-fw pi-calendar-minus', to: '/home/professionnelle/activites' },
                        { label: 'Journal pointage Badgeuse', icon: 'pi pi-fw pi-check-circle', to: '/home/professionnelle/pointages' },
                        { label: 'Enrollement Badge', icon: 'pi pi-fw pi-users',  to: '/home/professionnelle/pointagesBadge'},
                    ]
                },
                { label: 'Evaluations', icon: 'pi pi-fw pi-id-card', to: '/home/professionnelle/evaluations' },
                { label: 'Notes & Moyennes', icon: 'pi pi-fw pi-circle-fill', to: '/home/professionnelle/moyennes' }
            ]
        },


    ];
    const menuFondateur = [
        {
            label: 'TABLEAU DE BORD',
            items: [{
                label: 'Tableau de bord', icon: 'pi pi-fw pi-home', to: '/home/professionnelle'
            }]
        },

        {
            label: 'FONDATEUR', icon: 'pi pi-fw pi-sitemap',
            items: [
                { label: "Ajouter au panier", icon: "pi pi-fw pi-check-square", to: "/home/professionnelle/AjouterPanier" },
                { label: "Mon panier", icon: "pi pi-fw pi-check-square", to: "/home/professionnelle/MonPanier" },
                {
                    label: 'Ma Messagerie', icon: 'pi pi-fw pi-bookmark',
                    items: [
                        {label: 'Messages envoyés', icon: 'pi pi-fw pi-send', to: '/home/professionnelle/messageEnvoyes'},
                        { label: 'Messages réçus', icon: 'pi pi-fw pi-inbox', to: '/home/professionnelle/messagerecus'},

                    ]
                },

                { label: "Recruter un Agent", icon: "pi pi-fw pi-check-square", to: "/home/professionnelle/RecrutementPersonnel" },
                { label: "Mon personnel", icon: "pi pi-fw pi-check-square", to: "/home/professionnelle/ListePersonnel" },


            ]
        },

        {
            label: 'Gestion des classes et moyennes', icon: 'pi pi-fw pi-sitemap',
            items: [
                // { label: 'Classes', icon: 'pi pi-fw pi-building', to: '/classes' },

                {
                    label: 'Classes', icon: 'pi pi-fw pi-bookmark',
                    items: [
                        {label: 'Liste des classes', icon: 'pi pi-fw pi-building', to: '/home/professionnelle/classes'},
                        { label: 'Séances', icon: 'pi pi-fw pi-pencil', to: '/home/professionnelle/seances'},
                        { label: 'Saisir séances', icon: 'pi pi-fw pi-pencil', to: '/home/professionnelle/seancesForm'},
                        { label: 'Ouverture de seances', icon: 'pi pi-fw pi-pencil', to: '/home/professionnelle/openSeances'},
                        { label: 'Emploi du temps', icon: 'pi pi-fw pi-calendar-minus', to: '/home/professionnelle/activites' },
                        { label: 'Journal pointage Badgeuse', icon: 'pi pi-fw pi-check-circle', to: '/home/professionnelle/pointages' },
                        { label: 'Enrollement Badge', icon: 'pi pi-fw pi-users',  to: '/home/professionnelle/pointagesBadge'},
                    ]
                },
                { label: 'Evaluations', icon: 'pi pi-fw pi-id-card', to: '/home/professionnelle/evaluations' },
                { label: 'Notes & Moyennes', icon: 'pi pi-fw pi-circle-fill', to: '/home/professionnelle/moyennes' }
            ]
        },

        {
            label: 'Affectations', icon: 'pi pi-fw pi-bookmark',
            items: [
                { label: 'Elève - Classe', icon: 'pi pi-fw pi-chevron-circle-right', to: '/home/professionnelle/eleveclasse' },

                { label: 'Professeur - Matiere', icon: 'pi pi-fw pi-chevron-circle-right', to: '/home/professionnelle/profmatiereclasse' },
            ]
        },
        {
            label: 'Parametrage', icon: 'pi pi-fw pi-check-square',
            items: [
                { label: 'Salles', icon: 'pi pi-fw pi-id-card', to: '/home/professionnelle/salles' },
                {label: 'Matières', icon: 'pi pi-fw pi-building', to: '/home/professionnelle/matieres'}

            ]
        },
        {
            label: 'Inscription', icon: 'pi pi-fw pi-search',
            items: [
                { label: "Importer des eleves ", icon: "pi pi-fw pi-bookmark", to: "/home/professionnelle/importEleve" },
                { label: "Identification eleve", icon: "pi pi-fw pi-bookmark", to: "/home/professionnelle/identification" },
                { label: 'Inscription à Valider', icon: 'pi pi-fw pi-id-card', to: '/home/professionnelle/inscriptionAvalider' },
                // { label: 'Attribution de classe', icon: 'pi pi-fw pi-id-card', to: '/home/professionnelle/AttributionClasse' },
            ]
        },
        {
            label: 'RAPPORT', icon: 'pi pi-fw pi-search',
            items: [
                { label: "Documents administratifs", icon: "pi pi-fw pi-bookmark", to: "/home/professionnelle/imprimerDocument" },
                { label: "Rapport global", icon: "pi pi-fw pi-bookmark", to: "/home/professionnelle/rapportGlobal" },

            ]
        }

        ,

    ];

    const menuGain = [
        {
            label: 'TABLEAU DE BORD',
            items: [{
                label: 'Tableau de bord', icon: 'pi pi-fw pi-home', to: '/home/professionnelle'
            }]
        },




        {
            label: 'ADMINISTRATION', icon: 'pi pi-fw pi-sitemap',
            items: [
                { label: "Valider personnels", icon: "pi pi-fw pi-check-square", to: "/home/professionnelle/souscriptionAvalider" },
                { label: "Valider ecoles", icon: "pi pi-fw pi-check-square", to: "/home/professionnelle/validerEcole" },

            ]
        }

        ,


    ];

    const menu = [
        {
            label: 'TABLEAU DE BORD',
            items: [{
                label: 'Tableau de bord', icon: 'pi pi-fw pi-home', to: '/home/professionnelle'
            }]
        },

        {
            label: 'FONDATEUR', icon: 'pi pi-fw pi-sitemap',
            items: [
                { label: "Ajouter au panier", icon: "pi pi-fw pi-check-square", to: "/home/professionnelle/AjouterPanier" },
                { label: "Mon panier", icon: "pi pi-fw pi-check-square", to: "/home/professionnelle/MonPanier" },
                {
                    label: 'Ma Messagerie', icon: 'pi pi-fw pi-bookmark',
                    items: [
                        {label: 'Messages envoyés', icon: 'pi pi-fw pi-send', to: '/home/professionnelle/messageEnvoyes'},
                        { label: 'Messages réçus', icon: 'pi pi-fw pi-inbox', to: '/home/professionnelle/messagerecus'},

                    ]
                },

                { label: "Recruter un Agent", icon: "pi pi-fw pi-check-square", to: "/home/professionnelle/RecrutementPersonnel" },
                { label: "Mon personnel", icon: "pi pi-fw pi-check-square", to: "/home/professionnelle/ListePersonnel" },


            ]
        },

        {
            label: 'Gestion des classes et moyennes', icon: 'pi pi-fw pi-sitemap',
            items: [
                // { label: 'Classes', icon: 'pi pi-fw pi-building', to: '/classes' },

                {
                    label: 'Classes', icon: 'pi pi-fw pi-bookmark',
                    items: [
                        {label: 'Liste des classes', icon: 'pi pi-fw pi-building', to: '/home/professionnelle/classes'},
                        { label: 'Séances', icon: 'pi pi-fw pi-pencil', to: '/home/professionnelle/seances'},
                        { label: 'Saisir séances', icon: 'pi pi-fw pi-pencil', to: '/home/professionnelle/seancesForm'},
                        { label: 'Ouverture de seances', icon: 'pi pi-fw pi-pencil', to: '/home/professionnelle/openSeances'},
                        { label: 'Emploi du temps', icon: 'pi pi-fw pi-calendar-minus', to: '/home/professionnelle/activites' },
                        { label: 'Journal pointage Badgeuse', icon: 'pi pi-fw pi-check-circle', to: '/home/professionnelle/pointages' },
                        { label: 'Enrollement Badge', icon: 'pi pi-fw pi-users',  to: '/home/professionnelle/pointagesBadge'},
                    ]
                },
                { label: 'Evaluations', icon: 'pi pi-fw pi-id-card', to: '/home/professionnelle/evaluations' },
                { label: 'Notes & Moyennes', icon: 'pi pi-fw pi-circle-fill', to: '/home/professionnelle/moyennes' }
            ]
        },



        {
            label: 'ADMINISTRATION', icon: 'pi pi-fw pi-sitemap',
            items: [
                { label: "Valider personnels", icon: "pi pi-fw pi-check-square", to: "/home/professionnelle/souscriptionAvalider" },
                { label: "Valider ecoles", icon: "pi pi-fw pi-check-square", to: "/home/professionnelle/validerEcole" },

            ]
        }

         ,
        {
            label: 'Affectations', icon: 'pi pi-fw pi-bookmark',
            items: [
                { label: 'Elève - Classe', icon: 'pi pi-fw pi-chevron-circle-right', to: '/home/professionnelle/eleveclasse' },

                { label: 'Professeur - Matiere', icon: 'pi pi-fw pi-chevron-circle-right', to: '/home/professionnelle/profmatiereclasse' },
            ]
        },
        {
            label: 'Parametrage', icon: 'pi pi-fw pi-check-square',
            items: [
                { label: 'Salles', icon: 'pi pi-fw pi-id-card', to: '/home/professionnelle/salles' },
                {label: 'Matières', icon: 'pi pi-fw pi-building', to: '/home/professionnelle/matieres'}

            ] 
        },
        {
            label: 'Inscription', icon: 'pi pi-fw pi-search',
            items: [
                { label: "Importer des eleves ", icon: "pi pi-fw pi-bookmark", to: "/home/professionnelle/importEleve" },
                { label: "Identification eleve", icon: "pi pi-fw pi-bookmark", to: "/home/professionnelle/identification" },
                { label: 'Inscription à Valider', icon: 'pi pi-fw pi-id-card', to: '/home/professionnelle/inscriptionAvalider' },
                // { label: 'Attribution de classe', icon: 'pi pi-fw pi-id-card', to: '/home/professionnelle/AttributionClasse' },
            ]
        },
        {
            label: 'RAPPORT', icon: 'pi pi-fw pi-search',
            items: [
                { label: "Documents administratifs", icon: "pi pi-fw pi-bookmark", to: "/home/professionnelle/imprimerDocument" },
                { label: "Rapport global", icon: "pi pi-fw pi-bookmark", to: "/home/professionnelle/rapportGlobal" },



            ]
        }

     ,

    ];

    const addClass = (element, className) => {
        if (element.classList)
            element.classList.add(className);
        else
            element.className += ' ' + className;
    }

    const removeClass = (element, className) => {
        if (element.classList)
            element.classList.remove(className);
        else
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }

    const wrapperClass = classNames('layout-wrapper', {
        'layout-overlay': layoutMode === 'overlay',
        'layout-static': layoutMode === 'static',
        'layout-static-sidebar-inactive': staticMenuInactive && layoutMode === 'static',
        'layout-overlay-sidebar-active': overlayMenuActive && layoutMode === 'overlay',
        'layout-mobile-sidebar-active': mobileMenuActive,
        'p-input-filled': inputStyle === 'filled',
        'p-ripple-disabled': ripple === false,
        'layout-theme-light': layoutColorMode === 'light'
    });

    return (
        <div className={wrapperClass} onClick={onWrapperClick}>
            <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

            <AppTopbar onToggleMenuClick={onToggleMenuClick} layoutColorMode={layoutColorMode}
                       mobileTopbarMenuActive={mobileTopbarMenuActive} onMobileTopbarMenuClick={onMobileTopbarMenuClick} onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick} />

            <div className="layout-sidebar" onClick={onSidebarClick}>
                <AppMenu model={menu} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />
            </div>

            <div className="layout-main-container">
                <div className="layout-main">
                    {/*<Route path="/home/professionnelle" exact render={() => <DashboardDirecteurEtude colorMode={layoutColorMode} location={location} />} />*/}

                    <Route path="/home/professionnelle" component={ImportEleve}  />

                    <Route path="/home/professionnelle/imprimerDocument" component={ImprimerRapports} />
                    <Route path="/home/professionnelle/rapportGlobal" component={RapportGlobal} />
                    <Route path="/home/professionnelle/ListePersonnel" component={ListePersonnelEcole} />
                    <Route path="/home/professionnelle/RecrutementPersonnel" component={RecrutementPersonnel} />
                    <Route path="/home/professionnelle/importEleve" component={ImportEleve} />
                    <Route path="/home/professionnelle/formlayout" component={FormLayoutDemo} />
                    <Route path="/home/professionnelle/souscriptionAvalider" component={SouscriptionAvalider} />
                    <Route path="/home/professionnelle/inscriptionAvalider" component={InscriptionAvalider} />
                    <Route path="/home/professionnelle/AttributionClasse" component={AttribuerClasse} />
                    <Route path="/home/professionnelle/inscrireEtablissement" component={SouscripEcole} />
                    <Route path="/home/professionnelle/dashbordDE" component={DashboardDirecteurEtude} />
                    <Route path="/home/professionnelle/dashbordProfesseur" component={DashboardProfesseur} />
                    <Route path="/home/professionnelle/creerSonCompte" component={CompteProfesseur} />
                    <Route path="/home/professionnelle/identification" component={Inscription} />
                    <Route path="home/professionnelle/evaluations/notes/:evalCode" component={Notes} />
                    <Route path="/home/professionnelle/login" component={Login} />
                    <Route path="/home/professionnelle/connexion" component={Connexion} />
                    <Route path="/home/professionnelle/validerEcole" component={ValiderEcole} />
                    <Route path="/home/professionnelle/personnel" component={Personnel} />
                    <Route path="/home/professionnelle/ficheProfesseur" component={FicheProfesseur} />
                    <Route path="/home/professionnelle/ListeProfesseur" component={AttribuerMatiereClasse} />
                    <Route path="/home/input" component={InputDemo} />
                    <Route path="/home/floatlabel" component={FloatLabelDemo} />
                    <Route path="/home/invalidstate" component={InvalidStateDemo} />
                    <Route path="/home/button" component={ButtonDemo} />
                    <Route path="/home/table" component={TableDemo} />
                    <Route path="/home/list" component={ListDemo} />
                    <Route path="/home/tree" component={TreeDemo} />
                    <Route path="/home/panel" component={PanelDemo} />
                    <Route path="/home/overlay" component={OverlayDemo} />
                    <Route path="/home/media" component={MediaDemo} />
                    <Route path="/home/menu" component={MenuDemo} />
                    <Route path="/home/messages" component={MessagesDemo} />
                    {/*<Route path="/home/blocks" component={BlocksDemo} />*/}
                    <Route path="/home/icons" component={IconsDemo} />
                    <Route path="/home/file" component={FileDemo} />
                    <Route path="/home/chart" render={() => <ChartDemo colorMode={layoutColorMode} location={location} />} />
                    <Route path="/home/misc" component={MiscDemo} />
                    <Route path="/home/timeline" component={TimelineDemo} />
                    <Route path="/home/crud" component={Crud} />

                    <Route path="/home/empty" component={EmptyPage} />
                    <Route path="/home/professionnelle/classes" component={MyClasses} />
                    <Route path="/home/professionnelle/activites" component={Activites} />
                    <Route path="/home/professionnelle/openSeances" component={OpenSeance} />
                    <Route path="/home/professionnelle/seances" component={Seances} />
                    <Route path="/home/professionnelle/seancesForm" component={SeancesForm} />

                    <Route path="/home/professionnelle/pointages" component={Pointage} />
                    <Route path="/home/professionnelle/pointagesBadge" component={PointageBadge} />
                    <Route exact path="/home/professionnelle/evaluations" component={evaluationProf} />
                    <Route path="/home/professionnelle/evaluations/notes/:evalCode" component={Notes} />
                    <Route path="/home/professionnelle/moyennes" component={Moyennes} />
                    <Route path="/home/professionnelle/salles" component={Salles} />
                    <Route path="/home/professionnelle/eleveclasse" component={ClasseEleve} />
                    <Route path="/home/professionnelle/profmatiereclasse" component={PersonnelMatiereClasse} />
                    <Route path="/home/professionnelle/matieres" component={matiere} />


                    <Route path="/home/professionnelle/AjouterPanier" component={AjouterPanierPersonnel} />
                    <Route path="/home/professionnelle/MessagerieFondateur" component={MessagerieFondateur} />
                    <Route path="/home/professionnelle/MonPanier" component={MonPanierPersonnel} />
                    <Route path="/home/professionnelle/monProfil" component={EspacePersonnel} />
                    <Route path="/home/professionnelle/ModifierMotdePasse" component={ModifierMotDepassPersonnel} />
                    <Route path="/home/professionnelle/MesDocuments" component={DocumentsPersonnel} />
                    <Route path="/home/professionnelle/messageEnvoyes" component={BoiteEnvoieFondateur} />
                    <Route path="/home/professionnelle/messageRecus" component={BoiteReceptionFondateur} />
                    {/*<Route path="/home/documentation" component={Documentation} />*/}
                </div>

                <AppFooter layoutColorMode={layoutColorMode} />
            </div>

            <AppConfig rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange}
                       layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange} />

            <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
                <div className="layout-mask p-component-overlay"></div>
            </CSSTransition>

        </div>
    );

}

export default App2;
