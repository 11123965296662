import axios from 'axios';

import { BaseService } from '../BaseService';
export class SouscriptionService  extends BaseService{

    listMessageEnvoyes(idUser) {
        return axios.get(this.getHost()+'message-personnel/boite-envoie/'+idUser).then(res => res.data);
    }

    checkpseudo(login) {
        return axios.get(this.getHost()+'connexion/check-pseudo/'+login).then(res => res.data);
    }
    sendEmail(destinataire ,message,objet) {
        return axios.post(this.getHost()+'sendEmail?destinataire='+destinataire+'&message='+message+'&objet='+objet).then(res => res.data);
    }

    listMessageRecus(idUser) {
        return axios.get(this.getHost()+'message-personnel/boite-reception/'+idUser).then(res => res.data);
    }

    listDomaineFormation() {
        return axios.get(this.getHost()+'Domaine_formation/').then(res => res.data);
    }
    listProfils() {
        return axios.get(this.getHost()+'profil/').then(res => res.data);
    }

    listVisibleProfils() {
        return axios.get(this.getHost()+'profil/profil-visible').then(res => res.data);
    }



    listTypeAutorisation() {
        return axios.get(this.getHost()+'typeAutorisation/').then(res => res.data);
    }
    listfonction(fondateur) {
        return axios.get(this.getHost()+'fonction/sans-fondateur/'+fondateur).then(res => res.data);
    }

    listNiveau_etude() {
        return axios.get(this.getHost()+'niveau_etude/').then(res => res.data);
    }

    listSouscription() {
        return axios.get(this.getHost()+'souscription-personnel/').then(res => res.data);
    }
    listSouscriptionAvalider(status) {
        return axios.get(this.getHost()+'souscription-personnel/attente/'+status).then(res => res.data);
    }

    listSouscriptionFondateurAvalider(status,fondateur) {
        return axios.get(this.getHost()+'souscription-personnel/attente-fondateur/'+status+'/'+fondateur).then(res => res.data);
    }

    infosProfil(idPersonnel) {
        return axios.get(this.getHost()+'souscription-personnel/personnelById/'+idPersonnel).then(res => res.data);
    }


    listPaniersParEcole(idEcole,statut) {
        return axios.get(this.getHost()+'panier-personnel/ecole/'+idEcole+'/'+statut).then(res => res.data);
    }
    listMessageParEcole(idEcole) {
        return axios.get(this.getHost()+'message-personnel/ecole/'+idEcole).then(res => res.data);
    }

    listMessageParCandidat(idCandidat) {
        return axios.get(this.getHost()+'message-personnel/personnel-connectes/'+idCandidat).then(res => res.data);
    }

    candidatById(idPersonnel) {
        return axios.get(this.getHost()+'souscription-personnel/personnelById/'+idPersonnel).then(res => res.data);
    }


    listPersonnelParEcole(idEcole) {
        return axios.get(this.getHost()+'souscription-personnel/personnel/'+idEcole).then(res => res.data);
    }

    listClasseParProfesseur(idPersonnel,idAnnee) {
        return axios.get(this.getHost()+'souscription-personnel/classe-par-prof/'+idPersonnel+'/'+idAnnee).then(res => res.data);
    }

    listSouscriptionEcolesAvalider(status) {
        return axios.get(this.getHost()+'souscription-ecole/allSouscriptionEcoles/'+status).then(res => res.data);
    }

    listEcolesParFondateur(idSouscripteur) {
        return axios.get(this.getHost()+'souscription-ecole/allSouscriptionEcoles-fondateur/'+idSouscripteur).then(res => res.data);
    }



    ouvrirFichier(path) {
        return axios.get(this.getHost()+'souscription-personnel/ouvrir-fichier/'+path).then(res => res.data);
    }

    validerSouscription(souscription) {
        return axios.put(this.getHost()+'souscription-personnel/valider-souscription-personnel/',souscription).then(res => res.data);
    }
    envoyeAdminMess(idPersonnel,message) {
        return axios.post(this.getHost()+'message-personnel/admin-envoyer-message/'+idPersonnel,message).then(res => res.data);
    }

    validerSouscriptionFonda(souscription) {
        return axios.put(this.getHost()+'souscription-personnel/valider-souscription-fondateur/',souscription).then(res => res.data);
    }

    validerSouscriptionEcole(souscription) {
        return axios.put(this.getHost()+'souscription-ecole/valider-ecoles/',souscription).then(res => res.data);
    }

    nombreEcoleFondateurNonValider(idFondateur) {
        return axios.get(this.getHost()+'souscription-personnel/nombre-ecole-valider-par-fondateur/'+idFondateur).then(res => res.data);
    }


    nombreNewEcoleFondateurNonValider(idFondateur) {
        return axios.get(this.getHost()+'souscription-personnel/nombre-nouvelle-ecoles-valider/'+idFondateur).then(res => res.data);
    }

    validerRecruterPersonnel(idEcole,idsouscri) {
        return axios.post(this.getHost()+'souscription-personnel/recruter/'+idEcole+'/'+idsouscri).then(res => res.data);
    }

    infosListeConnexion(idEcole) {
        return axios.get(this.getHost()+'personnel/infos-connexion-personnels/'+idEcole).then(res => res.data);
    }

    desacffecterProfilUtisateur(active,idEcole,idPersonnel,idProfil) {
        return axios.put(this.getHost()+'connexion/desactiver-activer-profil-utilisateur?active='+active+'&ecoleId='+idEcole+'&personnelId='+idPersonnel+'&profilId='+idProfil).then(res => res.data);
    }

    affecterProfilUtisateur(AffecterProfilUtilisateurDto) {
        return axios.post(this.getHost()+'connexion/affecter-profil-utilisateur',AffecterProfilUtilisateurDto).then(res => res.data);
    }

    ajouterPanierPersonnel(panier) {
        return axios.post(this.getHost()+'panier-personnel',panier).then(res => res.data);
    }

    envoyerMessagePersonnel(identifiantPanier,message) {
        return axios.post(this.getHost()+'message-personnel/'+identifiantPanier,message).then(res => res.data);
    }

    validerPanier(identifiantPanier) {
        return axios.put(this.getHost()+'panier-personnel/validerPanier/'+identifiantPanier).then(res => res.data);
    }

    chargerFichiers(fichiers) {
        return axios.post(this.getHost()+'souscription-personnel/files',fichiers)
            .then(res => res.data);
    }

    creerSoucriptProfesseur(souscriptionProfess) {
        return axios.post(this.getHost()+'souscription-personnel/',souscriptionProfess)
            .then(res => res.data);
    }


    modifierSoucriptProfesseur(souscriptionProfess) {
        return axios.put(this.getHost()+'souscription-personnel/',souscriptionProfess)
            .then(res => res.data);
    }

    SouscriptionByEmail(email) {
        return axios.get(this.getHost()+'souscription-personnel/email/'+email).then(res => res.data);
    }

    listVilleParPays(idPays) {
        return axios.get(this.getHost()+'ville/pays/'+idPays).then(res => res.data);
    }

    listDirectionParPays(idPays) {
        return axios.get(this.getHost()+'DirectionGenerale/pays/'+idPays).then(res => res.data);
    }
    listVilleParDirect(idDirect) {
        return axios.get(this.getHost()+'ville/direction-regionale/'+idDirect).then(res => res.data);
    }

    listcommuneParVille(idVille) {
        return axios.get(this.getHost()+'commune/ville/'+idVille).then(res => res.data);
    }

    listzoneParCommune(idCommune) {
        return axios.get(this.getHost()+'zone/commune/'+idCommune).then(res => res.data);
    }


    listDirectionRegioParPays(idPays) {
        return axios.get(this.getHost()+'DirectionGenerale/pays/'+idPays).then(res => res.data);
    }

    listPays() {
        return axios.get(this.getHost()+'pays/').then(res => res.data);
    }
    listNiveauEnseignement() {
        return axios.get(this.getHost()+'niveau-enseignement/list').then(res => res.data);
    }
    listZone() {
        return axios.get(this.getHost()+'zone/').then(res => res.data);
    }

    getFondateurId(fondateur) {
        return axios.get(this.getHost()+'fonction/fondateur/'+fondateur).then(res => res.data);
    }
    getSouscipteurEtblissAmodifier(idfondateur) {
        return axios.get(this.getHost()+'souscription-ecole/souscri-etabliss-a-modifier-fondateur/'+idfondateur).then(res => res.data);
    }
    creerSoucripEcole(contact1,contact2,fonctionId,nom,prenom,email,passWord,login,listEcole) {
        return axios.post(this.getHost()+'souscription-ecole/souscription-etablissement?'+'contact1='+contact1+'&contact2='+contact2+'&fonctionId='+fonctionId+'&nom='+nom+'&prenom='+prenom +'&email='+email+'&passWord='+passWord +'&login='+login,listEcole)
                .then(res => res.data);
    }

    ajouterSoucripEcole(idSouscrip,listEcole) {
        return axios.post(this.getHost()+'souscription-ecole/ajouter/souscription-etablissement?'+'idSouscrip='+idSouscrip,listEcole)
            .then(res => res.data);
    }

    modifierSoucripEcole(contact1,contact2,souscripId,nom,prenom,email,listEcole) {
        return axios.put(this.getHost()+'souscription-ecole/souscription-etablissement?'+'contact1='+contact1+'&contact2='+contact2+'&souscripId='+souscripId+'&nom='+nom+'&prenom='+prenom +'&email='+email,listEcole)
            .then(res => res.data);
    }

    miseAjourRapportInfosEtabliss(etablissement) {
        return axios.post(this.getHost()+'souscription-ecole/rapport-infos-etablissement',etablissement)
            .then(res => res.data);
    }
    miseAjourRapportDirecteur(directeur) {
        return axios.post(this.getHost()+'souscription-ecole/rapport-infos-directeur_etude',directeur)
            .then(res => res.data);
    }

    miseAjourRapportFondateur(fondateur) {
        return axios.post(this.getHost()+'souscription-ecole/rapport-infos-fondateur',fondateur)
            .then(res => res.data);
    }

    chargerPhoto(photo,codeEcole ,NiveauEnseign) {
        return axios.put(this.getHost()+'souscription-ecole/charger-photo-etablissement/'+codeEcole+'/'+NiveauEnseign,photo).then(res => res.data);
    }

    chargerFiligrane(photo,codeEcole,NiveauEnseign ) {
        return axios.put(this.getHost()+'souscription-ecole/charger-filigrane-etablissement/'+codeEcole+'/'+NiveauEnseign,photo).then(res => res.data);
    }



    checkEmailConnexion(email) {
        return axios.post(this.getHost()+'connexion/'+email)
            .then(res => res.data);
    }

}
