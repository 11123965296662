import React, { useState, useEffect, useRef } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import FileViewer from 'react-file-viewer';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import FileDemo from "../FileDemo";
import {BaseService} from "../../service/BaseService";
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';


import {Dropdown} from "primereact/dropdown";
import {SouscriptionService} from "../../service/operations/SouscriptionService";

import { Tooltip } from 'primereact/tooltip';
import { InputTextarea } from 'primereact/inputtextarea';
import emailjs from '@emailjs/browser';
import {useHistory} from "react-router-dom";

const ValiderEcole = () => {
    const form = useRef();
    let emptyDomaineFormation = {
        domaine_formationid :null ,
        domaine_formation_code :'',
        domaine_formation_libelle:''
    };

    let sendEmailEmpty = {
        subject :'Bonjour SOUM',
        user_name :'SOUMSMITH1',
        user_email:'soumsmith1@gmail.com',
        message:'Juste un text'
    };

    let emptySexe = {
        sexe_code :'',
        sexe_libelle :''
    };
    let emptyNiveauEtude = {
        niveau_etudeid :null ,
        niveau_etude_code :'',
        niveau_etude_libelle:''
    };
    let emptyAnneeExperience = {
        nbreAnnee_code :0 ,
        nbreAnnee_libelle :''
    };
    let emptyEtatInscription={
        etat_code:'',
        etat_libelle:''
    } ;
    let souscriptionValidaEmpty= {
        idsouscrip:null ,
        statuts: '',
        messageRefus:''
    } ;
    let emptyfonction = {
        fonctionid :null ,
        fonctioncode :'',
        fonctionlibelle:''
    } ;
    let emptyDirectionGeneral = {
        id :null ,
        code :'',
        libelle:''
    };

    let emptyNiveauEnsein = {
        id :null ,
        code :'',
        libelle:''
    };

    let emptyVille = {
        villeid :null,
        villecode :'',
        villelibelle:'',
        myDirection_regionale:null
    };
    let emtyzone = {
        zoneid :null ,
        zonecode :'',
        zonelibelle:'',
        commune_communeid: null
    };

    let emptypays = {
        paysid :null ,
        payslibelle :'',
        payscode:''
    };
    let emptyCommune = {
        communeid :null,
        communecode :'',
        communelibelle:'',
        ville_villeid:null
    };
    const [fonction, setFonction] = useState(emptyfonction);
    const [etatInscription, setetatInscription]  = useState(emptyEtatInscription);
    const [souscVali, setsouscVali] = useState(souscriptionValidaEmpty);
    const [niveauEnseignement, setNiveauEnseignement] = useState(emptyNiveauEnsein);
    const defaultValues2 ={
        sousc_atten_etablissid: null,
        sousc_atten_etablisscode: '',
        sousc_atten_etabliss_nom: '',
        sousc_atten_etabliss_fondateur: '',
        sousc_atten_etabliss_tel: '',
        sousc_atten_etabliss_email:'',
        sousc_atten_etabliss_indication: '',
        sousc_atten_etabliss_lien_autorisa:'',
        sousc_atten_etabliss_lien_logo:'',
        // Niveau_Enseignement_id:null ,
        niveau_Enseignement_id:niveauEnseignement,
        niveau_Enseignement_obj :niveauEnseignement,
        ville_ville : '',
        zone_zone : '',
        pays : '',
        commune_commune: '',
        ville_villeid: null,
        zone_zoneid:null,
        commune_communeid:null,
        myDirection_regionale:'',
        nomFondateur:'',
        prenomFondateur:''

    }


    let history = useHistory();
    const columns = [
        {field: 'inscriptions_status', header: 'Statut Inscription'},
        {field: 'inscriptionsidEleve', header: 'N°'},
        {field: 'nomEleve', header: 'Nom'},
        {field: 'prenomEleve', header: 'Prenom'},
        {field: 'matriculeEleve', header: 'Matricule'},
        {field: 'inscriptions_type', header: 'Type Inscription'}
    ];
    const [selectedColumns, setSelectedColumns] = useState(columns);

    const [filters2, setFilters2] = useState({
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'sousc_atten_etablissid':{ value: null, matchMode: FilterMatchMode.STARTS_WITH },
        'sousc_atten_etablisscode':{ value: null, matchMode: FilterMatchMode.CONTAINS },
        'sousc_atten_etabliss_nom':{ value: null, matchMode: FilterMatchMode.CONTAINS },
        'sousc_atten_etabliss_tel':  { value: null, matchMode: FilterMatchMode.CONTAINS } ,
        'sousc_atten_etabliss_email':  { value: null, matchMode: FilterMatchMode.CONTAINS } ,
        'sousc_atten_etabliss_indication':  { value: null, matchMode: FilterMatchMode.CONTAINS } ,
        'ville_ville':  { value: null, matchMode: FilterMatchMode.CONTAINS } ,
        'commune_commune':  { value: null, matchMode: FilterMatchMode.CONTAINS },
        'Niveau_Enseignement_id':  { value: null, matchMode: FilterMatchMode.CONTAINS },
        'zone_zone':  { value: null, matchMode: FilterMatchMode.CONTAINS },
        'niveau_Enseignement_obj':  { value: null, matchMode: FilterMatchMode.CONTAINS },
        'sousc_atten_etabliss_lien_autorisa':  { value: null, matchMode: FilterMatchMode.CONTAINS },
        'sousc_atten_etabliss_lien_logo':  { value: null, matchMode: FilterMatchMode.CONTAINS },
    });


    const [etablis, setEtablis] = useState([]);
    const [pays, setpays] = useState(emptypays);
    const [payss, setpayss] = useState(null);
    const [fonctionFon, setfonctionFon] = useState('FONDATEUR');
    const [niveauEnseignements, setNiveauEnseignements] = useState(null);
    const [ville, setville] = useState(emptyVille);
    const [villes, setvilles] = useState(null);
    const [zone, setzone] = useState(emtyzone);
    const [FileName, setFileName] = useState('');
    const [fileExten, setExtentfileName] = useState('');
    const [zones, setzones] = useState(null);
    const [directionGen, setDirectionGen] = useState(emptyDirectionGeneral);
    const [directionGens, setDirectionGens] = useState([]);
    const [commune, setCommune] = useState(emptyCommune);
    const [communes, setCommunes] = useState([]);
    const [sousAvalider, setSousAvalider] = useState(defaultValues2);
    const [etabli, setEtabli] = useState(defaultValues2);
    const [sousAvaliders, setSousAvaliders] = useState(null);
    const [emailObject, setEmailObject] = useState(sendEmailEmpty);
    const [idModif, setIdmodif] = useState(null);
    const [statut, setstatut] = useState("EN_ATTENTE");
   

    const [motifRefu, setmotifRefu] = useState('');

    const [globalFilterValue2, setGlobalFilterValue2] = useState('');

    
    const [eleves, setEleves]  = useState(null);
    const [filters1, setFilters1] = useState(null);
    const [productDialog, setProductDialog] = useState(false);

    const [FileDialog, setFileDialog] = useState(false);
    const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);

    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const [baseUri, setbaseUri] = useState(window.$baseUri);
    const [fileUrl, setFileUrl] = useState(baseUri+'souscription-personnel/ouvrir-fichier/');
    const [file, setfile] = useState('');



    const initFilters1 = () => {
        setFilters2({
            'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
            'prenomEleve':{ value: null, matchMode: FilterMatchMode.STARTS_WITH },
            'inscriptions_status':{ value: null, matchMode: FilterMatchMode.STARTS_WITH },
            'inscriptionsidEleve':{ value: null, matchMode: FilterMatchMode.STARTS_WITH },
            'nomEleve':  { value: null, matchMode: FilterMatchMode.STARTS_WITH },
           'matriculeEleve': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            'inscriptions_type': { value: null, matchMode: FilterMatchMode.EQUALS  }
        });
        setGlobalFilterValue2('');
    }
    const onGlobalFilterChange2 = (e) => {
        const value = e.target.value;
        let _filters2 = { ...filters2 };
        _filters2['global'].value = value;

        setFilters2(_filters2);
        setGlobalFilterValue2(value);
    }
    useEffect(() => {
        history.push("/home/professionnelle");
        console.log("/home/professionnelle")
    }, []);

    useEffect(() => {
        const  domainServ= new SouscriptionService() ;

        domainServ.listPays().then(data => setpayss(data));
        domainServ.listNiveauEnseignement().then(data => setNiveauEnseignements(data));
        domainServ.getFondateurId(fonctionFon).then(data => setFonction(data));
        const souscrService = new SouscriptionService();
        souscrService.listSouscriptionEcolesAvalider(statut)
            .then(data => {
                console.log(data)
                setEtablis(data)
            });

    }, [idModif]);



    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
    }

    const hideFileDialog = () => {
         setFileDialog(false);
    }

    const openFile = (rowData) => {
        return (
            <>
                <FileDemo/>
            </>
        );
    }


    const openFile1 = (fileName) => {
    if(!fileName || fileName.length === 0) {
        toast.current.show({severity:'info', summary: 'Vie-ecoles', detail:'Pas de fichier disponible', life: 3000});
    } else {
      /*  const souscrService = new SouscriptionService();
        souscrService.ouvrirFichier(fileName);*/
        setfile(fileUrl+fileName) ;
        setExtentfileName( fileName.split(".").pop()) ;

       setFileDialog(true);

    }

    }




    const saveProduct = () => {
        setIdmodif(0);
        setSubmitted(true);

      const souscrService = new SouscriptionService();
        if(etatInscription.etat_libelle.trim()==="VALIDEE" ){
            souscVali.messageRefus= motifRefu ;
            souscVali.statuts = etatInscription.etat_libelle ;
            souscVali.idsouscrip = etabli.sousc_atten_etablissid ;
            souscrService.validerSouscriptionEcole(souscVali)
                .then(data => {
                 console.log(data)
                setIdmodif(1)
              });
           // sendEmail();
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Souscription traitée ', life: 3000 });
            setProductDialog(false);
            setetatInscription(emptyEtatInscription);
            setsouscVali(souscriptionValidaEmpty)
            setmotifRefu('') ;
         }  else if(etatInscription.etat_libelle==="REFUSEE" && motifRefu.trim())  {
            souscVali.messageRefus= motifRefu ;
            souscVali.statuts = etatInscription.etat_libelle ;
            souscVali.idsouscrip = etabli.sousc_atten_etablissid ;
            souscrService.validerSouscriptionEcole(souscVali)
                .then(data => {
                    console.log(data)
                    setIdmodif(1)
                });
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Souscription traitée ', life: 3000 });
            setProductDialog(false);
            setetatInscription(emptyEtatInscription);
            setsouscVali(souscriptionValidaEmpty)
            setmotifRefu('') ;
        }

        else if(etatInscription.etat_libelle==="REFUSEE" && motifRefu.trim()==="")  {
            toast.current.show({ severity: 'error', summary: 'Error Message', detail: 'Veuillez renseigner le motif ', life: 3000 });
        }
        else  {
          toast.current.show({ severity: 'error', summary: 'Error Message', detail: 'Veuillez traiter la demande ou annuler', life: 3000 });
            //setProductDialog(false);
        }


    }





    const EtatInscriptions = [
        {etat_code: 'REFUSEE', etat_libelle: 'REFUSEE'},
        {etat_code: 'VALIDEE', etat_libelle: 'VALIDEE'}

    ];



    const libelleBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Nom</span>
                {rowData.sousc_atten_etabliss_nom}
            </>
        );
    }


    const zoneBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Zone</span>

                { rowData.zone_zone}
            </>
        );
    }

    const niveanEnseignBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Niveau Enseignement</span>
                {rowData.niveau_Enseignement_obj}
            </>
        );
    }
    const autoriEnseignBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Fichier (1)</span>
                {rowData.sousc_atten_etabliss_lien_autorisa}
            </>
        );
    }

    const logoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Fichier (2)</span>
                {rowData.sousc_atten_etabliss_lien_logo}
            </>
        );
    }

    const villeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Ville</span>
                {rowData.ville_ville}
            </>
        );
    }
    const communeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">commune</span>
                {rowData.commune_commune}
            </>
        );
    }
    const telephoneBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Téléphone</span>
                {rowData.sousc_atten_etabliss_tel}
            </>
        );
    }

    const emailBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Email</span>
                {rowData.sousc_atten_etabliss_email}
            </>
        );
    }
    const indicationBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Email</span>
                {rowData.sousc_atten_etabliss_indication}
            </>
        );
    }

    const codeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Code</span>
                {rowData.sousc_atten_etablisscode}
            </>
        );
    }

    const idBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">N°</span>
                {rowData.sousc_atten_etablissid}
            </>
        );
    }


    const editProduct = (souscrip) => {
        setEtabli({ ...souscrip });

        setProductDialog(true);
    }
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editProduct(rowData)} />
               </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Liste des élèves</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Rechercher..." />
            </span>
        </div>
    );
    const typeItemTemplate = (option) => {
        return <span className={`customer-badge status-${option}`}>{option}</span>;
    }

    const statusItemTemplate = (option) => {
        return <span className={`customer-badge status-${option}`}>{option}</span>;
    }

    const productDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
        </>
    );



    const renderHeader2 = () => {
        return (
            <div className="flex justify-content-end">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue2} onChange={onGlobalFilterChange2} placeholder="Mots clés..." />
                </span>
            </div>
        )
    }
    const header2 = renderHeader2();
    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />


                    <div className="card">
                        <h5>Liste des ecoles </h5>
                        <p>Liste des ecoles en attentes de validation </p>
                        <DataTable ref={dt} value={etablis} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                                   dataKey="sousc_atten_etablisscode" paginator size="small" rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                                   paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                   currentPageReportTemplate="Showing {first} to {last} of {totalRecords} école"
                                   filters={filters2} filterDisplay="row"
                                   globalFilterFields={['sousc_atten_etablissid','sousc_atten_etablisscode', 'sousc_atten_etabliss_nom','sousc_atten_etabliss_tel','sousc_atten_etabliss_email', 'sousc_atten_etabliss_indication',
                                       'sousc_atten_etabliss_indication','ville_ville','commune_commune','Niveau_Enseignement_id','commune_commune','zone_zone','niveau_Enseignement_obj',
                                       'sousc_atten_etabliss_lien_autorisa','sousc_atten_etabliss_lien_logo']}
                                   emptyMessage="Aucune école enregistrée." header={header2} responsiveLayout="scroll">
                            <Column selectionMode="multiple" headerStyle={{ width: '3rem'}}></Column>
                            <Column field="sousc_atten_etablissid" header="N°" sortable body={idBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>

                            <Column field="sousc_atten_etablisscode" header="Code" sortable body={codeBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>

                            <Column field="sousc_atten_etabliss_nom" header="Nom" sortable body={libelleBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>

                            <Column field="sousc_atten_etabliss_tel" header="Téléphone" sortable body={telephoneBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>

                            <Column field="sousc_atten_etabliss_email" header="Email" sortable body={emailBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>

                            <Column field="sousc_atten_etabliss_indication" header="Indication" sortable body={indicationBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>

                            <Column field="ville_ville" header="Ville" sortable body={villeBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>

                            <Column field="commune_commune" header="Commune" sortable body={communeBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>

                            <Column field="zone_zone" header="Zone" sortable body={ etabli.zone_zone  == null ? '' :  zoneBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>

                            <Column field="niveau_Enseignement_obj" header="Niveau d'enseignement" sortable body={niveanEnseignBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column field="sousc_atten_etabliss_lien_autorisa" header="Fichier(2)" body={autoriEnseignBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>

                            <Column field="sousc_atten_etabliss_lien_logo" header="Fichier(1)"  body={logoBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>

                            <Column body={actionBodyTemplate}></Column>
                        </DataTable>
                    </div>

                    <Dialog visible={productDialog} style={{ width: '650px' }} header="Details souscripteur" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>

                        <form ref={form} onSubmit={saveProduct}>
                             <div className="formgrid grid">
                                    <div className="field col">
                                        <label htmlFor="name1">Ecole</label>
                                        <InputText value={etabli.sousc_atten_etabliss_nom } disabled />
                                    </div>

                                    <div className="field col">
                                        <label htmlFor="name1">Code école</label>
                                        <InputText value={etabli.sousc_atten_etablisscode } disabled />
                                    </div>


                              </div>

                            <div className="formgrid grid">
                                <div className="field col">
                                    <label htmlFor="name1">Nom fondateur </label>
                                    <InputText value={etabli.nomFondateur } disabled />
                                </div>

                                <div className="field col">
                                    <label htmlFor="name1">Prénom Fondateur</label>
                                    <InputText value={etabli.prenomFondateur } disabled />
                                </div>
                            </div>

                            <div className="formgrid grid">
                            <div className="field col">
                                <label htmlFor="name1">Indication</label>
                                <InputText value={etabli.sousc_atten_etabliss_indication } disabled />
                            </div>
                            </div>
                        <div className="formgrid grid">
                        <div className="field col">
                            <Dropdown value={etatInscription} onChange={(e) => setetatInscription(e.value)} options={EtatInscriptions} optionLabel="etat_libelle" placeholder="Valider l'inscription" />
                        </div>
                        </div>

                        <div className="formgrid grid">
                            <div className="field col">
                                <InputTextarea value={motifRefu}  onChange={(e) => setmotifRefu(e.target.value)} rows={5} cols={30} autoResize />
                            </div>
                        </div>


                        </form>
                        <div className="flex align-items-center export-buttons">
                            <Tooltip target=".export-buttons>button" position="bottom" />
                            <Button type="button" data-pr-tooltip="Fichier1" icon="pi pi-file" className="mr-2" onClick={() => openFile1(etabli.sousc_atten_etabliss_lien_autorisa)}  />
                            <Button type="button" data-pr-tooltip="Fichier2" icon="pi pi-file" className="mr-2" onClick={() => openFile1(etabli.sousc_atten_etabliss_lien_logo)}  />

                        </div>
                    </Dialog>

                    <Dialog visible={FileDialog} style={{ width: '750px', height:'800px'}}  modal className="p-fluid"  onHide={hideFileDialog}>
                        <FileViewer
                            fileType={fileExten}

                            filePath={file}
                        />
                    </Dialog>



                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

//export default React.memo(ValiderEcole, comparisonFn);

export default ValiderEcole ;
