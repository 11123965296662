import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Toolbar } from 'primereact/toolbar';

import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import {EleveService} from '../../service/operations/EleveService';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';

import {Dropdown} from "primereact/dropdown";
import {SouscriptionService} from "../../service/operations/SouscriptionService";
import EmptyPage from "../../pages/EmptyPage";
import {Route} from "react-router-dom";
import { Tooltip } from 'primereact/tooltip';
import { InputTextarea } from 'primereact/inputtextarea';
import emailjs from '@emailjs/browser';
import { Password } from 'primereact/password';
import {ConnexionService} from "../../service/operations/ConnexionService";


const ModifierMotDepassPersonnel = () => {
    const form = useRef();

    let emptyDomaineFormation = {
        domaine_formationid :null ,
        domaine_formation_code :'',
        domaine_formation_libelle:''
    };

    let sendEmailEmpty = {
        subject :'Bonjour SOUM',
        user_name :'SOUMSMITH1',
        user_email:'soumsmith1@gmail.com',
        message:'Juste un text'
    };

    let emptySexe = {
        sexe_code :'',
        sexe_libelle :''
    };
    let emptyNiveauEtude = {
        niveau_etudeid :null ,
        niveau_etude_code :'',
        niveau_etude_libelle:''
    };
    let emptyAnneeExperience = {
        nbreAnnee_code :0 ,
        nbreAnnee_libelle :''
    };
    let emptyEtatInscription={
        etat_code:'',
        etat_libelle:''
    } ;
    let souscriptionValidaEmpty= {
        idsouscrip:null ,
        statuts: '',
        messageRefus:''
    } ;
    let emptyfonction = {
        fonctionid :null ,
        fonctioncode :'',
        fonctionlibelle:''
    } ;

    let emptyModifierPass = {
        login :'' ,
        motdePasse :'',
        confirmMotPass:''
    } ;
    const [domaineFormation, setDomaineFormation] = useState(emptyDomaineFormation);
    const [mfonction, setmfonction] = useState(emptyfonction);
    const [niveauEtude, setNiveauEtude] = useState(emptyNiveauEtude);
    const defaultValues2 = {
        sous_attent_personnid: null,
        sous_attent_personn_nom: '',
        sous_attent_personn_prenom: '',
        sous_attent_personn_email: '',
        sous_attent_personn_sexe: '',
        sous_attent_personn_diplome_recent:'',
        sous_attent_personn_date_naissance: null,
        sous_attent_personn_nbre_annee_experience:null,
        sous_attent_personn_lien_cv:'',
        niveau_etude:niveauEtude,
        sous_attent_personncode:'',
        domaine_formation:domaineFormation,
        fonction:mfonction,
        sous_attent_personn_login:'',
        sous_attent_personn_password:'',
        sous_attent_personn_donnee: null,
        sous_attent_personn_contact :'',
        sous_attent_personn_lien_piece:'',
        sous_attent_personn_lien_autorisation :''
    }

    const columns = [
        {field: 'inscriptions_status', header: 'Statut Inscription'},
        {field: 'inscriptionsidEleve', header: 'N°'},
        {field: 'nomEleve', header: 'Nom'},
        {field: 'prenomEleve', header: 'Prenom'},
        {field: 'matriculeEleve', header: 'Matricule'},
        {field: 'inscriptions_type', header: 'Type Inscription'}
    ];
    const [selectedColumns, setSelectedColumns] = useState(columns);

    const [filters2, setFilters2] = useState({
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'sous_attent_personnid':{ value: null, matchMode: FilterMatchMode.STARTS_WITH },
        'sous_attent_personn_nom':{ value: null, matchMode: FilterMatchMode.CONTAINS },
        'sous_attent_personn_prenom':{ value: null, matchMode: FilterMatchMode.CONTAINS },
        'sous_attent_personn_nbre_annee_experience':  { value: null, matchMode: FilterMatchMode.CONTAINS } ,
        'domaine_formation.domaine_formation_libelle':  { value: null, matchMode: FilterMatchMode.CONTAINS } ,
        'sous_attent_personn_email':  { value: null, matchMode: FilterMatchMode.CONTAINS } ,
        'fonction.fonctionlibelle':  { value: null, matchMode: FilterMatchMode.CONTAINS }
    });
    const [emailObject, setEmailObject] = useState(sendEmailEmpty);
    const [userName, setUserName] = useState('');
    const [motPassDto, setMotPassDto] = useState(emptyModifierPass);
    const [userEmail, setUserEmail] = useState('');
    const [userMessage, setUserMessage] = useState('');
    const [idModif, setIdmodif] = useState(null);
    const [statut, setstatut] = useState("VALIDEE");
    const [souscVali, setsouscVali] = useState(souscriptionValidaEmpty);
    const [sexe, setSexe] = useState(emptySexe);
    const [retourConnexion, setRetourConnexion] = useState('');
    const [monEmail, setMonEmail] = useState(sessionStorage.getItem('emailCandidat'));
    const [ancienPassWord, setAncienPassWord] = useState('');
    const [confirmPassWord, setConfirmPassWord] = useState('');
    const [retourApi, setretourApi]  = useState(null);
    
    const [domaineFormations, setDomaineFormations] = useState([]);
    const [motifRefu, setmotifRefu] = useState('');
    const [souscriptionAttente, setsouscriptionAttente] = useState((defaultValues2));
    const [souscriptionAttentes, setsouscriptionAttentes] = useState([]);

    

    const [tenant, settenant]  = useState(sessionStorage.getItem('EcoleID'));
    const [etatInscription, setetatInscription]  = useState(emptyEtatInscription);
    const [eleves, setEleves]  = useState(null);
    const [filters1, setFilters1] = useState(null);
    const [productDialog, setProductDialog] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [FileDialog, setFileDialog] = useState(false);
    const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
    const [modif, setModif]  = useState(null);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);

    useEffect(()=>{
        if(modif===1){
            setShowMessage(true);
        }
    },[modif])


    useEffect(() => {
        const souscrService = new SouscriptionService();
          souscrService.listSouscriptionAvalider(statut)
            .then(data => {
            console.log(data)
            setsouscriptionAttentes(data)

        });

    }, [idModif]);

    const formatCurrency = (value) => {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }


    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
    }

    const hideFileDialog = () => {
         setFileDialog(false);
    }

    const hideDeleteProductDialog = () => {
        setDeleteProductDialog(false);
    }

    const hideDeleteProductsDialog = () => {
        setDeleteProductsDialog(false);
    }

    const openFile1 = (fileName) => {
    if(fileName.trim()==="") {
        toast.current.show({severity:'info', summary: 'Vie-ecoles', detail:'Pas de fichier disponible', life: 3000});
    } else {
        const souscrService = new SouscriptionService();
        souscrService.ouvrirFichier(fileName);
    }

    }



    const viewFiles = () => {
        setFileDialog(true);
    }

    const EtatInscriptions = [
        {etat_code: '-------', etat_libelle: '------'},
        {etat_code: 'VALIDEE', etat_libelle: 'VALIDEE'}

    ];
    const Sexe = [
        {sexe_code: 'MASCULIN', sexe_libelle: 'MASCULIN'},
        {sexe_code: 'FEMININ', sexe_libelle: 'FEMININ'}

    ];

    const Statuts = [
        {statut_code: 'AFFECTE', statut_libelle: 'AFFECTE'},
        {statut_code: 'NON_AFFECTE', statut_libelle: 'NON AFFECTE'}
    ];

    const InscriptionTypeOperation2 = [
        'PREINSCRIPTION', 'INSCRIPTION'
    ];

    const InscriptionStatut = [
        'EN ATTENTE', 'VALIDEE'
    ];

    const InscriptionTypeOperation = [
        {inscripStatuCode: 'INSCRIPTION', inscripStatulibelle: 'INSCRIPTION'},
        {inscripStatuCode: 'PREINSCRIPTION', inscripStatulibelle: 'PREINSCRIPTION'}
    ];


    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Nouveau" icon="pi pi-plus" className="p-button-success mr-2" onClick={viewFiles} />
                </div>
            </React.Fragment>
        )
    }

    const IdsouscriptionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">N°</span>
                {rowData.sous_attent_personnid}
            </>
        );
    }


    const NomBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">nom</span>
                {rowData.sous_attent_personn_nom}
            </>
        );
    }

    const PreNomBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Prénom</span>
                {rowData.sous_attent_personn_prenom}
            </>
        );
    }

    const emailBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Email</span>
                {rowData.sous_attent_personn_email}
            </>
        );
    }


    const sexeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Email</span>
                {rowData.sous_attent_personn_sexe}
            </>
        );
    }
    const domaineFormationBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Domaine de formation</span>
                {rowData.domaine_formation.domaine_formation_libelle}
            </>
        );
    }
    const diplomeRecentBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Diplôme récent</span>
                {rowData.sous_attent_personn_diplome_recent}
            </>
        );
    }

    const statutEleveBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Statut</span>
                {rowData.inscriptions_statut_eleve}
            </>
        );
    }
    const nomBreAnneeExperienceBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Nombre année d'experienxe</span>
                {rowData.sous_attent_personn_nbre_annee_experience}
            </>
        );
    }

    const fonctionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Fonction</span>
                {rowData.fonction.fonctionlibelle}
            </>
        );
    }

    const statutInscriptionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Etat</span>
                {rowData.inscriptions_status}
            </>
        );
    }

    const dateNaissanceBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Date naissance</span>
                {rowData.Date_naissanceEleve}
            </>
        );
    }





    const contactBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Cellulaire</span>
                {rowData.contactEleve}
            </>
        );
    }

    const statutInscripBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Cellulaire</span>
                {rowData.inscriptions_status}
            </>
        )
    }


    const typeInscriptionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Cellulaire</span>
                {rowData.inscriptions_type}
            </>
        )
    }
    const editProduct = (souscrip) => {
        setsouscriptionAttente({ ...souscrip });
        setUserName('GAIN SARL') ;
        setUserEmail('soumsmith1@gmail.com') ;
        setUserMessage('Bonjour Monsieur '+souscrip.sous_attent_personn_nom+' '+souscrip.sous_attent_personn_prenom +'\n'+
            'Veuillez utiliser ces paramètres pour vous connecter à notre application'+'\n');
        setProductDialog(true);
    }
    const dialogFooter = <div className="flex justify-content-center"><Button label="OK" className="p-button-text" autoFocus onClick={() => setShowMessage(false)} /></div>;

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editProduct(rowData)} />
               </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Liste des élèves</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Rechercher..." />
            </span>
        </div>
    );
    const typeItemTemplate = (option) => {
        return <span className={`customer-badge status-${option}`}>{option}</span>;
    }

    const statusItemTemplate = (option) => {
        return <span className={`customer-badge status-${option}`}>{option}</span>;
    }


    useEffect(() => {
        if(retourConnexion.trim()) {
            if(retourConnexion.trim()&&retourConnexion==='mot de passe modifié!'&&motPassDto.motdePasse.trim()&&motPassDto.confirmMotPass.trim()){
                setShowMessage(true);
            } else {
                toast.current.show({severity:'error', summary: 'pouls-scolaire: Messages d"erreur', detail:'Les paramètres de connexion sont incorrects', life: 3000});
            }
        }

    }, [retourConnexion]);



    const confirmUpdate = () => {
        const  connexServ= new ConnexionService() ;
        motPassDto.login= monEmail ;
        motPassDto.motdePasse= ancienPassWord ;
        motPassDto.confirmMotPass = confirmPassWord ;

        if(ancienPassWord.trim()&&confirmPassWord.trim()){
            connexServ.modifierMotDepasse(motPassDto)
                .then(data => {
                    console.log(data)
                    setRetourConnexion(data)
                });

        } else {
            toast.current.show({severity:'error', summary: 'pouls-scolaire: Messages d"erreur', detail:'Veuillez renseigner les différents champs svp', life: 3000});

        }

    }
    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />

                    <div className="col-12">
                        <div className="card">
                            <h5>Modifier mon mot de passe</h5>
                            <div className="field grid">
                                <label htmlFor="login" className="col-12 mb-2 md:col-2 md:mb-0">Login:</label>
                                <div className="col-12 md:col-10">
                                    <InputText id="login" value={monEmail} onChange={(e) => setMonEmail(e.target.value)}  type="text" disabled/>
                                </div>
                            </div>
                            <div className="field grid">
                                <label htmlFor="ancienPassWord" className="col-12 mb-2 md:col-2 md:mb-0">Ancien mot de passe:</label>
                                <div className="col-12 md:col-10">
                                    <Password id="ancienPassWord" type="text" value={ancienPassWord} onChange={(e) => setAncienPassWord(e.target.value)}/>
                                </div>
                            </div>
                            <div className="field grid">
                                <label htmlFor="confirmPassWord" className="col-12 mb-2 md:col-2 md:mb-0">Nouveau du mot de passe:</label>
                                <div className="col-12 md:col-10">
                                    <Password id="confirmPassWord" type="text" value={confirmPassWord} onChange={(e) => setConfirmPassWord(e.target.value)}/>
                                </div>
                            </div>

                            <Button icon="pi pi-save" label="Enregistrer" onClick={confirmUpdate}  />
                        </div>
                    </div>

                    <Dialog visible={showMessage} onHide={() => setShowMessage(false)} position="top" footer={dialogFooter} showHeader={false} breakpoints={{ '960px': '80vw' }} style={{ width: '30vw' }}>
                        <div className="flex justify-content-center flex-column pt-6 px-3">
                            <i className="pi pi-check-circle" style={{ fontSize: '5rem', color: 'var(--green-500)' }}></i>
                            <h5>{"Modification du mot de passe"}</h5>
                            <p style={{ lineHeight: 1.5, textIndent: '1rem' }}>
                               {retourConnexion}
                            </p>
                        </div>
                    </Dialog>






                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(ModifierMotDepassPersonnel, comparisonFn);
