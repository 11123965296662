// Footer.js

import React from 'react';

const usefulLinks = [
  { id: 1, title: 'À propos ', url: 'about.html' },
  { id: 2, title: 'Contact', url: 'contact.html' },
  { id: 3, title: 'Centre d\'Aide', url: 'contact.html' },
  { id: 4, title: 'Remboursement', url: 'about.html' },
  { id: 5, title: 'Conditions', url: 'about.html' },
  { id: 6, title: 'Politique de Confidentialité', url: 'about.html' }
];

const categories = [
  { id: 1, title: 'Compétences en Design', url: '#' },
  { id: 2, title: 'Développement', url: '#' },
  { id: 3, title: 'Marketing', url: '#' },
  { id: 4, title: 'Finance & Comptabilité', url: '#' },
  { id: 5, title: 'Informatique & Logiciels', url: '#' },
  { id: 6, title: 'Marketing de Ventes', url: '#' }
];

const Footer = () => {
  return (
    <>
    
    <div className="cta-area">
      <div className="container">
        <div className="cta-wrapper">
          <div className="row align-items-center">
            <div className="col-xl-6 col-lg-6">
              <div className="cta-content mb-30 mb-lg-0">
                <span className="cta-subtitle">Télécharger l'Application</span>
                <h2 className="cta-title">
                  Êtes-vous prêt à commencer votre cours en ligne ?
                </h2>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6">
              <div className="cta-button">
                <a href="#" className="cta-btn">
                  <i className="fa-brands fa-apple" />
                  App Store
                </a>
                <a href="#" className="cta-btn">
                  <i className="fa-brands fa-google-play" />
                  Play Store
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <footer className="footer-area">
      <div className="footer-top pt-240 pb-55">
        <div className="container">
          <div className="row justify-content-between">
            {/* Section pour les liens utiles */}
            <div className="col-xl-3 col-lg-7 col-md-7 col-sm-12">
              <div className="footer-widget mb-40">
                <div className="footer-logo">
                  <a href="index.html"><img src="assets/layout/images/logo-gain.png" alt="" width={100} /></a>
                </div>
                <p className="footer-widget-text mb-35">
                  Grâce à une combinaison de cours, de lectures et de discussions, les étudiants acquerront une base solide en éducation.
                </p>
                <div className="footer-social">
                  <ul>
                    <li><a href="#"><i className="fa-brands fa-twitter"></i></a></li>
                    <li><a href="#"><i className="fa-brands fa-facebook-f"></i></a></li>
                    <li><a href="#"><i className="fa-brands fa-linkedin-in"></i></a></li>
                  </ul>
                </div>
              </div>
            </div>
            
            {/* Section pour les liens utiles */}
            <div className="col-xl-2 col-lg-5 col-md-5 col-sm-6 d-flex justify-content-xl-center">
              <div className="footer-widget mb-40">
                <h5 className="footer-widget-title">Liens Utiles</h5>
                <div className="footer-widget-list">
                  <ul>
                    {usefulLinks.map(link => (
                      <li key={link.id}><a href={link.url}>{link.title}</a></li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            
            {/* Section pour les catégories */}
            <div className="col-xl-2 col-lg-5 col-md-5 col-sm-6 d-flex justify-content-xl-center order-md-4 order-xl-3">
              <div className="footer-widget mb-40">
                <h5 className="footer-widget-title">Catégories</h5>
                <div className="footer-widget-list">
                  <ul>
                    {categories.map(category => (
                      <li key={category.id}><a href={category.url}>{category.title}</a></li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            
            {/* Section pour la newsletter */}
            <div className="col-xl-4 col-lg-7 col-md-7 col-sm-12 order-md-3 order-xl-4">
              <div className="footer-widget ml-80 mb-40">
                <h5 className="footer-widget-title">Newsletter</h5>
                <p className="footer-widget-text mb-20 newsletter-text">Inscrivez-vous à notre newsletter et obtenez 34% <br /> de réduction sur votre prochain cours.</p>
                <form action="#">
                  <div className="footer-subscribe-form">
                    <input type="email" placeholder="Entrez votre email*" />
                    <button type="submit">S'abonner</button>
                  </div>
                  <div className="footer-subscribe-condition">
                    <label className="condition_label">J'accepte les conditions d'utilisation et la politique de confidentialité.
                      <input type="checkbox" />
                      <span className="check_mark"></span>
                    </label>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {/* Section pour le texte de copyright */}
      {/* <div className="copyright-area">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="copyright-text">
                <p>Droit d'auteur © 2023 Tous droits réservés par Eduan</p>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </footer>
</>
    
  );
}

export default Footer;
