import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Image } from 'primereact/image';
import { Button } from 'primereact/button';
import { RadioButton } from "primereact/radiobutton";
import { FileUpload } from 'primereact/fileupload';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Toolbar } from 'primereact/toolbar';
import { TabView, TabPanel } from 'primereact/tabview';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import {EleveService} from '../../service/operations/EleveService';
import { Checkbox } from "primereact/checkbox";


import { InputSwitch } from 'primereact/inputswitch';


import {Dropdown} from "primereact/dropdown";
import {Message} from "primereact/message";
import {RapportService} from "../../service/rapports/RapportService";
import {ProgressSpinner} from "primereact/progressspinner";
import axios from "axios";
import {Calendar} from "primereact/calendar";
import moment from "moment/moment";


const InscriptionAvalider =  () => {
    let emptyInscrAvalider = {
        idEleveInscrit: null,
        inscriptionsidEleve: null,
        nomEleve: '',
        prenomEleve: '',
        matriculeEleve: '',
        identifiantBranche: null,
        date_naissanceEleve: null,
        sexeEleve: '',
        contactEleve: null,
        inscriptions_statut_eleve: null,
        inscriptions_status: 'EN_ATTENTE',
        inscriptions_processus: 'EN_COURS',
        inscriptions_type: null,
        inscriptions_ecole_origine: '',
        inscriptions_classe_precedente: '',
        inscriptions_derniereclasse_religieuse: '',
        inscriptions_classe_actuelle: '',
        inscriptions_redoublant: '',
        inscriptions_langue_vivante: '',
        inscriptions_contact2: '',
        inscriptions_boursier: '',
        inscriptionsid: null,
        num_decision_affecte: '',
        nom_prenoms_pere: '',
        nom_prenoms_mere: '',
        nom_prenoms_tuteur: '',
        commune: '',
        handicap: false,
        moteur: false,
        visuel: false,
        auditif: false,
        reaffecte: false,
        regularisation: false,
        reintegration: false,
        prise_en_charge: false,
        transfert: false,
        origine_prise_en_charge: '',
        profession_pere: '',
        boite_postal_pere: '',
        tel1_pere: '',
        tel2_pere: '',
        profession_mere: '',
        boite_postal_mere: '',
        tel1_mere: '',
        tel2_mere: '',
        profession_tuteur: '',
        boite_postal_tuteur: '',
        tel1_tuteur: '',
        tel2_tuteur: '',
        profession_pers_en_charge: '',
        boite_postale_pers_en_charge: '',
        tel1_pers_en_charge: '',
        tel2_pers_en_charge: '',
        autre_handicap: '',
        nom_prenom_pers_en_charge: '',
        classe_arabe: '',
        ecole_origine: '',
        num_decision_affectation: '',
        internes: false,
        demi_pension: false,
        externes: false,
        ivoirien: false,
        etranger_africain: false,
        etranger_non_africain: false,
        cheminphoto:'',
        nationalite:'',
        lieu_naissance:''


    };
    let emptyeleve = {
        eleve_mail: '',
        eleve_numero_extrait_naiss: 0,
        eleveadresse: '',
        elevecellulaire: '',
        elevecode: '',
        elevedate_etabli_extrait_naiss: null,
        elevedate_naissance: null,
        eleveid: null,
        idTenant: '',
        eleveSexe: '',
        elevelieu_etabliss_etrait_naissance: '',
        elevelieu_naissance: '',
        elevenom: '',
        eleveprenom: '',
        elevematricule_national: '',
        parentList: []
    };

    let emptyInscriptions = {
        inscriptionsid: null,
        inscriptionscode: '',
        inscriptions_ecole_origine: '',
        inscriptions_classe_precedente: '',
        inscriptions_derniereclasse_religieuse: '',
        inscriptions_classe_actuelle: '',
        inscriptions_delete: false,
        inscriptions_status: '',
        inscriptions_redoublant: '',
        inscriptions_boursier: '',
        inscriptions_statut_eleve: '',
        inscriptions_type: '',
        inscriptionsdate_creation: null,
        inscriptionsdate_modification: null,
        identifiantEleve: null,
        identifiantEcole: 0,
        identifiantAnnee_scolaire: 0,

        identifiantNiveau: '',
        inscriptionBadge: '',
        tenant_tenantid: '',
        inscriptionLangueVivante: '',
        libellehandicap: []
    };


    let emptyEtatInscription = {
        etat_code: '',
        etat_libelle: ''
    }
    let empty = {
        id: null,
        code: '',
        libelle: ''
    };


    let emptystatutEleve = {
        statut_code: '',
        statut_libelle: ''
    }
    let emptyAnneeScoliare = {
        annee_scolaireid: 1,
        annee_scolaire_libelle: '',
        annee_scolaire_code: '',
        annee_scolaire_visible: '',
        tenant_tenantid: ''
    };

    let emptyStatutEleve = {
        statuts_eleveid: null,
        statuts_elevecode: '',
        statuts_elevelibelle: '',
        tenant_tenantid: ''
    };

    let emptyNiveau = {
        niveauid: null,
        niveaucode: '',
        niveaulibelle: '',
        tenant_tenantid: ''
    };
    let emptyNiveauEnsein = {
        id: null,
        code: '',
        libelle: ''
    };
    let emptyInfos = {
        id: '',
        libelle: ''
    };

    let emptyInfos3 = {
        id: '',
        libelle: ''
    };

    let emptyInfos4 = {
        id: '',
        libelle: ''
    };
    let emptyInfos2 = {
        id: '',
        libelle: ''
    };
    let emtySexe = {
        sexe_code: '',
        sexe_libelle: ''
    };
    let emptyTransfert = {
        code: 'N',
        libelle: 'NON'
    };
    const columns = [
        {field: 'inscriptions_status', header: 'Statut Inscription'},
        {field: 'inscriptionsidEleve', header: 'N°'},
        {field: 'nomEleve', header: 'Nom'},
        {field: 'prenomEleve', header: 'Prenom'},
        {field: 'matriculeEleve', header: 'Matricule'},
        {field: 'inscriptions_type', header: 'Type Inscription'}
    ];
    const [selectedColumns, setSelectedColumns] = useState(columns);

    const [filters2, setFilters2] = useState({
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'prenomEleve': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'inscriptions_status': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'inscriptionsidEleve': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'nomEleve': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'matriculeEleve': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'inscriptions_statut_eleve': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'sexe': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'brancheLibelle': {value: null, matchMode: FilterMatchMode.CONTAINS}
    });

    const [selectedFile, setSelectedFile] = useState(null);
    const [byteArray, setByteArray] = useState(null);
    const [infosMiseAjour, setInfosMiseAjour] = useState('');
    const [numDecisionAff, setNumDecisionAff] = useState('');
    const [ecole_origine, setEcole_origine] = useState('');
    const [derniereclasse_religieuse, setDerniereclasse_religieuse] = useState(emptyNiveau);
    const [classe_precedente, setClasse_precedente] = useState(emptyNiveau);
    const [classe_actuelle, setClasse_actuelle] = useState(emptyNiveau);
    const [redoublant, setRedoublant] = useState(emptyInfos);
    const [boursier, setBoursier] = useState(emptyInfos3);
    const [langue_vivante, setLangue_vivante] = useState(emptyInfos2);
    const [contact2, setContact2] = useState('');
    const [checked, setChecked] = useState(null);
    const [idModif, setIdmodif] = useState(null);
    const [inscriAvalide, setainscriAvalide] = useState(emptyInscrAvalider);
    const [inscriAvalides, setinscriAvalides] = useState(null);
    const [anneeScolaires, setanneeScolaires] = useState(null);
    const [anneeScolaire, setanneeScolaire] = useState(emptyAnneeScoliare);
    const [niveaus, setniveaus] = useState(null);
    const [niveau, setniveau] = useState(emptyNiveau);
    const [branches, setBranches] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);
    const [branche, setBranche] = useState(emptyInfos4);
    const [etatInscription, setEtatInscription] = useState(emptyEtatInscription);
    const [statutEleve, setstatutEleve] = useState(emptystatutEleve);
    const [date_naissance, setdate_naissance] = useState('05/11/1990');
    const [globalFilterValue2, setGlobalFilterValue2] = useState('');
    const [typeOperation, setTypeOperation] = useState(null);
    const [anneeEncours, setAnneeEncours] = useState(sessionStorage.getItem('AnneEncours'));
    const [typeOperation1, settypeOperation1] = useState("INSCRIPTION");
    const [typeOperation2, settypeOperation2] = useState("PREINSCRIPTION");
    const [tenant, settenant] = useState(sessionStorage.getItem('EcoleID'));
    const [liseleveInscrits, setLiseleveInscrits] = useState(null);
    const [isNotSpinning, setIsNotSpinning] = useState('hidden');
    const [eleves, setEleves] = useState(null);
    const [filters1, setFilters1] = useState(null);
    const [productDialog, setProductDialog] = useState(false);
    const [FicheDialog, setFicheDialog] = useState(false);
    const [baseUri, setbaseUri] = useState(window.$baseUri);
    const [annees, setannees] = useState(null);
    const [annee, setannee] = useState(empty);
    const [isNotSpinning1, setIsNotSpinning1] = useState('hidden');
    const [isNotSpinning2, setIsNotSpinning2] = useState('hidden');
    const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    const [deletePhotoDialog, setDeletePhotoDialog] = useState(false);
    const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
    const [eleve, setEleve] = useState(emptyeleve);
    const [eleveCreate, setEleveCreate] = useState(emptyeleve);
    const [inscription, setInscription] = useState(emptyInscriptions);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const [files, setFiles] = useState([]);
    const [file1, setFile1] = useState('');
    const [niveauEnseignement, setNiveauEnseignement] = useState(emptyNiveauEnsein);
    const [niveauEnseignements, setNiveauEnseignements] = useState(null);
    const [selectedNiveau, setSelectedNiveau] = useState(null);
    const [selectedStatut, setSelectedStatut] = useState(null);
    const [selectedSexe, setSelectedSexe] = useState(emtySexe);
    const [nom_prenoms_pere, setnom_prenoms_pere] = useState(null);
    const [nom_prenoms_mere, setnom_prenoms_mere] = useState('');
    const [selectedInscrStatut, setSelectedInscrStatut] = useState('');
    const [nom_prenoms_tuteur, setnom_prenoms_tuteur] = useState('');
    const [commune, setcommune] = useState('');
    const [handicap, sethandicap] = useState(false);
    const [moteur, setmoteur] = useState(false);
    const [visuel, setvisuel] = useState(false);
    const [auditif, setauditif] = useState(false);
    const [reaffecte, setreaffecte] = useState(false);
    const [regularisation, setregularisation] = useState(false);
    const [reintegration, setreintegration] = useState(false);
    const [prise_en_charge, setprise_en_charge] = useState(false);
    const [origine_prise_en_charge, setorigine_prise_en_charge] = useState('');
    const [profession_pere, setprofession_pere] = useState('');
    const [boite_postal_pere, setboite_postal_pere] = useState('');
    const [tel1_pere, settel1_pere] = useState('');
    const [tel2_pere, settel2_pere] = useState('');
    const [profession_mere, setprofession_mere] = useState('');
    const [boite_postal_mere, setboite_postal_mere] = useState('');
    const [tel1_mere, settel1_mere] = useState('');
    const [tel2_mere, settel2_mere] = useState('');
    const [profession_tuteur, setprofession_tuteur] = useState('');
    const [boite_postal_tuteur, setboite_postal_tuteur] = useState('');
    const [tel1_tuteur, settel1_tuteur] = useState('');
    const [tel2_tuteur, settel2_tuteur] = useState('');
    const [profession_pers_en_charge, setprofession_pers_en_charge] = useState('');
    const [boite_postale_pers_en_charge, setboite_postale_pers_en_charge] = useState('');
    const [tel1_pers_en_charge, settel1_pers_en_charge] = useState('');
    const [tel2_pers_en_charge, settel2_pers_en_charge] = useState('');
    const [autre_handicap, setautre_handicap] = useState('');
    const [nom_prenom_pers_en_charge, setnom_prenom_pers_en_charge] = useState('');
    const [classe_arabe, setclasse_arabe] = useState('');
    const [transfert, settransfert] = useState(false);
    const [modif, setModif] = useState(0);
    const [matriculeEdit, setMatriculeEdit] = useState('');
    const [vmatricule, setVmatricule] = useState('');
    const [vnom, setVnom] = useState('');
    const [vprenom, setVprenom] = useState('');
    const [vnationalite, setVnationalite] = useState('');
    const [vlieuNaissance, setVlieuNaissance] = useState('');
    const [vsexe, setVsexe] = useState('');


    const [internes, setinternes] = useState(false);
    const [demi_pension, setdemi_pension] = useState(false);
    const [externes, setexternes] = useState(false);

    const [ivoirien, setivoirien] = useState(false);
    const [etranger_africain, setetranger_africain] = useState(false);
    const [etranger_non_africain, setetranger_non_africain] = useState(false);

    const [etatEleve, setEtatEleve] = useState('');
    const [natEleve, setNatEleve] = useState('');
    const [cheminphoto, setcheminphoto] = useState('');

    const handleFileUpload = (e) => {
        setSelectedFile(URL.createObjectURL(e.files[0]));
        setFile1(URL.createObjectURL(e.files[0]));
    };


    const initFilters1 = () => {
        setFilters2({
            'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            'prenomEleve': {value: null, matchMode: FilterMatchMode.CONTAINS},
            'inscriptions_status': {value: null, matchMode: FilterMatchMode.CONTAINS},
            'inscriptionsidEleve': {value: null, matchMode: FilterMatchMode.CONTAINS},
            'nomEleve': {value: null, matchMode: FilterMatchMode.CONTAINS},
            'matriculeEleve': {value: null, matchMode: FilterMatchMode.CONTAINS},
            'inscriptions_type': {value: null, matchMode: FilterMatchMode.CONTAINS},
            'inscriptions_statut_eleve': {value: null, matchMode: FilterMatchMode.CONTAINS},

        });
        setGlobalFilterValue2('');
    }
    const onGlobalFilterChange2 = (e) => {
        const value = e.target.value;
        let _filters2 = {...filters2};
        _filters2['global'].value = value;

        setFilters2(_filters2);
        setGlobalFilterValue2(value);
    }
    const clearFilter1 = () => {
        initFilters1();
    }

    useEffect(() => {

        const eleveService = new EleveService();
        eleveService.listBrancheByEcole(tenant).then(data => {
            console.log(data)
            setBranches(data)
        });

    }, []);

    const onAnneeChange = (e) => {
        setannee(e.value);
    }

    useEffect(() => {
        const eleveService = new EleveService();
        if (modif == 1) {
            setIsNotSpinning('');
            eleveService.listTouslesEleveInscrit(tenant, anneeEncours, typeOperation1)
                .then(data => {
                    console.log(data)
                    setLiseleveInscrits(data)
                    setIsNotSpinning('hidden');
                });

        }

    }, [modif]);

    useEffect(() => {
        const eleveService = new EleveService();
        if (idModif == 1) {
            setIsNotSpinning('');
            eleveService.listTouslesEleveInscrit(tenant, anneeEncours, typeOperation1)
                .then(data => {
                    console.log(data)
                    setLiseleveInscrits(data)
                    setIsNotSpinning('hidden');
                });

        }

    }, [idModif]);

    useEffect(() => {
        const eleveService = new EleveService();
        initFilters1();
        setIsNotSpinning('');
        eleveService.listTouslesEleveInscrit(tenant, anneeEncours, typeOperation1)
            .then(data => {
                console.log(data)
                setLiseleveInscrits(data)
            });
        eleveService.listNiveau(tenant).then(data => {
            console.log(data)
            setniveaus(data)
            setIsNotSpinning('hidden');
        });


        const rapp = new RapportService();
        rapp.listAnnee(tenant).then(data => setannees(data));

    }, []);
    let emptySexe= {
        sexe_code: '' ,
        sexe_libelle: ''
    } ;
    const onLoadFile1Change = (e) => {
        console.log(e.target.files);
        setFile1(e.target.files[0]);

        setFiles(current => [...current, e.target.files[0]]);

        //console.log("Nom fichier "+files[0].name) ;

        let file = e.target.files;

        let reader = new FileReader();

        /*reader.readAsText(file);
        reader.onload = function() {
            console.log(reader.result);
        };*/


    };

    const onLoadparamChange = (e) => {
        setFile1(e.target.files);

        setFiles(current => [...current, e.target.files[0]]);
        console.log("Nom fichier+++ " + file1);
        //console.log("Nom fichier "+files[0].name) ;

        let file = e.target.files;

        let reader = new FileReader();

        /*reader.readAsText(file);
        reader.onload = function() {
            console.log(reader.result);
        };*/


    };


    const formatCurrency = (value) => {
        return value.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
    }

    const openNew = () => {
        setEleve(emptyeleve);
        setSubmitted(false);
        setProductDialog(true);
    }
    const openDeletePhotoDialog = () => {
        setDeletePhotoDialog(true);
    }
    const hideDialog = () => {
        setNatEleve('');
        setEtatEleve('');
        setSubmitted(false);
        setProductDialog(false);
    }

    const hideFicheDialog = () => {

        setFicheDialog(false);
    }

    const hideDeleteProductDialog = () => {
        setDeleteProductDialog(false);
    }
    const hideDeletePhotoDialog = () => {
        setDeletePhotoDialog(false);
    }

    const hideDeleteProductsDialog = () => {
        setDeleteProductsDialog(false);
    }
    const handleImageChange = (e) => {
        const file = e.target.files[0];

        // Update the selectedImage state
        setSelectedImage(file);

        // Create a URL for the selected image to display in the preview
        const previewURL = URL.createObjectURL(file);
        setPreviewImage(previewURL);
    };
    const uploadImage = async () => {


            try {
                const formData = new FormData();
                formData.append('file', selectedImage); // 'image' should match the field name expected by the server

                const response = await axios.put(baseUri+'inscriptions/charger-photo/'+inscriAvalide.inscriptionsidEleve, formData);

                toast.current.show({
                    severity: 'success',
                    summary: 'Successful',
                    detail: 'Photo chargée avec succès!',
                    life: 3000
                });
            } catch (error) {
                // Handle errors, e.g., display an error message
                toast.current.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Erreur lors du chargement',
                    life: 3000
                });
                console.error('Error uploading image', error);
            }


    };


    const enregisterPhoto = () => {
        const eleveService = new EleveService();
        if (file1) {
            const data = new FormData();
            for (let i = 0; i < files.length; i++) {
                data.append('file', files[i]);
            }
            data.append('file', files);
            console.log("data-- " + data);
            eleveService.chargerPhoto(data, inscriAvalide.inscriptionsidEleve)
                .then((response) => {
                    toast.current.show({
                        severity: 'success',
                        summary: 'Successful',
                        detail: 'Photo chargée avec succès!',
                        life: 3000
                    });
                })
                .catch((e) => {
                    // toast.error('Upload Error')
                })

            console.log("idmodif" + idModif);

            etatInscription.etat_code = "EN_ATTENTE";
            //setProductDialog(false);

        } else {

            toast.current.show({
                severity: 'error',
                summary: 'Error Message',
                detail: 'Veuillez Charger la photo',
                life: 3000
            });
            //setProductDialog(false);
        }
    }

    const enregisterimageParam = () => {
        const eleveService = new EleveService();
        if (file1) {
            const data = new FormData();
            for (let i = 0; i < files.length; i++) {
                data.append('file', files[i]);
            }

            eleveService.chargerParam(data)
                .then((response) => {
                    toast.current.show({
                        severity: 'success',
                        summary: 'Successful',
                        detail: 'background chargée avec succès!',
                        life: 3000
                    });
                })
                .catch((e) => {
                    // toast.error('Upload Error')
                })

            console.log("idmodif" + idModif);

            etatInscription.etat_code = "EN_ATTENTE";
            //setProductDialog(false);

        } else {

            toast.current.show({
                severity: 'error',
                summary: 'Error Message',
                detail: 'Veuillez Charger la photo',
                life: 3000
            });
            //setProductDialog(false);
        }
    }
    const saveInfosComplementaire = () => {
        setSubmitted(true);

        const eleveService = new EleveService();
        inscriAvalide.nomEleve =vnom ;
        inscriAvalide.prenomEleve = vprenom ;
        inscriAvalide.matriculeEleve =vmatricule ;
        inscriAvalide.sexeEleve = selectedSexe.sexe_code ;
        inscriAvalide.inscriptions_classe_actuelle = classe_actuelle.niveaulibelle;
        inscriAvalide.inscriptions_derniereclasse_religieuse = derniereclasse_religieuse.niveaulibelle;
        inscriAvalide.inscriptions_classe_precedente = classe_actuelle.niveaulibelle;
        inscriAvalide.inscriptions_redoublant = redoublant.libelle;
        inscriAvalide.inscriptions_langue_vivante = langue_vivante.libelle;
        inscriAvalide.inscriptions_ecole_origine = ecole_origine;
        inscriAvalide.inscriptions_boursier = boursier.libelle;
        inscriAvalide.inscriptionsid = inscriAvalide.inscriptionsidEleve;
        inscriAvalide.inscriptions_statut_eleve = statutEleve.statut_code ;
        console.log("Date Naiss>> "+date_naissance) ;
        inscriAvalide.date_naissanceEleve = moment(date_naissance).utc().format('YYYY-MM-DD') ;
        console.log("inscriAvalide.date_naissanceEleve>> "+inscriAvalide.date_naissanceEleve);
        inscriAvalide.num_decision_affecte = numDecisionAff;
        inscriAvalide.nom_prenoms_pere = nom_prenoms_pere;
        inscriAvalide.nom_prenoms_mere = nom_prenoms_mere;
        inscriAvalide.nom_prenoms_tuteur = nom_prenoms_tuteur;
        inscriAvalide.profession_pere = profession_pere ;
        inscriAvalide.origine_prise_en_charge = origine_prise_en_charge ;
        inscriAvalide.commune = commune;
        inscriAvalide.handicap = handicap;
        inscriAvalide.moteur = moteur;
        inscriAvalide.visuel = visuel;
        inscriAvalide.auditif = auditif;
        inscriAvalide.reaffecte = reaffecte;
        inscriAvalide.transfert = transfert;
        inscriAvalide.regularisation = regularisation;
        inscriAvalide.reintegration = reintegration;
        inscriAvalide.prise_en_charge = prise_en_charge;
        inscriAvalide.boite_postal_pere = boite_postal_pere;
        inscriAvalide.tel1_pere = tel1_pere;
        inscriAvalide.tel2_pere = tel2_pere;
        inscriAvalide.profession_mere = profession_mere;
        inscriAvalide.tel1_mere = tel1_mere;
        inscriAvalide.tel2_mere = tel2_mere;
        inscriAvalide.profession_tuteur = profession_tuteur;
        inscriAvalide.boite_postal_tuteur = boite_postal_tuteur;
        inscriAvalide.tel1_tuteur = tel1_tuteur;
        inscriAvalide.tel2_tuteur = tel2_tuteur;
        inscriAvalide.profession_pers_en_charge = profession_pers_en_charge;
        inscriAvalide.boite_postale_pers_en_charge = boite_postale_pers_en_charge;
        inscriAvalide.tel1_pers_en_charge = tel1_pers_en_charge;
        inscriAvalide.tel2_pers_en_charge = tel2_pers_en_charge;
        inscriAvalide.autre_handicap = autre_handicap;
        inscriAvalide.nom_prenom_pers_en_charge = nom_prenom_pers_en_charge;
        inscriAvalide.classe_arabe = classe_arabe;
        inscriAvalide.brancheid = branche.id;
        inscriAvalide.ecole_origine = ecole_origine;
        console.log("inscriAvalide " + branche.id)
        console.log("inscriAvalide " + branche.libelle)
        inscriAvalide.inscriptions_boursier = boursier.id;
        inscriAvalide.inscriptions_redoublant = redoublant.id;
        inscriAvalide.inscriptions_langue_vivante = langue_vivante.id;
        inscriAvalide.identifiantBranche = branche.id;
        inscriAvalide.nationalite = vnationalite ;
        inscriAvalide.lieu_naissance = vlieuNaissance ;
        if (etatEleve == 'internes') {
            inscriAvalide.internes = true;
            inscriAvalide.externes = false;
            inscriAvalide.demi_pension = false;
        } else if (etatEleve == 'externes') {
            inscriAvalide.externes = true;
            inscriAvalide.internes = false;
            inscriAvalide.demi_pension = false;
        } else if (etatEleve == 'demi_pension') {
            inscriAvalide.demi_pension = true;
            inscriAvalide.externes = false;
            inscriAvalide.internes = false;
        }

        if (natEleve == 'ivoirien') {
            inscriAvalide.ivoirien = true;
            inscriAvalide.etranger_africain = false;
            inscriAvalide.etranger_non_africain = false;
        } else if (natEleve == 'etranger_africain') {
            inscriAvalide.etranger_africain = true;
            inscriAvalide.ivoirien = false;
            inscriAvalide.etranger_non_africain = false;
        } else if (natEleve == 'etranger_non_africain') {
            inscriAvalide.etranger_non_africain = true;
            inscriAvalide.etranger_africain = false;
            inscriAvalide.ivoirien = false;
        }


        eleveService.miseAjourInfosComplementaire(inscriAvalide)
            .then(data => {

                console.log(data)
                setModif(1);

            });

        toast.current.show({
            severity: 'success',
            summary: 'Successful',
            detail: 'informations mises à jour',
            life: 3000
        });
        setModif(0);


        setainscriAvalide(emptyInscrAvalider);


    }
    const saveProduct = () => {
        setSubmitted(true);

        const eleveService = new EleveService();
        if (inscriAvalide.inscriptionsidEleve && etatInscription.etat_code === 'VALIDEE') {

            eleveService.validerInscription(inscriAvalide.inscriptionsidEleve)
                .then(data => {
                    console.log(data)
                    setIdmodif(1)
                    setEleveCreate(data)
                });

            console.log("idmodif" + idModif);
            toast.current.show({
                severity: 'success',
                summary: 'Successful',
                detail: 'Inscription validée ',
                life: 3000
            });
            etatInscription.etat_code = "EN_ATTENTE";
            setProductDialog(false);

        } else {
            etatInscription.etat_code = "EN_ATTENTE";
            toast.current.show({
                severity: 'error',
                summary: 'Error Message',
                detail: 'Veuillez Valider ou annuler',
                life: 3000
            });
            //setProductDialog(false);
        }


    }
    const editFiche = (fiche) => {
        setainscriAvalide({...fiche});
        setMatriculeEdit(fiche.matriculeEleve);
        setFicheDialog(true);


    }
    const fetchImage = async (inscription) => {
        const res = await fetch(baseUri + "inscriptions/get-image-by-inscription/" + inscription);
        const imageBlob = await res.blob();
        const imageObjectURL = URL.createObjectURL(imageBlob);
        setPreviewImage(imageObjectURL);
    };

    const editProduct = (product) => {
        const eleveService2 = new EleveService();
        setainscriAvalide({...product});
        setVnom(product.nomEleve) ;
        setVprenom(product.prenomEleve);
        setVmatricule(product.matriculeEleve);
        selectedSexe.sexe_code = product.sexeEleve ;

        setnom_prenoms_pere(product.nom_prenoms_pere);
        //console.log("--- " + product.nom_prenoms_pere + " " + product.nomEleve + product.prenomEleve);
        setnom_prenoms_mere(product.nom_prenoms_mere);
        setnom_prenoms_tuteur(product.nom_prenoms_tuteur);
        setcommune(product.commune);
        sethandicap(product.handicap);
        setmoteur(product.moteur);
        setvisuel(product.visuel);
        setauditif(product.visuel);
        setreaffecte(product.reaffecte);
        setregularisation(product.regularisation);
        setreintegration(product.reintegration);
        setprise_en_charge(product.prise_en_charge);
        setorigine_prise_en_charge(product.origine_prise_en_charge);
        setprofession_pere(product.profession_pere);
        setboite_postale_pers_en_charge(product.boite_postale_pers_en_charge);
        setprofession_pere(product.profession_pere);
        setboite_postal_pere(product.boite_postal_pere);
        settel1_pere(product.tel1_pere);
        settel2_pere(product.tel2_pere);
        setprofession_mere(product.profession_mere);
        setboite_postal_mere(product.boite_postal_mere);
        settel1_mere(product.tel1_mere);
        settel2_mere(product.tel2_mere);
        setprofession_tuteur(product.profession_tuteur);
        setboite_postal_tuteur(product.boite_postal_tuteur);
        settel1_tuteur(product.tel1_tuteur);
        settel2_tuteur(product.tel2_tuteur);
        setprofession_pers_en_charge(product.profession_pers_en_charge);
        setboite_postale_pers_en_charge(product.boite_postale_pers_en_charge);
        settel1_pers_en_charge(product.tel1_pers_en_charge);
        settel2_pers_en_charge(product.tel2_pers_en_charge);
        setautre_handicap(product.autre_handicap);
        setnom_prenom_pers_en_charge(product.nom_prenom_pers_en_charge);
        setEcole_origine(product.ecole_origine);
        settransfert(product.transfert);
        setNumDecisionAff(product.num_decision_affectation)
        setclasse_arabe(product.classe_arabe);
        branche.id = product.brancheid;
        console.log(("branche.id+++ " + branche.id));
        //branche.libelle = product.brancheLibelle ;
        redoublant.id = product.inscriptions_redoublant;
        boursier.id = product.inscriptions_boursier;
        statutEleve.statut_code = product.inscriptions_statut_eleve;
        let dateNais = new Date(product.date_naissanceEleve) ;
        setdate_naissance(dateNais) ;
        langue_vivante.id = product.inscriptions_langue_vivante;
        setinternes(product.internes);
        setdemi_pension(product.demi_pension);
        setexternes(product.externes);

        setivoirien(product.ivoirien);
        setetranger_africain(product.etranger_africain);
        setetranger_non_africain(product.etranger_non_africain);
       setcheminphoto(product.cheminphoto) ;
        fetchImage(product.inscriptionsidEleve);
        setVlieuNaissance(product.lieu_naissance) ;
        setVnationalite(product.nationalite) ;

        /*eleveService2.displayPhoto(product.inscriptionsidEleve)
            .then(data => {
                const imageUrl = URL.createObjectURL(data) ;
                setSelectedFile(imageUrl)
            });*/


        if (product.internes) {
            setEtatEleve('internes');
        } else if (product.externes) {
            setEtatEleve('externes');
        } else if (product.demi_pension) {
            setEtatEleve('demi_pension');
        }

        if (product.ivoirien) {
            setNatEleve('ivoirien');
        } else if (product.etranger_africain) {
            setNatEleve('etranger_africain');
        } else if (product.etranger_non_africain) {
            setNatEleve('etranger_non_africain');
        }


        setProductDialog(true);
    }
    const byteArrayToBase64 = (byteArray) => {
        let binary = '';
        for (let i = 0; i < byteArray.length; i++) {
            binary += String.fromCharCode(byteArray[i]);
        }
        return window.btoa(binary);
    };
    const confirmDeleteProduct = (product) => {
        setainscriAvalide(product);
        setDeleteProductDialog(true);
    }
    const byteToBase64 = (byteArray) => {
        const binaryString = String.fromCharCode(...byteArray);
        const base64String = btoa(binaryString);
        return `data:image/png;base64,${base64String}`;
    };

    const deletePhoto = () => {

        const eleveService = new EleveService();
        eleveService.supprimerPhoto(inscriAvalide.inscriptionsidEleve)
            .then();
        setDeletePhotoDialog(false);
        toast.current.show({severity: 'success', summary: 'Successful', detail: 'la photo a été supprimée', life: 3000});
    }
    const deleteProduct = () => {
        let _eleves = eleves.filter(val => val.eleveid !== eleve.eleveid);
        setEleves(_eleves);
        const eleveService = new EleveService();
        eleveService.SupprimerEleve(eleve.eleveid, eleve)
            .then(data => setEleves(_eleves));
        setDeleteProductDialog(false);
        setEleve(emptyeleve);
        toast.current.show({severity: 'success', summary: 'Successful', detail: 'eleve a été supprimer', life: 3000});
    }

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < eleves.length; i++) {
            if (eleves[i].eleveid === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    const createId = () => {
        let id = '';
        let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < 5; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return id;
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const confirmDeleteSelected = () => {
        setDeleteProductsDialog(true);
    }

    const deleteSelectedProducts = () => {
        let _eleves = eleves.filter(val => !selectedProducts.includes(val));
        setEleves(_eleves);
        setDeleteProductsDialog(false);
        setSelectedProducts(null);

        toast.current.show({severity: 'success', summary: 'Successful', detail: 'Products Deleted', life: 3000});
    }


    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _eleve = {...eleve};
        _eleve[`${name}`] = val;

        setEleve(_eleve);
    }


    const EtatInscriptions = [
        {etat_code: 'EN_ATTENTE', etat_libelle: 'EN ATTENTE'},
        {etat_code: 'VALIDEE', etat_libelle: 'VALIDEE'}

    ];
    const Sexe = [
        {sexe_code: 'MASCULIN', sexe_libelle: 'MASCULIN'},
        {sexe_code: 'FEMININ', sexe_libelle: 'FEMININ'}

    ];

    const Boursiers = [
        {id: 'B', libelle: 'B'},
        {id: '1/2B', libelle: '1/2B'}

    ];

    const Transferts = [
        {code: 'O', libelle: 'OUI'},
        {code: 'N', libelle: 'NON'}

    ];
    const LV2S = [
        {id: 'ALL', libelle: 'ALL'},
        {id: 'ESP', libelle: 'ESP'}

    ];
    const Redoublants = [
        {id: 'NON', libelle: 'NON'},
        {id: 'OUI', libelle: 'OUI'}
    ];
    const Statuts = [
        {statut_code: 'AFFECTE', statut_libelle: 'AFFECTE'},
        {statut_code: 'NON_AFFECTE', statut_libelle: 'NON AFFECTE'}
    ];

    const InscriptionTypeOperation2 = [
        'PREINSCRIPTION', 'INSCRIPTION'
    ];

    const InscriptionStatut = [
        'EN ATTENTE', 'VALIDEE'
    ];

    const InscriptionTypeOperation = [
        {inscripStatuCode: 'INSCRIPTION', inscripStatulibelle: 'INSCRIPTION'},
        {inscripStatuCode: 'PREINSCRIPTION', inscripStatulibelle: 'PREINSCRIPTION'}
    ];

    const onNiveauChange = (e) => {
        setniveau(e.value);
    }

    const onInscripStatutChange = (e) => {
        setSelectedInscrStatut(e.value);
    }


    const onAnneeColaireChange = (e) => {
        setanneeScolaire(e.value);
    }


    const onStatutChange = (e) => {
        let _statut = {...statutEleve};
        _statut.statut_code = e.value;
        setstatutEleve(_statut);

    }

    const onSexeChange = (e) => {
        console.log("e+++" + e.value)
        let _sexe = {...selectedSexe};
        _sexe.sexe_code = e.value;

        setSelectedSexe(_sexe);

    }

    const statusBodyTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">Statut</span>
                {rowData.inscriptions_statut_eleve}
            </>
        );

    }

    const imprimerFicheIdentifi = () => {

        const rapportService = new RapportService();
        setIsNotSpinning1('')
        fetch(baseUri + "imprimer-Fiche-eleve/identification/" + annee.libelle + "/" + matriculeEdit + "/" + tenant)
            .then(response => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = 'Fiche identification ' + '_' + matriculeEdit + '.pdf';
                    a.click();
                });
                setIsNotSpinning1('hidden')
            });

    }

    const imprimerFicheIdentifi2 = () => {

        const rapportService = new RapportService();
        setIsNotSpinning2('')
        fetch(baseUri + "imprimer-Fiche-eleve/inscription/" + annee.libelle + "/" + matriculeEdit + "/" + tenant)
            .then(response => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = 'Fiche inscription ' + '_' + matriculeEdit + '.pdf';
                    a.click();
                });
                setIsNotSpinning2('hidden')
            });

    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Nouveau" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew}/>

                </div>
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <FileUpload mode="basic" accept="image/!*" maxFileSize={1000000} label="Import" chooseLabel="Import"
                            className="mr-2 inline-block"/>
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV}/>
            </React.Fragment>
        )
    }
    const IdInscriptionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">N°</span>
                {rowData.inscriptionsidEleve}
            </>
        );
    }


    const NomBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">nom</span>
                {rowData.nomEleve}
            </>
        );
    }

    const PreNomBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Prénom</span>
                {rowData.prenomEleve}
            </>
        );
    }

    const matriculeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">matricule</span>
                {rowData.matriculeEleve}
            </>
        );
    }


    const statutEleveBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Statut</span>
                {rowData.inscriptions_statut_eleve}
            </>
        );
    }
    const statutInscriptionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Etat</span>
                {rowData.inscriptions_status}
            </>
        );
    }
    const brancheBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Branche</span>
                {rowData.brancheLibelle}
            </>
        );
    }
    const statusInscriptionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Statut Inscription</span>
                {rowData.inscriptions_status}
            </>
        )
    }
    const prenomBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">prénom</span>
                {rowData.prenomEleve}
            </>
        );
    }
    const NumInscrBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">N°</span>
                {rowData.inscriptionsidEleve}
            </>
        );
    }


    const sexeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Sexe</span>
                {rowData.sexeEleve}
            </>
        );
    }

    const onDernierClassChange = (e) => {
        setClasse_precedente(e.value);

    };

    const onDernierClassReligieuseChange = (e) => {
        setDerniereclasse_religieuse(e.value);

    };

    const onBoursierChange = (e) => {
        // setBoursier(e.value);
        let _boursier = {...boursier};
        _boursier.id = e.value;
        setBoursier(_boursier)

    };
    const onRedoublantChange = (e) => {
        //  setRedoublant(e.value);
        let _redoublant = {...redoublant};
        _redoublant.id = e.value;
        setRedoublant(_redoublant)

    };

    const onLangChange = (e) => {
        //setLangue_vivante(e.value);
        let _langu = {...langue_vivante};
        _langu.id = e.value;
        setLangue_vivante(_langu);

    };


    const onClassActuelleChange = (e) => {
        console.log("e+++" + e.value)
        let _branche = {...branche};
        _branche.id = e.value;
        setBranche(_branche)


        console.log("Change " + branche.id + " " + branche.libelle);
        // setClasse_actuelle(e.value);

    };

    const contactBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Cellulaire</span>
                {rowData.contactEleve}
            </>
        );
    }

    const statutInscripBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Cellulaire</span>
                {rowData.inscriptions_status}
            </>
        )
    }


    const typeInscriptionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Cellulaire</span>
                {rowData.inscriptions_type}
            </>
        )
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2"
                        onClick={() => editProduct(rowData)}/>
                <Button icon="pi pi-file-pdf" className="p-button-rounded p-button-info mr-2"
                        onClick={() => editFiche(rowData)}/>
            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Liste des élèves</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search"/>
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Rechercher..."/>
            </span>
        </div>
    );
    const typeItemTemplate = (option) => {
        return <span className={`customer-badge status-${option}`}>{option}</span>;
    }

    const statusItemTemplate = (option) => {
        return <span className={`customer-badge status-${option}`}>{option}</span>;
    }
    const typeOperationFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={InscriptionTypeOperation2}
                         onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={typeItemTemplate}
                         placeholder="Sélectionnez le type" className="p-column-filter" showClear/>;
    }

    const statutFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={InscriptionStatut}
                         onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={statusItemTemplate}
                         placeholder="Sélectionnez le Statut" className="p-column-filter" showClear/>;
    }
    /*   const productDialogFooter = (
           <>
               <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
               <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
               <Button label="Charger la photo" icon="pi pi-check" className="p-button-text" onClick={enregisterPhoto} />
           </>
       );*/
    const deletePhotoDialogFooter = (
        <>
            <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={hideDeletePhotoDialog}/>
            <Button label="Oui" icon="pi pi-check" className="p-button-text" onClick={deletePhoto}/>
        </>
    );
    const deleteProductDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog}/>
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteProduct}/>
        </>
    );
    const deleteProductsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductsDialog}/>
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedProducts}/>
        </>
    );
    const renderHeader2 = () => {
        return (
            <div className="flex justify-content-end">
                <span className="p-input-icon-left">
                    <i className="pi pi-search"/>
                    <InputText value={globalFilterValue2} onChange={onGlobalFilterChange2} placeholder="Mots clés..."/>
                </span>
            </div>
        )
    }

    const header2 = renderHeader2();
    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast}/>


                    <div className="card">
                        <h5>Inscriptions en attente</h5>
                        <p>Liste des inscriptions en attente de validation</p>
                        <ProgressSpinner className={isNotSpinning}  style={{width: '50px', height: '50px'}}  strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />

                        <DataTable value={liseleveInscrits} paginator className="p-datatable-customers" rows={10}
                                   selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                                   dataKey="inscriptionsidEleve" filters={filters2} filterDisplay="row"
                                   responsiveLayout="scroll"
                                   globalFilterFields={['inscriptionsidEleve', 'nomEleve', 'matriculeEleve', 'prenomEleve', 'inscriptions_status', 'inscriptions_statut_eleve', 'eleve_sexe', 'brancheLibelle']}
                                   header={header2}
                                   emptyMessage="Pas d'inscription en attente pour ce critère de choix!">
                            <Column filterField="inscriptionsidEleve" header="N°" body={NumInscrBodyTemplate}
                                    headerStyle={{width: '10%', minWidth: '5rem'}}></Column>

                            <Column filterField="matriculeEleve" header="Matricule" body={matriculeBodyTemplate}
                                    headerStyle={{width: '14%', minWidth: '5rem'}}></Column>

                            <Column filterField="NomEleve" header="Nom" body={NomBodyTemplate}
                                    headerStyle={{width: '10%', minWidth: '5rem'}}></Column>

                            <Column filterField="prenomEleve" header="prénom(s)" body={prenomBodyTemplate}
                                    headerStyle={{width: '22%', minWidth: '10rem'}}></Column>

                            <Column field="inscriptions_statut_eleve" header="Statut" body={statusBodyTemplate}
                                    headerStyle={{width: '10%', minWidth: '5rem'}}
                                    showFilterMenu={false} filterMenuStyle={{width: '14rem'}}
                            ></Column>
                            <Column field="inscriptions_status" header="Status Inscription"
                                    body={statusInscriptionBodyTemplate} headerStyle={{width: '14%', minWidth: '5rem'}}
                                    showFilterMenu={false}></Column>
                            <Column field="eleve_sexe" header="Sexe" body={sexeBodyTemplate}
                                    headerStyle={{width: '14%', minWidth: '10rem'}}></Column>
                            <Column field="brancheLibelle" header="Branche" body={brancheBodyTemplate}
                                    headerStyle={{width: '14%', minWidth: '10rem'}}></Column>

                            <Column body={actionBodyTemplate}></Column>
                        </DataTable>
                    </div>

                    <Dialog visible={productDialog} style={{width: '950px'}} header="Details éleve" modal
                            className="p-fluid" onHide={hideDialog}>
                        <TabView>
                            <TabPanel header="VALIDATION DE L'ELEVE">

                                <div className="formgrid grid">

                                    <div className="field col">
                                        <label htmlFor="vtype">Type processus</label>
                                        <InputText value={typeOperation1} id="vtype" disabled/>

                                    </div>
                                    <div className="field col">
                                        <label htmlFor="vmatricule">Matricule</label>
                                        <InputText type="text" id="vmatricule" value={vmatricule}
                                                   onChange={(e) => setVmatricule(e.target.value)}/>
                                    </div>
                                </div>

                                <div className="formgrid grid">
                                    <div className="field col">
                                        <label htmlFor="vsexe">Sexe</label>
                                        <Dropdown value={selectedSexe.sexe_code} id="vsexe" options={Sexe} onChange={onSexeChange} optionValue="sexe_code"  optionLabel="sexe_code" placeholder="Selectionnez le Sexe" />

                                    </div>

                                <div className="field col">
                                    <label htmlFor="vnom">Nom</label>
                                    <InputText type="text" id="vnom" value={vnom}
                                               onChange={(e) => setVnom(e.target.value)}/>
                                </div>

                                <div className="field col">
                                    <label htmlFor="vprenom">Prénom(s)</label>
                                    <InputText type="text" id="vprenom" value={vprenom}
                                               onChange={(e) => setVprenom(e.target.value)}/>
                                </div>
                                </div>

                                <div className="formgrid grid">
                                    <div className="field col">
                                        <label htmlFor="nationalite">Nationnalité</label>
                                        <InputText type="text" id="nationalite" value={vnationalite}
                                                   onChange={(e) => setVnationalite(e.target.value)}/>
                                    </div>

                                    <div className="field col">
                                        <label htmlFor="lieuNaiss">Lieu de naissance</label>
                                        <InputText type="text" id="lieuNaiss" value={vlieuNaissance}
                                                   onChange={(e) => setVlieuNaissance(e.target.value)}/>
                                    </div>
                                </div>

                                <div className="formgrid grid">
                                    <div className="field col">
                                        <Dropdown value={statutEleve.statut_code} optionValue="statut_code" id="statut_code" onChange={onStatutChange} options={Statuts} optionLabel="statut_libelle" placeholder="Selectionnez le statut*" />
                                    </div>

                                    <div className="field col">
                                        <Calendar showIcon showButtonBar view="month" required id="date" value={date_naissance} onChange={(e) => setdate_naissance(e.value)} dateFormat="dd/mm/yy" placeholder="dd/mm/yy" />
                                    </div>
                                </div>



                                <div className="formgrid grid">
                                    <div className="field col">

                                        {previewImage && <img src={previewImage} alt="Preview" width="150" />}
                                    </div>
                                </div>
                                <div className="formgrid grid">

                                    <div className="field col">
                                        <Dropdown value={etatInscription} onChange={(e) => setEtatInscription(e.value)}
                                                  disabled={inscriAvalide.inscriptions_status == "VALIDEE" ? true : false}
                                                  options={EtatInscriptions} optionLabel="etat_libelle"
                                                  placeholder="Valider l'inscription"/>
                                    </div>


                                </div>


                                <div className="formgrid grid">
                                    <div className="field col">
                                        <Button label="Annuler" icon="pi pi-times" className="p-button-text"
                                                onClick={hideDialog}/>
                                    </div>
                                    <div className="field col">
                                        <Button label="Valider" icon="pi pi-check" className="p-button-text"
                                                onClick={saveProduct}/>
                                    </div>
                                    <div className="field col">
                                        <Button label="Modifier" icon="pi pi-pencil" className="p-button-text"
                                                onClick={saveInfosComplementaire}/>
                                    </div>
                                </div>


                            </TabPanel>
                            <TabPanel header="CHARGER LA PHOTO">

                                    <div className="field">
                                        { <img src={cheminphoto} alt="Preview" width="150" />}
                                    </div>
                                    <div className="field">
                                        <label htmlFor="file2">Charger Photo</label>
                                        <input type="file" accept="image/*" onChange={handleImageChange} />

                                    </div>
                                <div className="formgrid grid">
                                <div className="field col">
                                    <Button label="Charger la photo" icon="pi pi-check" className="p-button-text"
                                            onClick={uploadImage}/>
                                </div>
                                    <div className="field col">
                                    </div>
                                    <div className="field col">
                                        <Button label="Supprimer" severity="danger" onClick={openDeletePhotoDialog} />
                                    </div>
                                </div>

                            </TabPanel>

                            <TabPanel header="INFORMATIONS COMPLEMENTAIRES">
                                <div className="formgrid grid">
                                    <div className="field col">
                                        <label htmlFor="handicap">handicap</label>
                                        <Checkbox onChange={e => sethandicap(e.checked)} checked={handicap}></Checkbox>
                                    </div>
                                    <div className="field col">
                                        <label htmlFor="Moteur">Moteur</label>
                                        <Checkbox onChange={e => setmoteur(e.checked)} checked={moteur}></Checkbox>
                                    </div>
                                    <div className="field col">
                                        <label htmlFor="Visuel">Visuel</label>
                                        <Checkbox onChange={e => setvisuel(e.checked)} checked={visuel}></Checkbox>
                                    </div>
                                    <div className="field col">
                                        <label htmlFor="auditif">Auditif</label>
                                        <Checkbox onChange={e => setauditif(e.checked)} checked={auditif}></Checkbox>
                                    </div>
                                </div>
                                <div className="formgrid grid">
                                    <div className="field col">
                                        <label htmlFor="autreHandi">Autre Handicap</label>
                                        <InputText type="text" id="autreHandi" value={autre_handicap}
                                                   onChange={(e) => setautre_handicap(e.target.value)}/>
                                    </div>
                                </div>
                                <div className="formgrid grid">
                                    <div className="field col">
                                        <label htmlFor="reaffecte">reaffecte</label>
                                        <Checkbox onChange={e => setreaffecte(e.checked)}
                                                  checked={reaffecte}></Checkbox>
                                    </div>
                                    <div className="field col">
                                        <label htmlFor="regularisation">Regularisation</label>
                                        <Checkbox onChange={e => setregularisation(e.checked)}
                                                  checked={regularisation}></Checkbox>
                                    </div>
                                    <div className="field col">
                                        <label htmlFor="reintegration">Reintégration</label>
                                        <Checkbox onChange={e => setreintegration(e.checked)}
                                                  checked={reintegration}></Checkbox>
                                    </div>

                                    <div className="field col">
                                        <label htmlFor="transfert">Transfert</label>
                                        <Checkbox onChange={e => settransfert(e.checked)}
                                                  checked={transfert}></Checkbox>
                                    </div>

                                </div>

                                <div className="formgrid grid">
                                    <div className="field col">
                                        <RadioButton inputId="internes" name="pizza" value="internes"
                                                     onChange={(e) => setEtatEleve(e.value)}
                                                     checked={etatEleve === 'internes'}/>
                                        <label htmlFor="internes" className="ml-2">Internes</label>
                                    </div>

                                    <div className="field col">
                                        <RadioButton inputId="demi_pension" name="pizza" value="demi_pension"
                                                     onChange={(e) => setEtatEleve(e.value)}
                                                     checked={etatEleve === 'demi_pension'}/>
                                        <label htmlFor="demi_pension" className="ml-2">Demi pension</label>
                                    </div>

                                    <div className="field col">
                                        <RadioButton inputId="externes" name="pizza" value="externes"
                                                     onChange={(e) => setEtatEleve(e.value)}
                                                     checked={etatEleve === 'externes'}/>
                                        <label htmlFor="externes" className="ml-2">Externes</label>
                                    </div>
                                </div>

                                <div className="formgrid grid">

                                    <div className="field col">
                                        <RadioButton inputId="ivoirien" name="pizza1" value="ivoirien"
                                                     onChange={(e) => setNatEleve(e.value)}
                                                     checked={natEleve === 'ivoirien'}/>
                                        <label htmlFor="ivoirien" className="ml-2">Ivoirien</label>
                                    </div>

                                    <div className="field col">
                                        <RadioButton inputId="etranger_africain" name="pizza1" value="etranger_africain"
                                                     onChange={(e) => setNatEleve(e.value)}
                                                     checked={natEleve === 'etranger_africain'}/>
                                        <label htmlFor="etranger_africain" className="ml-2">Etranger africain</label>
                                    </div>

                                    <div className="field col">
                                        <RadioButton inputId="etranger_non_africain" name="pizza1"
                                                     value="etranger_non_africain"
                                                     onChange={(e) => setNatEleve(e.value)}
                                                     checked={natEleve === 'etranger_non_africain'}/>
                                        <label htmlFor="etranger_non_africain" className="ml-2">Etranger non
                                            africain</label>
                                    </div>
                                </div>
                                <div className="formgrid grid">
                                    <div className="field col">
                                        <label htmlFor="priseEnCharg">Prise en charge</label>
                                        <Checkbox onChange={e => setprise_en_charge(e.checked)}
                                                  checked={prise_en_charge}></Checkbox>
                                    </div>


                                    <div className="field col">
                                        <label htmlFor="origPriseCharge">Origine prise en charge</label>
                                        <InputText type="text" id="origPriseCharge" value={origine_prise_en_charge}
                                                   onChange={(e) => setorigine_prise_en_charge(e.target.value)}/>
                                    </div>
                                </div>


                                <div className="formgrid grid">
                                    <div className="field col">
                                        <label htmlFor="ecoleOrigine">Ecole d'origine</label>
                                        <InputText type="text" id="ecoleOrigine" value={ecole_origine}
                                                   onChange={(e) => setEcole_origine(e.target.value)}/>
                                    </div>
                                    <div className="field col">
                                        <label htmlFor="numDecisionAff">Decision d'affectation</label>
                                        <InputText type="text" id="numDecisionAff" value={numDecisionAff}
                                                   onChange={(e) => setNumDecisionAff(e.target.value)}/>
                                    </div>
                                </div>
                                <div className="formgrid grid">
                                    <div className="field col">
                                        <label htmlFor="dernieClassReligieuse">Dernière classe réligieuse</label>
                                        <InputText type="text" id="dernieClassReligieuse" value={classe_arabe}
                                                   onChange={(e) => setclasse_arabe(e.target.value)}/>
                                    </div>

                                    <div className="field col">
                                        <label htmlFor="classePrec">Classe précedente</label>
                                        <Dropdown id="classePrec" options={niveaus} value={classe_precedente}
                                                  onChange={onDernierClassChange}
                                                  optionLabel="niveaulibelle"></Dropdown>

                                    </div>
                                </div>

                                <div className="formgrid grid">
                                    <div className="field col">
                                        <label htmlFor="classeActu">Niveau actuel</label>
                                        <Dropdown id="classeActu" value={branche.id} options={branches}
                                                  onChange={onClassActuelleChange} optionValue="id"
                                                  optionLabel="libelle"></Dropdown>


                                    </div>
                                    <div className="field col">
                                        <label htmlFor="redoubl">Redoublant</label>
                                        <Dropdown id="redoubl" options={Redoublants} value={redoublant.id}
                                                  onChange={onRedoublantChange} optionValue="id"
                                                  optionLabel="libelle"></Dropdown>

                                    </div>
                                </div>
                                <div className="formgrid grid">
                                    <div className="field col">
                                        <label htmlFor="boursier">boursier</label>
                                        <Dropdown id="boursier" options={Boursiers} value={boursier.id}
                                                  onChange={onBoursierChange} optionLabel="libelle"
                                                  optionValue="id"></Dropdown>

                                    </div>

                                    <div className="field col">
                                        <label htmlFor="langV2">Langue vivante</label>
                                        <Dropdown id="langV2" options={LV2S} value={langue_vivante.id}
                                                  onChange={onLangChange} optionLabel="libelle"
                                                  optionValue="id"></Dropdown>

                                    </div>
                                </div>
                                <div className="formgrid grid">
                                    <div className="field col">
                                        <Button label="Cancel" icon="pi pi-times" className="p-button-text"
                                                onClick={hideDialog}/>
                                    </div>
                                    <div className="field col">
                                        <Button label="Modifier" icon="pi pi-pencil" className="p-button-text"
                                                onClick={saveInfosComplementaire}/>
                                    </div>

                                </div>

                            </TabPanel>

                            <TabPanel header="INFORMATIONS SUR LES PARENTS">

                                <div className="formgrid grid">
                                    <div className="field col">
                                        <label htmlFor="nomPere">Nom du père</label>
                                        <InputText type="text" id="nomPere" value={nom_prenoms_pere}
                                                   onChange={(e) => setnom_prenoms_pere(e.target.value)}/>
                                    </div>

                                    <div className="field col">
                                        <label htmlFor="profePere">Profession du père</label>
                                        <InputText type="text" id="profePere" value={profession_pere}
                                                   onChange={(e) => setprofession_pere(e.target.value)}/>
                                    </div>

                                    <div className="field col">
                                        <label htmlFor="boitePere">Boite postal du père</label>
                                        <InputText type="text" id="boitePere" value={boite_postal_pere}
                                                   onChange={(e) => setboite_postal_pere(e.target.value)}/>
                                    </div>

                                    <div className="field col">
                                        <label htmlFor="tel1Pere">téléphone 1 du père</label>
                                        <InputText type="text" id="tel1Pere" value={tel1_pere}
                                                   onChange={(e) => settel1_pere(e.target.value)}/>
                                    </div>

                                    <div className="field col">
                                        <label htmlFor="tel2Pere">téléphone 2 du père</label>
                                        <InputText type="text" id="tel2Pere" value={tel2_pere}
                                                   onChange={(e) => settel2_pere(e.target.value)}/>
                                    </div>

                                </div>
                                <div className="formgrid grid">
                                    <div className="field col">
                                        <label htmlFor="nomMere">Nom de la mère</label>
                                        <InputText type="text" id="nomMere" value={nom_prenoms_mere}
                                                   onChange={(e) => setnom_prenoms_mere(e.target.value)}/>
                                    </div>

                                    <div className="field col">
                                        <label htmlFor="profeMere">Profession de la mère</label>
                                        <InputText type="text" id="profeMere" value={profession_mere}
                                                   onChange={(e) => setprofession_mere(e.target.value)}/>
                                    </div>

                                    <div className="field col">
                                        <label htmlFor="boiteMere">Boite postal de la mère </label>
                                        <InputText type="text" id="boiteMere" value={boite_postal_mere}
                                                   onChange={(e) => setboite_postal_mere(e.target.value)}/>
                                    </div>

                                    <div className="field col">
                                        <label htmlFor="tel1Mere">téléphone 1 de la mère </label>
                                        <InputText type="text" id="tel1Pere" value={tel1_mere}
                                                   onChange={(e) => settel1_mere(e.target.value)}/>
                                    </div>

                                    <div className="field col">
                                        <label htmlFor="tel2Mere">téléphone 2 de la mère</label>
                                        <InputText type="text" id="tel2Mere" value={tel2_mere}
                                                   onChange={(e) => settel2_mere(e.target.value)}/>
                                    </div>

                                </div>

                                <div className="formgrid grid">
                                    <div className="field col">
                                        <label htmlFor="nomTuteur">Nom du tuteur</label>
                                        <InputText type="text" id="nomTuteur" value={nom_prenoms_tuteur}
                                                   onChange={(e) => setnom_prenoms_tuteur(e.target.value)}/>
                                    </div>

                                    <div className="field col">
                                        <label htmlFor="profeTuteur">Profession du tuteur</label>
                                        <InputText type="text" id="profeTuteur" value={profession_tuteur}
                                                   onChange={(e) => setprofession_tuteur(e.target.value)}/>
                                    </div>

                                    <div className="field col">
                                        <label htmlFor="boiteTuteur">Boite postal du tuteur </label>
                                        <InputText type="text" id="boiteTuteur" value={boite_postal_tuteur}
                                                   onChange={(e) => setboite_postal_tuteur(e.target.value)}/>
                                    </div>

                                    <div className="field col">
                                        <label htmlFor="tel1Tuteur">téléphone 1 du tuteur </label>
                                        <InputText type="text" id="tel1Tuteur" value={tel1_tuteur}
                                                   onChange={(e) => settel1_tuteur(e.target.value)}/>
                                    </div>

                                    <div className="field col">
                                        <label htmlFor="tel2Tuteur">téléphone 2 du tuteur</label>
                                        <InputText type="text" id="tel2Tuteur" value={tel2_tuteur}
                                                   onChange={(e) => settel2_tuteur(e.target.value)}/>
                                    </div>

                                </div>
                                <div className="formgrid grid">
                                    <div className="field col">
                                        <label htmlFor="nomPersoCharg">Nom Personne en charge</label>
                                        <InputText type="text" id="nomPersoCharg" value={nom_prenom_pers_en_charge}
                                                   onChange={(e) => setnom_prenom_pers_en_charge(e.target.value)}/>
                                    </div>

                                    <div className="field col">
                                        <label htmlFor="profePersEnch">Profession Personne en charge</label>
                                        <InputText type="text" id="profePersEnch" value={profession_pers_en_charge}
                                                   onChange={(e) => setprofession_pers_en_charge(e.target.value)}/>
                                    </div>

                                    <div className="field col">
                                        <label htmlFor="boitePersChar">Boite postal Personne en charge </label>
                                        <InputText type="text" id="boitePersChar" value={boite_postale_pers_en_charge}
                                                   onChange={(e) => setboite_postale_pers_en_charge(e.target.value)}/>
                                    </div>

                                    <div className="field col">
                                        <label htmlFor="tel1PersChar">téléphone1 Personne en charge </label>
                                        <InputText type="text" id="tel1PersChar" value={tel1_pers_en_charge}
                                                   onChange={(e) => settel1_pers_en_charge(e.target.value)}/>
                                    </div>

                                    <div className="field col">
                                        <label htmlFor="tel2PersChar">téléphone2 Personne en charge </label>
                                        <InputText type="text" id="tel2PersChar" value={tel2_pers_en_charge}
                                                   onChange={(e) => settel2_pers_en_charge(e.target.value)}/>
                                    </div>

                                </div>
                                <div className="formgrid grid">
                                    <div className="field col">
                                        <Button label="Cancel" icon="pi pi-times" className="p-button-text"
                                                onClick={hideDialog}/>
                                    </div>
                                    <div className="field col">
                                        <Button label="Modifier" icon="pi pi-pencil" className="p-button-text"
                                                onClick={saveInfosComplementaire}/>
                                    </div>

                                </div>

                            </TabPanel>

                        </TabView>


                    </Dialog>

                    <Dialog visible={FicheDialog} style={{width: '950px'}} header="Imprimer Fiche élève" modal
                            className="p-fluid" onHide={hideFicheDialog}>
                        <div className="formgrid grid">
                            <div className="field col">
                                <InputText value={inscriAvalide.matriculeEleve} disabled/>
                            </div>
                            <div className="field col">
                                <InputText value={inscriAvalide.nomEleve} disabled/>
                            </div>

                            <div className="field col">
                                <InputText value={inscriAvalide.prenomEleve} disabled/>
                            </div>
                        </div>

                        <div className="field">
                            <label htmlFor="dropdown">Annee</label>
                            <Dropdown id="dropdown" options={annees} value={annee} onChange={onAnneeChange}
                                      optionLabel="libelle">
                            </Dropdown>
                        </div>
                        <div className="formgrid grid">


                            <div className="field col">
                                <div className="field">
                                    <ProgressSpinner className={isNotSpinning1} style={{width: '50px', height: '50px'}}
                                                     strokeWidth="8" fill="var(--surface-ground)"
                                                     animationDuration=".5s"/>
                                </div>
                                <Button label="Fiche d'identification" className="mr-2 mb-2"
                                        onClick={() => imprimerFicheIdentifi()}></Button>

                            </div>

                            <div className="field col">
                                <div className="field">
                                    <ProgressSpinner className={isNotSpinning2} style={{width: '50px', height: '50px'}}
                                                     strokeWidth="8" fill="var(--surface-ground)"
                                                     animationDuration=".5s"/>
                                </div>
                                <Button label="Fiche d'inscription" className="mr-2 mb-2"
                                        onClick={() => imprimerFicheIdentifi2()}></Button>

                            </div>

                        </div>

                    </Dialog>

                    <Dialog visible={deleteProductDialog} style={{width: '450px'}} header="Confirm" modal
                            footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{fontSize: '2rem'}}/>
                            {eleve &&
                                <span>Voulez vous vraiment supprimer <b>{eleve.elevematricule_national}</b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteProductsDialog} style={{width: '450px'}} header="Confirm" modal
                            footer={deleteProductsDialogFooter} onHide={hideDeleteProductsDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{fontSize: '2rem'}}/>
                            {eleve && <span>Voulez vous vraiment supprimer les elements selectionnés?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={deletePhotoDialog} style={{width: '450px'}} header="Confirmation" modal
                            footer={deletePhotoDialogFooter} onHide={hideDeletePhotoDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{fontSize: '2rem'}}/>
                            {eleve &&
                                <span>Voulez vous vraiment supprimer la photo <b>{eleve.elevematricule_national}</b>?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(InscriptionAvalider, comparisonFn);
