// BlogArea.js

import React from 'react';

const BlogArea = () => {
  const articles = [
    {
      id: 1,
      title: "Daloa / CAFOP: ce qui s'est passé hier dans ce centre de formation",
      date: '11 Mai 2024',
      content: "Hier, samedi 11 mai 2024, le centre d'animation et de formation pédagogique (CAFOP) de Daloa a abrité une cérémonie spéciale. L'antenne de la pédagogie et de la formation continue (APFC) a utilisé les locaux de ce centre de formation pour organiser sa deuxième journée pédagogique, dénommée journée de l'antenne pédagogique et de la formation continue de Daloa (J-APFC-Daloa).",
      description: "Une vue de la cérémonie au CAFOP de Daloa, où les enseignants et les élèves se sont réunis pour discuter de la pédagogie et de la formation continue.",
      imageSrc: 'assets/img/blog/1/cafop-daloa.png',
    },
    {
      id: 2,
      title: "Séguéla : la DRENA récompense les filles majors des classes d'examens primaire et du secondaire",
      date: '10 Mai 2024',
      content: "La direction régionale de l'éducation nationale et de l'alphabétisation (DRENA) de Séguéla, dirigée par Mme Assié, a organisé une cérémonie pour récompenser les filles majors des classes de CM2, de Troisième et de Terminale.",
      description: "Les lauréates lors de la cérémonie de récompense organisée par la DRENA à Séguéla, sous le regard des personnalités présentes.",
      imageSrc: 'assets/img/blog/1/drena-seguela.png',
    },
    {
      id: 3,
      title: "Côte d’Ivoire – AIP / Des élèves de Bocanda instruits sur les valeurs du civisme et de la citoyenneté",
      date: '9 Mai 2024',
      content: "Les élèves du lycée moderne de Bocanda ont été instruits sur les valeurs du civisme et de la citoyenneté par le directeur départemental de la Promotion de la jeunesse et de l’Emploi des jeunes.",
      description: "Une séance éducative au lycée moderne de Bocanda, axée sur le civisme et la citoyenneté.",
      imageSrc: 'assets/img/blog/1/aip.png',
    },
    {
      id: 4,
      title: "Quel est le mot le plus long de la langue Française ? Non ce n’est plus anticonstitutionnellement !",
      date: 'Inconnu',
      content: "Le mot le plus long de la langue française n'est plus 'anticonstitutionnellement'. Découvrez le nouveau mot qui prend sa place.",
      description: "Illustration de la langue française et des mots qui la composent.",
      imageSrc: 'assets/img/blog/1/blog-1.jpg',
    },
    {
      id: 5,
      title: "Côte d’Ivoire-AIP/ Une centaine des meilleurs élèves et enseignants primés au lycée 1 de Gagnoa",
      date: '10 Mai 2024',
      content: "Une centaine des meilleurs élèves et enseignants du lycée moderne 1 de Gagnoa ont été primés lors d'une journée d’excellence.",
      description: "Célébration des lauréats au lycée moderne 1 de Gagnoa, soulignant les réalisations académiques.",
      imageSrc: 'assets/img/blog/1/blog-1.jpg',
    },
    {
      id: 6,
      title: "Fin de la fermeture des classes en début juin: voici les nouvelles dates",
      date: 'Inconnu',
      content: "Cette année, les cours ne vont pas s'arrêter au début du mois de juin comme habituellement. Le ministre a révélé les nouvelles dates des compositions.",
      description: "Annonces officielles sur le calendrier scolaire par le ministère de l'Éducation.",
      imageSrc: 'assets/img/blog/1/blog-1.jpg',
    },
    {
      id: 7,
      title: "Bourses pour étudier au Japon: voici les critères à remplir (ministre de l'enseignement supérieur)",
      date: 'Inconnu',
      content: "Le gouvernement du Japon offre des bourses d'études au titre de l'année fiscale japonaise 2025, avec plusieurs domaines d'études disponibles.",
      description: "Information sur les bourses d'études japonaises pour les étudiants sénégalais.",
      imageSrc: 'assets/img/blog/1/blog-1.jpg',
    }
  ];

  return (
    <section className="blog-area pt-40 pb-40">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-8 col-md-10">
            <div className="testimonial-section-area text-center">
              <div className="section-area mb-55 section-area-top">
                <span className="section-subtitle">Notre Blog</span>
                <h2 className="section-title mb-20">Nos Derniers Articles</h2>
                <p className="section-text">
                  Grâce à une combinaison de conférences, de lectures et de discussions, les étudiants acquerront une base solide en psychologie de l'éducation.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-8 col-lg-8">
            <div className="blog-wrap">
              {articles.slice(0, 2).map(post => (
                <div key={post.id} className="blog-item blog-item-h mb-30">
                  <div className="blog-img">
                    <a href={post.link}><img src={post.imageSrc} alt={post.title} /></a>
                  </div>
                  <div className="blog-content">
                    <div className="blog-content-meta">
                      <span><i className="fa-thin fa-user"></i>{post.author}</span>
                      <span><i className="fa-thin fa-clock"></i>{post.date}</span>
                    </div>
                    <h5 className="blog-content-title"><a href={post.link}>{post.title}</a></h5>
                    <p className="blog-excerpt">{post.content.split('.')[0]}.</p> {/* Affiche la première phrase comme extrait */}
                    <a href="#" className="theme-btn blog-btn t-theme-btn">Lire Plus</a>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="col-xl-4 col-lg-4">
            <div className="blog-item blog-item-v mb-30">
              <div className="blog-img">
                <a href={articles[2].link}><img src={articles[2].imageSrc} alt={articles[2].title} /></a>
              </div>
              <div className="blog-content">
                <div className="blog-content-meta">
                  <span><i className="fa-thin fa-user"></i>{articles[2].author}</span>
                  <span><i className="fa-thin fa-clock"></i>{articles[2].date}</span>
                </div>
                <h5 className="blog-content-title"><a href={articles[2].link}>{articles[2].title}</a></h5>
                <p className="blog-excerpt">{articles[2].content.split('.')[0]}.</p> {/* Affiche la première phrase comme extrait */}
                <a href="#" className="theme-btn blog-btn t-theme-btn">Lire Plus</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BlogArea;
