import React, { useState, useEffect, useRef } from 'react';

import SliderComponent from "../Home/SliderComponent";
import Footer from "../Home/Footer";
import BlogArea from "../Home/BlogArea";
import BrandArea from "../Home/BrandArea";
import CategorySlider from "../Home/CategorySlider";
import AboutSection from "../Home/AboutSection";
import CourseList from "../Home/CourseList";
import TestimonialArea from "../Home/TestimonialArea";
import EventCarousel from "../Home/EventCarousel";
import '../Home/main.css';
import Header from '../Home/Header';
import { AnneeService } from '../../service/AnneeService';



const Accueil = () => {

    const images = [
        { id: 1, STR_SLIDERIMAGE: 'banner03.jpg' },
        { id: 2, STR_SLIDERIMAGE: 'banner04.jpg' },
        { id: 3, STR_SLIDERIMAGE: 'banner03.jpg' },
      ];

    return (
        <div  id="HomePage">
            <Header />
            <div>

                <div className='container homeslider-section'>
                    <SliderComponent sliderData={images} numberSlidesToShow={1} /> 
                </div>

                <CategorySlider />

                <AboutSection/>

                <CourseList/>

                <EventCarousel />
                
                <TestimonialArea/>

                <BlogArea />

                <BrandArea />

               
            </div>

            <Footer className="mt-10" />
        </div>
    )
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

//export default React.memo(PageAccueil, comparisonFn);
export default Accueil;
