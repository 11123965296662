import axios from 'axios';
import { BaseService } from './BaseService';


export class ActiviteService extends BaseService {
    getList(){
        return axios.get(this.getHost()+'activite/list').then(res => res.data);
    }
    getListByEcole(ecole){
        return axios.get(this.getHost()+'activite/list-by-ecole/'+ecole).then(res => res.data);
    }
    getByClasseAndJour(annee, classe, jour){
        return axios.get(this.getHost()+'activite/list-by-classe-jour?annee='+annee+'&classe='+classe+'&jour='+jour).then(res => res.data);
    }

    isPlageHoraireValid(annee, classe, jour, heureDeb, heureFin){
        return axios.get(this.getHost()+'activite/is-plage-horaire-valid?annee='+annee+'&classe='+classe+'&jour='+jour+'&heureDeb='+heureDeb+'&heureFin='+heureFin).then(res => res.data);
    }
    

    save(activite){
        axios.post(this.getHost()+'activite/save',activite);
    }

    update(activite){
       return axios.post(this.getHost()+'activite/update',activite).then(response => response.data);
    }

    updateAndDisplay(activite){
        return axios.post(this.getHost()+'activite/update-display',activite).then(res => res.data);
     }
     
    saveAndDisplay(activite){
        return axios.post(this.getHost()+'activite/saveAndDisplay',activite).then(res => res.data);        
    }


    delete(id){
        return axios.delete(this.getHost()+'activite/delete/'+id);
    }
}
 