import axios from 'axios';
import { BaseService } from './BaseService';

const ecoleId = sessionStorage.getItem('EcoleID');

export class SeanceService extends BaseService{

    getList() {
        return axios.get(this.getHost() + 'seances/list').then(res => res.data);
    }

    getListByEcole() {
        return axios.get(this.getHost() + 'seances/list').then(res => res.data);
    }

    getListByDate(date) {
        return axios.get(this.getHost() + 'seances/get-list-date?date=' + date+'&ecole='+ecoleId).then(res => res.data);
    }

    getDistinctListByDate(date, ecole) {
        return axios.get(this.getHost() + 'seances/get-distinct-list-date?date=' + date+'&ecole='+ecole).then(res => res.data);
    }

    getDistinctListByDateAndClasse(date, classeId) {
        return axios.get(this.getHost() + 'seances/get-distinct-list-date-classe?date=' + date+'&classe='+classeId).then(res => res.data);
    }

    getListByDateAndClasse(date, classe) {
        return axios.get(this.getHost() + 'seances/get-list-date-classe?date=' + date + '&classe=' + classe).then(res => res.data);
    }

    getListByDateAndProf(date, prof) {
        // console.log(date);
        return axios.get(this.getHost() + 'seances/get-list-date-prof?date=' + date + '&prof=' + prof).then(res => res.data);
    }

    getListByDateAndClasseAndStatut(date, classe,statut) {
        return axios.get(this.getHost() + 'seances/get-list-date-classe-statut?date=' + date + '&classe=' + classe+'&statut='+statut).then(res => res.data);
    }

    getListStatut(annee, statut) {
        return axios.get(this.getHost() + 'seances/get-list-statut?annee=' + annee+'&statut='+statut+'&ecole='+ecoleId).then(res => res.data);
    }

    generateSeances(date, classeId, anneeId) {
        if (!classeId) {
            return axios.get(this.getHost() + 'seances/generate-seances?date=' + date+'&ecole='+ecoleId+'&annee='+anneeId).then(res => res.data);
        } else
            return axios.get(this.getHost() + 'seances/generate-seances?date=' + date + '&classe=' + classeId+'&annee='+anneeId).then(res => res.data);
    }

    updateAndDisplay(seance){
        console.log(seance);
        return axios.post(this.getHost()+'seances/update-display',seance).then(res => res.data);
     }
     
    saveAndDisplay(seance){
        return axios.post(this.getHost()+'seances/saveAndDisplay',seance).then(res => res.data);        
    }

    isPlageHoraireValid(annee, classe, jour, dateSeance, heureDeb, heureFin){
        return axios.get(this.getHost()+'seances/is-plage-horaire-valid?annee='+annee+'&classe='+classe+'&jour='+jour+'&date='+dateSeance.toISOString().split('T')[0]+'&heureDeb='+heureDeb+'&heureFin='+heureFin).then(res => res.data);
    }

    delete(id){
        return axios.delete(this.getHost()+'seances/delete/'+id);
    }
}
