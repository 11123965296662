import React, { useState, useEffect, useRef } from 'react';
// eslint-disable-next-line
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { AnneeService } from '../service/AnneeService';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Tag } from 'primereact/tag';
import { RadioButton } from 'primereact/radiobutton';
import './css/ButtonDemo.css';
import { PeriodiciteService } from '../service/PeriodiciteService';
import { NiveauEnseignementService } from '../service/NiveauEnseignementService';
import { Calendar } from 'primereact/calendar';
import { PeriodeService } from '../service/PeriodeService';
import 'primereact/resources/themes/saga-blue/theme.css';
// import { BiBroadcast } from "react-icons/bi";
// import { AiOutlineMinus } from "react-icons/ai";
// import { GiOpenGate } from "react-icons/gi"
import { BiWindowOpen } from "react-icons/bi";
import { FaLockOpen } from "react-icons/fa";
import { HiLockClosed } from "react-icons/hi";
import { IoMdWarning } from "react-icons/io";
import PuffLoader from "react-spinners/PuffLoader";



// import { ProgressBar } from 'primereact/progressbar';


const OpenAnnee = () => {

    const [annee, setAnnee] = useState();
    const [annees, setAnnees] = useState([]);
    const [globalFilter, setGlobalFilter] = useState(null);
    const dt = useRef(null);
    const toast = useRef(null);
    const [submitted, setSubmitted] = useState(false);
    const [anneeDialog, setAnneeDialog] = useState(false);
    const [headerTitle, setHeaderTitle] = useState("");
    const [anneeToCloture, setAnneeToCloture] = useState(null)
    const [anneeToOpenOrClose, setAnneeToOpenOrClose] = useState({});
    const [anneeOuverte, setAnneeOuverte] = useState({});
    const [niveauxEnseignement, setNiveauxEnseignement] = useState([]);
    const [periodicites, setPeriodicites] = useState([]);
    const [periodes, setPeriodes] = useState([]);
    const [selectedPeriodicite, setSelectedPeriodicite] = useState({ id: 2 });
    const [marqueurIndex, setMarqueurIndex] = useState(0);
    const [datasList, setDatasList] = useState([]);
    const [myArrayDeb, setMyArrayDeb] = useState(new Array(12).fill(null));
    const [myArrayFin, setMyArrayFin] = useState(new Array(12).fill(null));
    const [myArrayLim, setMyArrayLim] = useState(new Array(12).fill(null));
    const [myArrayNbEval, setMyArrayNbEval] = useState(new Array(12).fill(null));
    const [clotureSpinner, setClotureSpinner] = useState(false);
    const [typeAction, setTypeAction] = useState('new');
    const [clotureDialog, setClotureDialog] = useState(false);
    const [openShareConfirmDialog, setOpenShareConfirmDialog] = useState(false);
    const [flatIsOuvert, setFlatIsOuvert] = useState(false);
    const [delayValue, setDelayValue] = useState(0);


    useEffect(() => {
        (async () => {

            const anneeService = new AnneeService();
            const _annees = await anneeService.getListToEcole(sessionStorage.getItem('EcoleID'));
            setAnnees(_annees);
            if (_annees != null && _annees.length > 0) {
                _annees.forEach((elmt) => {
                    // console.log(elmt);
                    if (elmt.statut === 'OUVERT') {
                        console.log('coucou');
                        setAnneeOuverte(elmt);
                        setFlatIsOuvert(true);
                    }
                });
            }
        })();
        console.log('Use effect method trigged');
        // console.log(infosAnnee);
    }, [annee]);

    const onInputChange = (e, name) => {
        // console.log(e)
        let val = (e.target && e.target.value) || '';
        if (val === '' && (name === 'anneeDebut' || name === 'nbreEval'))
            val = 0;
        let _annee = { ...annee };
        // console.log(typeof (val));
        // console.log(val);
        _annee[`${name}`] = parseInt(val);
        if (name === 'anneeDebut' && val)
            _annee.anneeFin = parseInt(val) + 1;

        console.log(_annee);

        setAnnee(_annee);
    }

    const onInputArrayChange = (e, name) => {
        //let [typeIndex, valIndex] = e.target.id.split('_');
        console.log(e.target);
        let obj = {
            id: e.target.id,
            nbEval: e.target.value
        };

        let index = -1;
        let [typeIndex, valIndex] = e.target.id.split('_');

        if (typeIndex === 'nbeval') {
            let _myArrayNbEval = { ...myArrayNbEval };
            console.log(_myArrayNbEval);
            _myArrayNbEval[valIndex] = e.target.value;
            console.log(_myArrayNbEval);
            setMyArrayNbEval(_myArrayNbEval);
        }

        datasList.forEach((elmt, idx) => {
            if (elmt.id === obj.id)
                index = idx;
        })

        if (index === -1) {
            console.log('new');
            datasList.push(obj)
        } else {
            console.log('update');
            datasList[index] = obj;
        }
    }

    const onSelectChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _annee = { ...annee };
        _annee[`${name}`] = { id: val };
        // console.log(_annee);
        setAnnee(_annee);
    }
    const hideDialog = () => {
        setSubmitted(false);
        setAnneeDialog(false);
        initVariables();
    }

    const initVariables = () => {
        setDatasList([]);
        setMyArrayDeb(new Array(12).fill(null));
        setMyArrayFin(new Array(12).fill(null));
        setMyArrayLim(new Array(12).fill(null));
        setMyArrayNbEval(new Array(12).fill(null));
        setSelectedPeriodicite({ id: 2 });
        setAnnee({});
    }

    const buildDateArrays = async (tab) => {
        let _debArray = [];
        let _finArray = [];
        let _limiteArray = [];
        let _nbEvalArray = [];
        if (tab !== null && tab.length > 0) {
            for (let index = 0; index < tab.length; index++) {
                if (tab[index].id != null && tab[index].id.includes('deb')) {
                    let date = new Date(tab[index].value.split('Z')[0]);
                    _debArray[tab[index].id.split('_')[1]] = date;
                }
                if (tab[index].id != null && tab[index].id.includes('fin')) {
                    let date = new Date(tab[index].value.split('Z')[0]);
                    _finArray[tab[index].id.split('_')[1]] = date;
                }
                if (tab[index].id != null && tab[index].id.includes('limite')) {
                    let date = new Date(tab[index].value.split('Z')[0]);
                    _limiteArray[tab[index].id.split('_')[1]] = date;
                }
                if (tab[index].id != null && tab[index].id.includes('nbeval')) {
                    _nbEvalArray[tab[index].id.split('_')[1]] = tab[index].nbEval;
                }
            }
            setMyArrayDeb(_debArray);
            setMyArrayFin(_finArray);
            setMyArrayLim(_limiteArray);
            setMyArrayNbEval(_nbEvalArray);
        }
    }

    const edit = async (rowData) => {
        console.log(rowData);
        setSubmitted(false);
        setAnneeDialog(true);
        setTypeAction('update');
        const periodiciteService = new PeriodiciteService();
        const periodeService = new PeriodeService();
        const periodicitesList = await periodiciteService.getList();
        const niveauService = new NiveauEnseignementService();
        setPeriodicites(periodicitesList);
        niveauService.getList().then((res) => {
            setNiveauxEnseignement(res);
        })

        const periodesList = await periodeService.getByPeriodicite(rowData.periodicite.id);
        const newMarqueurIndex = periodesList.findIndex(objet => objet.isfinal === 'O');
        setMarqueurIndex(newMarqueurIndex === -1 ? periodesList.length : newMarqueurIndex);
        setPeriodes(periodesList);
        setSelectedPeriodicite(rowData.periodicite);
        setAnnee(rowData);
        console.log('rowData.delaiNotes '+rowData.delaiNotes)
        setDelayValue(rowData.delaiNotes);
        setHeaderTitle("Parametrer une année scolaire");
        await buildDateArrays(rowData.anneePeriodes);
        setDatasList(rowData.anneePeriodes);

    }

    async function beforeSave() {
        let _annee = { ...annee };
        _annee.anneePeriodes = datasList;
        _annee.niveau = 'ECOLE';
        _annee.user = sessionStorage.getItem('idUser');
        _annee.delaiNotes = delayValue;
        // console.log(_annee);
        // bonne pratique de mise a jour
        setAnnee(prevAnnee => ({ ...prevAnnee, ..._annee }));
        return _annee;
    }

    const reactfindIndexById = (id, tab) => {
        let index = -1;
        for (let i = 0; i < tab.length; i++) {
            if (tab[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    const open = () => {
        if (annee.anneeDebut != null && annee.niveauEnseignement?.id != null && annee.nbreEval != null && annee.periodicite?.id != null && datasList.length == ((marqueurIndex + 1) * 4)) {
            setOpenShareConfirmDialog(true);
            setAnneeToOpenOrClose(annee);
            setAnneeDialog(false);
        }
    }
    const save = async (typeAction) => {
        let anneeService = new AnneeService();
        setSubmitted(true);
        // console.log(marqueurIndex);
        // console.log((marqueurIndex+1) * 2);
        // console.log(datasList.length);
        // console.log(annee.anneeDebut != null);
        // console.log(annee.niveauEnseignement?.id !=null);
        // console.log(annee.nbreEval!=null);
        // console.log(annee.periodicite?.id !=null);
        if (annee.anneeDebut != null && annee.niveauEnseignement?.id != null && annee.nbreEval != null && annee.periodicite?.id != null && datasList.length == ((marqueurIndex + 1) * 4)) {
            
            let finalAnnee = await beforeSave();
            console.log(finalAnnee);
            anneeService.save(finalAnnee).then((res) => {
                console.log(typeAction);
                // console.log(res);
                let _annees = [...annees];
                if (typeAction === 'new') {
                    _annees.push(res);
                } else if (typeAction === 'update') {
                    const index = reactfindIndexById(finalAnnee.id, _annees);
                    _annees[index] = res;
                }
                setAnnees(_annees);
                hideDialog();
                toast.current.show({ severity: 'success', summary: 'Succes', detail: typeAction === 'new' ? 'Nouvelle année Créee' : 'Année modifiée', life: 3000 });
            }).catch((error) => {
                // console.log('EREUR ::: ' + error.message);
                hideDialog();
                toast.current.show({ severity: 'error', summary: 'erreur', detail: error.message, life: 3000 });
            });

        }
    }


    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h3 className="m-0">Liste des années scolaires </h3>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Recherche..." />
            </span>
        </div>
    );

    const anneeDialogFooter = (
        <>
            <div className='border-3 surface-border p-2'>
                {/* {console.log(anneeOuverte)} */}
                <Button label="Annuler" icon="pi pi-times" className="p-button-outlined" onClick={hideDialog} />
                <Button label="Enregistrer" icon="pi pi-check" disabled={annee?.statut === 'CLOTURE' ? true : false} className="p-button-outlined p-button-success mr-5" onClick={() => save('new')} />
                <Button label="Ouvrir Année" icon="pi pi-check" className={(annee?.statut === 'DIFFUSE' && Object.keys(anneeOuverte).length === 0) ? "p-button-danger mx-5" : 'hidden'} onClick={open} />
            </div>
        </>
    );

    const cloture = () => {
        setClotureDialog(true)
        // setAnneeToDelete(ap);
    }

    const actionButtons = (rowData) => {
        return (
            <div className="button-demo">
                <div>
                    <Button title="Details" icon="pi pi-pencil" onClick={() => edit(rowData)} className="p-button-sm p-button-warning" />

                </div>
            </div >
        )
    }
    const getStatutAttr = (status) => {
        let attr = {};
        if (status === 'DIFFUSE') {
            attr.severity = 'primary';
            attr.info = 'EN ATTENTE';
        } else if (status === 'OUVERT') {
            attr.severity = 'success';
            attr.info = status;
        } else if (status === 'CLOTURE') {
            attr.severity = 'danger';
            attr.info = status;
        }
        return attr;
    }

    const displayStatut = (rowData) => {
        return (
            < Tag className='px-3 py-2' severity={getStatutAttr(rowData.statut).severity} value={getStatutAttr(rowData.statut).info} ></Tag >
        )
    }
    const onChangePeriodicite = async (periodicite) => {
        const periodeService = new PeriodeService();
        let _annee = { ...annee };
        _annee.periodicite = { ...periodicite };
        setAnnee(_annee);
        setSelectedPeriodicite(periodicite);
        const periodesList = await periodeService.getByPeriodicite(periodicite.id);
        const newMarqueurIndex = periodesList.findIndex(objet => objet.isfinal === 'O');
        setMarqueurIndex(newMarqueurIndex === -1 ? periodesList.length : newMarqueurIndex);
        setPeriodes(periodesList);
        setDatasList([]);
        setMyArrayDeb(new Array(12).fill(null));
        setMyArrayFin(new Array(12).fill(null));
    }

    const clotureProcess = () => {
        const anneeService = new AnneeService();
        hideClotureDialog();
        setClotureSpinner(true);
        anneeService.cloturer(anneeOuverte).then(res => {
            console.log(res);
            let _annees = [...annees];
            // _annees.push(res);
            let index = reactfindIndexById(res.id, _annees);
            _annees[index] = res;
            setAnnees(_annees);
            setAnneeOuverte({});
            setFlatIsOuvert(false);

            setClotureSpinner(false);
            toast.current.show({ severity: 'success', summary: 'Succès', detail: 'Année cloturée', life: 6000 });

        }).catch((error) => {
            if (error.response) {
                console.log('erreur response- Pouls scolaire' + error.response.status);
            } else if (error.request) {
                console.log('erreur request- Pouls scolaire' + error.request);
            } else {
                console.log('Error autres', error.message);
            }
            setClotureSpinner(false);
            toast.current.show({ severity: 'error', summary: 'erreur', detail: error.message, life: 3000 });
        });

    }

    const headerBlock = () => {
        return (
            <div className='m-3 text-2xl pl-2 py-3 surface-300'>
                {headerTitle}
            </div>
        )
    }
    const hideClotureDialog = () => {
        setAnneeToCloture(null);
        setClotureDialog(false);
    }
    const hideShareDialog = () => {
        setAnneeToOpenOrClose({});
        setOpenShareConfirmDialog(false);
    }

    const activeProgressBar = async () => {
        let index = reactfindIndexById(anneeToOpenOrClose.id, annees);
        let _annees = [...annees];

        if (index !== -1) {
            if (_annees[index]?.hide !== 'O')
                _annees[index].hide = 'O';
            else
                _annees[index].hide = '';
            setAnnees(_annees);
            console.log('_annees');
            console.log(_annees);
        }
        hideShareDialog();
    }

    const openProcess = async () => {
        const anneeService = new AnneeService();
        anneeService.open(anneeToOpenOrClose).then(res => {
            let _annees = [...annees];
            // _annees.push(res);
            let index = reactfindIndexById(res.id, _annees);
            _annees[index] = res;
            setAnnees(_annees);
            setAnneeOuverte(res);
            setFlatIsOuvert(true);
            hideShareDialog();
            toast.current.show({ severity: 'success', summary: 'Succes', detail: res?.libelle + ' Ouverte', life: 6000 });
        }).catch((error) => {
            if (error.response) {
                console.log('erreur response- Pouls scolaire' + error.response.status);
            } else if (error.request) {
                console.log('erreur request- Pouls scolaire' + error.request);
            } else {
                console.log('Error autres', error.message);
            }
            toast.current.show({ severity: 'error', summary: 'erreur', detail: error.message, life: 3000 });
        });
    }

    const clotureDialogFooter = (
        <>
            <Button label="Annuler" icon="pi pi-times" className="p-button-outlined " onClick={hideClotureDialog} />
            <Button label="Cloturer" icon="pi pi-check" className="p-button-danger mr-5" onClick={clotureProcess} />
        </>
    );

    const shareDialogFooter = (rowData) => {
        return (
            <>
                <Button label="Annuler" icon="pi pi-times" className="p-button-outlined p-button-warning" onClick={hideShareDialog} />
                <Button label="OUVRIR ANNEE" icon={<BiWindowOpen className='mx-2' size='1.2em' />} className="p-button-success" onClick={openProcess}></Button>
            </>)
    };
    const headerDialog = () => {
        return (
            <div className='border-bottom-2 font-bold text-2xl py-1 surface-200'>
                Confirmation
            </div>
        )
    }

    const onCalendarChange = (e) => {
        let calendar = {
            id: e.target.id,
            value: e.target.value
        };
        let index = -1;
        let [typeIndex, valIndex] = e.target.id.split('_');
        // stockage des valeurs dans les constantes dédié pour conserver la valeur à l'écran dans le calendrier
        if (typeIndex === 'deb') {
            let _myArrayDeb = { ...myArrayDeb };
            _myArrayDeb[valIndex] = e.target.value;
            setMyArrayDeb(_myArrayDeb);
        }

        if (typeIndex === 'fin') {
            let _myArrayFin = { ...myArrayFin };
            _myArrayFin[valIndex] = e.target.value;
            setMyArrayFin(_myArrayFin);
        }

        if (typeIndex === 'limite') {
            let _myArrayLim = { ...myArrayLim };
            _myArrayLim[valIndex] = e.target.value;
            setMyArrayLim(_myArrayLim);
        }

        if (typeIndex === 'nbeval') {
            let _myArrayNbEval = { ...myArrayNbEval };
            _myArrayNbEval[valIndex] = e.target.value;
            setMyArrayNbEval(_myArrayNbEval);
        }

        datasList.forEach((elmt, idx) => {
            if (elmt.id === calendar.id)
                index = idx;
        })

        if (index === -1) {
            console.log('new');
            datasList.push(calendar)
        } else {
            console.log('update');
            datasList[index] = calendar;
        }
        // console.log(calendarList);

    };

    const saisieChange = (e) => {
        console.log(e)
        setDelayValue(e.value);
    }

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <div style={{ backgroundColor: anneeOuverte.libelle == null ? '#37b500' : '#CC0000' }} className={(flatIsOuvert ? '' : 'hidden') + ' card bg-green-600 border-3 m-2'}>
                        <div className='col-12 grid font-bold text-white text-2xl'>
                            <div className='col-1'>
                                <FaLockOpen className='m-2' size={'3em'} color='white' />
                            </div>
                            <div className='col-10'>
                                <div className='py-3'>Année en cours :  </div>
                                <div><span className='vertical-align-middle text-5xl'>{anneeOuverte.libelle}</span> </div>

                            </div>
                            <div style={{ backgroundColor: '#e3f8db' }} className='col-12 border-1 border-round text-right'>
                                <Button className='p-button-danger' label='CLOTURER ANNEE' icon={<HiLockClosed className='mr-2' size='1.2em' />} onClick={() => cloture()} />
                            </div>
                        </div>
                    </div>

                    <div style={{ backgroundColor: anneeOuverte.libelle == null ? '#CC0000' : '#51e90e' }} className={(!flatIsOuvert ? '' : 'hidden') + ' grid card bg-red-600 border-3 m-2'}>
                        <div className='col-1'>
                            <HiLockClosed className='m-2' size={'3em'} color='white' />
                        </div>
                        <div className='col-9 font-bold text-white text-4xl'>
                            <div>Aucune Année ouverte!</div>
                            <div className='text-sm font-italic'>Veuillez proceder à l'ouverture d'une nouvelle année</div>
                        </div>

                    </div>

                    <DataTable ref={dt} value={annees}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} header={header} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="affichage de {first} à {last} sur {totalRecords} notes"
                        globalFilter={globalFilter} emptyMessage="Aucun enregistrement trouvé." responsiveLayout="scroll">
                        {/* <Column header="Niveau Enseignement" field='niveauEnseignement.libelle' headerStyle={{ Width: '1rem' }}></Column> */}
                        <Column header="Libellé" field='libelle' headerStyle={{ minWidth: '5rem' }}></Column>
                        <Column header="Périodicité" field='periodicite.libelle' headerStyle={{ Width: '1rem' }}></Column>
                        <Column header="Statut" headerStyle={{ Width: '1rem' }} body={displayStatut}></Column>
                        <Column header="Action" headerStyle={{ Width: '1rem' }} body={actionButtons}></Column>
                    </DataTable>

                    <Dialog visible={anneeDialog} style={{ width: '65%' }} scrollable scrollHeight="400px" header={headerBlock} modal className="p-fluid shadow-2" footer={anneeDialogFooter} onHide={hideDialog}>
                        <div className="ml-3 font-bold text-2xl grid col-11 p-fluid flex align-items-center justify-content-center">
                            <div className='border-1 py-2 px-5'>
                                {annee?.libelle}
                            </div>
                        </div>

                        <div className='grid hidden surface-100 fluid formgrid align-items-center justify-content-center '>
                            <div className="field col-2 mt-2 ">
                                <label className='pt-2' ><b>P&eacute;riodicit&eacute;</b></label>
                            </div>
                            <div className='grid fluid formgrid align-items-center justify-content-center'>
                                {periodicites.map((obj, index) => {
                                    return (
                                        <div key={index} className="p-field-radiobutton mx-2">
                                            <RadioButton inputId={obj.id} name="periodicite" value={obj} onChange={(e) => onChangePeriodicite(e.value)} checked={selectedPeriodicite.id === obj.id} />
                                            <label htmlFor={obj.id}>{obj.libelle}</label>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className='grid  col-12'>
                            <div className='grid col-12 my-0 '>
                                <div className='col-offset-1 col-2 font-bold surface-300 vertical-align-middle text-center'>Période</div>
                                <div className='col-2 font-bold surface-300 vertical-align-middle text-center'>Date Debut </div>
                                <div className='col-2 font-bold surface-300 vertical-align-middle text-center'>Date Fin</div>
                                <div className='col-2 font-bold surface-300 vertical-align-middle text-center'>Date Limite</div>
                                <div className='col-2 font-bold surface-300 vertical-align-middle text-center'>Max Evaluations</div>
                            </div>
                            {
                                periodes.filter((obj, indx) => indx <= marqueurIndex).map((per, index) => {
                                    return (
                                        <div key={per.id} className='grid col-12 my-0 '>
                                            <div className='col-offset-1 col-2 py-3 surface-100'>{per.libelle}</div>
                                            <div className='col-2 surface-100'><Calendar disabled={annee?.statut === 'CLOTURE' ? true : false} id={'deb_' + per.id} value={myArrayDeb[per.id]} dateFormat="dd/mm/yy" onChange={(e) => onCalendarChange(e)} className={classNames({ 'p-invalid': submitted && !(myArrayDeb[per.id]) })} showIcon></Calendar> </div>
                                            <div className='col-2 surface-100'><Calendar disabled={annee?.statut === 'CLOTURE' ? true : false} id={'fin_' + per.id} value={myArrayFin[per.id]} dateFormat="dd/mm/yy" onChange={(e) => onCalendarChange(e)} className={classNames({ 'p-invalid': submitted && !(myArrayFin[per.id]) })} showIcon></Calendar></div>
                                            <div className='col-2 surface-100'><Calendar disabled={annee?.statut === 'CLOTURE' ? true : false} id={'limite_' + per.id} value={myArrayLim[per.id]} dateFormat="dd/mm/yy" onChange={(e) => onCalendarChange(e)} className={classNames({ 'p-invalid': submitted && !(myArrayLim[per.id]) })} showIcon></Calendar></div>
                                            <div className='col-2 surface-100 text-center'><InputNumber disabled={annee?.statut === 'CLOTURE' ? true : false} min={1} max={9} value={myArrayNbEval[per.id]} onValueChange={(e) => onInputArrayChange(e, 'nbeval')} id={'nbeval_' + per.id} style={{ width: '60%' }} className={classNames({ 'p-invalid': submitted && myArrayNbEval[per.id] })} /></div>
                                        </div>
                                    )
                                })}
                            <div className='grid col-12 my-0 '>

                                <div className='col-10 col-offset-1 surface-100' ><b className=' px-3 py-1'>+Evaluations</b></div>
                                <div className='col-10  grid '>
                                    <div className='flex align-items-center col-offset-2 col-3'>
                                        <label htmlFor='delay'>Délai de saisie des notes</label>
                                    </div>
                                    <div className='col-2 p-0 m-0'>
                                        <InputNumber className='col-2' value={delayValue} id='delay' min={1} max={120} suffix=' Jour(s)' onValueChange={(e) => saisieChange(e)} ></InputNumber>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </Dialog>

                    <Dialog visible={clotureDialog} style={{ width: '1000px' }} header={headerDialog} modal footer={clotureDialogFooter} onHide={hideClotureDialog}>
                        <div className="flex align-items-center justify-content-center text-2xl m-2">

                            {anneeOuverte && <span className='font-semi-bold'>Vous souhaitez <span className='font-bold'>cloturer </span>l&apos; <b>{anneeOuverte.libelle} </b>?</span>}
                        </div>

                        <div className="flex align-items-center justify-content-center">
                            <div className='border-2 px-2 surface-border'>
                                <IoMdWarning size='5em' color='red' />
                            </div>
                            <div className='text-xl text-center text-white font-bold px-5' style={{ backgroundColor: '#EF4444' }}>
                                <p className='my-0'>ATTENTION LE PROCESSUS DE CLOTURE EST IRREVERSIBLE!</p>
                                <p className='my-0'>Vous ne pourrai plus effectuer des modifications dans le système pour l&apos; année à cloturer</p>
                                <p className='my-0'> Veuillez confirmer votre choix en cliquant sur le bouton &laquo;Cloturer&raquo;</p>
                            </div>

                        </div>
                    </Dialog>

                    <Dialog visible={openShareConfirmDialog} style={{ width: '700px' }} header={headerDialog} modal footer={shareDialogFooter} onHide={hideShareDialog}>
                        <div className="text-center text-2xl mx-3 justify-content-center">

                            <i className='mr-3'><BiWindowOpen color='green' size='2em' /></i> {anneeToOpenOrClose && <span>Vous vous apprêtez à <span className='font-bold'>ouvrir</span> l&apos; <b>{anneeToOpenOrClose.libelle} </b>, En êtes-vous sûr?</span>}
                        </div>

                        {/* <div className='flex align-items-center justify-content-center mt-3 text-center font-semibold'><i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} /><Tag severity='danger' className='p-2 text-lg'>Vous ne pourrai plus y apporter de modification une fois diffusée</Tag></div> */}
                    </Dialog>

                    <Dialog visible={clotureSpinner} closable={false} showHeader={false} modal >

                        <PuffLoader
                            color="#ff4444"
                            loading={true}

                            size={150}

                        />
                        <p className='font-italic font-bold'>Veuillez patienter ...</p>

                    </Dialog>

                </div>
            </div>
        </div>
    )
}
const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(OpenAnnee, comparisonFn);