import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import { PersonnelMatiereClasseService } from '../service/PersonnelMatiereClasseService';
import { PeriodeService } from '../service/PeriodeService';
import { NoteService } from '../service/NoteService';
import { ClasseMatierePeriodeService } from '../service/ClasseMatierePeriodeService';
import { Checkbox } from 'primereact/checkbox';
import { ClasseEleveMatiereService } from '../service/ClasseEleveMatiereService';
import {InputText} from "primereact/inputtext";
import {ClasseService} from "../service/ClasseService";


const MoyennesMatiereParent = () => {

    let emptyClasse = {
        id: null,
        libelle: ''
    }

    let emptyPeriode = {
        id: null
    }

    let emptyMatiere = {
        id: null
    }

    const toast = useRef(null);

    const [loading, setLoading] = useState(true);

    const [globalFilter, setGlobalFilter] = useState(null);

    const dt = useRef(null);

    const [matiere, setMatiere] = useState(emptyMatiere);

    const [matieres, setMatieres] = useState([]);

    const [periode, setPeriode] = useState(emptyPeriode);

    const [periodes, setPeriodes] = useState([]);

    const [classes, setClasses] = useState([]);

    const [classe, setClasse] = useState(emptyClasse);

    const [submitted, setSubmitted] = useState(null);

    const [searchBtnLbl, setSearchBtnLbl] = useState("Rechercher");

    const [isSearching, setIsSearching] = useState(false);

    const [isNotSpinning, setIsNotSpinning] = useState('hidden');

    const userId = sessionStorage.getItem('CandidatId');

    const annee = sessionStorage.getItem('AnneEncours');

    const [isClassedTab, setIsClassedTab] = useState([]);

    const [moyenneEleves, setMoyenneEleves] = useState(null);

    const [saveFlag, setSaveFlag] = useState(true);

    const [hiddenFlatUpdate, setHiddenFlatUpdate] = useState(true);
    const [matricule, setmatricule] = useState('');


    useEffect(() => {
        const classeService = new ClasseService();
        const periodeService = new PeriodeService();
        periodeService.getByPeriodicite(sessionStorage.getItem('periodiciteId')).then(res => setPeriodes(res));

        classeService.getListByEcole(sessionStorage.getItem("EcoleID")).then(data => setClasses(data));

    }, []);

    useEffect(() => {
        if(classe!=null) {
            const classeService = new ClasseService();
            classeService.getListMatierByEcoleClasseWeb(sessionStorage.getItem("EcoleID"),classe.id).then(data => setMatieres(data));

        }

    }, [classe]);

    const setCheckedIsClassed = (rowData, val) => {
        let _isClassedTab = [...isClassedTab];
        let _rankObj = { ..._isClassedTab[rowData.getIndex] };
        let classeEleveMatiereService = new ClasseEleveMatiereService();
        val == false ? _rankObj.isClassed = 'N' : _rankObj.isClassed = 'O';

        //Effectuer l'enregistrement après chaque action de l'état de classement de l'élève
        classeEleveMatiereService.marquageClassement(_rankObj).then(res => {
            console.log(res);
            // _rankObj.isClassed = res;
            _isClassedTab[rowData.getIndex] = _rankObj;
            setIsClassedTab(_isClassedTab);
            // setSaveFlag(true);
            setSearchBtnLbl("Actualiser");
            // setHiddenFlatUpdate(false);

        }).catch((error) => {
            console.log("except");
            console.log(error.response.data);
            toast.current.show({ severity: 'error', summary: 'erreur', detail: error.response.data, life: 5000 });
        }).finally(()=>{
            setSaveFlag(true);
            setHiddenFlatUpdate(false);
        })
    }
;
    const onSelectClasseChange = (e, name) => {
        const persMatClasService = new PersonnelMatiereClasseService();
        let classeId = (e.target && e.target.value) || '';
        let _classe = { ...classe };

        if (name !== null)
            _classe[`${name}`].id = classeId;
        else
            _classe.id = classeId;
        setClasse(_classe);

        // console.log('classe id :' + classeId);
        persMatClasService.getByProfesseurAndClasse(userId, classeId, annee).then(res => {
            let matiereCtrl = [];
            let _matieres = []
            console.log(res);
            for (let i = 0; i < res.length; i++) {
                if (!matiereCtrl.includes(res[i].matiere.id)) {
                    matiereCtrl.push(res[i].matiere.id)
                    _matieres.push(res[i].matiere)
                }
            }
            setMatieres(_matieres);
        })

    }

    const onSelectPeriodeChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _periode = { ...periode };
        if (name !== null)
            _periode[`${name}`].id = val;
        else
            _periode.id = val;
        setPeriode(_periode);
    }

    const onSelectMatiereChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _matiere = { ...matiere };
        if (name !== null)
            _matiere[`${name}`].id = val;
        else
            _matiere.id = val;
        setMatiere(_matiere);
    }

    const search = () => {
        if(matricule){

            setSubmitted(true);
            let _isClassedTab = [];
            if (classe.id && periode.id && matiere.id) {
                setIsSearching(true);
                setIsNotSpinning('');
                let noteService = new NoteService();
                noteService.getMoyennesMatiereAndNotesMatricule(matricule, matiere.id, annee, periode.id).then(res => {
                        // setIsClassedTab([]);
                        console.log(res)
                        setMoyenneEleves(res);
                        for (let index = 0; index < res.length; index++) {
                            let isClassedObj = {
                                eleve: res[index].eleve,
                                periode: res[index].periode,
                                classe: res[index].classe,
                                annee: res[index].annee,
                                isClassed: res[index].isClassed,
                                matiere: matiere
                            }
                            _isClassedTab.push(isClassedObj);
                        }
                        console.log(_isClassedTab);
                        setIsClassedTab(_isClassedTab);
                        // console.log(isRankedTab);
                        setSearchBtnLbl("Rechercher");
                        setLoading(false);
                        if (res.length == 0) {
                            setSaveFlag(true);
                            toast.current.show({ severity: 'warn', summary: 'Aucune donnée trouvée', detail: '', life: 10000 });
                        } else
                            setSaveFlag(false);

                        setIsSearching(false);
                        setIsNotSpinning('hidden');
                        setHiddenFlatUpdate(true);
                    }
                ).catch((error) => {
                    if (error.response) {
                        console.log('erreur response- pouls-scolaire' + error.response.status);
                    } else if (error.request) {
                        console.log('erreur request- pouls-scolaire' + error.request);
                    } else {
                        console.log('Error autres', error.message);
                    }
                    toast.current.show({ severity: 'error', summary: 'Erreur', detail: error.message, life: 10000 })
                    setIsSearching(false);
                    setIsNotSpinning('hidden');
                });
            }
        } else {
            toast.current.show({
                severity: 'error',
                summary: 'Error Message',
                detail: 'Veuillez renseigner les champs',
                life: 3000
            });
        }

    }

    const nomPrenoms = (rowData) => {
        return (
            <>

                {rowData.eleve.nom} {rowData.eleve.prenom}
            </>
        )
    }

    const noteBodyTemplate = (rowData) => {
        let notes = [];
        return (
            <div >
                {
                    rowData.notesMatiereMap[0].value.forEach(element => {
                        notes.push(element.note + '/' + element.evaluation.noteSur);
                    })
                }
                {notes.join(' | ')}
            </div>
        );
    }

    const showMoyenne = (rowData) => {
        return (
            <div>
                {rowData.notesMatiereMap[0].key.moyenne}
            </div>
        )
    }

    const showCoef = (rowData) => {
        return (
            <div>
                {rowData.notesMatiereMap[0].key.coef}
            </div>
        )
    }

    const calculMoyCoef = (rowData) => {
        return (
            <div>
                {(rowData.notesMatiereMap[0].key.moyenne * rowData.notesMatiereMap[0].key.coef).toFixed(2)}
            </div>
        )
    }

    const showRang = (rowData) => {
        return (
            <div>
                {rowData.isClassed === 'O' ? (rowData.notesMatiereMap[0].key.rang == '1' ? rowData.notesMatiereMap[0].key.rang+' er' : rowData.notesMatiereMap[0].key.rang + ' ème') : 'NC'}
            </div>
        )
    }
    const getIsRanked = (rowData) => {
        // console.log(isClassedTab);
        for (let index = 0; index < isClassedTab.length; index++) {
            if (rowData.eleve.id == isClassedTab[index].eleve.id) {
                // console.log (isRankedTab[index]);
                rowData.getIndex = index;
                return isClassedTab[index].isClassed;
            }
        }
    }

    const showIsClassed = (rowData) => {
        return (
            <div>
                {/* {rowData.notesMatiereMap[0].key.eleveMatiereIsClassed} */}
                <span>{getIsRanked(rowData) !== 'N' ? 'Oui' : 'Non'} </span>
                <Checkbox disabled={true} onChange={e => setCheckedIsClassed(rowData, e.checked)} checked={getIsRanked(rowData) !== 'N'}></Checkbox>
            </div>
        )
    }

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <div className="col-12">
                        <div className="formgroup-inline">
                            <div className="col-12">
                                <label><h3><b>Moyennes par matière</b></h3></label>
                            </div>
                        </div>
                        <div className="card grid">

                            <div className="col-12 md:col-3 lg:col-3 lg:mb-0">
                                <label className="mx-3" htmlFor="classe"><b>Classe</b></label>
                                <Dropdown id="classe" value={classe.id} onChange={(e) => onSelectClasseChange(e, null)} options={classes}
                                    required optionValue="id" optionLabel="libelle" placeholder="Selectionner la classe" className={classNames({ 'p-invalid': submitted && !(classe.id) })} />
                            </div>
                            <div className="col-12 md:col-3 lg:col-3 lg:mb-0">
                                <label className="mx-3" htmlFor="Période"><b>Période</b></label>
                                <Dropdown id="periode" value={periode.id} onChange={(e) => onSelectPeriodeChange(e, null)} options={periodes}
                                    required optionValue="id" optionLabel="libelle" placeholder="Selectionner la période" className={classNames({ 'p-invalid': submitted && !(matiere.id) })} />
                            </div>

                            <div className="col-12 md:col-3 lg:col-3 lg:mb-0">
                                <label className="mx-3" htmlFor="matiere"><b>Matiere</b></label>
                                <Dropdown id="matiere" value={matiere.id} onChange={(e) => onSelectMatiereChange(e, null)} options={matieres}
                                    required optionValue="id" optionLabel="libelle" placeholder="Selectionner la matière" className={classNames({ 'p-invalid': submitted && !(matiere.id) })} />
                            </div>
                            <div className="col-12">
                                <label className="mx-3" htmlFor="matricule">Matricule élève</label>
                                <InputText id="matricule" type="text" value={matricule} style={{ width: '250px' }} onChange={(e) => setmatricule(e.target.value)}/>

                            </div>
                            <div className="col-12 md:col-3 lg:col-3 lg:mb-0">
                                <Button label={searchBtnLbl} icon="pi pi-search" disabled={isSearching} className="p-button-success mr-2" onClick={search} />
                                <ProgressSpinner style={{ width: '30px', height: '30px' }} className={isNotSpinning} disabled={isSearching} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                            </div>
                        </div>
                    </div>
                    <Toast ref={toast} />
                    <div className="card">
                    <div className='p-3 text-center border-round bg-orange-600' hidden={hiddenFlatUpdate}>
                        <label className='text-white-alpha-90 text-lg bold mx-4'> Des modifications ont été constatées, veuillez actualiser les informations en Cliquant sur le bouton "Actualiser".</label>
                    </div>
                        <DataTable value={moyenneEleves} header="Matrice des élèves" emptyMessage='Aucune donnée' responsiveLayout="scroll">
                            <Column field="eleve.matricule" header="Matricule"></Column>
                            <Column body={nomPrenoms} header="Nom & Prénoms"></Column>
                            <Column className='border-1' body={noteBodyTemplate} header="Notes" style={{ width: '30em' }}></Column>
                            <Column header="Moyenne" body={showMoyenne}></Column>
                            <Column header="Coef." body={showCoef} ></Column>
                            <Column header="Moyenne Coef." style={{ width: '5em' }} body={calculMoyCoef}></Column>
                            <Column header="Rang" body={showRang}></Column>
                            <Column header="Est classé?" body={showIsClassed}></Column>
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(MoyennesMatiereParent, comparisonFn);
