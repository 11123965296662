import axios from 'axios';
import { BaseService } from './BaseService';

export class ClasseEleveMatiereService extends BaseService {

    async marquageClassement(obj){
        return axios.post(this.getHost()+'Classe-eleve-matiere/marquage-classement', obj).then(res => res.data);
    }

}
 