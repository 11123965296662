import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { InputText } from 'primereact/inputtext';
import classNames from 'classnames';
import { Dropdown } from 'primereact/dropdown';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { PersonnelService } from '../service/PersonnelService';
import { PersonnelMatiereClasseService } from '../service/PersonnelMatiereClasseService';
import { ClasseService } from '../service/ClasseService';


import { FonctionService } from '../service/FonctionService';

const ecoleId = sessionStorage.getItem('EcoleID');
const anneeId = sessionStorage.getItem('AnneEncours');

const PersonnelClasse = () => {
    let emptyProfesseur = {
        id: null,
        nom: 'Selectionner ',
        prenom: ' le professeur',
        fonction: {
            code: '01'
        }

    }

    let emptyEducateur = {
        id: null,
        nom: 'Selectionner ',
        prenom: ' l\'éducateur',
        fonction: {
            code: '02'
        }
    }

    let emptyPersonnel = {
        id: null,
        nom: "",
        prenom: ""

    }

    let emptyMatiere = {
        id: null,
        code: "",
        libelle: ""
    }

    let emptyClasse = {
        id: null,
        code: "",
        libelle: ""
    }

    let emptyAnnee = {
        id: null,
    }

    let emptyPersClasseMat = {
        matiere: emptyMatiere,
        personnel: emptyPersonnel,
        classe: emptyClasse,
        annee: emptyAnnee
    }



    const [globalFilter, setGlobalFilter] = useState(null);

    const toast = useRef(null);
    const dt = useRef(null);
   
    const [personnelMatiereClasse, setPersonnelMatiereClasse] = useState(emptyPersClasseMat);
    const [personnelMatiereClasses, setPersonnelMatiereClasses] = useState(null);
    
   
    const [openForm, setOpenForm] = useState(false);
    const [persMatClasseTab, setPersMatClasseTab] = useState([]);

    const [classes, setClasses] = useState(null);
   
    const [professeurs, setProfesseurs] = useState(null);
    const [educateurs, setEducateurs] = useState(null);
    const [fonctions, setFonctions] = useState(null);
    const [fonction, setFonction] = useState({ id: null });
    const [professeur, setProfesseur] = useState(null);
    const [educateur, setEducateur] = useState(null);
    const [persClaMatList, setPersClaMatList] = useState([]);
    const [disabled, setDisabled] = useState(true);
    const [submitted, setSubmitted] = useState(null);

    useEffect(() => {
   
        const fonctionService = new FonctionService();

        fonctionService.getList().then(res => {
            setFonctions(res);
        })

    }, []);

    const onSelectTypeChange = (e) => {
        const val = (e.target && e.target.value) || '';
        let _fonction = { ...fonction };
        _fonction[`id`] = val;
        setFonction(_fonction);
        const personnelMatiereClasseService = new PersonnelMatiereClasseService();
        personnelMatiereClasseService.getByFonction(anneeId, ecoleId, val).then(resp => { setPersonnelMatiereClasses(resp); console.log(resp); });
    }


    const getPpOrEdu = (personnels) => {

        setProfesseur(emptyProfesseur);
        setEducateur(emptyEducateur);

        for (let i = 0; i < personnels.length; i++) {
            if (personnels[i]?.typeFonction === 'PPRINC') {
                console.log('--->prof');
                setProfesseur(personnels[i].personnel);
            }
            if (personnels[i]?.typeFonction === 'EDUC') {
                console.log('--->educateur');
                setEducateur(personnels[i].personnel);
            }
        }
    }

    const onSelectChange = (e, name) => {
        const val = (e.target && e.target.value) || '';

        let _persClasMatList = [...persClaMatList];
        let _perMatClassObj = {};
       
        let _prof = { ...professeur };
        let _educ = { ...educateur }
        _perMatClassObj.annee = {id:anneeId};
        _perMatClassObj.user = sessionStorage.getItem('idUser');
      
        if (name === 'professeur') {
            _prof.id = val;
            setProfesseur(_prof);
            // console.log(_prof);
            // console.log(_persClasMatList.length);
            _perMatClassObj.typeFonction = 'PPRINC';
            _perMatClassObj.personnel = _prof;
            _perMatClassObj.classe = personnelMatiereClasse.classe;

            // rendre le champ convertible en type long pour éviter les erreurs au niveau api
            if(_perMatClassObj.personnel.id === '')
                _perMatClassObj.personnel.id = 0;

            if (_persClasMatList.length === 0) {
                _persClasMatList = _persClasMatList.concat([_perMatClassObj]);
                
            } else {
                let flat = true;
                for (let i = 0; i < _persClasMatList.length; i++) {
                    if(_persClasMatList[i].personnel.fonction.code === '01'){
                        _persClasMatList[i].personnel = {..._prof};
                        flat = false;
                    }
                }
                console.log(flat);
                if(flat)
                    _persClasMatList = _persClasMatList.concat([_perMatClassObj]);
            }

        }
        
        if (name === 'educateur') {
            _educ.id = val;
            setEducateur(_educ);
            console.log(_educ);
            console.log(_persClasMatList.length);
            _perMatClassObj.personnel = _educ;
            _perMatClassObj.typeFonction = 'EDUC';
            _perMatClassObj.classe = personnelMatiereClasse.classe;

            if(_perMatClassObj.personnel.id === '')
                _perMatClassObj.personnel.id = 0;
            
            if (_persClasMatList.length === 0) {
                _persClasMatList = _persClasMatList.concat([_perMatClassObj]);
            } else {
                let flat = true;
                for (let i = 0; i < _persClasMatList.length; i++) {
                    if(_persClasMatList[i].personnel.fonction.code === '02'){
                        _persClasMatList[i].personnel = {..._educ};
                        flat = false;
                    }
                }
                console.log(flat);
                if(flat)
                _persClasMatList = _persClasMatList.concat([_perMatClassObj]);
            }
        }
        
        
        // console.log(professeur.id+' ------ '+educateur.id);

        // console.log(_prof.id+' ------ '+_educ.id)
        
        // console.log(_persClasMatList);
        setPersClaMatList(_persClasMatList);


    }

    const onSelectClasseChange = (e) => {
        const val = (e.target && e.target.value) || '';
        const personnelService = new PersonnelService();
        let _persMatClasse = { ...personnelMatiereClasse };
        _persMatClasse[`classe`].id = val;
        _persMatClasse.annee.id= anneeId;
        personnelService.getListByProfil(sessionStorage.getItem('EcoleID'), 8).then(resp => setProfesseurs([emptyProfesseur].concat(resp)));
        setPersonnelMatiereClasse(_persMatClasse);
        setDisabled(false);
        // chargement des prof princ et éducateur s'il existaient pour affichage
        setPersClaMatList([]);
        let persClasMatService = new PersonnelMatiereClasseService();
        persClasMatService.getPersonnelByClasse(anneeId, ecoleId, val).then(res => {
            // setPersMatClasseTab(res);
            getPpOrEdu(res);
            console.log('---> ')

        })

        persClasMatService.getEducateurAndProfPrinc(anneeId, ecoleId, val).then(res => {
            setPersMatClasseTab([res]);
        })

    }



    // const reactfindIndexById = (id, atvs) => {
    //     let index = -1;
    //     for (let i = 0; i < atvs.length; i++) {
    //         if (atvs[i].id === id) {
    //             index = i;
    //             break;
    //         }
    //     }

    //     return index;
    // }



    const save = () => {
        setSubmitted(true);
        console.log(personnelMatiereClasse.classe.id !== null);
        
            if (personnelMatiereClasse.classe.id !== null && !persClaMatList.length == 0) {
                console.log('coco');
                console.log(persClaMatList);
                // console.log((educateur.id != null || professeur != null));
                // console.log(educateur.id +' _ '+professeur.id );
                const personnelMatiereClasseService = new PersonnelMatiereClasseService();
                personnelMatiereClasseService.saveAndDisplayList(persClaMatList).then(res => {
                    setPersMatClasseTab([res]);
                    console.log([res]);
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Enregistrement bien effectué', life: 3000 });
                }).catch((error) => {
                    if (error.response) {
                        console.log('erreur response- vie ecole- seances - save' + error.response.status);
                    } else if (error.request) {
                        console.log('erreur request- vie ecole' + error.request);
                    } else {
                        console.log('Error autres', error.message);
                    }
                    toast.current.show({ severity: 'error', summary: 'Successful', detail: error.message, life: 3000 });
                }).finally(
                    setPersClaMatList([])
                );
            }
            
       console.log(persClaMatList);
       console.log(personnelMatiereClasse);
    }
    const codeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Classe</span>
                {rowData?.classe?.libelle}
            </>
        );
    }


    const nomBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Matière</span>
                {rowData.personnel != null ? rowData.personnel.nom + ' ' + rowData.personnel.prenom : ''}
            </>
        );
    }

    const fonctionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Matière</span>
                {rowData?.personnel?.fonction?.libelle}
            </>
        );
    }


    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h3> </h3>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Recherche..." />
            </span>
        </div>
    );

    const openNew = () => {
        setOpenForm(true);
        setSubmitted(false);
        setDisabled(true);
        const personnelService = new PersonnelService();
        const classeService = new ClasseService();
        setPersClaMatList([]);
        personnelService.getListByProfil(sessionStorage.getItem('EcoleID'), 9).then(resp => {
            setEducateurs([emptyEducateur].concat(resp));
        });
        classeService.getSortedListByEcole(sessionStorage.getItem("EcoleID")).then(resp => setClasses(resp));
    }

    const onHideForm = () => {
        setOpenForm(false);
        setPersMatClasseTab([]);
        setPersonnelMatiereClasse(emptyPersClasseMat);
        setEducateur(null);
        setProfesseur(null);
        setEducateurs([]);
        setProfesseurs([]);
    }

    const rigthToolbarContent = (
        <React.Fragment>
            <div className="my-2">
                <Button label="Nouveau" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                {/* <Button label="Supprimer" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedClasses || !selectedClasses.length} /> */}
            </div>
        </React.Fragment>
    )

    const nomPrenom = (val) => {
        return (
            <>
                {val.nom + ' ' + val.prenom}
            </>
        )
    }
    

    const nomPrenomBodyEduc = (val) => {
        return (
            <>
                {val.educateur ? val.educateur.nom + ' ' + val.educateur.prenom : ''}
            </>
        )
    }

    const nomPrenomBodyProf = (val) => {
        return (
            <>
                {val.prof ? val.prof.nom + ' ' + val.prof.prenom : ''}
            </>
        )
    }
    

    const formDialogFooter = () => {
        return (
            <div className='grid col-12'>
                <div className='col-offset-8 col-4'>
                    <Button label="Fermer" className=" p-button-primary" onClick={onHideForm} />
                </div>
            </div>
        )
    }

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <div>
                        <label><h3><b>Affecter un personnel à une classe</b></h3></label>
                    </div>
                    <div className="md:col-offset-4">
                    </div>
                    <div className="col-12 md:col-4 lg:col-4 lg:mb-0">
                        <label className="mx-3" htmlFor="typePersonnel"><b>Type de personnel</b></label>
                        <Dropdown id="typePersonnel" value={fonction?.id} onChange={(e) => onSelectTypeChange(e)} options={fonctions}
                            required optionValue="id" optionLabel="libelle" placeholder="Selectionner le type de personnel" />
                    </div>

                </div>
            </div>

            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar right={rigthToolbarContent} className="mb-4"></Toolbar>

                    <DataTable ref={dt} value={personnelMatiereClasses}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="affichage {first} de {last} sur {totalRecords} classes"
                        globalFilter={globalFilter} emptyMessage="Aucun enregistrement trouvé." header={header} responsiveLayout="scroll">
                        <Column field="classe.libelle" header="Classe" body={codeBodyTemplate} headerStyle={{ width: '14%', minWidth: '7rem' }}></Column>
                        <Column field="personnel.code" header="Matricule" sortable headerStyle={{ width: '14%', minWidth: '7rem' }}></Column>
                        <Column field="personnel.nom" header="Nom et Prénoms" body={nomBodyTemplate} sortable headerStyle={{ width: '14%', minWidth: '7rem' }}></Column>
                        <Column field="personnel.fonction.id" header="Fonction" body={fonctionBodyTemplate} sortable headerStyle={{ width: '14%', minWidth: '5rem' }}></Column>
                    </DataTable>
                </div>
            </div>

            <Dialog visible={openForm} style={{ width: '70%' }} header="Affecter une classe à un personnel " footer={formDialogFooter} modal className="p-fluid" onHide={onHideForm}>
                <div className='grid'>
                    <div className='col-4'>

                        <div className="col-12">
                            <label className="mx-3" htmlFor="classe"><b>Classe</b></label>
                            <Dropdown id="classe" value={personnelMatiereClasse?.classe?.id} onChange={(e) => onSelectClasseChange(e)} options={classes}
                                required optionValue="id" optionLabel="libelle" placeholder="Selectionner la classe" className={classNames({ 'p-invalid': submitted && !(personnelMatiereClasse.classe.id) })} />
                        </div>

                        <div className='col-12'>
                            <label className="mx-3" htmlFor="prof"><b>Professeur Principal</b></label>
                            <Dropdown id='prof' value={professeur?.id} disabled={disabled} options={professeurs} onChange={(e) => onSelectChange(e, 'professeur')}
                                optionValue='id' optionLabel={nomPrenom} placeholder='selectionner le professeur' className={classNames({ 'p-invalid': submitted && !(professeur?.id || educateur?.id) })}></Dropdown>
                        </div>

                        <div className="col-12">
                            <label className="mx-3" htmlFor="educateur"><b>Educateur</b></label>
                            <Dropdown id="educateur" value={educateur?.id} disabled={disabled} onChange={(e) => onSelectChange(e, 'educateur')} options={educateurs}
                                required optionValue="id" optionLabel={nomPrenom} placeholder="Selectionner l&apos;éducateur" className={classNames({ 'p-invalid': submitted && !(educateur?.id || professeur?.id) })} />
                        </div>

                    </div>
                    <div className='col-6 col-offset-2'>

                        <DataTable value={persMatClasseTab} scrollable scrollHeight="400px" emptyMessage='En attente d affectation ...' header='Affectations crées' stripedRows responsiveLayout="scroll">
                            <Column field="classe.libelle" header="Classe"></Column>
                            <Column field="prof.id"  body={nomPrenomBodyProf} header="Professeur Princ."></Column>
                            <Column field="educateur.id" body={nomPrenomBodyEduc} header="Educateur" ></Column>
                        </DataTable>
                    </div>
                    <div className='col-3 col-offset-1'>
                        <Button label="Enregistrer" icon="pi pi-save" disabled={disabled} className="p-button-success" onClick={() => save()} />
                    </div>

                </div>
            </Dialog>

        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(PersonnelClasse, comparisonFn);