import React, { useState, useEffect, useRef } from 'react';
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';
import { Chart } from 'primereact/chart';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Divider } from 'primereact/divider';
import {ProductService} from "../../service/ProductService";
import {ScrollTop} from "primereact/scrolltop";

const lineData = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
        {
            label: 'First Dataset',
            data: [65, 59, 80, 81, 56, 55, 40],
            fill: false,
            backgroundColor: '#2f4860',
            borderColor: '#2f4860',
            tension: .4
        },
        {
            label: 'Second Dataset',
            data: [28, 48, 40, 19, 86, 27, 90],
            fill: false,
            backgroundColor: '#00bb7e',
            borderColor: '#00bb7e',
            tension: .4
        }
    ]
};

const DashboardDirecteurEtude = (props) => {
    const [products, setProducts] = useState(null);
    const menu1 = useRef(null);
    const menu2 = useRef(null);
    const [loadPage, setloadPage] = useState(sessionStorage.getItem('refresh'));
    const [pieOptions, setPieOptions] = useState(null)
    const [lineOptions, setLineOptions] = useState(null)
    const barData = {
        labels: ['AFF', 'NAFF', 'Nouveaux', 'Anciens inscrits', 'Anciens non inscrits'],
        datasets: [
            {
                label: 'Inscription Elèves',
                backgroundColor: '#479c12',
                data: [45, 58, 200, 85, 500, 300, 40]
            }
        ]
    };


    const applyLightTheme = () => {
        const lineOptions = {
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef',
                    }
                },
                y: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef',
                    }
                },
            }
        };

        setLineOptions(lineOptions)
    }
    const pieData = {
        labels: ['AFF ', 'NAFF', 'Nouveaux ','Anciens inscrits ','Anciens non inscrits '],
        datasets: [
            {
                data: [300, 100, 200,85,80],
                backgroundColor: [
                    "#FF6384",
                    "#36A2EB",
                    "#FFCE56",
                    "#479c12",
                    "#9c1265",
                ],
                hoverBackgroundColor: [
                    "#FF6384",
                    "#36A2EB",
                    "#FFCE56",
                    "#479c12",
                    "#9c1265"
                ]
            }
        ]
    };
    const barOptions = {
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef',
                }
            },
            y: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef',
                }
            },
        }
    };
    const applyDarkTheme = () => {
        const lineOptions = {
            plugins: {
                legend: {
                    labels: {
                        color: '#ebedef'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#ebedef'
                    },
                    grid: {
                        color: 'rgba(160, 167, 181, .3)',
                    }
                },
                y: {
                    ticks: {
                        color: '#ebedef'
                    },
                    grid: {
                        color: 'rgba(160, 167, 181, .3)',
                    }
                },
            }
        };

        setLineOptions(lineOptions)
    }


    useEffect(()=>{
        if(loadPage==1){

           window.location.reload(false);
           sessionStorage.setItem('refresh',0);
        }
    },[loadPage]) ;

    useEffect(() => {
   console.log("parentData "+ props.parentData) ;
        const productService = new ProductService();
        productService.getProductsSmall().then(data => setProducts(data));
    }, []);

    useEffect(() => {
        if (props.colorMode === 'light') {
            applyLightTheme();
        } else {
            applyDarkTheme();
        }
    }, [props.colorMode]);

    const formatCurrency = (value) => {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    };

    return (

        <div className="grid">
            <div className="col-12 ">
                <div className="card">
                    <h5>Inscription Elèves</h5>
                    <Chart type="bar" data={barData} options={barOptions} />
                </div>
            </div>




            <div className="col-12 xl:col-6">
                <div className="card">
                    <h5>Inscription Elèves</h5>
                    <Chart type="bar" data={barData} options={barOptions} />
                </div>

            </div>

            <div className="col-12 xl:col-6">
                <div className="card">
                        <h5>Appel Numérique</h5>

                            <Divider align="left">
                                <div className="inline-flex align-items-center">
                                    <i className="pi pi-user mr-2"></i>

                                </div>
                            </Divider>

                            <p> 0 Absences </p>

                            <Divider align="left">
                                <div className="inline-flex align-items-center">
                                    <i className="pi pi-user mr-2"></i>

                                </div>
                            </Divider>

                            <p> 0 Nombre de Classe ayant cours  </p>


                            <Divider align="left">
                                <div className="inline-flex align-items-center">
                                    <i className="pi pi-user mr-2"></i>
                                </div>
                            </Divider>
                            <p> 0 Nombre de cours  </p>

                            <Divider align="left">
                                <div className="inline-flex align-items-center">
                                    <i className="pi pi-user mr-2"></i>
                                </div>
                            </Divider>
                            <p> 0 Cours effectué  </p>

                            <Divider align="left">
                                <div className="inline-flex align-items-center">
                                    <i className="pi pi-user mr-2"></i>
                                </div>
                            </Divider>
                            <p> 0 Cours non effectué  </p>


                            <Divider align="left">
                                <div className="inline-flex align-items-center">
                                    <i className="pi pi-user mr-2"></i>
                                </div>
                            </Divider>
                            <p> 0 Appel effectué  </p>

                            <Divider align="left">
                                <div className="inline-flex align-items-center">
                                    <i className="pi pi-user mr-2"></i>
                                </div>
                            </Divider>
                            <p> 0 Appel non effectué  </p>

                            <Divider align="left">
                                <div className="inline-flex align-items-center">
                                    <i className="pi pi-user mr-2"></i>
                                </div>
                            </Divider>
                            <p> 0 Elèves présents en classe  </p>

                            <Divider align="left">
                                <div className="inline-flex align-items-center">
                                    <i className="pi pi-user mr-2"></i>
                                </div>
                            </Divider>
                            <p> 0 Elèves présents non autorisés  </p>

                            <Divider align="left">
                                <div className="inline-flex align-items-center">
                                    <i className="pi pi-user mr-2"></i>
                                </div>
                            </Divider>
                            <p> 0 Elèves absents en classe  </p>

                            <Divider align="left">
                                <div className="inline-flex align-items-center">
                                    <i className="pi pi-user mr-2"></i>
                                </div>
                            </Divider>
                            <p> 0 Elèves autorisés absents  </p>









                </div>


            </div>
        </div>

    );
}

const comparisonFn = function (prevProps, nextProps) {
    return (prevProps.location.pathname === nextProps.location.pathname) && (prevProps.colorMode === nextProps.colorMode);
};

//export default React.memo(DashboardDirecteurEtude, comparisonFn);
export default DashboardDirecteurEtude;
