import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import { InputNumber } from 'primereact/inputnumber';
import { ClasseService } from '../service/ClasseService';
import { PeriodeService } from '../service/PeriodeService';
import { BulletinService } from '../service/BulletinService';
import { Image } from 'primereact/image';
import { ClasseEleveService } from '../service/ClasseEleveService';


const AdjustMoyenne = () => {

  const [bulletin, setBulletin] = useState([]);
  const [loading, setLoading] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [classe, setClasse] = useState({ id: null });
  const [periode, setPeriode] = useState({ id: null });
  const [eleve, setEleve] = useState({ matricule: null });
  const [classes, setClasses] = useState([]);
  const [periodes, setPeriodes] = useState([]);
  const [eleves, setEleves] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [saveBtnState, setSaveBtnState] = useState(true);
  const toast = useRef(null);

  let defaultAvatarImage = 'images/profil/avatar-user-profile.png';


  const [photoUrl, setPhotoUrl] = useState('')

  useEffect(() => {

    const classeService = new ClasseService();
    const eleveService = new ClasseEleveService();
    const periodeService = new PeriodeService();

    classeService.getSortedListByEcole(sessionStorage.getItem('EcoleID')).then(res => {
      setClasses(res);
    })

    periodeService.getByPeriodicite(sessionStorage.getItem('periodiciteId')).then(res => {
      setPeriodes(res);
    })

  }, []);
  let identity =
    <div>
      <p>Matricule : <span className='text-500'>{bulletin.matricule}</span></p>
      <p>Nom & prénoms : <span className='text-500'>{bulletin.nom} {bulletin.prenoms}</span></p>
      <p>Classe : <span className='text-500'>{bulletin.libelleClasse}</span></p>
    </div>

  let InfoGenerale =
    <div>
      <p>Moyenne Generale : <span className='text-500'>{bulletin.moyGeneral}</span></p>
      <p>Appréciation : <span className='text-500'>{bulletin.appreciation}</span></p>
      <p>Rang : <span className='text-500'>{bulletin.rang} {bulletin.rang ? (bulletin.rang == 1 ? 'er' : 'ème') : ''}</span></p>
    </div>

  let photo = <div>
    <Image width='95%' className='mx-1' src={photoUrl} />
  </div>

  const search = () => {
    setSubmitted(true);
    if (classe.id && eleve.matricule && periode.id && sessionStorage.getItem('AnneEncours')) {
      const bulletinService = new BulletinService();
      setLoading(true);
      setIsSearching(true);
      bulletinService.getBulletin(classe.id, eleve.matricule, sessionStorage.getItem('AnneEncours'), periode.id).then(res => {
        res.anneeId = sessionStorage.getItem('AnneEncours');
        setBulletin(res);
        console.log(res)
        setPhotoUrl(eleve.urlPhoto ? eleve.urlPhoto : defaultAvatarImage);
        setLoading(false);
        setIsSearching(false);
        if (res.details != null && res.details.length > 0)
          setSaveBtnState(false);
      }).catch((error) => {
        console.log(error);
        setLoading(false);
        setIsSearching(false);
      })
    }
  }

  const onSelectClasseChange = (e) => {
    const val = (e.target && e.target.value) || '';
    console.log(val)
    let _classe = { ...classe };
    _classe.id = val;
    setClasse(_classe);
    const classeEleveService = new ClasseEleveService();
    classeEleveService.getByClasseAndAnnee(val, sessionStorage.getItem('AnneEncours')).then(res => {
      let _eleves = [];
      for (const ce of res) {
        _eleves.push(ce.inscription.eleve);
      }
      console.log(_eleves)
      setEleves(_eleves);
    });
  }

  const onSelectEleveChange = (e) => {
    const val = (e.target && e.target.value) || '';
    let _eleve = { ...eleve };
    _eleve.matricule = val;
    let _index = reactfindIndexByMatricule(val, eleves);
    if (_index !== -1) {
      _eleve.matricule = eleves[_index].matricule;
      _eleve.urlPhoto = eleves[_index].urlPhoto;
    }
    console.log(_eleve)
    setEleve(_eleve);
  }

  const onSelectPeriodeChange = (e) => {
    const val = (e.target && e.target.value) || '';
    console.log(val)
    let _periode = { ...periode };
    _periode.id = val;
    setPeriode(_periode);
  }

  const reactfindIndexByMatricule = (matricule, list) => {
    let index = -1;
    for (let i = 0; i < list.length; i++) {
      if (list[i].matricule === matricule) {
        index = i;
        break;
      }
    }
    return index;
  }
  const reactfindIndexByMatiere = (matiere) => {
    let index = -1;
    for (let i = 0; i < bulletin.details.length; i++) {
      if (bulletin.details[i].matiereId === matiere) {
        index = i;
        break;
      }
    }
    return index;
  }

  const onSetAdjustment = (e, rowData) => {
    let val = e.value ? e.value : 0;
    rowData.adjustMoyenne = val;
    rowData.statut = 'VALID'
    rowData.isChecked = true;
    let index = reactfindIndexByMatiere(rowData.matiereId);
    if (index !== -1) {
      let _bulletin = { ...bulletin };
      _bulletin.details.splice(index, 1, rowData);
      setBulletin(_bulletin);
    }
  }

  const onChangeCheck = (e, rowData) => {

    rowData.isChecked = e.value;
    rowData.statut = e.value ? 'VALID' : 'INVALID';
    console.log(e.value);
    let index = reactfindIndexByMatiere(rowData.matiereId);
    console.log('index ::: ' + index);
    if (index !== -1) {
      let _bulletin = { ...bulletin };
      _bulletin.details.splice(index, 1, rowData);
      setBulletin(_bulletin);
    }
    console.log(rowData);
  }

  const adjustZone = (rowData) => {
    return <InputNumber allowEmpty={false} value={rowData.adjustMoyenne} size={3} min={0} max={20} onValueChange={(e) => onSetAdjustment(e, rowData)} ></InputNumber>
  }

  const pecZone = (rowData) => {
    return <InputSwitch checked={rowData.isChecked} onChange={(e) => onChangeCheck(e, rowData)}></InputSwitch>
  }

  const save = () => {
    const bulletinService = new BulletinService();
    console.log(bulletin);
    bulletinService.saveAdjust(bulletin).then(res => {
      console.log(res);
      toast.current.show({ severity: 'success', summary: 'Repêchage', detail: res, life: 20000 });
    }).catch((error) =>{
      let detailTxt = '';
      if (error.response) {
          console.log('erreur response- pouls-scolaire' + error.response.status);
          detailTxt = error.response.data;
      } else if (error.request) {
          console.log('erreur request- pouls-scolaire' + error.request);
          console.log(error.request);
          detailTxt = 'Connexion refusée ou interrompue! veuillez vérifier votre connexion internet ou votre parefeu!';
      } else {
          console.log('Error autres', error.message);
          detailTxt = error.message;
      }
      toast.current.show({ severity: 'error', summary: 'Repêchage', detail: detailTxt, life: 20000 });
    })
  }

  const footer = () => {
    return (<div className='flex my-2 p-2 surface-200 shadow-2 justify-content-end'>
      <Button label='Enregistrer' disabled={saveBtnState} onClick={() => save()} />
    </div>)
  }

  const header = (
    <ColumnGroup>
      <Row>
        <Column alignHeader={'center'} colSpan={10} header={<h3><b>BULLETIN DU {bulletin.libellePeriode}</b></h3>} className='border-1 uppercase' />
      </Row>
      <Row>
        <Column header={photo} style={{ width: '120px' }} rowSpan={3} className='border-1 p-1' />
        <Column header={identity} colSpan={6} className='border-1' />
        <Column header={InfoGenerale} colSpan={3} className='border-1' />
      </Row>

      <Row>
        <Column colSpan={2} style={{ width: '15%' }} />
        <Column header="Moy" />
        <Column header="Coef" />
        <Column header="Moy. Coef." />
        <Column header="Rang." />
        <Column header="Appr." />
        <Column header="Moy Ajust" />
        <Column header="Action" />
      </Row>
    </ColumnGroup>
  )

  let nomPrenoms = (rowData) => {
    return rowData.matricule + ' - ' + rowData.nom + ' ' + rowData.prenom;
  }

  return (
    <div className="grid crud-demo">
      <Toast ref={toast} />
      <div className="col-12">
        <div className="">
          <div className='card grid my-3'>
            <div className="col-12 md:col-2 lg:col-2 lg:mb-0">
              <label className="mx-3" htmlFor="classe"><b>Classe</b></label>
              <Dropdown id="classe" filter filterBy='libelle' filterPlaceholder='Filtrer par la classe' value={classe.id} onChange={(e) => onSelectClasseChange(e)} options={classes}
                required optionValue="id" optionLabel='libelle' placeholder="Selectionner la classe" className={classNames({ 'p-invalid': submitted && !(classe?.id) })} />
            </div>
            <div className="col-12 md:col-4 lg:col-4 lg:mb-0">
              <label className="mx-3" htmlFor="eleve"><b>&Eacute;lève</b></label>
              <Dropdown id="eleve" value={eleve?.matricule} filter filterBy='matricule' filterPlaceholder='Filtrer par le matricule' onChange={(e) => onSelectEleveChange(e)} options={eleves}
                required optionValue="matricule" optionLabel={nomPrenoms} placeholder="Selectionner l&apos;&eacute;l&egrave;ve" className={classNames({ 'p-invalid': submitted && !(eleve?.matricule) })} />
            </div>

            <div className="col-12 md:col-4 lg:col-4 lg:mb-0">
              <label className="mx-3" htmlFor="periode"><b>Période</b></label>
              <Dropdown id="periode" value={periode?.id} onChange={(e) => onSelectPeriodeChange(e)} options={periodes}
                required optionValue="id" optionLabel="libelle" placeholder="Selectionner la période" className={classNames({ 'p-invalid': submitted && !(periode?.id) })} />
            </div>

            <div className="col-12 md:col-2 lg:col-2 lg:mb-0">
              <Button label='Rechercher' icon="pi pi-search" disabled={isSearching} className="p-button-success mr-2" onClick={search} />
            </div>
          </div>
          <div>
            <DataTable value={bulletin.details} rows={10} loading={loading} dataKey="eleve.matricule" emptyMessage="Aucun bulletin trouvé" responsiveLayout="scroll"
              globalFilter={globalFilter} headerColumnGroup={header} footer={footer} className='pt-3'>
              <Column field="matiereLibelle" colSpan={3} />
              <Column></Column>
              <Column></Column>
              <Column field="moyenne" />
              <Column field="coef" />
              <Column field="moyCoef" />
              <Column field="rang" />
              <Column field="appreciation" />
              <Column style={{ width: '5px' }} body={adjustZone} />
              <Column body={pecZone} />
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  );
}

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(AdjustMoyenne, comparisonFn);